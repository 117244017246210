import { createContext } from 'react'

import { type ChartFields } from '../../types'

export interface ChartFieldsContextValue {
  chartCellId: string
  fields: ChartFields
  setFieldValue: <K extends keyof ChartFields>(
    fieldName: K,
    value: ChartFields[K]
  ) => void
}

export const ChartFieldsContext = createContext<ChartFieldsContextValue | null>(
  null
)
