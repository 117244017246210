import { AutoscheduleStarSolid } from '@motion/icons'
import { ControlledUnstyledCollapsableContainer } from '@motion/ui/base'

import { RotatingChevronIcon } from '~/global/components'
import { type ReactNode } from 'react'

type SectionCollapsibleProps = {
  title: string
  children: ReactNode
}

export function SectionCollapsible({
  title,
  children,
}: SectionCollapsibleProps) {
  return (
    <ControlledUnstyledCollapsableContainer
      renderHeader={({ expanded, toggle }) => (
        <button
          type='button'
          onClick={toggle}
          className='hover:bg-semantic-neutral-bg-hover duration-150 flex items-center gap-3 rounded w-max p-1 pl-2 pr-3'
        >
          <RotatingChevronIcon
            expanded={expanded}
            className='text-semantic-neutral-icon-default size-4'
          />

          <h3 className='flex items-center gap-1 text-sm font-semibold text-semantic-neutral-text-default'>
            <AutoscheduleStarSolid className='size-4 shrink-0 text-semantic-gradient-purple' />
            {title}
          </h3>
        </button>
      )}
    >
      <div className='flex flex-col gap-6 py-3'>{children}</div>
    </ControlledUnstyledCollapsableContainer>
  )
}
