import { type COLOR, COLOR_HUES } from '@motion/shared/common'
import { type ColorId } from '@motion/ui-logic'
import { entries } from '@motion/utils/object'

import { GRAY_HUE } from './palette-provider'

export function getColorHueValue(color: COLOR | string) {
  return colorOptions[color as COLOR]?.hue ?? GRAY_HUE
}

export function getColorName(color: COLOR | string): string {
  return colorOptions[color as COLOR]?.name ?? ''
}

export function getColorFromColorId(
  colorId: ColorId | null | undefined
): COLOR {
  if (!colorId) return 'gray'

  return colorIdToColor.get(colorId) ?? 'gray'
}

export function getColorIdFromColor(color: COLOR): ColorId | null {
  return colorOptions[color].colorId
}

type ColorValueWithColorId = {
  hue: number
  name: string
  colorId: ColorId
}

type ColorValueWithoutColorId = Omit<ColorValueWithColorId, 'colorId'> & {
  colorId: null
}

type ColorsWithColorId =
  | 'red'
  | 'orange'
  | 'mint'
  | 'green'
  | 'cyan'
  | 'blue'
  | 'lavender'
  | 'violet'
  | 'pink'
  | 'gray'
  | 'yellow'
  | 'emerald'
  | 'rose'

type ColorsWithoutColorId =
  | 'tangerine'
  | 'highlighter-yellow'
  | 'lime'
  | 'sage'
  | 'teal'
  | 'sky'
  | 'cobalt'
  | 'grape'
  | 'raspberry'
  | 'purple'

type ColorOptions = {
  [key in ColorsWithColorId]: ColorValueWithColorId
} & {
  [key in ColorsWithoutColorId]: ColorValueWithoutColorId
}

export const colorOptions: ColorOptions = {
  red: {
    hue: COLOR_HUES['red'],
    name: 'Red',
    colorId: '11',
  },
  orange: {
    hue: COLOR_HUES['orange'],
    name: 'Orange',
    colorId: '6',
  },
  tangerine: {
    hue: COLOR_HUES['tangerine'],
    name: 'Tangerine',
    colorId: null,
  },
  yellow: {
    hue: COLOR_HUES['yellow'],
    name: 'Yellow',
    colorId: '5',
  },
  'highlighter-yellow': {
    hue: COLOR_HUES['highlighter-yellow'],
    name: 'Yellow Highlight',
    colorId: null,
  },
  lime: {
    hue: COLOR_HUES['lime'],
    name: 'Lime',
    colorId: null,
  },
  mint: {
    hue: COLOR_HUES['mint'],
    name: 'Mint',
    colorId: '2',
  },
  green: {
    hue: COLOR_HUES['green'],
    name: 'Green',
    colorId: '0',
  },
  sage: {
    hue: COLOR_HUES['sage'],
    name: 'Sage',
    colorId: null,
  },
  emerald: {
    hue: COLOR_HUES['emerald'],
    name: 'Emerald',
    colorId: '10',
  },
  cyan: {
    hue: COLOR_HUES['cyan'],
    name: 'Cyan',
    colorId: '7',
  },
  teal: {
    hue: COLOR_HUES['teal'],
    name: 'Teal',
    colorId: null,
  },
  sky: {
    hue: COLOR_HUES['sky'],
    name: 'Sky',
    colorId: null,
  },
  blue: {
    hue: COLOR_HUES['blue'],
    name: 'Blue',
    colorId: '9',
  },
  cobalt: {
    hue: COLOR_HUES['cobalt'],
    name: 'Cobalt',
    colorId: null,
  },
  lavender: {
    hue: COLOR_HUES['lavender'],
    name: 'Lavender',
    colorId: '1',
  },
  purple: {
    hue: COLOR_HUES['purple'],
    name: 'Purple',
    colorId: null,
  },
  violet: {
    hue: COLOR_HUES['violet'],
    name: 'Violet',
    colorId: '3',
  },
  grape: {
    hue: COLOR_HUES['grape'],
    name: 'Grape',
    colorId: null,
  },
  pink: {
    hue: COLOR_HUES['pink'],
    name: 'Pink',
    colorId: '12',
  },
  raspberry: {
    hue: COLOR_HUES['raspberry'],
    name: 'Raspberry',
    colorId: null,
  },
  rose: {
    hue: COLOR_HUES['rose'],
    name: 'Rose',
    colorId: '4',
  },
  // Gray is treated specially for web and doesn't use COLOR_HUES
  gray: { hue: GRAY_HUE, name: 'Gray', colorId: '8' },
}

export const colorIdToColor = new Map(
  entries(colorOptions)
    .filter(([, { colorId }]) => colorId != null)
    .map(([color, { colorId }]) => [colorId, color])
)
