import { showToast } from '@motion/ui/base'
import { Sentry } from '@motion/web-base/sentry'
import type {
  FolderSchema,
  ProjectSchema,
  VersionedViewV2,
} from '@motion/zod/client'

import { useFavoriteTypeLookup } from '~/areas/folders/hooks/use-favorite-type-lookup'
import { useFindFolderItem } from '~/areas/folders/hooks/use-find-folder-item'
import { useRemoveFavorite } from '~/areas/folders/hooks/use-remove-favorite'
import { useAddItemToFolder, useFavoriteFolderItem } from '~/global/rpc/folders'
import { showErrorToast } from '~/global/toasts'
import { useCallback, useMemo } from 'react'

import { type UserFavoriteType, useUserFavorites } from './use-user-favorites'

export type UseUserFavoritedItemParameters = [
  itemType: UserFavoriteType,
  schemaOrId:
    | VersionedViewV2
    | FolderSchema
    | ProjectSchema
    | string
    | undefined,
  viewContext?: {
    workspaceId: string
    folderId?: string
    projectId?: string
  },
]

export type UseUserFavoritedItemReturnType = {
  isFavorited: boolean
  toggleFavorite: () => Promise<void>
}

export const useUserFavoritedItem = (
  ...[itemType, schemaOrId, viewContext]: UseUserFavoritedItemParameters
): UseUserFavoritedItemReturnType => {
  const { mutateAsync: mutateAddViewAsFavorite } = useAddItemToFolder()
  const { mutateAsync: mutateAddItemAsFavorite } = useFavoriteFolderItem()
  const removeFavorite = useRemoveFavorite()
  const userFavorites = useUserFavorites()
  const findFolderItem = useFindFolderItem()
  const favoriteTypeLookup = useFavoriteTypeLookup()

  const model = useMemo(() => {
    if (typeof schemaOrId === 'string') {
      return favoriteTypeLookup(itemType, schemaOrId)
    }

    return schemaOrId
  }, [favoriteTypeLookup, itemType, schemaOrId])

  const folderItem = useMemo(() => {
    if (!schemaOrId || itemType === 'VIEW') return null

    const lookupId = typeof schemaOrId === 'string' ? schemaOrId : schemaOrId.id

    const result = findFolderItem((item) => {
      return item.itemType === itemType && item.itemId === lookupId
    })

    return result ? result[0] : null
  }, [findFolderItem, itemType, schemaOrId])

  const favoritedItem = useMemo(() => {
    if (!model) return null

    return (
      userFavorites.items.find((favorite) => {
        switch (favorite.type) {
          case 'VIEW':
            return (
              favorite.view.id === model.id &&
              favorite.workspaceId === viewContext?.workspaceId &&
              favorite.folderId === viewContext?.folderId &&
              favorite.projectId === viewContext?.projectId
            )
          case 'FOLDER':
            return favorite.folder.id === model.id
          case 'PROJECT':
            return favorite.project.id === model.id
          default:
            return false
        }
      }) ?? null
    )
  }, [model, userFavorites.items, viewContext])

  const toggleFavorite = useCallback(async () => {
    const isAdding = !favoritedItem

    const errorExtra = {
      level: 'error' as const,
      tags: {
        position: 'useUserFavoritedItem',
      },
      extra: {
        matchedFavoritedItemId: favoritedItem?.id ?? null,
        matchedFolderItemId: folderItem?.id ?? null,
        matchedModelId: model?.id ?? null,
        newState: isAdding ? 'ADDING' : 'REMOVING',
      },
    }

    if (!model) {
      Sentry.captureException(
        new Error('Could not locate item to toggle favorite'),
        errorExtra
      )

      return void showErrorToast('Failed to modify your favorites')
    }

    if (!userFavorites.folder) {
      Sentry.captureException(
        new Error('Could not locate user favorites system folder'),
        errorExtra
      )

      return void showErrorToast('Failed to modify your favorites')
    }

    if (!isAdding) {
      return void (await removeFavorite(favoritedItem.id))
    }

    // Add Favorite
    try {
      if (itemType === 'VIEW') {
        if (!viewContext?.workspaceId) {
          Sentry.captureException(
            new Error('workspaceId was not set when trying to favorite a view'),
            errorExtra
          )

          return void showErrorToast('Failed to modify your favorites')
        }

        await mutateAddViewAsFavorite({
          itemType: 'VIEW',
          folderId: userFavorites.folder.id,
          itemId: model.id,
          metadata: {
            workspaceId: viewContext.workspaceId,
            folderId: viewContext.folderId,
            projectId: viewContext.projectId,
          },
        })
      } else {
        if (!folderItem) {
          Sentry.captureException(
            new Error('Failed to match folder item to add as favorite'),
            errorExtra
          )

          return void showErrorToast('Failed to modify your favorites')
        }

        await mutateAddItemAsFavorite({
          folderId: userFavorites.folder.id,
          itemId: folderItem.id,
        })
      }

      showToast('success', `'${model.name}' has been added to your favorites`)
    } catch (e) {
      Sentry.captureException(e, errorExtra)

      showErrorToast(e, `Failed to add '${model.name}' to your favorites`)
    }
  }, [
    favoritedItem,
    folderItem,
    itemType,
    model,
    mutateAddItemAsFavorite,
    mutateAddViewAsFavorite,
    removeFavorite,
    userFavorites.folder,
    viewContext,
  ])

  return {
    isFavorited: !!favoritedItem,
    toggleFavorite,
  }
}
