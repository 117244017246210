import { isObjectNoneId } from '@motion/shared/identifiers'
import {
  type ProjectSchema,
  type StageDefinitionSchema,
  type WorkspaceSchema,
} from '@motion/zod/client'

import { StageBadge } from '~/global/components/badges'
import { StageDropdown } from '~/global/components/dropdowns'
import { StageLabel } from '~/global/components/labels'
import { useLegacyStageDefinition } from '~/global/hooks'

import { ModalFieldButton, type ModalFieldButtonProps } from '../components'

export type StageFieldProps = {
  stageDefinitionId: StageDefinitionSchema['id']
  workspaceId: WorkspaceSchema['id']
  projectId: ProjectSchema['id'] | null | undefined
  onChange: (stageDef: StageDefinitionSchema) => void
  suffix?: ModalFieldButtonProps['suffix']
  disabled?: boolean
  showLabel?: boolean
}
export const StageField = ({
  stageDefinitionId,
  workspaceId,
  projectId,
  onChange,
  suffix,
  disabled = false,
  showLabel = false,
}: StageFieldProps) => {
  const stage = useLegacyStageDefinition(stageDefinitionId)
  if (isObjectNoneId(stage)) return null

  return (
    <StageDropdown
      selectedStageId={stage.id}
      workspaceId={workspaceId}
      projectId={projectId}
      onChange={onChange}
    >
      <ModalFieldButton
        size='normal'
        icon={<StageBadge value={stage} />}
        suffix={suffix}
        disabled={disabled}
        label={showLabel ? 'Stage' : undefined}
      >
        <StageLabel value={stage} nameOnly />
      </ModalFieldButton>
    </StageDropdown>
  )
}
