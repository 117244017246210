import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import {
  type MeetingActionItemSchema,
  type MeetingInsightsSchema,
  type MeetingInsightsVisibility,
  type TaskSchema,
} from '@motion/zod/client'

import { useSearchParams } from '~/routing'

export function useAiAnalyticsCalls() {
  // if param present, we're in a MIP
  const { mTask } = useSearchParams<{ mTask?: string }>()

  const recordActionItemTriage = (
    action: 'accepted' | 'rejected',
    taskId?: TaskSchema['id'],
    actionItemId?: MeetingActionItemSchema['id']
  ) => {
    recordAnalyticsEvent('MEETING_INSIGHTS_ACTION_ITEM_TRIAGED', {
      action,
      isInMeetingInProject: mTask != null,
      taskId,
      actionItemId,
    })
  }

  const recordActionItemManuallyCreated = (
    taskId: TaskSchema['id'],
    actionItemId: MeetingActionItemSchema['id']
  ) => {
    recordAnalyticsEvent('MEETING_INSIGHTS_ACTION_ITEM_MANUALLY_CREATED', {
      isInMeetingInProject: mTask != null,
      taskId,
      actionItemId,
    })
  }

  const recordUserContentFeedback = (
    meetingInsightsId: MeetingInsightsSchema['id'],
    section: 'summary' | 'notes' | 'actionItems',
    feedback: 'positive' | 'negative'
  ) => {
    recordAnalyticsEvent('MEETING_INSIGHTS_CONTENT_FEEDBACK', {
      meetingInsightsId,
      section,
      feedback,
    })
  }

  const recordMeetingInsightsVisibilityChange = (
    meetingInsightsId: MeetingInsightsSchema['id'],
    visibility: MeetingInsightsSchema['visibility']
  ) => {
    recordAnalyticsEvent('MEETING_INSIGHTS_VISIBILITY_CHANGE', {
      meetingInsightsId,
      visibility,
    })
  }

  const recordSendBotNowCall = (
    meetingInsightsId: MeetingInsightsSchema['id']
  ) => {
    recordAnalyticsEvent('MEETING_INSIGHTS_SEND_BOT_NOW', {
      meetingInsightsId,
    })
  }

  const recordMeetingInsightsSettingsUpdate = (
    defaultVisibility?: MeetingInsightsVisibility
  ) => {
    recordAnalyticsEvent('MEETING_INSIGHTS_SETTINGS_UPDATE', {
      defaultVisibility,
    })
  }

  return {
    recordSendBotNowCall,
    recordActionItemTriage,
    recordActionItemManuallyCreated,
    recordUserContentFeedback,
    recordMeetingInsightsVisibilityChange,
    recordMeetingInsightsSettingsUpdate,
  }
}
