import { StackSolid } from '@motion/icons'
import { useDependantState } from '@motion/react-core/hooks'
import { type GROUP_BY_DATE_OPTIONS } from '@motion/rpc-types'
import { Button, PopoverTrigger } from '@motion/ui/base'

import {
  type GroupableField,
  noneField,
} from '~/areas/project-management/pages/pm-v3/grouping'
import { RowFieldSelect } from '~/areas/project-management/pages/pm-v3/header'
import { DateGroupOptionsSelect } from '~/areas/project-management/pages/pm-v3/header/group-by/date-group-options-select'
import { GroupByPopoverContext } from '~/areas/project-management/pages/pm-v3/header/group-by/group-by-popover-context'
import {
  useViewState,
  type ViewStateGroupByField,
} from '~/areas/project-management/pages/pm-v3/view-state'
import { useCallback, useMemo } from 'react'

import { isGroupByDateKey } from '../grouping'
import { MeetingInsightsGroupableFields } from '../types'

const groupableFields = [noneField, ...MeetingInsightsGroupableFields]

export function ConnectedGroupingButton() {
  const [viewState, setViewState] = useViewState()

  const handleOnChange = (value: GroupByValue) => {
    setViewState((prev) => ({
      ...prev,
      groupBy: {
        ...prev.groupBy,
        fields: value.fields.map((f) => ({
          key: f.id,
          by: f.by,
        })),
      },
    }))
  }

  const value = useMemo(() => {
    return {
      fields: viewState.groupBy.fields
        .map((f) => {
          const groupableField = groupableFields.find(
            (field) => field.id === f.key
          )

          if (!groupableField) {
            return null
          }

          return {
            ...groupableField,
            by: f.by,
          }
        })
        .filter(Boolean),
    }
  }, [viewState.groupBy.fields])

  return <GroupByButton value={value} onChange={handleOnChange} />
}

type SelectedGroupByField = GroupableField & Pick<ViewStateGroupByField, 'by'>
export type FieldWithNone = SelectedGroupByField | typeof noneField

export type GroupByValue = {
  // One item only for now
  fields: SelectedGroupByField[]
}

type GroupByButtonProps = {
  value: GroupByValue
  onChange: (value: GroupByValue) => void
}

function GroupByButton(props: GroupByButtonProps) {
  const isActive = props.value.fields.length > 0

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <GroupByButtonPopover {...props} close={close} />
      )}
    >
      <Button
        variant='outlined'
        sentiment={isActive ? 'primary' : 'neutral'}
        size='small'
      >
        <StackSolid />
        <div className='font-medium whitespace-nowrap max-w-xs'>
          {isActive
            ? `Group by: ${[...props.value.fields.map((f) => f.label)].join(
                ' › '
              )}`
            : `Group by: None`}
        </div>
      </Button>
    </PopoverTrigger>
  )
}

type GroupByButtonPopoverProps = {
  value: GroupByValue
  onChange: (value: GroupByValue) => void
  close: () => void
}

// Very simple group by right now
function GroupByButtonPopover({ value, onChange }: GroupByButtonPopoverProps) {
  const [fields, setFields] = useDependantState<FieldWithNone[]>(
    () => (value.fields.length > 0 ? value.fields : [noneField]),
    [value.fields]
  )

  const handleSetFields = useCallback(
    (values: FieldWithNone[]) => {
      setFields(values)

      if (values.length === 1 && values[0].id === 'none') {
        return onChange({
          fields: [],
        })
      }

      onChange({
        fields: values,
      })
    },
    [onChange, setFields]
  )

  const setFieldOption = useCallback(
    (id: string, by: GROUP_BY_DATE_OPTIONS) => {
      const newFields = fields.map((f) => (f.id === id ? { ...f, by } : f))
      handleSetFields(newFields)
    },
    [fields, handleSetFields]
  )

  const ctx = useMemo(
    () => ({
      fields,
      setFieldOption,
    }),
    [fields, setFieldOption]
  )

  return (
    <GroupByPopoverContext.Provider value={ctx as any}>
      <div className='bg-modal-bg text-semantic-neutral-text-default w-[320px] flex flex-col p-3 gap-3'>
        <div className='flex justify-between items-center'>
          <span className='text-sm'>Groups</span>
          <Button
            onClick={() => handleSetFields([])}
            sentiment='neutral'
            variant='muted'
            size='small'
          >
            Reset
          </Button>
        </div>
        <div className='flex flex-col gap-2'>
          {fields.map((field, index) => {
            const selected =
              groupableFields.find((f) => f.id === field.id) ??
              groupableFields[0]

            const onSelect = (selected: GroupableField) => {
              const newFields = [...fields]
              newFields[index] = selected
              handleSetFields(newFields)
            }

            return (
              <div className='flex gap-1 justify-between' key={field.id}>
                <RowFieldSelect
                  selected={selected}
                  available={groupableFields}
                  onSelect={onSelect}
                />
                {isGroupByDateKey(selected.id) && (
                  <DateGroupOptionsSelect id={selected.id} />
                )}
              </div>
            )
          })}
        </div>
      </div>
    </GroupByPopoverContext.Provider>
  )
}
