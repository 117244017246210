import { type Schedule } from '@motion/rpc-types'
import { type OnboardingType } from '@motion/web-common/onboarding'
import { type RecurringTaskSchema } from '@motion/zod/client'

import { IndividualOrTeam } from '../../../components/ProjectManagement/types'
import { type AuthSettings } from '../../../types/authTypes'
import { type MotionTheme } from '../../../types/settingsTypes'

export { IndividualOrTeam }
export type { OnboardingType }
export type { MotionTheme }

export type OnboardingRecurringTask = Pick<
  RecurringTaskSchema,
  | 'days'
  | 'deadlineType'
  | 'duration'
  | 'frequency'
  | 'name'
  | 'timeStart'
  | 'timeEnd'
>

// <email, AuthSettings>
/**
 * @deprecated remove when kill firestore - onboarding is complete
 */
export type Accounts = Record<string, AuthSettings>
// <email, CalendarListEntry>

export type Contact = { name: string; account: string; email: string }
export type Contacts = Contact[]

// <day | night | work | anytime, Schedule>
export type Schedules = Record<string, Schedule>

export type Workspace = {
  workspaceId?: string
  workspaceName: string
  projectId?: string
  projectName: string
  tasks: Task[]
}

export type Task = {
  id: string
  taskId?: string
  description: string
  deadlineISO: string
  durationMinutes: number
}

export const validOnboardingScreens = [
  'individual_or_team_prompt',
  'billing',
  '1_min_video',
  'connect_calendar',
  'select_main_calendar',
  'select_my_calendars',
  'choose_work_hours',
  'pm_tm_video',
  'create_team',
  'create_recurring_tasks',
  'create_first_workspace',
  'create_first_project',
  'create_first_tasks',
  'choose_theme',
  'billing_interception',
  'mobile_billing',
  'team_interception',
  'kyc_company_questionnaire',
  'verify_email',
] as const

export type OnboardingScreen = (typeof validOnboardingScreens)[number]

export type TaskCreateObject = {
  title: string
  duration: number
  deadline: string
}
