import { useCallback, useContext } from 'react'

import { type Column, TreeListOptionsContext } from './context'

export function useTreeListOptions() {
  const ctx = useContext(TreeListOptionsContext)

  if (ctx == null) {
    throw new Error('Missing tree list options context')
  }

  return ctx
}

export function useColumnVisibility() {
  const ctx = useTreeListOptions()

  return useCallback(
    (column: Column) => ctx.columnsVisibility[column],
    [ctx.columnsVisibility]
  )
}
