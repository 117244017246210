import { entries } from '@motion/utils/object'

import { useSearchParams } from '~/routing'
import { useCallback } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import {
  type FlowTemplateModalUrlProps,
  type FlowTemplateUrlSearchParams,
} from '../utils'

export function useFlowTemplateModalUrl() {
  const { workspaceId: workspaceIdParam } =
    useParams<FlowTemplateModalUrlProps>()
  const { pathname: currentPath, search } = useLocation()

  return useCallback(
    (params: FlowTemplateUrlSearchParams = {}) => {
      const { pathName, template, templateId, ...rest } = params
      const finalPath = pathName ?? currentPath

      const urlParams = new URLSearchParams(search)
      urlParams.delete('task')
      urlParams.delete('project')

      const newParams: FlowTemplateUrlSearchParams = {
        ...rest,
        // replace params based on the current route params
        forWorkspace:
          params.forWorkspace === workspaceIdParam
            ? undefined
            : params.forWorkspace,
        template:
          template == null && templateId != null ? 'edit' : (template ?? 'new'),
        templateId,
      }

      for (const [key, value] of entries(newParams)) {
        if (value) {
          urlParams.set(key, value)
        }
      }

      return `${finalPath}?${urlParams.toString()}`
    },
    [currentPath, search, workspaceIdParam]
  )
}

export const useFlowTemplateModalUrlParams = () => {
  const { workspaceId: workspaceIdParam } =
    useParams<FlowTemplateModalUrlProps>()
  const {
    forDuplicate,
    template,
    templateId,
    forWorkspace,
    fromPresetId,
    isEnterprise,
  } = useSearchParams<FlowTemplateUrlSearchParams>()

  return {
    // This can be undefined, e.g. when creating a new template from "all tasks"
    // Use watch() to get the value from the form
    workspaceId: forWorkspace ?? workspaceIdParam,
    forDuplicate,
    template,
    templateId,
    fromPresetId,
    isEnterprise: isEnterprise === 'true',
  }
}
