import { classed } from '@motion/theme'
import { isMeetingTask } from '@motion/ui-logic/pm/task'

import { MotionLink } from '~/global/components'
import { useUriByGlobalModalId } from '~/global/navigation'
import { type TaskWithRelations } from '~/global/proxies'
import { useNavigate } from 'react-router'

import {
  TaskLineAssigneeItem,
  TaskLineAutoScheduleItem,
  TaskLineDeadlineItem,
  TaskLineDurationItem,
  TaskLinePriorityItem,
  TaskLineStatusItem,
} from './components'

import { useColumnVisibility } from '../../contexts'

export type TaskLineItemProps = {
  task: TaskWithRelations
}

export function TaskLineItem({ task }: TaskLineItemProps) {
  const navigate = useNavigate()
  const getModalUri = useUriByGlobalModalId()
  const isColumnVisible = useColumnVisibility()

  const taskLink = getModalUri('task', {
    task: task.id,
    isMeeting: isMeetingTask(task),
  })

  return (
    <Wrapper onClick={() => navigate(taskLink)}>
      <TaskLineStatusItem task={task} />
      <MotionLink
        className='flex-1 truncate text-semantic-neutral-text-default text-sm ml-1 hover:text-inherit'
        url={taskLink}
      >
        {task.name}
      </MotionLink>

      {isColumnVisible('deadline') && <TaskLineDeadlineItem task={task} />}
      {isColumnVisible('autoschedule') && (
        <TaskLineAutoScheduleItem task={task} />
      )}
      {isColumnVisible('duration') && <TaskLineDurationItem task={task} />}
      {isColumnVisible('priority') && <TaskLinePriorityItem task={task} />}
      {isColumnVisible('assignee') && <TaskLineAssigneeItem task={task} />}
    </Wrapper>
  )
}

const Wrapper = classed('div', {
  base: `
  flex flex-row items-center gap-1
  px-1 h-full
  rounded
  cursor-pointer

  hover:bg-semantic-neutral-bg-hover
  `,
})
