import { DotsVerticalSolid } from '@motion/icons'
import { IconButton, PopoverTrigger } from '@motion/ui/base'
import { isMeetingTask } from '@motion/ui-logic/pm/task'
import { type TaskSchema } from '@motion/zod/client'

import { EventActionList } from '~/areas/event/components'
import { TaskActionList } from '~/areas/tasks/components'
import { twMerge } from 'tailwind-merge'

type Props = {
  task: TaskSchema
  disabled?: boolean
}

export function TaskLineMenu({ task, disabled = false }: Props) {
  return (
    <div
      className={twMerge(
        'flex items-center',
        'invisible',
        !disabled &&
          'group-hover/task-line:visible group-focus-within/task-line:visible'
      )}
    >
      <PopoverTrigger
        placement='right-start'
        renderPopover={({ close }) =>
          isMeetingTask(task) ? (
            <EventActionList close={close} eventId={task.meetingEventId} />
          ) : (
            <TaskActionList close={close} task={task} />
          )
        }
      >
        <IconButton
          size='xsmall'
          variant='muted'
          sentiment='neutral'
          icon={DotsVerticalSolid}
          disabled={disabled}
        />
      </PopoverTrigger>
    </div>
  )
}
