import { type COLOR, COLORS } from '@motion/shared/common'
import { Button, SearchableList } from '@motion/ui/base'

import { ColorItemBadge } from '~/global/components/badges'
import { ColorLabel } from '~/global/components/labels'
import { useCallback } from 'react'

import { FilterItem } from './filter-item/filter-item'

import { useFieldFilter } from '../../../context'
import { type DropdownFilterProps } from '../../types'

export function ConnectedColorFilter({
  onBlur,
  openOnMount,
}: DropdownFilterProps) {
  const [value, setValue] = useFieldFilter('projects', 'color')
  const colors: COLOR[] | null = value?.value ?? null

  return (
    <FilterItem
      label='Color'
      canInvert
      inverted={value?.inverse ?? false}
      onInvertChanged={(invert) => {
        if (value == null) return
        setValue({ ...value, inverse: invert })
      }}
      onBlur={onBlur}
      openOnMount={openOnMount}
      onDismiss={() => setValue(null)}
      renderDropdown={({ close }) => <ConnectedColorDropdown close={close} />}
    >
      {colors?.length === 1 ? (
        <ColorLabel color={colors[0]} />
      ) : (
        <MultipleColorLabel colors={colors ?? []} />
      )}
    </FilterItem>
  )
}

function MultipleColorLabel({ colors }: { colors: COLOR[] }) {
  return (
    <div className='flex flex-row'>
      {colors.map((color) => (
        <ColorItemBadge key={color} color={color} />
      ))}
    </div>
  )
}

type ConnectedColorDropdownProps = {
  close(): void
}

function ConnectedColorDropdown({ close }: ConnectedColorDropdownProps) {
  const [filter, setFilter] = useFieldFilter('projects', 'color')

  const computeSelected = useCallback(
    (value: COLOR) => filter?.value.includes(value) ?? false,
    [filter]
  )

  return (
    <div>
      <SearchableList
        itemType='checkbox'
        items={[...COLORS]}
        renderItem={(color) => <ColorLabel color={color} />}
        searchable={false}
        computeSelected={computeSelected}
        onSelect={(values) => {
          setFilter({
            operator: 'in',
            inverse: filter?.inverse ?? false,
            value: values,
          })
        }}
        computeKey={(item) => item}
      />
      <div className='border-t border-dropdown-border p-2 flex justify-between'>
        <Button
          onClick={() => {
            setFilter(null)
            close()
          }}
          variant='outlined'
          sentiment='neutral'
          size='small'
        >
          Clear
        </Button>
        <Button
          onClick={() => {
            setFilter({
              operator: 'in',
              inverse: filter?.inverse ?? false,
              value: [...COLORS],
            })
          }}
          variant='outlined'
          sentiment='neutral'
          size='small'
        >
          Select all
        </Button>
      </div>
    </div>
  )
}
