import type FullCalendar from '@fullcalendar/react'
import { memo, useEffect, useRef } from 'react'

import { useTimeslotMainCalendarProps } from './hooks/use-timeslot-calendar-props'

import { Calendar } from '../../components/calendar/calendar'
import { useCalendarState } from '../../hooks'
import { microTask } from '../../utils/micro-task'

export const TimeSlotCalendar = memo(function TimeSlotCalendar() {
  const calendarRef = useRef<FullCalendar | null>(null)

  const selectedDate = useCalendarState((state) => state.selectedDate)
  const calendarProps = useTimeslotMainCalendarProps()

  useEffect(
    function handleBaseDateChange() {
      const baseDate = selectedDate.toJSDate()
      // Micro task is needed due to a rendering quirk of FullCalendar, should be fixed in newer versions.
      microTask(() => {
        calendarRef.current?.getApi().gotoDate(baseDate)
      })
    },
    [selectedDate]
  )

  return <Calendar ref={calendarRef} calendarProps={calendarProps} />
})
