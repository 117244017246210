import { omit } from '@motion/utils/core'

import { createKey, defineApi, defineMutation } from '../../core'
import { type RouteTypes } from '../types'

/**
 * @deprecated
 */
type GetProjectDefinitionByIdContext =
  RouteTypes<'DefinitionsV1Controller_getProjectDefinitionById'>
export const queryKeys = {
  root: createKey(['v2', 'projectDefinitions']),
  query: (args: Pick<GetProjectDefinitionByIdContext['request'], 'id'>) =>
    createKey(queryKeys.root, args.id),
}

/**
 * @deprecated
 */
export const getById = defineApi<
  GetProjectDefinitionByIdContext['request'],
  GetProjectDefinitionByIdContext['response']
>().using({
  method: 'GET',
  key: (args) => queryKeys.query(args),
  uri: (args) =>
    `/v2/workspaces/${args.workspaceId}/templates/projects/${args.id}`,
})

/**
 * @deprecated
 */
export const getLazyById = defineApi<
  GetProjectDefinitionByIdContext['request'],
  GetProjectDefinitionByIdContext['response']
>().using({
  key: (args) => ['lazy', ...queryKeys.query(args)],
  uri: getById.uri,
  queryOptions: {
    staleTime: 0,
    cacheTime: 0,
  },
})

/**
 * @deprecated
 */
type CreateProjectDefinitionContext =
  RouteTypes<'DefinitionsV1Controller_createProjectDefinition'>
/**
 * @deprecated
 */
export const create = defineMutation<
  CreateProjectDefinitionContext['request'],
  CreateProjectDefinitionContext['response']
>().using({
  method: 'POST',
  uri: (args) => `/v2/workspaces/${args.workspaceId}/templates/projects`,
  body: (args) => omit(args, 'workspaceId'),
})

/**
 * @deprecated
 */
type UpdateProjectDefinitionContext =
  RouteTypes<'DefinitionsV1Controller_updateProjectDefinition'>
/**
 * @deprecated
 */
export const update = defineMutation<
  UpdateProjectDefinitionContext['request'],
  UpdateProjectDefinitionContext['response']
>().using({
  method: 'PUT',
  uri: (args) =>
    `/v2/workspaces/${args.workspaceId}/templates/projects/${args.id}`,
  body: (args) => omit(args, ['workspaceId', 'id']),
})

/**
 * @deprecated
 */
type DeleteProjectDefinitionContext =
  RouteTypes<'DefinitionsV1Controller_deleteProjectDefinition'>
/**
 * @deprecated
 */
export const deleteProjectDefinition = defineMutation<
  DeleteProjectDefinitionContext['request'],
  void
>().using({
  method: 'DELETE',
  uri: (args) =>
    `/v2/workspaces/${args.workspaceId}/templates/projects/${args.id}`,
})

/**
 * @deprecated
 */
type CopyProjectDefinitionContext =
  RouteTypes<'DefinitionsV1Controller_copyProjectDefinition'>
/**
 * @deprecated
 */
export const copyProjectDefinition = defineMutation<
  CopyProjectDefinitionContext['request'],
  CopyProjectDefinitionContext['response']
>().using({
  method: 'POST',
  uri: (args) =>
    `/v2/workspaces/${args.workspaceId}/templates/projects/copy/${args.id}`,
})

/**
 * @deprecated
 */
type GetGalleryDefinitions =
  RouteTypes<'DefinitionsV1Controller_getGalleryProjectDefinitions'>
/**
 * @deprecated
 */
export const getGallery = defineApi<
  {
    workspaceId: string
  },
  GetGalleryDefinitions['response']
>().using({
  method: 'GET',
  uri: (args) =>
    `/v2/workspaces/${args.workspaceId}/templates/projects/gallery`,
  key: createKey(queryKeys.root, 'gallery'),
})
