import { type AssignmentSchema, type MeetingInsights } from '@motion/rpc-types'

import { PermissionPopover } from './permission-popover'
import { type Invitee } from './types'

import { useUpdateMeetingInsights } from '../hooks/rpc/use-update-meeting-insights'

type ConnectedPermissionPopoverProps = {
  meetingInsights: MeetingInsights
}

export function ConnectedPermissionPopover({
  meetingInsights,
}: ConnectedPermissionPopoverProps) {
  const { mutateAsync: updateMeetingInsights } = useUpdateMeetingInsights()

  const invitees =
    meetingInsights.assignments?.flatMap((assignment) =>
      assignment.targetIds.map((id) => ({
        id,
        type: assignment.type,
        action: assignment.action,
      }))
    ) ?? []

  function handleIdsChange(type: AssignmentSchema['type'], ids: string[]) {
    const copy =
      meetingInsights.assignments != null
        ? [...meetingInsights.assignments]
        : []

    const existingAssignment = copy.find((a) => a.type === type)

    if (existingAssignment) {
      existingAssignment.targetIds = ids
    } else {
      copy.push({ type, targetIds: ids, action: 'read' })
    }

    updateMeetingInsights({
      assignments: copy,
      meetingInsightsId: meetingInsights.id,
    })
  }

  function handleRemove(invitee: Invitee) {
    const copy =
      meetingInsights.assignments != null
        ? [...meetingInsights.assignments]
        : []

    copy.forEach((a) => {
      a.targetIds = a.targetIds.filter((id) => id !== invitee.id)
    })

    updateMeetingInsights({
      assignments: copy,
      meetingInsightsId: meetingInsights.id,
    })
  }

  return (
    <PermissionPopover
      invitees={invitees}
      onSelected={handleIdsChange}
      onRemove={handleRemove}
      placement='bottom-end'
    />
  )
}
