import {
  DeadlineSolid,
  ExternalLinkOutline,
  PlayButtonSolid,
  ProjectCubeSolid,
  StartDateSolid,
  StopSolid,
} from '@motion/icons'
import { ActionList, Button, PopoverTrigger } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useAgendaTaskActions } from '~/areas/agenda/hooks'
import {
  DeadlineDateDropdownContent,
  StartDateDropdownContent,
} from '~/areas/project-management/components'
import { useIsTaskStarted } from '~/areas/tasks/hooks'

import { type ActionsButtonProps } from './types'

type TaskItemButtonDropdownProps = ActionsButtonProps

export function TaskItemActionsDropdown(props: TaskItemButtonDropdownProps) {
  return (
    <PopoverTrigger
      placement='left-start'
      renderPopover={({ close }) => (
        <TaskItemActionList {...props} closePopover={close} />
      )}
    >
      <Button
        variant='outlined'
        size='small'
        sentiment='neutral'
        onClick={(e) => {
          recordAnalyticsEvent('AGENDA_TASK_TASK_ACTIONS_DROPDOWN_OPENED')
        }}
      >
        Task actions
      </Button>
    </PopoverTrigger>
  )
}

type TaskItemActionListProps = TaskItemButtonDropdownProps & {
  closePopover: () => void
}

function TaskItemActionList({
  task,
  project = null,
  showOpenTask = true,
  showOpenProject = project != null,
  showChangeStartDate = true,
  showChangeDeadline = true,
  showStartTask = task.completedTime == null,
  closePopover,
}: TaskItemActionListProps) {
  const {
    openTask,
    openProject,
    changeStartDate,
    changeDeadline,
    startTask,
    stopTask,
  } = useAgendaTaskActions({
    taskId: task.id,
    projectId: project?.id,
  })

  const { isStarted } = useIsTaskStarted(task.id)

  return (
    <ActionList
      onActionAnyItem={closePopover}
      items={[
        showOpenTask && {
          prefix: <ExternalLinkOutline />,
          content: 'Open task',
          onAction: openTask,
        },
        showOpenProject &&
          project != null && {
            prefix: <ProjectCubeSolid />,
            content: 'Open project',
            onAction: openProject,
          },
        showChangeStartDate && {
          prefix: <StartDateSolid />,
          content: 'Change start date',
          renderPopover: () => (
            <StartDateDropdownContent
              value={task.startDate}
              onChange={(value) => {
                changeStartDate(value)
                close()
              }}
              contextProps={{ taskId: task.id }}
              dropdownTarget='task'
            />
          ),
        },
        showChangeDeadline && {
          prefix: <DeadlineSolid />,
          content: 'Change deadline',
          renderPopover: () => (
            <DeadlineDateDropdownContent
              value={task.dueDate}
              onChange={(value) => {
                changeDeadline(value)
                close()
              }}
              contextProps={{ taskId: task.id }}
              dropdownTarget='task'
            />
          ),
        },
        showStartTask &&
          (isStarted
            ? {
                prefix: <StopSolid />,
                content: 'Stop task',
                onAction: stopTask,
              }
            : {
                prefix: <PlayButtonSolid />,
                content: 'Start task',
                onAction: startTask,
              }),
      ]}
    />
  )
}
