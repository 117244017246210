import { type DeadlineStatus } from '@motion/shared/common'
import { Button } from '@motion/ui/base'
import { normalizeProjectDeadlineStatus } from '@motion/ui-logic'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useHasTreatment } from '@motion/web-common/flags'
import { type ProjectSchema, type StageSchema } from '@motion/zod/client'

import { OptimizeProjectButton } from '~/areas/eta/components/common/optimize-project-button'
import { ProjectEtaPopover } from '~/areas/eta/components/popover'
import { useProject } from '~/global/hooks'

import { useGetRibbonDetails } from './hooks'
import { MultipleStagesNeedAttentionDropdown } from './multiple-stages-need-attention-dropdown'
import { LabelContainer, LabelText, RibbonContainer } from './styled'

type ProjectEtaRibbonProps = {
  projectId: ProjectSchema['id']
}

export function ProjectEtaRibbon({ projectId }: ProjectEtaRibbonProps) {
  const hasPastDueM2 = useHasTreatment('past-due-m2')
  const project = useProject(projectId)
  const deadlineStatus = normalizeProjectDeadlineStatus(project)

  const { sentiment, label, badge, stagesNeedAttention } =
    useGetRibbonDetails(project)

  if (!hasPastDueM2 || project == null) {
    return null
  }

  if (sentiment == null || label == null || badge == null) return null

  const showResolveButton =
    sentiment === 'error' || sentiment === 'warning' || sentiment === 'notice'
  const showOptimizeButton = !showResolveButton && sentiment === 'primary'

  return (
    <RibbonContainer sentiment={sentiment}>
      {badge}
      <LabelContainer>
        <LabelText sentiment={sentiment === 'info' ? sentiment : 'none'}>
          {label}
        </LabelText>
        {showResolveButton && (
          <ResolveButton
            stagesThatNeedAttention={stagesNeedAttention}
            deadlineStatus={deadlineStatus}
            project={project}
          />
        )}
        {showOptimizeButton && (
          <OptimizeProjectButton
            type='button'
            variant='link'
            onClick={() => {
              recordAnalyticsEvent('ETA_OPEN_OPTIMIZE_MODAL', {
                source: 'modal-banner',
                eta: deadlineStatus,
                type: 'project',
              })
            }}
            source='modal-banner'
            sentiment='primary'
            size='small'
            project={project}
          />
        )}
      </LabelContainer>
    </RibbonContainer>
  )
}

type ResolveButtonProps = {
  deadlineStatus: DeadlineStatus
  stagesThatNeedAttention: StageSchema[]
  project: ProjectSchema
}

function ResolveButton({
  deadlineStatus,
  stagesThatNeedAttention,
  project,
}: ResolveButtonProps) {
  const shouldShowMultipleStagesDropdown = deadlineStatus === 'at-risk'

  const button = (
    <Button
      type='button'
      variant='link'
      sentiment='primary'
      size='small'
      onClick={() => {
        if (shouldShowMultipleStagesDropdown) {
          recordAnalyticsEvent('ETA_OPEN_MULTI_STAGE_ETA_POPOVER')
          return
        }

        recordAnalyticsEvent('ETA_OPEN_POPOVER', {
          source: 'modal-banner',
          eta: deadlineStatus,
          type: 'project',
        })
      }}
    >
      Resolve
    </Button>
  )

  return shouldShowMultipleStagesDropdown ? (
    <MultipleStagesNeedAttentionDropdown
      stagesThatNeedAttention={stagesThatNeedAttention}
    >
      {button}
    </MultipleStagesNeedAttentionDropdown>
  ) : (
    <ProjectEtaPopover
      entity={project}
      config={{ hideEditButton: true }}
      source='modal-banner'
    >
      {button}
    </ProjectEtaPopover>
  )
}
