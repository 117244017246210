import {
  EyeSolid,
  MenuAlt2Outline,
  PlannerSolid,
  type SvgIcon,
  TemplateSolid,
  ViewBoardsOutline,
} from '@motion/icons'
import type { VersionedViewV2 } from '@motion/zod/client'

export function getViewIcon(view: VersionedViewV2): SvgIcon {
  if (view.type === 'team-schedule') {
    return EyeSolid
  }

  if (view.definition.type === 'dashboard') {
    return TemplateSolid
  }

  switch (view.definition.layout) {
    case 'kanban':
      return ViewBoardsOutline

    case 'gantt':
    case 'planner':
      return PlannerSolid

    default:
      return MenuAlt2Outline
  }
}
