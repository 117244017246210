import { classed } from '@motion/theme'

export const EmptyTableCard = classed('div', {
  base: `
    flex flex-col items-center justify-center gap-4
    rounded border border-dashed border-semantic-neutral-border-strong bg-semantic-neutral-surface-raised-bg-default
    w-full p-4
  `,
})

export const CardTitle = classed('span', {
  base: `text-semantic-neutral-text-default text-sm font-semibold truncate min-h-4`,
})

export const CardDescription = classed('span', {
  base: `
    text-semantic-neutral-text-subtle
    text-center text-xs font-normal
  `,
})

export const ProjectCountBadge = classed('span', {
  base: `flex pl-1 pr-1.5 justify-center items-center gap-1
    rounded-xl bg-semantic-neutral-surface-raised-bg-subtle
    text-2xs text-semantic-neutral-text-subtle
    h-4 leading-4 cursor-pointer
  `,
})

export const FlowCard = classed('div', {
  base: `
    flex flex-col gap-4 items-start flex-shrink-0 p-4 w-full h-full rounded-lg
    border border-semantic-neutral-border-default bg-semantic-neutral-surface-raised-bg-default
    overflow-hidden
  `,
})

export const FlowCardHeader = classed('div', {
  base: `flex justify-between items-center self-stretch`,
})

export const CardList = classed('div', {
  base: `flex flex-col gap-1 items-start self-stretch`,
  variants: {
    isRow: {
      true: `flex-row`,
      false: ``,
    },
  },
  defaultVariants: {
    isRow: false,
  },
})
