import { type EventConferenceType } from '@motion/rpc-types'
import {
  type NormalTaskSchema,
  type RecurringTaskSchema,
  type TaskSchema,
} from '@motion/zod/client'

export function validateTask(
  id: string | null | undefined,
  task: TaskSchema | RecurringTaskSchema | null | undefined,
  loading: boolean
): task is NormalTaskSchema {
  if (id != null && !loading && task == null) {
    return false
  }

  if (task != null && task.type !== 'NORMAL') {
    return false
  }

  return true
}

export function isAiBotSupportedConference(
  conferenceType: EventConferenceType | null | undefined
) {
  return (
    conferenceType === 'hangoutsMeet' ||
    conferenceType === 'zoom' ||
    conferenceType === 'teamsForBusiness'
  )
}
