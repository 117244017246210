import 'react-grid-layout/css/styles.css'
import './grid-layout.css'

import { useMemoDeep } from '@motion/react-core/hooks'
import { type DashboardViewCellSchema } from '@motion/rpc-types'
import { cloneDeep, isEqual } from '@motion/utils/core'

import { useState } from 'react'
import { Responsive, WidthProvider } from 'react-grid-layout'

import { AddChartTile, Controls } from './components'
import { GridTile } from './grid-tile'
import { useViewToLayouts } from './hooks'
import { type TileLayout } from './utils'
import { chartComponentLookup } from './utils/lookups'

import { useDashboardViewState } from '../view-state'

/**
 * This breakpoint is the width of the react-grid-layout container, not viewport
 */
const breakpoints: Record<TileLayout, number> = {
  flow: 0,
  grid: 700,
}

const cols: Record<TileLayout, number> = {
  flow: 2,
  grid: 12,
}

const rowHeight: Record<TileLayout, number> = {
  flow: 200,
  grid: 200,
}

declare module 'react-grid-layout' {
  interface Layout {
    cell?: DashboardViewCellSchema
  }
}

const ResponsiveGridLayout = WidthProvider(Responsive)

export function DashboardGridLayout() {
  const [currentBreakpoint, setCurrentBreakpoint] = useState<TileLayout>('grid')

  const [{ view: selectedRaw }, setViewState] = useDashboardViewState()
  const selected = useMemoDeep(selectedRaw)
  const layouts = useViewToLayouts(selected)

  if (layouts == null || selected.type !== 'dashboard') return null

  const handleBreakPointChange = (newBreakpoint: TileLayout) => {
    setCurrentBreakpoint(newBreakpoint)
  }
  const currentLayout = layouts[currentBreakpoint]

  return (
    <ResponsiveGridLayout
      layouts={layouts}
      rowHeight={rowHeight[currentBreakpoint]}
      breakpoints={breakpoints}
      useCSSTransforms
      cols={cols}
      resizeHandles={currentBreakpoint === 'flow' ? [] : ['se', 'sw']}
      autoSize={false}
      className='dashboard-grid-layout'
      margin={[16, 16]}
      compactType='vertical'
      onBreakpointChange={handleBreakPointChange}
      draggableHandle='.drag-handle'
      onLayoutChange={(layout, allLayouts) => {
        // ignore changes for 'flow' layouts
        if (layout === allLayouts.flow) {
          return
        }

        const cloned = cloneDeep(selected)

        cloned.cells = layout
          .map((l) => {
            const cell = cloned.cells.find((x) => x.id === l.i)
            if (!cell) return
            cell.x = l.x
            cell.y = l.y
            cell.width = l.w
            cell.height = l.h
            return cell
          })
          .filter(Boolean)
        if (isEqual(cloned, selected)) return

        setViewState((prev) => ({
          ...prev,
          view: cloned,
        }))
      }}
    >
      {currentLayout.map((c) => {
        if (c.i === 'add-card') {
          return (
            <div key='add-card'>
              <AddChartTile />
            </div>
          )
        }
        const cell = c.cell
        if (cell == null) return null

        const Chart = chartComponentLookup(cell.chart.type)
        if (Chart == null) return null
        return (
          <GridTile
            cell={cell}
            key={c.i}
            renderContent={() => <Chart chart={cell.chart} />}
            renderControls={() => <Controls cell={cell} />}
          />
        )
      })}
    </ResponsiveGridLayout>
  )
}
