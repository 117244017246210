import { ScheduleSolid } from '@motion/icons'
import { PopoverTrigger } from '@motion/ui/base'
import { useAuthenticatedUser } from '@motion/web-common/auth'
import { useFirestoreSettings } from '@motion/web-common/settings'

import { ModalFieldButton } from '~/areas/task-project/components'
import {
  ScheduleDropdownContent,
  type ScheduleDropdownContentProps,
} from '~/global/components/dropdowns'
import { useController } from 'react-hook-form'

import { useActionItemForm } from '../hooks'

export const ScheduleField = () => {
  const { form } = useActionItemForm()
  const { data: firestoreSettings, refetch } = useFirestoreSettings()

  const { uid: userId } = useAuthenticatedUser()

  const fsSchedules = firestoreSettings?.schedules ?? {}

  const { watch } = form

  const selectedScheduleId = watch('scheduleId')
  const assigneeId = watch('assigneeUserId')

  const schedules = fsSchedules
  const selectedSchedule =
    selectedScheduleId != null
      ? schedules[selectedScheduleId as keyof typeof schedules]
      : null

  const isOwnTask = userId != null && userId === assigneeId

  if (!isOwnTask) {
    return null
  }

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <ScheduleFieldContent
          close={close}
          onUpdate={refetch}
          isRecurringTaskOrInstance={false}
        />
      )}
    >
      <ModalFieldButton label='Schedule'>
        <ScheduleSolid />
        {selectedSchedule?.title}
      </ModalFieldButton>
    </PopoverTrigger>
  )
}

type ScheduleFieldContentProps = {
  close: () => void
  onUpdate: () => void
  isRecurringTaskOrInstance: boolean
}

function ScheduleFieldContent({
  close,
  onUpdate,
  isRecurringTaskOrInstance,
}: ScheduleFieldContentProps) {
  const { form } = useActionItemForm()

  const { watch } = form

  const { field } = useController({
    name: 'scheduleId',
    control: form.control,
    rules: {
      required: true,
    },
  })

  const selectedScheduleId = watch('scheduleId')

  const onChange: ScheduleDropdownContentProps['onChange'] = (scheduleId) => {
    field.onChange(scheduleId)
  }

  return (
    <ScheduleDropdownContent
      close={close}
      selectedScheduleId={selectedScheduleId ?? ''}
      showCustomSchedule={isRecurringTaskOrInstance}
      onChange={onChange}
      onUpdate={onUpdate}
    />
  )
}
