import { type CalendarEventSchemaV2 } from '@motion/rpc-types'
import { PopoverButton, PopoverTrigger, SearchableList } from '@motion/ui/base'
import { getColorIdFromColor, getColorName } from '@motion/ui/palette'

import { useEventColorData } from '~/areas/event/hooks'
import { ColorLabel } from '~/global/components/labels'
import { useController } from 'react-hook-form'

import { useEventModalState } from '../contexts'
import { useEventForm } from '../hooks'

export const ControlledEventColorField = () => {
  const { isLoading, isReadOnly, initialMeetingTask } = useEventModalState()
  const { form } = useEventForm()
  const { control, watch } = form

  const { colorOptions, eventColor } = useEventColorData({
    id: watch('id') ?? '',
    colorId: watch('colorId'),
    email: watch('email'),
    calendarId: watch('calendarId'),
    meetingTaskId: initialMeetingTask?.id,
  })

  const { field } = useController({
    name: 'colorId',
    control,
  })

  const onChange = (item: CalendarEventSchemaV2['colorId']) => {
    field.onChange(item)
  }

  if (isLoading || colorOptions.length === 0) return null

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <SearchableList
          items={colorOptions}
          renderItem={(item) => <ColorLabel color={item} />}
          computeKey={(item) => item}
          computeSearchValue={(item) => getColorName(item)}
          computeSelected={(item) => item === eventColor}
          onSelect={(item) => {
            const colorId = getColorIdFromColor(item)
            if (colorId == null) {
              onChange(null)
            } else {
              onChange(colorId)
            }
            close()
          }}
        />
      )}
    >
      <PopoverButton
        aria-label='Event color'
        readOnly={isReadOnly}
        size='small'
      >
        <ColorLabel color={eventColor} />
      </PopoverButton>
    </PopoverTrigger>
  )
}
