import { NotFoundPage } from '~/components/MotionTab/NotFoundPage'
// eslint-disable-next-line import-x/no-restricted-paths
import { dashboardRoute } from '~/pages/pm/dashboard/route'
import { type MotionRoute } from '~/routing/api'
import { matchPath } from 'react-router'

import { getSavedState, loadState } from './loader'

import { ConnectedProjectKanban } from '../../pages/connected-project-kanban'
import { ConnectedProjectList } from '../../pages/connected-project-list'
import { ConnectedProjectPlanner } from '../../pages/connected-project-planner'
import { ConnectedTaskKanban } from '../../pages/connected-task-kanban'
import { ConnectedTaskList } from '../../pages/connected-task-list'
import { ShellWithContext } from '../../shell'
import { RootV3 } from '../../shell/root'
import { type RouteData } from '../types'
import { redirectLoader, relativeRedirect } from '../utils/redirect'

declare module '~/routing/hooks/navigate-by-id-params' {
  interface NavigateByIdParams {
    parent: {
      type?: 'tasks' | 'projects'
      variant?: 'list' | 'kanban' | 'gantt'
    }
    'all-tasks':
      | {
          type?: 'tasks' | 'projects'
          variant?: 'list' | 'kanban' | 'gantt'
        }
      | undefined
    'my-tasks':
      | {
          type?: 'tasks' | 'projects'
          variant?: 'list' | 'kanban' | 'gantt'
        }
      | undefined
    'all-projects':
      | {
          type?: 'projects'
          variant?: 'list' | 'kanban' | 'gantt'
        }
      | undefined
    'my-tasks-archive': undefined
    'workspace-detail': {
      workspaceId: string
      type?: 'tasks' | 'projects'
      variant?: 'list' | 'kanban' | 'gantt'
    }
    'workspace-project': {
      workspaceId: string
      projectId: string
      type?: 'tasks' | 'projects'
      variant?: 'list' | 'kanban' | 'gantt'
    }
    'workspace-archive': {
      workspaceId: string
    }
    'team-schedule': undefined
    'workspace-folder':
      | {
          type?: 'folders'
          variant?: 'list' | 'kanban' | 'gantt'
        }
      | undefined
    'notes-detail': { noteId: string }
  }
}

const taskRoutes: MotionRoute[] = [
  {
    path: '',
    loader: async (data) => {
      const params = data.params as {
        workspaceId: string
        projectId?: string
        folderId?: string
      }

      const url = new URL(data.request.url)
      const w = matchPath('/web/pm/:pageId', url.pathname)

      const pageId = w && w.params.pageId
      const stateKey =
        pageId === 'all-tasks'
          ? 'all-tasks'
          : pageId === 'all-projects'
            ? 'all-projects'
            : pageId === 'my-tasks'
              ? 'my-tasks'
              : params.projectId
                ? `project_${params.projectId}`
                : params.folderId
                  ? `folder_${params.folderId}`
                  : `workspace_${params.workspaceId}`

      const { type, page } = await getSavedState(stateKey, {
        featureFlags: data.featureFlags,
      })

      if (page === 'dashboard') {
        return relativeRedirect(data, `./dashboard`)
      }

      return relativeRedirect(data, `./${type}/${page}`)
    },
  },
  {
    path: 'tasks?',
    loader: () => ({ type: 'tasks' }) satisfies Partial<RouteData>,
    params: {
      type: 'tasks',
    },
    children: [
      {
        path: 'list?',
        Component: ConnectedTaskList,
        loader: () => ({ variant: 'list' }) satisfies Partial<RouteData>,
        tags: {
          page: 'tasks_list',
        },
        params: {
          variant: 'list',
        },
      },
      {
        path: 'kanban',
        Component: ConnectedTaskKanban,
        loader: () => ({ variant: 'kanban' }) satisfies Partial<RouteData>,
        tags: {
          page: 'tasks_kanban',
        },
        params: {
          variant: 'kanban',
        },
      },
      {
        path: 'gantt',
        Component: ConnectedProjectPlanner,
        loader: () =>
          ({
            variant: 'gantt',
            type: 'projects',
          }) satisfies Partial<RouteData>,
        tags: {
          page: 'projects_planner',
        },
        params: {
          variant: 'gantt',
        },
      },
      // Deprecated route
      {
        path: 'planner',
        loader: redirectLoader('../gantt'),
      },
    ],
  },
  dashboardRoute,
]

const commonChildren: MotionRoute[] = [
  ...taskRoutes,

  {
    path: 'projects',
    loader: () => ({ type: 'projects' }) satisfies Partial<RouteData>,
    children: [
      {
        path: 'list?',
        Component: ConnectedProjectList,
        loader: () => ({ variant: 'list' }) satisfies Partial<RouteData>,
        tags: {
          page: 'projects_list',
        },
        params: {
          variant: 'list',
        },
      },
      {
        path: 'kanban',
        Component: ConnectedProjectKanban,
        loader: () => ({ variant: 'kanban' }) satisfies Partial<RouteData>,
        tags: {
          page: 'projects_kanban',
        },
        params: {
          variant: 'kanban',
        },
      },
      {
        path: 'gantt',
        Component: ConnectedProjectPlanner,
        loader: () =>
          ({
            variant: 'gantt',
          }) satisfies Partial<RouteData>,
        tags: {
          page: 'projects_planner',
        },
        params: {
          variant: 'gantt',
        },
      },
      // Deprecated route
      {
        path: 'planner',
        loader: redirectLoader('../gantt'),
      },
    ],
  },
]

export const v3Routes: MotionRoute[] = [
  {
    id: 'pm-v3-root',
    path: '',
    Component: RootV3,
    featureFlag: {
      name: 'saved-views-as-tabs',
      negate: true,
    },
    children: [
      {
        id: 'my-tasks',
        path: 'my-tasks',
        Component: ShellWithContext,
        routing: {
          relative: true,
          template: `/:type?/:variant?`,
          defaults: {
            type: 'tasks',
            variant: 'list',
          },
        },
        children: [
          {
            path: '',
            loader: async (args) => {
              return {
                page: 'my-tasks',
                key: 'my-tasks',
                filter: {
                  views: 'my-tasks',
                },
                state: await loadState('my-tasks', {
                  featureFlags: args.featureFlags,
                }),
              } satisfies Partial<RouteData>
            },
            children: commonChildren,
          },
          {
            id: 'my-tasks-archive',
            path: 'archive',
            Component: ConnectedTaskList,
            loader: async (args) => {
              return {
                page: 'my-tasks',
                variant: 'archive',
                key: 'my-tasks-archive',
                filter: {
                  views: 'my-tasks',
                  archive: true,
                },
                state: await loadState('my-tasks_archived', {
                  featureFlags: args.featureFlags,
                }),
              } satisfies Partial<RouteData>
            },
          },
        ],
      },
      {
        id: 'my-tasks-redirect',
        path: 'workspaces/my-tasks',
        loader: redirectLoader('../../my-tasks/tasks/list'),
      },
      {
        id: 'my-tasks-archive-redirect',
        path: 'workspaces/my-tasks/archive',
        loader: redirectLoader('../../../my-tasks/archive'),
      },
      {
        id: 'all-tasks',
        path: 'all-tasks',
        Component: ShellWithContext,
        routing: {
          relative: true,
          template: `/:type?/:variant?`,
          defaults: {
            type: 'tasks',
            variant: 'list',
          },
        },
        async loader(args) {
          return {
            page: 'all-tasks',
            key: 'all-tasks',
            filter: {
              views: 'all-tasks',
            },
            state: await loadState('all-tasks', {
              featureFlags: args.featureFlags,
            }),
          } satisfies Partial<RouteData>
        },
        children: commonChildren,
      },
      {
        id: 'all-projects',
        path: 'all-projects',
        Component: ShellWithContext,
        routing: {
          relative: true,
          template: `/:type?/:variant?`,
          defaults: {
            type: 'projects',
            variant: 'gantt',
          },
        },
        async loader(args) {
          return {
            page: 'all-projects',
            key: 'all-projects',
            filter: {
              views: 'all-tasks',
            },
            state: await loadState('all-projects', {
              featureFlags: args.featureFlags,
            }),
          } satisfies Partial<RouteData>
        },
        children: commonChildren,
      },
      {
        id: 'workspace-detail',
        path: 'workspaces/:workspaceId',
        Component: ShellWithContext,
        routing: {
          relative: true,
          template: `/:type?/:variant?`,
          defaults: {
            type: 'tasks',
            variant: 'list',
          },
        },
        children: [
          {
            path: '',
            async loader(args) {
              const state = await loadState(
                `workspace_${args.params.workspaceId}`,
                {
                  featureFlags: args.featureFlags,
                }
              )
              return {
                page: 'workspace',
                key: `workspace_${args.params.workspaceId}`,
                filter: {
                  views: 'workspace',
                  workspaceId: args.params.workspaceId,
                },
                state,
              } satisfies Partial<RouteData>
            },
            children: commonChildren,
          },
          {
            id: 'workspace-archive',
            path: 'archive',
            Component: ConnectedTaskList,
            loader: async (args) => {
              return {
                page: 'workspace',
                variant: 'archive',
                key: `workspace_${args.params.workspaceId}_archived`,
                filter: {
                  views: 'workspace',
                  workspaceId: args.params.workspaceId,
                  archive: true,
                },
                state: await loadState(
                  `workspace_${args.params.workspaceId}_archived`,
                  {
                    featureFlags: args.featureFlags,
                  }
                ),
              } satisfies Partial<RouteData>
            },
          },
        ],
      },
      {
        id: 'workspace-project',
        path: 'workspaces/:workspaceId/projects/:projectId',
        Component: ShellWithContext,
        routing: {
          relative: true,
          template: `/:type?/:variant?`,
          defaults: {
            type: 'tasks',
            variant: 'list',
          },
        },
        async loader(args) {
          return {
            page: 'project',
            key: `project_${args.params.projectId}`,
            filter: {
              views: 'workspace',
              workspaceId: args.params.workspaceId,
              projectId: args.params.projectId,
            },
            state: await loadState(`project_${args.params.projectId}`, {
              featureFlags: args.featureFlags,
            }),
          } satisfies Partial<RouteData>
        },
        children: taskRoutes,
      },
      {
        id: 'workspace-folder',
        path: 'workspaces/:workspaceId/folders/:folderId',
        Component: ShellWithContext,
        routing: {
          relative: true,
          template: `/:type?/:variant?`,
          defaults: {
            type: 'tasks',
            variant: 'list',
          },
        },
        async loader(args) {
          return {
            page: 'folder',
            key: `folder_${args.params.folderId}`,
            filter: {
              views: 'workspace',
              workspaceId: args.params.workspaceId,
              folderId: args.params.folderId,
            },
            state: await loadState(`folder_${args.params.folderId}`, {
              featureFlags: args.featureFlags,
            }),
          } satisfies Partial<RouteData>
        },
        children: commonChildren,
      },
      {
        id: 'not-found-v3',
        path: '*',
        loader: redirectLoader('/web/pm/all-tasks'),
        element: <NotFoundPage path='/web/error-v3' />,
      },
    ],
  },
]
