import { Portal } from '@motion/ui/base'
import { Sentry } from '@motion/web-base/sentry'

import {
  type Active,
  closestCenter,
  type CollisionDetection,
  DndContext,
  type DragEndEvent,
  type DragMoveEvent,
  type DragOverEvent,
  DragOverlay,
  type DragStartEvent,
  type Over,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { useVirtualizer } from '@tanstack/react-virtual'
import { type ReactNode, useCallback, useMemo, useRef, useState } from 'react'

import {
  adjustTranslate,
  dropAnimationConfig,
  indentationWidth,
  itemHeight,
  measuringConfiguration,
} from './constants'
import { useDragProjection } from './hooks'
import {
  type DragProjection,
  type LevelCalculation,
  type SortableTreeviewBaseItem,
} from './types'

export type SortableTreeviewProps<
  T extends SortableTreeviewBaseItem = SortableTreeviewBaseItem,
> = {
  items: T[]
  renderItem: (args: {
    item: T
    level: number
    projection: DragProjection<T> | null
  }) => ReactNode
  renderGhostItem?: (args: {
    item: T
    level: number
    projection: DragProjection<T> | null
  }) => ReactNode
  renderFilter?: (
    items: T[],
    args: {
      activeItem: T | null
      activeItemIdx: number | null
    }
  ) => T[]
  collisionDetection?: (args: { activeItem: T | null }) => CollisionDetection
  calculateMinimumLevel?: LevelCalculation<T>
  calculateMaximumLevel?: LevelCalculation<T>
  onDragEnd?: (args: {
    projection: DragProjection<T>
    active: Active
    over: Over | null
  }) => void
}

export const SortableTreeview = <T extends SortableTreeviewBaseItem>({
  items,
  renderItem,
  renderGhostItem,
  renderFilter = (items) => items,
  collisionDetection = () => closestCenter,
  calculateMinimumLevel: getMinLevel,
  calculateMaximumLevel: getMaxLevel,
  onDragEnd,
}: SortableTreeviewProps<T>) => {
  const treeviewRef = useRef<HTMLDivElement>(null)

  const [activeId, setActiveId] = useState<T['id'] | null>(null)
  const [overId, setOverId] = useState<T['id'] | null>(null)
  const [dragLevel, setDragLevel] = useState(0)

  const renderableItems = useMemo(() => {
    const activeItemIdx = items.findIndex(({ id }) => activeId === id)
    const activeItem = (items[activeItemIdx] as T | undefined) ?? null

    return renderFilter(items, {
      activeItem,
      activeItemIdx,
    })
  }, [items, activeId, renderFilter])

  const projection = useDragProjection({
    items,
    activeId,
    overId,
    dragLevel,
    getMinLevel,
    getMaxLevel,
  })

  const sensors = useSensors(useSensor(PointerSensor))

  const activeItem =
    (activeId
      ? renderableItems.find(({ id }) => id === activeId)
      : undefined) ?? null

  const getItemKey = useCallback(
    (index: number) => renderableItems[index].id,
    [renderableItems]
  )

  const getScrollElement = useCallback(
    () =>
      // Returns the parent <ScrollArea> component
      treeviewRef.current?.closest('[data-radix-scroll-area-viewport]') ?? null,
    []
  )

  const rowVirtualizer = useVirtualizer({
    count: renderableItems.length,
    estimateSize: () => itemHeight,
    gap: 4, // matches space-y-1
    getItemKey,
    getScrollElement,
    overscan: 15,
    scrollMargin: treeviewRef.current?.offsetTop ?? 0,
  })

  const virtualItems = rowVirtualizer.getVirtualItems()

  return (
    <div ref={treeviewRef}>
      <DndContext
        collisionDetection={collisionDetection({ activeItem })}
        measuring={measuringConfiguration}
        onDragCancel={resetState}
        onDragEnd={handleDragEnd}
        onDragMove={handleDragMove}
        onDragOver={handleDragOver}
        onDragStart={handleDragStart}
        sensors={sensors}
      >
        <div
          className='relative w-full'
          style={{
            minHeight: `${Math.max(0, rowVirtualizer.getTotalSize())}px`,
          }}
        >
          <SortableContext
            items={renderableItems}
            strategy={verticalListSortingStrategy}
          >
            <div
              className='absolute top-0 left-0 w-full space-y-1'
              style={{
                transform: `translateY(${(virtualItems[0]?.start ?? 0) - rowVirtualizer.options.scrollMargin}px)`,
              }}
            >
              {virtualItems.map(({ key, index }) => {
                const item = renderableItems[index]
                const level =
                  item.id === activeId && projection
                    ? projection.level
                    : item.level

                return (
                  <div
                    key={key}
                    ref={rowVirtualizer.measureElement}
                    data-index={index}
                  >
                    {renderItem({ item, level, projection })}
                  </div>
                )
              })}
            </div>

            {renderGhostItem && (
              <Portal container={document.body}>
                <DragOverlay
                  modifiers={[adjustTranslate]}
                  dropAnimation={dropAnimationConfig}
                >
                  {activeItem
                    ? renderGhostItem({
                        item: activeItem,
                        level: activeItem.level,
                        projection,
                      })
                    : null}
                </DragOverlay>
              </Portal>
            )}
          </SortableContext>
        </div>
      </DndContext>
    </div>
  )

  function handleDragStart({ active: { id: activeId } }: DragStartEvent) {
    setActiveId(activeId)
    setOverId(activeId)

    document.body.style.setProperty('cursor', 'grabbing')

    if (!treeviewRef.current) return

    // Prevent the tree from jumping when dragging and the parent container size
    // changes, like when moving a folder or workspace
    treeviewRef.current.style.setProperty(
      'min-height',
      `${treeviewRef.current.scrollHeight}px`
    )
  }

  function handleDragMove({ delta }: DragMoveEvent) {
    const newOffsetLeft = Math.round(delta.x / indentationWidth)

    if (dragLevel !== newOffsetLeft) {
      setDragLevel(newOffsetLeft)
    }
  }

  function handleDragOver({ over }: DragOverEvent) {
    setOverId(over?.id ?? null)
  }

  function handleDragEnd({ active, over }: DragEndEvent) {
    resetState()

    if (!projection) {
      return void Sentry.captureException(
        new Error('handleDragEnd projected state is undefined'),
        {
          extra: {
            activeId,
            overId,
            projected: projection,
          },
          tags: {
            position: 'SortableTreeview',
          },
        }
      )
    }

    if (!projection.hasMoved) return

    onDragEnd?.({
      projection,
      active,
      over,
    })
  }

  function resetState() {
    setActiveId(null)
    setDragLevel(0)
    setOverId(null)

    document.body.style.setProperty('cursor', '')

    treeviewRef.current?.style.removeProperty('min-height')
  }
}
