import {
  type COLOR,
  findDefaultStatus,
  getRandomColor,
} from '@motion/shared/common'
import {
  convertDaysToDeadlineInterval,
  createStableFlowKey,
  getDefaultRelativeInterval,
  SYSTEM_FLOW_VARIABLES,
} from '@motion/shared/flows'
import { createPlaceholderId } from '@motion/shared/identifiers'
import { type CustomFieldFieldArrayValue } from '@motion/ui-logic'
import {
  type FlowTemplateFormFields,
  type FlowTemplateFormTask,
  type FlowTemplateStage,
} from '@motion/ui-logic/pm/project'
import { uniqueId } from '@motion/utils/core'
import { type StatusSchema } from '@motion/zod/client'

export const createNewRole = (
  name = '',
  color = getRandomColor()
): FlowTemplateFormFields['roles'][number] => ({
  id: createPlaceholderId(uniqueId()),
  key: createStableFlowKey(),
  type: 'person',
  color,
  name,
})

export const createNewTextVariable = (
  name: string,
  key = createStableFlowKey()
): FlowTemplateFormFields['textVariables'][number] => ({
  id: createPlaceholderId(uniqueId()),
  key,
  type: 'text',
  color: 'blue',
  name,
})

export const DEFAULT_TEXT_VARIABLES: FlowTemplateFormFields['textVariables'] =
  Array.from(SYSTEM_FLOW_VARIABLES.entries()).map(([name, key]) =>
    createNewTextVariable(name, key)
  )

export const DEFAULT_TEXT_VARIABLE_KEYS = DEFAULT_TEXT_VARIABLES.map(
  (variable) =>
    variable.key as typeof SYSTEM_FLOW_VARIABLES extends Map<any, infer V>
      ? V
      : never
)

export function getNewStageData(opts: {
  workspaceId: string
  currentUserId: string
  workspaceStatuses: StatusSchema[]
  name?: string
  color?: COLOR
  customFieldValuesFieldArray?: CustomFieldFieldArrayValue[]
}): FlowTemplateStage {
  const {
    workspaceId,
    currentUserId,
    workspaceStatuses,
    name = 'New stage',
    color = getRandomColor(),
    customFieldValuesFieldArray,
  } = opts
  return {
    id: createPlaceholderId(uniqueId('stage')),
    name,
    color,
    automaticallyMoveToNextStage: true,
    deadlineInterval: convertDaysToDeadlineInterval(7),
    duration: convertDaysToDeadlineInterval(7),
    tasks: [
      getInitialStageTaskData({
        currentUserId,
        workspaceStatuses,
        defaultStatusId: findDefaultStatus(workspaceStatuses)?.id,
        customFieldValuesFieldArray,
      }),
    ],
    variables: [],
    workspaceId,
  }
}

const DEFAULT_START_RELATIVE_INTERVAL =
  getDefaultRelativeInterval('STAGE_START')
const DEFAULT_DUE_RELATIVE_INTERVAL = getDefaultRelativeInterval('STAGE_DUE')

export function getInitialStageTaskData(opts: {
  currentUserId: string
  workspaceStatuses: StatusSchema[]
  defaultStatusId?: string
  customFieldValuesFieldArray?: CustomFieldFieldArrayValue[]
  isAutoScheduled?: boolean
}) {
  const {
    currentUserId,
    workspaceStatuses,
    defaultStatusId,
    customFieldValuesFieldArray = [],
    isAutoScheduled = true,
  } = opts

  return {
    id: createPlaceholderId(uniqueId('task')),
    name: '',
    description: '',
    priorityLevel: 'MEDIUM' as const,
    labelIds: [],
    statusId: defaultStatusId ?? workspaceStatuses[0].id,
    assigneeUserId: currentUserId,
    assigneeVariableKey: null,
    duration: 30,
    isAutoScheduled,
    blockedByTaskId: null,
    minimumDuration: null,
    customFieldValuesFieldArray,
    scheduleMeetingWithinDays: null,
    uploadedFileIds: [],
    deadlineType: 'SOFT',
    startRelativeInterval: {
      ...DEFAULT_START_RELATIVE_INTERVAL,
      duration: {
        ...DEFAULT_START_RELATIVE_INTERVAL.duration,
        sign: 1,
      },
    },
    dueRelativeInterval: {
      ...DEFAULT_DUE_RELATIVE_INTERVAL,
      duration: {
        ...DEFAULT_DUE_RELATIVE_INTERVAL.duration,
        sign: -1,
      },
    },
  } satisfies FlowTemplateFormTask
}
