export function safeParseNumber(value: string | null | undefined): number | null
export function safeParseNumber(
  value: string | null | undefined,
  defaultValue: number
): number
export function safeParseNumber(
  value: string | null | undefined,
  defaultValue: null
): number | null
export function safeParseNumber(
  value: string | null | undefined,
  defaultValue: string
): number | string
export function safeParseNumber(
  value: string | null | undefined,
  defaultValue: number | string | null = null
): number | string | null {
  if (!value) return defaultValue
  const asNumber = parseInt(value)
  if (isNaN(asNumber)) {
    return defaultValue
  }
  return asNumber
}

type FormatNumberOptions = {
  decimals?: number
}

let numberFormatters: Intl.NumberFormat[] = []
export function formatNumber(
  value: number,
  { decimals = 2 }: FormatNumberOptions
) {
  numberFormatters[decimals] ??= new Intl.NumberFormat('en-US', {
    maximumFractionDigits: decimals,
  })

  return numberFormatters[decimals].format(value)
}

let percentFormatters: Intl.NumberFormat[] = []
export function formatPercentage(
  value: number,
  { decimals = 2 }: FormatNumberOptions
) {
  percentFormatters[decimals] ??= new Intl.NumberFormat('en-US', {
    style: 'percent',
    maximumFractionDigits: decimals,
  })

  return percentFormatters[decimals].format(value)
}
