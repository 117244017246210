import { useTeamMembers } from '~/global/hooks/team'

import { TeamPageWrapper } from './components/Common/team-page-wrapper'
import { ManageTeamButtons } from './components/Pages/team-members-page-v2/manage-team-buttons'
import { TeamMembersPage } from './components/Pages/team-members-page-v2/team-members-page'

import { SettingPage } from '../Settings/Components/SettingPage'

export const ConnectedTeamMembersPage = () => {
  const teamMembers = useTeamMembers()

  return (
    <TeamPageWrapper>
      <SettingPage
        fullWidth
        className='gap-4'
        title={
          <div className='flex justify-between w-full items-center'>
            <span>
              Team Members (
              {teamMembers.filter((m) => !m.user.isPlaceholder).length} members)
            </span>
            <ManageTeamButtons />
          </div>
        }
      >
        <TeamMembersPage />
      </SettingPage>
    </TeamPageWrapper>
  )
}
