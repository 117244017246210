import {
  ChartSquareBarSolid,
  MenuAlt2Outline,
  PlannerSolid,
  type SvgIcon,
  ViewBoardsOutline,
} from '@motion/icons'

export type ViewType = 'list' | 'kanban' | 'gantt' | 'dashboard'

export const VIEW_OPTIONS: {
  id: ViewType
  label: string
  icon: SvgIcon
}[] = [
  {
    id: 'list',
    label: 'List',
    icon: MenuAlt2Outline,
  },
  {
    id: 'kanban',
    label: 'Kanban',
    icon: ViewBoardsOutline,
  },
  {
    id: 'gantt',
    label: 'Gantt',
    icon: PlannerSolid,
  },
  {
    id: 'dashboard',
    label: 'Dashboard',
    icon: ChartSquareBarSolid,
  },
]
