import type { ProjectSchema, TaskSchema } from '@motion/zod/client'

import { useRedirectToProject } from '~/areas/project/hooks'
import { useStartTask, useStopTask, useTaskUpdater } from '~/areas/tasks/hooks'
import { useChangeTaskStartDate } from '~/areas/tasks/hooks/actions/use-change-task-start-date'
import { useTaskModalUrl } from '~/global/navigation'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export type UseAgendaActionsProps = {
  taskId: TaskSchema['id']
  projectId?: ProjectSchema['id'] | null
}

export type UseAgendaActionsReturnType = {
  openTask: () => void
  openProject?: () => void
  changeStartDate: (value: string | null) => void
  changeDeadline: (value: string | null) => void
  startTask: () => void
  stopTask: () => void
  updateTaskInFlight: boolean
}

export function useAgendaTaskActions({
  taskId,
  projectId = null,
}: UseAgendaActionsProps): UseAgendaActionsReturnType {
  const navigate = useNavigate()
  const buildTaskModalUrl = useTaskModalUrl()
  const updateTask = useTaskUpdater()
  const startTaskCall = useStartTask()
  const stopTaskCall = useStopTask()
  const redirectToProject = useRedirectToProject()
  const changeTaskStartDate = useChangeTaskStartDate()
  const [updateTaskInFlight, setUpdateTaskInFlight] = useState(false)

  const openTask = () => {
    navigate(
      buildTaskModalUrl({
        task: taskId,
      })
    )
  }

  const openProject = () => {
    if (projectId != null) {
      redirectToProject(projectId)
    }
  }

  const changeStartDate = async (value: string | null) => {
    setUpdateTaskInFlight(true)
    await changeTaskStartDate(taskId, value)
    setUpdateTaskInFlight(false)
  }

  const changeDeadline = async (value: string | null) => {
    setUpdateTaskInFlight(true)
    await updateTask(taskId, { dueDate: value })
    setUpdateTaskInFlight(false)
  }

  const startTask = async () => {
    await startTaskCall(taskId, {
      source: 'agendaTaskItem',
    })
  }

  const stopTask = async () => {
    await stopTaskCall(taskId, {
      source: 'agendaTaskItem',
    })
  }

  return {
    openTask,
    openProject: projectId != null ? openProject : undefined,
    changeStartDate,
    changeDeadline,
    startTask,
    stopTask,
    updateTaskInFlight,
  }
}
