import { useStageLocation } from './hooks'
import {
  StageCompletionBar,
  StageInitialCircle,
  StageItemContainer,
} from './styled'
import { type ConnectedResizeableProjectStageItemProps } from './types'

type PlaceholderProjectStageItemProps = Pick<
  ConnectedResizeableProjectStageItemProps,
  'stageWithDates' | 'project'
>

export const PlaceholderProjectStageItem = ({
  stageWithDates,
  project,
}: PlaceholderProjectStageItemProps) => {
  const { stageLeft, stageProgressWidth, maxStageWidth } = useStageLocation({
    project,
    stageWithDates,
    projectDeltaWidth: 0,
  })

  return (
    <StageItemContainer
      style={{
        left: stageLeft,
        width: maxStageWidth,
      }}
    >
      <StageCompletionBar
        style={{
          width: stageProgressWidth,
        }}
      />

      <StageInitialCircle
        style={{
          right: 0,
        }}
      >
        <div className='rounded-full bg-palette-text-default opacity-40 w-full h-full' />
      </StageInitialCircle>
    </StageItemContainer>
  )
}
