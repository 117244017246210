import { DotsHorizontalSolid } from '@motion/icons'
import { IconButton, PopoverTrigger } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { NoteActionsList } from '~/areas/notes'

import { type WorkspacesTreeviewItem } from '../types'

type NoteActionsMenuProps = {
  treeViewItem: WorkspacesTreeviewItem['item']
  moveActionAllowed: {
    up: boolean
    down: boolean
  }
}

export const NoteActionsMenu = ({
  moveActionAllowed,
  treeViewItem,
}: NoteActionsMenuProps) => {
  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <NoteActionsList
          noteId={treeViewItem.itemId}
          folderItemId={treeViewItem.id}
          moveActionAllowed={moveActionAllowed}
          close={close}
        />
      )}
    >
      <IconButton
        variant='muted'
        sentiment='neutral'
        size='xsmall'
        icon={DotsHorizontalSolid}
        onClick={() => {
          recordAnalyticsEvent('FOLDERS_CLICKED_SIDEBAR_BUTTON', {
            itemType: 'NOTE',
            button: 'ACTIONS',
          })
        }}
      />
    </PopoverTrigger>
  )
}
