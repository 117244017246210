import { classed } from '@motion/theme'

export const StyledListRow = classed('li', {
  base: `
    group/list-row
    pl-[calc(var(--row-depth,0)*16px)]
    h-[var(--row-height)]
  `,
  variants: {
    expandable: {
      true: `
        z-[2] sticky
        bg-modal-bg

        setvar-[offset=calc(var(--row-height)*var(--row-depth,0))]
        top-[var(--offset)]
      `,
      false: 'ml-2',
    },
  },
  defaultVariants: {
    expandable: false,
  },
})
