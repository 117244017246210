import { PlusSolid } from '@motion/icons'
import { Button, Tooltip } from '@motion/ui/base'
import { useModalApi } from '@motion/web-common/modals'

import { useSidebarSearchContext } from '~/areas/search/hook'
import { useNavigateByRouteId } from '~/routing'

import { ExpandableSection } from './components'
import {
  useWorkspacesTreeviewItems,
  WorkspacesTreeview,
} from './workspaces-treeview'

import { useGlobalSidebarState } from '../hooks'

export const WorkspacesSection = () => {
  const modalApi = useModalApi()
  const navigateByRouteId = useNavigateByRouteId()
  const { sidebarState, toggleSidebarSection } = useGlobalSidebarState()
  const { searchQuery, hasSearch } = useSidebarSearchContext()
  const items = useWorkspacesTreeviewItems()

  return (
    <ExpandableSection
      title='Workspaces'
      isExpanded={sidebarState.sections.workspaces}
      onToggleExpand={(state) => void toggleSidebarSection('workspaces', state)}
      actionButton={{
        icon: PlusSolid,
        onClick: () => {
          modalApi.prompt('add-workspace', {
            onValue: (workspaceId) =>
              navigateByRouteId('workspace-detail', { workspaceId }),
          })
        },
        wrapper: (children) => (
          <Tooltip asChild content='Add workspace'>
            {children}
          </Tooltip>
        ),
      }}
    >
      <WorkspacesTreeview items={items} />

      {hasSearch && items.length === 0 && (
        <div
          className='h-full flex flex-col gap-1 overflow-auto text-sm text-semantic-neutral-text-subtle p-4'
          aria-live='polite'
        >
          <div className='font-semibold'>No results</div>
          <div>Try a different search term.</div>
          <div className='pt-2'>
            <Button
              variant='outlined'
              sentiment='neutral'
              size='small'
              onClick={() =>
                modalApi.open('search', { initialQuery: searchQuery })
              }
            >
              Search tasks instead
            </Button>
          </div>
        </div>
      )}
    </ExpandableSection>
  )
}
