import { PopoverButton, SearchableDropdown } from '@motion/ui/base'

import { useState } from 'react'

export type PlusMinusDropdownProps = {
  value: -1 | 1
  onChange: (sign: -1 | 1) => void
}

export const PlusMinusDropdown = ({
  value,
  onChange,
}: PlusMinusDropdownProps) => {
  const selected = value === -1 ? 'minus' : 'plus'

  // Will un-disable once adding meetings
  const [disabled] = useState(true)

  return (
    <SearchableDropdown
      searchable={false}
      items={['plus', 'minus']}
      selectedItem={selected}
      onChange={(item) => onChange(item === 'plus' ? 1 : -1)}
      renderItem={(item) => item}
    >
      <PopoverButton disabled={disabled}>{selected}</PopoverButton>
    </SearchableDropdown>
  )
}
