import { type TaskSchema } from '@motion/rpc-types'
import { getNextStage } from '@motion/ui-logic/pm/project'

import { useLegacyStageDefinition, useProject } from '~/global/hooks'

export const useNextTaskStage = (task: TaskSchema) => {
  const project = useProject(task.projectId ?? '')
  const nextStage = getNextStage(
    project,
    task.type === 'NORMAL' && task.stageDefinitionId
      ? task.stageDefinitionId
      : ''
  )
  const stageDefinition = useLegacyStageDefinition(
    nextStage ? nextStage.stageDefinitionId : ''
  )

  return stageDefinition
}
