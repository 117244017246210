import { CalendarPlusSolid, TaskSolid } from '@motion/icons'
import { ActionList } from '@motion/ui/base'
import { sleep } from '@motion/utils/promise'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useModalApi } from '@motion/web-common/modals'

import { useTaskModalUrl } from '~/global/navigation'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router'

import { useClosePeekPopover, useSelectedCalendarEvent } from './hooks'
import { PeekModalPopover } from './peek-modal-popover'

import { useCalendarState } from '../../hooks'

export function CalendarPeekModal() {
  const isDraggingEvent = useCalendarState(
    (state) => state.isDraggingEvent ?? false
  )

  const { targetId, event } = useSelectedCalendarEvent()

  const modalApi = useModalApi()
  const buildTaskModalUrl = useTaskModalUrl()
  const closePeekPopover = useClosePeekPopover()
  const navigate = useNavigate()

  const [anchor, setAnchor] = useState<HTMLElement | null>(null)
  const refActionTriggered = useRef(false)

  useEffect(() => {
    if (!targetId || isDraggingEvent) return

    const element: HTMLElement | null =
      document.querySelector(`[data-event-id="${targetId}"]`) ?? null
    setAnchor(element)

    return () => {
      setAnchor(null)
    }
  }, [targetId, event, isDraggingEvent])

  useEffect(() => {
    if (!targetId) return

    // Resetting action
    refActionTriggered.current = false

    return () => {
      // When it closes and no action has triggered
      if (!refActionTriggered.current) {
        recordAnalyticsEvent('CALENDAR_DRAG_CREATE', { option: 'none' })
      }
    }
  }, [targetId])

  if (!event || !anchor || isDraggingEvent) return null

  return (
    <PeekModalPopover
      anchor={anchor}
      onClose={() => {
        closePeekPopover()
      }}
    >
      <ActionList
        onActionAnyItem={async () => {
          refActionTriggered.current = true
          closePeekPopover()
          // Sleeping a tiny bit to let the peek modal close before navigating to the actions
          await sleep(10)
        }}
        items={[
          {
            prefix: <CalendarPlusSolid />,
            content: 'Create event',
            onAction: () => {
              recordAnalyticsEvent('CALENDAR_DRAG_CREATE', { option: 'event' })
              modalApi.open('event-modal', {
                start: event.start,
                end: event.end,
                isAllDay: event.isAllDay,
                attendees: event.attendees,
              })
            },
          },
          event.isAllDay && {
            prefix: <TaskSolid />,
            content: 'Create reminder task',
            onAction: () => {
              recordAnalyticsEvent('CALENDAR_DRAG_CREATE', {
                option: 'reminder_task',
              })
              navigate(
                buildTaskModalUrl({
                  forStartDate: event.start,
                }),
                {
                  state: {
                    reminderTask: true,
                  },
                }
              )
            },
          },
          !event.isAllDay && {
            prefix: <TaskSolid />,
            content: 'Create task (fixed time)',
            onAction: () => {
              recordAnalyticsEvent('CALENDAR_DRAG_CREATE', {
                option: 'fixed_task',
              })
              navigate(
                buildTaskModalUrl({
                  forStartDate: event.start,
                  forDueDate: event.end,
                }),
                {
                  state: {
                    fixedTask: true,
                  },
                }
              )
            },
          },
        ]}
      />
    </PeekModalPopover>
  )
}
