import { createNoneProject } from '@motion/ui-logic/pm/data'
import { type ProjectSchema, type WorkspaceSchema } from '@motion/zod/client'

import { type WorkspaceProjectDropdownProps } from '~/areas/project-management/components'
import { ProjectBadge } from '~/global/components/badges'
import { WorkspacesTreeDropdown } from '~/global/components/dropdowns'
import { ProjectLabel } from '~/global/components/labels'
import { useProject, useWorkspaceById } from '~/global/hooks'

import { ModalFieldButton, type ModalFieldButtonProps } from '../components'

export type WorkspaceProjectFieldProps = {
  workspaceId: WorkspaceSchema['id']
  projectId: ProjectSchema['id'] | null
  onChange: (
    workspaceId: WorkspaceSchema['id'],
    projectId: ProjectSchema['id'] | null
  ) => void
  disabled?: boolean
  suffix?: ModalFieldButtonProps['suffix']
  hideNoProject?: WorkspaceProjectDropdownProps['hideNoProject']
  disallowSelectWorkspace?: WorkspaceProjectDropdownProps['disallowSelectWorkspace']
}
export const WorkspaceProjectField = ({
  workspaceId,
  projectId,
  onChange,
  disabled,
  suffix,
  hideNoProject,
  disallowSelectWorkspace,
}: WorkspaceProjectFieldProps) => {
  const selectedWorkspace = useWorkspaceById(workspaceId)
  const selectedProject =
    useProject(projectId) ?? createNoneProject(workspaceId)

  if (selectedWorkspace == null) return null

  return (
    <WorkspacesTreeDropdown
      // TODO: if no project id is selected select the "none" item which is the parent, in this case a workspace
      // for tasks this is only true while we don't allow task in folders without being in a project.
      selectedId={projectId ?? workspaceId}
      workspaceId={workspaceId}
      hideNoProject={hideNoProject}
      onChange={(selected) => {
        onChange(selected.workspaceId, selected.projectId)
      }}
    >
      <ModalFieldButton
        size='normal'
        icon={<ProjectBadge value={selectedProject} />}
        disabled={disabled}
        suffix={suffix}
      >
        <ProjectLabel value={selectedProject} nameOnly />
      </ModalFieldButton>
    </WorkspacesTreeDropdown>
  )
}
