import { AutoscheduleStarSolid } from '@motion/icons'
import { type MeetingInsightsSchema } from '@motion/rpc-types'
import { useModalApi } from '@motion/web-common/modals'

import { UserRating } from '~/areas/ai-hackerhouse/components'
import { MotionLink } from '~/global/components'
import { useUriByRouteId } from '~/routing'

type MeetingSummaryProps = {
  meetingInsights: MeetingInsightsSchema
  showRecordingLink?: boolean
}

export function MeetingSummary({
  meetingInsights,
  showRecordingLink,
}: MeetingSummaryProps) {
  const getRouteUri = useUriByRouteId()
  const modalApi = useModalApi()

  return (
    <div className='bg-semantic-purple-bg-default px-3 py-2 rounded flex gap-1'>
      <AutoscheduleStarSolid className='size-4 shrink-0 text-semantic-gradient-purple' />

      <div className='flex flex-col gap-2 text-sm text-semantic-neutral-text-default leading-normal w-full'>
        <div className='flex flex-col'>
          <h3 className='font-semibold'>Meeting Summary</h3>

          <p>
            {meetingInsights.summary ??
              'No summary available for this meeting.'}
          </p>
        </div>

        {showRecordingLink && (
          <MotionLink
            url={getRouteUri('meeting-insights', {
              meetingInsightsId: meetingInsights.id,
            })}
            onClick={() => modalApi.dismiss('event-modal')}
            className='self-start text-semantic-primary-text-default font-medium'
          >
            View Meeting Recording
          </MotionLink>
        )}

        <div className='self-end'>
          <UserRating
            label='How accurate was this summary?'
            section='summary'
            meetingInsightsId={meetingInsights.id}
          />
        </div>
      </div>
    </div>
  )
}
