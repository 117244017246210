import { ConfirmationModal } from '@motion/ui/base'
import { formatToReadableWeekDayMonth, templateStr } from '@motion/ui-logic'
import { getEnabledStagesWithDates } from '@motion/ui-logic/pm/project'
import { parseDate } from '@motion/utils/dates'
import { Sentry } from '@motion/web-base/sentry'

import { StageLabel } from '~/global/components/labels'
import { useI18N } from '~/global/contexts'
import { useLegacyStageDefinition, useProject } from '~/global/hooks'
import { useState } from 'react'

import { type ModalTriggerComponentProps } from '../../../modals/modal-trigger'
import { ModalRadioButton } from '../components/modal-radio-button'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'update-stage-deadline': PromptCallbacks<{
      updateStageOnly: boolean
    }> & {
      projectId: string
      stageDefinitionId: string
      dueDate: string
    }
  }
}

export function UpdateStageDeadlineModal({
  close,
  projectId,
  stageDefinitionId,
  dueDate,
}: ModalTriggerComponentProps<'update-stage-deadline'>) {
  const project = useProject(projectId)
  const stageDefinition = useLegacyStageDefinition(stageDefinitionId)
  const [hasUpdateStageOnly, setUpdateStageOnly] = useState(false)
  const { pluralize } = useI18N()

  if (!project || !stageDefinition) {
    Sentry.captureException(
      new Error('Project or stage definition not found'),
      {
        tags: {
          position: 'update-stage-deadline-modal',

          projectId,
          stageDefinitionId,
        },
      }
    )
    close()
    return null
  }

  // stage calculations
  const validStages = getEnabledStagesWithDates(project.stages ?? [], {
    start: project.startDate,
    due: project.dueDate,
  })
  const stageIndex = validStages.findIndex(
    (stageWithDate) =>
      stageWithDate.stage.stageDefinitionId === stageDefinitionId
  )
  const projectStage = validStages[stageIndex]
  const nextActiveStage = validStages[stageIndex + 1]
  const isLastStage = stageIndex === validStages.length - 1

  // date calculations
  const projectStageStartDate = parseDate(projectStage?.start ?? '')
  const projectDueDate = parseDate(project.dueDate ?? '')
  const projectStageDueDate = parseDate(projectStage?.due ?? '')
  const nextStageDueDate =
    nextActiveStage?.due && !nextActiveStage.stage.completedTime
      ? parseDate(nextActiveStage.due)
      : projectDueDate
  const formattedDueDate = parseDate(dueDate)

  // disabled conditions
  const extendedPastProjectDeadline = formattedDueDate > projectDueDate
  const expandedPastValidDeadline =
    !isLastStage &&
    (extendedPastProjectDeadline || formattedDueDate > nextStageDueDate)
  const contractedBeforeProjectStageStartDate =
    formattedDueDate < projectStageStartDate
  const disableStageOnlyChange =
    expandedPastValidDeadline || contractedBeforeProjectStageStartDate
  const deadlineDiff = Math.round(
    formattedDueDate.diff(projectStageDueDate, 'days').days
  )
  const updateStageOnly = disableStageOnlyChange ? false : hasUpdateStageOnly
  const daysChangedCopy = `${deadlineDiff > 0 ? '+' : ''}${deadlineDiff} ${pluralize(deadlineDiff, 'day', 'days')}`

  return (
    <ConfirmationModal
      action={{
        label: 'Continue',
        onAction: () => {
          close({ updateStageOnly })
          setUpdateStageOnly(false)
        },
      }}
      closeLabel='Cancel'
      description={
        <div className='gap-3 flex flex-col'>
          <div className='text-semantic-neutral-text-default text-xs font-normal inline-block leading-6'>
            {templateStr(
              'You are changing the {{stageLabel}} stage deadline from {{stageDueDate}} to {{newDueDate}} ({{daysChangedCopy}})',
              {
                stageLabel: (
                  <div className='inline-block align-middle'>
                    <StageLabel size='small' value={stageDefinition} />
                  </div>
                ),
                stageDueDate: formatToReadableWeekDayMonth(projectStageDueDate),
                newDueDate: formatToReadableWeekDayMonth(formattedDueDate),
                daysChangedCopy,
              }
            )}
          </div>
          <div className='flex flex-col items-start gap-4 self-stretch'>
            <ModalRadioButton
              radioTitle='Resize stage and resize project'
              radioSubtitle={`Motion will move all the stage deadlines and the project deadline by ${daysChangedCopy}`}
              checked={!updateStageOnly}
              onChange={(checked) => setUpdateStageOnly(!checked)}
            />
            <ModalRadioButton
              disabled={disableStageOnlyChange}
              radioTitle='Resize stage only'
              radioSubtitle={`Motion will move the stage deadline by ${daysChangedCopy},
                      but all other future stage deadlines and project deadline
                      will remain unchanged`}
              checked={updateStageOnly}
              onChange={setUpdateStageOnly}
              tooltipContent={
                disableStageOnlyChange
                  ? expandedPastValidDeadline
                    ? templateStr(
                        'The stage cannot be extended to a date later than {{nextItem}} deadline',
                        {
                          nextItem: extendedPastProjectDeadline
                            ? 'its project'
                            : 'the next stage',
                        }
                      )
                    : 'The stage cannot be shortened to a date earlier than its project start date'
                  : undefined
              }
            />
          </div>
        </div>
      }
      onClose={close}
      title='Would you like to resize the project or just the stage?'
      visible
    />
  )
}
