import { PopoverTrigger, SearchableList } from '@motion/ui/base'
import { cloneDeep, toMerged } from '@motion/utils/core'
import { keys } from '@motion/utils/object'
import {
  type DashboardViewDataDirectionSortSchema,
  type DashboardViewSortSchema,
} from '@motion/zod/client'

import { ModalFieldButton } from '~/areas/task-project/components'
import { SubItemIndentMarker } from '~/areas/tasks/modals/task-modal/components'
import { useCallback } from 'react'

import { useChartSettingField } from '../../contexts/chart-fields-context'
import { ListItem } from '../list-item'

// TODO: support custom sort
function isDirectionSort(
  sort: DashboardViewSortSchema
): sort is DashboardViewDataDirectionSortSchema {
  return 'source' in sort
}

type DirectionSortKey =
  `${DashboardViewDataDirectionSortSchema['direction']}.${DashboardViewDataDirectionSortSchema['source']}`

const sortByMap: Record<DirectionSortKey, string> = {
  'asc.value': 'Value Ascending',
  'desc.value': 'Value Descending',
  'asc.name': 'Name Ascending',
  'desc.name': 'Name Descending',
}

function getSortByLabelFromDotNotation(sortKey: DirectionSortKey): string {
  return sortByMap[sortKey] ?? sortKey
}

type ChartSortByFieldProps = {
  category: 'groupBy' | 'segmentBy'
}

type SortValue = {
  direction: DashboardViewDataDirectionSortSchema['direction']
  source: DashboardViewDataDirectionSortSchema['source']
}

const DEFAULT_SORT: DashboardViewDataDirectionSortSchema = {
  source: 'name',
  direction: 'asc',
}

export function ChartSortByField(props: ChartSortByFieldProps) {
  const [chartGroupBy, setChartGroupBy] = useChartSettingField('groupBy')

  const sortBy =
    (props.category === 'groupBy'
      ? chartGroupBy[0]?.sort
      : chartGroupBy[1]?.sort) ?? DEFAULT_SORT

  const setSortBy = useCallback(
    (value: SortValue) => {
      if (sortBy == null) return

      const clone = cloneDeep(chartGroupBy)

      const index = props.category === 'groupBy' ? 0 : 1
      if (!clone[index]) return

      clone[index].sort = toMerged(clone[index].sort ?? {}, value)
      setChartGroupBy(clone)
    },
    [chartGroupBy, props.category, setChartGroupBy, sortBy]
  )

  if (sortBy == null) return null

  const showSortBy = isDirectionSort(sortBy)

  if (!showSortBy) {
    return null
  }

  return (
    <div className='flex flex-row items-center gap-0.5'>
      <SubItemIndentMarker />

      <PopoverTrigger
        placement='bottom-start'
        renderPopover={({ close }) => (
          <SearchableList
            searchable={false}
            items={keys(sortByMap)}
            computeKey={(item) => item}
            computeSelected={(item) =>
              item === `${sortBy.direction}.${sortBy.source}`
            }
            onSelect={(item) => {
              const [direction, source] = item.split('.') as [
                DashboardViewDataDirectionSortSchema['direction'],
                DashboardViewDataDirectionSortSchema['source'],
              ]

              setSortBy({
                direction,
                source,
              })
              close()
            }}
            renderItem={(item) => (
              // TODO: Add icon
              <ListItem name={getSortByLabelFromDotNotation(item)} />
            )}
          />
        )}
      >
        <ModalFieldButton label='Sort by'>
          {getSortByLabelFromDotNotation(
            `${sortBy.direction}.${sortBy.source}`
          )}
        </ModalFieldButton>
      </PopoverTrigger>
    </div>
  )
}
