import {
  type MeetingActionItemTaskDataSchema,
  type TaskSchema,
} from '@motion/rpc-types'
import { createNoneId } from '@motion/shared/identifiers'
import { getDefaultChunkDuration } from '@motion/ui-logic'
import { DEFAULT_SCHEDULE_ID } from '@motion/ui-logic/pm/task'

import { DateTime } from 'luxon'
import { useMemo } from 'react'

import { type ActionItemFormFields } from '../types'

type InitialFormData = {
  initialData: MeetingActionItemTaskDataSchema | TaskSchema
}

export function useInitialFormData({ initialData }: InitialFormData) {
  return useMemo(() => {
    return {
      id: 'id' in initialData ? initialData.id : null,
      name: initialData.name,
      description: initialData.description ?? '',
      priorityLevel: initialData.priorityLevel ?? 'MEDIUM',
      duration: initialData.duration ?? 0,
      minimumDuration:
        'minimumDuration' in initialData && initialData.minimumDuration != null
          ? initialData.minimumDuration
          : getDefaultChunkDuration(initialData.duration ?? 0),
      dueDate:
        initialData.dueDate ?? DateTime.now().plus({ day: 1 }).toISODate(),
      startDate: initialData.startDate ?? DateTime.now().toISODate(),
      assigneeUserId: initialData.assigneeUserId ?? null,
      workspaceId: initialData.workspaceId ?? createNoneId('workspace'),
      projectId: initialData.projectId ?? createNoneId('project'),
      ignoreWarnOnPastDue: false,
      scheduleId: DEFAULT_SCHEDULE_ID,
      deadlineType:
        'deadlineType' in initialData
          ? (initialData.deadlineType ?? 'SOFT')
          : 'SOFT',
      isAutoScheduled: true,
    } satisfies ActionItemFormFields
  }, [initialData])
}
