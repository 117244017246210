import { useOnValueChange } from '@motion/react-core/hooks'
import {
  type AssignmentSchema,
  type ConditionSchema,
  type PermissionPolicy,
} from '@motion/rpc-types'
import { classed } from '@motion/theme'
import { FormModal, PopoverButton, showToast, Tooltip } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { templateStr } from '@motion/ui-logic'
import { groupInto } from '@motion/utils/array'

import { type ModalTriggerComponentProps } from '~/areas/modals'
import { useCurrentTeam } from '~/global/rpc/team'
import { showErrorToast } from '~/global/toasts'
import { useState } from 'react'

import { PermissionPopover } from './permission-popover'
import { type Invitee } from './types'

import {
  useCreateAutomationPolicy,
  useUpdateAutomationPolicy,
} from '../hooks/rpc'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'automation-form': {
      policy?: PermissionPolicy
    }
  }
}

type AutomationFormModalProps = ModalTriggerComponentProps<'automation-form'>

type Condition = Omit<ConditionSchema, 'version'>

export function ConnectedAutomationFormModal({
  close,
  policy,
}: AutomationFormModalProps) {
  // Ugly, can be improved with a reducer or using rhf, but works for now :)
  const [condition, setCondition] = useState<Condition>({
    field: 'title',
    operator: 'CONTAINS',
    value: '',
    inverse: false,
    sourceType: 'EVENTS',
  })
  const [invitees, setInvitees] = useState<Invitee[]>([])
  const [error, setError] = useState<string | null>(null)

  const { mutateAsync: createPolicy } = useCreateAutomationPolicy()
  const { mutateAsync: updatePolicy } = useUpdateAutomationPolicy()

  const { data: team } = useCurrentTeam()

  useOnValueChange(
    policy,
    (newPolicy) => {
      if (newPolicy) {
        setInvitees(
          newPolicy.assignments.flatMap((a) =>
            a.targetIds.map((id) => ({
              id,
              type: a.type,
              action: a.action,
            }))
          )
        )
        setCondition(newPolicy.condition)
      }
    },
    {
      triggerOnFirstRender: true,
    }
  )

  function validate() {
    if (!condition.value) {
      setError('Please enter a condition value')
      return false
    }

    if (invitees.length === 0) {
      setError('Please select at least one user or workspace')
      return false
    }

    setError(null)
    return true
  }

  async function handleSubmit() {
    if (team == null) {
      showErrorToast('Automation policies can only be created for teams')
      return
    }

    if (!validate()) {
      return
    }

    const assignments = groupInto(invitees, (i) => i.type).flatMap((group) => {
      const groupedByAction = groupInto(group.items, (i) => i.action)
      return groupedByAction.map((actionGroup) => ({
        type: group.key,
        action: actionGroup.key,
        targetIds: actionGroup.items.map((i) => i.id),
      }))
    })

    if (policy != null) {
      try {
        await updatePolicy({
          policyId: policy.id,
          condition,
          assignments,
        })

        close()

        showToast('success', 'Automation policy updated')
      } catch (e) {
        showErrorToast(e)
      }
    } else {
      try {
        await createPolicy({
          condition,
          assignments,
          teamId: team.id,
        })

        close()

        showToast('success', 'Automation policy created')
      } catch (e) {
        showErrorToast(e)
      }
    }
  }

  function handleIdsChange(type: AssignmentSchema['type'], ids: string[]) {
    setInvitees((prev) => {
      const idSet = new Set(ids)

      const existing = prev.filter((i) => i.type !== type || idSet.has(i.id))
      const existingIds = new Set(existing.map((i) => i.id))

      const newInvitees = ids
        .filter((id) => !existingIds.has(id))
        .map((id) => ({
          id,
          type,
          action: prev.find((i) => i.id === id)?.action ?? 'read',
        }))

      return [...existing, ...newInvitees]
    })
  }

  function handleRemove(invitee: Invitee) {
    setInvitees((prev) => {
      return prev.filter((i) => i.id !== invitee.id)
    })
  }

  const title = policy == null ? 'Add automation' : 'Edit automation'

  return (
    <FormModal
      title={title}
      submitAction={{
        text: templateStr('{{action}} automation', {
          action: policy == null ? 'Create' : 'Update',
        }),
        onAction: handleSubmit,
      }}
      visible
      onClose={close}
    >
      <div className='flex flex-col gap-3'>
        <Row>
          <Text>If</Text>

          <Tooltip asChild content='More automations coming soon!'>
            <div>
              <PopoverButton disabled size='small'>
                title contains
              </PopoverButton>
            </div>
          </Tooltip>
          <div className='flex-1'>
            <TextField
              labelHidden
              label='Enter text'
              placeholder='Enter text'
              size='small'
              value={condition.value}
              onChange={(value) =>
                setCondition((prev) => ({
                  ...prev,
                  value,
                }))
              }
            />
          </div>
        </Row>

        <Row>
          <Text>share notes & recording with</Text>
          <div className='flex-1 [&_button]:justify-between max-w-full'>
            <PermissionPopover
              invitees={invitees}
              onSelected={handleIdsChange}
              onRemove={handleRemove}
            />
          </div>
        </Row>

        {error != null && (
          <Row>
            <p className='text-sm text-semantic-error-text-default'>{error}</p>
          </Row>
        )}
      </div>
    </FormModal>
  )
}

const Text = classed('span', {
  base: `
    text-semantic-neutral-text-default
    text-xs
  `,
})

const Row = classed('div', {
  base: `
    flex items-center
    gap-2
  `,
})
