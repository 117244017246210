import { ExclamationCircleOutline } from '@motion/icons'
import { type ProviderType } from '@motion/shared/common'
import { logEvent, recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { Events } from '../../analyticsEvents'
import {
  selectEmailAccounts,
  selectMainEmailAccountId,
} from '../../state/email-accounts/email-accounts-slice'
import { deleteEmailAccount } from '../../state/email-accounts/email-accounts-thunks'
import { useAppDispatch, useAppSelector } from '../../state/hooks'
import { addAccount } from '../../utils/auth'
import { AddAccountDropdown } from '../Account/add-account-dropdown'

const formatter = new Intl.ListFormat('en')

export const EmailAccountsErrorBanner = () => {
  const dispatch = useAppDispatch()

  const emailAccounts = useAppSelector(selectEmailAccounts)
  const mainEmailAccountId = useAppSelector(selectMainEmailAccountId)

  const emailsWithErrors = emailAccounts.filter(
    (emailAccount) => emailAccount.status !== 'OK'
  )

  useEffect(() => {
    if (emailsWithErrors.length === 0) {
      return
    }
    recordAnalyticsEvent('PERMISSIONS_ERROR', {
      erroringEmails: emailsWithErrors.map((e) => e.email),
      providerTypes: emailsWithErrors.map((e) => e.providerType),
      status: emailsWithErrors.map((e) => e.status),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailsWithErrors.length])

  const removeStaleEmailAccounts = () => {
    emailsWithErrors.forEach((e) => {
      // Cannot remove main email account
      if (mainEmailAccountId === e.id) {
        return
      }

      void logEvent(Events.CALENDAR_REMOVE_ACCOUNT_CLICK, {
        email: e.email,
      })
      void dispatch(deleteEmailAccount(e.id))
    })
  }

  const onAddAccount = (provider: ProviderType) => {
    removeStaleEmailAccounts()
    addAccount(provider)
    void logEvent(Events.CALENDAR_AUTHLESS_ACCOUNT_SIGNIN)
    void logEvent(Events.AUTH_BANNER_RECONNECT_CLICK)
  }

  if (emailsWithErrors.length === 0) {
    return null
  }

  return (
    <div className='calendar-selection-account electron-drag'>
      <div className='calendar-selection-banner-authless-warning calendar-selection-authless-reconnection'>
        <div className='calendar-selection-banner-items'>
          <div className='calendar-banner-content'>
            <div className='calendar-banner-text'>
              <div className='calendar-selection-banner-authless-icon-container'>
                <ExclamationCircleOutline className='calendar-selection-banner-authless-icon' />
              </div>
              {`We can't access your calendar${
                emailsWithErrors.length > 1 ? 's' : ''
              } (${formatter.format(emailsWithErrors.map((e) => e.email))}).`}
            </div>
            <div className='calendar-banner-buttons electron-no-drag'>
              <Link
                to='/web/settings/calendar'
                className='calendar-selection-banner-authless-red-button'
              >
                Manage Calendars
              </Link>
              <div
                className='calendar-selection-banner-authless-red-button'
                onClick={removeStaleEmailAccounts}
              >
                {emailsWithErrors.length === 1
                  ? 'Remove Calendar'
                  : 'Remove Calendars'}
              </div>
              <AddAccountDropdown
                onAddAccount={onAddAccount}
                placement='bottom-end'
              >
                <div className='calendar-selection-banner-authless-button'>
                  Reconnect Calendar
                </div>
              </AddAccountDropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
