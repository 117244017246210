import { PencilSolid, ProjectCubeSolid, PuzzleSolid } from '@motion/icons'
import { classed } from '@motion/theme'
import { ButtonGroup, IconButton } from '@motion/ui/base'
import { ProjectPalette } from '@motion/ui/project'
import { stripHtml } from '@motion/ui-logic'
import { useModalApi } from '@motion/web-common/modals'

import {
  useDeleteProjectDefinition,
  useFlowTemplateModalUrl,
  useUpdateProjectDefinition,
} from '~/areas/flows'
import { ColorDropdown } from '~/global/components/dropdowns'
import { type ProjectDefinitionWithProjectInfo } from '~/global/hooks'

import { StageTemplateCardMenu } from './stage-template-card-menu'
import {
  CardTitle,
  FlowCard,
  FlowCardHeader,
  ProjectCountBadge,
} from './styled'
import { TemplateStages } from './template-stages'

type FlowTemplateCardProps = {
  flowTemplate: ProjectDefinitionWithProjectInfo
}
export function FlowTemplateCard({ flowTemplate }: FlowTemplateCardProps) {
  const updateFlowTemplate = useUpdateProjectDefinition()
  const deleteFlowTemplate = useDeleteProjectDefinition()
  const buildFlowTemplateModalUrl = useFlowTemplateModalUrl()
  const modalApi = useModalApi()

  const editFlowTemplateUrl = buildFlowTemplateModalUrl({
    forWorkspace: flowTemplate.workspaceId,
    template: 'edit',
    templateId: flowTemplate.id,
  })
  return (
    <FlowTemplateContainer>
      <FlowDescriptionContainer>
        <FlowCardHeader>
          <ProjectPalette color={flowTemplate.color}>
            <ColorDropdown
              onChange={(color) => {
                void updateFlowTemplate(
                  flowTemplate.id,
                  flowTemplate.workspaceId,
                  {
                    definition: {
                      ...flowTemplate,
                      color: color,
                    },
                  }
                )
              }}
              selectedColor={flowTemplate.color}
            >
              <div className='flex p-1 items-center gap-2 rounded bg-palette-bg-light cursor-pointer hover:bg-palette-bg-hover focus:bg-palette-bg-hover size-5'>
                <PuzzleSolid className='size-3 text-palette-highlight-default' />
              </div>
            </ColorDropdown>
          </ProjectPalette>
          <ButtonGroup>
            <IconButton
              icon={PencilSolid}
              sentiment='neutral'
              size='xsmall'
              url={editFlowTemplateUrl}
              variant='muted'
              aria-label='Edit flow template'
            />
            <StageTemplateCardMenu
              onDuplicate={() => {
                modalApi.open('duplicate-flow', {
                  flowId: flowTemplate.id,
                  workspaceId: flowTemplate.workspaceId,
                })
              }}
              onDelete={() => {
                void deleteFlowTemplate(flowTemplate.id)
              }}
            />
          </ButtonGroup>
        </FlowCardHeader>
        <CardTitle>{flowTemplate.name}</CardTitle>
        <FlowDescription className='truncate'>
          {flowTemplate.description
            ? stripHtml(flowTemplate.description.replace(/<p>/g, '\n'))
            : 'No Description provided'}
        </FlowDescription>
        <ProjectCountBadge>
          <ProjectCubeSolid className='size-3' />
          {flowTemplate.projects.length} Active projects
        </ProjectCountBadge>
      </FlowDescriptionContainer>
      <TemplateStages flowTemplate={flowTemplate} />
    </FlowTemplateContainer>
  )
}

const FlowTemplateContainer = classed('div', {
  base: `
    rounded border border-semantic-neutral-border-light bg-semantic-neutral-surface-raised-bg-subtlest
    flex w-full flex-col items-center
  `,
})

const FlowDescriptionContainer = classed(FlowCard, {
  base: `
    h-[150px] gap-3 self-stretch min-w-0
  `,
})

const FlowDescription = classed('span', {
  base: `
    truncate text-semantic-neutral-text-default text-xs font-normal
    max-w-full min-h-8 whitespace-pre-wrap
    line-clamp-2
  `,
})
