import { PlusSolid } from '@motion/icons'
import { ActionDropdown, IconButton } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useModalApi } from '@motion/web-common/modals'
import { type FolderSchema, type WorkspaceSchema } from '@motion/zod/client'

import { useCreateNote } from '~/areas/notes'
import { useProjectModalUrl } from '~/global/navigation'
import { useNavigate } from 'react-router'

type WorkspaceActionMenuProps = {
  workspaceId: WorkspaceSchema['id']
  folderId?: FolderSchema['id']
  allowNewFolder?: boolean
  allowNewNote?: boolean
}

export const WorkspaceCreateMenu = (props: WorkspaceActionMenuProps) => {
  const {
    workspaceId,
    folderId,
    allowNewFolder = true,
    allowNewNote = false,
  } = props

  const modalApi = useModalApi()
  const buildProjectModalUrl = useProjectModalUrl()
  const navigate = useNavigate()
  const createNote = useCreateNote()

  return (
    <ActionDropdown
      sections={[
        {
          items: [
            {
              prefix: <PlusSolid />,
              content: 'New project',
              onAction: () => {
                navigate(
                  buildProjectModalUrl({
                    forWorkspace: workspaceId,
                    forFolder: folderId,
                  })
                )
              },
            },
            allowNewFolder && {
              prefix: <PlusSolid />,
              content: 'New folder',
              onAction: () => {
                modalApi.open('create-new-folder', {
                  workspaceId,
                  folderId,
                })
              },
            },
            allowNewNote && {
              prefix: <PlusSolid />,
              content: 'New note',
              onAction: async () => {
                createNote(
                  {
                    title: '',
                    targetType: 'WORKSPACE',
                    targetId: workspaceId,
                    folderId,
                  },
                  { navigate: true }
                )
              },
            },
          ],
        },
      ]}
    >
      <IconButton
        variant='muted'
        sentiment='neutral'
        size='xsmall'
        icon={PlusSolid}
        onClick={() => {
          recordAnalyticsEvent('FOLDERS_CLICKED_SIDEBAR_BUTTON', {
            itemType: folderId ? 'FOLDER' : 'WORKSPACE',
            button: 'CREATE',
          })
        }}
      />
    </ActionDropdown>
  )
}
