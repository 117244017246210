import { CheckSolid, FilledChevronDownSolid } from '@motion/icons'
import { classed } from '@motion/theme'
import { ActionList, PopoverTrigger, Toggle } from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'
import {
  generateBillingFormula,
  type GenerateBillingFormulaProps,
  INDIVIDUAL_ANNUAL_PLAN_SAVINGS_ABSOLUTE,
  INDIVIDUAL_ANNUAL_PRICE_AMORTIZED_MONTHLY,
  INDIVIDUAL_MONTHLY_PRICE,
  LOW_COST_TRIAL_PRICE,
  Term,
} from '@motion/ui-logic/billing'

import { type ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

import { BucketSeatsDropdown } from '../bucket-seats-dropdown'

type SharedBillingPlanProps = {
  text: ReactNode
  subText?: string
  price: number
  chosenTrialLength: number
  term: Term
  allowLowCostTrial?: boolean
  isTeam: boolean
  minSeats?: number
}

type IndividualBillingPlanProps = SharedBillingPlanProps & {
  isTeam: false
  setBucket: undefined
  bucket: undefined
}

type TeamBillingPlanProps = SharedBillingPlanProps & {
  isTeam: true
  setBucket: (bucket: number) => void
  bucket: number
}

export type BillingPlanProps = IndividualBillingPlanProps | TeamBillingPlanProps

const BillingPlan = ({
  text,
  subText,
  price,
  chosenTrialLength,
  term,
  allowLowCostTrial,
  setBucket,
  isTeam,
  bucket,
  minSeats = 0,
}: BillingPlanProps) => {
  return (
    <div
      className={twMerge(
        'bg-semantic-neutral-bg-default rounded-lg flex flex-col lg:flex-row justify-between items-center py-3 px-4 text-semantic-neutral-text-default}',
        isTeam && 'mb-3'
      )}
    >
      <div className='flex w-full justify-between flex-row lg:flex-col lg:items-start lg:gap-1'>
        <div className='flex flex-row'>
          <span className='font-semibold text-base text-semantic-neutral-text-default'>
            {text}
            {!allowLowCostTrial && !isTeam && ` ($${price}/mo)`}
          </span>
        </div>
        {isTeam && bucket && setBucket ? (
          <div className='flex flex-row gap-1.5 items-center'>
            <BucketSeatsDropdown
              selectedBucket={bucket}
              onChange={setBucket}
              minSeats={minSeats}
            />
            <span className='text-xs text-semantic-neutral-text-subtle'>
              ${price}/mo per seat
            </span>
          </div>
        ) : (
          subText && <BillingSubtext>{subText}</BillingSubtext>
        )}
      </div>
      <div className='flex flex-col w-full mt-[18px] gap-2 lg:items-end lg:mt-0 lg:gap-1'>
        {term === Term.LowCostTrial ? (
          <HighlightedBillingPlan
            line1Big={`$${LOW_COST_TRIAL_PRICE}`}
            line1Small='for first month'
            formulaInputs={{
              isTeam: !!isTeam,
              isAnnual: true,
              seats: isTeam ? bucket : undefined,
              monthlyPrice: price,
            }}
          />
        ) : chosenTrialLength ? (
          <HighlightedBillingPlan
            line1Big='$0'
            line1Small={`for ${chosenTrialLength} day free trial`}
            formulaInputs={{
              isTeam: !!isTeam,
              isAnnual: term === Term.Annual,
              seats: isTeam ? bucket : undefined,
              monthlyPrice: price,
            }}
          />
        ) : allowLowCostTrial && term === Term.Monthly ? (
          <>
            <div className='text-semantic-neutral-text-subtle'>
              <span className='text-lg leading-5 mr-1 font-semibold'>
                ${price}
              </span>
              <span className='font-normal'>per month starting now</span>
            </div>
            <BillingSubtext>then ${price} every month</BillingSubtext>
          </>
        ) : (
          <>
            <div className='flex flex-col items-end'>
              <div className='font-semibold text-semantic-neutral-text-subtle'>
                <span className='text-lg'>
                  ${price * (bucket ?? 1) * (term === Term.Annual ? 12 : 1)}
                </span>
                <span className='text-xs'>
                  /{term === Term.Annual ? 'year' : 'month'}
                </span>
              </div>
              <p className='text-xs text-semantic-neutral-text-subtle'>
                billed {term === Term.Annual ? 'annually' : 'monthly'}
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

type HighlightedBillingPlanProps = {
  line1Big: string
  line1Small: string
} & ({ formulaInputs: GenerateBillingFormulaProps } | { line2: string })
const HighlightedBillingPlan = (props: HighlightedBillingPlanProps) => {
  const { line1Big, line1Small } = props
  let line2: string
  if ('formulaInputs' in props) {
    line2 = templateStr('then {{formula}}', {
      formula: generateBillingFormula(props.formulaInputs),
    })
  } else {
    line2 = props.line2
  }
  return (
    <>
      <div className='text-semantic-primary-text-default'>
        <span className='text-lg leading-5 mr-1 font-semibold'>{line1Big}</span>
        <span className='font-normal'>{line1Small}</span>
      </div>
      <BillingSubtext>{line2}</BillingSubtext>
    </>
  )
}

const BillingSubtext = classed('span', {
  base: 'text-semantic-neutral-text-subtle text-xs font-normal',
})

type BillingPlanSelectorProps = {
  term: Term
  setTerm: (term: Term) => void
  annualTrialLength: number
  monthlyTrialLength: number
  allowLowCostTrial: boolean
  savingsPercent: number
  title?: string
}

export const BillingPlanSelector = ({
  term,
  setTerm,
  annualTrialLength,
  monthlyTrialLength,
  chosenTrialLength,
  allowLowCostTrial,
  savingsPercent,
  title = 'Your plan',
  text,
  ...billingPlanExclusiveProps
}: BillingPlanSelectorProps & BillingPlanProps) => {
  const billingPlanText = allowLowCostTrial ? (
    <BillingPlanDropdown
      annualTrialLength={annualTrialLength}
      monthlyTrialLength={monthlyTrialLength}
      term={term}
      setTerm={setTerm}
    />
  ) : (
    text
  )
  return (
    <div>
      <div className='flex flex-row justify-between items-center mb-3'>
        <p className='text-semantic-neutral-text-default text-xl font-semibold'>
          {title}
        </p>
        <div className='flex flex-row gap-2'>
          {allowLowCostTrial ? null : (
            <BillingPlanToggle
              term={term}
              setTerm={setTerm}
              savingsPercent={savingsPercent}
            />
          )}
        </div>
      </div>
      <div className='border border-semantic-neutral-border-subtle rounded bg-semantic-neutral-bg-default'>
        <BillingPlan
          text={billingPlanText}
          term={term}
          chosenTrialLength={chosenTrialLength}
          allowLowCostTrial={allowLowCostTrial}
          {...billingPlanExclusiveProps}
        />
      </div>
    </div>
  )
}

type BillingPlanDropdownProps = {
  annualTrialLength: number
  monthlyTrialLength: number
  term: Term
  setTerm: (term: Term) => void
}

export const BillingPlanDropdown = ({
  annualTrialLength,
  monthlyTrialLength,
  term,
  setTerm,
}: BillingPlanDropdownProps) => {
  const termToButton = {
    [Term.Annual]: annualTrialLength
      ? `${annualTrialLength}-day free trial`
      : 'Annual plan',
    [Term.LowCostTrial]: `$${LOW_COST_TRIAL_PRICE} for 1 month`,
    [Term.Monthly]: monthlyTrialLength
      ? `${monthlyTrialLength}-day free trial`
      : 'Monthly plan',
  }

  const termToItemText = {
    [Term.Annual]: annualTrialLength
      ? `ANNUAL with ${annualTrialLength} day free trial (SAVE $${INDIVIDUAL_ANNUAL_PLAN_SAVINGS_ABSOLUTE} USD)`
      : 'ANNUAL starting now',
    [Term.LowCostTrial]: `ANNUAL with $${LOW_COST_TRIAL_PRICE} for first month`,
    [Term.Monthly]: monthlyTrialLength
      ? `MONTHLY with ${monthlyTrialLength} day free trial`
      : 'MONTHLY starting now',
  }
  const annualItemSubtext = `then $${INDIVIDUAL_ANNUAL_PRICE_AMORTIZED_MONTHLY} USD/month`
  const monthlyItemSubtext = `then $${INDIVIDUAL_MONTHLY_PRICE} USD/month`

  const makeDropdownItem = (itemTerm: Term) => {
    return {
      content: (
        <div className='mt-2 mb-2 flex'>
          <div className='flex-column w-[256px]'>
            <div className='mb-1'>{termToItemText[itemTerm]}</div>
            <div className='text-sm text-semantic-neutral-text-subtle'>
              {itemTerm === Term.Monthly
                ? monthlyItemSubtext
                : annualItemSubtext}
            </div>
          </div>
          {term === itemTerm && (
            <CheckSolid
              className='text-semantic-blue-text-default'
              style={{
                transform: 'scale(0.75,0.75)',
                transformOrigin: 'top center',
              }}
            />
          )}
        </div>
      ),
      onAction: () => setTerm(itemTerm),
    }
  }

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <ActionList
          onActionAnyItem={close}
          items={[
            makeDropdownItem(Term.Annual),
            makeDropdownItem(Term.LowCostTrial),
            makeDropdownItem(Term.Monthly),
          ]}
        />
      )}
    >
      <div className='flex text-semantic-neutral-text-default hover:text-semantic-blue-text-default cursor-pointer'>
        {termToButton[term]}{' '}
        <FilledChevronDownSolid
          className='text-semantic-neutral-icon-default'
          style={{ transform: 'scale(0.75,0.75)' }}
        />
      </div>
    </PopoverTrigger>
  )
}

type BillingPlanToggleProps = {
  term: Term
  setTerm: (term: Term) => void
  savingsPercent: number
}

export const BillingPlanToggle = ({
  term,
  setTerm,
  savingsPercent,
}: BillingPlanToggleProps) => {
  return (
    <Toggle
      checked={term === Term.Annual}
      onChange={(e) =>
        setTerm(e.currentTarget.checked ? Term.Annual : Term.Monthly)
      }
    >
      <span>Billed yearly (Save {savingsPercent}%)</span>
    </Toggle>
  )
}
