import { convertDateIntervalToDays } from '@motion/shared/flows'
import type { FlowTemplateFormFields } from '@motion/ui-logic/pm/project'

import { calculateAdjustedRelativeDurations } from '~/areas/flows/flow-template-modal/utils'
import { type UseFormReturn } from 'react-hook-form'

export const handleTaskRelativeIntervalUpdate = (
  form: UseFormReturn<FlowTemplateFormFields>,
  stageIndex: number,
  taskIndex: number,
  lock: 'start' | 'due'
) => {
  const stageDeadlineIntervalInDays = convertDateIntervalToDays(
    form.getValues(`stages.${stageIndex}.deadlineInterval`)
  )

  const { duration: startDuration, referenceType: startReferenceType } =
    form.getValues(
      `stages.${stageIndex}.tasks.${taskIndex}.startRelativeInterval`
    )
  const { duration: dueDuration, referenceType: dueReferenceType } =
    form.getValues(
      `stages.${stageIndex}.tasks.${taskIndex}.dueRelativeInterval`
    )

  const adjustedDates = calculateAdjustedRelativeDurations(
    stageDeadlineIntervalInDays,
    { startDuration, dueDuration, startReferenceType, dueReferenceType, lock }
  )

  if (adjustedDates) {
    if (adjustedDates.start) {
      form.setValue(
        `stages.${stageIndex}.tasks.${taskIndex}.startRelativeInterval.duration`,
        {
          ...startDuration,
          ...adjustedDates.start,
        }
      )
    }

    if (adjustedDates.due) {
      form.setValue(
        `stages.${stageIndex}.tasks.${taskIndex}.dueRelativeInterval.duration`,
        {
          ...dueDuration,
          ...adjustedDates.due,
        }
      )
    }
  }
}
