import { API, type ApiTypes, useQueryOptionsFactory } from '@motion/rpc'
import { useStoreModelsFn } from '@motion/rpc-cache'
import {
  type DashboardViewChartSchema,
  type TaskSchema,
} from '@motion/zod/client'

import { useQuery } from '@tanstack/react-query'
import { mapCellSchemaToChartQuery } from '~/areas/charts/utils'
import { usePageData } from '~/areas/project-management/pages/pm-v3/routes'
import { useAppDataContext } from '~/global/contexts'
import { useMemo } from 'react'

type QueryApi = ApiTypes<typeof API.charts.getChartTasks>

export function useChartTasks(chart: DashboardViewChartSchema) {
  const route = usePageData()
  const appDateCtx = useAppDataContext()
  const storeModels = useStoreModelsFn()

  const queryOptionsOf = useQueryOptionsFactory(API.charts.getChartTasks)
  const queryArgs = useMemo(() => {
    const taskQueryArgs = mapCellSchemaToChartQuery(
      appDateCtx,
      chart,
      route.overrides
    )
    return queryOptionsOf(taskQueryArgs)
  }, [appDateCtx, chart, queryOptionsOf, route.overrides])

  return useQuery<QueryApi['queryFnData'], Error, TaskSchema[]>({
    notifyOnChangeProps: ['error', 'data', 'dataUpdatedAt'],
    select: (data: QueryApi['queryFnData']) => {
      return (
        data.data
          .map(({ id }) => {
            return data.models.tasks[id]
          })
          // TODO: Remove this filter. Currently the API returns data ids that don't exist as models, because of bad data
          // https://usemotion.slack.com/archives/C07T1B3SKBJ/p1737730932959429
          .filter(Boolean)
      )
    },
    ...queryArgs,
    // @ts-expect-error - typing
    async queryFn(ctx) {
      const value = await queryArgs.queryFn(ctx as any)
      storeModels(value.models)
      return value
    },
  })
}
