import { createUseMutation } from '@motion/rpc'
import { client } from '@motion/web-common/rpc'

import { deleteAutomationPolicy } from '../../rpc-definition'

const useDeleteAutomationPolicyMutation = createUseMutation(
  deleteAutomationPolicy
)

export function useDeleteAutomationPolicy() {
  return useDeleteAutomationPolicyMutation({
    onSuccess: () => {
      client.invalidateQueries(['automations'])
    },
  })
}
