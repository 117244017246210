import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

export const useCloseMeetingModal = () => {
  const [, setSearchParams] = useSearchParams()

  return useCallback(() => {
    setSearchParams((prev) => {
      prev.delete('mTask')
      prev.delete('meetingInsightsId')
      return prev
    })
  }, [setSearchParams])
}
