import { ScrollArea, useShortcut } from '@motion/ui/base'
import { getWindowData } from '@motion/web-base/env'
import { useAuthenticatedUser } from '@motion/web-common/auth'
import { useModalApi } from '@motion/web-common/modals'

import { useHasCreatedWorkspace } from '~/areas/project-management/pages/pm-revamp/hooks'
import { useSidebarSearchContext } from '~/areas/search/hook'
import { SectionAnalyticsMetadata } from '~/global/analytics'
import { StatefulSidebar } from '~/global/components'
import { useSidebarState } from '~/global/hooks'
import { useNavigateByRouteId } from '~/routing'
import { useToggleIntercom } from '~/utils/toggleIntercom'
import { twMerge } from 'tailwind-merge'

import {
  AddTaskProjectButtons,
  PageLinks,
  PastDueControl,
  SidebarFooter,
  SidebarHeader,
  UpcomingEvent,
} from './components'
import { FirstWorkspaceHint } from './components/first-workspace-hint'
import { WorkspaceProjectSearchField } from './components/workspace-project-search-field'
import { GLOBAL_SIDEBAR_PANEL_CONTAINER_ID } from './constants'
import { GlobalSidebarContextProvider } from './context'
import { Treeviews } from './treeviews'

export function GlobalSidebar() {
  const user = useAuthenticatedUser()
  const navigateByRouteId = useNavigateByRouteId()
  const toggleIntercom = useToggleIntercom()
  const [, , toggleSidebar] = useSidebarState('global')
  const { isElectron } = getWindowData()
  const modalApi = useModalApi()
  const hasCreatedWorkspace = useHasCreatedWorkspace()

  const handleSearch = () => {
    modalApi.open('search', {})
  }

  useShortcut('mod+k', handleSearch)

  function onAddWorkspace() {
    modalApi.prompt('add-workspace', {
      onValue: (workspaceId) =>
        navigateByRouteId('workspace-detail', { workspaceId }),
    })
  }

  return (
    <GlobalSidebarContextProvider>
      <StatefulSidebar
        closedWidth={0}
        sidebarKey='global'
        maxWidth={320}
        minWidth={244}
        hideCloseButton
      >
        <SectionAnalyticsMetadata name='left-sidebar'>
          <div className='relative z-10 w-full h-full flex flex-col items-start border-r border-semantic-neutral-border-default bg-semantic-neutral-surface-bg-subtlest'>
            <div className='flex flex-col gap-2 bg-semantic-neutral-surface-bg-subtle w-full py-3'>
              <SidebarHeader
                closeSidebar={toggleSidebar}
                handleSearch={handleSearch}
                showCloseSidebar={!isElectron}
                toggleIntercom={toggleIntercom}
                user={user}
              />

              <div className='px-3 my-1'>
                <UpcomingEvent />
              </div>

              <AddTaskProjectButtons />
            </div>

            <div className='w-full p-3'>
              <WorkspaceProjectSearchField placeholder='Search sidebar' />
            </div>

            <ScrollArea className='w-full'>
              <div className='flex flex-col w-full h-full'>
                <PrimaryNavigation />

                <div className='py-2'>
                  <Treeviews />
                </div>

                {!hasCreatedWorkspace && (
                  <FirstWorkspaceHint onAddWorkspace={onAddWorkspace} />
                )}
              </div>
            </ScrollArea>

            <SidebarFooter />
          </div>

          <div
            id={GLOBAL_SIDEBAR_PANEL_CONTAINER_ID}
            className='absolute top-0 left-full h-full flex z-0'
          />
        </SectionAnalyticsMetadata>
      </StatefulSidebar>
    </GlobalSidebarContextProvider>
  )
}

const PrimaryNavigation = () => {
  const { hasSearch } = useSidebarSearchContext()

  return (
    <div
      className={twMerge(
        'flex flex-col items-stretch gap-1 px-[14px] py-1.5',
        hasSearch && 'flex-row flex-wrap justify-between gap-0 items-center'
      )}
    >
      <PageLinks />

      <div
        className={
          hasSearch
            ? 'min-w-7 h-7 flex items-center justify-center'
            : '-mx-3 mt-1.5'
        }
      >
        <PastDueControl onlyRenderCount={hasSearch} />
      </div>
    </div>
  )
}
