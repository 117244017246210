import { XSolid } from '@motion/icons'
import { useSharedState } from '@motion/react-core/shared-state'
import { Button, UnstyledModal } from '@motion/ui/base'
import { ActiveFilterKey } from '@motion/ui-logic/pm/data'
import { useHasTreatment } from '@motion/web-common/flags'
import { type DashboardViewCellSchema } from '@motion/zod/client'

import { type ModalTriggerComponentProps } from '~/areas/modals'
import { toViewDefinitionFilters } from '~/areas/project-management/pages/pm-v3/views'
import { useMemo } from 'react'
import { twMerge } from 'tailwind-merge'

import {
  ConnectedTaskPanel,
  GridChart,
  GridDetailsAside,
  GridHeader,
  GridShell,
  HeaderTitle,
  ModalShell,
} from './components'

import { chartComponentLookup } from '../../grid-layout/utils/lookups'
import {
  ChartFieldProvider,
  useValidChartFieldsContext,
} from '../chart-modal/contexts'
import { mergeChartFieldsIntoCell } from '../chart-modal/utils'

declare module '@motion/web-common/flags/definitions' {
  interface AmplitudeExperiments {
    'charts-task-list': FeatureFlag
  }
}

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'expanded-chart-modal': {
      cell: DashboardViewCellSchema
    }
  }
}

export function ExpandedChartModal(
  props: ModalTriggerComponentProps<'expanded-chart-modal'>
) {
  return (
    <UnstyledModal
      modalClassName={twMerge(
        'grid grid-rows-[auto,1fr] bg-modal-bg rounded-lg',
        'w-[90%]'
      )}
      data-testid='expanded-chart-modal'
      visible
      onClose={() => props.close()}
      withAnimation
      overlayClassName='bg-modal-overlay'
    >
      <InnerExpandedChartModal {...props} />
    </UnstyledModal>
  )
}

function InnerExpandedChartModal({
  cell,
  close,
}: ModalTriggerComponentProps<'expanded-chart-modal'>) {
  const showTaskList = useHasTreatment('charts-task-list')

  const Chart = chartComponentLookup(cell.chart.type)
  if (Chart == null) return null

  return (
    <ModalShell>
      <ChartFieldProvider chartCell={cell}>
        <GridShell>
          <GridHeader>
            <HeaderTitle>{cell.title}</HeaderTitle>

            <Button
              iconOnly
              onClick={() => close()}
              sentiment='neutral'
              size='small'
              variant='muted'
            >
              <XSolid />
            </Button>
          </GridHeader>

          <GridChart className={twMerge(!showTaskList && '!col-span-full')}>
            <UpdatedChart chartComponent={Chart} chartCell={cell} />
          </GridChart>
          {showTaskList && (
            <GridDetailsAside>
              <ConnectedTaskPanel chartCell={cell} />
            </GridDetailsAside>
          )}
        </GridShell>
      </ChartFieldProvider>
    </ModalShell>
  )
}

type UpdatedChartProps = {
  chartComponent: any
  chartCell: DashboardViewCellSchema
}

function UpdatedChart({ chartComponent: Chart, chartCell }: UpdatedChartProps) {
  const ctx = useValidChartFieldsContext()
  const [filters] = useSharedState(ActiveFilterKey)

  const updatedChartCell = useMemo(
    () =>
      mergeChartFieldsIntoCell(
        ctx.fields,
        chartCell,
        toViewDefinitionFilters(filters)
      ),
    [ctx.fields, chartCell, filters]
  )

  return <Chart chart={updatedChartCell.chart} title={updatedChartCell.title} />
}
