import { getDefaultRelativeInterval } from '@motion/shared/flows'
import { createPlaceholderId } from '@motion/shared/identifiers'
import { getDefaultChunkDuration } from '@motion/ui-logic'
import { uniqueId } from '@motion/utils/core'

import {
  DEFAULT_EVENT_DURATION,
  DEFAULT_SCHEDULE_MEETING_WITHIN_DAYS,
} from '~/areas/flows/utils'

import {
  type CreateProjectDefinitionSchemaWithoutWorkspaceFields,
  getVariableKeyByName,
  getWrappedVariableKeyByName,
} from './utils'

import {
  createNewRole,
  createNewTextVariable,
  DEFAULT_TEXT_VARIABLES,
} from '../../utils'

export const creativeAndDesignPreset = () => {
  const roles = [
    createNewRole('Account Manager'),
    createNewRole('Designer'),
    createNewRole('Creative Director'),
    createNewRole('Copywriter'),
    createNewRole('Video Producer'),
  ]

  const textVariables = [
    ...DEFAULT_TEXT_VARIABLES,
    createNewTextVariable('Client Name'),
  ]

  return {
    name: 'Creative Campaign Development',
    description:
      '<p>Use this template if you are a creative or design agency managing client projects</p>',
    managerId: null,
    folderId: undefined,
    priorityLevel: 'MEDIUM' as const,
    labelIds: [],
    color: 'gray' as const,
    variables: [...roles, ...textVariables],
    stageDefinitionReferences: [],
    stages: [
      {
        id: createPlaceholderId(uniqueId('stage')),
        name: 'Briefing and Conceptualization',
        color: 'yellow',
        automaticallyMoveToNextStage: true,
        deadlineIntervalDays: 5,
        duration: {
          unit: 'DAYS',
          value: 5,
        },
        tasks: [
          {
            name: `Receive and review client brief for ${getWrappedVariableKeyByName('Client Name', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Account Manager', roles),
            duration: 30,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(30),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
            startRelativeInterval: getDefaultRelativeInterval('STAGE_START'),
            dueRelativeInterval: getDefaultRelativeInterval('STAGE_DUE'),
          },
          {
            name: `Brainstorming session with ${getWrappedVariableKeyByName('Client Name', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName(
              'Creative Director',
              roles
            ),
            duration: DEFAULT_EVENT_DURATION,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(DEFAULT_EVENT_DURATION),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: DEFAULT_SCHEDULE_MEETING_WITHIN_DAYS,
            startRelativeInterval: getDefaultRelativeInterval('STAGE_START'),
            dueRelativeInterval: getDefaultRelativeInterval('STAGE_DUE'),
          },
          {
            name: `Develop initial concepts for ${getWrappedVariableKeyByName('Client Name', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Designer', roles),
            duration: 480,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(480),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
            startRelativeInterval: getDefaultRelativeInterval('STAGE_START'),
            dueRelativeInterval: getDefaultRelativeInterval('STAGE_DUE'),
          },
          {
            name: `Select final concept to develop for ${getWrappedVariableKeyByName('Client Name', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName(
              'Creative Director',
              roles
            ),
            duration: 60,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(60),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
            startRelativeInterval: getDefaultRelativeInterval('STAGE_START'),
            dueRelativeInterval: getDefaultRelativeInterval('STAGE_DUE'),
          },
          {
            name: `Prepare presentation of concept for client for ${getWrappedVariableKeyByName('Client Name', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Designer', roles),
            duration: 30,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(30),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
            startRelativeInterval: getDefaultRelativeInterval('STAGE_START'),
            dueRelativeInterval: getDefaultRelativeInterval('STAGE_DUE'),
          },
          {
            name: `Present to and gather feedback from ${getWrappedVariableKeyByName('Client Name', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Account Manager', roles),
            duration: DEFAULT_EVENT_DURATION,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(DEFAULT_EVENT_DURATION),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: DEFAULT_SCHEDULE_MEETING_WITHIN_DAYS,
            startRelativeInterval: getDefaultRelativeInterval('STAGE_START'),
            dueRelativeInterval: getDefaultRelativeInterval('STAGE_DUE'),
          },
        ],
        variables: [],
      },
      {
        id: createPlaceholderId(uniqueId('stage')),
        name: 'Design Development',
        color: 'orange',
        automaticallyMoveToNextStage: true,
        deadlineIntervalDays: 15,
        duration: {
          unit: 'DAYS',
          value: 15,
        },
        tasks: [
          {
            name: `${getWrappedVariableKeyByName('Client Name', textVariables)} - Refine chosen concept based on feedback`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Designer', roles),
            duration: 120,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(120),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
            startRelativeInterval: getDefaultRelativeInterval('STAGE_START'),
            dueRelativeInterval: getDefaultRelativeInterval('STAGE_DUE'),
          },
          {
            name: `${getWrappedVariableKeyByName('Client Name', textVariables)} - Develop multiple design iterations`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Designer', roles),
            duration: 240,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(240),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
            startRelativeInterval: getDefaultRelativeInterval('STAGE_START'),
            dueRelativeInterval: getDefaultRelativeInterval('STAGE_DUE'),
          },
          {
            name: `Internal design review for ${getWrappedVariableKeyByName('Client Name', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName(
              'Creative Director',
              roles
            ),
            duration: DEFAULT_EVENT_DURATION,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(DEFAULT_EVENT_DURATION),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: DEFAULT_SCHEDULE_MEETING_WITHIN_DAYS,
            startRelativeInterval: getDefaultRelativeInterval('STAGE_START'),
            dueRelativeInterval: getDefaultRelativeInterval('STAGE_DUE'),
          },
          {
            name: `Present design iterations to ${getWrappedVariableKeyByName('Client Name', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Account Manager', roles),
            duration: DEFAULT_EVENT_DURATION,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(DEFAULT_EVENT_DURATION),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: DEFAULT_SCHEDULE_MEETING_WITHIN_DAYS,
            startRelativeInterval: getDefaultRelativeInterval('STAGE_START'),
            dueRelativeInterval: getDefaultRelativeInterval('STAGE_DUE'),
          },
          {
            name: `${getWrappedVariableKeyByName('Client Name', textVariables)} - Approve Final Design`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName(
              'Creative Director',
              roles
            ),
            duration: 60,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(60),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
            startRelativeInterval: getDefaultRelativeInterval('STAGE_START'),
            dueRelativeInterval: getDefaultRelativeInterval('STAGE_DUE'),
          },
        ],
        variables: [],
      },
      {
        id: createPlaceholderId(uniqueId('stage')),
        name: 'Content Creation',
        color: 'pink',
        automaticallyMoveToNextStage: true,
        deadlineIntervalDays: 12,
        duration: {
          unit: 'DAYS',
          value: 12,
        },
        tasks: [
          {
            name: `Write copy for various campaign elements for ${getWrappedVariableKeyByName('Client Name', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Copywriter', roles),
            duration: 30,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(30),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
            startRelativeInterval: getDefaultRelativeInterval('STAGE_START'),
            dueRelativeInterval: getDefaultRelativeInterval('STAGE_DUE'),
          },
          {
            name: `Produce video or multimedia content for ${getWrappedVariableKeyByName('Client Name', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Video Producer', roles),
            duration: 30,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(30),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
            startRelativeInterval: getDefaultRelativeInterval('STAGE_START'),
            dueRelativeInterval: getDefaultRelativeInterval('STAGE_DUE'),
          },
          {
            name: `Edit and finalize all multimedia content for ${getWrappedVariableKeyByName('Client Name', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Video Producer', roles),
            duration: 30,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(30),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
            startRelativeInterval: getDefaultRelativeInterval('STAGE_START'),
            dueRelativeInterval: getDefaultRelativeInterval('STAGE_DUE'),
          },
          {
            name: `Integrate copy and visuals for ${getWrappedVariableKeyByName('Client Name', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Designer', roles),
            duration: 30,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(30),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
            startRelativeInterval: getDefaultRelativeInterval('STAGE_START'),
            dueRelativeInterval: getDefaultRelativeInterval('STAGE_DUE'),
          },
          {
            name: `Review content consistency for ${getWrappedVariableKeyByName('Client Name', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName(
              'Creative Director',
              roles
            ),
            duration: 30,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(30),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: DEFAULT_SCHEDULE_MEETING_WITHIN_DAYS,
            startRelativeInterval: getDefaultRelativeInterval('STAGE_START'),
            dueRelativeInterval: getDefaultRelativeInterval('STAGE_DUE'),
          },
          {
            name: `Client approval of final content for ${getWrappedVariableKeyByName('Client Name', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Account Manager', roles),
            duration: 30,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(30),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
            startRelativeInterval: getDefaultRelativeInterval('STAGE_START'),
            dueRelativeInterval: getDefaultRelativeInterval('STAGE_DUE'),
          },
        ],
        variables: [],
      },
      {
        id: createPlaceholderId(uniqueId('stage')),
        name: 'Production',
        color: 'purple',
        automaticallyMoveToNextStage: true,
        deadlineIntervalDays: 22,
        duration: {
          unit: 'DAYS',
          value: 22,
        },
        tasks: [
          {
            name: `Prepare files for production  for ${getWrappedVariableKeyByName('Client Name', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Designer', roles),
            duration: 30,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(30),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
            startRelativeInterval: getDefaultRelativeInterval('STAGE_START'),
            dueRelativeInterval: getDefaultRelativeInterval('STAGE_DUE'),
          },
          {
            name: `${getWrappedVariableKeyByName('Client Name', textVariables)} - Create Production tasks`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Account Manager', roles),
            duration: 30,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(30),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
            startRelativeInterval: getDefaultRelativeInterval('STAGE_START'),
            dueRelativeInterval: getDefaultRelativeInterval('STAGE_DUE'),
          },
        ],
        variables: [],
      },
      {
        id: createPlaceholderId(uniqueId('stage')),
        name: 'Launch and Implementation',
        color: 'blue',
        automaticallyMoveToNextStage: true,
        deadlineIntervalDays: 7,
        duration: {
          unit: 'DAYS',
          value: 7,
        },
        tasks: [
          {
            name: `Coordinate launch timing with ${getWrappedVariableKeyByName('Client Name', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Account Manager', roles),
            duration: 30,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(30),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
            startRelativeInterval: getDefaultRelativeInterval('STAGE_START'),
            dueRelativeInterval: getDefaultRelativeInterval('STAGE_DUE'),
          },
          {
            name: `Create Data and Monitoring for ${getWrappedVariableKeyByName('Client Name', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Account Manager', roles),
            duration: 30,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(30),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
            startRelativeInterval: getDefaultRelativeInterval('STAGE_START'),
            dueRelativeInterval: getDefaultRelativeInterval('STAGE_DUE'),
          },
        ],
        variables: [],
      },
      {
        id: createPlaceholderId(uniqueId('stage')),
        name: 'Review and Reporting',
        color: 'cyan',
        automaticallyMoveToNextStage: true,
        deadlineIntervalDays: 3,
        duration: {
          unit: 'DAYS',
          value: 3,
        },
        tasks: [
          {
            name: `${getWrappedVariableKeyByName('Client Name', textVariables)} - Compile campaign results and metrics`,
            assigneeUserId: null,
            assigneeVariableKey: null,
            duration: 30,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(30),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
            startRelativeInterval: getDefaultRelativeInterval('STAGE_START'),
            dueRelativeInterval: getDefaultRelativeInterval('STAGE_DUE'),
          },
          {
            name: `${getWrappedVariableKeyByName('Client Name', textVariables)} - Create final report on campaign performance`,
            assigneeUserId: null,
            assigneeVariableKey: null,
            duration: 30,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(30),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
            startRelativeInterval: getDefaultRelativeInterval('STAGE_START'),
            dueRelativeInterval: getDefaultRelativeInterval('STAGE_DUE'),
          },
          {
            name: `Review project outcomes with ${getWrappedVariableKeyByName('Client Name', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: null,
            duration: DEFAULT_EVENT_DURATION,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(DEFAULT_EVENT_DURATION),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: DEFAULT_SCHEDULE_MEETING_WITHIN_DAYS,
            startRelativeInterval: getDefaultRelativeInterval('STAGE_START'),
            dueRelativeInterval: getDefaultRelativeInterval('STAGE_DUE'),
          },
        ],
        variables: [],
      },
    ],
  } satisfies CreateProjectDefinitionSchemaWithoutWorkspaceFields
}
