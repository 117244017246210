import {
  type ColumnDef,
  type ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { type Range, useVirtualizer } from '@tanstack/react-virtual'
import {
  type GroupedNode,
  type Tree,
} from '~/areas/project-management/pages/pm-v3/grouping'
import { type TreeListRowValueType } from '~/areas/project-management/pages/pm-v3/tree-list'
import { type CSSProperties, useRef, useState } from 'react'

import { StyledListRow } from './components'
import { type TreeListOptionsProps, TreeListOptionsProvider } from './contexts'
import { renderLineItem } from './line-lookup'

export type TaskTreeListProps = {
  tree: Tree<GroupedNode<TreeListRowValueType>>
  columnsVisibility?: TreeListOptionsProps['columnsVisibility']
}

const columns: ColumnDef<GroupedNode<TreeListRowValueType>, any>[] = [
  {
    accessorFn: (row) => row,
    header: 'Default',
  },
]

export function TaskTreeList({ tree, columnsVisibility }: TaskTreeListProps) {
  const tableContainerRef = useRef<HTMLDivElement>(null)

  const [expanded, setExpanded] = useState<ExpandedState>(() => {
    const data: Record<string, boolean> = {}

    function expand(item: Tree<GroupedNode<TreeListRowValueType>>) {
      // Only group nodes have children. Leafs don't
      if (item.children != null) {
        data[item.qualifiedKey] = true
        item.children.forEach(expand)
      }
    }

    tree.children.forEach(expand)
    return data
  })

  const table = useReactTable({
    data: tree.values,
    columns,
    state: {
      expanded,
    },
    filterFromLeafRows: true,
    getRowId: (row) => row.qualifiedKey,
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getSubRows: (row) => row.children,
    getExpandedRowModel: getExpandedRowModel(),
  })

  const { rows } = table.getRowModel()

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: (index) => 36,
    getScrollElement: () => tableContainerRef.current,
    getItemKey: (index) => rows[index].id,
    rangeExtractor: (range: Range) => {
      // Full page of rows
      return Array.from(
        { length: Math.min(range.endIndex + range.overscan, range.count) },
        (_, i) => i
      )
    },
    overscan: 10,
  })

  const virtualItems = rowVirtualizer.getVirtualItems()
  const range = {
    startIndex: rowVirtualizer.range?.startIndex ?? 0,
    endIndex: rowVirtualizer.range?.endIndex ?? 0,
    overscan: rowVirtualizer.options.overscan,
    count: rowVirtualizer.options.count,
  }

  return (
    <TreeListOptionsProvider columnsVisibility={columnsVisibility}>
      <div ref={tableContainerRef} className='h-full overflow-auto'>
        <ul
          className='relative'
          style={{
            height: `${rowVirtualizer.getTotalSize()}px`,
          }}
        >
          {virtualItems.map((virtual) => {
            const row = rows[virtual.index]

            const visible =
              virtual.index >= range.startIndex - range.overscan ||
              row.getCanExpand()

            return (
              <StyledListRow
                key={row.id}
                data-row-depth={row.depth}
                expandable={row.original.children != null}
                style={
                  {
                    '--row-height': `${virtual.size}px`,
                    '--row-depth': row.depth,
                  } as CSSProperties
                }
              >
                {visible && renderLineItem({ row })}
              </StyledListRow>
            )
          })}
        </ul>
      </div>
    </TreeListOptionsProvider>
  )
}
