import { ClockOutline, NumbersSolid, type SvgIcon } from '@motion/icons'
import { PopoverTrigger, SearchableList } from '@motion/ui/base'
import { isEqual } from '@motion/utils/core'
import { entries, keys } from '@motion/utils/object'
import { type DashboardViewChartSchema } from '@motion/zod/client'

import { ModalFieldButton } from '~/areas/task-project/components'

import { useChartSettingField } from '../../contexts'
import { ListItem } from '../list-item'

type ChartAggregateFieldProps = {
  label: string
}

export function ChartAggregateField({ label }: ChartAggregateFieldProps) {
  const [aggregateValue, setAggregateValue] = useChartSettingField('aggregate')

  const [selectedKey, selectedItem] = getItemFromAggregateValue(aggregateValue)

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <SearchableList
          searchable={false}
          items={keys(itemMap)}
          computeKey={(item) => item}
          computeSelected={(item) => itemMap[item] === selectedItem}
          onSelect={(item) => {
            setAggregateValue(itemMap[item].value)
            close()
          }}
          renderItem={(item) => (
            <ListItem icon={itemMap[item]?.icon} name={getDataName(item)} />
          )}
        />
      )}
    >
      <ModalFieldButton label={label}>
        {getDataName(selectedKey)}
      </ModalFieldButton>
    </PopoverTrigger>
  )
}

type AggregateValue = DashboardViewChartSchema['aggregate']

const itemMap: Record<
  string,
  {
    value: AggregateValue
    icon: SvgIcon
    name: string
  }
> = {
  count: { value: { type: 'count' }, icon: NumbersSolid, name: 'Count' },
  totalHours: {
    value: { type: 'sum', field: 'duration' },
    icon: ClockOutline,
    name: 'Total hours',
  },
  // remainingHours: { icon: ClockOutline, name: 'Remaining hours' },
  // completedHours: { icon: CheckSolid, name: 'Completed hours' },
  // scheduledHours: { icon: CalendarSolid, name: 'Scheduled hours' },
}

function getDataName(item: string): string {
  return itemMap[item]?.name ?? 'Unknown'
}

function getItemFromAggregateValue(aggregateValue: AggregateValue) {
  return (
    entries(itemMap).find(([k, val]) => isEqual(val.value, aggregateValue)) ?? [
      '',
      null,
    ]
  )
}
