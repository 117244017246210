import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { StageAutoscheduleToggle } from '~/areas/task-project/components/side-panel/header'
import { useFieldArray } from 'react-hook-form'

import { useFlowTemplateForm } from '../../../hooks'

export type StageAutoScheduleFieldProps = {
  tasksPath: `stages.${number}.tasks`
}
export function StageAutoScheduleField({
  tasksPath,
}: StageAutoScheduleFieldProps) {
  const {
    form: { control, watch },
  } = useFlowTemplateForm()

  const { replace } = useFieldArray({
    control,
    name: tasksPath,
    keyName: 'key',
  })
  const tasks = watch(tasksPath)

  const isAutoScheduled = tasks.some((task) => task.isAutoScheduled)

  function onAutoScheduleStage(isAutoScheduled: boolean) {
    recordAnalyticsEvent('STAGE_AUTO_SCHEDULE_TOGGLE', {
      numTasks: tasks.filter((task) => task.isAutoScheduled !== isAutoScheduled)
        .length,
      autoScheduled: isAutoScheduled,
    })

    replace(tasks.map((task) => ({ ...task, isAutoScheduled })))
  }

  return (
    <StageAutoscheduleToggle
      isAutoScheduled={isAutoScheduled}
      label='Auto-schedule all tasks in this stage'
      onChange={onAutoScheduleStage}
      customTooltipContent={
        isAutoScheduled
          ? 'Motion recommends leaving auto-scheduling on. Only turn this off if this stage may not be needed'
          : 'Enable auto-scheduling for all tasks in this stage'
      }
    />
  )
}
