import {
  type AddOperation,
  type Operation,
  type RemoveOperation,
  type ReplaceOperation,
} from './types'

export function keysOf<T extends object>(obj: T): (string & keyof T)[] {
  return Object.keys(obj) as (string & keyof T)[]
}

export function allKeys<T extends object | null | undefined>(
  left: T,
  right: T
): (string & keyof NonNullable<T>)[] {
  const set = new Set<string & keyof NonNullable<T>>()
  if (left != null) {
    keysOf(left).forEach((key) => set.add(key))
  }
  if (right != null) {
    keysOf(right).forEach((key) => set.add(key))
  }
  return Array.from(set)
}

export function hasId<T extends object | null | undefined>(
  obj: T
): obj is T & { id: string } {
  if (obj == null) return false
  return 'id' in obj
}

export function canHaveId<T>(obj: T): obj is T & { id: string } {
  if (obj == null) return true
  return typeof obj === 'object' && 'id' in obj
}

export function isArrayWithIdElement<T>(
  arr: unknown
): arr is (T & { id: string })[] {
  if (arr == null) return false
  if (!Array.isArray(arr)) return false
  return canHaveId(arr[0])
}

export function join(...segments: (number | string)[]) {
  return (
    '/' +
    segments
      .flatMap((x) => (typeof x === 'number' ? [String(x)] : x.split('/')))
      .filter(Boolean)
      .join('/')
  )
}

export function normalizePath(segments: PathSegment) {
  if (typeof segments === 'string') return segments
  if (typeof segments === 'number') return String(segments)
  return join(...segments)
}
export type PathSegment = (number | string)[] | number | string

export const OP = {
  replace<T>(
    path: PathSegment,
    value: T,
    originalValue?: T
  ): ReplaceOperation<T> {
    return { op: 'replace', path: normalizePath(path), value, originalValue }
  },
  remove<T>(path: PathSegment, value?: T): RemoveOperation<T> {
    return { op: 'remove', path: normalizePath(path), value }
  },
  add<T>(path: PathSegment, value: T): AddOperation<T> {
    return { op: 'add', path: normalizePath(path), value }
  },
} satisfies Record<string, (...args: any[]) => Operation>

export function extractProp<T extends object>(input: string): string & keyof T {
  const prop = input.split('/')[1]

  if (prop === undefined) {
    throw new Error('Could not find prop')
  }

  return prop as string & keyof T
}
