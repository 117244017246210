import {
  type DashboardViewBarChartSchema,
  type DashboardViewChartSchema,
  type DashboardViewLineChartSchema,
  type DashboardViewNumberChartSchema,
  type DashboardViewPieChartSchema,
} from '@motion/zod/client'

export function createBaseChart(
  type: DashboardViewChartSchema['type']
): DashboardViewChartSchema {
  if (type === 'pie') {
    return createBasePieChart()
  }

  if (type === 'line') {
    return createBaseLineChart()
  }

  if (type === 'bar') {
    return createBaseBarChart()
  }

  if (type === 'number') {
    return createBaseNumberChart()
  }

  throw new Error('Unknown chart type')
}

function createBasePieChart(): DashboardViewPieChartSchema {
  return {
    type: 'pie',
    item: 'tasks',
    filters: {
      tasks: {
        ordered: [],
        filters: {
          completed: 'exclude',
        },
      },
      projects: {
        ordered: [],
        filters: {},
      },
      workspaces: {
        ordered: [],
        filters: {},
      },
    },
    groupBy: [
      {
        field: 'status.name',
      },
    ],
    aggregate: {
      type: 'count',
    },
    donut: false,
    legend: {
      position: 'auto',
    },
  }
}

function createBaseLineChart(): DashboardViewLineChartSchema {
  return {
    type: 'line',
    item: 'tasks',
    filters: {
      tasks: {
        ordered: [],
        filters: {
          completed: 'exclude',
        },
      },
      projects: {
        ordered: [],
        filters: {},
      },
      workspaces: {
        ordered: [],
        filters: {},
      },
    },
    groupBy: [
      {
        field: 'startDate',
        by: 'week',
        sort: {
          direction: 'asc',
          source: 'value',
        },
        range: {
          operator: 'defined-relative',
          name: 'next-30-days',
        },
      },
    ],
    aggregate: {
      type: 'count',
    },
    legend: {
      position: 'auto',
    },
    interpolation: 'linear',
  }
}

function createBaseBarChart(): DashboardViewBarChartSchema {
  return {
    type: 'bar',
    item: 'tasks',
    filters: {
      tasks: {
        ordered: [],
        filters: {
          completed: 'exclude',
        },
      },
      projects: {
        ordered: [],
        filters: {},
      },
      workspaces: {
        ordered: [],
        filters: {},
      },
    },
    groupBy: [
      {
        field: 'assigneeUserId',
        sort: {
          direction: 'asc',
          source: 'name',
        },
      },
    ],
    aggregate: {
      type: 'count',
    },
    orientation: 'vertical',
    layout: 'stacked',
    legend: {
      position: 'auto',
    },
  }
}

function createBaseNumberChart(): DashboardViewNumberChartSchema {
  return {
    type: 'number',
    item: 'tasks',
    filters: {
      tasks: {
        ordered: [],
        filters: {
          completed: 'exclude',
        },
      },
      projects: {
        ordered: [],
        filters: {},
      },
      workspaces: {
        ordered: [],
        filters: {},
      },
    },
    aggregate: {
      type: 'count',
    },
  }
}
