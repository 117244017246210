import {
  ArrowRightGradientSolid,
  CheckCircleGradientSolid,
  CheckCircleSolid,
  MotionLogoGradientSolid,
  MotionLogoSolid,
} from '@motion/icons'
import { ProgressCircle } from '@motion/ui/base'

import { useMemo } from 'react'
import { twMerge } from 'tailwind-merge'

import { type TutorialLesson, type TutorialSection } from '../types'

export type TutorialListProps = {
  sections: TutorialSection[]
  completedLessonIds: string[]
  activeSectionIndex: number
  activeLessonIndex: number
  onSelectLesson: (lessonId: string) => void
}

export const TutorialList = ({
  sections,
  completedLessonIds,
  activeSectionIndex,
  activeLessonIndex,
  onSelectLesson,
}: TutorialListProps) => {
  return (
    <div className='flex flex-col flex-shrink-0 gap-y-4'>
      {sections.map((section: TutorialSection, idx) => {
        return (
          <TutorialListSection
            section={section}
            completedLessonIds={completedLessonIds}
            active={idx === activeSectionIndex}
            activeLessonIndex={activeLessonIndex}
            key={section.title}
            onSelectLesson={onSelectLesson}
          />
        )
      })}
    </div>
  )
}

type TutorialListSectionProps = {
  section: TutorialSection
  completedLessonIds: string[]
  active: boolean
  activeLessonIndex: number
  onSelectLesson: (lessonId: string) => void
}

const TutorialListSection = ({
  section,
  completedLessonIds,
  active,
  activeLessonIndex,
  onSelectLesson,
}: TutorialListSectionProps) => {
  const completedPercent = useMemo(() => {
    const totalLessons = section.lessons.length
    const completedLessons = section.lessons.filter((lesson) =>
      completedLessonIds.includes(lesson.id)
    ).length
    return Math.round((completedLessons / totalLessons) * 100)
  }, [section, completedLessonIds])

  return (
    <div
      className={twMerge(
        'border border-transparent flex flex-col w-full pb-2 rounded-lg px-2',
        active &&
          'border border-semantic-purple-border-default border-opacity-20 shadow-[0_0_30px_-20px_rgba(0,0,0,0.3)] shadow-semantic-pink-border-active'
      )}
    >
      <div className='flex w-full h-9 px-2 gap-3 items-center'>
        {active ? (
          <MotionLogoGradientSolid className='size-4' />
        ) : (
          <MotionLogoSolid className='text-semantic-neutral-icon-default size-4' />
        )}
        <p className='text-semantic-neutral-text-default text-sm font-medium'>
          {section.title}
        </p>
        <div className='flex ml-auto gap-1'>
          {completedPercent === 100 ? (
            active ? (
              <CheckCircleGradientSolid className='size-4' />
            ) : (
              <CheckCircleSolid className='text-semantic-neutral-icon-default size-4' />
            )
          ) : (
            <ProgressCircle
              size='xsmall'
              labelHidden
              value={completedPercent}
              sentiment={active ? 'purple' : 'neutral'}
              // @ts-expect-error - fine
              className='mt-0.5'
            />
          )}
          <p className='text-xs text-semantic-neutral-text-subtle'>
            {completedPercent}%
          </p>
        </div>
      </div>
      {section.lessons.map((lesson, idx) => (
        <TutorialListLesson
          lesson={lesson}
          key={lesson.id}
          active={active && idx === activeLessonIndex}
          completed={completedLessonIds.includes(lesson.id)}
          onSelectLesson={onSelectLesson}
        />
      ))}
    </div>
  )
}

type TutorialLessonProps = {
  lesson: TutorialLesson
  active: boolean
  completed: boolean
  onSelectLesson: (lessonId: string) => void
}

const TutorialListLesson = ({
  lesson,
  active,
  completed,
  onSelectLesson,
}: TutorialLessonProps) => {
  return (
    <button
      onClick={() => onSelectLesson(lesson.id)}
      className={twMerge(
        'text-sidebar-item-text-default flex w-full h-8 px-2 gap-3 items-center rounded-md',
        active &&
          'bg-purple-gradient bg-opacity-10 text-semantic-gradient-purple'
      )}
    >
      {completed &&
        (active ? (
          <CheckCircleGradientSolid className='size-4' />
        ) : (
          <CheckCircleSolid className='text-semantic-neutral-icon-default size-4' />
        ))}
      {active && !completed && <ArrowRightGradientSolid className='size-4' />}
      <p
        className={twMerge(
          'text-sm text-start',
          !completed && !active && 'pl-7'
        )}
      >
        {lesson.title}
      </p>
    </button>
  )
}
