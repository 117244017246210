import { classed } from '@motion/theme'
import { addComponentName } from '@motion/ui/helpers'

import {
  Fragment,
  type KeyboardEventHandler,
  type MouseEventHandler,
  type ReactNode,
  useRef,
} from 'react'
import { twMerge } from 'tailwind-merge'

import { Link } from '../../internal'
import { focusNextMenuItem } from '../../utils'
import { PopoverTrigger, type PopoverTriggerProps } from '../popover'
import { Shortcut } from '../shortcut'
import { Tooltip, type TooltipProps } from '../tooltip'

export type ActionSection =
  | false
  | {
      items: ActionItem[]
      title?: ReactNode
    }

export type ActionItem =
  // Allow `false` so it can be used with conditions when defining the list of items
  // like `shouldShowXYZ && { content, onAction, ...}`
  | false
  | {
      content: ReactNode
      disabled?: boolean
      destructive?: boolean
      isNested?: boolean

      url?: string
      onAction?: () => void
      onMouseMove?: MouseEventHandler<HTMLLIElement>
      renderPopover?: PopoverTriggerProps['renderPopover']

      shortcut?: string
      prefix?: ReactNode
      suffix?: ReactNode
      tooltip?: TooltipProps['content']
      description?: ReactNode
      active?: boolean
    }

export type ActionListProps = {
  alignItems?: 'start' | 'center'
  onActionAnyItem?: () => void | Promise<void>
  sections?: ActionSection[]
  items?: ActionItem[]
}

export const ActionList = (props: ActionListProps) => {
  const { items, sections, onActionAnyItem, alignItems } = props

  const wrapperRef = useRef<HTMLDivElement>(null)

  const handleKeys: KeyboardEventHandler<HTMLDivElement> = (evt) => {
    const target = evt.target as HTMLElement
    if (!wrapperRef.current?.contains(target)) return

    if (evt.key === 'Enter') {
      evt.preventDefault()
      target.click()
    } else if (evt.key === 'ArrowUp') {
      focusNextMenuItem(wrapperRef.current, target, 'previous')
    } else if (evt.key === 'ArrowDown') {
      focusNextMenuItem(wrapperRef.current, target, 'next')
    }
  }

  if (items == null && sections == null) {
    throw new Error(
      'The ActionList requires at least a list of `items` or `sections`.'
    )
  }

  const finalSections = sections ?? [{ items: items ?? [] }]

  return (
    <div
      ref={wrapperRef}
      onKeyDown={handleKeys}
      {...addComponentName('ActionList')}
    >
      {finalSections
        .filter(
          (section) =>
            section && !!section.items.length && !section.items.every((i) => !i)
        )
        .map((section, idxSection) => {
          if (!section) return null

          return (
            <Fragment key={idxSection}>
              <ul
                role='menu'
                key={idxSection}
                className={twMerge(
                  'p-1',
                  idxSection > 0 && 'border-t border-dropdown-border'
                )}
              >
                {section.title && (
                  <li key={idxSection}>
                    <SectionTitle>{section.title}</SectionTitle>
                  </li>
                )}
                {section.items.map((item, idxItem) => {
                  if (!item) return null

                  return (
                    <li
                      className={twMerge(
                        item.disabled ? 'cursor-not-allowed' : 'cursor-pointer',
                        'group/row'
                      )}
                      key={idxItem}
                      onMouseMove={item.onMouseMove}
                    >
                      <ListItemWrapper
                        item={item}
                        onActionAnyItem={onActionAnyItem}
                      >
                        <ListItem
                          role='menuitem'
                          tabIndex={0}
                          disabled={item.disabled}
                          destructive={item.destructive}
                          isNested={item.isNested}
                          alignItems={alignItems}
                          active={item.active}
                          onClick={async (e) => {
                            if (item.disabled) return

                            if (item.renderPopover == null) {
                              await onActionAnyItem?.()
                            }

                            if (!e.defaultPrevented) {
                              item.onAction?.()
                            }
                          }}
                        >
                          {item.prefix}
                          <span className='flex flex-col grow gap-1 overflow-hidden'>
                            {item.content}
                            {item.description && (
                              <div className='text-xs text-semantic-neutral-text-subtle'>
                                {item.description}
                              </div>
                            )}
                          </span>
                          {item.shortcut ? (
                            <Shortcut shortcut={item.shortcut} />
                          ) : (
                            item.suffix
                          )}
                        </ListItem>
                      </ListItemWrapper>
                    </li>
                  )
                })}
              </ul>
            </Fragment>
          )
        })}
    </div>
  )
}

type ListItemWrapperProps = {
  children: ReactNode
  item: Exclude<ActionItem, false>
  onActionAnyItem?: ActionListProps['onActionAnyItem']
}
const ListItemWrapper = (props: ListItemWrapperProps) => {
  const { item, onActionAnyItem, children } = props

  const { disabled, renderPopover, tooltip, url } = item

  const showLink = url && !disabled

  if (!renderPopover)
    return (
      <Tooltip asChild content={tooltip} placement='left'>
        {showLink ? <Link url={url}>{children}</Link> : children}
      </Tooltip>
    )

  const wrapRenderPopover: PopoverTriggerProps['renderPopover'] = ({
    close,
  }) => {
    return renderPopover({
      close: async () => {
        await onActionAnyItem?.()
        close()
      },
    })
  }

  if (disabled) {
    return (
      <Tooltip asChild content={tooltip} placement='left'>
        {children}
      </Tooltip>
    )
  }

  return (
    <PopoverTrigger offset={10} renderPopover={wrapRenderPopover}>
      {children}
    </PopoverTrigger>
  )
}

export const ListItem = classed('div', {
  base: `
    flex flex-row gap-2 items-center
    w-full
    px-2 py-1.5

    text-sm text-left
    rounded

    hover:bg-dropdown-item-bg-hover

    [&>[data-icon]]:size-[18px]

    focus-visible:isolate
    focus-visible:outline-0
    focus-visible:transition-shadow
    focus-visible:ring-2
    focus-visible:ring-offset-1
    focus-visible:ring-offset-transparent
    focus-visible:ring-dropdown-item-border-focus
  `,
  variants: {
    active: {
      true: `
        bg-dropdown-item-bg-hover
      `,
      false: `
        hover:bg-transparent
      `,
    },
    disabled: { true: 'hover:bg-transparent opacity-50 cursor-not-allowed' },
    isNested: { true: 'hover:bg-transparent' },
    destructive: {
      true: `
        text-semantic-error-text-default
        [&>[data-icon]]:text-semantic-error-icon-default
      `,
      false: `
        text-dropdown-item-text-default
        [&>[data-icon]]:text-dropdown-item-icon-default
      `,
    },
    alignItems: {
      start: 'items-start',
      center: 'items-center',
    },
  },
  defaultVariants: {
    disabled: false,
    destructive: false,
    isNested: false,
    alignItems: 'center',
  },
})

const SectionTitle = classed(ListItem, {
  base: `
    cursor-default
    hover:bg-transparent
    text-xs
    font-bold
  `,
  variants: {
    destructive: {
      true: '',
      false: 'text-sidebar-title',
    },
  },
})
