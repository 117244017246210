import { type ReactNode, useMemo } from 'react'

import {
  defaultTreeListOptionsValue,
  TreeListOptionsContext,
  type TreeListOptionsContextValue,
} from './context'

export type TreeListOptionsProps = {
  columnsVisibility?: Partial<TreeListOptionsContextValue['columnsVisibility']>
  children: ReactNode
}

export function TreeListOptionsProvider({
  children,
  columnsVisibility,
}: TreeListOptionsProps) {
  const value = useMemo<TreeListOptionsContextValue>(() => {
    return {
      columnsVisibility: {
        ...defaultTreeListOptionsValue.columnsVisibility,
        ...columnsVisibility,
      },
    }
  }, [columnsVisibility])

  return (
    <TreeListOptionsContext.Provider value={value}>
      {children}
    </TreeListOptionsContext.Provider>
  )
}
