import { type MeetingUrlSearchParams } from '@motion/ui-logic'

import { type MeetingModalLocationState } from '~/global/navigation'
import { useSearchParams } from '~/routing'
import { type Location, useLocation } from 'react-router'

import { ConnectedEventModalAIHackerhouse } from './event-modal'
import { useCloseMeetingModal } from './hooks'

type ConnectedMeetingModalProps = {
  open: boolean
}

export function ConnectedMeetingModalAiHackerhouse({
  open,
}: ConnectedMeetingModalProps) {
  const closeModal = useCloseMeetingModal()
  const { mTask } = useSearchParams<MeetingUrlSearchParams>()
  const { state: locationState } = useLocation() as Location<
    MeetingModalLocationState | null | undefined
  >
  const { eventIdOverride } = locationState ?? {}

  return (
    <ConnectedEventModalAIHackerhouse
      open={open}
      close={closeModal}
      meetingTaskId={mTask}
      eventId={eventIdOverride}
    />
  )
}
