import { type ProjectSchema, type StageSchema } from '@motion/rpc-types'
import {
  AutoScheduleToggle,
  type AutoScheduleToggleProps,
  Tooltip,
} from '@motion/ui/base'
import {
  StageAutoScheduleTooltipContent,
  StageOrProjectAutoscheduleDisabledMessage,
} from '@motion/ui-logic'
import { getStageVariant } from '@motion/ui-logic/pm/project'

import { useAutoScheduleStage } from '~/areas/flows'
import { useStageTasksToAutoSchedule } from '~/areas/task-project/hooks'
import { type ReactNode } from 'react'

export type ConnectedStageAutoscheduleToggleProps = {
  projectStage: StageSchema
  project: ProjectSchema
  size?: AutoScheduleToggleProps['size']
}
export function ConnectedStageAutoscheduleToggle({
  projectStage,
  project,
  size = 'xsmall',
}: ConnectedStageAutoscheduleToggleProps) {
  const stageVariant = getStageVariant(projectStage)

  const stageTasksToAutoSchedule = useStageTasksToAutoSchedule({
    project,
    stageDefinitionId: projectStage.stageDefinitionId,
  })

  const isAutoScheduled = stageTasksToAutoSchedule.some(
    (task) => task.isAutoScheduled
  )

  const onAutoScheduleStage = useAutoScheduleStage({
    projectId: project.id,
    stageDefinitionId: projectStage.stageDefinitionId,
  })

  if (stageVariant !== 'default') {
    return null
  }

  return (
    <StageAutoscheduleToggle
      disabled={stageTasksToAutoSchedule.length === 0}
      isAutoScheduled={isAutoScheduled}
      size={size}
      onChange={onAutoScheduleStage}
    />
  )
}

export type StageAutoscheduleToggleProps = {
  customTooltipContent?: string
  isAutoScheduled: boolean
  onChange: (checked: boolean) => void
  label?: ReactNode
} & Pick<AutoScheduleToggleProps, 'size' | 'disabled'>
export function StageAutoscheduleToggle({
  customTooltipContent,
  isAutoScheduled,
  label,
  onChange,
  size = 'xsmall',
  disabled = false,
}: StageAutoscheduleToggleProps) {
  const disabledMessage = disabled
    ? StageOrProjectAutoscheduleDisabledMessage
    : undefined
  const tooltipMessage = isAutoScheduled
    ? StageAutoScheduleTooltipContent.autoScheduled
    : StageAutoScheduleTooltipContent.notAutoScheduled

  const tooltipContent = customTooltipContent
    ? customTooltipContent
    : (disabledMessage ?? tooltipMessage)

  return (
    <Tooltip content={tooltipContent} asChild>
      <AutoScheduleToggle
        checked={isAutoScheduled}
        disabled={disabled}
        labelHidden={!label}
        onChange={(e) => onChange(e.target.checked)}
        size={size}
      >
        {label}
      </AutoScheduleToggle>
    </Tooltip>
  )
}
