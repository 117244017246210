import { usePlannerProps } from '~/areas/project-management/pages/pm-v3/planner/context'
import { useProject } from '~/global/hooks'
import { useMemo } from 'react'

import { useResizeStageState } from './use-resize-stage-state'

import { isLastStage } from '../utils'
/*
 * This hook is used to resize the project from the stage bar.
 * In the case that a stage is resized to the left and passes the project start date, the difference of delta should be passed back
 * Or in the case where a stage is resized to the right in any way, the difference of delta should be passed back
 * @param projectId - The id of the project to check against
 * @returns The delta to be passed back to the project (left and width)
 */
export function useResizeProjectFromStage(projectId: string) {
  const [resizingStagesState] = useResizeStageState()
  const {
    delta = 0,
    projectId: resizingProjectId,
    stageId,
  } = resizingStagesState.stageDetails || {}
  const project = useProject(projectId)
  const [{ shiftModifier }] = usePlannerProps()

  return useMemo(() => {
    if (!stageId || delta === 0 || resizingProjectId !== projectId || !project)
      return { left: 0, width: 0 }

    if (isLastStage(stageId, project.stages)) {
      return { left: 0, width: delta }
    }

    // do not change the project dates if we're using the shift modifier and stage resizing
    // as stages will "ABSORB" instead of "SHIFT"
    if (shiftModifier) {
      return { left: 0, width: 0 }
    }

    return { left: 0, width: delta }
  }, [delta, project, projectId, resizingProjectId, shiftModifier, stageId])
}
