import { type DashboardViewCellSchema } from '@motion/zod/client'

import {
  ChartAggregateField,
  ChartColorField,
  ChartDataField,
  ChartFirstGroupByField,
  ChartSecondGroupByField,
} from '../fields'
import { ChartInterpolationField } from '../fields/chart-interpolation-field'
import { ChartOrientationField } from '../fields/chart-orientation-field'
import { ChartPieStyleField } from '../fields/chart-pie-style-field'
import { SidebarSection, SidebarSectionTitle } from '../styled'

type ChartSettingsSectionProps = {
  cell: DashboardViewCellSchema
}

export function ChartSettingsSection({ cell }: ChartSettingsSectionProps) {
  return (
    <SidebarSection>
      <SidebarSectionTitle>Chart settings</SidebarSectionTitle>

      <ChartSettingsTypeSwitch cell={cell} />
    </SidebarSection>
  )
}

function ChartSettingsTypeSwitch({ cell }: ChartSettingsSectionProps) {
  switch (cell.chart.type) {
    case 'pie':
      return <PieChartSettings cell={cell} />
    case 'bar':
      return <BarChartSettings cell={cell} />
    case 'line':
      return <LineChartSettings cell={cell} />
    case 'number':
      return <NumberChartSettings cell={cell} />
    case 'bar':
      return <BarChartSettings cell={cell} />
  }
}

function PieChartSettings({ cell }: ChartSettingsSectionProps) {
  return (
    <>
      <ChartPieStyleField />
      <ChartDataField />
      <ChartAggregateField label='Value' />
      <ChartFirstGroupByField label='Group by' byLabel='Time unit' />
    </>
  )
}

function BarChartSettings({ cell }: ChartSettingsSectionProps) {
  return (
    <>
      <ChartOrientationField />
      <ChartDataField />
      <ChartAggregateField label='Y-Axis' />
      <ChartFirstGroupByField label='X-Axis' byLabel='Time unit' />
      <ChartSecondGroupByField label='Group by' />
    </>
  )
}

function LineChartSettings({ cell }: ChartSettingsSectionProps) {
  return (
    <>
      <ChartDataField />
      <ChartAggregateField label='Y-Axis' />
      <ChartFirstGroupByField label='X-Axis' byLabel='Time unit' />
      <ChartSecondGroupByField label='Group by' />
      <ChartInterpolationField />
    </>
  )
}

function NumberChartSettings({ cell }: ChartSettingsSectionProps) {
  return (
    <>
      <ChartDataField />
      <ChartAggregateField label='Field' />
      <ChartColorField />
    </>
  )
}
