import { useClosure } from '@motion/react-core/hooks'

import { useEffect } from 'react'

import { useActivityFeedContext } from '../providers'
import { type ReplyActionPayload } from '../types'

export function useOnReplyAction(fn: (payload: ReplyActionPayload) => void) {
  const { onReplyAction } = useActivityFeedContext()
  const closure = useClosure(fn)

  useEffect(() => {
    const unsub = onReplyAction(closure)

    return unsub
  }, [closure, onReplyAction])
}
