import {
  CheckSolid,
  ExclamationTriangleSolid,
  PencilSolid,
  XSolid,
} from '@motion/icons'
import { type TaskSchema } from '@motion/rpc-types'
import { classed } from '@motion/theme'
import { IconButton, Tooltip } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'

import { useActionItemForm } from '~/areas/ai-hackerhouse/event/modals/action-item-modal/hooks'
import { useTaskUpdater } from '~/areas/tasks/hooks'
import { NAME_FIELD_RULE } from '~/global/rules'
import { type ReactNode, useState } from 'react'
import { useController } from 'react-hook-form'

type ActionItemTitleProps = {
  task?: TaskSchema | null
  disabled?: boolean
  isRejected?: boolean
  isTaskCanceled?: boolean
  notPermittedWorkspace?: boolean
  renderStatus: () => ReactNode
  onClick: () => void
  forceInputMode?: boolean
}

export function ActionItemTitle({
  task,
  disabled = false,
  isRejected = false,
  isTaskCanceled = false,
  notPermittedWorkspace = false,
  renderStatus,
  onClick,
  forceInputMode = false,
}: ActionItemTitleProps) {
  const { form } = useActionItemForm()
  const { control } = form
  const { field: nameField } = useController({
    name: 'name',
    control,
    rules: NAME_FIELD_RULE,
  })

  const taskExists = task != null
  const currentName = taskExists ? task.name : nameField.value
  const updateTask = useTaskUpdater()

  const [inEditMode, setInEditMode] = useState(false)

  const handleClickEdit = async () => {
    // entering edit mode
    setInEditMode(true)
  }

  const handleClickSave = async (value: string) => {
    // leaving edit mode/saving
    nameField.onChange(value)

    if (taskExists) {
      await updateTask(task.id, { name: value })
    }

    setInEditMode(false)
  }

  const handleInputChange = (value: string) => {
    if (forceInputMode) {
      nameField.onChange(value)
    }
  }

  return (
    <div className='flex flex-row w-full min-w-0 items-center rounded-md px-1 py-0.5 gap-2 text-2xs text-semantic-neutral-text-subtle'>
      <TaskTitleContainer>
        <div className='shrink-0'>{renderStatus()}</div>
        {inEditMode || forceInputMode ? (
          <EditTaskNameInput
            initialName={currentName}
            cancel={() => setInEditMode(false)}
            save={(value) => handleClickSave(value)}
            handleInputChange={handleInputChange}
            showButtons={!forceInputMode}
          />
        ) : (
          <div className='flex flex-row gap-1'>
            <button
              disabled={disabled}
              aria-disabled={disabled}
              onClick={onClick}
              type='button'
              className='[&>*]:whitespace-nowrap'
            >
              <TaskTitle strikethrough={isRejected || isTaskCanceled}>
                <Tooltip
                  renderContent={() =>
                    notPermittedWorkspace
                      ? "This task was created in a workspace you don't have access to."
                      : null
                  }
                  asChild
                >
                  <div className='flex flex-row gap-2'>
                    <span className='truncate'>{currentName}</span>
                    {notPermittedWorkspace && (
                      <ExclamationTriangleSolid className='size-3 flex items-center text-semantic-warning-text-default mt-1 shrink-0' />
                    )}
                  </div>
                </Tooltip>
              </TaskTitle>
            </button>
            {!isRejected && !isTaskCanceled && !disabled && (
              <IconButton
                onClick={handleClickEdit}
                icon={PencilSolid}
                sentiment='neutral'
                variant='mutedBg'
                size='xsmall'
              />
            )}
          </div>
        )}
      </TaskTitleContainer>
    </div>
  )
}

type EditTaskNameInputProps = {
  initialName: string
  cancel: () => void
  save: (newName: string) => void
  handleInputChange?: (value: string) => void
  showButtons?: boolean
}

function EditTaskNameInput({
  initialName,
  cancel,
  save,
  handleInputChange: handleInputChangeFromProps,
  showButtons = true,
}: EditTaskNameInputProps) {
  const [newName, setNewName] = useState<string>(initialName)

  const handleInputChange = (value: string) => {
    setNewName(value)
    handleInputChangeFromProps?.(value)
  }

  return (
    <div className='flex flex-row gap-1 min-w-0 w-full shrink-0'>
      <div className='min-w-[400px]'>
        <TextField
          value={newName}
          onChange={handleInputChange}
          size='xsmall'
          autoFocus
          variant='minimal'
          fullWidth
        />
      </div>
      {showButtons && (
        <span className='flex flex-row gap-px'>
          <IconButton
            onClick={() => save(newName)}
            icon={CheckSolid}
            sentiment='neutral'
            variant='mutedBg'
            size='xsmall'
            disabled={newName === initialName || newName.trim() === ''}
          />
          <IconButton
            onClick={cancel}
            icon={XSolid}
            sentiment='neutral'
            variant='mutedBg'
            size='xsmall'
          />
        </span>
      )}
    </div>
  )
}

const TaskTitle = classed('span', {
  base: 'truncate min-w-0',
  variants: {
    strikethrough: {
      true: 'line-through text-semantic-neutral-text-muted',
      false:
        'underline underline-offset-auto decoration-solid decoration-skip-ink-none decoration-from-font text-semantic-primary-text-default',
    },
  },
})

const TaskTitleContainer = classed('span', {
  base: 'min-w-0 w-full text-[13px] leading-[16px] font-normal flex items-center gap-1.5',
})
