import { classed } from '@motion/theme'

// These styles are identical to the ones in ./shared except for the lack of border and different padding

export const Shell = classed('div', {
  base: 'p-6 flex flex-col gap-3 overflow-x-hidden',
})

export const Header = classed('header', {
  base: `flex justify-between items-center gap-2`,
})

export const HeaderTitle = classed('div', {
  base: `text-semantic-neutral-text-default font-semibold text-base`,
})

export const Body = classed('div', {
  base: `text-semantic-neutral-text-default text-sm`,
})

export const Footer = classed('footer', {
  base: `flex justify-end self-stretch mt-2`,
})
