import type { ToggleProps } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useAppDispatch } from '~/state/hooks'
import {
  persistShowCompletedTasks,
  setShowCompletedTasks,
} from '~/state/mainSlice'

import { useCalendarState, useSendCalendarState } from './use-calendar-state'

export function useCompletedTasksDisplaySetting(): [
  boolean,
  ToggleProps['onChange'],
] {
  const dispatch = useAppDispatch()
  const settings = useCalendarState((data) => data.settings)
  const { showCompletedEntities: fromCalendarState } = settings
  const setCalendarState = useSendCalendarState()

  const onChangeCompletedTasks: ToggleProps['onChange'] = (e) => {
    const checked = e.currentTarget.checked

    if (checked) {
      recordAnalyticsEvent('CALENDAR_TOGGLE_SHOW_COMPLETED_TASKS_ON')
    } else {
      recordAnalyticsEvent('CALENDAR_TOGGLE_SHOW_COMPLETED_TASKS_OFF')
    }

    setCalendarState((prev) => ({
      ...prev,
      settings: {
        ...prev.settings,
        showCompletedEntities: checked ? 'include' : 'exclude',
      },
    }))

    dispatch(setShowCompletedTasks(checked))
    // To save the setting to firebase. Should be cleaned up.
    void dispatch(
      persistShowCompletedTasks({
        showCompletedTasks: checked,
      })
    )
  }

  return [fromCalendarState === 'include', onChangeCompletedTasks]
}
