import { CalendarEvent } from '@motion/ui/calendar'
import { colorOptions } from '@motion/ui/palette'
import { type ColorId } from '@motion/ui-logic'
import { parseDate } from '@motion/utils/dates'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { type CalendarEventSchemaV2 } from '@motion/zod/client'

import { getEventColorId } from '~/areas/event/utils'
import { useCachedItem } from '~/global/cache'
import { useProject } from '~/global/hooks'

import {
  useSendScheduleAssistantCalendarContext,
  useUpdateSelectedCalendarEvent,
} from '../../hooks'

type RecentlyCreatedEventProps = {
  event: CalendarEventSchemaV2
}

export function RecentlyCreatedEvent({ event }: RecentlyCreatedEventProps) {
  const calendar = useCachedItem('calendars', event.calendarId)
  const sendCalendarEvent = useUpdateSelectedCalendarEvent()
  const updateCalendarState = useSendScheduleAssistantCalendarContext()

  // The meeting task should have already been filtered out by `useSelectExistingScheduleEntities`, but we keep this here just in case
  const meetingTask = useCachedItem('tasks', event.meetingTaskId)
  const project = useProject(meetingTask?.projectId)

  const onClick = () => {
    sendCalendarEvent({
      id: event.id,
    })
    updateCalendarState({
      selectedDate: parseDate(event.start),
    })

    recordAnalyticsEvent('SCHEDULE_ASSISTANT_EVENT_SELECTED', {
      type: 'recently-created',
    })
  }

  return (
    <button type='button' className='h-10 text-left' onClick={onClick}>
      <CalendarEvent
        colorId={
          getEventColorId(event.colorId as ColorId | null, {
            projectColor: project?.color,
            calendarColorId: calendar?.colorId as ColorId | undefined,
          }) ?? colorOptions.gray.colorId
        }
        name={event.title}
        startTime={event.start}
        endTime={event.end}
        showDate
      />
    </button>
  )
}
