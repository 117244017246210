import {
  type TaskDefinitionSchema,
  type VariableDefinitionSchema,
} from '@motion/rpc-types'
import { templateStr } from '@motion/ui-logic'

import { VariableLabel } from '~/global/components/labels'

export function TaskDefinitionName({
  task,
  variables,
}: {
  task: TaskDefinitionSchema
  variables: VariableDefinitionSchema[]
}) {
  const taskNameHtml = templateStr(task.name, (key) => {
    const variable = variables.find((v) => v.key === key)
    return variable ? (
      <VariableLabel value={variable} size='xsmall' />
    ) : (
      <span>{key}</span>
    )
  })

  return (
    <div className='text-2xs font-normal text-semantic-neutral-text-default inline-block'>
      {taskNameHtml}
    </div>
  )
}
