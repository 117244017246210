import { type DashboardViewGroupBySchema } from '@motion/rpc-types'

import type { FilterDefinition } from '~/areas/project-management/filters'

import { ConnectedArchivedFilter, ConnectedCompletedFilter } from './dropdowns'

import { isDateBasedGroupBy } from '../../utils'

export const additionalChartingTaskFilters = [
  {
    key: 'completed',
    label: 'Completed',
    renderDropdown: (props) => <ConnectedCompletedFilter {...props} />,
  },
  {
    key: 'archived',
    label: 'Archived',
    renderDropdown: (props) => <ConnectedArchivedFilter {...props} />,
  },
] satisfies FilterDefinition<'tasks'>[]

export const filterOverriddenFields = (
  def: { key: string },
  filters: string[],
  fullGroupBy: DashboardViewGroupBySchema[] = []
) => {
  // completedTime is a special case, we don't want to show completed filter if completedTime is selected
  if (
    filters.includes('completedTime') ||
    fullGroupBy.some((x) => x.field === 'completedTime')
  ) {
    if (def.key === 'completed') {
      return false
    }
  }

  // Hide matching date filter if date is selected as group by
  if (isDateBasedGroupBy(fullGroupBy[0])) {
    if (def.key === fullGroupBy[0].field) {
      return false
    }
  }

  return true
}
