import { PencilSolid } from '@motion/icons'
import { Button, type ButtonProps } from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'
import { type FlowTemplateFormTask } from '@motion/ui-logic/pm/project'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'

import { useCallback } from 'react'

import { useFlowsModalState } from '../../../contexts'
import { useFlowTemplateForm } from '../../../hooks'

type Props = {
  icon?: React.ReactNode
  text?: string
  taskBasePath: `stages.${number}.tasks.${number}`
  variant?: ButtonProps['variant']
}

export const EditTaskDefinitionButton = ({
  icon,
  text,
  taskBasePath,
  variant,
}: Props) => {
  const modalApi = useModalApi()
  const { dirtyTasksMap } = useFlowsModalState()
  const { form } = useFlowTemplateForm()
  const { watch, getValues } = form

  const workspaceId = watch('workspaceId')
  const task = watch(taskBasePath)

  const handleTaskDefinitionChange = useTaskDefinitionChangeFn(taskBasePath)

  const openTaskDefinitionModal = async () => {
    recordAnalyticsEvent('OPEN_TASK_DEFINITION_MODAL', {
      taskId: task.id,
    })

    const response = await modalApi.prompt('task-definition-modal', {
      taskDefinitionId: task.id,
      initialDirtyTasks: dirtyTasksMap,
      workspaceId,
      flowFormData: getValues(),
    })

    recordAnalyticsEvent('CLOSE_TASK_DEFINITION_MODAL', {
      taskId: task.id,
      state: response === ModalDismissed ? 'dismissed' : 'updated',
    })

    if (response !== ModalDismissed) {
      handleTaskDefinitionChange(response)
    }
  }

  return (
    <Button
      data-testid='edit-task-definition-button'
      variant={variant ?? 'outlined'}
      sentiment='neutral'
      size='xsmall'
      onClick={openTaskDefinitionModal}
    >
      {templateStr('{{icon}} {{text}}', {
        icon: icon ?? <PencilSolid />,
        text: text ?? 'Edit',
      })}
    </Button>
  )
}

function useTaskDefinitionChangeFn(
  taskBasePath: `stages.${number}.tasks.${number}`
) {
  const { form } = useFlowTemplateForm()
  const { getValues, setValue } = form

  return useCallback(
    (response: FlowTemplateFormTask) => {
      const task = getValues(taskBasePath)

      // Need to check id of the task definition to see if it has changed
      // If it has, we need to update that task definition in the form
      if (task.id === response.id) {
        setValue(taskBasePath, response, { shouldDirty: true })
        /**
         * Why doesn't the above set the minimumDuration? I have no idea.
         */
        setValue(`${taskBasePath}.minimumDuration`, response.minimumDuration, {
          shouldDirty: true,
        })
      } else {
        const stages = getValues('stages')
        const indices = stages.reduce<
          { stageIndex: number; taskIndex: number } | undefined
        >((acc, stage, stageIndex) => {
          if (acc) return acc

          const taskIndex = stage.tasks.findIndex(
            (task) => task.id === response.id
          )
          return taskIndex !== -1 ? { stageIndex, taskIndex } : undefined
        }, undefined)

        if (indices) {
          setValue(
            `stages.${indices.stageIndex}.tasks.${indices.taskIndex}`,
            response,
            { shouldDirty: true }
          )
          setValue(
            `stages.${indices.stageIndex}.tasks.${indices.taskIndex}.minimumDuration`,
            response.minimumDuration,
            { shouldDirty: true }
          )
        }
      }
    },
    [setValue, taskBasePath, getValues]
  )
}
