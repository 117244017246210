import { isCanceledStatus, isCompletedStatus } from '@motion/shared/common'
import { cssVar } from '@motion/theme/dom'
import { type StatusSchema } from '@motion/zod/client'

export function colorOfStatus(status: StatusSchema) {
  if (isCompletedStatus(status)) {
    return cssVar('palette-green-border-strong')
  }

  if (isCanceledStatus(status)) {
    return cssVar('palette-red-border-strong')
  }

  return status.color
}
