import { StartDateSolid } from '@motion/icons'
import { type StageDefinitionSchema } from '@motion/rpc-types'
import { type DeadlineInterval } from '@motion/shared/flows'
import { FieldButton, Tooltip } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useFlowTemplateForm } from '~/areas/flows/flow-template-modal/hooks'
import { getIndexFromName } from '~/areas/flows/flow-template-modal/utils/get-index-from-name'
import { useI18N } from '~/global/contexts'
import { useCallback, useMemo } from 'react'

import { RelativeDateDropdown, RelativeDateLabel } from './components'
import { DurationText } from './components/duration-text'
import {
  getRelativeDateTooltipContent,
  handleTaskRelativeIntervalUpdate,
} from './utils'

import { useStageTaskField } from '../hooks'

export type ControlledRelativeStartFieldProps = {
  color: StageDefinitionSchema['color']
  taskBasePath: `stages.${number}.tasks.${number}`
  size: 'xsmall' | 'small'
  stageDeadlineInterval: DeadlineInterval
}

export const ControlledRelativeStartField = ({
  color,
  taskBasePath,
  size,
  stageDeadlineInterval,
}: ControlledRelativeStartFieldProps) => {
  const { form } = useFlowTemplateForm()
  const [relativeStart, setRelativeStart] = useStageTaskField(
    `${taskBasePath}.startRelativeInterval`
  )
  const { watch } = form

  const taskDuration = watch(`${taskBasePath}.duration`)

  const { pluralize } = useI18N()
  const { duration, referenceType } = relativeStart
  const tooltipContent = useMemo(
    () =>
      getRelativeDateTooltipContent({
        referenceType,
        duration,
        pluralize,
        startOrEnd: 'Start date',
      }),
    [referenceType, duration, pluralize]
  )

  const onChange: typeof setRelativeStart = useCallback(
    (valueOrFn) => {
      const res = setRelativeStart(valueOrFn)

      const { stageIndex, taskIndex } = getIndexFromName(taskBasePath)
      if (stageIndex == null || taskIndex == null) return res

      handleTaskRelativeIntervalUpdate(form, stageIndex, taskIndex, 'start')

      const parsedValue =
        typeof valueOrFn === 'function' ? valueOrFn(relativeStart) : valueOrFn

      recordAnalyticsEvent('TASK_DEFINITION_RELATIVE_DATE_CHANGED', {
        taskValue: 'startDate',
        target: parsedValue.referenceType,
        relative: parsedValue.duration.sign === 1 ? 'plus' : 'minus',
        type: parsedValue.duration.unit,
        value: parsedValue.duration.value,
      })

      return res
    },
    [setRelativeStart, form, taskBasePath, relativeStart]
  )

  if (taskDuration === 0) {
    return null
  }

  return (
    <RelativeDateDropdown
      color={color}
      type='start'
      value={relativeStart}
      onChange={onChange}
      stageDeadlineInterval={stageDeadlineInterval}
    >
      <Tooltip asChild renderContent={() => tooltipContent}>
        <FieldButton variant='muted' fullWidth size={size}>
          <StartDateSolid />
          <RelativeDateLabel color={color} value={referenceType} size={size} />
          <DurationText duration={duration} />
        </FieldButton>
      </Tooltip>
    </RelativeDateDropdown>
  )
}
