import { classed } from '@motion/theme'

export const CommentIconButton = classed('button', {
  base: `
    w-5 h-5 rounded-full
    flex items-center justify-center
    text-button-neutral-muted-icon-default
    border-transparent
    
    [&_svg]:w-4 [&_svg]:h-4

    hover:bg-button-neutral-muted-bg-hover
    active:outline-button-neutral-muted-border-focus
    focus-visible:outline-button-neutral-muted-border-focus
    
    [&.visible]:bg-button-neutral-muted-bg-hover    

    outline
    outline-0
    focus-visible:outline-1
  `,
})
