import { getEnabledStagesWithDates } from '@motion/ui-logic/pm/project'
import { type ProjectSchema } from '@motion/zod/client'

import { BarOverlay } from './components'
import { PlaceholderProjectStageItem } from './placeholder-stage-item'
import { ConnectedResizeableProjectStageItem } from './resizeable-stage-item'

import { type Side } from '../resize-handle'

type StagesBarProps = {
  project: ProjectSchema
  // Amount the project has been resized
  deltaWidth?: number
  // Side that the project is currently being resized from
  currentSide?: Side
  ignoreStageResizing?: boolean
  isPlaceholder?: boolean
}

export const StagesBar = ({
  project,
  deltaWidth = 0,
  currentSide = 'left',
  ignoreStageResizing,
  isPlaceholder = false,
}: StagesBarProps) => {
  const stagesWithDates = getEnabledStagesWithDates(project.stages ?? [], {
    start: project.startDate,
    due: project.dueDate,
  })

  return (
    <div className='w-full h-2.5 relative'>
      <BarOverlay />
      <div className='flex items-center size-full max-w-full overflow-hidden rounded-b-md px-0.5'>
        {stagesWithDates.map((stage, stageIndex) => {
          if (!stage.stage?.id) return null

          return isPlaceholder ? (
            <PlaceholderProjectStageItem
              key={stage.stage?.id ?? `stage-key-${stageIndex}`}
              project={project}
              stageWithDates={stage}
            />
          ) : (
            <ConnectedResizeableProjectStageItem
              projectDeltaWidth={deltaWidth}
              currentSide={currentSide}
              key={stage.stage?.id ?? `stage-key-${stageIndex}`}
              project={project}
              stageWithDates={stage}
              ignoreStageResizing={ignoreStageResizing}
            />
          )
        })}
      </div>
    </div>
  )
}
