import { type NoteSchema } from '@motion/zod/client'

import {
  ColorDropdownContent,
  type ColorDropdownContentProps,
} from '~/global/components/dropdowns'
import { useUpdateNote } from '~/global/rpc/v2'

export type NoteColorPickerProps = {
  selected: ColorDropdownContentProps['selectedColor']
  noteId: NoteSchema['id']
  onChange?: (color: string) => void
}

export function NoteColorPicker(props: NoteColorPickerProps) {
  const { selected, noteId, onChange } = props

  const { mutate: updateNote } = useUpdateNote()

  return (
    <ColorDropdownContent
      selectedColor={selected}
      onChange={async (color) => {
        await updateNote({
          id: noteId,
          color,
        })

        onChange?.(color)
      }}
    />
  )
}
