import { createUseQuery, defineApi } from '@motion/rpc'

const getSignedRecordingUrl = defineApi<
  {
    meetingInsightsId: string
  },
  string
>().using({
  key: (args) => ['meeting-insights-recording', args.meetingInsightsId],
  uri: (args) =>
    `${import.meta.env.MOTION_AI_API_HOST}/recording/${args.meetingInsightsId}/signed`,
})

export const useRecordingSignedUrl = createUseQuery(getSignedRecordingUrl)
