import type { MeetingInsightsSchema } from '@motion/zod/client'

import { GenericSection } from '~/areas/ai-hackerhouse/components/generic-section'
import { UserRating } from '~/areas/ai-hackerhouse/components/user-rating'

export type NotesSectionProps = {
  meetingInsights: MeetingInsightsSchema
}

export function NotesSection({ meetingInsights }: NotesSectionProps) {
  if (meetingInsights.notes == null || meetingInsights.notes.length === 0) {
    return null
  }

  return meetingInsights.notes.map((section, index) => (
    <GenericSection
      key={`${section.title}_${index}`}
      title={section.title}
      content={section.content}
      renderFooter={() =>
        index === (meetingInsights.notes?.length ?? 0) - 1 && (
          <div className='self-end'>
            <UserRating
              label='How accurate were these notes?'
              section='notes'
              meetingInsightsId={meetingInsights.id}
            />
          </div>
        )
      }
    />
  ))
}
