import { classed } from '@motion/theme'

import React, { type ReactNode } from 'react'

import { TabList, type TabListProps } from './tab-list'
import { type TabItem } from './types'

export type TabsProps = {
  value: string
  onChange: (value: string) => void
  children: ReactNode
  gap?: TabListProps['gap']
  outerPadding?: TabListProps['outerPadding']
  size?: TabListProps['size']
  renderEndContent?: TabListProps['renderEndContent']
}

export const Tabs = ({
  value,
  onChange,
  children,
  gap,
  outerPadding,
  size,
  renderEndContent,
}: TabsProps) => {
  const childrenItems = React.Children.toArray(
    children
  ) as React.ReactElement<TabProps>[]

  if (childrenItems.length === 0) {
    return null
  }

  const items = childrenItems.map<
    Exclude<TabListProps['items'][number], false>
  >((child) => {
    return {
      content: child.props.name,
      value: child.props.value,
      onAction: () => onChange(child.props.value),
    }
  })

  const activeValue = value || items[0].value
  const activeTab =
    childrenItems.find((x) => x.props.value === activeValue) ?? childrenItems[0]

  return (
    <TabContainer>
      <TabList
        items={items}
        activeValue={activeValue}
        gap={gap}
        outerPadding={outerPadding}
        size={size}
        renderEndContent={renderEndContent}
      />
      <TabContent>{activeTab}</TabContent>
    </TabContainer>
  )
}

export type TabProps = Omit<TabItem, 'onAction' | 'url' | 'content'> & {
  children: ReactNode
}

export function Tab({ children }: TabProps) {
  return children
}

const TabContainer = classed('div', {
  base: 'grid grid-rows-[auto_1fr] overflow-hidden',
})

const TabContent = classed('div', {
  base: 'text-semantic-neutral-text-default overflow-hidden',
})
