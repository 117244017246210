import { StartDateSolid } from '@motion/icons'

import {
  StartDateDropdown,
  type StartDateDropdownProps,
} from '~/areas/project-management/components'
import { DateTriggerButton } from '~/areas/task-project/components'
import { DateTime } from 'luxon'
import { useController } from 'react-hook-form'

import { useActionItemForm } from '../hooks'

export const StartDateField = () => {
  const { form } = useActionItemForm()

  const { control, watch } = form

  const { field } = useController({
    name: 'startDate',
    control,
  })

  const selectedStartDate = field.value
  const projectId = watch('projectId')
  const priorityLevel = watch('priorityLevel')

  const isASAP = priorityLevel === 'ASAP'

  const today = DateTime.now().toISODate()

  const onChange: StartDateDropdownProps['onChange'] = (value) => {
    field.onChange(value)
  }

  return (
    <StartDateDropdown
      value={selectedStartDate}
      onChange={onChange}
      disableClearing={isASAP}
      contextProps={{
        projectId,
      }}
      dropdownTarget='task'
    >
      <DateTriggerButton
        label='Start date'
        icon={<StartDateSolid />}
        onClear={
          selectedStartDate === today ? undefined : () => onChange(today)
        }
        date={isASAP ? null : selectedStartDate}
        placeholder={isASAP ? 'ASAP' : 'None'}
        clearTooltipContent='Reset start date to today'
      />
    </StartDateDropdown>
  )
}
