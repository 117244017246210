import { XSolid } from '@motion/icons'
import {
  Button,
  IconButton,
  showToast,
  UnstyledModal,
  useShortcut,
} from '@motion/ui/base'
import {
  type FlowTemplateFormFields,
  type FlowTemplateFormTask,
  mapTaskDefinitionFormToFlowTaskDefinition,
} from '@motion/ui-logic/pm/project'
import {
  recordAnalyticsEvent,
  useOnMountAnalyticsEvent,
} from '@motion/web-base/analytics'

import { type ModalTriggerComponentProps } from '~/areas/modals'
import {
  SharedFormShell,
  ShellActions,
  ShellVars,
  SidebarContent,
  SidebarSection,
  SidebarShell,
  SidebarWorkspaceSection,
} from '~/areas/task-project/components'
import {
  GridBanner,
  GridShell,
  MainHeader,
  MainScrollableContent,
  ShellMainFooter,
  SidebarFooter,
} from '~/areas/tasks/modals/task-modal/components'
import { TutorialButtonLink } from '~/global/components'
import { useLegacyStageDefinition } from '~/global/hooks'
import { type FormEvent, useCallback, useMemo, useState } from 'react'

import {
  ControlledAssigneeField,
  ControlledAutoScheduleToggle,
  ControlledBlockersField,
  ControlledDeadlineField,
  ControlledDeadlineTypeToggle,
  ControlledDescriptionField,
  ControlledLabelsField,
  ControlledNameField,
  ControlledPriorityField,
  ControlledStartDateField,
  ControlledStatusField,
  ControlledWorkspaceProjectField,
  CustomFieldsTaskSidebarSection,
  DirtyBanner,
  DurationField,
  Header,
  MinChunkField,
  PreviousNextModalButtons,
  TaskDefinitionAttachmentsCollapsable,
} from './components'
import { useShowNotSavedModalIfDirtyFn } from './hooks'
import { TaskDefinitionForm } from './task-definition-form'
import { TaskDefinitionModalStateProvider } from './task-definition-modal-context'
import { useTaskDefinitionForm } from './use-task-definition-form'
import { useTaskDefinitionModalState } from './use-task-definition-modal-state'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'task-definition-modal': PromptCallbacks<FlowTemplateFormTask> & {
      workspaceId: string
      taskDefinitionId: string
      initialDirtyTasks: Record<string, boolean>
      flowFormData: FlowTemplateFormFields
    }
  }
}

type TaskDefinitionModalProps =
  ModalTriggerComponentProps<'task-definition-modal'>

export const TaskDefinitionModal = ({
  close,
  workspaceId,
  taskDefinitionId,
  initialDirtyTasks,
  flowFormData,
}: TaskDefinitionModalProps) => {
  const [open, setOpen] = useState(true)

  const onClose = useCallback(
    (values?: FlowTemplateFormTask) => {
      setOpen(false)
      setTimeout(() => (values ? close(values) : close()), 200)
    },
    [close]
  )

  return (
    <UnstyledModal
      data-testid='task-definition-modal'
      type='page'
      visible={open}
      onClose={onClose}
      withAnimation
      overlayClassName='bg-modal-overlay'
    >
      <TaskDefinitionModalStateProvider
        workspaceId={workspaceId}
        taskDefinitionId={taskDefinitionId}
        initialDirtyTasks={initialDirtyTasks}
        flowFormData={flowFormData}
      >
        <TaskDefinitionForm>
          <TaskDefinitionModalContent close={onClose} />
        </TaskDefinitionForm>
      </TaskDefinitionModalStateProvider>
    </UnstyledModal>
  )
}

const TaskDefinitionModalContent = ({
  close,
}: Pick<TaskDefinitionModalProps, 'close'>) => {
  const { form, hiddenFields } = useTaskDefinitionForm()
  const {
    watch,
    formState: { isDirty },
  } = form
  const { isTaskDirty, initialTaskData, flowFormData } =
    useTaskDefinitionModalState()
  const showNotSavedModalIfDirty = useShowNotSavedModalIfDirtyFn()

  const onCancel = async () => {
    const response = await showNotSavedModalIfDirty()

    if (response === true) {
      close()
    }
  }

  useShortcut('mod+s', () => onSubmit())
  useShortcut('escape', () => onCancel())

  const taskId = watch('id')
  const taskName = watch('name')
  const workspaceId = watch('workspaceId')
  const stageDefinitionId = watch('stageDefinitionId')
  const stage = useLegacyStageDefinition(stageDefinitionId)

  const isDirtyFromEitherForm = isDirty || isTaskDirty

  useOnMountAnalyticsEvent('FLOW_VIEW_TASK_DEFINITION', {
    properties: {
      existing: taskId != null,
    },
  })

  const onSubmit = async (e?: FormEvent<HTMLFormElement>) => {
    if (!isDirty) return

    await form.handleSubmit(
      (values) => {
        const flowTemplateFormTask =
          mapTaskDefinitionFormToFlowTaskDefinition(values)
        close(flowTemplateFormTask)
      },
      (validationErrors) => {
        const errors = Object.values(validationErrors)
        if (errors.length < 1) return

        const message = errors[0].message
        if (typeof message !== 'string' || !message) return

        showToast('error', message)
      }
    )(e)
  }

  const { task } = initialTaskData

  const stageDeadlineInterval = useMemo(() => {
    const taskStage = flowFormData?.stages.find(
      (stage) => task?.type === 'NORMAL' && stage.id === task.stageDefinitionId
    )
    return taskStage?.deadlineInterval
  }, [flowFormData?.stages, task])

  return (
    <ShellVars columns={2}>
      <SharedFormShell columns={2} onSubmit={onSubmit}>
        <ShellActions>
          <IconButton
            icon={XSolid}
            sentiment='onDark'
            size='small'
            variant='muted'
            onClick={onCancel}
          />

          <PreviousNextModalButtons />
        </ShellActions>
        <GridShell
          withFooter
          layout={isDirtyFromEitherForm ? 'bottomBanner' : 'normal'}
        >
          <>
            <MainHeader className='truncate'>
              <Header />
              <ControlledNameField />
            </MainHeader>

            <SidebarShell>
              <SidebarContent>
                <SidebarWorkspaceSection>
                  <ControlledWorkspaceProjectField />
                </SidebarWorkspaceSection>

                <ControlledAutoScheduleToggle />

                <SidebarSection className='pt-3 modal-lg:pt-5'>
                  <ControlledAssigneeField />
                  <ControlledStatusField />
                  <ControlledPriorityField />
                </SidebarSection>

                <SidebarSection>
                  <DurationField />
                  {!hiddenFields.has('minimumDuration') && <MinChunkField />}

                  <ControlledStartDateField
                    color={stage.color}
                    stageDeadlineInterval={stageDeadlineInterval}
                  />
                  <ControlledDeadlineField
                    color={stage.color}
                    stageDeadlineInterval={stageDeadlineInterval}
                  />

                  {!hiddenFields.has('deadlineType') && (
                    <ControlledDeadlineTypeToggle />
                  )}
                </SidebarSection>

                <SidebarSection>
                  <ControlledLabelsField />

                  <CustomFieldsTaskSidebarSection workspaceId={workspaceId} />
                </SidebarSection>

                <SidebarSection>
                  <ControlledBlockersField type='blockedBy' />
                  <ControlledBlockersField type='blocks' />
                </SidebarSection>
              </SidebarContent>
            </SidebarShell>

            <MainScrollableContent>
              <ControlledDescriptionField />
              <TaskDefinitionAttachmentsCollapsable />
            </MainScrollableContent>

            <ShellMainFooter>
              <TutorialButtonLink
                lesson='workflow-templates'
                rounded
                onClick={() => {
                  close()
                  recordAnalyticsEvent('FLOW_TASK_DEFINITION_TUTORIAL_CLICK')
                }}
              />
            </ShellMainFooter>

            <SidebarFooter>
              <Button
                sentiment='neutral'
                variant='muted'
                shortcut='esc'
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button
                type='submit'
                sentiment='primary'
                variant='solid'
                shortcut='mod+s'
                disabled={!isDirty || taskName.trim().length < 1}
              >
                Update task
              </Button>
            </SidebarFooter>
          </>

          {isDirtyFromEitherForm && (
            <GridBanner layout='bottomBanner'>
              <DirtyBanner />
            </GridBanner>
          )}
        </GridShell>
      </SharedFormShell>
    </ShellVars>
  )
}
