import { Button, PopoverTrigger } from '@motion/ui/base'
import { type ColorId } from '@motion/ui-logic'

import { generalDropdownItemClasses } from '~/components/Common/GeneralComponentStyles'
import { ColorItemBadge } from '~/global/components/badges'
import { calendarColorIds } from '~/storageConstants'
import { type ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

export type CalendarColorDropdownProps = Omit<
  ColorDropdownContentProps,
  'close'
> & {
  children: ReactNode
}

export function CalendarColorDropdown({
  children,
  ...contentProps
}: CalendarColorDropdownProps) {
  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <ColorDropdownContent {...contentProps} close={close} />
      )}
    >
      {children}
    </PopoverTrigger>
  )
}

type ColorDropdownContentProps = {
  currentColorId: ColorId
  onChange: (colorId: ColorId) => void
  colorsToHide?: ColorId[]
  customOptions?: (
    | {
        text: string
        onClick: () => void
      }
    | boolean
  )[]
  close: () => void
}

function ColorDropdownContent({
  currentColorId,
  onChange = () => {},
  colorsToHide = [],
  customOptions = [],
  close,
}: ColorDropdownContentProps) {
  return (
    <div className='flex flex-col w-[164px]'>
      {customOptions.map((option) => {
        if (option && typeof option !== 'boolean') {
          return (
            <button
              key={`option-${option.text}`}
              onClick={() => {
                option.onClick()
                close()
              }}
              className={twMerge(
                generalDropdownItemClasses,
                'h-auto min-h-[32px] text-left'
              )}
            >
              {option.text}
            </button>
          )
        }

        return null
      })}
      <div className='flex flex-row items-center flex-wrap p-2'>
        {calendarColorIds
          .flat()
          .filter((color) => !colorsToHide.includes(color))
          .map((colorId) => (
            <Button
              key={colorId}
              iconOnly
              onClick={() => {
                onChange(colorId)
                close()
              }}
              size='small'
              sentiment='neutral'
              variant='muted'
            >
              <ColorItemBadge
                colorId={colorId}
                selected={currentColorId === colorId}
              />
            </Button>
          ))}
      </div>
    </div>
  )
}
