import { MagicWandGradientSolid, MagicWandSolid } from '@motion/icons'
import { GradientButton } from '@motion/ui/base'
import { Sentry } from '@motion/web-base/sentry'
import { useModalApi } from '@motion/web-common/modals'

import { showErrorToast } from '../toasts'

type FlowsAICardProps = {
  workspaceId: string | undefined
  onClose?: () => void
}

export function FlowsAICard({ workspaceId, onClose }: FlowsAICardProps) {
  const modalApi = useModalApi()

  const openModal = () => {
    if (!workspaceId) {
      Sentry.captureException("No workspace ID found. Can't open AI modal.", {
        tags: {
          position: 'CreateFlowTemplateModalContent',
        },
      })

      showErrorToast("No workspace ID found. Can't open AI modal.")
      return
    }

    onClose?.()

    // NOTE: Can't open this modal immediately after closing, looks like it will cause the following modal to not be registered
    setTimeout(() => {
      modalApi.open('flows-ai-modal', {
        workspaceId,
      })
    }, 500)
  }

  return (
    <div className='py-5 px-4 flex flex-col items-center gap-3 border border-semantic-purple-border-default rounded-lg shadow-[0_0_30px_-20px_rgba(0,0,0,0.3)] shadow-semantic-pink-border-active'>
      <div className='flex items-center gap-2'>
        <MagicWandGradientSolid className='size-4' />

        <h4 className='font-bold bg-purple-gradient text-gradient'>
          Create project workflow with AI{' '}
          <span className='font-normal'>(beta)</span>
        </h4>
      </div>

      <p className='text-sm text-semantic-neutral-text-default mb-1'>
        Paste or upload your SOP or project guidelines. Motion will
        automatically create the stages and tasks required for the project.
      </p>

      <GradientButton sentiment='purple' onClick={openModal}>
        <MagicWandSolid />
        Create with AI
      </GradientButton>
    </div>
  )
}
