import { type NoteSchema } from '@motion/rpc-types'

import { useUriByRouteId } from '~/routing'

export function useNoteUrl() {
  const getRouteUri = useUriByRouteId()

  return (params: { noteId: NoteSchema['id'] }) => {
    const noteUri = getRouteUri('notes-detail', { noteId: params.noteId })

    const noteUrl = new URL(noteUri, window.location.origin).toString()

    return { noteUrl, noteUri }
  }
}
