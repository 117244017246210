import { isDefaultStatus } from '@motion/shared/common'
import { isNoneId } from '@motion/shared/identifiers'
import { classed } from '@motion/theme'
import { createNoneProject } from '@motion/ui-logic/pm/data'
import { type WorkspaceSchema } from '@motion/zod/client'

import { type ModalFieldButtonProps } from '~/areas/ai-hackerhouse/event/modals/action-item-modal/components/modal-field-button'
import { useTaskUpdater } from '~/areas/tasks/hooks'
import { useProject, useWorkspaceById, useWorkspaceFns } from '~/global/hooks'
import { useController } from 'react-hook-form'

import { WorkspaceField } from './workspace-field'
import { WorkspaceProjectField } from './workspace-project-field'

import { useActionItemForm } from '../hooks'

export function ControlledWorkspaceFolderProjectField({
  includeWorkspaceField = true,
  includeProjectField = true,
  showFullPath = false,
  size = 'normal',
}: {
  includeWorkspaceField?: boolean
  includeProjectField?: boolean
  showFullPath?: boolean
  size?: ModalFieldButtonProps['size']
}) {
  const { form } = useActionItemForm()
  const { control } = form

  // Add validation to make sure that workspace id is not a none id
  const { field: workspaceIdField } = useController({
    name: 'workspaceId',
    control,
  })
  const { field: projectIdField } = useController({
    name: 'projectId',
    control,
  })

  // if task is already created, can edit in-line
  const taskId = form.watch('id')
  const updateTask = useTaskUpdater()

  const assigneeUserId = form.watch('assigneeUserId')

  const { getWorkspaceMemberById, getWorkspaceStatuses } = useWorkspaceFns()

  const selectedWorkspace = useWorkspaceById(workspaceIdField.value)
  const selectedProject =
    useProject(projectIdField.value) ??
    createNoneProject(workspaceIdField.value ?? '')

  const updateWorkspaceProject = async (
    newWorkspaceId: string,
    newProjectId: string | null
  ) => {
    if (
      newWorkspaceId === selectedWorkspace?.id &&
      newProjectId === selectedProject.id
    ) {
      return
    }

    let updatedAssigneeUserId = assigneeUserId
    let updatedStatusId = undefined

    if (newWorkspaceId !== workspaceIdField.value) {
      workspaceIdField.onChange(newWorkspaceId)

      if (
        isNoneId(newWorkspaceId) ||
        getWorkspaceMemberById(newWorkspaceId, assigneeUserId) == null
      ) {
        form.setValue('assigneeUserId', null)
        updatedAssigneeUserId = null
      }

      // Set the default status when the workspace changes
      if (!isNoneId(newWorkspaceId)) {
        const defaultStatus =
          getWorkspaceStatuses(newWorkspaceId).find(isDefaultStatus)

        if (defaultStatus) {
          form.setValue('statusId', defaultStatus.id)
          updatedStatusId = defaultStatus.id
        }
      }
    }
    if (newProjectId !== projectIdField.value) {
      projectIdField.onChange(newProjectId)
    }

    if (taskId != null) {
      await updateTask(taskId, {
        workspaceId: newWorkspaceId,
        projectId:
          newProjectId != null && !isNoneId(newProjectId) ? newProjectId : null,
        statusId: updatedStatusId != null ? updatedStatusId : undefined,
        assigneeUserId: updatedAssigneeUserId,
      })
    }

    return
  }

  const onWorkspaceChange = (newWorkspace: WorkspaceSchema) =>
    updateWorkspaceProject(newWorkspace.id, null)

  const onWorkspaceProjectChange = (
    newWorkspaceId: string,
    newProjectId: string | null
  ) => updateWorkspaceProject(newWorkspaceId, newProjectId)

  return (
    <LocationSectionContainer>
      {includeWorkspaceField && (
        <WorkspaceField
          workspaceId={workspaceIdField.value}
          onChange={onWorkspaceChange}
          size={size}
        />
      )}

      {/* <FolderField
        workspaceId={workspaceIdField.value}
        folderId={selectedProject.folderId ?? null}
        onChange={() => null}
        // TODO: Disabled for now, still working on the implentation
        disabled
      /> */}

      {includeProjectField && (
        <WorkspaceProjectField
          workspaceId={workspaceIdField.value}
          projectId={projectIdField.value}
          onChange={onWorkspaceProjectChange}
          showFullPath={showFullPath}
          size={size}
        />
      )}
    </LocationSectionContainer>
  )
}

const LocationSectionContainer = classed('div', {
  base: 'flex flex-col gap-0 modal-lg:gap-1.5',
})
