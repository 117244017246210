import { useDependantState } from '@motion/react-core/hooks'
import {
  ActionList,
  type ActionSection,
  PopoverButton,
  PopoverTrigger,
} from '@motion/ui/base'
import { type CalendarStartDay } from '@motion/ui-logic/calendar'
import { type DateFilterSchema } from '@motion/zod/client'

import { DisplayValue } from './format'
import {
  DATE_RANGE_OPTIONS,
  type DateRangeOption,
  findSelected,
} from './options'
import { DatePickerContent } from './pickers/picker-shell'

import { FilterActionBar } from '../action-bar'

export type DateFilterPickerProps = {
  calendarStartDay?: CalendarStartDay

  value: DateFilterSchema
  onSelect(value: DateFilterSchema | null): void
}

function createSections(
  onSelected: (item: DateFilterSchema) => void
): ActionSection[] {
  return DATE_RANGE_OPTIONS.reduce(
    (acc, cur) => {
      if (cur.key === 'separator') {
        acc.push({ items: [] })
        return acc
      }

      const section = acc[acc.length - 1]
      if (!section) return acc

      section.items.push({
        content: <FilterItem value={cur} />,
        onAction:
          'default' in cur
            ? () => onSelected(cur.default())
            : 'filter' in cur
              ? () => onSelected(cur.filter)
              : undefined,
      })

      return acc
    },
    [{ items: [] }] as ActionSection[]
  )
}

export const DateFilterQueryPicker = (props: DateFilterPickerProps) => {
  const [local, setLocal] = useDependantState<DateFilterSchema>(
    () => props.value,
    [props.value]
  )

  const selected = findSelected(local)

  const sections = createSections((item) => {
    setLocal(item)
  })

  return (
    <div className='w-[263px]'>
      <div className='p-2 flex flex-col gap-2'>
        <PopoverTrigger
          renderPopover={({ close }) => {
            return <ActionList sections={sections} onActionAnyItem={close} />
          }}
        >
          <PopoverButton>{selected.label}</PopoverButton>
        </PopoverTrigger>
        <DatePickerContent value={local} onSelect={setLocal} />
      </div>

      <FilterActionBar
        onApply={() => props.onSelect(local)}
        onClear={() => props.onSelect(null)}
      />
    </div>
  )
}

function FilterItem({ value }: { value: DateRangeOption }) {
  if (!('label' in value)) return null
  return (
    <div className='flex gap-2 text-sm'>
      <span className='text-semantic-neutral-text-default'>{value.label}</span>
      {'filter' in value && <DisplayValue value={value.filter} />}
    </div>
  )
}
