import { type MeetingInsightsSchema } from '@motion/rpc-types'
import { classed } from '@motion/theme'

import { CellText } from '~/areas/project-management/pages/pm-v3/tree-list/cells/components'
import { useUriByRouteId } from '~/routing'
import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

type NameCellProps = {
  counter: number
  meetingInsights: MeetingInsightsSchema
}

export function NameCell({ counter, meetingInsights }: NameCellProps) {
  const buildUrl = useUriByRouteId()

  const meetingInsightsUrl = buildUrl('meeting-insights', {
    meetingInsightsId: meetingInsights.id,
  })

  return (
    <CellText withHover>
      <LineCounter className='ml-[var(--indent)]'>{counter}</LineCounter>

      <Link
        to={meetingInsightsUrl}
        className={twMerge(
          'overflow-hidden flex-1 flex self-stretch items-center'
        )}
      >
        <span className='truncate'>{meetingInsights.eventData?.title}</span>
      </Link>
    </CellText>
  )
}

const LineCounter = classed(
  'span',
  'w-[26px] text-center text-[10px] text-semantic-neutral-text-disabled shrink-0'
)
