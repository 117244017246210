import { classed } from '@motion/theme'

import { SharedGridShell } from '~/areas/task-project/components'

export const FormShell = classed('form', {
  base: `
    bg-modal-bg
    rounded-lg
    shadow-lg

    h-[687px]
    w-[calc(100vw-56px)]
    max-w-[894px]
  `,
})

export const ShellActions = classed('div', {
  base: `
    absolute
    -right-[28px]
    top-0

    flex flex-col gap-4
  `,
})

export const GridShell = classed('div', SharedGridShell, {
  base: `
    grid-rows-[min-content_min-content_1fr]
    [grid-template-areas:'banner_banner''header_aside''main_aside']
  `,
})

export const BannerShell = classed('div', {
  base: `
    [grid-area:banner]
    bg-semantic-purple-bg-default
    rounded-t-lg
    h-[32px]
  `,
})

const MainContentPaddings = classed('div', {
  base: `
    px-6
    modal-lg:px-10
    `,
})

export const MainHeader = classed(MainContentPaddings, {
  base: `
    [grid-area:header]
    py-4
    flex flex-col gap-2
    modal-lg:pt-6
  `,
})

export const MainScrollableContent = classed(MainContentPaddings, {
  base: `
    [grid-area:main]
    overflow-auto
    flex flex-col gap-3
    modal-lg:pt-0 modal-lg:pb-6
  `,
})

export const SidebarShell = classed('aside', {
  base: `
    bg-semantic-neutral-surface-raised-bg-subtlest
    border-l border-modal-border
    rounded-r-lg

    [grid-area:aside]
    h-full overflow-auto
  `,
})

export const SidebarSection = classed('div', {
  base: `
    w-full
    flex flex-col gap-0.5
    px-3 py-2.5
    modal-lg:px-5 modal-lg:gap-1.5
  `,
})

export const SidebarWorkspaceSection = classed('div', SidebarSection, {
  base: `
    p-3 pt-4
    modal-lg:py-6 modal-lg:px-5
  `,
})
