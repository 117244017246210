import { PlusSolid } from '@motion/icons'

import { useProjectModalUrl } from '~/global/navigation'

import { SortableTreeviewItem } from '../../components/sortable-treeview/sortable-treeview-item'
import { type WorkspacesTreeviewItem } from '../types'

export type ProjectPlaceholderProps = {
  item: WorkspacesTreeviewItem
}

export const ProjectPlaceholder = ({
  item: flattenedItem,
}: ProjectPlaceholderProps) => {
  const { item, id, type, level } = flattenedItem

  const buildProjectModalUrl = useProjectModalUrl()

  return (
    <SortableTreeviewItem
      icon={PlusSolid}
      id={item.id}
      label='Add project'
      sortableData={{
        item,
        parentId: id,
        parentType: type,
        type: 'PLACEHOLDER',
        level,
      }}
      level={level}
      url={buildProjectModalUrl({
        forWorkspace: item.workspaceId,
        forFolder: flattenedItem.parentId?.toString() ?? undefined,
      })}
      order={item.order}
      disableDrag
    />
  )
}
