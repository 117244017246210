import { type DashboardViewChartSchema } from '@motion/zod/client'

export class ChartQueryError extends Error {
  readonly chart: DashboardViewChartSchema

  constructor(message: string, chart: DashboardViewChartSchema) {
    super(message)
    this.name = 'ChartQueryError'
    this.chart = chart

    Error.captureStackTrace(this, ChartQueryError)
  }
}
