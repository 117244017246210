import { classed } from '@motion/theme'
import {
  AutoScheduleToggle,
  type AutoScheduleToggleProps,
  Tooltip,
} from '@motion/ui/base'

// Just a static auto-schedule toggle that's default to on
export function AutoscheduleToggle({
  showLabel = true,
  padding = 'normal',
  variantOverride,
  size = 'normal',
  canToggle = true,
  checked,
  onChange,
}: {
  showLabel?: boolean
  padding?: 'none' | 'normal'
  variantOverride?: 'on' | 'off' | 'error'
  size?: AutoScheduleToggleProps['size']
  canToggle?: boolean
  checked: boolean
  onChange?: (checked: boolean) => void
}) {
  return (
    <ToggleContainer variant={variantOverride ?? 'on'} padding={padding}>
      <Tooltip
        content={
          !canToggle &&
          'Autoschedule can be turned off once the action item has been approved'
        }
        asChild
      >
        <AutoScheduleToggle
          checked={checked}
          disabled={!canToggle}
          size={size}
          onChange={(e) => onChange?.(e.currentTarget.checked)}
        >
          {showLabel && <ToggleLabel size={size}>Auto-scheduled</ToggleLabel>}
        </AutoScheduleToggle>
      </Tooltip>
    </ToggleContainer>
  )
}

const ToggleContainer = classed('div', {
  base: 'w-full align-middle flex flex-row items-center gap-1',
  variants: {
    variant: {
      error: 'bg-semantic-error-bg-disabled',
      on: 'bg-semantic-purple-bg-default',
      off: '',
    },
    padding: {
      none: 'px-0 py-0',
      normal: 'h-9 px-4 py-2.5 modal-lg:px-5 modal-lg:py-4',
    },
  },
  defaultVariants: {
    variant: 'off',
  },
})

const ToggleLabel = classed('p', {
  base: 'text-semantic-purple-text-default text-sm',
  variants: {
    size: {
      xsmall: 'text-2xs',
      small: 'text-xs',
      normal: 'text-sm',
    },
  },
  defaultVariants: {
    size: 'normal',
  },
})
