import { useSharedState } from '@motion/react-core/shared-state'
import { type DashboardViewCellSchema } from '@motion/rpc-types'
import { ActiveFilterKey } from '@motion/ui-logic/pm/data'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { toViewDefinitionFilters } from '~/areas/project-management/pages/pm-v3/views'
import { useCallback } from 'react'

import { useDashboardViewState } from '../../../view-state'
import { useValidChartFieldsContext } from '../contexts'
import { mergeChartFieldsIntoCell } from '../utils'

export const useAddChartCell = () => {
  const [, setViewState] = useDashboardViewState()
  const ctx = useValidChartFieldsContext()
  const [filters] = useSharedState(ActiveFilterKey)

  return useCallback(
    (cell: DashboardViewCellSchema) => {
      recordAnalyticsEvent('DASHBOARD_CHART_ADD', {
        chartType: cell.chart.type,
      })

      setViewState((prevView) => {
        const newRowIndex = Math.max(...prevView.view.cells.map((c) => c.y)) + 1

        return {
          ...prevView,
          view: {
            ...prevView.view,
            cells: [
              ...prevView.view.cells,
              mergeChartFieldsIntoCell(
                ctx.fields,
                { ...cell, y: newRowIndex },
                toViewDefinitionFilters(filters)
              ),
            ],
          },
        }
      })
    },
    [ctx.fields, filters, setViewState]
  )
}
