import { PlusSolid } from '@motion/icons'
import { classed } from '@motion/theme'
import { PopoverTrigger } from '@motion/ui/base'

import { CreateChartPopoverContent } from '~/areas/project-management/pages/pm-v3/components'

export function AddChartTile() {
  return (
    <PopoverTrigger
      placement='top'
      renderPopover={({ close }) => <CreateChartPopoverContent close={close} />}
    >
      <StyledGridTileButton>
        <div className='place-self-center flex flex-col items-center gap-4'>
          <div className='rounded-full bg-semantic-neutral-bg-secondary p-2 group-hover:bg-semantic-neutral-bg-subtle'>
            <PlusSolid className='size-8 text-semantic-neutral-icon-default group-hover:text-semantic-neutral-icon-hover' />
          </div>
          <span className='text-semantic-neutral-text-subtle text-sm font-medium'>
            Add card
          </span>
        </div>
      </StyledGridTileButton>
    </PopoverTrigger>
  )
}

const StyledGridTileButton = classed('button', {
  base: `
    group
    rounded-lg
    h-[90%] w-full
    grid-rows-[1fr]
    cursor-pointer
    hover:outline-1 hover:outline hover:outline-semantic-neutral-border-hover
  `,
})
