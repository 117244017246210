import { type ProjectDefinitionSchema } from '@motion/rpc-types'
import {
  type FlowTemplateFormFields,
  getProjectDefDescription,
} from '@motion/ui-logic/pm/project'

import { useInitialStages } from './use-initial-stages'
import { useInitialVariables } from './use-initial-variables'

import { type FlowTemplateUrlSearchParams } from '../../utils'

type GetInitialFormDataProps = {
  projectDefinition: ProjectDefinitionSchema | null
  templateId: string | undefined
  templateOperationParam: FlowTemplateUrlSearchParams['template']
  workspaceId: string
  currentUserId: string
  isLoading?: boolean
}

export function useGetInitialFormData({
  projectDefinition,
  templateId,
  templateOperationParam,
  workspaceId,
  currentUserId,
  isLoading,
}: GetInitialFormDataProps) {
  const usingTemplateData = templateId != null && projectDefinition != null
  const isEditing = usingTemplateData && templateOperationParam === 'edit'
  const isCreatingFromTemplate =
    usingTemplateData && templateOperationParam === 'new'

  const id = isEditing ? projectDefinition.id : undefined

  const stages = useInitialStages(
    workspaceId,
    projectDefinition,
    isCreatingFromTemplate,
    currentUserId
  )

  const { roles, textVariables } = useInitialVariables(
    projectDefinition,
    isCreatingFromTemplate
  )

  const assigneeUserId = usingTemplateData
    ? projectDefinition.managerId
    : currentUserId
  const priorityLevel = usingTemplateData
    ? projectDefinition.priorityLevel
    : 'MEDIUM'

  return () => {
    return {
      isLoading: isLoading ?? false,
      hasError: false,
      id,
      workspaceId,
      name: projectDefinition?.name ?? '',
      description: getProjectDefDescription(projectDefinition?.description),
      color: projectDefinition?.color ?? 'gray',
      folderId: projectDefinition?.folderId ?? undefined,
      managerId: assigneeUserId,
      priorityLevel,
      labelIds: projectDefinition?.labelIds ?? [],
      roles,
      textVariables,
      stages,
    } satisfies FlowTemplateFormFields
  }
}
