import { useOnceWhen } from '@motion/react-core/hooks'
import { type MeetingInsightsSchema } from '@motion/rpc-types'

import { useState } from 'react'

import { RecordingPlayer } from '../components'
import { useRecordingSignedUrl } from '../hooks/rpc/use-recording-signed-url'

type MeetingRecordingPlayerProps = {
  meetingInsights: MeetingInsightsSchema
}

export function MeetingRecordingPlayer({
  meetingInsights,
}: MeetingRecordingPlayerProps) {
  const [noRefetch, setNoRefetch] = useState(false)

  const { data: videoUrl } = useRecordingSignedUrl(
    {
      meetingInsightsId: meetingInsights.id,
    },
    {
      enabled: !noRefetch,
    }
  )

  useOnceWhen(videoUrl != null, () => {
    setNoRefetch(true)
  })

  if (meetingInsights.mimeType == null) {
    return (
      <p className='p-4 rounded-md text-center bg-semantic-neutral-surface-raised-bg-default font-medium'>
        This meeting insight does not have a recording.
      </p>
    )
  }

  return (
    <div className='w-full flex flex-col'>
      <RecordingPlayer videoUrl={videoUrl ?? ''} />
    </div>
  )
}
