import { PlusSolid, TrashSolid } from '@motion/icons'
import { IndividualOrTeam } from '@motion/rpc/types'
import { Button, ButtonGroup } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { templateStr, type WorkspaceMemberWithUser } from '@motion/ui-logic'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useAuthenticatedUser } from '@motion/web-common/auth'
import { useModalApi } from '@motion/web-common/modals'

import { useQueryClient } from '@tanstack/react-query'
import { ConnectedUserLabel } from '~/global/components/labels'
import {
  useDeleteWorkspace,
  useDeleteWorkspaceMember,
  useUpdateWorkspace,
  useWorkspaceById,
  useWorkspaceMembers,
} from '~/global/hooks'
import { useCurrentTeam } from '~/global/rpc/team'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { SettingTable } from '../components/setting-table'
import { TeamMemberDropdown } from '../components/team-member-dropdown'

export const WorkspaceOverviewSettings = () => {
  const { workspaceId = '' } = useParams<{ workspaceId: string }>()
  const client = useQueryClient()
  const { updateWorkspace } = useUpdateWorkspace()
  const { deleteWorkspace } = useDeleteWorkspace()

  const user = useAuthenticatedUser()

  const workspace = useWorkspaceById(workspaceId)
  const workspaceMembers = useWorkspaceMembers(workspaceId)

  const { deleteWorkspaceMember } = useDeleteWorkspaceMember()

  const [workspaceName, setWorkspaceName] = useState(workspace?.name ?? '')
  const modalApi = useModalApi()
  const navigate = useNavigate()
  const { data: team } = useCurrentTeam()

  const isTeamWorkspace = workspace?.type === IndividualOrTeam.TEAM

  useEffect(() => {
    if (workspace) {
      setWorkspaceName(workspace.name)
    }
  }, [workspace])

  async function handleDeleteWorkspace() {
    if (!workspace) return

    const deleted = await deleteWorkspace(workspace)
    if (deleted) {
      navigate('/web/settings/calendar')
    }
  }

  function handleDeleteMember(member: WorkspaceMemberWithUser) {
    const description = (
      <>
        <div className='font-semibold'>
          For tasks assigned to {member.user?.name}:
        </div>
        <br />
        <div className='text-normal'>
          Incomplete tasks will be marked as unassigned.
        </div>
        <br />
        <div className='text-normal'>
          Completed tasks will still be assigned to this user.
        </div>
      </>
    )

    void modalApi.prompt('confirm', {
      analytics: {
        name: 'delete-workspace-member',
      },
      destructive: true,
      async onValue(value) {
        if (value && workspace?.teamId) {
          deleteWorkspaceMember({
            workspaceId,
            memberId: member.id,
          })

          // Refetch all workspaces to update the workspace member list
          void client.invalidateQueries({ queryKey: ['v2', 'workspaces'] })
        }
      },
      title: `Are you sure you want to remove ${member.user?.name} from ${workspace?.name}?`,
      description,
    })
  }

  async function handleUpdateName() {
    if (
      workspace &&
      workspaceName !== workspace.name &&
      workspaceName.trim() !== ''
    ) {
      await updateWorkspace({
        name: workspaceName,
        workspaceId,
      })
    }
  }

  if (!workspace) return null

  return (
    <div>
      <div className='mb-2 text-semantic-neutral-text-subtle font-semibold text-xs'>
        Workspace name
      </div>
      <div className='w-full mb-8'>
        <TextField
          label='Workspace name'
          disabled={workspace.uniquenessId === user.uid}
          labelHidden
          onBlur={handleUpdateName}
          onChange={(name) => setWorkspaceName(name)}
          value={workspaceName}
        />
      </div>
      {isTeamWorkspace ? (
        <>
          <SettingTable
            items={workspaceMembers
              .filter(
                (member) => member.status === 'ACTIVE' && !member.user.deleted
              )
              .map((member) => ({
                id: member.userId,
                label: (
                  <div className='flex items-center'>
                    <ConnectedUserLabel size='xlarge' userId={member.userId} />
                  </div>
                ),
                onDelete: () => handleDeleteMember(member),
              }))}
            title='Members'
          />
          <div className='mb-8 mt-3'>
            <TeamMemberDropdown
              onChange={() => {
                recordAnalyticsEvent(
                  'PROJECT_MANAGEMENT_ADD_WORKSPACE_MEMBER',
                  {
                    view: 'settings',
                  }
                )
              }}
              workspaceId={workspaceId}
            >
              <Button alignment='left' sentiment='neutral' variant='muted'>
                <PlusSolid />
                Add member
              </Button>
            </TeamMemberDropdown>
          </div>
        </>
      ) : (
        <p className='mb-5 text-sm'>
          {templateStr(
            'This is a private workspace, only you can access it.{{convertToTeam}}',
            {
              convertToTeam:
                !workspace.isPersonalWorkspace && !!team
                  ? ' To add team members to this workspace, convert it to a Team workspace.'
                  : '',
            }
          )}
        </p>
      )}

      {!workspace.isPersonalWorkspace && (
        <ButtonGroup stretch>
          {!!team && !isTeamWorkspace && (
            <Button
              sentiment='neutral'
              onClick={() => {
                updateWorkspace({ workspaceId, teamId: team.id })
              }}
            >
              Convert to Team Workspace
            </Button>
          )}
          {workspace.uniquenessId !== user.uid && (
            <Button onClick={handleDeleteWorkspace} sentiment='error'>
              <TrashSolid />
              Delete workspace
            </Button>
          )}
        </ButtonGroup>
      )}
    </div>
  )
}
