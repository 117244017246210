import { useClosure } from '@motion/react-core/hooks'
import {
  createMergeReducer,
  createStateKeyFilter,
  on,
  SharedStateProvider,
} from '@motion/react-core/shared-state'
import { type CustomFieldSchema } from '@motion/shared/custom-fields'
import {
  type FolderSchema,
  type LabelSchema,
  type ProjectDefinitionSchema,
  type ProjectSchema,
  type StatusSchema,
  type VersionedViewV2,
  type WorkspaceMemberSchema,
  type WorkspaceSchema,
} from '@motion/zod/client'

import { type ReactNode } from 'react'

import {
  AppWorkspaceContext,
  type AppWorkspaceDataContext,
  type WorkspaceStageDefinition,
} from './app-workspace-context'
import { createEntityCache } from './utils'

export type FilteredWorkspaceContextProps = {
  name?: string
  children: ReactNode
  refreshKey?: string

  filterMembers?(member: WorkspaceMemberSchema): boolean
  statuses?(status: StatusSchema): boolean
  labels?(label: LabelSchema): boolean
  customFields?(field: CustomFieldSchema): boolean

  workspaces?: Filters['workspaces']
  projects?: Filters['projects']
  projectDefinitions?: Filters['projectDefinitions']
  stageDefinitions?: Filters['stageDefinitions']
  views?: Filters['views']
  folders?: Filters['folders']
}

export const FilteredWorkspaceContext = (
  props: FilteredWorkspaceContextProps
) => {
  const merge = useClosure(
    createMergeReducer(
      on(AppWorkspaceContext, (value) => {
        return filterBy(value, {
          workspaces: props.workspaces,
          members: props.filterMembers,
          projects: props.projects,
          projectDefinitions: props.projectDefinitions,
          stageDefinitions: props.stageDefinitions,
          statuses: props.statuses,
          labels: props.labels,
          customFields: props.customFields,
          views: props.views,
          folders: props.folders,
        })
      })
    )
  )

  return (
    <SharedStateProvider
      name={props.name}
      merge={merge}
      filter={onlyWorkspaceFilter}
      refreshKey={props.refreshKey}
      batchTime={0}
    >
      {props.children}
    </SharedStateProvider>
  )
}

const onlyWorkspaceFilter = createStateKeyFilter({
  include: [AppWorkspaceContext],
})

type Filters = {
  workspaces?(
    workspaces: WorkspaceSchema[],
    ctx: AppWorkspaceDataContext
  ): WorkspaceSchema[]
  projects?(
    projects: ProjectSchema[],
    ctx: AppWorkspaceDataContext
  ): ProjectSchema[]
  projectDefinitions?(
    projectDefinitions: ProjectDefinitionSchema[],
    ctx: AppWorkspaceDataContext
  ): ProjectDefinitionSchema[]
  stageDefinitions?(
    stageDefinitions: WorkspaceStageDefinition[],
    ctx: AppWorkspaceDataContext
  ): WorkspaceStageDefinition[]
  folders?(
    folders: FolderSchema[],
    ctx: AppWorkspaceDataContext
  ): FolderSchema[]

  members?(member: WorkspaceMemberSchema): boolean
  statuses?(status: StatusSchema): boolean
  labels?(label: LabelSchema): boolean
  customFields?(field: CustomFieldSchema): boolean

  views?(
    views: VersionedViewV2[],
    ctx: AppWorkspaceDataContext
  ): VersionedViewV2[]
}

function filterBy(
  data: AppWorkspaceDataContext,
  predicates: Filters
): AppWorkspaceDataContext {
  const {
    members: memberPredicate = () => true,
    statuses: statusPredicate = () => true,
  } = predicates

  const workspaces = createEntityCache(
    predicates.workspaces
      ? predicates.workspaces(data.workspaces.all, data)
      : data.workspaces.all
  )

  function inAvailableWorkspace<
    T extends { workspaceId: WorkspaceSchema['id'] },
  >(item: T) {
    return !!workspaces.byId[item.workspaceId]
  }

  const projects = createEntityCache(
    predicates.projects
      ? predicates.projects(data.projects.all, data)
      : data.projects.all.filter((p) => inAvailableWorkspace(p))
  )

  const statuses = createEntityCache(
    data.statuses.all.filter(
      (s) => inAvailableWorkspace(s) && statusPredicate(s)
    )
  )

  const labels = createEntityCache(data.labels.all.filter(inAvailableWorkspace))

  const teamMemberIds = new Set(
    workspaces.all.flatMap((w) =>
      w.members.filter(memberPredicate).map((m) => m.userId)
    )
  )

  const users = createEntityCache(
    data.users.all.map((user) => {
      if (teamMemberIds.has(user.id)) return user
      return { ...user, deleted: true }
    })
  )

  const customFields = createEntityCache(
    data.customFields.all.filter(inAvailableWorkspace)
  )

  const views = predicates.views
    ? createEntityCache(predicates.views(data.views.all, data))
    : data.views

  const members = createEntityCache(
    data.members.all.filter(inAvailableWorkspace)
  )

  const projectDefinitions = createEntityCache(
    predicates.projectDefinitions
      ? predicates.projectDefinitions(data.projectDefinitions.all, data)
      : data.projectDefinitions.all.filter(inAvailableWorkspace)
  )

  const legacyStageDefinitions = createEntityCache(
    predicates.stageDefinitions
      ? predicates.stageDefinitions(data.stageDefinitions.all, data)
      : data.stageDefinitions.all.filter(inAvailableWorkspace)
  )

  const legacyProjectTemplates = createEntityCache(
    data.legacyProjectTemplates.all.filter(inAvailableWorkspace)
  )

  const folders = createEntityCache(
    predicates.folders
      ? predicates.folders(data.folders.all, data)
      : data.folders.all.filter((item) => !!workspaces.byId[item.targetId])
  )

  const systemFolders = data.systemFolders

  return {
    loaded: data.loaded,
    workspaces,
    projects,
    statuses,
    labels,
    users,
    members,
    customFields,
    priorities: data.priorities,
    deadlineStatuses: data.deadlineStatuses,
    views,
    projectDefinitions,
    stageDefinitions: legacyStageDefinitions,
    // TODO(flows-m5): update this placeholder with actual filtering
    stageDefinitionsV2: data.stageDefinitionsV2,
    legacyProjectTemplates,
    folders,
    systemFolders,
  }
}
