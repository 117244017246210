export function groupByKey<T extends Record<string, any>>(
  array: T[],
  key: keyof T
): Record<string, T[]> {
  return groupBy(array, (item) => item[key])
}

export function groupBy<T extends Record<string, any>>(
  array: T[],
  accessor: (item: T) => string
): Record<string, T[]> {
  return array.reduce(
    (result, item) => {
      const groupKey = accessor(item)
      if (!result[groupKey]) {
        result[groupKey] = []
      }
      result[groupKey].push(item)
      return result
    },
    {} as Record<string, T[]>
  )
}

export type Group<T, TK> = {
  key: TK
  items: T[]
}

/**
 * Groups a list of `items` using the `by` function and returns an array of `Group<T>`
 * @param items the items to group
 * @param by The accessor to determine which group an item belongs to
 * @returns and array of `Group<T>` objects
 */
export function groupInto<T extends Record<string, any>, TK = string>(
  items: T[],
  by: (item: T) => TK
): Group<T, TK>[] {
  const buckets = new Map<TK, Group<T, TK>>()
  for (const item of items) {
    const key = by(item)
    let bucket = buckets.get(key)
    if (bucket == null) {
      bucket = { key, items: [] }
      buckets.set(key, bucket)
    }
    bucket.items.push(item)
  }
  return Array.from(buckets.values())
}

export type GroupWithHeader<T, TK extends string = string, TH = never> = {
  key: TK
  items: T[]
  header: TH
}

export function groupIntoWithHeader<
  T extends Record<string, any>,
  TK extends string,
  TH,
>(
  items: T[],
  by: (item: T) => TK,
  header: (item: T) => TH
): GroupWithHeader<T, TK, TH>[] {
  const buckets = new Map<string, GroupWithHeader<T, TK, TH>>()
  for (const item of items) {
    const key = by(item)
    let bucket = buckets.get(key)
    if (bucket == null) {
      bucket = { key, items: [], header: header(item) }
      buckets.set(key, bucket)
    }
    bucket.items.push(item)
  }
  return Array.from(buckets.values())
}
