import { FilledChevronRightSolid } from '@motion/icons'
import { classed } from '@motion/theme'
import { Button } from '@motion/ui/base'
import { getPrefixFromMaybeCustomFieldKey } from '@motion/ui-logic'

import { type Row } from '@tanstack/react-table'
import { type GroupedNode } from '~/areas/project-management/pages/pm-v3/grouping'
import { type TreeListRowValueType } from '~/areas/project-management/pages/pm-v3/tree-list'
import {
  getLabelComponent,
  type TaskFieldLabelProps,
} from '~/global/components/labels'
import { type FC, type PropsWithChildren } from 'react'

import { TaskLineItem } from './components'

export type LineItemComponentProps<T extends TreeListRowValueType['type']> = {
  row: Row<GroupedNode<Extract<TreeListRowValueType, { type: T }>>>
}

export function renderLineItem({
  row,
}: {
  row: Row<GroupedNode<TreeListRowValueType>>
}) {
  const item = row.original.value

  if (item.type === 'task') {
    return <TaskLineItem task={item.value} />
  }

  const count = row.original.count
  const canExpand = row.getCanExpand()

  const type = getPrefixFromMaybeCustomFieldKey(item.type) ?? item.type
  const LabelComponent = getLabelComponent(type) as FC<TaskFieldLabelProps<any>>

  const content = <LabelComponent keyProp={item.key} value={item.value} />

  if (canExpand) {
    return (
      <ChildWithCount row={row} count={count}>
        {content}
      </ChildWithCount>
    )
  }

  return content
}

// eslint-disable-next-line react-refresh/only-export-components
function ChildWithCount({
  row,
  count,
  children,
}: PropsWithChildren<{
  row: Row<GroupedNode<TreeListRowValueType>>
  count: number
}>) {
  const expanded = row.getIsExpanded()

  return (
    <div className='p-1 flex flex-row gap-2 items-center text-sm font-medium leading-5'>
      <Button
        iconOnly
        size='xsmall'
        sentiment='neutral'
        variant='muted'
        aria-label='Expand/Collapse group'
        onClick={row.getToggleExpandedHandler()}
      >
        <ExpandIcon expanded={expanded} />
      </Button>
      {children}
      <span className='text-semantic-neutral-text-subtle'>{count}</span>
    </div>
  )
}

// eslint-disable-next-line react-refresh/only-export-components
const ExpandIcon = classed(FilledChevronRightSolid, {
  base: `transition-transform`,
  variants: {
    expanded: {
      true: 'rotate-90',
      false: '',
    },
  },
  defaultVariants: {
    expanded: 'false',
  },
})
