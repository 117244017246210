import { Tooltip } from '@motion/ui/base'
import {
  formatDurationToShort,
  formatToShortTaskDuration,
  templateStr,
} from '@motion/ui-logic'

import { DurationDropdown } from '~/areas/project-management/components'
import { useTaskDurationData } from '~/areas/tasks/hooks'
import { type TaskWithRelations } from '~/global/proxies'

export type TaskLineDurationItemProps = {
  task: TaskWithRelations
}

export function TaskLineDurationItem({ task }: TaskLineDurationItemProps) {
  const { onChange: onChangeDuration, disabled } = useTaskDurationData(task)

  return (
    <DurationDropdown value={task.duration} onChange={onChangeDuration}>
      <button type='button' className='flex' disabled={disabled}>
        <Tooltip
          content={templateStr('Duration: {{duration}}', {
            duration: formatToShortTaskDuration(task.duration),
          })}
          asChild
        >
          <span className='text-semantic-neutral-text-subtle text-xs'>
            {formatDurationToShort(task.duration)}
          </span>
        </Tooltip>
      </button>
    </DurationDropdown>
  )
}
