import { ViewBoardsOutline } from '@motion/icons'
import { Button, PopoverTrigger } from '@motion/ui/base'
import { Checkbox } from '@motion/ui/forms'

import { type Column, type ColumnVisibility } from './task-tree-list'

export type ColumnButtonProps = Omit<ColumnOptionsProps, 'close'>

export function ColumnButton({
  columnsVisibility,
  onChange,
}: ColumnButtonProps) {
  return (
    <PopoverTrigger
      placement='bottom-end'
      renderPopover={({ close }) => (
        <ColumnOptions
          close={close}
          columnsVisibility={columnsVisibility}
          onChange={onChange}
        />
      )}
    >
      <Button sentiment='neutral' variant='outlined' size='small'>
        <ViewBoardsOutline />
        Columns
      </Button>
    </PopoverTrigger>
  )
}

export type ColumnOptionsProps = {
  close: () => void
  columnsVisibility: ColumnVisibility
  onChange: (columns: Column) => void
}
export const ColumnOptions = ({
  columnsVisibility,
  onChange,
}: ColumnOptionsProps) => {
  return (
    <div className='p-1 flex flex-col [&>div]:block [&>div]:px-2 [&>div]:py-1.5'>
      <Checkbox
        checked={columnsVisibility.deadline}
        onChange={() => onChange('deadline')}
        label='Deadline'
      />

      <Checkbox
        checked={columnsVisibility.autoschedule}
        onChange={() => onChange('autoschedule')}
        label='Auto-schedule toggle'
      />
      <Checkbox
        checked={columnsVisibility.duration}
        onChange={() => onChange('duration')}
        label='Duration'
      />
      <Checkbox
        checked={columnsVisibility.priority}
        onChange={() => onChange('priority')}
        label='Priority'
      />
      <Checkbox
        checked={columnsVisibility.assignee}
        onChange={() => onChange('assignee')}
        label='Assignee'
      />
    </div>
  )
}
