import { range } from '@motion/utils/array'

import { type DateTime, type DurationObjectUnits, Interval } from 'luxon'

import { formatTime } from '../utils'

type OptionItem = {
  value: DateTime
  label: string
  duration: DurationObjectUnits
}

export function createDurationLabel(duration: DurationObjectUnits) {
  let durationLabel = ''
  const hours = duration.hours
  const minutes = duration.minutes
  if (hours && hours > 0) {
    durationLabel += `${hours}h `
  }
  if (minutes && minutes > 0) {
    durationLabel += `${minutes}min`
  }
  return durationLabel.trim()
}

export type GenerateIntervalOptionsFromDateArgs = {
  startDate: DateTime
  endDate: DateTime
  intervalMinutes?: number
  exclusive?: boolean
}

export function generateIntervalOptionsFromDate(
  args: GenerateIntervalOptionsFromDateArgs
): OptionItem[] {
  const { startDate, endDate, intervalMinutes = 15, exclusive = false } = args

  const { minutes = 0 } = Interval.fromDateTimes(startDate, endDate)
    .toDuration()
    .shiftTo('minutes')
    .toObject()

  const count = Math.ceil(minutes / intervalMinutes) - (exclusive ? 1 : 0)

  return range(count).map((c) => {
    const intervalDate = startDate.plus({
      minutes: (exclusive ? c + 1 : c) * intervalMinutes,
    })
    return {
      value: intervalDate,
      label: formatTime(intervalDate),
      duration: startDate
        .until(intervalDate)
        .toDuration(['hours', 'minutes'])
        .toObject(),
    }
  })
}

export const generateTimeOptionsFromString = (
  timeString: string,
  startDate: DateTime,
  opts: { showDuration: boolean }
): OptionItem[] => {
  const { showDuration } = opts
  if (!timeString?.length) return []
  // Remove any non-numeric characters
  const cleanedTime = timeString.replace(/\D/g, '')

  let options = []

  // Option 1: Assuming the first character is the hour
  if (cleanedTime.length > 0 && cleanedTime.length < 4) {
    const hours = parseInt(cleanedTime.slice(0, 1), 10)
    const minutes = parseInt(cleanedTime.slice(1, 3).padEnd(2, '0'), 10)
    // options.push(currentDate.set({ hour: hours, minute: minutes }))
    if (minutes < 60) {
      options.push(startDate.set({ hour: hours, minute: minutes }))
      options.push(startDate.set({ hour: hours + 12, minute: minutes }))
    }
  }

  // Option 2: Assuming the first two characters are the hour and the next two are the minutes
  if (cleanedTime.length >= 2) {
    const hours = parseInt(cleanedTime.slice(0, 2), 10)
    const minutes = parseInt(cleanedTime.slice(2, 4).padEnd(2, '0'), 10)
    if (hours < 24 && minutes < 60) {
      options.push(startDate.set({ hour: hours, minute: minutes }))

      if (hours <= 12) {
        options.push(startDate.set({ hour: hours + 12, minute: minutes }))
      }
    }
  }

  // Option 3: Assuming it's a duration in hours
  if (showDuration) {
    const durationHours = parseInt(cleanedTime, 10)
    if (!isNaN(durationHours)) {
      options.push(startDate.plus({ hours: durationHours }))
    }
    // Option 4: Assuming it's a duration in minutes
    const durationMinutes = parseInt(cleanedTime, 10)
    if (!isNaN(durationMinutes)) {
      options.push(startDate.plus({ minutes: durationMinutes }))
    }
  }

  // If has "AM" or "PM" in the string, filter out the opposite
  const isAM = timeString.toLowerCase().includes('am')
  const isPM = timeString.toLowerCase().includes('pm')
  if (isAM || isPM) {
    options = options.filter(({ hour }) => (isAM ? hour < 12 : hour >= 12))
  }

  const dateOptions: Record<number, OptionItem> = {}
  for (const option of options) {
    if (option < startDate) continue
    dateOptions[option.toMillis()] = {
      value: option,
      label: option.toFormat('h:mm a'),
      duration: startDate
        .until(option)
        .toDuration(['hours', 'minutes'])
        .toObject(),
    }
  }

  return Object.values(dateOptions)
}
