import { getProjectStageFromDefinitionId } from '@motion/ui-logic/pm/project'
import { type StageDefinitionSchema } from '@motion/zod/client'

import { ConnectedStageAutoscheduleToggle } from '~/areas/task-project/components/side-panel/header'
import { useProjectDefinition } from '~/global/hooks'
import { useMemo } from 'react'

import { useProjectInTree } from './hooks'

import { type GroupedNode } from '../../grouping'

export type StageNameAutoScheduleToggleProps = {
  stage: Pick<StageDefinitionSchema, 'id' | 'name'>
  row: GroupedNode
}
export function StageNameAutoScheduleToggle({
  stage,
  row,
}: StageNameAutoScheduleToggleProps) {
  const project = useProjectInTree(row)

  const projectDefinition = useProjectDefinition(project?.projectDefinitionId)

  const projectStage = useMemo(() => {
    if (project == null) return null

    const foundStage = getProjectStageFromDefinitionId(project, stage.id)

    if (!foundStage) {
      const templateStage = projectDefinition?.stages.find(
        (stageDefinition) => stageDefinition.name === stage.name
      )
      // because of workspace stage name grouping, the stage object might be the workflow template stage
      return project.stages.find(
        (s) => s.stageDefinitionId === templateStage?.id
      )
    }
    return foundStage
  }, [project, stage.id, projectDefinition?.stages, stage.name])

  if (project == null || projectStage == null) return null

  return (
    <ConnectedStageAutoscheduleToggle
      project={project}
      projectStage={projectStage}
      size='normal'
    />
  )
}
