import { useDependantState } from '@motion/react-core/hooks'
import { type DashboardViewCellSchema } from '@motion/zod/client'

import { type ReactNode, useMemo } from 'react'

import { ChartFieldsContext, type ChartFieldsContextValue } from './context'

import { convertChartCellToChartFields } from '../../utils'
import { ChartActiveFilterContext } from '../chart-active-filter-context'

type ChartFieldProviderProps = {
  children: ReactNode
  chartCell: DashboardViewCellSchema
}

export function ChartFieldProvider({
  children,
  chartCell,
}: ChartFieldProviderProps) {
  const [fields, setFields] = useDependantState(
    () => convertChartCellToChartFields(chartCell),
    [chartCell]
  )

  const value = useMemo<ChartFieldsContextValue>(() => {
    return {
      chartCellId: chartCell.id,
      fields,
      setFieldValue: (fieldName, value) => {
        setFields((prevFields) => ({
          ...prevFields,
          [fieldName]: value,
        }))
      },
    }
  }, [fields, setFields, chartCell.id])

  return (
    <ChartFieldsContext.Provider value={value}>
      <ChartActiveFilterContext
        name='chart-filters'
        target={fields.settings.item}
        filters={chartCell.chart.filters}
      >
        {children}
      </ChartActiveFilterContext>
    </ChartFieldsContext.Provider>
  )
}
