import { PopoverTrigger, SearchableList } from '@motion/ui/base'
import { isCustomFieldKey } from '@motion/ui-logic'
import { byProperty, Compare } from '@motion/utils/array'

import { RestrictWidth } from '~/areas/project-management/pages/pm-v3/components'
import { type ReactNode, useMemo } from 'react'

import { type FilterDefinition } from '../types'

type AddFilterButtonProps = {
  definitions: FilterDefinition[]
  onSelect(def: FilterDefinition): void
  variant: 'initial' | 'additional'
  children: ReactNode
}

export const AddFilterButton = (props: AddFilterButtonProps) => {
  const { definitions, onSelect } = props

  const sections = useMemo(
    () =>
      [
        {
          key: 'fields',
          label: 'Fields',
          initialExpanded: true,
          items: definitions
            .filter((x) => !isCustomFieldKey(x.key))
            .sort(byProperty('label', Compare.string)),
        },
        {
          key: 'custom-fields',
          label: 'Custom fields',
          initialExpanded: true,
          items: definitions
            .filter((x) => isCustomFieldKey(x.key))
            .sort(byProperty('label', Compare.string)),
        },
      ].filter(Boolean),
    [definitions]
  )

  if (definitions.length === 0) return null

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <SearchableList
          itemType='sectioned'
          sections={sections}
          renderItem={(item) => <RestrictWidth>{item.label}</RestrictWidth>}
          computeKey={(item) => item.key}
          computeSearchValue={(item) => item.label}
          computeSelected={() => false}
          onSelect={(item) => {
            onSelect(item)
            close()
          }}
          searchable
        />
      )}
    >
      {props.children}
    </PopoverTrigger>
  )
}
