import {
  type ScopeOptions,
  useSharedStateQuery,
} from '@motion/react-core/shared-state'
import { type UserInfoSchema, type WorkspaceSchema } from '@motion/rpc-types'

import { AppWorkspaceContext } from '~/global/contexts'

import { type Invitee } from '../types'

// Set data to be { id: string } if H is true
type RedactedIfTrue<
  T extends { data: unknown; isRedacted: boolean },
  K extends keyof T,
> =
  | (Omit<T, K> & { [key in K]: false })
  | ((Omit<T, 'data'> & { [key in K]: true }) & { data: { id: string } })

export type InviteeWithData = Omit<Invitee, 'type'> &
  (
    | RedactedIfTrue<
        {
          type: 'USER'
          data: UserInfoSchema
          isRedacted: boolean
        },
        'isRedacted'
      >
    | RedactedIfTrue<
        {
          type: 'WORKSPACE'
          data: WorkspaceSchema
          isRedacted: boolean
        },
        'isRedacted'
      >
  )

export function useInviteesData(invitees: Invitee[], opts?: ScopeOptions) {
  return useSharedStateQuery(
    AppWorkspaceContext,
    (state) => {
      return invitees.map((invitee): InviteeWithData => {
        if (invitee.type === 'USER') {
          const user = state.users.byId[invitee.id]

          if (!user) {
            return {
              ...invitee,
              type: 'USER',
              data: { id: invitee.id },
              isRedacted: true,
            }
          }

          return { ...invitee, type: 'USER', data: user, isRedacted: false }
        }

        const workspace = state.workspaces.byId[invitee.id]

        if (!workspace) {
          return {
            ...invitee,
            type: 'WORKSPACE',
            data: { id: invitee.id },
            isRedacted: true,
          }
        }

        return {
          ...invitee,
          type: 'WORKSPACE',
          data: workspace,
          isRedacted: false,
        }
      })
    },
    opts
  )
}
