import { useMemoSyncExternalStore } from '@motion/react-core/hooks'

import { useEffect } from 'react'

import { instance } from './service'
import { type AmplitudeExperimentName, type TreatmentsOf } from './types'

type ExperimentOptions = {
  track?: boolean
}

export function useExperiment<TName extends AmplitudeExperimentName>(
  name: TName,
  options: ExperimentOptions = {}
) {
  const value = useMemoSyncExternalStore(
    `experiment:${name}`,
    instance.changed.subscribe,
    () => instance.get(name)
  )

  useEffect(() => {
    const shouldTrack = options.track === true || instance.isTracked(name)
    if (!shouldTrack) return

    instance.mark(name)
  }, [name, options.track, value])

  return value
}

type TreatmentOptions<TName extends AmplitudeExperimentName> = {
  treatment?: TreatmentsOf<TName>
} & ExperimentOptions

export function useHasTreatment<TName extends AmplitudeExperimentName>(
  name: TName,
  options: TreatmentOptions<TName> = {}
) {
  const result = useExperiment(name, options)
  return options?.treatment
    ? result.value === options.treatment
    : result.value === 'on' || result.value === 'treatment'
}
