import { AdjustmentsOutline, RefreshSolid } from '@motion/icons'
import { ButtonGroup, IconButton, Tooltip } from '@motion/ui/base'
import { useHasTreatment } from '@motion/web-common/flags'

import { BaseMultiDropdown } from '~/global/components'

import { useViewTabsContext } from '../context/use-view-tabs-context'
import { VIEW_OPTIONS } from '../utils/constants'

export const FilterViewsDropdown = () => {
  const { selectedViewTypes, setSelectedViewTypes } = useViewTabsContext()

  const hasSelectedViewTypes = selectedViewTypes.size > 0

  // TODO remove after rollout
  const hasDashboard = useHasTreatment('charts-preview')
  const items = VIEW_OPTIONS.filter(
    (view) => view.id !== 'dashboard' || hasDashboard
  )

  return (
    <ButtonGroup
      size='small'
      // @ts-expect-error - class
      className='ml-auto'
    >
      {hasSelectedViewTypes && (
        <Tooltip asChild content='Reset filters'>
          <IconButton
            icon={RefreshSolid}
            size='small'
            variant='muted'
            sentiment='neutral'
            onClick={() => {
              setSelectedViewTypes(new Set())
            }}
          />
        </Tooltip>
      )}
      <BaseMultiDropdown
        searchable={false}
        itemType='checkbox'
        items={items}
        onSelect={(items) => {
          setSelectedViewTypes(new Set(items.map((item) => item.id)))
        }}
        computeKey={(item) => item.id}
        computeSelected={(item) => selectedViewTypes.has(item.id)}
        renderItem={({ label, icon: Icon }) => (
          <div className='flex items-center gap-1.5'>
            <Icon className='size-4 text-dropdown-item-icon-default' />
            {label}
          </div>
        )}
      >
        <Tooltip asChild content='Filter views'>
          <IconButton
            icon={AdjustmentsOutline}
            size='small'
            variant={hasSelectedViewTypes ? 'outlined' : 'muted'}
            sentiment={hasSelectedViewTypes ? 'primary' : 'neutral'}
          />
        </Tooltip>
      </BaseMultiDropdown>
    </ButtonGroup>
  )
}
