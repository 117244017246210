import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgRecordingGradientSolid = (props, ref) => /* @__PURE__ */ jsxs("svg", { width: 24, height: 24, viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg", "data-icon": "recordinggradientsolid", ref, ...props, children: [
  /* @__PURE__ */ jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M8 14.9097C11.8161 14.9097 14.9097 11.8161 14.9097 7.99998C14.9097 4.18388 11.8161 1.09033 8 1.09033C4.1839 1.09033 1.09033 4.18388 1.09033 7.99998C1.09033 11.8161 4.1839 14.9097 8 14.9097ZM8.00007 13.7194C11.1588 13.7194 13.7195 11.1587 13.7195 7.99998C13.7195 4.84125 11.1588 2.28058 8.00007 2.28058C4.84132 2.28058 2.28065 4.84125 2.28065 7.99998C2.28065 11.1587 4.84132 13.7194 8.00007 13.7194ZM6.22217 10.0857V5.91431C6.22217 5.59623 6.58807 5.40297 6.86985 5.57611L10.2933 7.65776C10.554 7.81881 10.554 8.18119 10.2933 8.33822L6.86985 10.4239C6.58807 10.597 6.22217 10.4038 6.22217 10.0857Z", fill: "url(#paint0_linear_10098_3609)" }),
  /* @__PURE__ */ jsx("defs", { children: /* @__PURE__ */ jsxs("linearGradient", { id: "paint0_linear_10098_3609", x1: 8.35102, y1: 5.73499, x2: 8.35102, y2: 10.265, gradientUnits: "userSpaceOnUse", children: [
    /* @__PURE__ */ jsx("stop", { stopColor: "#849BE5" }),
    /* @__PURE__ */ jsx("stop", { offset: 1, stopColor: "#3D61DD" })
  ] }) })
] });
const ForwardRef = forwardRef(SvgRecordingGradientSolid);
export default ForwardRef;
ForwardRef.displayName = "RecordingGradientSolidIcon";
