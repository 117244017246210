import { createUseMutation } from '@motion/rpc'
import { client } from '@motion/web-common/rpc'

import { updateAutomationPolicy } from '../../rpc-definition'

const useUpdateAutomationPolicyMutation = createUseMutation(
  updateAutomationPolicy
)

export function useUpdateAutomationPolicy() {
  return useUpdateAutomationPolicyMutation({
    onSuccess: () => {
      client.invalidateQueries(['automations'])
    },
  })
}
