import { isNoneId } from '@motion/shared/identifiers'

import { createGroupBySort } from '~/areas/charts/utils'
import {
  type GroupByTypes,
  type GroupedNode,
  useAvailableTaskGroups,
} from '~/areas/project-management/pages/pm-v3/grouping'
import { useLookup } from '~/global/cache'
import { useMemo } from 'react'

import { useChartSettingField } from '../../chart-modal/contexts'

export const useTaskGroupBy = () => {
  const lookup = useLookup()
  const [groupByField] = useChartSettingField('groupBy')

  const availableGroups = useAvailableTaskGroups()

  const groupBy = useMemo(() => {
    if (groupByField == null) return []

    return groupByField
      .map((g) =>
        availableGroups.find(
          (x) => x.type === convertChartGroupKeyToGroupingKey(g.field)
        )
      )
      .filter(Boolean)
  }, [availableGroups, groupByField])

  const sortOrder = useMemo(() => {
    if (groupByField == null) return {}
    return groupByField.reduce(
      (acc, group) => {
        const chartSortFn = createGroupBySort(group, lookup)
        const groupingKey = convertChartGroupKeyToGroupingKey(group.field)

        acc[groupingKey] = (l: GroupedNode, r: GroupedNode) => {
          const leftChartSortableItem = {
            category: isNoneId(l.key) ? null : l.key,
            value: l.count,
          }
          const rightChartSortableItem = {
            category: isNoneId(r.key) ? null : r.key,
            value: r.count,
          }

          return chartSortFn(leftChartSortableItem, rightChartSortableItem)
        }

        return acc
      },
      {} as Record<GroupByTypes, (l: GroupedNode, r: GroupedNode) => number>
    )
  }, [groupByField, lookup])

  return useMemo(() => ({ groupBy, sortOrder }), [groupBy, sortOrder])
}

/** Convert a chart group by value into a Grouping type */
function convertChartGroupKeyToGroupingKey(field: string): GroupByTypes {
  if (['assigneeUserId', 'managerId'].includes(field)) {
    return 'user'
  }
  if (field === 'workspaceId') return 'workspace'
  if (field === 'status.name') return 'status'
  if (field === 'label.name') return 'label'
  if (field === 'priorityLevel') return 'priority'
  if (field === 'dueDate') return 'deadline'
  if (field === 'projectId') return 'project'
  if (field === 'stageDefinitionId') return 'stage'
  if (field === 'completedTime') return 'completedAt'
  if (field === 'scheduledStatus') return 'scheduledStatus'

  return field as GroupByTypes
}
