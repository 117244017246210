import { AssigneeDropdown } from '~/areas/project-management/components'
import { useAssigneeDropdown } from '~/areas/task-project/hooks'
import { AssigneeBadge } from '~/global/components/badges'
import { type TaskWithRelations } from '~/global/proxies'

export type TaskLineAssigneeItemProps = {
  task: TaskWithRelations
}

export function TaskLineAssigneeItem({ task }: TaskLineAssigneeItemProps) {
  const {
    onChange: onChangeAssignee,
    removeUnassignedOption,
    selectedAssignee,
    disabled: assigneeDisabled,
  } = useAssigneeDropdown(task)

  return (
    <AssigneeDropdown
      onChange={onChangeAssignee}
      removeUnassignedOption={removeUnassignedOption}
      selectedUserId={selectedAssignee.userId}
      workspaceId={task.workspaceId}
    >
      <button type='button' disabled={assigneeDisabled}>
        <AssigneeBadge value={selectedAssignee} />
      </button>
    </AssigneeDropdown>
  )
}
