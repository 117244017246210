import { useSharedStateQuery } from '@motion/react-core/shared-state'
import {
  type AssignmentSchema,
  type ConditionSchema,
  type PermissionPolicy,
} from '@motion/rpc-types'
import { Button, ButtonGroup } from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'
import { useModalApi } from '@motion/web-common/modals'

import { AppWorkspaceContext } from '~/global/contexts'
import { type ReactNode } from 'react'

import { useDeleteAutomationPolicy } from '../hooks/rpc'

type AutomationPolicyRowProps = {
  // We should read from cache... but not right now
  // policyId: string
  policy: PermissionPolicy
}

export function AutomationPolicyRow({ policy }: AutomationPolicyRowProps) {
  const assignmentString = useAssignmentString(policy.assignments)
  const { mutateAsync: deletePolicy } = useDeleteAutomationPolicy()
  const modalApi = useModalApi()

  function handleEdit() {
    void modalApi.open('automation-form', {
      policy,
    })
  }

  function handleDelete() {
    void deletePolicy({
      policyId: policy.id,
    })
  }

  return (
    <div
      className='flex justify-between gap-2 items-center px-4 py-3 rounded border border-semantic-neutral-border-default bg-semantic-neutral-surface-raised-bg-default
    '
    >
      <p className='text-sm line-clamp-2'>
        {getConditionString(policy.condition, assignmentString)}
      </p>

      <ButtonGroup>
        <Button
          onClick={handleEdit}
          variant='outlined'
          size='small'
          sentiment='neutral'
        >
          Edit
        </Button>
        <Button
          onClick={handleDelete}
          variant='outlined'
          size='small'
          sentiment='error'
        >
          Delete
        </Button>
      </ButtonGroup>
    </div>
  )
}

function getConditionString(
  schema: ConditionSchema,
  assignmentString: ReactNode
) {
  const condition = (
    <strong className='font-semibold'>
      {templateStr('{{field}} {{operator}} {{value}}', {
        field: schema.field,
        operator: schema.operator.toLowerCase(),
        value: schema.value,
      })}
    </strong>
  )

  return templateStr(
    'If {{condition}}, share notes & recording with {{assignments}}',
    {
      condition,
      assignments: assignmentString,
    }
  )
}

function useAssignmentString(assignments: AssignmentSchema[]) {
  const names = useSharedStateQuery(
    AppWorkspaceContext,
    (state) => {
      const workspaceIds = assignments
        .filter((assignment) => assignment.type === 'WORKSPACE')
        .flatMap((assignment) => assignment.targetIds)

      const userIds = assignments
        .filter((assignment) => assignment.type === 'USER')
        .flatMap((assignment) => assignment.targetIds)

      const workspaceNames = workspaceIds.map((id) => {
        const workspace = state.workspaces.byId[id]

        return workspace?.name ?? '(Hidden Workspace)'
      })

      const userNames = userIds.map((id) => {
        const user = state.users.byId[id]

        return user?.name ?? '(Hidden User)'
      })

      return [...workspaceNames, ...userNames]
    },
    {
      scope: 'root',
    }
  )

  return <strong>{names.join(', ')}</strong>
}
