import { ChartBarSolid, type SvgIcon } from '@motion/icons'
import { type DashboardViewBarChartSchema } from '@motion/rpc-types'
import { PopoverTrigger, SearchableList } from '@motion/ui/base'
import { keys } from '@motion/utils/object'

import { ModalFieldButton } from '~/areas/task-project/components'

import { useBarChartSettingField } from '../../contexts'
import { ListItem } from '../list-item'

export function ChartOrientationField() {
  const [orientation, setOrientation] = useBarChartSettingField('orientation')

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <SearchableList
          searchable={false}
          items={keys(itemToData)}
          computeKey={(item) => item}
          computeSelected={(item) => item === orientation}
          onSelect={(item) => {
            setOrientation(item)
            close()
          }}
          renderItem={(item) => (
            <ListItem
              icon={itemToData[item]?.icon}
              rotateIcon={itemToData[item]?.rotateIcon}
              name={getDataName(item)}
            />
          )}
        />
      )}
    >
      <ModalFieldButton label='Orientation'>
        {getDataName(orientation)}
      </ModalFieldButton>
    </PopoverTrigger>
  )
}

const itemToData: Record<
  DashboardViewBarChartSchema['orientation'],
  { icon: SvgIcon; rotateIcon: boolean; name: string }
> = {
  vertical: { icon: ChartBarSolid, rotateIcon: false, name: 'Vertical' },
  horizontal: { icon: ChartBarSolid, rotateIcon: true, name: 'Horizontal' },
}

function getDataName(item: DashboardViewBarChartSchema['orientation']): string {
  return itemToData[item]?.name ?? 'Unknown'
}
