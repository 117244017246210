import { DateTime, DateTimeUnit, Duration, Zone } from 'luxon'

import { DateLike, parseDate } from './parse-date'

type DateRange = {
  from: string
  to: string
}

type RangeOptions = {
  now?: DateLike
  zone?: string | Zone
}

export function fromRelativeNowToDateRange(
  isoDuration: string,
  opts?: RangeOptions
) {
  const now = normalizeNow(opts)

  const duration = Duration.fromISO(isoDuration)
  const farEnd = now.plus(duration)
  const [from, to] = [now, farEnd].sort()

  return {
    from: from.startOf('day').toISO(),
    to: to.endOf('day').toISO(),
  }
}

export type NamedDateRange =
  | 'today'
  | 'yesterday'
  | 'tomorrow'
  | 'next-7-days'
  | 'next-14-days'
  | 'next-30-days'
  | 'last-7-days'
  | 'last-14-days'
  | 'last-30-days'
  | 'last-60-days'
  | 'this-week'
  | 'last-week'
  | 'next-week'
  | 'this-month'
  | 'last-month'
  | 'next-month'

export function fromNamedDurationToDateRange(
  name: NamedDateRange,
  opts?: RangeOptions
): DateRange {
  const now = normalizeNow(opts)

  switch (name) {
    case 'today':
      return createDateUnitRangeFilter(now, 'day', 0)
    case 'yesterday':
      return createDateUnitRangeFilter(now, 'day', -1)
    case 'tomorrow':
      return createDateUnitRangeFilter(now, 'day', 1)

    // These are 1 less than 'expected' since it includes the current day
    case 'next-7-days':
      return createRelativeUnitFilter(now, 'day', 6)
    case 'next-14-days':
      return createRelativeUnitFilter(now, 'day', 13)
    case 'last-7-days':
      return createRelativeUnitFilter(now, 'day', -6)
    case 'last-14-days':
      return createRelativeUnitFilter(now, 'day', -13)
    case 'last-30-days':
      return createRelativeUnitFilter(now, 'day', -29)
    case 'last-60-days':
      return createRelativeUnitFilter(now, 'day', -59)
    case 'next-30-days':
      return createRelativeUnitFilter(now, 'day', 29)

    case 'this-week':
      return createDateUnitRangeFilter(now, 'week', 0)
    case 'last-week':
      return createDateUnitRangeFilter(now, 'week', -1)
    case 'next-week':
      return createDateUnitRangeFilter(now, 'week', 1)

    case 'this-month':
      return createDateUnitRangeFilter(now, 'month', 0)
    case 'last-month':
      return createDateUnitRangeFilter(now, 'month', -1)
    case 'next-month':
      return createDateUnitRangeFilter(now, 'month', 1)
  }
}

function createRelativeUnitFilter(
  base: DateTime,
  unit: DateTimeUnit,
  count: number
): DateRange {
  const farSide = base.plus({ [unit]: count })

  const [left, right] = [base, farSide].sort()
  return {
    from: left.startOf(unit).toISO(),
    to: right.endOf(unit).toISO(),
  }
}

const createDateUnitRangeFilter = (
  base: DateTime,
  unit: DateTimeUnit,
  count: number
): DateRange => {
  const offset = base.plus({ [unit]: count })
  return {
    from: offset.startOf(unit).toISO(),
    to: offset.endOf(unit).toISO(),
  }
}

function normalizeNow(opts?: RangeOptions) {
  const now = opts?.now ? parseDate(opts?.now) : DateTime.now()
  return opts?.zone ? now.setZone(opts?.zone) : now
}
