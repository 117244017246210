import { PlusSolid, XSolid } from '@motion/icons'
import { usePrevious } from '@motion/react-core/hooks'
import { ClientValidationError } from '@motion/rpc'
import { isAutoScheduledStatus } from '@motion/shared/common'
import {
  AutoScheduleToggle,
  type AutoScheduleToggleProps,
  Button,
  ButtonGroup,
  IconButton,
  Shortcut,
  Tooltip,
} from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import {
  createTemporaryNormalTask,
  isAutoScheduledToggleEnabled,
} from '@motion/ui-logic/pm/task'
import { omit } from '@motion/utils/core'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useAuthenticatedUser } from '@motion/web-common/auth'

import { useCreateTask, useTaskAutoscheduleUpdater } from '~/areas/tasks/hooks'
import { useAddTaskStateSetter } from '~/areas/tasks/states'
import { useLookup } from '~/global/cache'
import { StatusBadge } from '~/global/components/badges'
import { useTaskModalUrl } from '~/global/navigation'
import { createTaskProxy, type TaskWithRelations } from '~/global/proxies'
import { checkTextRuleErrors, NAME_FIELD_RULE } from '~/global/rules'
import { showErrorToast } from '~/global/toasts'
import { type FormEvent, useState } from 'react'
import { useNavigate } from 'react-router'

import { CellAction, CellText } from './components'
import { useAddItemCellRef, useInferTaskDataHandler } from './hooks'
import { type TaskInferItem } from './hooks/utils'

import { useSetLastCreatedId } from '../hooks'

export type AddTaskButtonCellProps = {
  parentRowId: string | null
  item: TaskInferItem
}

export const AddTaskButtonCell = ({
  parentRowId,
  item,
}: AddTaskButtonCellProps) => {
  const { uid: userId } = useAuthenticatedUser()
  const setAddTaskState = useAddTaskStateSetter()
  const lookup = useLookup()
  const buildTaskModalUrl = useTaskModalUrl()
  const navigate = useNavigate()

  const getTaskData = useInferTaskDataHandler({ useLastItemFallback: true })

  const handleClickAdd = () => {
    recordAnalyticsEvent('PROJECT_MANAGEMENT_LIST_INLINE_ADD_CLICK', {
      type: 'task',
    })

    const {
      workspaceId,
      projectId,
      assigneeId,
      priorityLevel,
      statusId,
      deadline,
      startDate,
      labelId,
      customFieldValues,
      stageDefinitionId,
      isAutoScheduled,
    } = getTaskData(item)

    // If we cannot determine the right data to figure out where to create the task, we simply open the modal
    if (
      workspaceId == null ||
      (stageDefinitionId != null && projectId == null)
    ) {
      return navigate(buildTaskModalUrl())
    }

    setAddTaskState(() => {
      const status = lookup('statuses', statusId)

      return {
        parentRowId,
        task: createTaskProxy(
          createTemporaryNormalTask({
            isAutoScheduled:
              isAutoScheduled ??
              (assigneeId != null && isAutoScheduledStatus(status)),
            assigneeUserId: assigneeId,
            createdByUserId: userId,
            workspaceId,
            projectId,
            statusId,
            dueDate: deadline,
            startDate,
            priorityLevel: priorityLevel ?? undefined,
            labelIds: labelId ? [labelId] : undefined,
            customFieldValues,
            stageDefinitionId,
          }),
          lookup
        ),
      }
    })
  }

  return (
    <CellAction
      className='pl-7 pr-2.5 gap-1 text-semantic-neutral-text-subtle'
      onClick={handleClickAdd}
      data-inline-add-button
    >
      <PlusSolid className='w-3 h-3' />
      <span>Add task</span>
    </CellAction>
  )
}

export type AddTaskNameCellProps = {
  task: TaskWithRelations
}
export const AddTaskNameCell = ({ task }: AddTaskNameCellProps) => {
  const setAddTaskState = useAddTaskStateSetter()
  const createTask = useCreateTask()
  const updateTaskAutoschedule = useTaskAutoscheduleUpdater()
  const setLastCreatedId = useSetLastCreatedId()

  const {
    disabled: autoscheduledDisabled,
    tooltipText: autoscheduledTooltipText,
  } = isAutoScheduledToggleEnabled(task)
  const handleAutoScheduleChange: AutoScheduleToggleProps['onChange'] = (e) => {
    void updateTaskAutoschedule(task, e.currentTarget.checked)
  }

  const [busy, setBusy] = useState(false)
  const [name, setName] = useState('')
  const prevName = usePrevious(name)

  const handleSave = async () => {
    try {
      const trimName = name.trim()
      if (trimName.length === 0) return handleCancel()

      const errorMessage = checkTextRuleErrors(trimName, NAME_FIELD_RULE)
      if (errorMessage) {
        throw new ClientValidationError(errorMessage)
      }

      // Should never hit this
      if (task.type !== 'NORMAL') {
        throw new Error('Cannot inline create a non normal task')
      }

      setBusy(true)
      setName('')
      const created = await createTask({
        ...omit(task, ['id']),
        name: trimName,
      })

      setLastCreatedId(created.id)
      // Refocus the textfield to quickly add another task
      cellRef.current?.focus()
    } catch (e) {
      if (prevName) {
        setName(prevName)
      }
      showErrorToast(e)
    } finally {
      setBusy(false)
    }
  }
  const handleSubmit = async (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault()
    handleSave()
  }

  const handleCancel = () => {
    setAddTaskState(() => ({
      parentRowId: null,
      task: null,
    }))
  }

  const cellRef = useAddItemCellRef({
    onClickOutside: handleSave,
  })

  return (
    <CellText
      ref={cellRef}
      withHover
      className='pl-7 pr-2.5 gap-1.5'
      as='form'
      onSubmit={handleSubmit}
    >
      <StatusBadge value={task.status} size='small' hideTooltip />
      <div className='flex-1'>
        <TextField
          autoFocus
          variant='muted'
          value={name}
          onChange={setName}
          onKeyDown={(evt) => {
            if (evt.key === 'Escape') {
              handleCancel()
            }
          }}
        />
      </div>
      <Tooltip asChild content={autoscheduledTooltipText}>
        <AutoScheduleToggle
          checked={task.isAutoScheduled}
          labelHidden
          onChange={handleAutoScheduleChange}
          disabled={autoscheduledDisabled}
        />
      </Tooltip>
      <ButtonGroup size='small'>
        <IconButton
          icon={XSolid}
          variant='muted'
          sentiment='neutral'
          size='small'
          onClick={handleCancel}
        />
        <Button
          type='submit'
          variant='solid'
          sentiment='primary'
          size='small'
          iconOnly
          loading={busy}
          disabled={name.length < 1}
        >
          <Shortcut shortcut='enter' size='small' />
        </Button>
      </ButtonGroup>
    </CellText>
  )
}
