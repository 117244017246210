import { type MotionRoute } from '~/routing/api'

import { MeetingInsightPage } from './meeting-insight-page'
import { MeetingInsightShell } from './meeting-insight-shell'
import { MeetingInsightsViewPage } from './meeting-insights-view-page'

declare module '~/routing/hooks/navigate-by-id-params' {
  interface NavigateByIdParams {
    'meeting-insights': {
      meetingInsightsId: string
    }
    'meeting-insights-list': void
  }
}

export const aiHackerhouseRoutes: MotionRoute[] = [
  {
    path: 'meeting-insights',
    element: <MeetingInsightShell />,
    featureFlag: 'ai-meeting-bot',
    children: [
      {
        id: 'meeting-insights-list',
        path: '',
        element: <MeetingInsightsViewPage />,
      },
      {
        id: 'meeting-insights',
        path: ':meetingInsightsId',
        element: <MeetingInsightPage />,
      },
    ],
  },
]
