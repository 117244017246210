import { type COLOR } from '@motion/shared/common'
import { Button, FormModal } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { getNewColorName, labelColors } from '@motion/ui-logic'
import { useHasTreatment } from '@motion/web-common/flags'

import { ColorItemBadge } from '~/global/components/badges'
import {
  ColorDropdown,
  LegacyColorDropdown,
} from '~/global/components/dropdowns'
import { useState } from 'react'

import { LabelColoredIcon } from '../../project-management/settings/common'

export type NewOptionModalProps = {
  onSave: (name: string, color: string) => Promise<void>
  onClose: () => void
  title: string
  placeholder?: string
}

export const NewOptionModal = ({
  onSave,
  onClose,
  title,
  placeholder,
}: NewOptionModalProps) => {
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  const [currentColor, setCurrentColor] = useState<string>(labelColors[0])

  const hasNewColorsEnabled = useHasTreatment(
    'new-colors-for-labels-and-custom-fields'
  )

  const onSubmit = async () => {
    setLoading(true)
    await onSave(name, currentColor)
    setLoading(false)
  }

  return (
    <FormModal
      visible
      title={title}
      onClose={onClose}
      submitAction={{
        onAction: onSubmit,
        disabled: loading || name.trim().length === 0,
        text: title,
      }}
    >
      <div className='flex items-center gap-2 self-stretch min-w-[400px]'>
        {hasNewColorsEnabled ? (
          <ColorDropdown
            selectedColor={getNewColorName(currentColor)}
            onChange={(color: COLOR) => {
              setCurrentColor(color)
            }}
          >
            <Button
              iconOnly
              variant='outlined'
              sentiment='neutral'
              size='small'
            >
              <div className='px-[5px] py-1'>
                <ColorItemBadge color={getNewColorName(currentColor)} />
              </div>
            </Button>
          </ColorDropdown>
        ) : (
          <LegacyColorDropdown
            colorOptions={labelColors}
            renderItem={(color, onClose) => (
              <Button
                iconOnly
                key={color}
                onClick={() => {
                  setCurrentColor(color)
                  onClose()
                }}
                size='small'
                variant='muted'
              >
                <LabelColoredIcon color={color} />
              </Button>
            )}
            trigger={
              <Button
                iconOnly
                variant='outlined'
                sentiment='neutral'
                size='small'
              >
                <div className='p-1'>
                  <LabelColoredIcon color={currentColor} />
                </div>
              </Button>
            }
          />
        )}
        <div className='w-full'>
          <TextField
            autoFocus
            placeholder={placeholder}
            size='normal'
            value={name}
            onChange={setName}
          />
        </div>
      </div>
    </FormModal>
  )
}
