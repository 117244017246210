import { RechartsStackedBarChart } from '@motion/web-charts/bar'
import { type ChartQueryResponseSchema } from '@motion/zod/client'

import { ChartQueryDataProvider } from './chart-query-data-provider'
import { type ConnectedChartProps } from './types'
import { useChartGroupDefinition } from './use-chart-category'
import { useChartLookups } from './use-chart-lookups'
import { useValueFormatter } from './use-value-formatter'

export const ConnectedBarChart = (props: ConnectedChartProps<'bar'>) => {
  return (
    <ChartQueryDataProvider chart={props.chart}>
      {(chartQueryData) => {
        return <InnerBarChart data={chartQueryData} chart={props.chart} />
      }}
    </ChartQueryDataProvider>
  )
}

type InnerBarChartProps = ConnectedChartProps<'bar'> & {
  data: ChartQueryResponseSchema
}

export const InnerBarChart = ({ data, chart }: InnerBarChartProps) => {
  const lookups = useChartLookups()

  const category = useChartGroupDefinition(chart.groupBy[0])
  const group = useChartGroupDefinition(chart.groupBy[1])

  const valueFormatter = useValueFormatter(chart)

  if (category == null) {
    return <div>Please add a group by</div>
  }

  return (
    <RechartsStackedBarChart
      category={category}
      group={group ?? category}
      data={data.data}
      labelAccessor={lookups.getLabel}
      showLabelIndicatorAccessor={lookups.getShowLabelIndicator}
      colorAccessor={lookups.getColor}
      stacked={chart.layout === 'stacked'}
      valueAxis={{
        label:
          chart.aggregate.type === 'count' ? chart.item : chart.aggregate.field,
      }}
      categoryAxis={{
        size: category.key === 'assigneeUserId' ? 30 : undefined,
      }}
      valueFormatter={valueFormatter}
      layout={chart.orientation}
    />
  )
}
