import { type DashboardViewCellSchema } from '@motion/zod/client'

import {
  buildTreeGroup,
  type GroupedNode,
} from '~/areas/project-management/pages/pm-v3/grouping'
import { type TreeListRowValueType } from '~/areas/project-management/pages/pm-v3/tree-list'
import { useLookup } from '~/global/cache'
import { createTaskProxy } from '~/global/proxies'
import { useMemo, useState } from 'react'

import { ColumnButton } from './column-button'
import { defaultTreeListOptionsValue, TaskTreeList } from './task-tree-list'

import { useChartTasks, useTaskGroupBy } from '../hooks'

export type ConnectedTaskPanelProps = {
  chartCell: DashboardViewCellSchema
}

export function ConnectedTaskPanel({ chartCell }: ConnectedTaskPanelProps) {
  const { groupBy: taskGroupBy, sortOrder: taskGroupBySortOrder } =
    useTaskGroupBy()
  const lookup = useLookup()

  const { data: response } = useChartTasks(chartCell.chart)

  const taskProxies = useMemo(() => {
    if (response == null) return []

    return response.map((task) => createTaskProxy(task, lookup))
  }, [response, lookup])

  const tree = useMemo(() => {
    return buildTreeGroup(taskGroupBy)
      .add('task', taskProxies)
      .buildTree<GroupedNode<TreeListRowValueType>>({
        hideEmptyGroups: true,
        sortGroupFns: taskGroupBySortOrder,
      })
  }, [taskGroupBy, taskGroupBySortOrder, taskProxies])

  const [columnsVisibility, setColumnsVisibility] = useState(
    defaultTreeListOptionsValue.columnsVisibility
  )

  return (
    <div className='p-4 grid grid-rows-[auto_1fr] gap-4 h-full'>
      <div className='flex flex-row items-center'>
        <h2 className='flex-1 text-semantic-neutral-text-default font-semibold text-[14px] leading-4'>
          Tasks ({tree.allItems.length})
        </h2>
        <ColumnButton
          columnsVisibility={columnsVisibility}
          onChange={(column) =>
            setColumnsVisibility((prev) => ({
              ...prev,
              [column]: !prev[column],
            }))
          }
        />
      </div>

      <TaskTreeList tree={tree} columnsVisibility={columnsVisibility} />
    </div>
  )
}
