import { PlusSolid, UsersSolid } from '@motion/icons'
import { classed } from '@motion/theme'
import { Button } from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'
import {
  type BillingPrices,
  makeTeamBillingStr,
  makeTeamResubscribeCtaStr,
  MAX_PRO_TEAM_UPGRADE_SIZE,
} from '@motion/ui-logic/billing'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useShouldShowSalesTaxMessage } from '@motion/web-billing'
import { useHasTreatment } from '@motion/web-common/flags'
import { useModalApi } from '@motion/web-common/modals'

import { useActiveMemberCount } from '~/global/hooks/team'
import { useGetTeamPrices, useUpdateBucketSeats } from '~/global/rpc/team'
import { useAppSelector } from '~/state/hooks'
import { fetchTeam } from '~/state/projectManagement/teamThunks'
import { dispatch } from '~/state/proxy'
import { type Team } from '~/state/TeamTypes'
import {
  getSubscription,
  selectEmail,
  type StripeSubscription,
} from '~/state/userSlice'
import { useToggleIntercom } from '~/utils/toggleIntercom'
import { DateTime } from 'luxon'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'

import { BillingSection, BillingSectionTitle } from './billing-section'

export type TeamPlanSectionProps = {
  stripeSubscription: StripeSubscription
  isActiveTeam: boolean
  numUsers: number
  team?: Readonly<Team | null>
}

const TeamPlanSection = ({
  isActiveTeam,
  stripeSubscription,
  numUsers,
  teamPrices,
  team,
}: TeamPlanSectionProps & { teamPrices: BillingPrices }) => {
  const modalApi = useModalApi()
  const toggleIntercom = useToggleIntercom()
  const navigate = useNavigate()
  const { mutateAsync: updateBucketSeats } = useUpdateBucketSeats()
  const email = useAppSelector(selectEmail)
  const shouldShowSalesTaxMessage = useShouldShowSalesTaxMessage()

  const teamId = team?.id
  const teamSubscription = team?.pmTeamSubscription
  const isCancelledTeam = team && teamSubscription?.status === 'canceled'

  const subscriptionMultiplier = stripeSubscription.quantity
  const isAnnual = stripeSubscription.plan.interval === 'year'
  const formattedEndDate = stripeSubscription.current_period_end
    ? DateTime.fromSeconds(stripeSubscription.current_period_end).toFormat(
        'LLL dd, yyyy'
      )
    : ''

  const activeMemberCount = useActiveMemberCount()

  const teamTrialForIndividualEnabled = useHasTreatment(
    'team-trial-for-individual'
  )

  const shouldShowCreateTeamTrial = teamTrialForIndividualEnabled && !teamId

  const makeTeamSeatsModalSubtext = (seats: number) => {
    if (numUsers > seats) {
      return (
        <p className='text-xs text-semantic-neutral-text-default'>
          {templateStr(
            'You have {{numUsers}} seats used, remove users in {{membersLink}} to switch to {{seats}} seats. Please {{supportLink}} if you need assistance with seat management.',
            {
              numUsers,
              membersLink: (
                <LinkWrapper>
                  <Link
                    to='/web/settings/team'
                    onClick={() => modalApi.dismiss('manage-team-seats')}
                  >
                    Members
                  </Link>
                </LinkWrapper>
              ),
              seats,
              supportLink: (
                <LinkWrapper>
                  <a onClick={toggleIntercom}>contact support</a>
                </LinkWrapper>
              ),
            }
          )}
        </p>
      )
    }
    return (
      <p className='text-xs text-semantic-neutral-text-subtle'>
        {makeTeamBillingStr({
          isAnnual,
          quantity: seats,
          isSeats: true,
          prorationTextParams: {
            isTrial: stripeSubscription.status === 'trialing',
            downgradeDate:
              seats < subscriptionMultiplier ? formattedEndDate : undefined,
          },
          teamPrices,
          shouldShowSalesTaxMessage,
        })}
      </p>
    )
  }

  return (
    <BillingSection>
      {!isActiveTeam &&
        (isCancelledTeam ? (
          <div className='bg-semantic-error-bg-default rounded-tl rounded-tr flex justify-center items-center p-4 gap-2 text-semantic-error-text-default'>
            <p className='text-sm font-medium'>
              {makeTeamResubscribeCtaStr((activeMemberCount ?? 1) - 1)}
            </p>
            <Button
              size='small'
              onClick={() =>
                modalApi.open('pay-team-form', {
                  team,
                  userEmail: email,
                  onSuccess: async () => {
                    await dispatch(fetchTeam())
                    modalApi.dismiss('pay-team-form')
                  },
                })
              }
            >
              Subscribe to team
            </Button>
          </div>
        ) : (
          <div className='bg-gradient-to-r from-[#AA47F71A] to-[#F147EA1A] rounded-tl rounded-tr flex justify-center p-4 gap-2 text-semantic-gradient-purple'>
            <UsersSolid className='w-[18px] h-[18px]' />
            <p className='text-sm font-medium'>Motion is better with a team</p>
            {shouldShowCreateTeamTrial && (
              <Button
                sentiment='neutral'
                onClick={() =>
                  navigate('/web/settings/team/create?phase=paying')
                }
                size='small'
              >
                Start your free team trial
              </Button>
            )}
          </div>
        ))}
      <div className='flex flex-row items-center justify-between p-6'>
        <div className='flex flex-col gap-2'>
          <BillingSectionTitle>Plan size</BillingSectionTitle>
          <h1 className='text-2xl text-semantic-neutral-text-default font-semibold'>
            {isActiveTeam ? `${subscriptionMultiplier} seats` : 'Individual'}
          </h1>
          {isActiveTeam && (
            <h2 className='text-sm text-semantic-neutral-text-default font-medium'>
              {numUsers}/{subscriptionMultiplier} seats used
            </h2>
          )}
        </div>
        {isActiveTeam && teamId ? (
          <div className='flex flex-row items-center gap-2'>
            <Button
              sentiment='neutral'
              onClick={() => {
                navigate('/web/settings/team')
              }}
            >
              See team members
            </Button>
            <Button
              sentiment='primary'
              variant='outlined'
              onClick={() => {
                recordAnalyticsEvent('TEAM_BILLING_MANAGE_SEATS', {
                  old_seats: numUsers,
                })
                modalApi.open('manage-team-seats', {
                  title: 'Manage seats',
                  actionText: 'Update seats',
                  cancelText: 'Cancel',
                  makeSubtext: makeTeamSeatsModalSubtext,
                  isAnnual,
                  onSubmit: async (seats: number) => {
                    await updateBucketSeats({ id: teamId, seats })
                    recordAnalyticsEvent('TEAM_BILLING_UPDATE_SEATS', {
                      old_seats: numUsers,
                      num_seats: seats,
                      action: seats > numUsers ? 'upgrade' : 'downgrade',
                    })
                    await dispatch(getSubscription())
                  },
                  initialSelection: subscriptionMultiplier,
                  teamSize: numUsers,
                  showSeatsUsed: true,
                  disableIfClean: true,
                  overrideMaxSeats: MAX_PRO_TEAM_UPGRADE_SIZE,
                })
              }}
            >
              Add or update seats
            </Button>
          </div>
        ) : (
          !teamId &&
          (shouldShowCreateTeamTrial ? (
            <Button
              sentiment='neutral'
              onClick={() => navigate('/web/settings/team/create?phase=paying')}
            >
              Start your free team trial
            </Button>
          ) : (
            <Button
              variant='outlined'
              onClick={() => navigate('/web/settings/team/create')}
            >
              <PlusSolid />
              Create Team
            </Button>
          ))
        )}
      </div>
    </BillingSection>
  )
}

export const ConnectedTeamPlanSection = (props: TeamPlanSectionProps) => {
  const { data: teamPrices } = useGetTeamPrices()

  if (!teamPrices) return null

  return <TeamPlanSection {...props} teamPrices={teamPrices} />
}

const LinkWrapper = classed('span', {
  base: `text-semantic-primary-text-default underline`,
})
