import { useSharedStateContext } from '@motion/react-core/shared-state'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'
import { type VersionedViewV2 } from '@motion/zod/client'

import { useDeleteView as useDeleteViewApi } from '~/global/rpc/v2'
import { useCallback } from 'react'

import { useSelectView } from './use-select-view'

import { ViewStateKey } from '../../view-state'

export const useDeleteView = () => {
  const sharedStateApi = useSharedStateContext()

  const deleteView = useDeleteViewApi()
  const selectView = useSelectView()
  const modalApi = useModalApi()

  return useCallback(
    async (view: VersionedViewV2) => {
      recordAnalyticsEvent('PROJECT_MANAGEMENT_DELETE_SAVED_VIEW', {
        isPrivate: Boolean(view.isPrivate),
        type: view.definition.type,
      })

      const result = await modalApi.prompt('confirm', {
        analytics: {
          name: 'delete-saved-view',
        },
        confirmButtonText: 'Delete',
        destructive: true,
        description: `Are you sure you want to delete the '${view.name}' view?`,
        title: 'Delete View?',
      })

      if (result === ModalDismissed || result === false) return
      await deleteView.mutateAsync({ viewId: view.id })

      if (sharedStateApi.get(ViewStateKey).viewId === view.id) {
        selectView(null)
      }
    },
    [sharedStateApi, deleteView, modalApi, selectView]
  )
}
