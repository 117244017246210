import { AutoscheduleSolid, BellSolid } from '@motion/icons'
import { FieldButton, PopoverTrigger } from '@motion/ui/base'
import { isValidTaskDeadlineDateOption } from '@motion/ui-logic/pm/task'

import { DateTriggerButton } from '~/areas/ai-hackerhouse/event/modals/action-item-modal/components/date-trigger-button'
import { type ModalFieldButtonProps } from '~/areas/ai-hackerhouse/event/modals/action-item-modal/components/modal-field-button'
import { type DeadlineDropdownProps } from '~/areas/project-management/components'
import { WarnOnToggle } from '~/areas/project-management/components/warn-on-toggle'
import { TaskDeadlineDropdown } from '~/areas/tasks/components'
import { useTaskUpdater } from '~/areas/tasks/hooks'
import { useController } from 'react-hook-form'

import { useActionItemForm } from '../hooks'

export const DeadlineField = ({
  showLabel = true,
  showLeftIcon = true,
  showWarnButton = true,
  allowRemoveDeadline = true,
  sentiment = 'ai',
  size = 'normal',
}: {
  showLabel?: boolean
  showLeftIcon?: boolean
  showWarnButton?: boolean
  allowRemoveDeadline?: boolean
  sentiment?: 'ai' | 'neutral'
  size?: ModalFieldButtonProps['size']
}) => {
  const { form } = useActionItemForm()

  const { control, watch } = form

  const { field } = useController({
    name: 'dueDate',
    control,
  })

  const selectedDeadlineDate = field.value

  const startDate = watch('startDate')
  const projectId = watch('projectId')

  const priorityLevel = watch('priorityLevel')
  const isASAP = priorityLevel === 'ASAP'

  // if task is already created, can edit in-line
  const taskId = form.watch('id')
  const updateTask = useTaskUpdater()

  const onChange: DeadlineDropdownProps['onChange'] = async (value) => {
    field.onChange(value)
    if (taskId != null) {
      await updateTask(taskId, { dueDate: value })
    }
  }

  return (
    <TaskDeadlineDropdown
      contextProps={{
        projectId,
      }}
      value={selectedDeadlineDate}
      onChange={(value) => {
        onChange(value)
      }}
      isValidDateOption={(date) =>
        isValidTaskDeadlineDateOption({ type: 'NORMAL', startDate }, date)
      }
    >
      <DateTriggerButton
        label={showLabel ? 'Deadline' : undefined}
        icon={showLeftIcon && <AutoscheduleSolid />}
        onClear={
          !allowRemoveDeadline || selectedDeadlineDate == null
            ? undefined
            : () => onChange(null)
        }
        date={isASAP ? null : selectedDeadlineDate}
        placeholder={isASAP ? 'ASAP' : 'No deadline'}
        clearTooltipContent={allowRemoveDeadline && 'Remove deadline'}
        endButton={showWarnButton && <DeadlineWarnButton isAutoScheduled />}
        sentiment={sentiment}
        size={size}
      />
    </TaskDeadlineDropdown>
  )
}

type DeadlineWarnButtonProps = {
  isAutoScheduled: boolean
}

function DeadlineWarnButton({ isAutoScheduled }: DeadlineWarnButtonProps) {
  return (
    <PopoverTrigger
      renderPopover={() => (
        <div className='p-2'>
          <FormWarnOnToggle />
        </div>
      )}
    >
      <FieldButton
        size='xsmall'
        variant='outlined'
        sentiment={isAutoScheduled ? 'ai' : 'neutral'}
        iconOnly
      >
        <div className='p-0.5'>
          <BellSolid />
        </div>
      </FieldButton>
    </PopoverTrigger>
  )
}

const FormWarnOnToggle = () => {
  const { form } = useActionItemForm()

  const { control, watch } = form

  const { field } = useController({
    name: 'ignoreWarnOnPastDue',
    control,
  })

  const ignoreOnPastDue = watch('ignoreWarnOnPastDue')

  const onChange = (value: boolean) => {
    field.onChange(value)
  }

  return (
    <WarnOnToggle
      checked={!ignoreOnPastDue}
      onChange={(checked) => onChange(!checked)}
    />
  )
}
