import { type DeadlineStatus } from '@motion/shared/common'
import { cssVar } from '@motion/theme/dom'
import { type CategoryLabels } from '@motion/web-charts/common'

import { EtaLabel } from '~/global/components/labels'
import { createElement } from 'react'

export function labelOfDeadlineStatus(value: DeadlineStatus): CategoryLabels {
  const el = createElement(EtaLabel, { value })
  return {
    axis: el,
    legend: el,
    tooltip: el,
  }
}

export function colorOfDeadlineStatus(value: DeadlineStatus) {
  switch (value) {
    case 'missed-deadline':
      return cssVar('palette-red-border-strong')
    case 'scheduled-past-deadline':
      return cssVar('palette-tangerine-border-strong')
    case 'at-risk':
      return cssVar('palette-yellow-border-strong')
    case 'on-track':
      return cssVar('palette-green-border-strong')
    case 'ahead-of-schedule':
      return cssVar('palette-teal-border-strong')
    default:
      return cssVar('palette-gray-border-strong')
  }
}
