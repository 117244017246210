import { type PriorityLevelSchema } from '@motion/rpc-types'
import { type COLOR } from '@motion/shared/common'
import { cssVar } from '@motion/theme/dom'
import { getColorName } from '@motion/ui/palette'
import {
  formatToTaskDuration,
  getPrefixFromMaybeCustomFieldKey,
  isCustomFieldKey,
} from '@motion/ui-logic/pm'
import { getUserColor } from '@motion/utils/color'
import { safeParseDate } from '@motion/utils/dates'
import {
  type SegmentedLabelAccessor,
  type ShowLabelIndicatorAccessor,
} from '@motion/web-charts/common'

import { useLookup } from '~/global/cache/model-cache/lookup'
import { UserBadge } from '~/global/components/badges/user'
import { StatusLabel } from '~/global/components/labels'
import { UserLabel } from '~/global/components/labels/user'
import { useCallback, useMemo } from 'react'

import {
  colorOfDeadlineStatus,
  colorOfPriorityLevel,
  labelOfDeadlineStatus,
  labelOfPriorityLevel,
} from '../utils'
import { colorOfStatus } from '../utils/accessors/status'

export const useChartLookups = () => {
  const lookup = useLookup()

  const allProjectDefinitions = lookup('projectDefinitions')
  const stageDefinitions = useMemo(
    () => allProjectDefinitions.flatMap((pd) => pd.stages),
    [allProjectDefinitions]
  )

  const getColor = useCallback(
    (group: string, key: string | null, index: number) => {
      switch (group) {
        case 'isAutoScheduled':
          return String(key) === 'true'
            ? cssVar('palette-violet-border-strong')
            : cssVar('palette-gray-border-strong')

        case 'deadlineStatus':
          return colorOfDeadlineStatus(key as any)

        case 'projectId': {
          const p = lookup('projects', key)
          if (p == null) return cssVar(`palette-gray-border-strong`)
          if (p.color === 'gray') return undefined

          return cssVar(`palette-${p.color}-border-strong`)
        }

        case 'statusId': {
          const status = lookup('statuses', key ?? '')
          return colorOfStatus(status)
        }
        case 'status.name': {
          const status = lookup('statuses').find((x) => x.name === key)
          return status?.color
        }

        case 'projectDefinitionId': {
          const pd = lookup('projectDefinitions', key)
          if (pd == null || pd.color === 'gray') return undefined

          return cssVar(`palette-${pd.color}-border-strong`)
        }

        case 'stageDefinitionId':
        case 'activeStageDefinitionId': {
          const stageDefinition = stageDefinitions.find((s) => s.id === key)
          if (stageDefinition == null || stageDefinition.color === 'gray')
            return undefined

          return cssVar(`palette-${stageDefinition.color}-border-strong`)
        }

        case 'color':
          return cssVar(`palette-${key as COLOR}-border-strong`)

        case 'priorityLevel':
          return colorOfPriorityLevel(key as PriorityLevelSchema)

        case 'assigneeUserId':
        case 'managerId': {
          if (key == null) {
            return cssVar('palette-gray-border-strong')
          }
          const user = lookup('users', key)
          return user != null ? getUserColor(user.id) : undefined
        }

        default:
          return undefined
      }
    },
    [lookup, stageDefinitions]
  )

  const getLabel = useCallback<SegmentedLabelAccessor<any>>(
    (group: string, key: string | null, index: number) => {
      /**
       * Handle custom fields
       */
      if (isCustomFieldKey(group)) {
        if (key == null) return 'No Value'

        const type = getPrefixFromMaybeCustomFieldKey(group)
        if (type === 'multiPerson' || type === 'person') {
          const user = lookup('users', key)
          return {
            legend: <UserLabel value={user} />,
            tooltip: <UserLabel value={user} />,
            axis: (
              <UserBadge
                key={user?.id ?? key}
                value={user}
                size='normal'
                proxyProfileUri
              />
            ),
          }
        }

        if (type === 'date') {
          const date = safeParseDate(key)
          const isoDate = date?.toISODate()

          return isoDate ?? key
        }

        return key
      }

      switch (group) {
        case 'isAutoScheduled':
          return String(key) === 'true' ? 'On' : 'Off'

        case 'deadlineStatus':
          return labelOfDeadlineStatus(key as any)

        case 'workspaceId': {
          const workspace = lookup('workspaces', key)
          return workspace?.name ?? 'unknown'
        }

        case 'projectId': {
          if (key === 'null') return 'No Project'
          const p = lookup('projects', key)
          if (p == null) return key ?? 'No Project'
          return p.name
        }

        case 'folderId': {
          if (key === 'null') return 'No Folder'
          const folder = lookup('folders', key)
          return folder?.name ?? 'No Folder'
        }

        case 'statusId': {
          const status = lookup('statuses', key)
          return status?.name ?? 'unknown'
        }

        case 'status.name': {
          if (key == null) return 'unknown'
          const status = lookup('statuses').find((x) => x.name === key)
          if (status == null) return key ?? 'unknown'

          return {
            legend: <StatusLabel value={status} />,
            tooltip: <StatusLabel value={status} />,
            axis: status?.name ?? key,
          }
        }

        case 'stageDefinitionId':
        case 'activeStageDefinitionId': {
          const stageDefinition = stageDefinitions.find((s) => s.id === key)
          return stageDefinition?.name ?? 'unknown'
        }

        case 'projectDefinitionId': {
          const projectDefinition = lookup('projectDefinitions', key)
          return projectDefinition?.name ?? 'unknown'
        }

        case 'duration': {
          if (key === null) return 'No Duration'
          const duration = parseInt(key, 10)
          if (Number.isNaN(duration)) return 'No Duration'
          return formatToTaskDuration(duration)
        }

        case 'assigneeUserId':
        case 'managerId': {
          const user = lookup('users', key)
          return {
            legend: <UserLabel value={user} />,
            tooltip: <UserLabel value={user} />,
            axis: (
              <UserBadge
                key={user?.id ?? key}
                value={user}
                size='normal'
                proxyProfileUri
              />
            ),
          }
        }

        case 'color':
          return key == null ? 'gray' : getColorName(key) || key

        case 'priorityLevel':
          return labelOfPriorityLevel(key as PriorityLevelSchema)

        case 'completedTime':
        case 'dueDate':
        case 'startDate': {
          return key ?? 'null'
        }

        default:
          return undefined
      }
    },
    [lookup, stageDefinitions]
  )

  const getShowLabelIndicator = useCallback<ShowLabelIndicatorAccessor<any>>(
    (group: string) => {
      switch (group) {
        case 'status.name':
        case 'deadlineStatus':
          return false
        default:
          return true
      }
    },
    []
  )

  return {
    getLabel,
    getShowLabelIndicator,
    getColor,
  }
}
