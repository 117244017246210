import { createNoneId } from '@motion/shared/identifiers'
import type {
  MeetingActionItemSchema,
  MeetingInsightsSchema,
} from '@motion/zod/client'

import { DateTime } from 'luxon'

export function createFakeActionItem(
  meetingInsights: MeetingInsightsSchema
): MeetingActionItemSchema {
  return {
    id: createNoneId('action-item'),
    taskId: null,
    meetingInsightsId: meetingInsights.id,
    hasBeenTriaged: false,
    taskData: {
      name: '',
      dueDate: DateTime.now().plus({ days: 1 }).toISO(),
      duration: 0,
    },
  }
}
