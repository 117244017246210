import { createUseMutation } from '@motion/rpc'
import { MotionCache } from '@motion/rpc-cache'
import { type MeetingInsightsQueryResponseSchema } from '@motion/rpc-types'
import { isNoneId } from '@motion/shared/identifiers'
import { showToast } from '@motion/ui/base'
import { cloneDeep } from '@motion/utils/core'
import { values } from '@motion/utils/object'
import { client } from '@motion/web-common/rpc'

import { getTaskQueryFilters } from '~/global/cache'
import { useReadTaskFn } from '~/global/rpc/v2'

import { approveMeetingAction } from '../../rpc-definition'
import { useAiAnalyticsCalls } from '../use-ai-analytics-calls'

const useApproveActionItemMutation = createUseMutation(approveMeetingAction)
export function useApproveActionItem() {
  const readTask = useReadTaskFn()
  const { recordActionItemTriage, recordActionItemManuallyCreated } =
    useAiAnalyticsCalls()

  return useApproveActionItemMutation({
    onSuccess: async ({ newActionItem }, { actionItemId: actionItemParam }) => {
      const actionItemId = newActionItem.id
      const taskId = newActionItem.taskId

      if (taskId == null) {
        showToast('error', 'Failed to approve action item')
        return
      }

      const task = await readTask(taskId)
      if (isNoneId(actionItemParam)) {
        recordActionItemManuallyCreated(taskId, actionItemId)
      } else {
        recordActionItemTriage('accepted', taskId, actionItemId)
      }

      if (task?.type === 'NORMAL') {
        MotionCache.upsert(client, getTaskQueryFilters(taskId), {
          models: {
            tasks: {
              [taskId]: task,
            },
          },
        })

        // AIHH - Refer to comment in useFormCreateEvent, but the following code will be a lot simpler in production
        client.setQueriesData<MeetingInsightsQueryResponseSchema>(
          ['meeting-insights'],
          (oldData) => {
            if (!oldData) {
              return
            }

            const meetingInsights = values(oldData.models.meetingInsights)
            const meetingInsightForActionItem = meetingInsights.find(
              (mi) => mi.id === newActionItem.meetingInsightsId
            )

            if (meetingInsightForActionItem == null) {
              return oldData
            }

            const cloned = cloneDeep(oldData)

            // Update the action item to reflect that it has been triaged
            const meetingInsight =
              cloned.models.meetingInsights[meetingInsightForActionItem.id]

            const meetingInsightHasActionItem = meetingInsight.actionItems.some(
              (item) => item.id === actionItemId
            )

            if (!meetingInsightHasActionItem) {
              // add new action item for now
              meetingInsight.actionItems.push(newActionItem)
              return cloned
            }

            const actionItemIndex = meetingInsight.actionItems.findIndex(
              (item) => item.id === actionItemId
            )
            if (actionItemIndex === -1) {
              return cloned
            }

            meetingInsight.actionItems.splice(actionItemIndex, 1, {
              ...meetingInsight.actionItems[actionItemIndex],
              hasBeenTriaged: true,
              taskId: task.id,
            })

            return cloned
          }
        )
      }

      showToast('success', 'Task created from approved action item')
    },
    onError: (error) => {
      showToast('error', 'Failed to approve action item')
    },
  })
}
