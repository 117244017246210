import { type EntityFilterState } from './data'

export function isShowCompletedChecked(
  entity: EntityFilterState['tasks'] | EntityFilterState['projects']
): 'indeterminate' | boolean {
  if (entity.filters.statusIds) return 'indeterminate'
  if ('completedTime' in entity.filters && entity.filters.completedTime)
    return 'indeterminate'

  return entity.filters.completed === 'include'
}
