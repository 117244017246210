import { FieldButton, PopoverTrigger, SearchableList } from '@motion/ui/base'
import { getChunkDurations } from '@motion/ui-logic'

import { ModalFieldButton } from '~/areas/task-project/components'
import { useController } from 'react-hook-form'

import { SubItemIndentMarker } from '../components'
import { useActionItemForm } from '../hooks'

export const MinChunkField = () => {
  const {
    form: { watch },
  } = useActionItemForm()

  const duration = watch('duration')

  if (getChunkDurations(duration).length === 0) {
    return null
  }

  return (
    <div className='flex flex-row items-center gap-0.5'>
      <SubItemIndentMarker />

      <ModalFieldButton label='Min chunk'>
        <MinChunkDurationDropdown />
      </ModalFieldButton>
    </div>
  )
}

const MinChunkDurationDropdown = () => {
  const { form } = useActionItemForm()

  const { control, watch } = form

  const duration = watch('duration')
  const { field: minChunkDurationField } = useController({
    name: 'minimumDuration',
    control,
  })

  const selectedMinChunkDuration = minChunkDurationField.value
  const chunkDurations = getChunkDurations(duration)

  const onChange: MinChunkDurationDropdownContentProps['onChange'] = (
    value
  ) => {
    minChunkDurationField.onChange(value)
  }

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <MinChunkDurationDropdownContent
          close={close}
          onChange={onChange}
          chunkDurations={chunkDurations}
          selectedMinChunkDuration={selectedMinChunkDuration}
        />
      )}
    >
      <FieldButton variant='muted' size='small' iconOnly>
        <span className='whitespace-nowrap'>
          {chunkDurations.find(
            (item) => item.value === selectedMinChunkDuration
          )?.label ?? 'No Chunks'}
        </span>
      </FieldButton>
    </PopoverTrigger>
  )
}

type MinChunkDurationDropdownContentProps = {
  close: () => void
  onChange: (value: number | null) => void
  chunkDurations: { label: string; value: number | null }[]
  selectedMinChunkDuration: number | null
}

const MinChunkDurationDropdownContent = ({
  close,
  onChange,
  chunkDurations,
  selectedMinChunkDuration,
}: MinChunkDurationDropdownContentProps) => {
  return (
    <SearchableList
      searchable={false}
      items={chunkDurations}
      computeKey={(item) => String(item.value)}
      computeSelected={(item) => item.value === selectedMinChunkDuration}
      onSelect={(item) => {
        onChange(item.value)
        close()
      }}
      renderItem={(item) => item.label}
    />
  )
}
