import { type Section } from '@motion/ui/base'
import {
  type AllAvailableCustomFieldSchema,
  buildCustomFieldKey,
} from '@motion/ui-logic'
import { byValue } from '@motion/utils/array'
import { entries } from '@motion/utils/object'

import { DEFAULT_STRING_SORT } from '~/areas/project-management/pages/pm-v3/fields'
import { useAvailableCustomFields } from '~/areas/project-management/pages/pm-v3/filtering/custom-fields'
import { useMemo } from 'react'

import { isDateBasedGroupBy, projectItems, taskItems } from '../utils'

export type UseGroupByOptions = {
  includeNone?: boolean
  includeDateFields?: boolean
  excludeFields?: string[]
}

export const useGroupByFields = (
  dataType: 'tasks' | 'projects',
  opts: UseGroupByOptions
) => {
  const {
    includeNone = false,
    includeDateFields = false,
    excludeFields = [],
  } = opts

  const customFields = useAvailableCustomFields()

  return useMemo(() => {
    const normalFields = dataType === 'tasks' ? taskItems : projectItems
    const customFieldsRecord = buildCustomFieldsRecord(customFields)
    const allFields = { ...normalFields, ...customFieldsRecord }

    let normalFieldKeys = getSortedKeys(normalFields)
    let customFieldKeys = getSortedKeys(customFieldsRecord)

    if (includeNone) {
      normalFieldKeys.unshift('none')
    }

    normalFieldKeys = normalFieldKeys.filter(
      (field) =>
        (includeDateFields || !isDateBasedGroupBy({ field })) &&
        !excludeFields.includes(field)
    )
    customFieldKeys = customFieldKeys.filter(
      (field) =>
        (includeDateFields || !isDateBasedGroupBy({ field })) &&
        !excludeFields.includes(field)
    )

    const sections: Section<string>[] = [
      {
        key: 'fields',
        label: 'Fields',
        items: normalFieldKeys,
        initialExpanded: true,
      },
      {
        key: 'custom-fields',
        label: 'Custom Fields',
        items: customFieldKeys,
        initialExpanded: true,
      },
    ]

    const getName = (item: string | null | undefined) => {
      if (item == null || item === 'none') return 'None'
      return allFields[item as keyof typeof allFields] ?? item
    }

    return {
      sections,
      getName,
    }
  }, [dataType, customFields, includeNone, includeDateFields, excludeFields])
}

const buildCustomFieldsRecord = (
  customFields: AllAvailableCustomFieldSchema[]
) => {
  return customFields.reduce<Record<string, string>>((acc, field) => {
    acc[buildCustomFieldKey(field)] = field.name
    return acc
  }, {})
}

const getSortedKeys = (fields: Record<string, string>) => {
  return entries(fields)
    .sort(byValue(([_, v]) => v, DEFAULT_STRING_SORT))
    .map(([k]) => k)
}
