import { PopoverTrigger, SearchableList } from '@motion/ui/base'

import { ConnectedUserLabel } from '~/global/components/labels'
import { useTeamMembers } from '~/global/hooks/team'
import { type ReactNode } from 'react'

/**
 * There's another similar component but the name is inaccurate, should be `WorkspaceMemberDropdown`
 * @param param0
 * @returns
 */
export const TeamMemberDropdown = ({
  children,
  onChange,
  selectedUserIds,
}: {
  children: ReactNode
  onChange: (userIds: string[]) => void
  selectedUserIds: string[]
}) => {
  const teamMembers = useTeamMembers()

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={() => (
        <SearchableList
          itemType='checkbox'
          computeSearchValue={(item) => item.user.name}
          items={teamMembers}
          onSelect={(items) => {
            onChange(items.map((item) => item.userId))
          }}
          renderItem={(item) => <ConnectedUserLabel userId={item.userId} />}
          computeKey={(item) => item.userId}
          computeSelected={(item) => selectedUserIds.includes(item.userId)}
        />
      )}
    >
      {children}
    </PopoverTrigger>
  )
}
