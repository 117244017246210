import {
  MenuAlt2Solid,
  PlannerSolid,
  type SvgIcon,
  TemplateSolid,
  ViewBoardsSolid,
} from '@motion/icons'
import { isCanceledStatus, isCompletedStatus } from '@motion/shared/common'
import { classed } from '@motion/theme'
import {
  ButtonTabs,
  FormModal,
  PopoverButton,
  SearchableDropdown,
  type TabItem,
} from '@motion/ui/base'
import { showToast } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { useHasTreatment } from '@motion/web-common/flags'
import { type ModalDefinitions } from '@motion/web-common/modals/definitions'
import {
  type DashboardViewDefinitionV2,
  type VersionedViewV2,
} from '@motion/zod/client'

import { useWorkspaceFns } from '~/global/hooks'
import { useCurrentTeam } from '~/global/rpc/team'
import {
  createDefaultDashboardViewDefinition,
  type ViewEntityCtx,
  // eslint-disable-next-line import-x/no-restricted-paths
} from '~/pages/pm/dashboard/view-state/defaults'
import { useMemo, useState } from 'react'

import { usePageData } from '../../routes/hooks/use-v3-page-data'
import { type PageType } from '../../routes/types'
import { getDefaultView } from '../defaults'
import { useSaveView, type UseSaveViewArgs } from '../hooks/use-save-view'
import { type LocalView } from '../types'

type ViewLayout = 'list' | 'kanban' | 'gantt' | 'dashboard'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'create-view-v2': {
      currentView: LocalView
      type?: ViewLayout
    } & PromptCallbacks<VersionedViewV2>
  }
}

type ConnectedCreateViewModalProps = ModalDefinitions['create-view-v2'] & {
  close: (view?: VersionedViewV2) => void
}

export const ConnectedCreateViewModal = (
  props: ConnectedCreateViewModalProps
) => {
  const route = usePageData()
  const { data: team } = useCurrentTeam()

  const [name, setName] = useState('')
  const canShare = route.page !== 'my-tasks' && Boolean(team) // must have a team to make team views
  const [isPrivate, setIsPrivate] = useState(
    props.currentView.isPrivate ?? !canShare
  )
  const [type, setType] = useState<ViewLayout>(props.type ?? 'list')
  const save = useSaveView()

  const hasDashboard = useHasTreatment('charts-preview')

  const { getAllStatuses } = useWorkspaceFns()

  const items = useMemo(() => {
    const items: TabItem[] = [
      {
        content: <ButtonContent text='List' Icon={MenuAlt2Solid} />,
        value: 'list',
        name: 'list',
        onAction: () => setType('list'),
      },
      {
        content: <ButtonContent text='Kanban' Icon={ViewBoardsSolid} />,
        value: 'kanban',
        name: 'kanban',
        onAction: () => setType('kanban'),
      },
      {
        content: <ButtonContent text='Gantt' Icon={PlannerSolid} />,
        value: 'gantt',
        name: 'gantt',
        onAction: () => setType('gantt'),
      },
    ]
    if (hasDashboard) {
      items.push({
        content: <ButtonContent text='Dashboard' Icon={TemplateSolid} />,
        value: 'dashboard',
        name: 'dashboard',
        onAction: () => setType('dashboard'),
      })
    }
    return items
  }, [hasDashboard])

  return (
    <FormModal
      onClose={() => props.close()}
      submitAction={{
        onAction: async () => {
          const allStatuses = getAllStatuses()
          const viewToSave = prepareView({
            name,
            isPrivate,
            type,
            baseView: props.currentView,
            page: route.page,
            params: route.params,
            entityCtx: {
              completedStatuses: allStatuses.filter(isCompletedStatus),
              cancelledStatuses: allStatuses.filter(isCanceledStatus),
            },
          })

          save({
            asNew: true,
            view: viewToSave,
          })
            .then((savedView) => {
              if (savedView) {
                props.close(savedView)
                showToast('success', 'View created')
              }
            })
            .catch((ex) => {
              showToast('error', 'Failed to create view')
            })
        },
        disabled: name.length < 2,
      }}
      title='New View'
      visible
    >
      <div className='flex flex-col items-start gap-4 self-stretch'>
        <div className='flex flex-col w-full gap-4 justify-between'>
          <div>
            <ButtonTabs fullWidth activeValue={type} items={items} />
          </div>
          <div className='flex flex-col [&>*]:flex-1 gap-3'>
            <TextField
              autoFocus
              value={name}
              label={<Label>Label</Label>}
              placeholder='View name'
              onChange={setName}
              size='normal'
            />
            {canShare && (
              <SearchableDropdown
                items={[true, false].map((item) => ({
                  id: `${item}`,
                  label: item ? 'Personal view' : 'Team view',
                  value: item,
                }))}
                renderItem={(item) => item.label}
                onChange={(item) => setIsPrivate(item.value)}
              >
                <PopoverButton className='h-[34px]'>
                  {isPrivate ? 'Personal view' : 'Team view'}
                </PopoverButton>
              </SearchableDropdown>
            )}
          </div>
        </div>
      </div>
    </FormModal>
  )
}

type ButtonContentProps = {
  Icon: SvgIcon
  text: string
}

const ButtonContent = (props: ButtonContentProps) => {
  return (
    <div className='flex flex-col items-center gap-2'>
      <props.Icon width={20} height={20} />
      <span className='text-2xs'>{props.text}</span>
    </div>
  )
}

type CreateViewArgs = {
  name: string
  type: ViewLayout
  page: PageType
  params: Record<string, string>
  isPrivate: boolean
  baseView: LocalView
  entityCtx: ViewEntityCtx
}

function prepareView(args: CreateViewArgs) {
  const newView =
    args.type === 'dashboard'
      ? createDefaultDashboardView(
          args.baseView,
          createDefaultDashboardViewDefinition(
            args.page,
            args.params,
            args.entityCtx
          )
        )
      : getDefaultView(args.page)

  const viewToSave: LocalView = {
    ...newView,
    name: args.name,
    isPrivate: args.isPrivate,
    targetType: args.baseView.targetType,
    targetId: args.baseView.targetId,
  }

  if (
    viewToSave.definition.type !== 'dashboard' &&
    viewToSave.definition.type !== 'team-schedule' &&
    args.type !== 'dashboard'
  ) {
    viewToSave.definition.layout = args.type
    if (viewToSave.definition.layout === 'gantt') {
      viewToSave.definition.itemType = 'projects'
    }
  }

  return viewToSave
}
type CreateDefaultDashboardViewArgs = Pick<
  LocalView,
  'type' | 'isPrivate' | 'targetType' | 'targetId'
>

function createDefaultDashboardView(
  args: CreateDefaultDashboardViewArgs,
  definition: DashboardViewDefinitionV2
): UseSaveViewArgs['view'] {
  return {
    name: 'New Dashboard',
    type: args.type,
    isPrivate: args.isPrivate,
    targetType: args.targetType,
    targetId: args.targetId,
    definition,
  }
}

const Label = classed(
  'p',
  'mb-2 text-sm font-semibold text-semantic-neutral-text-subtle'
)
