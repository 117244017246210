import { type Inclusion } from '@motion/zod/client'

import { useFieldFilter } from '~/areas/project-management/filters'
import { type DropdownFilterProps } from '~/areas/project-management/filters/components/types'
import { FilterItem } from '~/areas/project-management/filters/components/v2'
import { ConditionSelector } from '~/areas/project-management/filters/components/v2/buttons/filter-item/condition-dropdown'

import { getConditionLabel, INCLUSION_CONDITIONS } from './conditions'

export const ConnectedCompletedFilter = (
  props: DropdownFilterProps<'tasks'>
) => {
  const [value, setValue] = useFieldFilter('tasks', 'completed')

  const label = getConditionLabel(value)

  return (
    <FilterItem
      hideInvert
      label='Completed'
      onBlur={props.onBlur}
      openOnMount={props.openOnMount}
      onDismiss={() => setValue(null)}
      renderDropdown={({ close }) => (
        <ConditionSelector
          conditions={INCLUSION_CONDITIONS}
          value={value}
          onSelect={(condition: Inclusion) => setValue(condition)}
        />
      )}
    >
      {label}
    </FilterItem>
  )
}
