import { type COLOR } from '@motion/shared/common'
import {
  getColorFromColorId,
  getColorHueValue,
  getColorIdFromColor,
} from '@motion/ui/palette'
import { type ColorId, isColorId } from '@motion/ui-logic'
import { type ProjectSchema } from '@motion/zod/client'

type ColorOptions = {
  projectColor: ProjectSchema['color'] | undefined
  calendarColorId: ColorId | null | undefined
}

export function getEventColor(
  colorId: ColorId | null,
  options: ColorOptions
): COLOR {
  const { projectColor, calendarColorId } = options

  if (colorId != null) {
    return getColorFromColorId(colorId)
  }

  if (projectColor != null) {
    return projectColor
  }

  if (calendarColorId != null && isColorId(calendarColorId)) {
    return getColorFromColorId(calendarColorId)
  }

  // For legacy reasons, the default color is blue instead of gray for calendars/events
  return 'blue'
}

/**
 * Returns the color hue for a given event
 * Returns the hue based on the color id when the event has a custom color id.
 * Otherwise reads it from the project when the event is in a project, or from the calendar
 */
export function getEventColorHue(
  colorId: ColorId | null,
  options: ColorOptions
): number {
  return getColorHueValue(getEventColor(colorId, options))
}

/**
 * Returns the color id for a given event
 * Returns the id based on the color id when the event has a custom color id.
 * Otherwise reads it from the project when the event is in a project, or from the calendar
 */
export function getEventColorId(
  colorId: ColorId | null,
  options: ColorOptions
): ColorId | null {
  return getColorIdFromColor(getEventColor(colorId, options))
}
