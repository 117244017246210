import {
  API,
  type ApiTypes,
  type ApiUseMutationOptions,
  createUseMutation,
  createUseQuery,
  createUseQueryFn,
  useQueryOptionsFactory,
} from '@motion/rpc'
import {
  createQueryFilter,
  getCacheEntry,
  MODEL_CACHE_KEY,
  MotionCache,
  useStoreModelsFn,
} from '@motion/rpc-cache'
import { type NoteSchema } from '@motion/rpc-types'
import { showToast } from '@motion/ui/base'
import { Sentry } from '@motion/web-base/sentry'

import {
  useQuery,
  useQueryClient,
  type UseQueryResult,
} from '@tanstack/react-query'

export const useGetNoteById = createUseQueryFn(API.notes.getNoteById)
export const useQueryNotes = createUseQuery(API.notes.queryNotes)

export const useUpdateNoteMutation = createUseMutation(API.notes.updateNote)
export const useCreateNoteMutation = createUseMutation(API.notes.createNote)
export const useDeleteNoteMutation = createUseMutation(API.notes.deleteNote)

export type NoteByIdApi = ApiTypes<typeof API.notes.getNoteById>
export type NoteByIdApiArgs = Partial<NoteByIdApi['args']>

export const useNoteById = (
  args: NoteByIdApiArgs,
  opts?: NoteByIdApi['UseQueryOptions']
): UseQueryResult<NoteSchema | undefined> => {
  const { id } = args
  const storeModels = useStoreModelsFn()
  const client = useQueryClient()

  const noteId = id ?? ''

  const cachedNote = getCacheEntry(client, 'notes', noteId)

  const queryOptionsOf = useQueryOptionsFactory(API.notes.getNoteById)
  const queryArgs = queryOptionsOf(
    {
      ...args,
      id: noteId,
    },
    { enabled: Boolean(noteId), ...opts }
  )

  const initialData: NoteByIdApi['data'] | undefined = cachedNote
    ? {
        id: noteId,
        meta: {
          model: 'notes',
        },
        models: {
          notes: cachedNote
            ? {
                [noteId]: cachedNote.value,
              }
            : {},
          uploadedFiles: {},
        },
      }
    : undefined

  return useQuery<NoteByIdApi['queryFnData'], Error, NoteSchema | undefined>({
    ...queryArgs,
    // @ts-expect-error - typing
    queryFn: async (ctx) => {
      const response = await queryArgs.queryFn(ctx)
      storeModels(response.models)
      return response
    },
    initialData: initialData as unknown as NoteByIdApi['queryFnData'],
    initialDataUpdatedAt: initialData ? cachedNote?.updatedAt : undefined,
    enabled: queryArgs.enabled,
    cacheTime: 0,
    notifyOnChangeProps: ['error', 'data', 'dataUpdatedAt'],
    select: (data: NoteByIdApi['queryFnData']) => {
      return data.models[data.meta.model][data.id]
    },
  })
}

const notesCacheQueryFilter = createQueryFilter([
  API.notes.queryKeys.root,
  MODEL_CACHE_KEY,
])

export const useUpdateNote = (
  opts?: ApiUseMutationOptions<typeof API.notes.updateNote>
) => {
  const queryClient = useQueryClient()

  return useUpdateNoteMutation({
    onError: (error) => {
      Sentry.captureException(error)
      showToast('error', 'Failed to update note')
    },
    onSuccess: (data, vars) => {
      MotionCache.upsert(queryClient, notesCacheQueryFilter, data)
    },
    ...opts,
  })
}
