import { NoteSolid, type SvgIconProps } from '@motion/icons'
import { type COLOR } from '@motion/shared/common'

import { forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

import { getColorHueValue, PaletteProvider } from '../../../palette'

type NotesColorIconProps = SvgIconProps & {
  color?: COLOR
}
export const NotesColorIcon = forwardRef<SVGSVGElement, NotesColorIconProps>(
  function NotesIcon({ color = 'gray', className, ...etc }, ref) {
    const classNames = twMerge(
      '!text-palette-highlight-default shrink-0',
      className
    )

    const colorHue = getColorHueValue(color)

    return (
      <PaletteProvider colorHue={colorHue}>
        <NoteSolid ref={ref} {...etc} className={classNames} />
      </PaletteProvider>
    )
  }
)
