import { ProjectCubeSolid } from '@motion/icons'
import { Button } from '@motion/ui/base'
import { ProjectPalette } from '@motion/ui/project'

import { useFlowTemplateModalUrl } from '~/areas/flows'

import { CardDescription, CardTitle, EmptyTableCard } from './styled'

type NewFlowTemplateCardProps = {
  workspaceId: string
}
export function NewFlowTemplateCard({ workspaceId }: NewFlowTemplateCardProps) {
  const buildFlowTemplateModalUrl = useFlowTemplateModalUrl()

  const newFlowTemplateUrl = buildFlowTemplateModalUrl({
    template: 'create',
    forWorkspace: workspaceId,
  })

  return (
    <EmptyTableCard>
      <ProjectPalette color='blue'>
        <div className='flex items-center rounded-full bg-palette-bg-light p-2'>
          <ProjectCubeSolid className='text-palette-highlight-default size-4' />
        </div>
      </ProjectPalette>
      <CardTitle>Create your first project template</CardTitle>
      <CardDescription>
        Project templates allow you to codify your SOPs into repeatable
        workflows. This ensures tasks are assigned and scheduled automatically,
        keeping all projects on time!
      </CardDescription>
      <Button size='small' url={newFlowTemplateUrl}>
        Create project template
      </Button>
    </EmptyTableCard>
  )
}
