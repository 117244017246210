import { colorOptions } from '@motion/ui/palette'
import { type ColorId } from '@motion/ui-logic'

import { DateTime } from 'luxon'

import { type AvailabilityQuestionType } from './components/Booking/components/Modals/AvailabilityQuestionEditorModal/AvailabilityQuestion/AvailabilityQuestion'
import { type MotionLocalStorageItems } from './localServices/storage/types'
import { type FirebaseTeam } from './services/teamsService'
import { type BookingQuestion } from './state/booking'
import { type StripeSubscription } from './state/userSlice'
import { type CalendarEventOld as CalendarEvent } from './types/calendarTypes'
import { type ScheduleCollection } from './types/settingsTypes'

export const ZOOM_LINK_REGEX =
  /(https?:\/\/([A-Za-z0-9-]+?\.)?zoom\.us(\/[A-Za-z0-9\-._~:/?#[\]@!$&'*+Z;=%]*)?)/g

export const reminderWarningChoices = ['15 sec', '1 min', '5 min', '10 min']
export const reminderDurationChoices = [
  '1 min',
  '3 min',
  '5 min',
  '10 min',
  '20 min',
]

export const quarterHourTimeChoices = [...Array(96).keys()].map((i) =>
  DateTime.now()
    .startOf('day')
    .plus({ minutes: i * 15 })
    .toFormat('h:mm a')
)

export const calendarColorIds: ColorId[][] = [
  [
    colorOptions.blue.colorId,
    colorOptions.emerald.colorId,
    colorOptions.violet.colorId,
    colorOptions.cyan.colorId,
    colorOptions.mint.colorId,
    colorOptions.red.colorId,
  ],
  [
    colorOptions.gray.colorId,
    colorOptions.yellow.colorId,
    colorOptions.green.colorId,
    colorOptions.lavender.colorId,
    colorOptions.rose.colorId,
    colorOptions.orange.colorId,
  ],
]

export const defaultAvailabilityTemplate = {
  account: '',

  authorCalendarId: '',

  authorEmail: '',

  // today, tomorrow, day after tomorrow, next week
  authorName: '',

  blockingTime: 0,

  // just for safety, can remove this field soon
  buffer: 15,

  conferenceType: 'meet',

  conflictCalendars: [] as any[],

  customScheduleTimezone: 'default',

  days: {
    Friday: [{ preferred: false, range: '9am-5pm' }],
    Monday: [{ preferred: false, range: '9am-5pm' }],
    Saturday: [] as { range: string; preferred: boolean }[],
    Sunday: [] as { range: string; preferred: boolean }[],
    Thursday: [{ preferred: false, range: '9am-5pm' }],
    Tuesday: [{ preferred: false, range: '9am-5pm' }],
    Wednesday: [{ preferred: false, range: '9am-5pm' }],
  },
  daysSpan: 14,
  durationChoices: [30],
  eventName: '',
  fragmentMode: 'fragmentLabel',
  hasFragment: true,
  hasReminderEmail: false,
  id: 'default',
  maxMeetings: 0,
  minimumDuration: 30,
  name: 'Booking page 1',
  // [{email, calendarId, title}]
  otherGuests: [] as any[],

  permanentLink: '',

  permanentLinkId: '',

  questions: [
    {
      required: false,
      text: "Anything else you'd like to share for our meeting?",
      type: 'text-multiple',
    } as AvailabilityQuestionType,
  ],
  reminderEmailBody:
    'Hello $Invitee first name$,\n\n' +
    'Looking forward to our meeting in $Time until meeting$ at $Meeting time$.\n\n' +
    "Here's the meeting link: $Meeting link$.\n\n" +
    'Talk soon!\n\n' +
    'Best,\n' +
    '$Host first name$',
  reminderEmailSubject: 'Our upcoming meeting',
  reminderEmailTime: 60,
  schedule: 'work',
  startsIn: 'tomorrow',
}

export const defaultBookingTemplate = {
  teamId: null,
  hostCalendarId: '',
  hostEmail: '',
  hostDisplayName: '',

  bufferMins: 15,
  blockingTimeMins: 0,
  conferenceType: 'NONE',

  conflictCalendars: [] as any[],

  scheduleId: 'work',

  daysSpan: 14,
  durationChoices: [30],
  externalEventName: '',
  hasReminderEmail: false,
  id: 'default',
  maxDailyMeetings: 0,
  name: 'Booking page 1',
  otherGuests: [] as any[],

  linkSlug: '',

  questions: [
    {
      required: false,
      text: "Anything else you'd like to share for our meeting?",
      type: 'TEXT_MULTIPLE_LINES',
      choices: [],
    } as Partial<BookingQuestion>,
  ],
  reminderEmailBody:
    'Hello $Invitee first name$,\n\n' +
    'Looking forward to our meeting in $Time until meeting$ at $Meeting time$.\n\n' +
    "Here's the meeting link: $Meeting link$.\n\n" +
    'Talk soon!\n\n' +
    'Best,\n' +
    '$Host first name$',
  reminderEmailSubject: 'Our upcoming meeting',
  reminderEmailPreBookingMins: 60,
  startsIn: 'tomorrow',
}

export const sampleMeetingTimes =
  '\u2022 Fri, Apr 30: 12pm - 12:30pm\n\u2022 Mon, May 3: 12:15pm - 12:45pm\n\u2022 Wed, May 5: 12:15pm - 12:45pm'
export const defaultAvailabilityMessageTemplate =
  "Would any of these time windows work for a $Duration$ meeting ($Timezone$)?\n$Meeting times$\nFeel free to use this booking page if that's easier (also contains more availabilities):\n$Booking link$"
export const linkDurationChoicesMin = [
  10, 15, 20, 25, 30, 45, 50, 60, 90, 120, 240, 480, 720,
]
export const bufferChoices = [
  '0 min',
  '5 min',
  '10 min',
  '15 min',
  '20 min',
  '30 min',
]

export const daysSpanChoices = [
  '1 day',
  '2 days',
  '3 days',
  '4 days',
  '5 days',
  '7 days',
  '14 days',
  '21 days',
  '28 days',
  '1 month',
  '2 months',
  '3 months',
  '6 months',
  '1 year',
]
export const startsInChoices = [
  'Today',
  'Tomorrow',
  'In 2 days',
  'Next week',
  'In 7 days',
  'In 14 days',
  'Next month',
  'In 30 days',
  'In 60 days',
]

export const reminderEmailVariables = [
  '$Invitee first name$',
  '$Time until meeting$',
  '$Meeting time$',
  '$Meeting link$',
  '$Host first name$',
]
export const availabilityMessageVariables = [
  '$Meeting times$',
  '$Booking link$',
  '$Timezone$',
  '$Duration$',
]

export const CALENDAR_LICENSE_KEY = '0064757283-fcs-1697056131'

export const defaultSchedules: ScheduleCollection = {
  anytime: {
    schedule: {
      Friday: [{ preferred: false, range: '12:00am-11:59pm' }],
      Monday: [{ preferred: false, range: '12:00am-11:59pm' }],
      Saturday: [{ preferred: false, range: '12:00am-11:59pm' }],
      Sunday: [{ preferred: false, range: '12:00am-11:59pm' }],
      Thursday: [{ preferred: false, range: '12:00am-11:59pm' }],
      Tuesday: [{ preferred: false, range: '12:00am-11:59pm' }],
      Wednesday: [{ preferred: false, range: '12:00am-11:59pm' }],
    },
    timezone: 'default',
    title: 'Anytime (24/7)',
  },
  day: {
    schedule: {
      Friday: [{ preferred: false, range: '9:00am-5:00pm' }],
      Monday: [{ preferred: false, range: '9:00am-5:00pm' }],
      Saturday: [],
      Sunday: [],
      Thursday: [{ preferred: false, range: '9:00am-5:00pm' }],
      Tuesday: [{ preferred: false, range: '9:00am-5:00pm' }],
      Wednesday: [{ preferred: false, range: '9:00am-5:00pm' }],
    },
    timezone: 'default',
    title: 'Day',
  },
  night: {
    schedule: {
      Friday: [{ preferred: false, range: '6:00pm-9:00pm' }],
      Monday: [{ preferred: false, range: '6:00pm-9:00pm' }],
      Saturday: [],
      Sunday: [],
      Thursday: [{ preferred: false, range: '6:00pm-9:00pm' }],
      Tuesday: [{ preferred: false, range: '6:00pm-9:00pm' }],
      Wednesday: [{ preferred: false, range: '6:00pm-9:00pm' }],
    },
    timezone: 'default',
    title: 'Night',
  },
  work: {
    schedule: {
      Friday: [{ preferred: false, range: '9:00am-5:00pm' }],
      Monday: [{ preferred: false, range: '9:00am-5:00pm' }],
      Saturday: [],
      Sunday: [],
      Thursday: [{ preferred: false, range: '9:00am-5:00pm' }],
      Tuesday: [{ preferred: false, range: '9:00am-5:00pm' }],
      Wednesday: [{ preferred: false, range: '9:00am-5:00pm' }],
    },
    timezone: 'default',
    title: 'Work hours',
  },
  personal: {
    schedule: {
      Friday: [{ preferred: false, range: '6:00pm-9:00pm' }],
      Thursday: [{ preferred: false, range: '6:00pm-9:00pm' }],
      Wednesday: [{ preferred: false, range: '6:00pm-9:00pm' }],
      Tuesday: [{ preferred: false, range: '6:00pm-9:00pm' }],
      Monday: [{ preferred: false, range: '6:00pm-9:00pm' }],
      Saturday: [{ preferred: false, range: '10:00am-9:00pm' }],
      Sunday: [{ preferred: false, range: '10:00am-9:00pm' }],
    },
    timezone: 'default',
    title: 'Personal hours',
  },
}

export const defaultSettings: Partial<MotionLocalStorageItems> = {
  $loaded: false,
  calendarEvents: [] as CalendarEvent[],

  calendarList: {}, // [kfs]

  // { groupId: [ holdEventId, ] }
  calendarReminder: {
    event: {},
    visible: false,
  },

  calendarStartDay: 'sunday',

  calendarTimezone: null as string | null,

  colorIdMap: {
    calendar: {},
    event: {},
  },

  contacts: [] as string[],

  currentCalendarEvents: [] as CalendarEvent[],

  defaultTimezone: DateTime.now().zoneName,

  googleAuth: {}, // [kfs]

  ignoreInviteTeammates: [] as string[],

  // {email: signature}
  includeMotionSignature: true,

  invitedTeams: [],

  // {id: {fields}}
  isFlexibleDev: false,

  lastActiveHourISO: null as any,

  newTabInstalled: false,

  newsEnabled: false,

  // needs to be sorted by increasing start time
  nextEvent: null as CalendarEvent | null,

  recurringTasks: {},

  schedules: defaultSchedules,

  sidebarOffset: 170,

  stripeSubscription: null as StripeSubscription | null,

  // { id, name, order }
  taskReminder: {
    event: {},
    visible: false,
  },

  // { id, source, target, privacy }
  tasks: {},

  team: {} as FirebaseTeam,

  webAppInited: false,

  widgetSitesDisabled: [] as string[],

  widgetSnooze: null as boolean | null,

  widgetWarningDismissed: false,

  windowWorkspaceTracker: {},

  zoomNeedsRefresh: false,
}
