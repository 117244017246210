import { DragSolid } from '@motion/icons'
import { type DashboardViewCellSchema } from '@motion/rpc-types'
import { useModalApi } from '@motion/web-common/modals'

import { ErrorBoundary } from '~/global/components'
import {
  forwardRef,
  type HtmlHTMLAttributes,
  type ReactNode,
  useCallback,
} from 'react'
import { twMerge } from 'tailwind-merge'

type GridTileProps = {
  children?: ReactNode
  renderContent: () => ReactNode
  renderControls?: () => ReactNode
  cell: DashboardViewCellSchema
} & HtmlHTMLAttributes<HTMLDivElement>

export const GridTile = forwardRef<HTMLDivElement, GridTileProps>(
  ({ cell, children, renderContent, renderControls, ...props }, ref) => {
    const modalApi = useModalApi()

    const handleExpand = useCallback(
      () => modalApi.open('expanded-chart-modal', { cell }),
      [cell, modalApi]
    )

    return (
      <div
        ref={ref}
        {...props}
        className={twMerge(
          'group/tile',
          'grid grid-rows-[auto,minmax(0,1fr)] !bg-semantic-neutral-bg-default p-3 rounded-lg shadow-[0_0px_10px_2px_rgba(0,0,0,0.04)]',
          props.className
        )}
      >
        <div className='grid grid-cols-[auto,1fr,auto] items-center gap-1 min-w-0'>
          <div className='drag-handle text-semantic-neutral-icon-default cursor-grab'>
            <DragSolid className='size-4' />
          </div>

          <button onClick={handleExpand}>
            <h2 className='font-bold flex-1 line-clamp-2 text-left'>
              {cell.title}
            </h2>
          </button>

          {renderControls != null && (
            <div className='opacity-0 group-hover/tile:opacity-100 transition-opacity duration-200 '>
              {renderControls()}
            </div>
          )}
        </div>

        <div className='grid [&>*]:min-w-0'>
          <ErrorBoundary errorMessage='Error rendering the tile content'>
            {renderContent()}
          </ErrorBoundary>
        </div>

        {children}
      </div>
    )
  }
)

GridTile.displayName = 'GridTile'
