import { type TaskWithRelations } from '~/global/proxies'
import { DateTime } from 'luxon'

export const getTaskStageProjectDue = (task: TaskWithRelations) => {
  let newDeadline: string | undefined = undefined

  if (task.project?.dueDate) {
    newDeadline = DateTime.fromISO(task.project.dueDate).endOf('day').toISO()
  }

  if (task.type === 'NORMAL' && task.stageDefinitionId) {
    const stage = task.project?.stages.find(
      (stage) => stage.stageDefinitionId === task.stageDefinitionId
    )

    if (stage?.dueDate) {
      newDeadline = DateTime.fromISO(stage.dueDate).endOf('day').toISO()
    }
  }

  return newDeadline
}
