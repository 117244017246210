import { SearchableDropdown, showToast } from '@motion/ui/base'

import { useQueryClient } from '@tanstack/react-query'
import { ConnectedUserLabel } from '~/global/components/labels'
import {
  useCreateWorkspaceMember,
  useWorkspaceActiveMembers,
  useWorkspaceById,
} from '~/global/hooks'
import { useTeamMembers } from '~/global/hooks/team'
import { type ReactNode } from 'react'

export const TeamMemberDropdown = ({
  children,
  onChange,
  workspaceId,
}: {
  children: ReactNode
  onChange?: (userId: string, name: string) => void
  workspaceId: string
}) => {
  const client = useQueryClient()
  const teamMembers = useTeamMembers()

  const workspace = useWorkspaceById(workspaceId)
  const currentMembers = useWorkspaceActiveMembers(workspaceId)

  const { createWorkspaceMember } = useCreateWorkspaceMember()

  if (!workspace) {
    return null
  }

  return (
    <SearchableDropdown
      computeDisabled={(item) =>
        currentMembers.some((member) => member.user.id === item.userId)
      }
      computeSearchValue={(item) => item.user.name}
      items={teamMembers}
      onChange={async (item) => {
        await createWorkspaceMember({
          workspaceId,
          userId: item.userId,
        })

        const newMemberUser = teamMembers.find((member) => {
          return member.userId === item.userId
        })

        // Refetch all workspaces to update the workspace member list
        void client.invalidateQueries({ queryKey: ['v2', 'workspaces'] })

        showToast('success', 'Member added successfully')
        if (newMemberUser) {
          onChange?.(item.userId, newMemberUser.user.name)
        }
      }}
      renderItem={(item) => <ConnectedUserLabel userId={item.userId} />}
      searchable
      searchPlaceholder='Choose member'
    >
      {children}
    </SearchableDropdown>
  )
}
