import { ArrowLeftSolid, ArrowRightSolid, XSolid } from '@motion/icons'
import { useScrollContainer } from '@motion/react-core/hooks'
import { type CreateProjectDefinitionSchema } from '@motion/rpc-types'
import {
  IconButton,
  LoadingSpinner,
  showToast,
  UnstyledModal,
  useShortcut,
} from '@motion/ui/base'
import {
  recordAnalyticsEvent,
  useOnMountAnalyticsEvent,
} from '@motion/web-base/analytics'
import { HasExperiment } from '@motion/web-common/flags'
import { useModalTitle } from '@motion/web-common/html'

import { type ModalTriggerComponentProps } from '~/areas/modals'
import { ModalErrorState } from '~/areas/task-project/components'
import { useRouteConfirmationPromptBeforeLeaving } from '~/global/navigation'
import { type FormEvent, memo, useCallback } from 'react'
import { useFieldArray } from 'react-hook-form'

import {
  ArrowsHintContainer,
  FlowsTemplateFooter,
  FormShell,
  GridShell,
  Sidebar,
  SidebarDescription,
  SidebarHeader,
  SidebarSection,
  SidebarSectionFields,
  SidebarTitle,
  StageColumns,
  StagesContent,
  StagesTitle,
  StagesTitleContainer,
} from './components'
import { StageColumnsDnd } from './components/stages/dnd/components/stage-columns-dnd'
import { FlowsModalStateProvider, useFlowsModalState } from './contexts'
import {
  ControlledAssigneeField,
  ControlledAttachmentsField,
  ControlledColorField,
  ControlledDescriptionField,
  ControlledFolderField,
  ControlledLabelsField,
  ControlledNameField,
  ControlledPriorityField,
  ControlledRoleFields,
  ControlledTextVariableFields,
} from './fields'
import { FlowTemplateForm } from './flow-template-form'
import {
  useCloseFlowTemplateModal,
  useFlowTemplateForm,
  useFlowTemplateModalUrlParams,
  useSubmitTemplateForm,
} from './hooks'
import { getFlowTemplateFormErrorMessage } from './utils'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'flows-template-modal': {
      mode?: 'ai-generation'
      initialProjectDefinition?: CreateProjectDefinitionSchema
    }
  }
}

type ConnectedUrlFlowTemplateModalProps = {
  open: boolean
}

export function ConnectedUrlFlowTemplateModal({
  open,
}: ConnectedUrlFlowTemplateModalProps) {
  const closeModal = useCloseFlowTemplateModal()

  if (!open) {
    return null
  }

  return <ConnectedFlowTemplateModal close={closeModal} />
}

type ConnectedFlowTemplateModalProps =
  ModalTriggerComponentProps<'flows-template-modal'>

export function ConnectedFlowTemplateModal({
  close,
  initialProjectDefinition,
  mode,
}: ConnectedFlowTemplateModalProps) {
  return (
    <UnstyledModal
      data-testid='flow-template-modal'
      type='page'
      visible
      onClose={close}
      withAnimation
      disableEscapeKey
      overlayClassName='bg-modal-overlay'
      modalClassName='w-[90%] h-[90%] overflow-hidden'
    >
      <FlowTemplateForm initialProjectDefinition={initialProjectDefinition}>
        <FlowsModalStateProvider mode={mode}>
          <div className='w-full h-full flex gap-1 items-start'>
            <FlowModalContent close={close} />

            <IconButton
              icon={XSolid}
              sentiment='onDark'
              size='small'
              variant='muted'
              onClick={close}
            />
          </div>
        </FlowsModalStateProvider>
      </FlowTemplateForm>
    </UnstyledModal>
  )
}

type FlowModalContentProps = {
  close: () => void
}
const FlowModalContent = memo(function FlowModalContent({
  close,
}: FlowModalContentProps) {
  const { form } = useFlowTemplateForm()
  const submitForm = useSubmitTemplateForm()
  const modalState = useFlowsModalState()
  const { forDuplicate, fromPresetId } = useFlowTemplateModalUrlParams()
  const {
    watch,
    formState: { isDirty, isSubmitting },
  } = form

  const projectId = watch('id')
  const projectName = watch('name')
  const isLoading = watch('isLoading')
  const hasError = watch('hasError')

  const hasDirtyFormFields = isDirty && !isSubmitting

  useModalTitle(projectId ? projectName : 'New project')
  useRouteConfirmationPromptBeforeLeaving({
    when: hasDirtyFormFields,
  })

  useOnMountAnalyticsEvent('PROJECT_MANAGEMENT_VIEW_FLOW_TEMPLATE', {
    enabled: projectId != null,
  })
  useShortcut('mod+s', () => {
    // Task name RTE only processes HTML and updates state on blur
    // Required to catch any validation errors
    const activeElement = document.activeElement as HTMLInputElement
    activeElement?.blur?.()
    onSubmit()
  })

  const stages = watch('stages')
  const doAllStagesHaveTasks = stages.every((stage) => stage.tasks.length > 0)

  const getIsSubmitDisabled = useCallback((): boolean => {
    if (
      !isDirty &&
      !fromPresetId &&
      !forDuplicate &&
      modalState.mode !== 'ai-generation'
    )
      return true

    if (!doAllStagesHaveTasks) return true

    return false
  }, [
    isDirty,
    fromPresetId,
    forDuplicate,
    modalState.mode,
    doAllStagesHaveTasks,
  ])

  const isSubmitDisabled = getIsSubmitDisabled()

  const onSubmit = async (e?: FormEvent<HTMLFormElement>) => {
    if (isSubmitDisabled) return

    await form.handleSubmit(submitForm, (validationErrors) => {
      const errors = Object.values(validationErrors).flat().filter(Boolean)
      if (errors.length < 1) return

      const firstError = errors[0]

      const message = getFlowTemplateFormErrorMessage(firstError)

      if (!message) return

      recordAnalyticsEvent('FLOW_TEMPLATE_MODAL_SUBMIT_ERROR', {
        message,
      })
      showToast('error', message)
    })(e)
  }

  const { scrollContainerRef, getListeners } =
    useScrollContainer<HTMLDivElement>()

  const {
    form: { control },
  } = useFlowTemplateForm()

  const fieldArray = useFieldArray({
    control,
    name: 'stages',
  })

  return (
    <FormShell onSubmit={onSubmit}>
      <GridShell>
        {hasError ? (
          <ModalErrorState close={close} className='col-span-2 row-span-4'>
            This project workflow template doesn&apos;t exist
          </ModalErrorState>
        ) : isLoading ? (
          <div className='col-span-2 row-span-4 w-full h-full grid place-items-center'>
            <LoadingSpinner />
          </div>
        ) : (
          <>
            <Sidebar>
              <SidebarSection>
                <SidebarHeader>
                  <SidebarTitle>Project Defaults</SidebarTitle>
                </SidebarHeader>

                <SidebarSectionFields>
                  <ControlledNameField />
                  <ControlledColorField />
                  <HasExperiment name='flows-folders'>
                    <ControlledFolderField />
                  </HasExperiment>
                  <ControlledAssigneeField />
                  <ControlledLabelsField />
                  <ControlledPriorityField />
                  <ControlledDescriptionField />
                  <ControlledAttachmentsField />
                </SidebarSectionFields>
              </SidebarSection>

              <SidebarSection>
                <SidebarHeader>
                  <SidebarTitle>Roles</SidebarTitle>

                  <SidebarDescription>
                    Roles are placeholders that can be assigned to tasks inside
                    project workflow templates. When using the template, you’ll
                    be able to choose an assignee for each role.
                  </SidebarDescription>

                  <SidebarSectionFields>
                    <ControlledRoleFields />
                  </SidebarSectionFields>
                </SidebarHeader>

                <SidebarSectionFields />
              </SidebarSection>

              <SidebarSection>
                <SidebarHeader>
                  <SidebarTitle>Text variables</SidebarTitle>

                  <SidebarDescription>
                    Text variables enable you to include specific information
                    into task names and descriptions.
                  </SidebarDescription>
                </SidebarHeader>

                <SidebarSectionFields>
                  <ControlledTextVariableFields />
                </SidebarSectionFields>
              </SidebarSection>
            </Sidebar>

            <StagesContent>
              <StagesTitleContainer>
                <StagesTitle>Stages</StagesTitle>

                <ArrowsHintContainer>
                  Hold shift while scrolling to scroll horizontally
                  <div className='flex gap-1.5'>
                    <IconButton
                      icon={ArrowLeftSolid}
                      size='small'
                      variant='outlined'
                      sentiment='neutral'
                      {...getListeners('left')}
                    />
                    <IconButton
                      icon={ArrowRightSolid}
                      size='small'
                      variant='outlined'
                      sentiment='neutral'
                      {...getListeners('right')}
                    />
                  </div>
                </ArrowsHintContainer>
              </StagesTitleContainer>

              <StageColumnsDnd fieldArray={fieldArray}>
                <StageColumns
                  scrollColumnsRef={scrollContainerRef}
                  fieldArray={fieldArray}
                />
              </StageColumnsDnd>
            </StagesContent>

            <FlowsTemplateFooter
              isSubmitDisabled={isSubmitDisabled}
              isSubmitting={isSubmitting}
              close={close}
            />
          </>
        )}
      </GridShell>
    </FormShell>
  )
})
