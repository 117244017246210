import {
  API,
  useQueryOptionsFactory,
  type UseQueryOptionsWithQueryFn,
} from '@motion/rpc'
import { isNoneId } from '@motion/shared/identifiers'
import {
  type AppDataContext,
  createQuery,
  getMatchingProjects,
  toDataFilter,
} from '@motion/ui-logic/pm/data'
import {
  groupIntoWithHeader,
  type GroupWithHeader,
  sumBy,
} from '@motion/utils/array'
import { toMerged } from '@motion/utils/core'
import { parseDate } from '@motion/utils/dates'
import { useAuthenticatedUser } from '@motion/web-common/auth'
import {
  type ChartQueryAggregateSchema,
  type ChartQueryRequestSchema,
  type ChartQueryResponseSchema,
  type DashboardViewAggregate,
  type DashboardViewChartSchema,
  type DashboardViewGroupBySchema,
  type GroupableFieldSchema,
  type ViewDefinitionFiltersSchema,
} from '@motion/zod/client'

import { usePageData } from '~/areas/project-management/pages/pm-v3/routes'
import { type PageParamsOverrides } from '~/areas/project-management/pages/pm-v3/routes/types'
import { fromViewDefinitionFiltersToFilterState } from '~/areas/project-management/pages/pm-v3/views/utils'
import { type LookupFn, useLookup } from '~/global/cache'
import { useAppDataContext } from '~/global/contexts'
import { createProjectProxy, type ProjectWithRelations } from '~/global/proxies'
import { type DurationLike } from 'luxon'

// TODO: Add tests for these functions
export function mapCellSchemaToChartQuery(
  ctx: AppDataContext,
  chart: DashboardViewChartSchema,
  overrides: PageParamsOverrides
): ChartQueryRequestSchema {
  const filters = mapFilters(ctx, chart.filters, overrides)
  return {
    $version: 1,
    source: chart.item as 'tasks',
    aggregate: mapAggregate(chart.aggregate),
    groupBy: mapGroupBy('groupBy' in chart ? chart.groupBy : []),
    filters: filters,
  }
}

export function useCreateChartQueryOptions(
  chart: DashboardViewChartSchema
): UseQueryOptionsWithQueryFn<typeof API.charts.getQuery> {
  const lookup = useLookup()
  const { uid: userId } = useAuthenticatedUser()

  const pageData = usePageData()

  const createTaskChartQuery = useQueryOptionsFactory(API.charts.getQuery)
  const ctx = useAppDataContext()

  if (chart.item === 'tasks') {
    const taskQueryArgs = mapCellSchemaToChartQuery(
      ctx,
      chart,
      pageData.overrides
    )
    return createTaskChartQuery(taskQueryArgs)
  }

  const projectQueryOptions: UseQueryOptionsWithQueryFn<
    typeof API.charts.getQuery
  > = {
    queryKey: ['charts', 'project', chart as any],
    // @ts-expect-error - wonky types
    queryFn: createProjectQuery(ctx, chart, lookup, pageData.overrides, userId),
  }

  return projectQueryOptions
}

function mapAggregate(
  aggregate: DashboardViewAggregate
): ChartQueryAggregateSchema {
  switch (aggregate.type) {
    case 'count':
      return { type: 'count' }

    case 'sum':
      return {
        type: 'sum',
        field: aggregate.field,
      }
  }
}

function mapGroupBy(
  groupBy: DashboardViewGroupBySchema[]
): GroupableFieldSchema[] {
  return groupBy.map(({ sort, ...groupBy }) => groupBy)
}

function mapFilters(
  ctx: AppDataContext,
  filters: ViewDefinitionFiltersSchema,
  overrides: PageParamsOverrides
) {
  const filterState = fromViewDefinitionFiltersToFilterState('tasks', filters)
  const dataFilter = toMerged(toDataFilter(filterState), overrides)

  const query = createQuery(ctx, dataFilter, undefined, {
    dontInferTaskType: true,
  })

  return query?.filters ?? []
}

// TODO: Handle custom fields
function createProjectQuery(
  ctx: AppDataContext,
  chart: DashboardViewChartSchema,
  lookup: LookupFn,
  overrides: PageParamsOverrides,
  userId: string
) {
  return async (): Promise<ChartQueryResponseSchema | null> => {
    if (chart.item !== 'projects') return null

    const mergedFilters = toMerged(chart.filters, overrides)

    const projects = getMatchingProjects(
      ctx,
      fromViewDefinitionFiltersToFilterState('projects', mergedFilters),
      undefined,
      userId
    )
      .filter((p) => !isNoneId(p.id))
      .map((project) =>
        createProjectProxy(project, lookup)
      ) as ProjectWithRelations[]

    const groups = chart.type === 'number' ? [{ field: 'id' }] : chart.groupBy

    const grouped = groupIntoWithHeader(
      projects,
      (item) => getGroupHash(ctx, item, groups),
      (item) => getGroupValues(ctx, item, groups)
    )

    const populated = populateMissingGroups(ctx, projects, grouped, groups)

    const transformed =
      chart.aggregate.type === 'count'
        ? populated.map((g) => ({ ...g.header, value: g.items.length }))
        : populated.map((g) => ({
            ...g.header,
            value: sumBy(g.items, (item) =>
              // @ts-expect-error - the aggregate type is already narrowed
              getRowValue(item, chart.aggregate.field)
            ),
          }))

    return {
      meta: {
        groupBy: chart.type === 'number' ? [] : chart.groupBy,
        value: chart.aggregate.type,
      },
      data: transformed,
    }
  }
}

function populateMissingGroups(
  ctx: AppDataContext,
  rows: ProjectWithRelations[],
  grouped: GroupWithHeader<ProjectWithRelations, string, object>[],
  groupBy: GroupableFieldSchema[]
) {
  const dateGroup = groupBy.find((x) => 'by' in x)
  if (dateGroup == null) return grouped

  const range = rows.reduce(
    (acc, cur) => {
      const rawValue = getRowValue(cur, dateGroup.field)
      if (rawValue == null) return acc

      const value = Array.isArray(rawValue)
        ? parseDate(rawValue[0]).toMillis()
        : parseDate(rawValue).toMillis()

      if (value < acc.min) {
        acc.min = value
      }
      if (value > acc.max) {
        acc.max = value
      }

      return acc
    },
    { min: Number.MAX_SAFE_INTEGER, max: Number.MIN_SAFE_INTEGER }
  )

  if (
    range.min === Number.MAX_SAFE_INTEGER ||
    range.max === Number.MIN_SAFE_INTEGER
  ) {
    return grouped
  }

  const currentDateBuckets = new Set<string>()
  for (const row of rows) {
    currentDateBuckets.add(getGroupValue(ctx, row, dateGroup) as string)
  }
  const nonDateGroup = groupBy.find((x) => !('by' in x))
  const nonDateValues = new Set<string>()
  if (nonDateGroup != null) {
    rows.forEach((row) => {
      nonDateValues.add(String(getRowValue(row, nonDateGroup.field)))
    })
  }

  let current = parseDate(toGroupValue(ctx, range.min, dateGroup) as string)
  const lastBucket = parseDate(
    toGroupValue(ctx, range.max, dateGroup) as string
  )
  const step = getDateGroupStepBy(ctx, dateGroup)
  while (current <= lastBucket) {
    const value = current.toISODate()
    if (!currentDateBuckets.has(value)) {
      if (nonDateGroup == null) {
        grouped.push({
          key: `${value}`,
          header: {
            [dateGroup.field]: value,
          },
          items: [],
        })
      } else {
        Array.from(nonDateValues).forEach((v) => {
          grouped.push({
            key: `${v}|${value}`,
            header: {
              [dateGroup.field]: value,
              [nonDateGroup.field]: v,
            },
            items: [],
          })
        })
      }
    }
    current = current.plus(step)
  }
  return grouped
}

function getGroupValues(
  ctx: AppDataContext,
  row: ProjectWithRelations,
  groups: GroupableFieldSchema[]
) {
  return groups.reduce((acc, cur) => {
    // @ts-expect-error - dynamic typing
    acc[cur.field] = getGroupValue(ctx, row, cur)
    return acc
  }, {})
}

function getGroupHash(
  ctx: AppDataContext,
  row: ProjectWithRelations,
  groups: GroupableFieldSchema[]
) {
  return groups.map((group) => getGroupValue(ctx, row, group)).join('|')
}

function getGroupValue(
  ctx: AppDataContext,
  row: ProjectWithRelations,
  group: GroupableFieldSchema
) {
  const value = getRowValue(row, group.field)
  if (value == null) return null

  return toGroupValue(ctx, value, group)
}

function toGroupValue(
  ctx: AppDataContext,
  value: unknown,
  group: GroupableFieldSchema
) {
  if (group.by) {
    const asDate = parseDate(value as any)

    return asDate.setZone('local').startOf(group.by).toISODate()
  }
  return value
}

function getDateGroupStepBy(
  ctx: AppDataContext,
  group: GroupableFieldSchema
): DurationLike {
  switch (group.by) {
    case 'day':
      return { days: 1 }
    case 'week':
      return { weeks: 1 }
    case 'month':
      return { months: 1 }
    case 'quarter':
      return { months: 3 }
    case 'year':
      return { years: 1 }
    default: {
      throw new Error(`Can not group on '${group.by}'`)
    }
  }
}

function getRowValue(row: ProjectWithRelations, name: string) {
  const raw = getProjectValue(row, name)
  if (raw == null) return null
  return raw
}

const getProjectValue = (project: ProjectWithRelations, field: string) => {
  if (field in project) {
    return project[field as keyof ProjectWithRelations] as
      | string
      | number
      | null
  }

  if (field in project.customFieldValues || field.includes('/')) {
    const name = field.includes('/') ? field.split('/')[1] : field
    return project.customFieldValues[name]?.value ?? null
  }

  if (field === 'label.name') {
    return project.labels[0]?.name ?? null
  }

  if (field === 'status.name') {
    return project.status.name
  }

  return null
}
