import { PlusSolid } from '@motion/icons'
import {
  ButtonGroup,
  GradientButton,
  Shortcut,
  SHORTCUT_DELIMITER,
  Tooltip,
} from '@motion/ui/base'
import { addTestId } from '@motion/ui/helpers'
import { templateStr } from '@motion/ui-logic'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { getWindowData } from '@motion/web-base/env'
import { useModalApi } from '@motion/web-common/modals'

import { useProjectModalUrl, useTaskModalUrl } from '~/global/navigation'
import { selectDesktopSettings } from '~/state/desktopSlice'
import { useAppSelector } from '~/state/hooks'
import { parseShortcutKey } from '~/utils/desktop'
import { useMemo } from 'react'

const DEFAULT_SHORTCUT = 'alt+space'

export const AddTaskProjectButtons = () => {
  const buildTaskModalUrl = useTaskModalUrl()
  const buildProjectModalUrl = useProjectModalUrl()
  const { isElectron } = getWindowData()
  const desktopSettings = useAppSelector(selectDesktopSettings)
  const modalApi = useModalApi()

  const keysToUse = useMemo(() => {
    const shouldUseDesktopShortcut =
      isElectron && desktopSettings?.shortcuts?.addTask

    if (!shouldUseDesktopShortcut) return DEFAULT_SHORTCUT

    return desktopSettings.shortcuts.addTask
      .split(SHORTCUT_DELIMITER)
      .map(parseShortcutKey)
      .join(' ')
  }, [isElectron, desktopSettings])

  return (
    <div className='w-full px-3' {...addTestId('AddTaskProjectButtons')}>
      <ButtonGroup nowrap stretch size='small'>
        <Tooltip asChild renderContent={() => 'Click to create an event'}>
          <GradientButton
            size='small'
            sentiment='neutral'
            fullWidth
            onClick={() => {
              recordAnalyticsEvent('PROJECT_MANAGEMENT_CLICK_PRIMARY_ADD', {
                type: 'event',
              })

              modalApi.open('event-modal', {})
            }}
          >
            {/* HACK Override to make all of the buttons fit. */}
            <PlusSolid className='!size-3' />
            Event
          </GradientButton>
        </Tooltip>
        <Tooltip asChild renderContent={() => 'Click to create a project'}>
          <GradientButton
            size='small'
            sentiment='neutral'
            fullWidth
            url={buildProjectModalUrl()}
            onClick={() => {
              recordAnalyticsEvent('PROJECT_MANAGEMENT_CLICK_PRIMARY_ADD', {
                type: 'project',
              })
            }}
          >
            {/* HACK Override to make all of the buttons fit. */}
            <PlusSolid className='!size-3' />
            Project
          </GradientButton>
        </Tooltip>

        <Tooltip
          asChild
          renderContent={() =>
            templateStr('Click to create a task, or press {{keys}}', {
              keys: <Shortcut key={keysToUse} shortcut={keysToUse} />,
            })
          }
        >
          <GradientButton
            size='small'
            sentiment='primary'
            fullWidth
            url={buildTaskModalUrl()}
            onClick={() => {
              recordAnalyticsEvent('PROJECT_MANAGEMENT_CLICK_PRIMARY_ADD', {
                type: 'task',
              })
            }}
          >
            {/* HACK Override to make all of the buttons fit. */}
            <PlusSolid className='!size-3' />
            Task
          </GradientButton>
        </Tooltip>
      </ButtonGroup>
    </div>
  )
}
