import {
  formatDateTime,
  getAutoscheduleInfo,
  type ScheduleInfo,
  templateStr,
} from '@motion/ui-logic'
import { isTaskSchema } from '@motion/ui-logic/pm/task'
import { useHasTreatment } from '@motion/web-common/flags'
import type {
  NormalTaskSchema,
  RecurringTaskSchema,
  TaskSchema,
} from '@motion/zod/client'

import {
  composeGhostAutoScheduleTooltipMessage,
  type GhostAutoScheduleTooltipVars,
} from '~/areas/flows'
import { useAutoScheduleRange } from '~/areas/project-management/hooks/experiments'
import { useProject, useProjectDefinition } from '~/global/hooks'
import { type ReactNode } from 'react'

import { useTaskOverrideTooltip } from './use-task-override-tooltip'

import { PastDueBlockerChainTooltip } from '../../components/tooltips'

export function useAutoScheduleTooltip(
  scheduleDateInfo: ScheduleInfo,
  task: TaskSchema | RecurringTaskSchema | undefined
): ReactNode {
  const { isUnvisitedStage, projectId, stageDefinitionId } =
    task?.type === 'NORMAL' ? task : ({} as NormalTaskSchema)

  const project = useProject(projectId)
  const projectDefinition = useProjectDefinition(
    project?.projectDefinitionId ?? ''
  )

  const taskStage = projectDefinition?.stages.find(
    (s) => s.id === stageDefinitionId
  )

  const baseTooltipMessage = useBaseAutoScheduleTooltipMessage(
    scheduleDateInfo,
    task
  )

  if (isUnvisitedStage) {
    const ghostTooltipVars =
      taskStage != null
        ? ({
            taskStage,
          } satisfies GhostAutoScheduleTooltipVars)
        : undefined

    return composeGhostAutoScheduleTooltipMessage(
      baseTooltipMessage,
      isUnvisitedStage,
      ghostTooltipVars
    )
  }

  if (task?.type === 'NORMAL' && ['pastDue'].includes(scheduleDateInfo.type)) {
    return composePastDueAutoScheduleTooltipMessage(baseTooltipMessage, task)
  }

  return baseTooltipMessage
}

function useBaseAutoScheduleTooltipMessage(
  scheduleDateInfo: ScheduleInfo,
  task: TaskSchema | RecurringTaskSchema | undefined
): ReactNode {
  const autoScheduleRange = useAutoScheduleRange()
  const shouldUseDateTime = useHasTreatment('include-time-in-autoschedule-info')

  const taskOverrideTooltip = useTaskOverrideTooltip(
    task?.type === 'NORMAL' ? task : undefined
  )
  if (taskOverrideTooltip) {
    return taskOverrideTooltip
  }

  const isAutoScheduled = task?.isAutoScheduled ?? false
  const isCompleted = isTaskSchema(task) && task.completedTime != null

  const formattedDueDate =
    isTaskSchema(task) && task.dueDate != null
      ? formatDateTime(task.dueDate)
      : ''

  const { formattedScheduledDate, formattedScheduledDateTime } =
    getAutoscheduleInfo(task, isAutoScheduled, isCompleted)

  const baseTooltipMessage = scheduleDateInfo.tooltip
    ? templateStr(scheduleDateInfo.tooltip, {
        date: shouldUseDateTime
          ? formattedScheduledDateTime
          : formattedScheduledDate,
        datetime: formattedScheduledDateTime,
        deadline: formattedDueDate,
        autoScheduleRange,
      })
    : undefined

  return baseTooltipMessage
}

function composePastDueAutoScheduleTooltipMessage(
  baseTooltipMessage: ReactNode,
  task: NormalTaskSchema
): ReactNode {
  return (
    <div className='flex flex-col gap-2'>
      {baseTooltipMessage}
      <PastDueBlockerChainTooltip task={task} />
    </div>
  )
}
