import { CalendarSolid, PlusSolid } from '@motion/icons'
import {
  Button,
  PopoverButton,
  PopoverTrigger,
  SearchableList,
} from '@motion/ui/base'
import { CalendarTitle } from '@motion/ui/calendar'
import { getAccountOptions, parseColorId } from '@motion/ui-logic'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useEditableCalendars } from '~/areas/calendar/hooks'
import { ColorItemBadge } from '~/global/components/badges'
import { selectEmailAccountsMap } from '~/state/email-accounts/email-accounts-slice'
import { useAppSelector } from '~/state/hooks'
import { addAccount } from '~/utils/auth'
import { useMemo } from 'react'
import { useController } from 'react-hook-form'

import { PopoverButtonIcon } from '../components'
import { useEventModalState } from '../contexts'
import { useEventForm } from '../hooks'

export const ControlledEventHostField = () => {
  const { isLoading, isReadOnly, hostCalendar } = useEventModalState()
  const { form, disabledFields } = useEventForm()
  const { control } = form

  const emailAccountsMap = useAppSelector(selectEmailAccountsMap)
  const editableCalendars = useEditableCalendars()

  const accountOptions = useMemo(
    () => getAccountOptions(editableCalendars, emailAccountsMap),
    [editableCalendars, emailAccountsMap]
  )

  const { field: emailField } = useController({
    name: 'email',
    control,
  })
  const { field: calendarIdField } = useController({
    name: 'calendarId',
    control,
  })

  if (isLoading || hostCalendar == null) return null

  const onChange = async (email: string, calendarId: string) => {
    emailField.onChange(email)
    calendarIdField.onChange(calendarId)
  }

  const onAddEmailAccount = () => {
    addAccount()
    void recordAnalyticsEvent('CALENDAR_ADD_ACCOUNT_CLICK')
  }

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <>
          <SearchableList
            inputProps={{ placeholder: 'Choose calendar...' }}
            items={accountOptions}
            renderItem={(item) => (
              <div className='flex items-center gap-2 min-w-0 max-w-[368px]'>
                <ColorItemBadge colorId={parseColorId(item.colorId)} />
                <CalendarTitle calendar={item} email={item.email} />
              </div>
            )}
            computeKey={(item) => item.title + item.email}
            computeSelected={(item) => item.id === hostCalendar.id}
            onSelect={(item) => {
              onChange(item.email, item.calendarId)
              close()
            }}
          />
          <div className='flex flex-col border-t border-dropdown-border mt-1'>
            <Button
              alignment='left'
              onClick={() => {
                close()
                onAddEmailAccount()
              }}
              sentiment='neutral'
              variant='muted'
            >
              <PlusSolid />
              Add calendar
            </Button>
          </div>
        </>
      )}
    >
      <PopoverButton
        aria-label='Event host calendar'
        disabled={disabledFields.has('email')}
        readOnly={isReadOnly}
        size='small'
      >
        <div className='flex items-center gap-2 overflow-hidden'>
          <PopoverButtonIcon icon={CalendarSolid} />
          <CalendarTitle
            calendar={hostCalendar}
            size='xsmall'
            emailAccountsMap={emailAccountsMap}
          />
        </div>
      </PopoverButton>
    </PopoverTrigger>
  )
}
