import { Button } from '@motion/ui/base'
import { byValue, orderedAtEnd } from '@motion/utils/array'
import { type MeetingInsightsSchema } from '@motion/zod/client'

import { useMemo } from 'react'

import { ActionItem } from './action-item'
import { SectionCollapsible } from './section-collapsible'
import { UserRating } from './user-rating'

import { CreateNewActionItemProvider } from '../context/create-new-action-item-context-provider'
import { useCreateNewActionItemContext } from '../hooks/use-create-new-action-item-context'

type ActionItemSectionProps = {
  meetingInsights: MeetingInsightsSchema
}

export function ActionItemsSection({
  meetingInsights,
}: ActionItemSectionProps) {
  return (
    <CreateNewActionItemProvider>
      <ActionItemSectionBody meetingInsights={meetingInsights} />
    </CreateNewActionItemProvider>
  )
}

function ActionItemSectionBody({ meetingInsights }: ActionItemSectionProps) {
  const { actionItem: actionItemToCreate, createNewActionItem } =
    useCreateNewActionItemContext()
  const sortedActionItems = useMemo(
    () =>
      [...(meetingInsights?.actionItems ?? [])].sort(
        byValue(
          (item) =>
            item.taskId != null
              ? 'accepted'
              : item.hasBeenTriaged
                ? 'rejected'
                : 'pending',
          orderedAtEnd(['pending', 'accepted', 'rejected'])
        )
      ),
    [meetingInsights?.actionItems]
  )

  return (
    <SectionCollapsible title='Action Items'>
      <div className='flex flex-col gap-6'>
        {sortedActionItems.map((actionItem) => (
          <ActionItem key={actionItem.id} actionItem={actionItem} />
        ))}
        {actionItemToCreate && (
          <ActionItem actionItem={actionItemToCreate} createMode />
        )}
      </div>
      <div className='self-start'>
        {actionItemToCreate == null && (
          <Button
            sentiment='neutral'
            variant='outlined'
            size='small'
            onClick={() => createNewActionItem(meetingInsights)}
          >
            Add action item
          </Button>
        )}
      </div>
      <div className='self-end'>
        <UserRating
          label='How accurate were these action items?'
          section='actionItems'
          meetingInsightsId={meetingInsights.id}
        />
      </div>
    </SectionCollapsible>
  )
}
