import { AttachmentOutline } from '@motion/icons'
import { priorityLevels } from '@motion/rpc/types'
import { isFlowVariableKey } from '@motion/shared/flows'
import { classed } from '@motion/theme'
import {
  AutoScheduleToggle,
  ButtonGroup,
  FieldButton,
  Tooltip,
} from '@motion/ui/base'
import {
  findStageForTask,
  type FlowTemplateFormTask,
} from '@motion/ui-logic/pm/project'

import {
  DurationDropdown,
  StatusDropdown,
} from '~/areas/project-management/components'
import { LabelDropdown, PriorityDropdown } from '~/global/components/dropdowns'
import {
  ConnectedUserLabel,
  DurationLabel,
  PriorityLabel,
  StatusLabel,
} from '~/global/components/labels'
import { useWorkspaceStatusById } from '~/global/hooks'

import { EditTaskDefinitionButton } from './edit-task-definition-button'
import { StageEventDetailsSection } from './stage-event-details-section'
import { TaskCardHeader } from './stage-task-header'
import { StageTaskOptionMenu } from './stage-task-option-menu'
import { TaskTemplateDebugInfo } from './task-template-debug-info'

import { useFlowTemplateForm } from '../../../hooks'
import { useTaskDefinitionDirtyState } from '../../../hooks/use-task-dirty-state'
import { RoleLabel } from '../../role-label'
import {
  BlockedByField,
  ControlledRelativeDeadlineField,
  ControlledRelativeStartField,
  StageTaskAssigneeDropdown,
  StageTaskNameField,
  useStageTaskField,
} from '../fields'
import {
  useFlowTemplateRoles,
  useStageEventField,
  useStageTaskAssigneeField,
} from '../fields/hooks'
import { MinimumDurationField } from '../fields/minimum-duration-field'
import { InlineLabels } from '../inline-labels'

export type StageTaskCardProps = {
  stageTasksPath: `stages.${number}.tasks`
  taskBasePath: `stages.${number}.tasks.${number}`
  task: FlowTemplateFormTask
  onRemove?: () => void
}

export const StageTaskCard = (props: StageTaskCardProps) => {
  const {
    form: { watch },
  } = useFlowTemplateForm()
  const task = watch(props.taskBasePath)

  if (task == null) {
    return null
  }
  return <InnerStageTaskCard {...props} />
}

const InnerStageTaskCard = ({
  stageTasksPath,
  taskBasePath,
  task,
  onRemove,
}: StageTaskCardProps) => {
  const [statusId, onChangeStatus] = useStageTaskField(
    `${taskBasePath}.statusId`
  )
  const [duration, onChangeDuration] = useStageTaskField(
    `${taskBasePath}.duration`
  )
  const [minimumDuration, onChangeMinimumDuration] = useStageTaskField(
    `${taskBasePath}.minimumDuration`
  )
  const [priorityLevel, onChangePriorityLevel] = useStageTaskField(
    `${taskBasePath}.priorityLevel`
  )
  const [labelIds, onChangeLabelIds] = useStageTaskField(
    `${taskBasePath}.labelIds`
  )
  const [autoScheduled, onChangeAutoScheduled] = useStageTaskField(
    `${taskBasePath}.isAutoScheduled`
  )
  const [blockedByTaskId, onChangeBlockedByTaskId] = useStageTaskField(
    `${taskBasePath}.blockedByTaskId`
  )
  const [assigneeId, onChangeAssignee] = useStageTaskAssigneeField(taskBasePath)

  const [stageCardType, onChangeCardStageType] =
    useStageEventField(taskBasePath)

  const {
    form: { watch },
  } = useFlowTemplateForm()

  const isTaskDirty = useTaskDefinitionDirtyState(task.id)
  const status = useWorkspaceStatusById(statusId)
  const { flowTemplateRoles, selectedRole } = useFlowTemplateRoles(taskBasePath)

  const stages = watch('stages')
  const stageTasks = watch(stageTasksPath)
  const blockedByTask = stageTasks.find((t) => t.id === blockedByTaskId) ?? null

  const textVariables = watch('textVariables')
  const workspaceId = watch('workspaceId')
  const stageForTask = findStageForTask(stages, task.id)
  if (workspaceId == null || stageTasks.length === 0 || stageForTask == null) {
    return null
  }

  const attachmentsCount =
    stageForTask.tasks.find((t) => t.id === task.id)?.uploadedFileIds?.length ??
    0

  const fieldSize = 'xsmall'

  const stagePath = stageTasksPath
    .split('.')
    .slice(0, 2)
    .join('.') as `stages.${number}`
  const stageDeadlineInterval = watch(`${stagePath}.deadlineInterval`)

  return (
    <TaskCardContainer data-id={task.id}>
      <TaskCard isDirty={isTaskDirty}>
        <TaskCardHeader
          value={stageCardType}
          onChange={onChangeCardStageType}
          suffix={<StageTaskOptionMenu task={task} onRemove={onRemove} />}
          isTaskDirty={isTaskDirty}
        />
        <div className='pt-2 px-2 truncate'>
          <StageTaskNameField taskBasePath={taskBasePath} />
        </div>
        <TaskCardFields>
          <ControlledRelativeStartField
            color={stageForTask.color}
            taskBasePath={taskBasePath}
            size={fieldSize}
            stageDeadlineInterval={stageDeadlineInterval}
          />
          <ControlledRelativeDeadlineField
            color={stageForTask.color}
            taskBasePath={taskBasePath}
            size={fieldSize}
            stageDeadlineInterval={stageDeadlineInterval}
          />
          {/* add a placeholder to take up the whole row as reminder tasks dont show start date */}
          {duration === 0 && <div className='relative-date-placeholder' />}

          <StatusDropdown
            selectedStatusId={statusId}
            onChange={onChangeStatus}
            workspaceId={workspaceId}
            excludeResolved
            excludeCanceled
          >
            <FieldButton variant='muted' fullWidth size={fieldSize}>
              <StatusLabel value={status} />
            </FieldButton>
          </StatusDropdown>

          <div className='flex flex-row items-center gap-1'>
            <DurationDropdown
              value={duration}
              onChange={(d) => {
                onChangeDuration(d)
              }}
            >
              <FieldButton variant='muted' size={fieldSize}>
                <DurationLabel
                  value={duration}
                  longMode={duration === null ? false : true}
                  size={fieldSize}
                />
              </FieldButton>
            </DurationDropdown>

            <MinimumDurationField
              duration={duration}
              onMinimumDurationChange={onChangeMinimumDuration}
              minimumDuration={minimumDuration}
            />
          </div>

          <PriorityDropdown
            selectedItem={priorityLevel}
            items={priorityLevels}
            onChange={onChangePriorityLevel}
          >
            <FieldButton variant='muted' fullWidth size={fieldSize}>
              <PriorityLabel value={priorityLevel} />
            </FieldButton>
          </PriorityDropdown>

          <StageTaskAssigneeDropdown
            selectedUserId={assigneeId}
            onChange={onChangeAssignee}
            workspaceId={workspaceId}
            dropdownRoles={flowTemplateRoles}
          >
            <FieldButton variant='muted' fullWidth size={fieldSize}>
              {isFlowVariableKey(assigneeId) && selectedRole != null ? (
                <RoleLabel role={selectedRole} />
              ) : (
                <ConnectedUserLabel userId={assigneeId} />
              )}
            </FieldButton>
          </StageTaskAssigneeDropdown>

          <LabelDropdown
            selectedLabelIds={labelIds}
            onChange={onChangeLabelIds}
            workspaceId={workspaceId}
          >
            <FieldButton variant='muted' fullWidth size={fieldSize}>
              <InlineLabels labelIds={labelIds} workspaceId={workspaceId} />
            </FieldButton>
          </LabelDropdown>

          <BlockedByField
            stageTasksPath={stageTasksPath}
            task={task}
            value={blockedByTask}
            textVariables={textVariables}
            onChange={onChangeBlockedByTaskId}
            size={fieldSize}
          />
        </TaskCardFields>

        <TaskCardFooter>
          <div className='[&>label]:gap-1'>
            <Tooltip
              content={
                !assigneeId
                  ? 'Auto-schedule is disabled because this task has no assignee. Set an assignee to enable auto-scheduling.'
                  : undefined
              }
              asChild
            >
              <AutoScheduleToggle
                disabled={!assigneeId}
                checked={autoScheduled}
                onChange={(e) => onChangeAutoScheduled(e.target.checked)}
                size='xsmall'
              >
                <span className='text-2xs'>Auto-scheduled</span>
              </AutoScheduleToggle>
            </Tooltip>
          </div>

          <ButtonGroup size='small'>
            {attachmentsCount > 0 && (
              <EditTaskDefinitionButton
                icon={<AttachmentOutline />}
                text={attachmentsCount.toString()}
                taskBasePath={taskBasePath}
                variant='muted'
              />
            )}
            <EditTaskDefinitionButton taskBasePath={taskBasePath} />
          </ButtonGroup>
        </TaskCardFooter>
      </TaskCard>

      {!__IS_PROD__ && <TaskTemplateDebugInfo task={task} />}

      {stageCardType === 'event' && (
        <StageEventDetailsSection taskBasePath={taskBasePath} />
      )}
    </TaskCardContainer>
  )
}

const TaskCardContainer = classed('div', {
  base: `
    flex flex-col
    relative isolate
  `,
})

const TaskCard = classed('div', {
  base: `
        flex flex-col gap-2

        bg-kanban-card-bg-default
        border border-kanban-card-border-default
        rounded-[4px] overflow-hidden
      `,
  variants: {
    isDirty: {
      true: 'border-semantic-orange-border-active',
    },
  },
})

const TaskCardFields = classed('div', {
  base: `
        grid grid-cols-2 gap-1
        px-2
        grid-rows-[24px_24px_24px_24px]
        [&_*]:!text-[11px]
      `,
})

const TaskCardFooter = classed('div', {
  base: `
        bg-semantic-neutral-surface-raised-bg-subtlest
        flex flex-row items-center justify-between
        px-3
        py-2
        rounded-b-[4px]
      `,
})
