import { type VersionedViewV2 } from '@motion/rpc-types'

import { useMemo, useState } from 'react'

import { ViewTabsContext } from './view-tabs-context'

import { type ViewType } from '../utils'

type ViewTabsContextProviderProps = {
  children: React.ReactNode
  views: VersionedViewV2[]
}

export const ViewTabsContextProvider = ({
  children,
  views,
}: ViewTabsContextProviderProps) => {
  const [selectedViewTypes, setSelectedViewTypes] = useState<Set<ViewType>>(
    new Set()
  )

  const filteredViews = useMemo(() => {
    if (selectedViewTypes.size === 0) {
      return views
    }

    return views.filter((view) => {
      if (view.definition.type === 'dashboard') {
        return selectedViewTypes.has('dashboard')
      }

      if ('layout' in view.definition) {
        return selectedViewTypes.has(view.definition.layout as ViewType)
      }

      return false
    })
  }, [selectedViewTypes, views])

  const value = useMemo(
    () => ({ selectedViewTypes, setSelectedViewTypes, filteredViews }),
    [selectedViewTypes, setSelectedViewTypes, filteredViews]
  )

  return (
    <ViewTabsContext.Provider value={value}>
      {children}
    </ViewTabsContext.Provider>
  )
}
