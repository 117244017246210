import { getEventHiddenAndDisabledFields } from '@motion/ui-logic'

import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import { type EventAiFormFields } from '../../../types'
import { useEventModalState } from '../contexts'

export function useEventForm() {
  const form = useFormContext<EventAiFormFields>()
  const {
    initialEvent,
    initialEventRecurringParent,
    isReadOnly,
    initialSchedulingTask,
  } = useEventModalState()

  const { watch } = form

  const recurrence = watch('recurrence')
  const travelTimeBefore = watch('travelTimeBefore')
  const travelTimeAfter = watch('travelTimeAfter')
  const conferenceType = watch('conferenceType')

  const fromSchedulingTask = initialSchedulingTask != null

  return useMemo(() => {
    const { hiddenFields, disabledFields } = getEventHiddenAndDisabledFields(
      { recurrence, travelTimeBefore, travelTimeAfter, conferenceType },
      {
        initialEvent,
        initialEventRecurringParent,
        isReadOnly,
        fromSchedulingTask,
      }
    )

    return {
      form,
      hiddenFields,
      disabledFields,
    }
  }, [
    recurrence,
    travelTimeBefore,
    travelTimeAfter,
    conferenceType,
    initialEvent,
    initialEventRecurringParent,
    isReadOnly,
    fromSchedulingTask,
    form,
  ])
}
