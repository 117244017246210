import { NoteSolid } from '@motion/icons'
import { type COLOR } from '@motion/shared/common'
import { ColorTag, type ColorTagProps } from '@motion/ui/base'
import { type NoteSchema } from '@motion/zod/client'

import {
  ColorDropdown,
  type ColorDropdownProps,
} from '~/global/components/dropdowns'
import { useNoteById, useUpdateNote } from '~/global/rpc/v2'

type NoteColorTagProps = Omit<ColorTagProps, 'color' | 'icon'> & {
  noteId?: NoteSchema['id']
  onChange?: (color: COLOR) => void
}

export function NoteColorTag({
  noteId,
  onChange,
  ...props
}: NoteColorTagProps) {
  const { data: note } = useNoteById({ id: noteId })

  const { mutateAsync: updateNote } = useUpdateNote()

  const handleChange: ColorDropdownProps['onChange'] = async (color) => {
    if (noteId != null) {
      await updateNote({ id: noteId, color })

      onChange?.(color)
    }
  }

  const color = note?.color ?? 'gray'

  const content = (
    <ColorTag
      color={color}
      icon={<NoteSolid />}
      size='large'
      square
      {...props}
    />
  )

  if (noteId == null) return content

  return (
    <ColorDropdown selectedColor={color} onChange={handleChange}>
      {content}
    </ColorDropdown>
  )
}
