import { useAuthenticatedUser, type User } from '@motion/web-common/auth'

import { getAllIdsOfTypeInFolder } from '~/areas/folders/utils'
import {
  type AppWorkspaceDataContext,
  useAppWorkspaceContext,
} from '~/global/contexts'
import { useMatchedRouteData } from '~/routing/hooks/use-matched-route-data'
import { useMemo } from 'react'
import { useSearchParams as useRouterSearchParams } from 'react-router-dom'

import {
  FolderPageGroupExclusions,
  ProjectPageGroupExclusions,
  WorkspacePageGroupExclusions,
} from '../../../grouping'
import {
  type MatchedV3RouteData,
  type PageParams,
  type PageParamsOverrides,
  type PageParamsPredicates,
  type PageParamsView,
} from '../../types'
import { getProjectNameWithActiveStage } from '../get-project-name-with-active-stage'

export const usePageDataInternal = (): PageParams => {
  const route = useMatchedRouteData<MatchedV3RouteData>()

  const [query] = useRouterSearchParams()

  const [ctx] = useAppWorkspaceContext()
  const user = useAuthenticatedUser()

  // Construct a unique key based on the route param and route state key
  const pageKey = useMemo(() => {
    const {
      data: { key, page },
      params,
    } = route
    const segments = [key]

    // Add relevant ID based on page type
    switch (page) {
      case 'folder':
        segments.push(params.folderId)
        break
      case 'workspace':
        segments.push(params.workspaceId)
        break
      case 'project':
        segments.push(params.projectId)
        break
      default:
        segments.push(page)
        break
    }

    return segments.join('_')
  }, [route])

  return useMemo(
    () => ({
      stateKey: route.data.key,
      title: getPageTitle(ctx, route),
      type: route.data.type,
      page: route.data.page,
      pageKey,
      variant: route.data.variant,
      lock: {
        workspaceId: route.params.workspaceId,
        projectId: route.params.projectId,
        assignee: route.data.filter.user,
        folderId: route.data.filter.folderId,
        viewId: route.data.filter.viewId ?? 'default',
      },
      overrides: getOverrides(ctx, user, route),
      view: getViewFilter(ctx, route),
      state: route.data.state,
      predicates: getPagePredicates(ctx, route),
      params: route.params,
      search: Object.fromEntries(query.entries()),
    }),
    [ctx, pageKey, query, route, user]
  )
}

function getPageTitle(ctx: AppWorkspaceDataContext, route: MatchedV3RouteData) {
  const isArchive = !!route.data.filter.archive

  if (route.data.page === 'all-projects') return 'All Projects'
  if (route.data.filter.views === 'my-tasks')
    return isArchive ? 'Archived (My Tasks)' : 'My Tasks'
  if (route.data.filter.views === 'all-tasks') return 'All Tasks'

  if (route.data.filter.projectId) {
    const projectNameWithStage = getProjectNameWithActiveStage(
      ctx,
      route.data.filter.projectId
    )

    return projectNameWithStage ?? 'Unknown Project'
  }

  if (route.data.filter.folderId) {
    const title = ctx.folders.byId[route.data.filter.folderId]?.name
    return title ?? 'Unknown Folder'
  }

  if (route.data.filter.workspaceId) {
    const workspaceName =
      ctx.workspaces.byId[route.data.filter.workspaceId]?.name ??
      'Unknown Workspace'

    return isArchive ? `Archived (${workspaceName})` : workspaceName
  }

  return 'Unknown'
}

function getOverrides(
  ctx: AppWorkspaceDataContext,
  user: User,
  route: MatchedV3RouteData
): PageParamsOverrides {
  if (route.data.page === 'all-tasks') return {}

  if (route.data.page === 'team-schedule') {
    return {
      workspaces: {
        ids: {
          operator: 'in',
          value: ctx.workspaces.all
            .filter((w) => !w.isPersonalWorkspace)
            .map((w) => w.id),
        },
      },
    }
  }

  if (route.data.page === 'my-tasks') {
    if (route.data.type === 'projects') {
      return {
        projects: {
          managerIds: { operator: 'in', value: [user.uid] },
        },
      }
    }

    return {
      tasks: {
        assigneeUserIds: { operator: 'in', value: [user.uid] },
      },
    }
  }

  if (route.data.page === 'workspace' && route.data.filter.workspaceId) {
    return {
      workspaces: {
        ids: { operator: 'in', value: [route.data.filter.workspaceId] },
      },
    }
  }

  if (
    route.data.page === 'project' &&
    route.data.filter.projectId &&
    route.data.filter.workspaceId
  ) {
    return {
      projects: {
        ids: { operator: 'in', value: [route.data.filter.projectId] },
      },
      workspaces: {
        ids: { operator: 'in', value: [route.data.filter.workspaceId] },
      },
    }
  }

  if (
    route.data.page === 'folder' &&
    route.data.filter.folderId &&
    route.data.filter.workspaceId
  ) {
    return {
      projects: {
        ids: {
          operator: 'in',
          value: getAllIdsOfTypeInFolder(
            ctx.systemFolders.byId['workspaces'].items,
            route.data.filter.folderId,
            'PROJECT'
          ),
        },
      },
      workspaces: {
        ids: { operator: 'in', value: [route.data.filter.workspaceId] },
      },
    }
  }

  return {}
}

function getViewFilter(
  ctx: AppWorkspaceDataContext,
  route: MatchedV3RouteData
): PageParamsView {
  if (route.data.page === 'all-tasks' || route.data.page === 'all-projects') {
    return { type: 'all-tasks' }
  }

  if (route.data.page === 'my-tasks') {
    return { type: 'my-tasks' }
  }

  if (
    route.data.page === 'workspace' ||
    route.data.page === 'project' ||
    route.data.page === 'folder'
  ) {
    if (!route.data.filter.workspaceId) {
      throw new Error(`workspaceId is required`)
    }
    return {
      type: 'workspace',
      targetId: route.data.filter.workspaceId,
      targetType: 'WORKSPACE',
    }
  }

  return { type: 'unknown' }
}

const RETURN_TRUE = () => true
const RETURN_FALSE = () => false

const ACCEPT_ALL = {
  filters: RETURN_TRUE,
  fields: RETURN_TRUE,
}
const ACCEPT_NONE = {
  filters: RETURN_FALSE,
  fields: RETURN_FALSE,
}

function getPagePredicates(
  ctx: AppWorkspaceDataContext,
  route: MatchedV3RouteData
): PageParamsPredicates {
  const PROJECT_ACCEPT_ALL_WITH_OVERRIDES: PageParamsPredicates['projects'] = {
    filters: RETURN_TRUE,
    fields: RETURN_TRUE,
  }

  if (route.data.page === 'all-tasks')
    return {
      tasks: ACCEPT_ALL,
      projects: PROJECT_ACCEPT_ALL_WITH_OVERRIDES,
      groups: RETURN_TRUE,
    }

  if (route.data.page === 'my-tasks') {
    return {
      tasks: {
        filters: (def) => def.key !== 'assigneeUserIds',
        fields: RETURN_TRUE,
      },
      projects: PROJECT_ACCEPT_ALL_WITH_OVERRIDES,
      groups: RETURN_TRUE,
    }
  }

  if (route.data.page === 'project') {
    return {
      tasks: ACCEPT_ALL,
      projects: ACCEPT_NONE,
      groups: (def) => !ProjectPageGroupExclusions.includes(def.id),
    }
  }

  if (route.data.page === 'workspace') {
    return {
      tasks: ACCEPT_ALL,
      projects: PROJECT_ACCEPT_ALL_WITH_OVERRIDES,
      groups: (def) => !WorkspacePageGroupExclusions.includes(def.id),
    }
  }

  if (route.data.page === 'folder') {
    return {
      tasks: ACCEPT_ALL,
      projects: ACCEPT_ALL,
      groups: (def) => !FolderPageGroupExclusions.includes(def.id),
    }
  }

  return {
    tasks: ACCEPT_ALL,
    projects: ACCEPT_ALL,
    groups: RETURN_TRUE,
  }
}
