import { convertDateIntervalToDays } from '@motion/shared/flows'
import type { FlowTemplateFormFields } from '@motion/ui-logic/pm/project'

import { type UseFormReturn } from 'react-hook-form'

import { calculateAdjustedRelativeDurations } from '../../adjust-relative-durations'
import { getIndexFromName } from '../../get-index-from-name'

export const handleStageExpectedDurationUpdate = (
  form: UseFormReturn<FlowTemplateFormFields>,
  name: string
) => {
  const { stageIndex } = getIndexFromName(name)
  if (stageIndex == null) return

  // If the value or unit changes, update the relative dates
  if (name.endsWith('value') || name.endsWith('unit')) {
    const stageDeadlineInterval = form.getValues(
      `stages.${stageIndex}.deadlineInterval`
    )

    if (
      stageDeadlineInterval == null ||
      stageDeadlineInterval.value == null ||
      Number.isNaN(stageDeadlineInterval.value)
    )
      return

    const stageDeadlineIntervalInDays = convertDateIntervalToDays(
      stageDeadlineInterval
    )

    const tasks = form.getValues(`stages.${stageIndex}.tasks`)
    // If the stage expected duration changes, check if need to limit relative dates
    tasks.forEach(
      (
        {
          startRelativeInterval: { duration: startDuration },
          dueRelativeInterval: { duration: dueDuration },
        },
        i
      ) => {
        const adjustedDates = calculateAdjustedRelativeDurations(
          stageDeadlineIntervalInDays,
          { startDuration, dueDuration }
        )

        if (adjustedDates) {
          if (adjustedDates.start) {
            form.setValue(
              `stages.${stageIndex}.tasks.${i}.startRelativeInterval.duration`,
              {
                ...startDuration,
                ...adjustedDates.start,
              }
            )
          }

          if (adjustedDates.due) {
            form.setValue(
              `stages.${stageIndex}.tasks.${i}.dueRelativeInterval.duration`,
              {
                ...dueDuration,
                ...adjustedDates.due,
              }
            )
          }
        }
      }
    )
  }
}
