import { useLookup } from '~/global/cache'
import { useCallback } from 'react'

import type { UserFavoriteType } from './use-user-favorites'

export const LOOKUP_TABLE = {
  FOLDER: 'folders' as const,
  NOTE: 'notes' as const,
  PROJECT: 'projects' as const,
  VIEW: 'views' as const,
} satisfies Record<UserFavoriteType, string>

export const useFavoriteTypeLookup = () => {
  const lookup = useLookup()

  return useCallback(
    (type: Required<UserFavoriteType>, id: string) => {
      const lookupType = LOOKUP_TABLE[type]

      // TODO Notes are not in MotionCache yet, this prevents TS errors
      if (lookupType === 'notes') {
        return null
      }

      return lookup(lookupType, id)
    },
    [lookup]
  )
}
