import { XSolid } from '@motion/icons'
import { useSharedState } from '@motion/react-core/shared-state'
import {
  Button,
  ButtonGroup,
  IconButton,
  PopoverTrigger,
  SearchableList,
} from '@motion/ui/base'
import { userComparator } from '@motion/ui-logic'
import { useAuthenticatedUser } from '@motion/web-common/auth'
import { type IdFilterSchema, type UserInfoSchema } from '@motion/zod/client'

import { UserLabel } from '~/global/components/labels'
import { AppWorkspaceContext } from '~/global/contexts'
import { useCallback, useMemo } from 'react'

import { renderSelection } from './utils'

import { type UseFieldFilter, useFieldFilter } from '../../context'
import { buildIdFilter } from '../../utils'
import { type DropdownFilterProps } from '../types'

export const ConnectedUserFilter = (props: DropdownFilterProps) => {
  const { uid } = useAuthenticatedUser()
  const [context] = useSharedState(AppWorkspaceContext)

  const activeUsers = context.users.all
    .filter((x) => !x.deleted)
    .sort(userComparator({ currentUserId: uid }))

  const field =
    props.target === 'tasks'
      ? ('assigneeUserIds' as const)
      : ('managerIds' as const)

  const [filter, setFilter] = useFieldFilter(
    props.target,
    // @ts-expect-error - the field  will be fine
    field
  ) as UseFieldFilter<IdFilterSchema | null>

  const computeSelected = useCallback(
    (item: UserInfoSchema) => filter?.value.includes(item.id) ?? false,
    [filter]
  )

  const info = useMemo(() => {
    const selected = activeUsers.filter((x) => computeSelected(x))
    const text = renderSelection(selected, activeUsers)
    return { all: activeUsers, selected, text }
  }, [activeUsers, computeSelected])

  return (
    <PopoverTrigger
      placement='bottom-start'
      onClose={props.onBlur}
      openOnMount={props.openOnMount}
      renderPopover={({ close }) => {
        return (
          <div>
            <SearchableList
              inputProps={{ placeholder: 'Choose assignee ...' }}
              itemType='checkbox'
              items={activeUsers}
              renderItem={(user) => <UserLabel value={user} />}
              computeSearchValue={(user) => user.name}
              computeSelected={computeSelected}
              onSelect={(values) => {
                setFilter(buildIdFilter(values, filter))
              }}
              computeKey={(item) => item.id}
            />
            <div className='border-t border-dropdown-border p-2 flex justify-between'>
              <Button
                onClick={() => {
                  setFilter(null)

                  close()
                }}
                variant='outlined'
                sentiment='neutral'
                size='small'
              >
                Clear
              </Button>
              <Button
                onClick={() => {
                  setFilter(buildIdFilter(activeUsers, filter))
                }}
                variant='outlined'
                sentiment='neutral'
                size='small'
              >
                Select all
              </Button>
            </div>
          </div>
        )
      }}
    >
      <ButtonGroup segmented nowrap>
        <Button sentiment='neutral' variant='outlined' size='small'>
          Assignee: <div className='font-medium'>{info.text}</div>
        </Button>
        <IconButton
          sentiment='neutral'
          variant='outlined'
          size='small'
          icon={XSolid}
          aria-label='Clear assignee filter'
          onClick={(e) => {
            e.stopPropagation()
            setFilter(null)
          }}
        />
      </ButtonGroup>
    </PopoverTrigger>
  )
}
