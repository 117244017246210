import { type VariantProps } from '@motion/theme'
import { addComponentName } from '@motion/ui/helpers'

import { type ReactNode } from 'react'

import { StyledTabList, Tab, type TabButton, type TabProps } from './components'
import { type TabItem } from './types'

export type TabListProps = {
  items: Omit<TabItem, 'name'>[]
  activeValue: TabItem['value']
  gap?: TabProps['spacing']
  outerPadding?: VariantProps<typeof StyledTabList>['outerPadding']
  size?: VariantProps<typeof TabButton>['size']
  renderEndContent?: () => ReactNode
}

export const TabList = ({
  items,
  activeValue,
  gap,
  outerPadding,
  size,
  renderEndContent,
}: TabListProps) => {
  return (
    <StyledTabList
      role='tablist'
      outerPadding={outerPadding}
      {...addComponentName('TabList')}
    >
      {items.map((x) => {
        return (
          <Tab
            key={x.value}
            url={x.url}
            onAction={x.onAction}
            active={x.value === activeValue}
            size={size}
            spacing={gap}
          >
            {x.content}
          </Tab>
        )
      })}

      {renderEndContent?.()}
    </StyledTabList>
  )
}
