import { PlusSolid } from '@motion/icons'
import { Button, LoadingSpinner } from '@motion/ui/base'
import { useModalApi } from '@motion/web-common/modals'

import { AutomationPolicyRow } from '~/areas/ai-hackerhouse/automation'
import { useAutomationPolicies } from '~/areas/ai-hackerhouse/hooks/rpc'
import { SettingPage } from '~/components/Settings/Components/SettingPage'
import { useCurrentTeam } from '~/global/rpc/team'
import { type ReactNode } from 'react'

export function AiNotetakerSettings() {
  const modalApi = useModalApi()

  const { data: team } = useCurrentTeam()

  const {
    data: automationPolicies,
    isLoading,
    isError,
  } = useAutomationPolicies(
    {
      teamId: team?.id ?? '',
    },
    {
      enabled: team != null,
    }
  )

  function handleAddAutomation() {
    modalApi.open('automation-form', {})
  }

  if (isError) {
    return (
      <Shell>
        <p className='text-sm text-semantic-error-text-strong'>
          There was an error loading the automation policies
        </p>
      </Shell>
    )
  }

  if (isLoading) {
    return (
      <Shell>
        <LoadingSpinner size={24} />
      </Shell>
    )
  }

  return (
    <Shell>
      <div className='flex flex-col gap-4'>
        {automationPolicies.permissionPolicies.map((policy) => (
          <AutomationPolicyRow key={policy.id} policy={policy} />
        ))}

        <div className='mt-2'>
          <Button onClick={handleAddAutomation} size='small' variant='muted'>
            <PlusSolid />
            Add automation
          </Button>
        </div>
      </div>
    </Shell>
  )
}

type ShellProps = {
  children: ReactNode
}

function Shell({ children }: ShellProps) {
  return (
    <SettingPage title='AI Notetaker'>
      <div className='flex w-full flex-col gap-2'>
        <h3 className='text-base font-semibold'>Permission Automations</h3>
        <p className='text-semantic-neutral-text-subtle text-sm'>
          Automate who gets access to meeting notes &amp; recordings for
          specific events
        </p>

        <div className='py-4'>{children}</div>
      </div>
    </SettingPage>
  )
}
