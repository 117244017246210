import { type DeadlineStatus } from '@motion/shared/common'
import { Button, SearchableList } from '@motion/ui/base'
import { type FilterTarget } from '@motion/ui-logic/pm/data'
import {
  DeadlineStatusAllowedFilterValues,
  type DeadlineStatusSchema,
} from '@motion/zod/client'

import { EtaBadge } from '~/global/components/badges'
import { EtaLabel } from '~/global/components/labels'
import { useAppWorkspaceContext } from '~/global/contexts'
import { useCallback } from 'react'

import { FilterItem } from './filter-item/filter-item'

import { useFieldFilter } from '../../../context'
import { MultiSelectFilterValue } from '../../multi-select-filter-value'
import { type DropdownFilterProps } from '../../types'

export function ConnectedETAFilter({
  target,
  onBlur,
  openOnMount,
}: DropdownFilterProps) {
  const [value, setValue] = useFieldFilter(target, 'deadlineStatuses')
  const ids: DeadlineStatus[] | null = value?.value ?? null

  return (
    <FilterItem
      label='ETA'
      canInvert
      inverted={value?.inverse}
      onInvertChanged={(invert) => {
        if (value == null) return
        setValue({ ...value, inverse: invert })
      }}
      onBlur={onBlur}
      openOnMount={openOnMount}
      onDismiss={() => setValue(null)}
      renderDropdown={({ close }) => (
        <ConnectedETADropdown applyTo={target} close={close} />
      )}
    >
      <MultiSelectFilterValue
        type='deadlineStatuses'
        ids={ids}
        renderMultipleValues={({ ids }) => <MultipleEtaBadge ids={ids} />}
      />
    </FilterItem>
  )
}

function MultipleEtaBadge({ ids }: { ids: DeadlineStatus[] }) {
  return (
    <div className='flex flex-row gap-1'>
      {ids.map((id) => (
        <EtaBadge key={id} value={id} />
      ))}
    </div>
  )
}

type ConnectedETADropdownProps = {
  applyTo: FilterTarget
  close(): void
}

function ConnectedETADropdown({ applyTo, close }: ConnectedETADropdownProps) {
  const [filter, setFilter] = useFieldFilter(applyTo, 'deadlineStatuses')
  const [ctx] = useAppWorkspaceContext()

  const computeSelected = useCallback(
    (value: DeadlineStatusSchema) => filter?.value.includes(value) ?? false,
    [filter]
  )

  let deadlineStatuses = ctx.deadlineStatuses.all

  if (applyTo === 'tasks') {
    const allowedDeadlineStatuses = new Set<DeadlineStatus>(
      DeadlineStatusAllowedFilterValues
    )
    deadlineStatuses = deadlineStatuses.filter((ds) =>
      allowedDeadlineStatuses.has(ds)
    )
  }

  return (
    <div>
      <SearchableList
        inputProps={{ placeholder: 'Choose ETA ...' }}
        itemType='checkbox'
        items={deadlineStatuses}
        renderItem={(value) => <EtaLabel value={value} />}
        searchable={false}
        computeSelected={computeSelected}
        onSelect={(values) => {
          setFilter({
            operator: 'in',
            inverse: filter?.inverse ?? false,
            value: values,
          })
        }}
        computeKey={(item) => item}
      />
      <div className='border-t border-dropdown-border p-2 flex justify-between'>
        <Button
          onClick={() => {
            setFilter(null)
            close()
          }}
          variant='outlined'
          sentiment='neutral'
          size='small'
        >
          Clear
        </Button>
        <Button
          onClick={() => {
            setFilter({
              operator: 'in',
              inverse: filter?.inverse ?? false,
              value: deadlineStatuses,
            })
          }}
          variant='outlined'
          sentiment='neutral'
          size='small'
        >
          Select all
        </Button>
      </div>
    </div>
  )
}
