import { type TaskDefinitionFormRelativeIntervalDuration } from '@motion/ui-logic/pm/project'

import { getDurationText } from '../utils'

export type DurationTextProps = {
  duration: TaskDefinitionFormRelativeIntervalDuration
}

export const DurationText = ({ duration }: DurationTextProps) => {
  return (
    <span className='text-semantic-neutral-text-default text-2xs'>
      {getDurationText(duration)}
    </span>
  )
}
