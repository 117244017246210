import { useSharedStateQuery } from '@motion/react-core/shared-state'
import { PopoverTrigger, SearchableList } from '@motion/ui/base'
import { byProperty, Compare } from '@motion/utils/array'

import { AppWorkspaceContext } from '~/global/contexts'
import { type ReactNode } from 'react'

type WorkspaceSelectorProps = {
  onSelect: (workspaceIds: string[]) => void
  selectedWorkspaceIds: string[]
  children: ReactNode
}

export const WorkspaceSelector = ({
  onSelect,
  children,
  selectedWorkspaceIds,
}: WorkspaceSelectorProps) => {
  const workspaces = useSharedStateQuery(AppWorkspaceContext, (state) => {
    return state.workspaces.all
      .filter((workspace) => !workspace.isPersonalWorkspace)
      .sort(byProperty('name', Compare.string))
  })

  return (
    <PopoverTrigger
      placement='bottom'
      renderPopover={() => (
        <SearchableList
          itemType='checkbox'
          items={workspaces}
          computeKey={(item) => item.id}
          computeSelected={(item) => selectedWorkspaceIds.includes(item.id)}
          computeSearchValue={(item) => item.name}
          onSelect={(items) => {
            onSelect(items.map((item) => item.id))
          }}
          inputProps={{
            placeholder: 'Search workspaces',
          }}
          renderItem={(item) => item.name}
        />
      )}
    >
      {children}
    </PopoverTrigger>
  )
}
