import { TeamTaskType } from '@motion/rpc-types'
import {
  type COLOR,
  isCompletedStatus,
  StatusType,
} from '@motion/shared/common'
import { CalendarTask, getDurationEvent } from '@motion/ui/calendar'
import { isTaskPastDue, templateStr, useChunkInfo } from '@motion/ui-logic'
import {
  isMeetingTask,
  isSchedulingTask,
  isTaskUnfit,
  isUnscheduledSchedulingTask,
} from '@motion/ui-logic/pm/task'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { type StatusSchema } from '@motion/zod/client'

import { type EventContentArg } from '@fullcalendar/core'
import {
  CalendarBlockersHoverCard,
  ContextMenuPopoverWithButton,
} from '~/areas/calendar/components'
import { type FullCalendarTaskEvent } from '~/areas/calendar/utils'
import { EventActionList } from '~/areas/event/components'
import { TaskActionList, TaskStatusDropdown } from '~/areas/tasks/components'
import {
  useBlockerTasks,
  useDoTaskLater,
  useResolveTask,
  useTaskStatusUpdater,
  useTaskUpdater,
} from '~/areas/tasks/hooks'
import { useFilterActiveTasks } from '~/areas/tasks/hooks/helpers'
import {
  IconTooltipContent,
  TaskNameTooltipContent,
} from '~/areas/tasks/tooltips'
import { StatusBadge } from '~/global/components/badges'
import { useProject, useWorkspaceFns } from '~/global/hooks'
import { useTaskModalUrl } from '~/global/navigation'
import { type TaskWithRelations } from '~/global/proxies'
import { useReadTaskFn, useTaskByIdV2 } from '~/global/rpc/v2'
import { DateTime } from 'luxon'
import { useMemo } from 'react'
import { useNavigate } from 'react-router'
import { twMerge } from 'tailwind-merge'

type Props = {
  id: string
  event: EventContentArg
  modifier?: string
}

/**
 * Component for rendering a task onto FullCalendar
 * Note: this component relies on CSS classes injected in a parent element. See
 * `taskToFullCalendarEvent` for the potential classes available.
 */
export const TaskEvent = (props: Props) => {
  const { event: eventArg } = props

  const { isPast, isStart, isEnd } = eventArg
  const { start, end, extendedProps } = eventArg.event
  const { task: taskArg, chunkInfo } =
    extendedProps as FullCalendarTaskEvent['extendedProps']

  // TODO remove when type correct
  const task = taskArg as TaskWithRelations

  const project = useProject(task.projectId)

  const buildTaskModalUrl = useTaskModalUrl()
  const navigate = useNavigate()
  const updateTask = useTaskUpdater()
  const readTask = useReadTaskFn()
  const { getStatusById } = useWorkspaceFns()

  const { completeTask } = useResolveTask()
  const updateTaskStatus = useTaskStatusUpdater()
  const doTaskLater = useDoTaskLater()

  // Reading the parent task from rpc if we don't have it in the task object
  // Using an `Infinity` stale time because we don't want to hit the network
  const { data: dataParentChunk } = useTaskByIdV2(
    // @ts-expect-error TODO @absico remove
    { id: task?.parentChunkTaskId ?? '' },
    {
      staleTime: Infinity,
      // @ts-expect-error TODO @absico remove
      enabled: !task.parentChunkTask && !!task?.parentChunkTaskId,
    }
  )

  // @ts-expect-error TODO @absico remove
  const parentChunkTask = task.parentChunkTask ?? dataParentChunk

  const {
    chunkNumber: hookChunkNumber,
    chunkTotal: hookChunkTotal,
    chunks,
  } = useChunkInfo(task, parentChunkTask)
  const { blockedBy, blocking } = useBlockerTasks(
    {
      taskId: task.id,
    },
    {
      staleTime: Infinity,
    }
  )

  const activeBlockers = useFilterActiveTasks(blockedBy)

  // Old calendar events don't have chunk information, so we need to use the hook values
  const chunkNumber = chunkInfo ? chunkInfo.chunkNumber : hookChunkNumber
  const chunkTotal = chunkInfo ? chunkInfo.chunkTotal : hookChunkTotal

  const isRecurringInstance =
    (task?.type === TeamTaskType.RECURRING_INSTANCE ||
      parentChunkTask?.type === TeamTaskType.RECURRING_INSTANCE) ??
    false

  const isScheduleOverridden = task?.scheduleOverridden
  const isCompleted = task?.completedTime != null
  const snoozeUntil = task?.snoozeUntil
    ? DateTime.fromISO(task.snoozeUntil)
    : undefined

  const handleEditTask = () => {
    const parentChunkId = parentChunkTask?.id
    const taskId = task.id

    navigate(buildTaskModalUrl({ task: parentChunkId ?? taskId }), {
      state: {
        chunkId: parentChunkId != null ? taskId : null,
      },
    })
  }

  const { isUnvisitedStage = false } = task

  const duration = useMemo(() => {
    if (!start) return 0
    if (!end) return getDurationEvent(start, start)

    if (isStart && !isEnd) {
      return getDurationEvent(
        start,
        DateTime.fromJSDate(start).endOf('day').toJSDate()
      )
    }

    if (!isStart && isEnd) {
      return getDurationEvent(
        DateTime.fromJSDate(end).startOf('day').toJSDate(),
        end
      )
    }

    return getDurationEvent(start, end)
  }, [start, end, isStart, isEnd])

  if (!start || !task) return null

  const handleGhostStatusChange = (statusId: string): Promise<void> => {
    if (parentChunkTask != null) {
      const status = getStatusById(statusId)
      if (status && isCompletedStatus(status)) {
        return completeTask(task.id)
      }

      return updateTaskStatus(parentChunkTask.id, statusId)
    }

    return updateTaskStatus(task.id, statusId)
  }

  const isUnfit = isTaskUnfit(task)
  const isPastDue = isTaskPastDue(parentChunkTask ?? task)

  const isBlocked = activeBlockers.length > 0
  const isBlocking = blocking.length > 0

  const isScheduling = isSchedulingTask(parentChunkTask ?? task)
  const isUnscheduledScheduling = isUnscheduledSchedulingTask(
    parentChunkTask ?? task
  )

  const hasBlockers = isBlocked || isBlocking

  return (
    <div className='flex justify-end h-full'>
      <ContextMenuPopoverWithButton
        renderContextMenu={(close) =>
          isMeetingTask(task) ? (
            <EventActionList close={close} eventId={task.meetingEventId} />
          ) : (
            <TaskActionList close={close} task={task} />
          )
        }
        onMenuOpen={(source) => {
          recordAnalyticsEvent('CALENDAR_RIGHT_CLICK_TASK', {
            source: source === 'button' ? 'hover' : 'click',
          })
        }}
      >
        {({ handleContextMenu }) => (
          <CalendarTask
            isUnvisitedStage={isUnvisitedStage}
            colorOption={project?.color as COLOR}
            startTime={start}
            endTime={end ?? start}
            duration={duration}
            name={task.name}
            past={isPast}
            onClick={handleEditTask}
            onContextMenu={handleContextMenu}
            completed={isCompleted}
            locked={task.isFixedTimeTask}
            onUnlockTask={async () => {
              const taskv2 = await readTask(task.id)
              if (taskv2 == null) {
                throw new Error('Task not found')
              }
              if (taskv2.type === 'RECURRING_TASK') {
                throw new Error('Cannot unlock a recurring task')
              }

              recordAnalyticsEvent('CALENDAR_TASK_EVENT_UNLOCK')
              await updateTask(taskv2, { isFixedTimeTask: false })
            }}
            onUnsnoozeTask={async () => {
              recordAnalyticsEvent('CALENDAR_TASK_EVENT_UNSNOOZE')
              await doTaskLater((parentChunkTask ?? task).id, {
                snoozeUntil: null,
                source: 'task-event',
              })
            }}
            unfit={task.isUnfit}
            pastDue={isPastDue}
            scheduleOverridden={isScheduleOverridden}
            snoozeUntil={snoozeUntil}
            recurring={isRecurringInstance}
            chunkNumber={chunkNumber}
            chunkTotal={chunkTotal}
            renderNameTooltipContent={() => (
              <TaskNameTooltipContent task={task} />
            )}
            renderIconsTooltipContent={() => (
              <IconTooltipContent
                isRecurringInstance={isRecurringInstance}
                isUnfit={isUnfit}
                task={task}
                isPastDue={isPastDue}
                isScheduleOverridden={isScheduleOverridden}
                snoozeUntil={snoozeUntil}
                chunkTotal={chunkTotal}
                chunks={chunks}
              />
            )}
            renderStatus={() => {
              if (!task.status) return null
              const status = task.status as Pick<
                StatusSchema,
                'name' | 'color' | 'type'
              >
              const isCompleted = task.completedTime != null
              const taskVariant = isScheduling ? 'scheduling' : 'default'

              if (isCompleted) {
                return (
                  <div className='flex pt-px'>
                    <StatusBadge size='xsmall' value={status} hideTooltip />
                  </div>
                )
              }

              if (isUnvisitedStage || isScheduling) {
                return (
                  <TaskStatusDropdown
                    isUnvisitedStage={isUnvisitedStage}
                    selectedStatusId={task.statusId}
                    onChange={handleGhostStatusChange}
                    workspaceId={task.workspaceId}
                    taskVariant={taskVariant}
                    excludeResolved={isUnscheduledScheduling}
                  >
                    <button
                      title='Unvisited Stage Status Badge'
                      type='button'
                      className='flex py-px'
                    >
                      <StatusBadge
                        size='xsmall'
                        value={status}
                        iconVariant={
                          isUnvisitedStage ? 'isUnvisitedStage' : 'default'
                        }
                        taskVariant={taskVariant}
                      />
                    </button>
                  </TaskStatusDropdown>
                )
              }

              return (
                <CalendarBlockersHoverCard
                  taskId={task.id}
                  disabled={!hasBlockers}
                >
                  <button
                    className='group'
                    aria-label='Complete task'
                    disabled={isCompleted}
                    onClick={(e) => {
                      e.stopPropagation()
                      void completeTask(task.id)
                    }}
                  >
                    <div
                      className={twMerge(
                        !isCompleted && 'hidden group-hover:flex'
                      )}
                    >
                      <StatusBadge
                        size='xsmall'
                        value={{
                          ...status,
                          name: 'Completed',
                          type: StatusType.COMPLETED,
                        }}
                        hideTooltip
                      />
                    </div>
                    <div
                      className={twMerge(
                        !isCompleted ? 'flex group-hover:hidden' : 'hidden'
                      )}
                    >
                      <StatusBadge
                        size='xsmall'
                        value={status}
                        hideTooltip
                        iconVariant={isBlocked ? 'blocker' : 'default'}
                        taskVariant={taskVariant}
                      />
                    </div>
                  </button>
                </CalendarBlockersHoverCard>
              )
            }}
            data-testid={
              !__IS_PROD__ &&
              templateStr('task-event-{{taskId}}-{{taskName}}{{chunkNumber}}', {
                taskId: task.id,
                taskName: task.name,
                chunkNumber: chunkTotal > 1 ? `-${chunkNumber}` : '',
              })
            }
          />
        )}
      </ContextMenuPopoverWithButton>
    </div>
  )
}
