import { useSharedStateQuery } from '@motion/react-core/shared-state'
import { createUnknownStage } from '@motion/rpc-cache'
import { isObjectNoneId } from '@motion/shared/identifiers'
import { Sentry } from '@motion/web-base/sentry'
import {
  type ProjectDefinitionSchema,
  type ProjectSchema,
  type StageDefinitionSchema,
  type WorkspaceSchema,
} from '@motion/zod/client'

import {
  AppWorkspaceContext,
  type WorkspaceStageDefinition,
} from '~/global/contexts'

export type StageDefinitionWithProjectInfo = StageDefinitionSchema & {
  projects: ProjectSchema[]
  projectDefinitions: ProjectDefinitionSchema[]
}

export const useLegacyStageDefinition = (
  id: StageDefinitionSchema['id'] | null | undefined
): WorkspaceStageDefinition => {
  return useSharedStateQuery(AppWorkspaceContext, (state) => {
    const stageDefinition =
      id != null
        ? (state.stageDefinitions.byId[id] ?? createUnknownStage(id))
        : createUnknownStage(id)

    if (id != null && isObjectNoneId(stageDefinition)) {
      Sentry.captureMessage('No stage definition found', {
        extra: {
          id,
        },
      })
    }

    return stageDefinition
  })
}

export const useLegacyStageDefinitions = (
  ids: StageDefinitionSchema['id'][] | null | undefined
): WorkspaceStageDefinition[] => {
  return useSharedStateQuery(AppWorkspaceContext, (state) => {
    if (ids == null) {
      return []
    }

    return ids
      .map((id) => {
        const stageDefinition =
          state.stageDefinitions.byId[id] ?? createUnknownStage(id)

        if (isObjectNoneId(stageDefinition)) {
          Sentry.captureMessage('No stage definition found', {
            extra: {
              id,
            },
          })
          return null
        }

        return stageDefinition
      })
      .filter(Boolean) as WorkspaceStageDefinition[]
  })
}

export const useLegacyStageDefinitionsByProjectDefinitionId = (
  projectDefinitionId: ProjectDefinitionSchema['id'] | null | undefined
): StageDefinitionSchema[] => {
  return useSharedStateQuery(AppWorkspaceContext, (state) => {
    return state.stageDefinitions.all.filter(
      (sd) => sd.projectDefinitionId === projectDefinitionId
    )
  })
}

export const useWorkspaceStageDefinitions = (
  workspaceId: WorkspaceSchema['id']
): StageDefinitionSchema[] => {
  return useSharedStateQuery(AppWorkspaceContext, (state) => {
    return state.stageDefinitionsV2.all.filter(
      (stageDefinition) => stageDefinition.workspaceId === workspaceId
    )
  })
}

export const useStageDefinitionsWithProjectInfo = (
  workspaceId: WorkspaceSchema['id']
): StageDefinitionWithProjectInfo[] => {
  return useSharedStateQuery(AppWorkspaceContext, (state) => {
    const stages = state.stageDefinitionsV2.all.filter(
      (stageDefinition) => stageDefinition.workspaceId === workspaceId
    )

    const projectDefinitionsMap = state.projectDefinitions.all.reduce(
      (acc, projectDefinition) => {
        if (projectDefinition.workspaceId !== workspaceId) {
          return acc
        }

        projectDefinition.stages.forEach((stage) => {
          acc[stage.id] ||= []
          acc[stage.id].push(projectDefinition)
        })
        return acc
      },
      {} as Record<string, ProjectDefinitionSchema[]>
    )

    const projectsMap = state.projects.all.reduce(
      (acc, project) => {
        if (project.workspaceId !== workspaceId) {
          return acc
        }

        project.stages.forEach((stage) => {
          acc[stage.stageDefinitionId] ||= []
          acc[stage.stageDefinitionId].push(project)
        })
        return acc
      },
      {} as Record<string, ProjectSchema[]>
    )

    return stages.map((stageDefinition) => {
      return {
        ...stageDefinition,
        projects: projectsMap[stageDefinition.id] ?? [],
        projectDefinitions: projectDefinitionsMap[stageDefinition.id] ?? [],
      }
    })
  })
}
