import { type WorkspaceSchema } from '@motion/zod/client'

import { createContext, useContext } from 'react'

import { type ActivityFeedType, type ReplyActionPayload } from '../types'

export type ActivityFeedContextApi = {
  workspaceId: WorkspaceSchema['id']
  onUploadImage: (file: File) => Promise<string | undefined>
  editingCommentId: string | null
  setEditingCommentId: (id: string | null) => void

  triggerReplyAction: (payload: ReplyActionPayload) => void
  onReplyAction: (fn: (payload: ReplyActionPayload) => void) => void
} & ActivityFeedType

export const ActivityFeedContext = createContext<ActivityFeedContextApi | null>(
  null
)

export const useActivityFeedContext = () => {
  const context = useContext(ActivityFeedContext)
  if (context == null) {
    throw new Error(
      'useActivityFeedContext must be used within an ActivityFeedContextProvider'
    )
  }
  return context
}
