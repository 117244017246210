import { isGhostTask } from '@motion/ui-logic/pm/project'

import { useStatusDropdown } from '~/areas/task-project/hooks'
import { TaskStatusDropdown } from '~/areas/tasks/components'
import { StatusBadge } from '~/global/components/badges'
import { type TaskWithRelations } from '~/global/proxies'

export type TaskLineStatusItemProps = {
  task: TaskWithRelations
}

export function TaskLineStatusItem({ task }: TaskLineStatusItemProps) {
  const {
    onChange: onChangeStatus,
    value: statusDropdownValue,
    disabled: statusDisabled,
    iconVariant: statusIconVariant,
    taskVariant: statusTaskVariant,
    shouldExcludeResolved,
  } = useStatusDropdown(task)

  const isGhost = isGhostTask(task)

  return (
    <TaskStatusDropdown
      isUnvisitedStage={isGhost}
      selectedStatusId={task.statusId}
      onChange={onChangeStatus}
      workspaceId={task.workspaceId}
      taskVariant={statusTaskVariant}
      excludeResolved={shouldExcludeResolved}
    >
      <button type='button' className='flex' disabled={statusDisabled}>
        <StatusBadge
          value={statusDropdownValue}
          iconVariant={statusIconVariant}
          taskVariant={statusTaskVariant}
        />
      </button>
    </TaskStatusDropdown>
  )
}
