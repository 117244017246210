import { createKey, defineApi, defineMutation } from '../../core'
import { type RouteTypes } from '../types'

export const queryKeys = {
  root: createKey(['v2', 'notes']),
  query: (args: Pick<GetNoteById['request'], 'id'>) =>
    createKey(queryKeys.root, args.id),
}

type GetNoteById = RouteTypes<'BetaNotesController_getNoteById'>
export const getNoteById = defineApi<
  GetNoteById['request'],
  GetNoteById['response']
>().using({
  key: (args) => queryKeys.query(args),
  uri: (args) => `/v2/beta/notes/${args.id}`,
  method: 'GET',
})

type QueryNotes = RouteTypes<'BetaNotesController_queryForNotes'>
export const queryNotes = defineMutation<
  QueryNotes['request'],
  QueryNotes['response']
>().using({
  key: () => queryKeys.root,
  uri: () => `/v2/beta/notes/query`,
  method: 'POST',
})

type CreateNote = RouteTypes<'BetaNotesController_createNote'>
export const createNote = defineMutation<
  CreateNote['request'],
  CreateNote['response']
>().using({
  method: 'POST',
  uri: () => `/v2/beta/notes`,
  invalidate: [queryKeys.root],
})

type UpdateNote = RouteTypes<'BetaNotesController_updateNote'>
export const updateNote = defineMutation<
  UpdateNote['request'],
  UpdateNote['response']
>().using({
  method: 'PATCH',
  uri: (args) => `/v2/beta/notes/${args.id}`,
  body: (args) => args,
  invalidate: (args) => [queryKeys.query(args), queryKeys.root],
})

type DeleteNote = RouteTypes<'BetaNotesController_deleteNote'>
export const deleteNote = defineMutation<
  DeleteNote['request'],
  DeleteNote['response']
>().using({
  method: 'DELETE',
  uri: (args) => `/v2/beta/notes/${args.id}`,
  invalidate: [queryKeys.root],
})
