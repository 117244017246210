import { useModalApi } from '@motion/web-common/modals'

import { type WorkspaceCustomField } from '~/areas/custom-fields/types'
import { getCustomFieldIcon } from '~/areas/custom-fields/utils'
import { useParams } from 'react-router-dom'

import { SettingTableV2 } from '../../components'
import { useDeleteCustomField } from '../../hooks'

export type CustomFieldsTableProps = {
  fields: WorkspaceCustomField[]
}

export const CustomFieldsTable = ({ fields }: CustomFieldsTableProps) => {
  const { workspaceId } = useParams<{ workspaceId: string }>()
  const modalApi = useModalApi()
  const deleteField = useDeleteCustomField()

  if (!workspaceId) {
    throw new Error('No workspaceId')
  }

  return (
    <SettingTableV2
      items={fields.map((field) => ({
        id: field.id,
        label: field.name,
        icon: getCustomFieldIcon(field.type),
        onDuplicate: () => {
          modalApi.open('duplicate-custom-field', {
            workspaceId,
            customField: field,
          })
        },
        onDelete: () =>
          deleteField({
            workspaceId,
            customField: field,
          }),
        onEdit: () =>
          modalApi.open('edit-custom-field', {
            workspaceId,
            customField: field,
          }),
      }))}
    />
  )
}
