import { PopoverButton, PopoverTrigger } from '@motion/ui/base'

import { useInviteesData } from './hooks'
import {
  ShareWithDropdown,
  type ShareWithDropdownProps,
} from './share-with-dropdown'
import { type Invitee } from './types'

import { joinElements } from '../utils'

type PermissionPopoverProps = {
  invitees: Invitee[]
  placement?: 'bottom-start' | 'bottom-end'
  disabled?: boolean
} & Pick<ShareWithDropdownProps, 'onSelected' | 'onRemove'>

export function PermissionPopover({
  placement = 'bottom-start',
  invitees,
  onSelected,
  onRemove,
  disabled,
}: PermissionPopoverProps) {
  const inviteesWithData = useInviteesData(invitees)

  return (
    <PopoverTrigger
      placement={placement}
      renderPopover={() => (
        <ShareWithDropdown
          selectedInvitees={invitees}
          onSelected={onSelected}
          onRemove={onRemove}
        />
      )}
    >
      <PopoverButton
        aria-label='Share notes & recording with'
        size='small'
        id='permission-button'
        disabled={disabled}
      >
        <span className='text-semantic-neutral-text-default truncate max-w-[200px]'>
          {inviteesWithData.length === 0
            ? 'Attendees (internal only)'
            : joinElements(
                inviteesWithData.map((i) => {
                  return i.isRedacted ? (
                    <span className='line-through'>
                      (Hidden {i.type.toLowerCase()})
                    </span>
                  ) : (
                    i.data.name
                  )
                }),
                ', '
              )}
        </span>
      </PopoverButton>
    </PopoverTrigger>
  )
}
