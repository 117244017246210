import { priorityLevels } from '@motion/rpc/types'

import {
  PriorityField,
  type PriorityFieldProps,
} from '~/areas/task-project/fields'
import { useController } from 'react-hook-form'

import { useActionItemForm } from '../hooks'

export const ControlledPriorityField = () => {
  const { form } = useActionItemForm()
  const { control } = form

  const { field } = useController({
    name: 'priorityLevel',
    control,
  })

  const onChange: PriorityFieldProps['onChange'] = (priorityLevel) => {
    field.onChange(priorityLevel)
  }

  return (
    <PriorityField
      list={priorityLevels}
      value={field.value}
      onChange={onChange}
    />
  )
}
