import { PendingStatusSolid, XCircleSolid } from '@motion/icons'
import { type MeetingActionItemSchema } from '@motion/rpc-types'
import { classed } from '@motion/theme'

import { ActionItemTitle } from '~/areas/ai-hackerhouse/components/action-item-title'
import { ApproveRejectButtons } from '~/areas/ai-hackerhouse/components/approve-reject-buttons'
import { ActionItemForm } from '~/areas/ai-hackerhouse/event/modals/action-item-modal/action-item-form'
import { ConnectedActionItemModal } from '~/areas/ai-hackerhouse/event/modals/action-item-modal/action-item-modal'
import { AutoscheduleToggle } from '~/areas/ai-hackerhouse/event/modals/action-item-modal/components/autoschedule-toggle'
import {
  ControlledAssigneeField,
  ControlledWorkspaceFolderProjectField,
  DeadlineField,
  DurationField,
} from '~/areas/ai-hackerhouse/event/modals/action-item-modal/fields'
import { useTaskUpdater } from '~/areas/tasks/hooks'
import { StatusBadge } from '~/global/components/badges'
import { useWorkspaceFns, useWorkspaceStatusById } from '~/global/hooks'
import { useTaskModalUrl } from '~/global/navigation'
import { useTaskByIdV2 } from '~/global/rpc/v2'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router'

type ActionItemProps = {
  actionItem: MeetingActionItemSchema
  createMode?: boolean
}

export function ActionItem({
  actionItem,
  createMode = false,
}: ActionItemProps) {
  const buildTaskModalUrl = useTaskModalUrl()
  const navigate = useNavigate()

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const updateTask = useTaskUpdater()

  const { getWorkspaceCanceledStatus } = useWorkspaceFns()
  const { data: taskSchema, isInitialLoading: taskIsLoading } = useTaskByIdV2(
    {
      id: actionItem.taskId,
    },
    {
      enabled: actionItem.taskId != null,
    }
  )

  const task = taskSchema?.type === 'NORMAL' ? taskSchema : null

  const isRejected = actionItem.hasBeenTriaged && actionItem.taskId == null
  const notPermittedWorkspace =
    actionItem.taskId != null && !taskIsLoading && task == null

  const isAutoScheduled = task?.isAutoScheduled ?? true

  const disabled = isRejected || notPermittedWorkspace

  const taskStatus = useWorkspaceStatusById(task?.statusId)
  const isTaskCanceled =
    task != null
      ? task.statusId === getWorkspaceCanceledStatus(task.workspaceId).id
      : false

  const renderStatus = useCallback(() => {
    if (createMode) {
      return (
        <StatusBadge
          size='small'
          value={{
            name: 'Creating',
            color: 'gray',
            type: 'DEFAULT',
          }}
          hideTooltip
        />
      )
    }

    if (isRejected || isTaskCanceled) {
      return (
        <XCircleSolid className='size-3 text-semantic-neutral-icon-default' />
      )
    }

    if (actionItem.taskId != null) {
      return (
        <StatusBadge
          size='small'
          value={taskStatus}
          hideTooltip={notPermittedWorkspace}
        />
      )
    }

    return <PendingStatusSolid className='size-3' />
  }, [
    actionItem.taskId,
    createMode,
    isRejected,
    isTaskCanceled,
    notPermittedWorkspace,
    taskStatus,
  ])

  const handleOpenActionModal = () => {
    // Only open if it hasn't been triaged
    if (actionItem.hasBeenTriaged) {
      if (actionItem.taskId != null && task != null) {
        navigate(buildTaskModalUrl({ task: actionItem.taskId }))
      }

      return
    }

    setIsModalOpen(true)
  }

  const initialData = task ?? actionItem.taskData

  if (taskIsLoading) {
    return null
  }

  return (
    <ActionItemForm initialData={initialData}>
      {!createMode && (
        <ConnectedActionItemModal
          actionItem={actionItem}
          visible={isModalOpen}
          close={() => setIsModalOpen(false)}
        />
      )}
      <div className='flex flex-row justify-between items-center'>
        <div className='flex flex-col gap-2 items-start w-full'>
          <ActionItemTitle
            task={task}
            disabled={disabled}
            isRejected={isRejected}
            isTaskCanceled={isTaskCanceled}
            notPermittedWorkspace={notPermittedWorkspace}
            renderStatus={renderStatus}
            onClick={handleOpenActionModal}
            forceInputMode={createMode}
          />

          {!isRejected && (
            <div className='flex flex-row gap-0.5 ml-5 items-center h-4'>
              <span>
                <ControlledWorkspaceFolderProjectField
                  includeWorkspaceField={false}
                  includeProjectField
                  showFullPath
                  size='xxsmall'
                />
              </span>
              <Divider />
              <span>
                <DurationField showLabel={false} size='xxsmall' />
              </span>
              <Divider />
              <span>
                <DeadlineField
                  showLabel={false}
                  showLeftIcon={false}
                  showWarnButton={false}
                  allowRemoveDeadline={false}
                  sentiment='neutral'
                  size='xxsmall'
                />
              </span>
              <Divider />
              <span>
                <ControlledAssigneeField showLabel={false} size='xxsmall' />
              </span>
              {task != null && (
                <>
                  <Divider />
                  <span className='ml-0.5'>
                    <AutoscheduleToggle
                      padding='none'
                      variantOverride='off'
                      size='xsmall'
                      canToggle // task != null already checked
                      checked={isAutoScheduled}
                      onChange={(checked) => {
                        if (task == null) return
                        updateTask(task.id, {
                          isAutoScheduled: checked,
                        })
                      }}
                    />
                  </span>
                </>
              )}
            </div>
          )}
        </div>

        {!actionItem.hasBeenTriaged && (
          <ApproveRejectButtons actionItem={actionItem} />
        )}
      </div>
    </ActionItemForm>
  )
}

const Divider = classed('div', {
  base: 'h-[80%] w-px bg-semantic-neutral-border-default rounded-full self-center',
})
