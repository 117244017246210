import { defineApi, defineMutation } from '@motion/rpc'
import {
  type CreatePolicyRequestSchema,
  type CreatePolicyResponseDto,
  type ListPolicyResponseSchema,
  type PermissionPolicy,
  type UpdatePolicyRequestDto,
} from '@motion/rpc-types'

export const getAutomations = defineApi<
  {
    teamId: string
  },
  ListPolicyResponseSchema
>().using({
  key: (args) => ['automations', args.teamId],
  uri: (args) =>
    `${import.meta.env.MOTION_AI_API_HOST}/automations/list?teamId=${args.teamId}`,
})

export const createAutomationPolicy = defineMutation<
  CreatePolicyRequestSchema,
  CreatePolicyResponseDto
>().using({
  method: 'POST',
  uri: `${import.meta.env.MOTION_AI_API_HOST}/automations/create-policy`,
})

export const deleteAutomationPolicy = defineMutation<
  { policyId: string },
  void
>().using({
  method: 'DELETE',
  uri: (args) =>
    `${import.meta.env.MOTION_AI_API_HOST}/automations/${args.policyId}`,
})

export const updateAutomationPolicy = defineMutation<
  { policyId: string } & UpdatePolicyRequestDto,
  PermissionPolicy
>().using({
  method: 'PATCH',
  uri: (args) =>
    `${import.meta.env.MOTION_AI_API_HOST}/automations/${args.policyId}/update-policy`,
})
