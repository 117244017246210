import { classed } from '@motion/theme'

export const ShellVars = classed('div', {
  base: `
    contents

    setvar-[aside-width=280px]
    modal-md:setvar-[aside-width=320px]
    modal-lg:setvar-[aside-width=380px]
  `,
})

export const ModalShell = classed('section', {
  base: `
    bg-modal-bg
    rounded-lg
    shadow-lg
    text-semantic-neutral-text-default

    setvar-[modal-width=clamp(10px,calc(100vw-56px-var(--modal-offset,0px)),894px)]

    w-[var(--modal-width)]
    h-[687px]
    max-h-[100vh]
    modal-md:setvar-[modal-width=1018px] modal-md:h-[769px]
    modal-lg:setvar-[modal-width=1078px] modal-lg:h-[819px]
    modal-xl:setvar-[modal-width=1279px] modal-xl:h-[80vh] modal-xl:max-h-[1415px]
  `,
})

export const ShellActions = classed('div', {
  base: `
    absolute
    -right-[28px]
    top-0

    flex flex-col gap-4
  `,
})

export const GridShell = classed('div', {
  base: `
    h-full
    grid

    grid-rows-[1fr_min-content]
    grid-cols-[auto_var(--aside-width,320px)]
    [grid-template-areas:'chart_aside''chart_asidefooter']
    `,
})

export const ChartContainer = classed('div', {
  base: `
    [&>*]:h-full
    p-12
    [grid-area:chart]
  `,
})

export const SidebarStyles = classed('div', {
  base: `
    bg-semantic-neutral-surface-raised-bg-subtlest
    border-l border-modal-border
    rounded-r-lg
  `,
})

export const SidebarShell = classed('aside', SidebarStyles, {
  base: `
    [grid-area:aside]
    h-full overflow-auto
    flex flex-col
  `,
})

export const SidebarContent = classed('section', {
  base: `
    flex flex-col
    overflow-auto
  `,
})

export const SidebarSection = classed('div', {
  base: `
    w-full
    flex flex-col gap-0.5
    px-3 py-2.5
    modal-lg:px-5 modal-lg:gap-1.5
  `,
})

export const SidebarSectionTitle = classed('h3', {
  base: `
    text-semantic-neutral-text-default
    text-sm
    font-semibold

    flex items-center justify-between
    mb-2
  `,
})

export const SidebarFooter = classed('footer', SidebarStyles, {
  base: `
    [grid-area:asidefooter]
    flex gap-3 items-center justify-end
    rounded-br-lg

    p-3
    modal-lg:px-5
    `,
})
