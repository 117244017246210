import { type ColorId } from '@motion/ui-logic/calendar'

import { type ReactNode } from 'react'

import {
  getColorFromColorId,
  getColorHueValue,
  GRAY_HUE,
  PaletteProvider,
} from '../../palette'

export interface CalendarPalette {
  colorId?: ColorId
  colorHue?: number
}

interface CalendarPaletteProps extends CalendarPalette {
  children: ReactNode
}

export function CalendarPalette({
  children,
  colorId,
  colorHue,
}: CalendarPaletteProps) {
  if (colorId == null && colorHue == null) {
    throw new Error('CalendarPalette requires at least a colorId or a colorHue')
  }

  const hue = colorId
    ? getColorHueValue(getColorFromColorId(colorId))
    : (colorHue ?? GRAY_HUE)
  return <PaletteProvider colorHue={hue}>{children}</PaletteProvider>
}
