import { type StageDefinitionSchema } from '@motion/rpc-types'
import { type RelativeIntervalReferenceType } from '@motion/shared/flows'

import { StageLabel } from '~/global/components/labels'

import { mapRelativeIntervalReferenceTypeToReadable } from './utils'

export type RelativeDateLabelProps = {
  color: StageDefinitionSchema['color']
  size?: 'xsmall' | 'small'
  value: RelativeIntervalReferenceType
}

export const RelativeDateLabel = ({
  color,
  size,
  value,
}: RelativeDateLabelProps) => {
  return (
    <StageLabel
      value={{
        name: mapRelativeIntervalReferenceTypeToReadable(value),
        color: color ?? 'violet',
      }}
      size={size}
      variant={value === 'STAGE_DUE' ? 'stageDueDeadline' : undefined}
    />
  )
}
