import { useActiveFilter } from '@motion/ui-logic/pm/data'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useHasTreatment } from '@motion/web-common/flags'

import { useNavigateByRouteId } from '~/routing'

import { PageSelector } from '../header/page-selector'
import { usePageData } from '../routes/hooks/use-v3-page-data'
import { useViewState } from '../view-state'

export const ConnectedPageSelector = () => {
  const [viewState, setViewState] = useViewState()
  const [filterState, setFilters] = useActiveFilter()
  const routesV4 = useHasTreatment('saved-views-as-tabs')
  const pageData = usePageData()
  const navigate = useNavigateByRouteId()

  return (
    <PageSelector
      value={viewState.page}
      onChange={(value) => {
        recordAnalyticsEvent('PM_PAGE_VIEW', {
          context: pageData.page,
          layout: viewState.page,
        })

        if (routesV4) {
          if (value === 'gantt' && filterState.target === 'tasks') {
            setFilters((prev) => ({ ...prev, target: 'projects' }))
          }

          // @ts-expect-error - dashboard is page type
          setViewState((prev) => {
            return {
              ...prev,
              page: value,
            }
          })

          return
        }

        if (value === 'gantt') {
          // Only allow project groupings in Gantt
          navigate('parent', { type: 'projects', variant: value })
          return
        }

        navigate('parent', { variant: value })
      }}
    />
  )
}
