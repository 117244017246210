import { Button, Tooltip } from '@motion/ui/base'

import { useEventModalState } from '~/areas/ai-hackerhouse/event/modals/event-modal/contexts'
import { useSendBotNow } from '~/areas/ai-hackerhouse/hooks/rpc/use-send-bot-now'

import { useEventForm } from '../hooks'

export function ConnectedSendBotNowButton() {
  const { form } = useEventForm()
  const { meetingInsights, isMeetingInsightsCompleted } = useEventModalState()
  const {
    watch,
    formState: { isDirty },
  } = form

  const { mutateAsync: sendBotNow } = useSendBotNow()
  const conferenceLink = watch('conferenceLink')

  if (
    isDirty ||
    meetingInsights == null ||
    conferenceLink == null ||
    isMeetingInsightsCompleted ||
    meetingInsights.meetingBotStatus === 'DONT_SCHEDULE'
  )
    return null

  async function handleSendBotNow() {
    if (meetingInsights == null) return
    await sendBotNow({
      meetingInsightsId: meetingInsights.id,
    })
  }

  return (
    <Tooltip content='Your bot will join the meeting immediately, regardless of when your meeting is scheduled'>
      <Button
        sentiment='neutral'
        variant='mutedBg'
        size='xsmall'
        onClick={handleSendBotNow}
      >
        Add to event now
      </Button>
    </Tooltip>
  )
}
