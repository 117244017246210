import { NumberChart } from '@motion/web-charts/number'

import { useCallback } from 'react'

import { ChartQueryDataProvider } from './chart-query-data-provider'
import { type ConnectedChartProps } from './types'
import { useValueFormatter } from './use-value-formatter'

type ConnectedNumberChartProps = ConnectedChartProps<'number'>

export const ConnectedNumberChart = (props: ConnectedNumberChartProps) => {
  const baseFormatter = useValueFormatter(props.chart)
  const valueFormatter = useCallback(
    (value: number | null | undefined) => {
      if (value == null) return '-'
      if (Number.isNaN(value)) return '-'

      if (!Number.isFinite(value)) return '∞'
      return baseFormatter(value)
    },
    [baseFormatter]
  )

  return (
    <ChartQueryDataProvider chart={props.chart}>
      {(chartQueryData) => {
        const value = chartQueryData.data[0]?.value
        const color = props.chart.color?.value ?? null

        return (
          <NumberChart
            position='bottom'
            value={value}
            color={color}
            valueFormatter={valueFormatter}
            title={props.title}
          />
        )
      }}
    </ChartQueryDataProvider>
  )
}
