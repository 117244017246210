import { useSharedStateQuery } from '@motion/react-core/shared-state'

import { CalendarsStateKey } from '~/areas/calendar/hooks'
import { useFullCalendarStructuredEvents } from '~/areas/calendar/main-calendar/hooks'

import { useAgendaCalendarState } from './use-agenda-calendar-state'
import { useAgendaDayEntities } from './use-agenda-day-entities'
import { useAgendaState } from './use-agenda-state'

/*
  Grabs the events from state and returns them in a format that FullCalendar can understand.
*/
export const useAgendaFcEvents = () => {
  // We're using calendar state only to create new events
  // since the components for event creation are shared between calendar and agenda

  const newEventData = useSharedStateQuery(
    CalendarsStateKey,
    (data) => data.selectedCalendarEvent?.new
  )
  const [agendaCalendarState] = useAgendaCalendarState()
  const [agendaState] = useAgendaState()

  const { data } = useAgendaDayEntities(agendaCalendarState.selectedDate)

  return useFullCalendarStructuredEvents({
    newEventData,
    scheduledEntities: data?.scheduledEntities,
    selectedDate: agendaState.selectedDate,
  })
}
