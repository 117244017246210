import { CalendarSolid } from '@motion/icons'

import { DeadlineToggle } from '~/areas/project-management/components'
import { TaskDeadlineDropdown } from '~/areas/tasks/components'
import { useTaskDeadlineData } from '~/areas/tasks/hooks'
import { type TaskWithRelations } from '~/global/proxies'

export type TaskLineDeadlineItemProps = {
  task: TaskWithRelations
}

export function TaskLineDeadlineItem({ task }: TaskLineDeadlineItemProps) {
  const {
    onChange,
    onChangeDeadlineType,
    disabled,
    formattedValue,
    isValidDateOption,
  } = useTaskDeadlineData(task)

  return (
    <TaskDeadlineDropdown
      contextProps={{ taskId: task.id }}
      value={task.dueDate}
      onChange={onChange}
      isValidDateOption={isValidDateOption}
      renderCalendarFooter={() => (
        <DeadlineToggle
          onChange={onChangeDeadlineType}
          deadlineType={task.deadlineType}
        />
      )}
    >
      <button type='button' disabled={disabled}>
        <div className='flex gap-1 items-center overflow-hidden leading-4 text-xs'>
          <CalendarSolid className='text-semantic-neutral-icon-subtle size-3' />
          <div className='truncate'>{formattedValue}</div>
        </div>
      </button>
    </TaskDeadlineDropdown>
  )
}
