import { HoverCard, LoadingSpinner } from '@motion/ui/base'
import { useOnMountAnalyticsEvent } from '@motion/web-base/analytics'

import { useBlockerTasks } from '~/areas/tasks/hooks'
import { useFilterActiveTasks } from '~/areas/tasks/hooks/helpers'
import { type ReactNode } from 'react'

import { CalendarBlockerTaskItem } from './calendar-blocker-task-item'
import { CalendarBlockersHeader } from './calendar-blockers-header'

type CalendarBlockersHoverCardProps = {
  taskId: string
  children: ReactNode
  disabled?: boolean
}

export const CalendarBlockersHoverCard = ({
  taskId,
  children,
  disabled = false,
}: CalendarBlockersHoverCardProps) => {
  if (disabled) return children
  return (
    <HoverCard
      placement='bottom-start'
      renderContent={() => <CalendarBlockersHoverCardContent taskId={taskId} />}
    >
      {children}
    </HoverCard>
  )
}

const CalendarBlockersHoverCardContent = ({
  taskId,
}: Pick<CalendarBlockersHoverCardProps, 'taskId'>) => {
  useOnMountAnalyticsEvent('CALENDAR_SHOW_BLOCKERS_HOVER_CARD')
  const { blockedBy, blocking, isLoading } = useBlockerTasks({
    taskId,
  })

  const activeBlockers = useFilterActiveTasks(blockedBy)

  if (isLoading) {
    return (
      <div className='min-w-60 py-4 flex justify-center'>
        <LoadingSpinner size={16} />
      </div>
    )
  }

  const ready = activeBlockers.length === 0

  return (
    <div className='min-w-60'>
      {blockedBy.length > 0 && (
        <>
          {ready ? (
            <CalendarBlockersHeader variant='ready' tagText='Ready to start'>
              All blockers are completed ({blockedBy.length})
            </CalendarBlockersHeader>
          ) : (
            <CalendarBlockersHeader variant='blockedBy' tagText='Not ready'>
              This task is blocked by ({blockedBy.length})
            </CalendarBlockersHeader>
          )}
          <div className='p-1'>
            {blockedBy.map((task) => (
              <CalendarBlockerTaskItem task={task} key={`blocker-${task.id}`} />
            ))}
          </div>
        </>
      )}
      {blocking.length > 0 && (
        <>
          <CalendarBlockersHeader variant='blocking'>
            This task blocks ({blocking.length})
          </CalendarBlockersHeader>
          <div className='p-1'>
            {blocking.map((task) => (
              <CalendarBlockerTaskItem
                task={task}
                key={`blocking-${task.id}`}
              />
            ))}
          </div>
        </>
      )}
    </div>
  )
}
