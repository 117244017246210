import { type StageDefinitionSchema } from '@motion/rpc-types'
import { type RelativeIntervalReferenceType } from '@motion/shared/flows'
import { PopoverButton, SearchableDropdown } from '@motion/ui/base'
import { type TaskDefinitionFormRelativeInterval } from '@motion/ui-logic/pm/project'

import { StageBadge } from '~/global/components/badges'
import { useCallback } from 'react'

import { type RelativeDateDropdownProps } from './relative-date-dropdown'
import { mapRelativeIntervalReferenceTypeToReadable } from './utils'

export type RelativeIntervalReferenceTypeDropdownProps = {
  relativeInterval: TaskDefinitionFormRelativeInterval
  color: StageDefinitionSchema['color']
  onChange: RelativeDateDropdownProps['onChange']
}

export function RelativeIntervalReferenceTypeDropdown({
  color,
  relativeInterval,
  onChange,
}: RelativeIntervalReferenceTypeDropdownProps) {
  const onChangeHandler = useCallback(
    (newReferenceType: RelativeIntervalReferenceType) => {
      onChange((newRelativeInterval: TaskDefinitionFormRelativeInterval) => ({
        ...newRelativeInterval,
        referenceType: newReferenceType,
        duration: {
          ...newRelativeInterval.duration,
          sign: newReferenceType === 'STAGE_DUE' ? -1 : 1,
        },
      }))
    },
    [onChange]
  )

  const { referenceType } = relativeInterval

  return (
    <SearchableDropdown
      items={['STAGE_START', 'STAGE_DUE']}
      selectedItem={referenceType}
      onChange={onChangeHandler}
      renderItem={(item) => (
        <RelativeIntervalReferenceTypeOption
          color={color}
          referenceType={item}
        />
      )}
    >
      <PopoverButton>
        <RelativeIntervalReferenceTypeOption
          color={color}
          referenceType={referenceType}
        />
      </PopoverButton>
    </SearchableDropdown>
  )
}

type RelativeIntervalReferenceTypeOptionProps = {
  color: StageDefinitionSchema['color']
  referenceType: RelativeIntervalReferenceType
}

const RelativeIntervalReferenceTypeOption = ({
  color,
  referenceType,
}: RelativeIntervalReferenceTypeOptionProps) => {
  const name = mapRelativeIntervalReferenceTypeToReadable(referenceType)

  const label =
    referenceType === 'MEETING_TASK' ? name : 'Stage ' + name?.toLowerCase()

  return (
    <div className='flex gap-1.5 text-nowrap'>
      <StageBadge
        value={{ name, color }}
        hideTooltip
        variant={referenceType === 'STAGE_DUE' ? 'stageDueDeadline' : undefined}
      />

      {label}
    </div>
  )
}
