import { StarCrossOutOutline } from '@motion/icons'
import { ButtonGroup, IconButton, Tooltip } from '@motion/ui/base'

import { useRemoveFavorite } from '~/areas/folders/hooks/use-remove-favorite'
import { useMemo } from 'react'
import { useMatch } from 'react-router-dom'

import { HIGHLIGHTABLE_DATA_ATTRIBUTE } from '../../../constants'
import { useGlobalSidebarContext } from '../../../hooks'
import {
  type DragProjection,
  SortableTreeviewItem,
  type TreeviewItemProps,
} from '../../components/sortable-treeview'
import { type SortableFavoriteItem } from '../types'

export type ConnectedFavoritesTreeviewItemProps = {
  item: SortableFavoriteItem
  projection: DragProjection<SortableFavoriteItem> | null
} & Pick<TreeviewItemProps, 'disableDrag' | 'isGhost'>

export const ConnectedFavoritesTreeviewItem = ({
  item,
  projection,
  disableDrag,
  isGhost,
}: ConnectedFavoritesTreeviewItemProps) => {
  const removeFavorite = useRemoveFavorite()

  const { id, label, url, icon: Icon, tooltip } = item
  const { highlightedId } = useGlobalSidebarContext()
  const isActive = Boolean(useMatch(url))
  const sortableData = useMemo(() => ({ item }), [item])

  const renderButtons = () => (
    <ButtonGroup size='small' nowrap>
      <Tooltip content='Remove from favorites' asChild>
        <IconButton
          icon={StarCrossOutOutline}
          size='xsmall'
          sentiment='neutral'
          variant='muted'
          onClick={() => {
            void removeFavorite(id)
          }}
        />
      </Tooltip>
    </ButtonGroup>
  )

  return (
    <Tooltip content={tooltip} placement='right'>
      <SortableTreeviewItem
        attributes={{
          [HIGHLIGHTABLE_DATA_ATTRIBUTE]: id,
        }}
        disableDrag={disableDrag}
        icon={Icon}
        id={id}
        isActive={isActive}
        isGhost={isGhost}
        isHighlighted={highlightedId === item.id}
        label={label}
        level={0}
        order={(isGhost && projection
          ? projection.order
          : item.order
        ).toString()}
        renderButtons={renderButtons}
        sortableData={sortableData}
        url={url}
      />
    </Tooltip>
  )
}
