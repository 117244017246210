import { type TaskSchema } from '@motion/rpc-types'

import {
  type AttachmentGroup,
  AttachmentGroups,
} from '~/areas/attachments/components'
import { useGetProjectTaskAttachments } from '~/areas/attachments/hooks'
import { type ModalTriggerComponentProps } from '~/areas/modals'
import { useTaskByIdV2 } from '~/global/rpc/v2'
import { type Dispatch, type SetStateAction, useMemo } from 'react'

import { AttachmentsModalContent } from './attachments-modal-content'

export type AttachmentsModalTaskContentProps = Pick<
  ModalTriggerComponentProps<'attachments'>,
  'close'
> & {
  taskId: TaskSchema['id']
  searchQuery: string
  setSearchQuery: Dispatch<SetStateAction<string>>
}

export function AttachmentsModalTaskContent({
  close,
  taskId,
  searchQuery,
  setSearchQuery,
}: AttachmentsModalTaskContentProps) {
  const { data: task } = useTaskByIdV2({ id: taskId })
  const taskAttachments = useGetProjectTaskAttachments({
    targetId: taskId,
    targetType: 'TEAM_TASK',
  })

  const parsedAttachments = useMemo(
    () => taskAttachments.map((attachment) => attachment.id),
    [taskAttachments]
  )

  const groups = useMemo(
    () =>
      [
        task && {
          targetId: task.id,
          targetType: 'TEAM_TASK',
          workspaceId: task.workspaceId,
          uploadedFileIds: parsedAttachments,
        },
      ].filter(Boolean) as AttachmentGroup[],
    [task, parsedAttachments]
  )

  if (!task || task.type !== 'NORMAL') return null

  const attachmentCount = parsedAttachments.length

  return (
    <AttachmentsModalContent
      close={close}
      targetId={taskId}
      targetType='TEAM_TASK'
      workspaceId={task.workspaceId}
      attachmentCount={attachmentCount}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
    >
      <AttachmentGroups
        groups={groups}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      />
    </AttachmentsModalContent>
  )
}
