import {
  byProperty,
  byValue,
  cascade,
  Compare,
  descending,
  ordered,
} from '@motion/utils/array'
import { type Sortable } from '@motion/web-charts/common'
import {
  type DashboardViewGroupBySchema,
  PriorityLevelSchema,
} from '@motion/zod/client'

import { type LookupFn } from '~/global/cache'

const NOOP_SORT = (l: Sortable, r: Sortable) => 0

export function createGroupBySort(
  group: DashboardViewGroupBySchema,
  lookup: LookupFn
) {
  if (group.sort == null) {
    return NOOP_SORT
  }

  if (group.sort.direction === 'custom') {
    return byProperty<Sortable, 'category'>(
      'category',
      ordered<string | null>(group.sort.order)
    )
  }

  if (group.sort.source === 'name') {
    const categorySort = getCategorySort(group.field, lookup)
    return byProperty<Sortable, 'category'>(
      'category',
      group.sort.direction === 'asc' ? categorySort : descending(categorySort)
    )
  }

  return byProperty<Sortable, 'value'>(
    'value',
    Compare.numeric[group.sort.direction]
  )
}

const SORT_LOOKUP_MAP = {
  statusId: 'statuses',
  projectId: 'projects',
  workspaceId: 'workspaces',
  stageDefinitionId: 'stageDefinitions',
  activeStageDefinitionId: 'stageDefinitions',
  projectDefinitionId: 'projectDefinitions',
  assigneeUserId: 'users',
  folderId: 'folders',
} as const

const DEFAULT_SORT = cascade(
  ordered<string | null>([null]),
  Compare.string.with({ numeric: true })
)

function getCategorySort(
  field: DashboardViewGroupBySchema['field'],
  lookup: LookupFn
): (l: Sortable['category'], r: Sortable['category']) => number {
  if (field === 'priorityLevel') {
    return ordered<string | null>(PriorityLevelSchema)
  }
  if (field === 'isAutoScheduled') {
    return byValue((x: string | null) => String(x), ordered(['true', 'false']))
  }

  const lookupType = SORT_LOOKUP_MAP[field as keyof typeof SORT_LOOKUP_MAP]
  if (lookupType != null) {
    return byValue((x) => lookup(lookupType, x)?.name ?? x, Compare.string)
  }

  return DEFAULT_SORT
}
