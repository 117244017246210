import { useOnValueChange } from '@motion/react-core/hooks'
import { Button, UnstyledModal } from '@motion/ui/base'
import { INDIVIDUAL_PRICES, Term } from '@motion/ui-logic/billing'
import {
  getSegmentAnalytics,
  getTrackingCookies,
} from '@motion/web-base/analytics'
import { isMobileExperience } from '@motion/web-base/env'
import { Sentry } from '@motion/web-base/sentry'
import {
  BillingFeatures,
  BillingPaymentPage,
  SubmitButton,
} from '@motion/web-billing'
import { useExperiment } from '@motion/web-common/flags'
import { useRefreshSubscription } from '@motion/web-common/subscriptions'

import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import {
  type StripeErrorType,
  type StripePaymentElementChangeEvent,
} from '@stripe/stripe-js'
import { LoadingPage } from '~/areas/global'
import logo from '~/images/logo_1024.png'
import logoName from '~/images/motion_logo.svg'
import MD5 from 'crypto-js/md5'
import { useEffect, useState } from 'react'

import { CheckoutSurvey } from './checkout-survey'

import { logEvent } from '../../analytics'
import { useTrialLength } from '../../experiments'
import { getCloudRunUrl, useOnPurchaseRedirect } from '../../utils'
import { valueMap } from '../constants'
import { useGetTeamPricesUnauthorized } from '../hooks'

const isMobile = isMobileExperience()

interface PaymentFormNewProps {
  userEmail: string
  signOutEmail: () => void
  isResubscribe: boolean
  outerError: string | null
  updateClientSecret: (secret: string, error: string) => void
  isTeam: boolean
  noCcB2bEnabled: boolean
  onBack?: () => void
}

export const PaymentForm = ({
  userEmail,
  signOutEmail,
  isResubscribe,
  outerError,
  updateClientSecret,
  isTeam,
  noCcB2bEnabled,
  onBack,
}: PaymentFormNewProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isPaymentFilledOut, setIsPaymentFilledOut] = useState(false)
  const [error, setError] = useState<string | null>(outerError)
  const [showSurvey, setShowSurvey] = useState(false)
  // We want to default to annual unless we are running the noCcB2bEnabled experiment
  const [term, setTerm] = useState<Term>(
    noCcB2bEnabled ? Term.Monthly : Term.Annual
  )
  const isMonthly = term === Term.Monthly
  const isLowCostTrial = term === Term.LowCostTrial
  const [hasSelectedCard, setHasSelectedCard] = useState(true)
  const stripe = useStripe()
  const elements = useElements()
  const [bucketSeats, setBucketSeats] = useState(5)
  const [showAlreadyPurchasedModal, setShowAlreadyPurchasedModal] =
    useState(false)

  const maximizeAnnualB2cEligible = !isTeam && !isResubscribe
  const maximizeAnnualB2cPayload = useExperiment('maximize-annual-b2c', {
    track: maximizeAnnualB2cEligible,
  }).payload
  const lowCostTrialPriceId = maximizeAnnualB2cEligible
    ? maximizeAnnualB2cPayload?.priceId
    : undefined

  const trialFFLength = useTrialLength()
  const annualTrialLength = isResubscribe ? 0 : trialFFLength
  const monthlyTrialLength =
    isResubscribe || lowCostTrialPriceId ? 0 : trialFFLength
  const chosenTrialLength =
    term === Term.Annual
      ? annualTrialLength
      : term === Term.Monthly
        ? monthlyTrialLength
        : 0

  const [refreshingSubscription, setRefreshingSubscription] = useState(false)
  const refreshSubscription = useRefreshSubscription()

  const { data: teamPrices } = useGetTeamPricesUnauthorized({
    email: userEmail,
  })

  const [redirectStatus, setRedirectStatus] = useState<
    'not_ready' | 'ready' | 'redirecting'
  >('not_ready')
  const onPurchaseRedirect = useOnPurchaseRedirect()

  useEffect(function logAnalytics() {
    getSegmentAnalytics()?.track('add_to_cart', {
      category: 'checkout',
      url: window.location.href,
    })
  }, [])

  useEffect(() => {
    if (redirectStatus === 'ready' && !refreshingSubscription) {
      setRedirectStatus('redirecting')
      onPurchaseRedirect()
    }
  }, [redirectStatus, refreshingSubscription, onPurchaseRedirect])

  const logPurchaseEvent = (subscriptionId: string) => {
    try {
      const cookieData = getTrackingCookies()
      const data = {
        category: 'checkout',
        label: isMonthly ? 'motion_monthly' : 'motion_annual',
        value: isMonthly ? valueMap.motion_monthly : valueMap.motion_annual,
        trialLength: chosenTrialLength,
        ...(isLowCostTrial && lowCostTrialPriceId && { lowCostTrialPriceId }),
        currency: 'usd',
        action_source: 'website',
        url: window.location.href,
        customer_id: MD5(userEmail!).toString(),
        subscription_id: subscriptionId,
        messageId: `${subscriptionId}-trialStart`,
        isTeam: isTeam,
        seats: isTeam ? bucketSeats : undefined,
        ...cookieData,
      }
      // doesn't have name because it's a email-only signup flow
      const traitData = {
        traits: {
          email: userEmail,
        },
      }
      if (!isResubscribe) {
        // maps to Segment StartTrial event
        getSegmentAnalytics()?.track('success_purchase', data, traitData)
        // maps to Segment Purchase event
        getSegmentAnalytics()?.track('success_purchase_new', data, traitData)
        if (!hasSelectedCard) {
          logEvent('TRIAL_START_WALLET')
        }
      } else {
        getSegmentAnalytics()?.track(
          'success_purchase_resubscribe',
          data,
          traitData
        )
      }
    } catch (e) {
      Sentry.captureException(e, { tags: { position: 'logPurchaseEvent' } })
    }
  }

  const handlePaymentElementChange = (
    event: StripePaymentElementChangeEvent
  ) => {
    setHasSelectedCard(event.value.type === 'card')
    setIsPaymentFilledOut(event.complete)
  }

  const completeHandler = (
    newPurchase: boolean,
    shouldLog: boolean,
    subscriptionId: string
  ) => {
    if (shouldLog) {
      logPurchaseEvent(subscriptionId)
    }
    if (newPurchase) {
      setShowSurvey(true)
    } else {
      setRedirectStatus('ready')
    }
  }

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return
    }
    setIsSubmitting(true)

    try {
      const payload = await stripe.confirmPayment({
        elements,
        redirect: 'if_required',
      })

      if (payload.error) {
        setIsSubmitting(false)
        setError(`Error: ${payload.error.message}`)

        logEvent('ERROR_STRIPE_PAYMENT_PAYLOAD', {
          error: payload.error,
        })
        const filterOutErrorTypes: StripeErrorType[] = [
          'validation_error',
          'rate_limit_error',
          'card_error',
        ]
        // Filter out these errors, as they are not actionable
        if (
          !filterOutErrorTypes.some((type) => payload.error.type.includes(type))
        ) {
          Sentry.captureException(
            new Error('Payload error from stripe confirmPayment.', {
              cause: payload.error,
            }),
            {
              tags: { position: 'confirmPayment' },
              extra: { payload },
            }
          )
        }
      } else {
        setError(null)
        await createSubscription(
          payload.paymentIntent!.payment_method! as string
        )
      }
    } catch (e) {
      Sentry.captureException(e, { tags: { position: 'handleSubmit' } })
    }
  }

  const createSubscription = (paymentMethodId?: string) => {
    const cookieData = getTrackingCookies()
    return fetch(getCloudRunUrl('users/deprecated/subscription/unauthorized'), {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        paymentMethodId,
        label: isMonthly ? 'motion_monthly' : 'motion_annual',
        email: userEmail,
        // For BE creation, the low cost trials need to be treated as monthly.
        // But for all other purposes here (FE copy, analytics) they should be
        // treated as annual.
        isMonthly: isLowCostTrial ? true : isMonthly,
        ...(isLowCostTrial && lowCostTrialPriceId
          ? { lowCostTrialPriceId }
          : { trialLength: chosenTrialLength }),
        ps_xid: cookieData.ps_xid,
        seats: isTeam ? bucketSeats : undefined,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.newClientSecret) {
          updateClientSecret(result.newClientSecret, result.message)
        }
        if (result.error) {
          throw result.error
        }
        return result
      })
      .then((result) => {
        return { paymentMethodId, subscription: result.subscription }
      })
      .then(onSubscriptionComplete)
      .catch((error) => {
        if (error.message === 'You already purchased this item!') {
          setShowAlreadyPurchasedModal(true)
        } else {
          setError(error.message)
          getSegmentAnalytics()?.track('PAYMENT_ERROR', { error })
          Sentry.captureException(error, {
            tags: { position: 'createSubscription' },
          })
        }
      })
      .finally(() => setTimeout(() => setIsSubmitting(false), 1500))
  }

  const onSubscriptionComplete = ({
    subscription,
    paymentMethodId,
  }: {
    subscription: any
    paymentMethodId?: string
  }) => {
    if (
      subscription &&
      (subscription.status === 'active' || subscription.status === 'trialing')
    ) {
      setRefreshingSubscription(true)
      void refreshSubscription
        .mutateAsync()
        .finally(() => setRefreshingSubscription(false))

      // Don't log segment analytics if the user has no payment method and no-cc-b2b-everywhere enabled
      const shouldLog =
        (lowCostTrialPriceId && isLowCostTrial) ||
        (noCcB2bEnabled ? !!paymentMethodId : true)

      completeHandler(!isResubscribe, shouldLog, subscription.id)
    }
  }

  useOnValueChange(
    noCcB2bEnabled,
    () => {
      if (noCcB2bEnabled) {
        createSubscription()
      }
    },
    { triggerOnFirstRender: true }
  )

  if (noCcB2bEnabled) {
    if (showSurvey) {
      return <CheckoutSurvey onComplete={() => setRedirectStatus('ready')} />
    }

    return <LoadingPage id='refresh-sub-and-redirect' />
  }

  if (redirectStatus !== 'not_ready' || !teamPrices) {
    return <LoadingPage id='refresh-sub-and-redirect' />
  }

  const teamProps = isTeam
    ? { isTeam, setBucket: setBucketSeats, bucket: bucketSeats, teamPrices }
    : { isTeam }

  const prices = isTeam ? teamProps.teamPrices : INDIVIDUAL_PRICES
  const price = isMonthly ? prices?.monthlyPrice : prices?.annualPrice

  return (
    <div className='bg-wlight-500 flex h-screen w-screen flex-col items-center gap-y-6 overflow-y-scroll py-6 px-3'>
      <div
        className={`w-full max-w-screen-xl flex flex-row items-center justify-between ${
          showSurvey ? 'blur-sm' : 'blur-none'
        }`}
      >
        <div className='flex gap-x-3'>
          <img src={logo} className='h-10 w-10' alt='Motion logo' />
          <img className='fill-white' src={logoName} alt='Motion name' />
        </div>
        <a
          href='https://help.usemotion.com'
          target='_blank'
          rel='noreferrer'
          className='text-wlight-1000 m-0 p-0 text-sm font-semibold'
        >
          Contact Support
        </a>
      </div>
      <div
        className={`flex w-full max-w-screen-xl flex-col lg:flex-row ${
          showSurvey ? 'blur-sm' : 'blur-none'
        }`}
      >
        <BillingPaymentPage
          paymentElement={
            <PaymentElement onChange={handlePaymentElementChange} />
          }
          lowCostTrialPriceId={lowCostTrialPriceId}
          annualTrialLength={annualTrialLength}
          monthlyTrialLength={monthlyTrialLength}
          chosenTrialLength={chosenTrialLength}
          term={term}
          setTerm={setTerm}
          isMobile={isMobile}
          userEmail={userEmail}
          onChangeEmail={signOutEmail}
          hasSelectedCard={hasSelectedCard}
          error={error}
          isPaymentFilledOut={isPaymentFilledOut}
          isSubmitting={isSubmitting}
          handleSubmit={handleSubmit}
          onBack={onBack}
          {...teamProps}
        />
        <BillingFeatures
          allowLowCostTrial={!!lowCostTrialPriceId}
          term={term}
          price={price}
          trialLength={chosenTrialLength}
        />
      </div>
      {isMobile && (
        <div className='mt-2 self-center'>
          <SubmitButton
            disabled={!isPaymentFilledOut}
            loading={isSubmitting}
            onClick={handleSubmit}
            trialLength={chosenTrialLength}
            isLowCostTrial={isLowCostTrial}
          />
        </div>
      )}
      {showSurvey && (
        <CheckoutSurvey
          onComplete={(result) => {
            getSegmentAnalytics()?.track('checkout_survey', {
              value: result,
              ...getTrackingCookies(),
            })
            // delayed so logging can process
            setTimeout(() => setRedirectStatus('ready'), 1500)
          }}
        />
      )}
      {showAlreadyPurchasedModal && (
        <AlreadyPurchasedModal
          onClose={() => {
            setShowAlreadyPurchasedModal(false)
            completeHandler(false, false, '')
          }}
        />
      )}
    </div>
  )
}

type AlreadyPurchasedModalProps = {
  onClose: () => void
}

export const AlreadyPurchasedModal = ({
  onClose,
}: AlreadyPurchasedModalProps) => {
  return (
    <UnstyledModal
      visible
      onClose={onClose}
      overlayClassName='bg-modal-overlay'
      disableOverlayClick
    >
      <div className='flex flex-col gap-4 bg-modal-bg p-4 rounded-lg w-full max-w-xs'>
        <div className='font-semibold'>You already have a subscription</div>
        <div className='text-sm text-semantic-neutral-text-muted'>
          Please check your email for instructions on how to set up your
          account.
        </div>
        <Button onClick={onClose}>Continue</Button>
      </div>
    </UnstyledModal>
  )
}
