import { useDependantState } from '@motion/react-core/hooks'
import { Sentry } from '@motion/web-base/sentry'

import { useUserFavorites } from '~/areas/folders/hooks'
import { useUpdateItemInFolder } from '~/global/rpc/folders'
import { showErrorToast } from '~/global/toasts'

import { ConnectedFavoritesTreeviewItem } from './components/connected-favorites-treeview-item'
import { type SortableFavoriteItem } from './types'

import { SortableTreeview } from '../components/sortable-treeview'

type FavoritesTreeviewProps = {
  items: SortableFavoriteItem[]
  disableDrag?: boolean
}

export const FavoritesTreeview = ({
  items: ogItems,
  disableDrag = false,
}: FavoritesTreeviewProps) => {
  const { mutateAsync: updateItemInFolder } = useUpdateItemInFolder()
  const favorites = useUserFavorites()

  const [items, setOptimisticItems] = useDependantState(
    () => ogItems,
    [ogItems]
  )

  return (
    <SortableTreeview
      items={items}
      renderItem={({ item, projection }) => (
        <ConnectedFavoritesTreeviewItem
          item={item}
          projection={projection}
          disableDrag={disableDrag}
        />
      )}
      renderGhostItem={({ item, projection }) => (
        <ConnectedFavoritesTreeviewItem
          item={item}
          projection={projection}
          disableDrag
          isGhost
        />
      )}
      calculateMinimumLevel={() => 0}
      calculateMaximumLevel={() => 0}
      onDragEnd={async ({ active, projection }) => {
        const activeId = active.id
        const { order, newItems, hasMoved } = projection

        if (!hasMoved) return

        if (!favorites.folder) {
          return void Sentry.captureException(
            new Error('Could not find user favorites folder'),
            {
              extra: {
                activeId,
                projection,
              },
              tags: {
                position: 'FavoritesTreeview',
              },
            }
          )
        }

        const prevItems = [...items]

        setOptimisticItems(newItems)

        const args = {
          parentFolderId: favorites.folder.id,
          itemId: activeId.toString(),
          order: order.toString(),
        }

        try {
          await updateItemInFolder(args)
        } catch (e) {
          Sentry.captureException(e, {
            tags: {
              position: 'FavoritesTreeview',
            },
            extra: {
              ...args,
            },
          })

          setOptimisticItems(prevItems)
          showErrorToast(e, 'There was a problem reordering your favorites')
        }
      }}
    />
  )
}
