import { isProjectWithDates } from '@motion/ui-logic'
import { type StageArg } from '@motion/ui-logic/pm/project'
import { type ProjectSchema } from '@motion/zod/client'

import {
  getStageDateRangeColors,
  type StageDateRangeColorData,
} from '~/areas/flows'
import { useLegacyStageDefinitionsByProjectDefinitionId } from '~/global/hooks'

export type ProjectDatesContextData = {
  stageDateRangeColors: StageDateRangeColorData[]
}

type ProjectInput = Pick<
  ProjectSchema,
  'startDate' | 'dueDate' | 'projectDefinitionId'
> & { stages: StageArg[] }

export function useProjectDatesContextData(
  project: ProjectInput | null | undefined
): ProjectDatesContextData {
  const stagesDefinitions = useLegacyStageDefinitionsByProjectDefinitionId(
    project?.projectDefinitionId
  )

  if (!isProjectWithDates(project)) {
    return {
      stageDateRangeColors: [],
    }
  }

  const stageDateRangeColors: StageDateRangeColorData[] =
    getStageDateRangeColors(project, stagesDefinitions)

  return {
    stageDateRangeColors,
  }
}
