import { formatMonthDay } from '@motion/ui-logic'
import { isDateBetweenDates, safeParseDate } from '@motion/utils/dates'
import { type ProjectSchema, type StageSchema } from '@motion/zod/client'

import { DateTime } from 'luxon'
import { type FC } from 'react'

import { ConnectedStageConnector } from './stage-connector'
import { StageTimelineSegment } from './stage-timeline-segment'
import {
  ProjectStartDot,
  ProjectStartLabel,
  StageTimelineContainer,
  StageTimelineInnerContainer,
} from './styled'
import { groupStagesByDueDateAndFilterSkipped } from './utils'

type StageTimelineProps = {
  stages: StageSchema[]
  project: ProjectSchema
}

const MAX_STAGE_GROUPS_WITHOUT_SCROLL = 6
const STAGE_WIDTH = 55

export const StageTimeline: FC<StageTimelineProps> = ({ stages, project }) => {
  const groupedStages = groupStagesByDueDateAndFilterSkipped(stages)
  if (groupedStages.length === 0) return null

  const needsScroll = groupedStages.length > MAX_STAGE_GROUPS_WITHOUT_SCROLL
  const projectStartDate = safeParseDate(project.startDate ?? '')

  const today = DateTime.now().startOf('day')
  const firstStageDate = safeParseDate(groupedStages[0].dueDateKey)
  const isTodayBeforeFirstStage = firstStageDate
    ? isDateBetweenDates(projectStartDate, firstStageDate, today)
    : false

  return (
    <StageTimelineContainer needsScroll={needsScroll}>
      <StageTimelineInnerContainer
        style={{
          minWidth: needsScroll
            ? `${groupedStages.length * STAGE_WIDTH}px`
            : '100%',
        }}
      >
        {projectStartDate && (
          <>
            <div className='flex flex-col relative mr-0.5'>
              <ProjectStartDot />
              <ProjectStartLabel>
                {formatMonthDay(projectStartDate, {
                  numeric: true,
                  year: '2-digit',
                })}
              </ProjectStartLabel>
            </div>
            <ConnectedStageConnector
              groupedStages={groupedStages[0]}
              type={isTodayBeforeFirstStage ? 'firstHalf' : 'full'}
            />
          </>
        )}
        {groupedStages.map((group, index) => (
          <StageTimelineSegment
            key={group.dueDateKey}
            project={project}
            stageGroup={group}
            prevGroup={groupedStages[index - 1]}
            nextGroup={groupedStages[index + 1]}
            isLastGroup={index === groupedStages.length - 1}
          />
        ))}
      </StageTimelineInnerContainer>
    </StageTimelineContainer>
  )
}
