import { templateStr } from '@motion/ui-logic'
import {
  type TaskDefinitionFormRelativeInterval,
  type TaskDefinitionFormRelativeIntervalDuration,
} from '@motion/ui-logic/pm/project'

import { type ReactNode } from 'react'

export function getDurationText(
  duration: TaskDefinitionFormRelativeIntervalDuration
) {
  const shortText = formatToAbbrevIntervalText(duration)

  return duration.sign === -1 ? `-${shortText}` : `+${shortText}`
}

export type GetRelativeDateTooltipContentArgs = {
  referenceType: TaskDefinitionFormRelativeInterval['referenceType']
  duration: TaskDefinitionFormRelativeIntervalDuration
  pluralize: (
    count: number,
    singular: string,
    plural: string
  ) => string | ReactNode[]
  startOrEnd: 'Start date' | 'End date'
}
export function getRelativeDateTooltipContent({
  referenceType,
  duration,
  pluralize,
  startOrEnd,
}: GetRelativeDateTooltipContentArgs) {
  return templateStr(
    '{{startOrEnd}}: {{target}} {{sign}} {{duration}} {{interval}}',
    {
      startOrEnd,
      target:
        referenceType === 'STAGE_START'
          ? 'Stage start'
          : referenceType === 'STAGE_DUE'
            ? 'Stage end'
            : 'Event end',
      sign: duration.sign === 1 ? 'plus' : 'minus',
      duration: duration.value,
      interval:
        duration.unit === 'DAYS'
          ? pluralize(duration.value, 'day', 'days')
          : duration.unit === 'WEEKS'
            ? pluralize(duration.value, 'week', 'weeks')
            : duration.unit === 'MONTHS'
              ? pluralize(duration.value, 'month', 'months')
              : '',
    }
  )
}

const ABBREVIATED_LABELS = {
  DAYS: 'd',
  WEEKS: 'w',
  MONTHS: 'm',
}

function formatToAbbrevIntervalText({
  value,
  unit,
}: TaskDefinitionFormRelativeIntervalDuration) {
  const label = ABBREVIATED_LABELS[unit]
  return templateStr('{{value}}{{label}}', { value, label })
}
