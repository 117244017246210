import { showToast } from '@motion/ui/base'
import { Sentry } from '@motion/web-base/sentry'
import { type RecursiveFolderItemSchema } from '@motion/zod/client'

import { useDeleteItemFromFolder } from '~/global/rpc/folders'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

import { useFavoriteTypeLookup } from './use-favorite-type-lookup'
import { useUserFavorites } from './use-user-favorites'

export const useRemoveFavorite = () => {
  const { mutateAsync: mutateRemoveFavorite } = useDeleteItemFromFolder()
  const userFavorites = useUserFavorites()
  const lookup = useFavoriteTypeLookup()

  return useCallback(
    async (itemId: RecursiveFolderItemSchema['id']) => {
      const errorExtra = {
        level: 'error' as const,
        tags: {
          position: 'useRemoveFavorite',
        },
        extra: {
          userFavoritesFolderId: userFavorites.folder?.id ?? null,
          itemId,
        } as Record<string, string>,
      }

      if (!userFavorites.folder) {
        Sentry.captureException(
          new Error('Could not locate user favorites system folder'),
          errorExtra
        )

        return void showErrorToast('There was a problem removing your favorite')
      }

      const favorite = userFavorites.items.find(
        (favorite) => favorite.id === itemId
      )

      if (!favorite) {
        Sentry.captureException(
          new Error('Could not find favorite to remove'),
          errorExtra
        )

        return void showErrorToast('There was a problem removing your favorite')
      }

      errorExtra.extra.matchedFavoriteId = favorite.id

      const model = lookup(favorite.type, favorite.targetId)

      if (!model) {
        Sentry.captureException(
          new Error('Could not locate matching model for favorite'),
          errorExtra
        )

        return void showErrorToast('There was a problem removing your favorite')
      }

      errorExtra.extra.matchedModelId = model.id

      try {
        await mutateRemoveFavorite({
          folderId: userFavorites.folder.id,
          itemId,
        })

        showToast(
          'success',
          `'${model.name}' has been removed from your favorites`
        )
      } catch (e) {
        Sentry.captureException(e, errorExtra)

        showErrorToast(
          e,
          `Failed to remove '${model.name}' from your favorites`
        )
      }
    },
    [lookup, mutateRemoveFavorite, userFavorites]
  )
}
