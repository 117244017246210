import { classed } from '@motion/theme'

export const StageTimelineContainer = classed('div', {
  base: 'flex items-center px-3 pt-1 pb-2.5 bg-semantic-neutral-bg-active-hover',
  variants: {
    needsScroll: {
      true: 'overflow-x-auto overflow-y-hidden',
      false: 'overflow-hidden',
    },
  },
  defaultVariants: {
    needsScroll: false,
  },
})

export const StageTimelineInnerContainer = classed('div', {
  base: 'flex items-center h-12',
})

export const StageItem = classed('div', {
  base: 'flex flex-col items-center gap-[5px] relative h-4',
  variants: {
    stackedCount: {
      1: 'w-4',
      2: 'w-5',
      3: 'w-6',
      4: 'w-7',
      5: 'w-8',
    },
  },

  defaultVariants: {
    stackedCount: 1,
  },
})

export const StageConnectorBar = classed('div', {
  base: `flex-[2] h-[5px] bg-calendar-palette-border-default relative items-center flex`,
})

export const StageProgress = classed('div', {
  base: `h-[3px] bg-calendar-palette-highlight-default`,
})

export const StageDate = classed('div', {
  base: 'text-[8px] leading-[8px] font-medium text-semantic-neutral-text-subtle',
})

export const ProjectStartDot = classed('div', {
  base: `flex size-2 p-[1px] items-center bg-semantic-neutral-icon-subtle rounded-full`,
})

export const ProjectStartLabel = classed('span', {
  base: `text-semantic-neutral-text-subtle text-center text-[8px] font-medium leading-[8px] absolute left-[-6px] top-[16px]`,
})

export const StageDateSpacer = classed('div', 'h-[11px] shrink-0')

export const TodayMarkerContainer = classed(
  'div',
  `
  relative
  flex flex-col gap-[3px] h-[34px] w-[6px]
  `
)

export const TodayText = classed(
  'span',
  'text-[8px] text-semantic-error-text-default font-semibold uppercase',
  'relative left-[-10px]'
)

export const Dot = classed('div', {
  base: 'w-1.5 h-1.5 rounded-full shrink-0',
  variants: {
    color: {
      red: 'bg-semantic-error-text-default',
      neutral: 'bg-semantic-neutral-bg-active-hover',
    },
  },
  defaultVariants: {
    color: 'neutral',
  },
})

export const StageTimelineBadgeButton = classed('button', {
  base: 'h-4 z-[1]',
})

export const MultiStageBadgeWrapper = classed('div', {
  variants: {
    isStacked: {
      true: 'absolute',
      false: 'relative',
    },
    stackIndex: {
      0: 'left-0',
      1: 'left-1.5',
      2: 'left-3',
      3: 'left-4.5',
      4: 'left-6',
    },
    variant: {
      active: 'top-[-2px]',
    },
  },
  compoundVariants: [
    {
      isStacked: true,
      stackIndex: 0,
      class: 'z-50',
    },
    {
      isStacked: true,
      stackIndex: 1,
      class: 'z-40',
    },
    {
      isStacked: true,
      stackIndex: 2,
      class: 'z-30',
    },
    {
      isStacked: true,
      stackIndex: 3,
      class: 'z-20',
    },
    {
      isStacked: true,
      stackIndex: 4,
      class: 'z-10',
    },
  ],
  defaultVariants: {
    isStacked: false,
    stackIndex: 0,
  },
})
