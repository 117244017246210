import { Button, type ButtonProps } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'
import { type ProjectSchema } from '@motion/zod/client'

import { type ETA_SOURCE } from '~/@types/analytics'
import { useProjectDeadlineUpdater } from '~/areas/project/hooks'
import { DateTime } from 'luxon'

type OptimizeProjectButtonProps = ButtonProps & {
  project: ProjectSchema
  source: ETA_SOURCE
}

export function OptimizeProjectButton({
  project,
  source,
  ...buttonProps
}: OptimizeProjectButtonProps) {
  const modalApi = useModalApi()
  const updateProjectDeadline = useProjectDeadlineUpdater({
    dateAdjustmentStrategy: 'ABSORB',
  })

  const handleOnClickOptimize = async () => {
    recordAnalyticsEvent('ETA_OPEN_OPTIMIZE_MODAL', {
      source,
      type: 'project',
      eta: 'ahead-of-schedule',
    })

    const response = await modalApi.prompt('optimize-project', { project })
    if (
      response === ModalDismissed ||
      response === false ||
      project.estimatedCompletionTime == null
    )
      return

    const newDeadline = DateTime.fromISO(project.estimatedCompletionTime)
      .endOf('day')
      .toISO()
    await updateProjectDeadline(project, newDeadline)
  }

  return (
    <Button {...buttonProps} onClick={handleOnClickOptimize}>
      Optimize
    </Button>
  )
}
