import { type DashboardViewGroupBySchema } from '@motion/rpc-types'
import {
  type DashboardViewChartSchema,
  type DashboardViewSortSchema,
} from '@motion/zod/client'

import { useCallback, useContext } from 'react'

import { ChartFieldsContext } from './context'

import { type ChartFields } from '../../types'

export function useValidChartFieldsContext() {
  const ctx = useContext(ChartFieldsContext)

  if (ctx == null) {
    throw new Error('Missing context')
  }

  return ctx
}

export function useChartField<K extends keyof ChartFields>(
  name: K
): [ChartFields[K], (val: ChartFields[K]) => void] {
  const ctx = useValidChartFieldsContext()

  const setValue = useCallback(
    (newValue: ChartFields[K]) => {
      ctx.setFieldValue(name, newValue)
    },
    [ctx, name]
  )

  return [ctx.fields[name], setValue]
}

export function useChartSettings<
  TType extends DashboardViewChartSchema['type'],
>(): ChartFields<TType>['settings'] {
  const ctx = useValidChartFieldsContext()

  return ctx.fields.settings as ChartFields<TType>['settings']
}

function useGenericChartSettingField<
  TType extends DashboardViewChartSchema['type'],
  TName extends keyof TSettings,
  TSettings = ChartFields<TType>['settings'],
>(
  _type: TType,
  name: TName
): [TSettings[TName], (val: TSettings[TName]) => void] {
  const ctx = useValidChartFieldsContext()

  const setValue = useCallback(
    (newValue: TSettings[TName]) => {
      ctx.setFieldValue('settings', {
        ...ctx.fields.settings,
        [name]: newValue,
      })
    },
    [ctx, name]
  )

  return [(ctx.fields.settings as any)[name], setValue]
}

export function useChartSettingField<
  TName extends keyof TSettings,
  TSettings = ChartFields['settings'],
>(name: TName): [TSettings[TName], (val: TSettings[TName]) => void] {
  return useGenericChartSettingField('pie', name)
}

export function useBarChartSettingField<
  TName extends keyof TSettings,
  TSettings = ChartFields<'bar'>['settings'],
>(name: TName): [TSettings[TName], (val: TSettings[TName]) => void] {
  return useGenericChartSettingField('bar', name)
}

export function useNumberChartSettingField<
  TName extends keyof TSettings,
  TSettings = ChartFields<'number'>['settings'],
>(name: TName): [TSettings[TName], (val: TSettings[TName]) => void] {
  return useGenericChartSettingField('number', name)
}

export function useLineChartSettingField<
  TName extends keyof TSettings,
  TSettings = ChartFields<'line'>['settings'],
>(name: TName): [TSettings[TName], (val: TSettings[TName]) => void] {
  return useGenericChartSettingField('line', name)
}

// First-level group by
export function useChartGroupBy(): [
  DashboardViewGroupBySchema | undefined,
  (val: DashboardViewGroupBySchema) => void,
] {
  const [groupBy, setGroupBy] = useChartSettingField('groupBy')

  const firstGroupBy: DashboardViewGroupBySchema = groupBy.at(0) ?? {
    field: 'statusId',
  }

  const setFirstGroupBy = useCallback(
    (newValue: DashboardViewGroupBySchema) => {
      const segmentBy = groupBy.at(1)

      const newGroupBy = [newValue]

      if (segmentBy != null && newValue.field !== segmentBy.field) {
        newGroupBy.push(segmentBy)
      }

      setGroupBy(newGroupBy)
    },
    [setGroupBy, groupBy]
  )

  return [firstGroupBy, setFirstGroupBy]
}

export function useChartSortBy(): [
  DashboardViewSortSchema,
  (val: DashboardViewSortSchema) => void,
] {
  const [groupBy, setGroupBy] = useChartGroupBy()

  const setSortBy = useCallback(
    (newValue: DashboardViewSortSchema) => {
      if (groupBy == null) return

      setGroupBy({
        ...groupBy,
        sort: newValue,
      })
    },
    [setGroupBy, groupBy]
  )

  return [
    groupBy?.sort ?? {
      direction: 'asc',
      source: 'value',
    },
    setSortBy,
  ]
}

// Second-level group by
export function useChartSegmentBy(): [
  DashboardViewGroupBySchema | null,
  (val: DashboardViewGroupBySchema | null) => void,
] {
  const [groupBy, setGroupBy] = useChartSettingField('groupBy')

  const segmentBy: DashboardViewGroupBySchema | null = groupBy.at(1) ?? null

  const setSegmentBy = useCallback(
    (newValue: DashboardViewGroupBySchema | null) => {
      if (newValue == null) {
        setGroupBy([...groupBy.slice(0, 1)])
      } else {
        setGroupBy([...groupBy.slice(0, 1), newValue])
      }
    },
    [setGroupBy, groupBy]
  )

  return [segmentBy, setSegmentBy]
}
