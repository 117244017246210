import { type SidebarState } from './types'

export const defaultSidebarState: SidebarState = {
  open: true,
  width: 240,
}

export const sidebarKeys = {
  agenda: 'motion:agendaSidebarState',
  calendar: 'calendarSidebarOpen',
  global: 'globalSidebarState',
  note: 'motion:noteSidebarState',
} as const

export type SidebarKeys = keyof typeof sidebarKeys
