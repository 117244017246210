import { useSharedStateQuery } from '@motion/react-core/shared-state'
import {
  type ProjectDefinitionSchema,
  type ProjectSchema,
  type StageDefinitionSchema,
  type WorkspaceSchema,
} from '@motion/zod/client'

import { AppWorkspaceContext } from '~/global/contexts'

export const useAllProjectDefinitions = (): ProjectDefinitionSchema[] => {
  return useSharedStateQuery(
    AppWorkspaceContext,
    (state) => state.projectDefinitions.all
  )
}

export const useProjectDefinitions = (
  workspaceId: WorkspaceSchema['id'] | undefined | null
): ProjectDefinitionSchema[] => {
  return useSharedStateQuery(AppWorkspaceContext, (state) =>
    state.projectDefinitions.all.filter((p) => p.workspaceId === workspaceId)
  )
}

export const useProjectDefinition = (
  id: ProjectDefinitionSchema['id'] | null | undefined
): ProjectDefinitionSchema | null => {
  return useSharedStateQuery(AppWorkspaceContext, (state) =>
    id == null ? null : (state.projectDefinitions.byId[id] ?? null)
  )
}

export const useProjectDefinitionForStageId = (
  workspaceId: WorkspaceSchema['id'],
  stageDefinitionId: StageDefinitionSchema['id']
): ProjectDefinitionSchema | undefined => {
  return useSharedStateQuery(AppWorkspaceContext, (state) =>
    state.projectDefinitions.all.find(
      (pd) =>
        pd.workspaceId === workspaceId &&
        pd.stages.some((sd) => sd.id === stageDefinitionId)
    )
  )
}

export type ProjectDefinitionWithProjectInfo = ProjectDefinitionSchema & {
  projects: ProjectSchema[]
}

export const useProjectDefinitionsWithProjectInfo = (
  workspaceId: WorkspaceSchema['id']
): ProjectDefinitionWithProjectInfo[] => {
  return useSharedStateQuery(AppWorkspaceContext, (state) => {
    const projectDefinitions = state.projectDefinitions.all.filter(
      (projectDefinition) => projectDefinition.workspaceId === workspaceId
    )
    const projects = state.projects.all.filter(
      (project) => project.workspaceId === workspaceId
    )

    return projectDefinitions.map((projectDefinition) => {
      const projectsForDefinition = projects.filter(
        (project) => project.projectDefinitionId === projectDefinition.id
      )
      return { ...projectDefinition, projects: projectsForDefinition }
    })
  })
}
