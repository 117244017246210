import { XSolid } from '@motion/icons'

import { type ReactNode, useRef } from 'react'

import { Modal, type ModalProps } from './modal'
import { Body, Footer, Header, HeaderTitle, Shell } from './styled/no-border'
import { type ModalAction } from './types'

import { Button } from '../button'
import { ButtonGroup } from '../button-group'
import { Focus } from '../focus'

type BlockingActionModalProps = {
  blocking: true
  onClose?: ModalProps['onClose']
}

type NonBlockingActionModalProps = {
  blocking?: boolean
  onClose: ModalProps['onClose']
}

type BaseActionModalProps = ModalProps & {
  actions: ModalAction[]
  title?: ReactNode
  showCloseButton?: boolean
}

export type ActionModalProps = BaseActionModalProps &
  (BlockingActionModalProps | NonBlockingActionModalProps)

export function ActionModal(props: ActionModalProps) {
  const {
    visible,
    title,
    actions = [],
    onClose,
    blocking = false,
    children,
    ...rest
  } = props

  const bodyRef = useRef<HTMLDivElement>(null)

  const showTitleBar = title || !blocking

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      {...rest}
      disableOverlayClick={blocking}
      disableEscapeKey={blocking}
    >
      <Shell>
        {showTitleBar && (
          <Header>
            <HeaderTitle>{title}</HeaderTitle>

            {!blocking && (
              <Button
                iconOnly
                onClick={onClose}
                sentiment='neutral'
                size='small'
                variant='muted'
              >
                <XSolid />
              </Button>
            )}
          </Header>
        )}

        {children && (
          <Focus contentRef={bodyRef}>
            <Body ref={bodyRef}>{children}</Body>
          </Focus>
        )}

        {actions.length > 0 && (
          <Footer>
            <ButtonGroup>
              {actions.map(({ label, onAction, ...props }) => (
                <Button key={label} onClick={onAction} {...props}>
                  {label}
                </Button>
              ))}
            </ButtonGroup>
          </Footer>
        )}
      </Shell>
    </Modal>
  )
}
