import { omit } from '@motion/utils/core'
import { type DashboardViewCellSchema } from '@motion/zod/client'

import { type ChartFields } from '../types'

export function convertChartCellToChartFields(
  cell: DashboardViewCellSchema
): ChartFields {
  return {
    title: cell.title,
    settings: omit(cell.chart, 'filters') as ChartFields['settings'],
  }
}
