import { classed } from '@motion/theme'

export const StageItemContainer = classed('div', {
  base: `
    absolute flex items-center h-full min-w-[9px]
  `,
  variants: {
    smallMinWidth: {
      true: 'min-w-px',
      false: '',
    },
  },
  defaultVariants: {
    smallMinWidth: false,
  },
})

export const StageCompletionBar = classed('div', {
  base: `
    bg-white dark:bg-opacity-20 bg-opacity-70 h-full absolute left-0 rounded-none
  `,
})

export const StageBadgeContainer = classed('div', {
  base: `
    relative hidden group-hover/planner-project-item:flex transition-all
    h-full
    w-full
    -top-[0.5px]
    overflow-hidden
  `,
  variants: {
    showing: {
      true: `flex`,
    },
  },
  defaultVariants: {
    showing: false,
  },
})

export const StageInitialCircle = classed('div', {
  base: `
    absolute w-1.5 h-1.5 flex group-hover/planner-project-item:hidden transition-all
  `,
  variants: {
    hidden: {
      true: `hidden`,
    },
  },
  defaultVariants: {
    hidden: false,
  },
})
