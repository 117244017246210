import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { type SortableTreeviewBaseItem } from '~/areas/sidebar/treeviews/components/sortable-treeview/types'

import { TreeviewItem, type TreeviewItemProps } from './components'

export type SortableTreeviewItemProps = Omit<
  TreeviewItemProps,
  | 'handleAttributes'
  | 'handleListeners'
  | 'handleRef'
  | 'isDragging'
  | 'isSorting'
  | 'style'
  | 'wrapperRef'
> & {
  id: SortableTreeviewBaseItem['id']
  sortableData: Record<string, any>
}

export const SortableTreeviewItem = ({
  id,
  sortableData,
  ...treeviewItemProps
}: SortableTreeviewItemProps) => {
  const {
    attributes: handleAttributes,
    isDragging,
    isSorting,
    listeners: handleListeners,
    setActivatorNodeRef,
    setDraggableNodeRef,
    setDroppableNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
    data: sortableData,
  })

  return (
    <TreeviewItem
      handleAttributes={handleAttributes}
      handleListeners={handleListeners}
      handleRef={setActivatorNodeRef}
      isDragging={isDragging}
      isSorting={isSorting}
      ref={setDraggableNodeRef}
      style={{
        transform: CSS.Translate.toString(transform),
        transition,
      }}
      wrapperRef={setDroppableNodeRef}
      {...treeviewItemProps}
    />
  )
}
