import { useOnceWhen } from '@motion/react-core/hooks'
import { type MeetingInsightsSchema } from '@motion/rpc-types'
import { time } from '@motion/utils/debug'

import { LoadingPage } from '~/areas/project-management/pages/pm-revamp/common/loading-page'
import {
  buildTreeGroup,
  type GroupedNode,
} from '~/areas/project-management/pages/pm-v3/grouping'
import { TreeList } from '~/areas/project-management/pages/pm-v3/tree-list'
import { useViewState } from '~/areas/project-management/pages/pm-v3/view-state'
import { useMemo } from 'react'

import { useMeetingInsightsQuery } from '../../hooks/rpc'
import { type GroupDefinition, useAvailableGroups } from '../grouping'
import { useTreeGroupOverride } from '../grouping/use-tree-group-override'
import { useMeetingInsightsColumns } from '../hooks/use-meeting-insights-columns'
import { type MeetingTreeListRowValueType, type SortField } from '../types'

export function ConnectedMeetingInsightsList() {
  // TBH might be worth just have a meeting insights version of this.
  const [viewState, setViewState] = useViewState()
  const groups = useAvailableGroups()

  const { data } = useMeetingInsightsQuery({
    filters: {
      meetingBotStatus: {
        operator: 'in',
        value: ['COMPLETED'],
      },
    },
    sortBy: viewState.sortBy
      ? {
          field: viewState.sortBy.field as SortField,
          direction: viewState.sortBy.direction,
        }
      : undefined,
  })

  const filteredData = useMemo(() => {
    if (!data) return []

    // TODO: Eventually add text search here
    return data
  }, [data])

  const groupDefinitions = useMemo(() => {
    return viewState.groupBy.fields
      .map((g) => groups.find((def) => def.type === g.key))
      .filter(Boolean)
  }, [groups, viewState.groupBy.fields])

  useOnceWhen(viewState.sortBy == null, () => {
    setViewState({
      ...viewState,
      sortBy: {
        field: 'startTime' as any,
        direction: 'desc',
      },
    })
  })

  if (data == null) {
    return <LoadingPage />
  }

  if (data.length === 0) {
    return (
      <div className='grid place-items-center w-full h-full'>
        <span className='text-semantic-neutral-text-default font-semibold text-center'>
          No meeting insights found
        </span>
      </div>
    )
  }

  return (
    <MeetingInsightsList
      groupBy={groupDefinitions}
      meetingInsights={filteredData}
    />
  )
}

type MeetingInsightsListProps = {
  groupBy: GroupDefinition<MeetingInsightsSchema>[]
  meetingInsights: MeetingInsightsSchema[]
}

function MeetingInsightsList(props: MeetingInsightsListProps) {
  const overrides = useTreeGroupOverride()
  const [viewState] = useViewState()

  const grouped = useMemo(() => {
    return time('build-tree-group.total', () => {
      return buildTreeGroup(props.groupBy as any, overrides)
        .add('meetingInsights', props.meetingInsights)
        .buildTree<GroupedNode<MeetingTreeListRowValueType>>({
          hideEmptyGroups: true,
          sortOrder: viewState.groupBy.order,
        })
    })
  }, [overrides, props.groupBy, props.meetingInsights, viewState.groupBy.order])

  const columns = useMeetingInsightsColumns()

  return <TreeList columns={columns} tree={grouped} />
}
