import {
  convertDateIntervalToDays,
  type DeadlineInterval,
  type RelativeIntervalReferenceType,
  type RelativeIntervalUnit,
} from '@motion/shared/flows'

export type RelativeDatePath =
  `stages.${number}.tasks.${number}.${'start' | 'due'}RelativeInterval`

export function mapRelativeIntervalReferenceTypeToReadable(
  value: RelativeIntervalReferenceType
): string {
  let name: string
  switch (value) {
    case 'STAGE_START':
      name = 'Start'
      break
    case 'STAGE_DUE':
      name = 'Deadline'
      break
    case 'MEETING_TASK':
      name = 'Event'
      break
  }

  return name
}

const WEEK_LENGTH = 7
const MONTH_LENGTH = 30

export function getValidDurationValues<T extends DeadlineInterval>(
  stageDeadlineInterval: T,
  taskDuration: T
): number[] {
  const stageDays = convertDateIntervalToDays(stageDeadlineInterval)

  const values: number[] = []

  switch (taskDuration.unit) {
    case 'DAYS':
      // For days, allow values up to the stage duration in days
      for (let i = 0; i <= stageDays; i++) {
        values.push(i)
      }
      break

    case 'WEEKS':
      // For weeks, allow values up to the stage duration in weeks (rounded down)
      const maxWeeks = Math.floor(stageDays / WEEK_LENGTH)
      for (let i = 0; i <= maxWeeks; i++) {
        values.push(i)
      }
      break

    case 'MONTHS':
      // For months, allow values up to the stage duration in months (rounded down)
      const maxMonths = Math.floor(stageDays / MONTH_LENGTH)
      for (let i = 0; i <= maxMonths; i++) {
        values.push(i)
      }
      break
  }

  return values
}

export const getValidDurationUnits =
  <T extends DeadlineInterval>(stageDeadlineInterval: T, taskDuration: T) =>
  (item: RelativeIntervalUnit): boolean => {
    const stageDays = convertDateIntervalToDays(stageDeadlineInterval)

    if (item === 'DAYS') return true

    if (item === 'WEEKS') {
      // Don't allow weeks if:
      // 1. Stage is less than a week, or
      // 2. Converting task's current value to weeks would exceed stage weeks
      const stageWeeks = Math.floor(stageDays / WEEK_LENGTH)
      return stageWeeks >= 1 && taskDuration.value <= stageWeeks
    }

    if (item === 'MONTHS') {
      // Don't allow months if:
      // 1. Stage is less than a month, or
      // 2. Converting task's current value to months would exceed stage months
      const stageMonths = Math.floor(stageDays / MONTH_LENGTH)
      return stageMonths >= 1 && taskDuration.value <= stageMonths
    }

    return false
  }
