import { classed } from '@motion/theme'
import { AutoScheduleToggle, Tooltip } from '@motion/ui/base'
import { normalizeTaskDeadlineStatus, templateStr } from '@motion/ui-logic'
import {
  isAutoScheduledToggleEnabled,
  isMeetingTask,
} from '@motion/ui-logic/pm/task'
import { daysBetweenDates } from '@motion/utils/dates'
import { useHasTreatment } from '@motion/web-common/flags'

import { useAutoSchedule } from '~/areas/task-project/hooks'
import { AutoScheduleTooltip } from '~/areas/tasks/tooltips'
import { type TaskWithRelations } from '~/global/proxies'
import { DateTime } from 'luxon'

export type TaskLineAutoScheduleItemProps = {
  task: TaskWithRelations
}

export function TaskLineAutoScheduleItem({
  task,
}: TaskLineAutoScheduleItemProps) {
  if (isMeetingTask(task)) return null

  return <TaskAutoscheduleItem task={task} />
}

function TaskAutoscheduleItem({ task }: TaskLineAutoScheduleItemProps) {
  const {
    isAutoScheduled,
    scheduledDate,
    scheduledDateInfo,
    toggleChange: toggleChangeAutoSchedule,
    isError,
  } = useAutoSchedule(task)
  const hasPastDueM2 = useHasTreatment('past-due-m2')

  const deadlineDate =
    task.dueDate != null ? DateTime.fromISO(task.dueDate) : null

  const diffInDays =
    scheduledDate != null && deadlineDate != null
      ? daysBetweenDates(deadlineDate, scheduledDate)
      : 0

  const normalizedDeadlineStatus = normalizeTaskDeadlineStatus(task)
  const taskDateTextState =
    isError || (hasPastDueM2 && normalizedDeadlineStatus === 'missed-deadline')
      ? 'error'
      : hasPastDueM2 && normalizedDeadlineStatus === 'scheduled-past-deadline'
        ? 'warning'
        : 'none'

  const { disabled: isAutoScheduleDisabled } = isAutoScheduledToggleEnabled({
    status: task.status,
    isAutoScheduled,
  })

  return (
    <>
      {diffInDays !== 0 && (
        <Tooltip
          renderContent={() => (
            <AutoScheduleTooltip info={scheduledDateInfo} task={task} />
          )}
          asChild
        >
          <TaskDateText state={taskDateTextState}>
            {templateStr('{{count}}d', { count: diffInDays })}
          </TaskDateText>
        </Tooltip>
      )}
      <AutoScheduleToggle
        checked={isAutoScheduled}
        onChange={toggleChangeAutoSchedule}
        labelHidden
        size='xsmall'
        disabled={isAutoScheduleDisabled}
      />
    </>
  )
}

const TaskDateText = classed('span', 'text-2xs', {
  variants: {
    state: {
      error: 'text-semantic-error-text-default',
      warning: 'text-semantic-warning-text-default',
      none: 'text-semantic-neutral-text-subtle text-nowrap',
    },
  },
  defaultVariants: {
    state: 'none',
  },
})
