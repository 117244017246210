import { CogSolid } from '@motion/icons'
import { type COLOR, MAX_FOLDER_DEPTH } from '@motion/shared/common'
import { ButtonGroup, IconButton } from '@motion/ui/base'
import { FolderColoredIcon, WorkspaceColoredIcon } from '@motion/ui/project'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useHasTreatment } from '@motion/web-common/flags'

import { NoteActionsList } from '~/areas/notes'
import { useSidebarSearchContext } from '~/areas/search/hook'
import { ConnectedProjectBadge } from '~/global/components/badges'
import { ConnectedNoteBadge } from '~/global/components/badges/note'
import { useUriByRouteId } from '~/routing'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import {
  FolderActionsMenu,
  FolderActionsPopoverContents,
} from './folder-actions-menu'
import { NoteActionsMenu } from './note-actions-menu'
import {
  ConnectedProjectActionList,
  ProjectActionsMenu,
} from './project-actions-menu'
import {
  WorkspaceActionList,
  WorkspaceActionMenu,
} from './workspace-actions-menu'
import { WorkspaceCreateMenu } from './workspace-create-menu'

import { HIGHLIGHTABLE_DATA_ATTRIBUTE } from '../../../constants'
import { useGlobalSidebarContext } from '../../../hooks'
import {
  type DragProjection,
  SortableTreeviewItem,
  type TreeviewItemProps,
} from '../../components/sortable-treeview'
import { type SortableItemData, type WorkspacesTreeviewItem } from '../types'

export type ConnectedWorkspacesTreeviewItemProps = {
  item: WorkspacesTreeviewItem
  level: number
  projection: DragProjection<WorkspacesTreeviewItem> | null
} & Pick<TreeviewItemProps, 'disableDrag' | 'isGhost'>

export const ConnectedWorkspacesTreeviewItem = ({
  item: flattenedItem,
  level,
  projection,
  disableDrag,
  isGhost,
}: ConnectedWorkspacesTreeviewItemProps) => {
  const { canMoveDown, canMoveUp, id, item, parentId, parentType, type } =
    flattenedItem

  const { highlightedId } = useGlobalSidebarContext()
  const { hasSearch } = useSidebarSearchContext()
  const getRouteUri = useUriByRouteId({ noDefaults: true })
  const { updateFolderState } = useGlobalSidebarContext()
  const isActive = useIsSidebarItemActive(flattenedItem)

  const hasNotes = useHasTreatment('notes-in-webapp')

  const sortableData: SortableItemData = {
    item,
    parentId,
    parentType,
    type,
    level,
  }

  const Icon = useMemo(() => {
    switch (item.type) {
      case 'FOLDER':
        return function Icon(props: { className?: string }) {
          return (
            <FolderColoredIcon
              color={item.color as COLOR}
              open={item.expanded}
              {...props}
            />
          )
        }

      case 'PROJECT':
        return function Icon(props: { className?: string }) {
          return (
            <ConnectedProjectBadge id={item.itemId} {...props} hideTooltip />
          )
        }

      case 'NOTE':
        return function Icon(props: { className?: string }) {
          return <ConnectedNoteBadge id={item.itemId} {...props} hideTooltip />
        }

      case 'INDIVIDUAL_WORKSPACE':
      case 'TEAM_WORKSPACE':
        return function Icon(props: { className?: string }) {
          return <WorkspaceColoredIcon color={item.color as COLOR} {...props} />
        }
    }
  }, [item])

  const moveActionAllowed = {
    up: canMoveUp && !hasSearch,
    down: canMoveDown && !hasSearch,
  }

  const isWorkspace =
    item.type === 'INDIVIDUAL_WORKSPACE' || item.type === 'TEAM_WORKSPACE'

  const renderButtons = () => (
    <ButtonGroup size='small' nowrap>
      {isWorkspace && (
        <>
          <IconButton
            icon={CogSolid}
            size='xsmall'
            sentiment='neutral'
            variant='muted'
            url={getRouteUri('workspace-settings', {
              workspaceId: item.workspaceId,
            })}
            onClick={() => {
              recordAnalyticsEvent('FOLDERS_CLICKED_SIDEBAR_BUTTON', {
                itemType: 'WORKSPACE',
                button: 'WORKSPACE_SETTINGS',
              })
            }}
          />

          <WorkspaceActionMenu
            workspaceId={item.workspaceId}
            itemId={item.id}
            moveActionAllowed={moveActionAllowed}
          />

          <WorkspaceCreateMenu
            workspaceId={item.workspaceId}
            allowNewNote={hasNotes}
          />
        </>
      )}

      {item.type === 'FOLDER' && (
        <>
          <FolderActionsMenu
            folder={item}
            moveActionAllowed={moveActionAllowed}
          />

          <WorkspaceCreateMenu
            workspaceId={item.workspaceId}
            folderId={item.itemId}
            allowNewFolder={level < MAX_FOLDER_DEPTH}
            allowNewNote={hasNotes}
          />
        </>
      )}

      {item.type === 'PROJECT' && (
        <ProjectActionsMenu
          projectId={item.itemId}
          moveActionAllowed={moveActionAllowed}
        />
      )}

      {hasNotes && item.type === 'NOTE' && (
        <NoteActionsMenu
          treeViewItem={item}
          moveActionAllowed={moveActionAllowed}
        />
      )}
    </ButtonGroup>
  )

  const handleToggleExpand = (expanded: boolean) => {
    if (!item.isContainer) return

    recordAnalyticsEvent('FOLDERS_TOGGLED_SIDEBAR_VISIBILITY', {
      itemType: isWorkspace ? 'WORKSPACE' : 'FOLDER',
      state: expanded ? 'EXPANDED' : 'COLLAPSED',
    })

    updateFolderState({
      folderId: item.itemId,
      expanded,
    })
  }

  return (
    <SortableTreeviewItem
      attributes={{
        [HIGHLIGHTABLE_DATA_ATTRIBUTE]: item.id,
      }}
      disableDrag={disableDrag}
      icon={Icon}
      id={id}
      isActive={isActive}
      isContainer={item.isContainer}
      isContainerExpanded={item.expanded}
      isGhost={isGhost}
      isHighlighted={highlightedId === item.id}
      isInside={projection ? projection.parentId === id : false}
      label={
        <>
          <span className=''>{item.label}</span>

          {item.isContainer && (
            <span className='relative text-[10px] leading-[10px] font-light text-semantic-neutral-text-disabled ml-1.5 -top-px'>
              {item.projectCount}
            </span>
          )}
        </>
      }
      level={level}
      onToggleExpand={handleToggleExpand}
      order={isGhost && projection ? projection.order.toString() : item.order}
      renderButtons={renderButtons}
      sortableData={sortableData}
      url={item.url}
      renderContextMenuContent={({ close }) => {
        if (item.type === 'PROJECT') {
          return (
            <ConnectedProjectActionList
              close={close}
              projectId={item.itemId}
              options={{
                showColors: true,
                allowProjectResolution: true,
                showMoves: true,
                disabledMoveActions: {
                  up: !moveActionAllowed.up,
                  down: !moveActionAllowed.down,
                },
              }}
            />
          )
        }

        if (item.type === 'FOLDER') {
          return (
            <FolderActionsPopoverContents
              close={close}
              folder={item}
              moveActionAllowed={moveActionAllowed}
            />
          )
        }

        if (item.type === 'NOTE') {
          return (
            <NoteActionsList
              close={close}
              noteId={item.itemId}
              folderItemId={item.id}
              moveActionAllowed={moveActionAllowed}
            />
          )
        }

        if (isWorkspace) {
          return (
            <WorkspaceActionList
              close={close}
              workspaceId={item.workspaceId}
              itemId={item.id}
              moveActionAllowed={moveActionAllowed}
            />
          )
        }

        return null
      }}
    />
  )
}

function useIsSidebarItemActive(
  item: ConnectedWorkspacesTreeviewItemProps['item']
): boolean {
  const { workspaceId, folderId, projectId, noteId } = useParams<{
    workspaceId?: string
    folderId?: string
    projectId?: string
    noteId?: string
  }>()

  const workspaceMatches = workspaceId === item.item.workspaceId
  const folderMatches = folderId === item.item.itemId
  const projectMatches = projectId === item.item.itemId
  const noteMatches = noteId === item.item.itemId

  switch (item.type) {
    case 'WORKSPACE':
      return workspaceMatches && !folderId && !projectId
    case 'FOLDER':
      return workspaceMatches && folderMatches && !projectId
    case 'PROJECT':
      return workspaceMatches && projectMatches
    case 'NOTE':
      return noteMatches
    default:
      return false
  }
}
