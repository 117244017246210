import { priorityLevels, type PriorityLevelType } from '@motion/rpc/types'
import { isNoneId } from '@motion/shared/identifiers'
import {
  formatToShortTaskDuration,
  isCustomFieldKey,
  NO_DURATION,
} from '@motion/ui-logic'
import { isMeetingTask } from '@motion/ui-logic/pm/task'
import {
  byProperty,
  Compare,
  groupBy,
  groupInto,
  ordered,
  sumBy,
} from '@motion/utils/array'

import { type CellContext } from '@tanstack/react-table'
import {
  ColorLabel,
  ConnectedProjectLabel,
  EtaLabel,
  FolderLabel,
  Label,
  PriorityLabel,
  StageLabel,
  StatusLabel,
  UserLabel,
} from '~/global/components/labels'
import {
  type ProjectWithRelations,
  type TaskWithRelations,
} from '~/global/proxies'
import { type ReactNode } from 'react'

import { AddProjectButtonCell, AddProjectNameCell } from './add-project-cell'
import { AddTaskButtonCell, AddTaskNameCell } from './add-task-cell'
import { ProjectAssigneeCell, TaskAssigneeCell } from './assignee-cell'
import { ProjectAttachmentsCell, TaskAttachmentsCell } from './attachments-cell'
import { TaskBlockedByCell } from './blocked-by-cell'
import { TaskBlockingCell } from './blocking-tasks-cell'
import { ColorCell } from './color-cell'
import { DateTimeCell } from './common-cells'
import { TaskCompletedDurationCell } from './completed-duration-cell'
import { CreatedByCell } from './created-by-cell'
import { CustomFieldGroupHeader } from './custom-fields/group-headers'
import { ProjectDeadlineCell, TaskDeadlineCell } from './deadline-cell'
import {
  ProjectCompletedDurationCell,
  ProjectTotalDurationCell,
  StageCompletedDurationCell,
  StageTotalDurationCell,
  TaskDurationCell,
} from './duration'
import { ETACell, ETAStageCell } from './eta-cell'
import { ExpandableHeader } from './expandable-header'
import { ProjectFolderCell, TaskFolderCell } from './folder-cell'
import {
  DateGroupHeader,
  GroupHeaderWithFieldName,
  StageGroupHeaderWithFieldName,
} from './group-headers'
import { GuestsCells } from './guests-cell'
import { ProjectLabelsCell, TaskLabelsCell } from './label-cell'
import { ProjectNameCell, TaskNameCell } from './name-cell'
import { ProjectPriorityCell, TaskPriorityCell } from './priority-cell'
import { OpenProjectButton, ProjectCell } from './project-cell'
import { ProjectDefinitionCell } from './project-definition-cell'
import { TaskScheduleCell } from './schedule-cell'
import { ProjectStageCell, TaskStageCell } from './stage-cell'
import { StageDeadlineCell } from './stage-deadline-cell'
import { ProjectStartDateCell, TaskStartDateCell } from './start-date-cell'
import {
  ProjectStatusCell,
  StageStatusCell,
  TaskStatusCell,
} from './status-cell'
import {
  TotalCountCell,
  TotalDateRangeCell,
  TotalDurationCell,
  TotalPrioritiesCell,
} from './total-cell'
import { WorkspaceCell } from './workspace-cell'

import { type ProjectFieldId, type TaskFieldId } from '../../fields'
import { type GroupedNode } from '../../grouping'
import { type TreeListRowValueType } from '../types'

export type CellRenderFn = (
  info: CellContext<GroupedNode<TreeListRowValueType>, unknown>
) => ReactNode

type FieldId = TaskFieldId | ProjectFieldId

export const CELLS: Record<FieldId, CellRenderFn> = {
  name(info) {
    const item = info.row.original.value

    const counter = info.row.index + 1
    const canExpand = info.row.getCanExpand()

    let cell = null
    let cellSuffix = null
    if (item.type === 'stage') {
      cell = (
        <StageGroupHeaderWithFieldName stage={item.value}>
          {item.value != null && !isNoneId(item.value.id) ? (
            <StageLabel value={item.value} />
          ) : (
            'No Stage'
          )}
        </StageGroupHeaderWithFieldName>
      )
    } else if (item.type === 'status') {
      cell = (
        <GroupHeaderWithFieldName fieldName='Status'>
          <StatusLabel value={item.value} />
        </GroupHeaderWithFieldName>
      )
    } else if (item.type === 'priority') {
      cell = (
        <GroupHeaderWithFieldName fieldName='Priority'>
          {item.value != null ? (
            <PriorityLabel value={item.value} />
          ) : (
            'No Priority'
          )}
        </GroupHeaderWithFieldName>
      )
    } else if (item.type === 'workspace') {
      cell = (
        <GroupHeaderWithFieldName fieldName='Workspace'>
          <span className='truncate'>{item.value.name}</span>
        </GroupHeaderWithFieldName>
      )
    } else if (item.type === 'projectDefinition') {
      cell = (
        <GroupHeaderWithFieldName fieldName='Template'>
          <span className='truncate'>
            {item.value ? item.value.name : 'No Template'}
          </span>
        </GroupHeaderWithFieldName>
      )
    } else if (item.type === 'user') {
      cell = (
        <GroupHeaderWithFieldName fieldName='Assignee'>
          <UserLabel value={item.value} />
        </GroupHeaderWithFieldName>
      )
    } else if (item.type === 'createdBy') {
      cell = (
        <GroupHeaderWithFieldName fieldName='Created by'>
          <UserLabel value={item.value} />
        </GroupHeaderWithFieldName>
      )
    } else if (item.type === 'project') {
      if (canExpand) {
        cell = (
          <GroupHeaderWithFieldName fieldName='Project'>
            <ConnectedProjectLabel id={item.value?.id ?? null} clickable />
          </GroupHeaderWithFieldName>
        )
        cellSuffix =
          item.value && !isNoneId(item.value.id) ? (
            <OpenProjectButton id={item.value.id} />
          ) : null
      } else if (item.value != null) {
        // This means we're in the "project" target, project obviously exist
        cell = (
          <ProjectNameCell info={info} counter={counter} project={item.value} />
        )
      }
    } else if (item.type === 'task') {
      cell = <TaskNameCell info={info} counter={counter} task={item.value} />
    } else if (item.type === 'label') {
      cell = (
        <GroupHeaderWithFieldName fieldName='Label'>
          <Label value={item.value} />
        </GroupHeaderWithFieldName>
      )
    } else if (item.type === 'deadline') {
      cell = <DateGroupHeader item={item} fieldName='Deadline' />
    } else if (item.type === 'createdAt') {
      cell = <DateGroupHeader item={item} fieldName='Created at' />
    } else if (item.type === 'completedAt') {
      cell = <DateGroupHeader item={item} fieldName='Completed at' />
    } else if (item.type === 'updatedAt') {
      cell = <DateGroupHeader item={item} fieldName='Updated at' />
    } else if (item.type === 'startDate') {
      cell = <DateGroupHeader item={item} fieldName='Start date' />
    } else if (item.type === 'scheduledDate') {
      cell = <DateGroupHeader item={item} fieldName='Scheduled date' />
    } else if (item.type === 'folder') {
      cell = <FolderLabel value={item.value} />
    } else if (item.type === 'deadlineStatus') {
      cell = (
        <GroupHeaderWithFieldName fieldName='ETA'>
          <EtaLabel value={item.value} />
        </GroupHeaderWithFieldName>
      )
    } else if (item.type === 'color') {
      cell = <ColorLabel color={item.value} />
    } else if (isCustomFieldKey(item.type)) {
      cell = <CustomFieldGroupHeader item={item} />
    } else if (item.type === 'task-totals') {
      if (item.value.addItemValue == null) {
        cell = (
          <AddTaskButtonCell
            parentRowId={info.row.original.parent?.qualifiedKey ?? null}
            item={item}
          />
        )
      } else {
        cell = <AddTaskNameCell task={item.value.addItemValue} />
      }
    } else if (item.type === 'project-totals') {
      if (item.value.addItemValue == null) {
        cell = (
          <AddProjectButtonCell
            parentRowId={info.row.original.parent?.qualifiedKey ?? null}
            item={item}
          />
        )
      } else {
        cell = <AddProjectNameCell project={item.value.addItemValue} />
      }
    }

    if (canExpand) {
      return (
        <ExpandableHeader cell={cell} cellSuffix={cellSuffix} info={info} />
      )
    }

    return cell
  },
  deadline(props) {
    const p = props.row.original.value
    if (p.value === null) return null

    if (p.type === 'stage' && p.value?.id) {
      return (
        <StageDeadlineCell
          row={props.row.original}
          stageDefinitionId={p.value.id}
        />
      )
    }

    if (
      (p.type === 'task-totals' || p.type === 'project-totals') &&
      p.value.addItemValue == null
    ) {
      const items =
        p.type === 'task-totals'
          ? p.value.items.filter((task) => !isMeetingTask(task))
          : p.value.items

      const [fromDate, toDate] = getFirstAndLastFromArray<
        ProjectWithRelations | TaskWithRelations
      >(items, (item) => item.dueDate)

      return <TotalDateRangeCell fromDate={fromDate} toDate={toDate} />
    }
    if (p.type === 'task-totals' && p.value.addItemValue != null)
      return <TaskDeadlineCell task={p.value.addItemValue} />
    if (p.type === 'project-totals' && p.value.addItemValue != null)
      return <ProjectDeadlineCell project={p.value.addItemValue} />
    if (p.type === 'task') return <TaskDeadlineCell task={p.value} />
    if (p.type === 'project') return <ProjectDeadlineCell project={p.value} />

    return null
  },
  startDate(props) {
    const p = props.row.original.value
    if (p.value === null) return null

    const isTotalRow = p.type === 'task-totals' || p.type === 'project-totals'
    if (isTotalRow && p.value.addItemValue == null) {
      const items =
        p.type === 'task-totals'
          ? p.value.items.filter((task) => !isMeetingTask(task))
          : p.value.items

      const [fromDate, toDate] = getFirstAndLastFromArray<
        ProjectWithRelations | TaskWithRelations
      >(items, (item) => item.startDate)

      return <TotalDateRangeCell fromDate={fromDate} toDate={toDate} />
    }
    if (p.type === 'task-totals' && p.value.addItemValue != null)
      return <TaskStartDateCell task={p.value.addItemValue} />
    if (p.type === 'project-totals' && p.value.addItemValue != null)
      return <ProjectStartDateCell project={p.value.addItemValue} />

    if (p.type === 'task') return <TaskStartDateCell task={p.value} />

    if (p.type === 'project') return <ProjectStartDateCell project={p.value} />

    return null
  },
  stage(props) {
    const p = props.row.original.value
    if (p.value === null) return null

    if (p.type === 'task-totals' && p.value.addItemValue == null) {
      const groups = groupBy<TaskWithRelations>(p.value.items, (item) =>
        item.type === 'NORMAL' ? (item.stageDefinition?.name ?? 'none') : 'none'
      )
      const count = Object.keys(groups).length
      return (
        <TotalCountCell
          count={count}
          suffix={{
            one: 'stage',
            other: 'stages',
          }}
        />
      )
    }
    if (p.type === 'project-totals' && p.value.addItemValue == null) {
      const groups = groupBy<ProjectWithRelations>(
        p.value.items,
        (item) => item.activeStageDefinition?.name ?? 'none'
      )
      const count = Object.keys(groups).length
      return (
        <TotalCountCell
          count={count}
          suffix={{
            one: 'stage',
            other: 'stages',
          }}
        />
      )
    }
    if (p.type === 'task-totals' && p.value.addItemValue != null)
      return <TaskStageCell task={p.value.addItemValue} />
    if (p.type === 'project-totals' && p.value.addItemValue != null)
      return <ProjectStageCell project={p.value.addItemValue} />
    if (p.type === 'task') {
      return <TaskStageCell task={p.value} />
    }
    if (p.type === 'project') {
      return <ProjectStageCell project={p.value} />
    }

    return null
  },
  status(props) {
    const p = props.row.original.value
    if (p.value === null) return null

    if (
      (p.type === 'task-totals' || p.type === 'project-totals') &&
      p.value.addItemValue == null
    ) {
      const items =
        p.type === 'task-totals'
          ? p.value.items.filter((task) => !isMeetingTask(task))
          : p.value.items

      const groups = groupBy<ProjectWithRelations | TaskWithRelations>(
        items,
        (item) => item.status.name
      )
      const count = Object.keys(groups).length
      return (
        <TotalCountCell
          count={count}
          suffix={{
            one: 'status',
            other: 'statuses',
          }}
        />
      )
    }
    if (p.type === 'task-totals' && p.value.addItemValue != null)
      return <TaskStatusCell task={p.value.addItemValue} />
    if (p.type === 'project-totals' && p.value.addItemValue != null)
      return <ProjectStatusCell project={p.value.addItemValue} />
    if (p.type === 'task') {
      return <TaskStatusCell task={p.value} />
    }
    if (p.type === 'project') {
      return <ProjectStatusCell project={p.value} />
    }
    if (p.type === 'stage' && p.value?.id) {
      return (
        <StageStatusCell
          row={props.row.original}
          stageDefinitionId={p.value.id}
        />
      )
    }

    return null
  },
  priority(props) {
    const p = props.row.original.value
    if (p.value === null) return null
    if (
      (p.type === 'task-totals' || p.type === 'project-totals') &&
      p.value.addItemValue == null
    ) {
      const items =
        p.type === 'task-totals'
          ? p.value.items.filter((task) => !isMeetingTask(task))
          : p.value.items

      const groups = groupBy<ProjectWithRelations | TaskWithRelations>(
        items,
        (item) => item.priorityLevel
      )
      const totals = Object.keys(groups)
        .map((priorityLevel) => ({
          priorityLevel: priorityLevel as PriorityLevelType,
          count: groups[priorityLevel].length,
        }))
        .sort(byProperty('priorityLevel', ordered(priorityLevels)))

      return <TotalPrioritiesCell totals={totals} />
    }
    if (p.type === 'task-totals' && p.value.addItemValue != null)
      return <TaskPriorityCell task={p.value.addItemValue} />
    if (p.type === 'project-totals' && p.value.addItemValue != null)
      return <ProjectPriorityCell project={p.value.addItemValue} />
    if (p.type === 'task') {
      return <TaskPriorityCell task={p.value} />
    }
    if (p.type === 'project') {
      return <ProjectPriorityCell project={p.value} />
    }

    return null
  },
  completedDuration(props) {
    const p = props.row.original.value
    if (p.value === null) return null

    if (p.type === 'project' && p.value?.id) {
      return <ProjectCompletedDurationCell project={p.value} />
    }

    if (p.type === 'stage' && p.value?.id) {
      return (
        <StageCompletedDurationCell
          row={props.row.original}
          stageDefinitionId={p.value.id}
        />
      )
    }

    if (p.type === 'task-totals' && p.value.addItemValue == null) {
      const items = p.value.items.filter((task) => !isMeetingTask(task))

      const totalDuration = sumBy(items, (item) => {
        if (item.type !== 'CHUNK') {
          return item.completedDuration
        }
        return 0
      })

      return <TotalDurationCell duration={totalDuration} />
    }
    if (p.type === 'task-totals' && p.value.addItemValue != null)
      return <TaskCompletedDurationCell task={p.value.addItemValue} />
    if (p.type !== 'task') return null

    return <TaskCompletedDurationCell task={p.value} />
  },
  duration(props) {
    const p = props.row.original.value
    if (p.value === null) return null

    if (p.type === 'project' && p.value?.id) {
      return <ProjectTotalDurationCell project={p.value} />
    }

    if (p.type === 'stage' && p.value?.id) {
      return (
        <StageTotalDurationCell
          row={props.row.original}
          stageDefinitionId={p.value.id}
        />
      )
    }

    if (p.type === 'task-totals' && p.value.addItemValue == null) {
      const hasDuration = p.value.items.some(
        (item) => item.duration !== NO_DURATION
      )
      const totalDuration = hasDuration
        ? sumBy(p.value.items, (item) => item.duration ?? 0)
        : null

      return (
        <TotalDurationCell
          duration={totalDuration}
          formatter={formatToShortTaskDuration}
        />
      )
    }
    if (p.type === 'task-totals' && p.value.addItemValue != null)
      return <TaskDurationCell task={p.value.addItemValue} />
    if (p.type !== 'task') return null

    return <TaskDurationCell task={p.value} />
  },
  project(props) {
    const p = props.row.original.value

    if (p.type === 'task-totals' && p.value.addItemValue == null) return null
    if (p.type === 'task-totals' && p.value.addItemValue != null)
      return <ProjectCell task={p.value.addItemValue} />
    if (p.type !== 'task') return null

    return <ProjectCell task={p.value} />
  },

  assignee(props) {
    const p = props.row.original.value
    if (p.value === null) return null

    if (p.type === 'task-totals' && p.value.addItemValue == null) {
      const groups = groupInto(p.value.items, (item) =>
        String(item.assigneeUserId)
      )
      const count = groups.length

      return (
        <TotalCountCell
          count={count}
          suffix={{
            one: 'assignee',
            other: 'assignees',
          }}
        />
      )
    }
    if (p.type === 'project-totals' && p.value.addItemValue == null) {
      const groups = groupInto(p.value.items, (item) => String(item.managerId))
      const count = groups.length

      return (
        <TotalCountCell
          count={count}
          suffix={{
            one: 'assignee',
            other: 'assignees',
          }}
        />
      )
    }
    if (p.type === 'task-totals' && p.value.addItemValue != null)
      return <TaskAssigneeCell task={p.value.addItemValue} />
    if (p.type === 'project-totals' && p.value.addItemValue != null)
      return <ProjectAssigneeCell project={p.value.addItemValue} />
    if (p.type === 'task') {
      return <TaskAssigneeCell task={p.value} />
    }
    if (p.type === 'project') {
      return <ProjectAssigneeCell project={p.value} />
    }

    return null
  },
  guests(props) {
    const p = props.row.original.value

    if (p.value == null) return null
    if (p.type === 'task-totals' && p.value.addItemValue == null) {
      const allAttendees = p.value.items.flatMap((item) => {
        if (!isMeetingTask(item)) return []

        return item.meetingEvent?.attendees ?? []
      })
      const groups = groupInto(allAttendees, (attendee) => attendee.email)
      const count = groups.length

      return (
        <TotalCountCell
          count={count}
          suffix={{
            one: 'guest',
            other: 'guests',
          }}
        />
      )
    }

    if (p.type !== 'task') return null

    return <GuestsCells task={p.value} />
  },
  labels(props) {
    const p = props.row.original.value
    if (p.value === null) return null

    if (
      (p.type === 'task-totals' || p.type === 'project-totals') &&
      p.value.addItemValue == null
    ) {
      const count = Object.keys(
        p.value.items.reduce<Record<string, boolean>>((acc, item) => {
          if (item.type !== 'NORMAL') return acc
          item.labelIds.forEach((id) => {
            acc[id] = true
          })

          return acc
        }, {})
      ).length

      return (
        <TotalCountCell
          count={count}
          suffix={{
            one: 'label',
            other: 'labels',
          }}
        />
      )
    }
    if (p.type === 'task-totals' && p.value.addItemValue != null)
      return <TaskLabelsCell task={p.value.addItemValue} />
    if (p.type === 'project-totals' && p.value.addItemValue != null)
      return <ProjectLabelsCell project={p.value.addItemValue} />
    if (p.type === 'task') return <TaskLabelsCell task={p.value} />
    if (p.type === 'project') return <ProjectLabelsCell project={p.value} />

    return null
  },
  blockedBy(props) {
    const p = props.row.original.value
    if (p.value === null) return null

    if (p.type === 'task-totals' && p.value.addItemValue != null)
      return <TaskBlockedByCell task={p.value.addItemValue} />
    if (p.type === 'task') return <TaskBlockedByCell task={p.value} />

    return null
  },
  blocking(props) {
    const p = props.row.original.value
    if (p.value === null) return null

    if (p.type === 'task-totals' && p.value.addItemValue != null)
      return <TaskBlockingCell task={p.value.addItemValue} />
    if (p.type === 'task') return <TaskBlockingCell task={p.value} />

    return null
  },
  createdAt(props) {
    const p = props.row.original.value
    if (p.value === null) return null
    if (
      (p.type === 'task-totals' || p.type === 'project-totals') &&
      p.value.addItemValue != null
    )
      return <DateTimeCell dateTime={p.value.addItemValue.createdTime} />

    if (p.type !== 'task' && p.type !== 'project') return null

    return <DateTimeCell dateTime={p.value.createdTime} />
  },
  updatedAt(props) {
    const p = props.row.original.value
    if (p.value === null) return null
    if (p.type !== 'task' && p.type !== 'project') return null

    const updatedTime =
      p.type === 'task'
        ? (p.value.lastInteractedTime ?? null)
        : p.value.updatedTime

    return <DateTimeCell dateTime={updatedTime} />
  },
  completedAt(props) {
    const p = props.row.original.value
    if (p.value === null) return null
    if (p.type !== 'task') return null

    return <DateTimeCell dateTime={p.value.completedTime} />
  },
  estimatedCompletionTime(props) {
    const p = props.row.original.value
    if (p.value === null) return null
    if (p.type !== 'task' || !('estimatedCompletionTime' in p.value))
      return null

    return <DateTimeCell dateTime={p.value.estimatedCompletionTime} />
  },
  createdBy(props) {
    const p = props.row.original.value
    if (p.value === null) return null

    if (
      (p.type === 'task-totals' || p.type === 'project-totals') &&
      p.value.addItemValue == null
    ) {
      const groups = groupInto<ProjectWithRelations | TaskWithRelations>(
        p.value.items,
        (item) => String(item.createdByUserId)
      )
      const count = groups.length

      return (
        <TotalCountCell
          count={count}
          suffix={{
            one: 'user',
            other: 'users',
          }}
        />
      )
    }
    if (
      (p.type === 'task-totals' || p.type === 'project-totals') &&
      p.value.addItemValue != null
    )
      return <CreatedByCell value={p.value.addItemValue.createdByUser} />

    if (p.type === 'task' || p.type === 'project') {
      return <CreatedByCell value={p.value.createdByUser} />
    }

    return null
  },
  schedule(props) {
    const p = props.row.original.value
    if (p.value === null) return null
    if (p.type === 'task-totals' && p.value.addItemValue != null)
      return <TaskScheduleCell task={p.value.addItemValue} />
    if (p.type !== 'task') return null

    return <TaskScheduleCell task={p.value} />
  },
  workspace(info) {
    const p = info.row.original.value
    if (p.value == null) return null
    if (
      (p.type === 'task-totals' || p.type === 'project-totals') &&
      p.value.addItemValue != null
    )
      return <WorkspaceCell item={p.value.addItemValue} />
    if (p.type !== 'task' && p.type !== 'project') return null

    return <WorkspaceCell item={p.value} />
  },
  projectDefinition(props) {
    const p = props.row.original.value
    if (p.value == null) return null

    if (p.type === 'project') {
      return <ProjectDefinitionCell item={p.value} />
    }

    return null
  },
  folder(props) {
    const p = props.row.original.value
    if (p.value == null) return null

    if (p.type === 'project-totals' && p.value.addItemValue != null)
      return <ProjectFolderCell item={p.value.addItemValue} />

    if (p.type === 'project') {
      return <ProjectFolderCell item={p.value} />
    }

    if (p.type === 'task') return <TaskFolderCell item={p.value} />

    return null
  },
  deadlineStatus(props) {
    const p = props.row.original.value
    if (p.value == null) return null

    switch (p.type) {
      case 'task':
        return <ETACell item={p.value} type={p.type} />
      case 'project':
        return <ETACell item={p.value} type={p.type} />
      case 'stage':
        return (
          <ETAStageCell
            row={props.row.original}
            stageDefinitionId={p.value.id}
          />
        )
      default:
        return null
    }
  },
  color(props) {
    const p = props.row.original.value

    if (p.value != null && p.type === 'project') {
      return <ColorCell item={p.value} />
    }

    return null
  },
  attachments(props) {
    const p = props.row.original.value
    if (p.value == null) return null

    if (p.type === 'project') return <ProjectAttachmentsCell item={p.value} />

    if (p.type === 'task') return <TaskAttachmentsCell item={p.value} />

    return null
  },
}

function getFirstAndLastFromArray<T>(
  items: T[],
  by: (item: T) => string | null
): [string | undefined, string | undefined] {
  const sortedBy = items.map(by).filter(Boolean).sort(Compare.string)
  return [sortedBy[0], sortedBy[sortedBy.length - 1]]
}
