import {
  ActionList,
  PopoverButton,
  PopoverTrigger,
  SearchableList,
} from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'
import {
  MAX_PRO_TEAM_INITIAL_SIZE,
  TEAM_BILLING_BUCKETS,
} from '@motion/ui-logic/billing'

import { useEnterpriseLink } from '../hooks'

type BucketSeatsDropdownProps = {
  selectedBucket: number
  onChange: (bucket: number) => void
  minSeats?: number
  overrideMaxSeats?: number
}

export const BucketSeatsDropdown = ({
  selectedBucket,
  onChange,
  minSeats = 0,
  overrideMaxSeats,
}: BucketSeatsDropdownProps) => {
  const maxSeats = overrideMaxSeats ?? MAX_PRO_TEAM_INITIAL_SIZE

  let possibleSeats = TEAM_BILLING_BUCKETS.filter(
    (x) => x >= minSeats && x <= maxSeats
  )
  const showEnterpriseUpsell = minSeats <= maxSeats

  const openEnterpriseLink = useEnterpriseLink()

  return (
    <PopoverTrigger
      placement='bottom'
      renderPopover={({ close }) => (
        <>
          <SearchableList
            searchable={false}
            items={possibleSeats}
            computeKey={(item) => item.toString()}
            computeSelected={(item) => item === selectedBucket}
            onSelect={(item) => {
              onChange(item)
              close()
            }}
            renderItem={(item) => {
              return <span className='text-sm'>{item} seats</span>
            }}
          />

          {showEnterpriseUpsell && (
            <div className='flex flex-col border-t border-dropdown-border mt-1'>
              <ActionList
                onActionAnyItem={close}
                items={[
                  {
                    content: (
                      <div className='flex flex-col gap-1.5'>
                        <p className='text-dropdown-item-text-default text-sm'>
                          {templateStr('{{maxSize}}+ seats (Enterprise plan)', {
                            maxSize: maxSeats,
                          })}
                        </p>
                        <p className='text-semantic-primary-text-default text-xs'>
                          Book a call with our sales team
                        </p>
                      </div>
                    ),
                    onAction: () => openEnterpriseLink('bucketSeatsDropdown'),
                  },
                ]}
              />
            </div>
          )}
        </>
      )}
    >
      <PopoverButton className='w-[95px] h-7 text-xs'>
        {selectedBucket} seats
      </PopoverButton>
    </PopoverTrigger>
  )
}
