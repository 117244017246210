import { getDefaultChunkDuration } from '@motion/ui-logic'
import type { FlowTemplateFormFields } from '@motion/ui-logic/pm/project'

import { type UseFormReturn } from 'react-hook-form'

import { getIndexFromName } from '../../get-index-from-name'

export const handleTaskDurationUpdate = (
  form: UseFormReturn<FlowTemplateFormFields>,
  name: string
) => {
  const { stageIndex, taskIndex } = getIndexFromName(name)
  if (stageIndex == null || taskIndex == null) return

  const duration = form.getValues(
    `stages.${stageIndex}.tasks.${taskIndex}.duration`
  )

  form.setValue(
    `stages.${stageIndex}.tasks.${taskIndex}.minimumDuration`,
    getDefaultChunkDuration(duration)
  )
}
