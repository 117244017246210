import { ModalFieldButton } from '~/areas/task-project/components'
import { ColorDropdown } from '~/global/components/dropdowns'
import { ColorLabel } from '~/global/components/labels'

import { useNumberChartSettingField } from '../../contexts'

export function ChartColorField() {
  const [color, setColor] = useNumberChartSettingField('color')

  const selectedColor = color?.value ?? 'gray'

  return (
    <ColorDropdown
      selectedColor={selectedColor}
      onChange={(color) => setColor({ type: 'static', value: color })}
      onClear={() => setColor(null)}
    >
      <ModalFieldButton label='Color' onClear={() => setColor(null)}>
        <ColorLabel color={selectedColor}>
          {color == null ? 'Unset' : undefined}
        </ColorLabel>
      </ModalFieldButton>
    </ColorDropdown>
  )
}
