import { type COLOR, COLORS } from '@motion/shared/common'
import { ActionList, Button, ColorTag, PopoverTrigger } from '@motion/ui/base'

import { type ReactNode, useState } from 'react'

import { ColorItemBadge } from '../badges'

export type ColorDropdownProps = Omit<ColorDropdownContentProps, 'close'> & {
  children: ReactNode
}

export const ColorDropdown = (props: ColorDropdownProps) => {
  const { children, ...rest } = props

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <ColorDropdownContent close={close} {...rest} />
      )}
    >
      {children}
    </PopoverTrigger>
  )
}

export type ColorDropdownContentProps = {
  selectedColor: COLOR
  onChange: (color: COLOR) => void
  onClear?: () => void
  close?: () => void
}

export function ColorDropdownContent(props: ColorDropdownContentProps) {
  const { selectedColor, onChange, onClear, close } = props

  const [hoverColor, setHoverColor] = useState<COLOR | null>(null)

  const handleColorOptionMouseEnter = (color: COLOR) => () =>
    setHoverColor(color)

  const handleColorOptionMouseLeave = () => setHoverColor(null)

  return (
    <div className='flex flex-col'>
      <div className='flex flex-col gap-1 p-2'>
        <div className='flex max-w-[200px] items-start content-start flex-wrap'>
          {COLORS.map((color) => {
            const isSelected = color === selectedColor
            return (
              <Button
                key={color}
                iconOnly
                onClick={() => {
                  onChange(color)
                  close?.()
                }}
                size='small'
                sentiment='neutral'
                variant='muted'
                onMouseEnter={handleColorOptionMouseEnter(color)}
                onMouseLeave={handleColorOptionMouseLeave}
              >
                <ColorItemBadge color={color} selected={isSelected} />
              </Button>
            )
          })}
        </div>
        <div>
          <ColorTag color={hoverColor ?? selectedColor} />
        </div>
      </div>
      {onClear != null && (
        <div className='border-t border-dropdown-border mt-1'>
          <ActionList
            items={[
              {
                content: `Remove color`,
                onAction: () => {
                  onClear()
                  close?.()
                },
              },
            ]}
          />
        </div>
      )}
    </div>
  )
}
