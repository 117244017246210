import { createContext } from 'react'

export type ColumnVisibility = {
  deadline: boolean
  autoschedule: boolean
  duration: boolean
  priority: boolean
  assignee: boolean
}

export type Column = keyof ColumnVisibility

export interface TreeListOptionsContextValue {
  columnsVisibility: ColumnVisibility
}

export const defaultTreeListOptionsValue: TreeListOptionsContextValue = {
  columnsVisibility: {
    deadline: true,
    autoschedule: true,
    duration: true,
    priority: true,
    assignee: true,
  },
}

export const TreeListOptionsContext = createContext(defaultTreeListOptionsValue)
