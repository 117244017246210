import type { VersionedViewV2 } from '@motion/zod/client'

import { createContext } from 'react'

import { type ViewType } from '../utils'

type ViewTabsContextType = {
  selectedViewTypes: Set<ViewType>
  setSelectedViewTypes: (viewTypes: Set<ViewType>) => void
  filteredViews: VersionedViewV2[]
}

export const ViewTabsContext = createContext<ViewTabsContextType>({
  selectedViewTypes: new Set(),
  setSelectedViewTypes: () => {},
  filteredViews: [],
})
