import { createEvent } from '@motion/react-core/hooks'
import { type ProjectSchema, type TaskSchema } from '@motion/zod/client'

import { useUploadWorkspaceImage } from '~/areas/project-management/hooks'
import { useCallback, useMemo, useRef, useState } from 'react'

import {
  ActivityFeedContext,
  type ActivityFeedContextApi,
} from './activity-feed-context'

import { type ActivityFeedType, type ReplyActionPayload } from '../types'

export type ActivityFeedProviderProps = {
  children: React.ReactNode
  workspaceId: string
} & ActivityFeedType

export function ActivityFeedProvider({
  children,
  type,
  target,
  workspaceId,
}: ActivityFeedProviderProps) {
  const [editingCommentId, setEditingCommentId] = useState<string | null>(null)
  const onUploadImage = useUploadWorkspaceImage(workspaceId, {
    taskId: type === 'task' ? target.id : '',
    projectId: type === 'project' ? target.id : '',
    isRecurringTask: false,
  })

  const replyActionCallback =
    useRef<ReturnType<typeof createEvent<ReplyActionPayload>>>(
      createEvent<ReplyActionPayload>()
    )

  const onTriggerReplyAction = useCallback(
    (fn: (payload: ReplyActionPayload) => void) => {
      replyActionCallback.current.subscribe(fn)

      return () => {
        replyActionCallback.current.unsubscribe(fn)
      }
    },
    []
  )

  const triggerReplyAction = useCallback((payload: ReplyActionPayload) => {
    replyActionCallback.current.fire(payload)
  }, [])

  const value = useMemo<ActivityFeedContextApi>(() => {
    if (type === 'project') {
      return {
        workspaceId,
        type,
        target: target as ProjectSchema,
        onUploadImage,
        editingCommentId,
        setEditingCommentId,
        onReplyAction: onTriggerReplyAction,
        triggerReplyAction,
      }
    }

    return {
      workspaceId,
      type,
      target: target as TaskSchema,
      onUploadImage,
      editingCommentId,
      setEditingCommentId,
      onReplyAction: onTriggerReplyAction,
      triggerReplyAction,
    }
  }, [
    type,
    workspaceId,
    target,
    onUploadImage,
    editingCommentId,
    onTriggerReplyAction,
    triggerReplyAction,
  ])

  return (
    <ActivityFeedContext.Provider value={value}>
      {children}
    </ActivityFeedContext.Provider>
  )
}
