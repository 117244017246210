import {
  type UploadedFileSchema,
  type UploadFileRequest,
} from '@motion/rpc-types'
import {
  SUPPORTED_MIME_TYPES,
  type SupportedMimeType,
} from '@motion/shared/files'
import { showToast } from '@motion/ui/base'
import { isFileTooLarge, maxUploadFileSizeMB } from '@motion/ui-logic'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useI18N } from '~/global/contexts'
import { useRef } from 'react'

import { useUploadAttachments } from './hooks'

export type AttachmentUploadShellProps = {
  targetId?: UploadFileRequest['targetId']
  targetType?: UploadFileRequest['targetType']
  workspaceId: UploadFileRequest['workspaceId']
  onUpload?: (files: File[]) => void
  onUploadSettled?: (uploadedFile: UploadedFileSchema) => void
  children: (props: { trigger: () => void }) => React.ReactNode
  /**
   * Supported mime types for the file input.
   * Defaults to all mime types.
   */
  supportedMimeTypes?: SupportedMimeType[]
}

/**
 * Provides a wrapper/shell component for uploading attachments.
 * Mainly used with a button component to trigger the file input.
 * See `AttachmentUploadButton` for an example.
 */
export const AttachmentUploadShell = ({
  targetId,
  targetType,
  workspaceId,
  onUpload,
  onUploadSettled,
  children,
  supportedMimeTypes,
}: AttachmentUploadShellProps) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const { pluralize } = useI18N()

  const uploadAttachments = useUploadAttachments({
    targetId,
    targetType,
    workspaceId,
    onUploadSettled,
  })

  const handleAttachmentUploadClick = () => {
    fileInputRef.current?.click()
  }

  const handleAttachmentUploadChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = Array.from(e.target.files || [])

    // We want to log an event for all files selected regardless
    // of whether they pass the size check or not
    files.forEach((file) => {
      recordAnalyticsEvent('ATTACHMENT_FILE_UPLOAD', {
        file_size: file.size,
        mime_type: file.type,
        target_type: targetType ?? 'none',
      })
    })

    const filesOverSizeLimit: File[] = []
    const filesUnderSizeLimit: File[] = []

    // Check all files are under the size limit
    files.forEach((file) =>
      isFileTooLarge(file.size)
        ? filesOverSizeLimit.push(file)
        : filesUnderSizeLimit.push(file)
    )

    if (filesOverSizeLimit.length > 0) {
      if (filesOverSizeLimit.length === files.length) {
        showToast(
          'error',
          `Attachments must be under ${maxUploadFileSizeMB()}MB.`
        )
      } else {
        filesOverSizeLimit.forEach((file) => {
          showToast(
            'error',
            `${file.name} exceeds the ${maxUploadFileSizeMB()}MB limit.`
          )
        })
      }
    }

    if (filesUnderSizeLimit.length === 0) return

    onUpload?.(filesUnderSizeLimit)

    // Upload attachments
    const { failedFileUploads } = await uploadAttachments(filesUnderSizeLimit)

    if (filesUnderSizeLimit.length === failedFileUploads.length) {
      showToast('error', 'Upload failed. Please try again.')
    } else if (failedFileUploads.length > 0) {
      showToast('neutral', 'Some attachments failed to upload.')
    } else {
      showToast(
        'success',
        `${pluralize(files.length, 'Attachment', 'Attachments')} uploaded successfully.`
      )
    }

    // Clear file input
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }

  return (
    <>
      <input
        ref={fileInputRef}
        type='file'
        onChange={handleAttachmentUploadChange}
        accept={(supportedMimeTypes ?? SUPPORTED_MIME_TYPES).join(',')}
        multiple
        hidden
      />
      {children({ trigger: handleAttachmentUploadClick })}
    </>
  )
}
