import { useSharedState } from '@motion/react-core/shared-state'
import { type CustomFieldValuesRecord } from '@motion/rpc/types'
import { findDefaultStatus } from '@motion/shared/common'
import { isNoneId } from '@motion/shared/identifiers'
import { adjustTaskDueDate } from '@motion/ui-logic/pm/task'
import { keys } from '@motion/utils/object'
import {
  type PriorityLevelSchema,
  type StageDefinitionSchema,
  type StatusSchema,
} from '@motion/zod/client'

import { useProjectStageByProjectAndDefinitionIdFn } from '~/areas/project/hooks/data'
import { useFieldFilter } from '~/areas/project-management/filters'
import { getTaskDefaultDatesWithErrorLogging } from '~/areas/tasks/utils'
import { AppWorkspaceContext } from '~/global/contexts'
import { useWorkspaceFns } from '~/global/hooks'
import { type NormalTaskWithRelations } from '~/global/proxies'
import { DateTime } from 'luxon'
import { useCallback } from 'react'

import { useInferWorkspaceIdFromFilters } from './shared'
import {
  type FilterFunctionOpts,
  findByName,
  makeDateFilterFunction,
  makeIdFilterFunction,
  type TaskInferItem,
} from './utils'

import {
  getCustomFieldValuesFromGroupParents,
  useWorkspaceCustomFieldsByTypedName,
} from '../../../grouping/custom-fields'
import { usePageData } from '../../../routes'
import { type PageParamsOverrides } from '../../../routes/types'

export type InferTaskDataHandler = (
  item: TaskInferItem,
  workspaceIdArg?: string
) => {
  workspaceId: string | null
  projectId: string | null
  assigneeId: string | null
  priorityLevel: PriorityLevelSchema | null
  statusId: string
  startDate: string | null
  deadline: string | null
  labelId: string | null
  customFieldValues: CustomFieldValuesRecord | null
  stageDefinitionId: StageDefinitionSchema['id'] | null
  isAutoScheduled: boolean | null
}

export type UseInferTaskDataHandlerArgs = {
  useLastItemFallback?: boolean
}
export function useInferTaskDataHandler({
  useLastItemFallback,
}: UseInferTaskDataHandlerArgs = {}): InferTaskDataHandler {
  const data = usePageData()
  const [ctx] = useSharedState(AppWorkspaceContext)
  const { getWorkspaceStatuses, getWorkspaceLabels } = useWorkspaceFns()
  const getWorkspaceCustomFieldsByTypedName =
    useWorkspaceCustomFieldsByTypedName()

  const getProjectIdFromFilters = useInferProjectIdFromFilters(data.overrides, {
    useLastItemFallback,
  })
  const getWorkspaceIdFromFilters = useInferWorkspaceIdFromFilters(
    data.overrides,
    { useLastItemFallback }
  )
  const getAssigneeIdFromFilters = useInferAssigneeIdFromFilters({
    useLastItemFallback,
  })
  const getPriorityFromFilters = useInferPriorityFromFilters({
    useLastItemFallback,
  })
  const getStatusIdFromFilters = useInferStatusIdFromFilters({
    useLastItemFallback,
  })
  const getLabelIdFromFilters = useInferLabelIdFromFilters({
    useLastItemFallback,
  })
  const getDeadlineFromFilters = useInferDeadlineFromFilters({
    useLastItemFallback,
  })
  const getStageIdFromFilters = useInferStageIdFromFilters({
    useLastItemFallback,
  })

  const getProjectStageByProjectAndDefinitionId =
    useProjectStageByProjectAndDefinitionIdFn()

  return useCallback(
    (item, workspaceIdArg) => {
      const { parents } = item

      const maybeProjectId =
        parents.project?.value?.id ?? getProjectIdFromFilters(item.value.items)
      const projectId =
        maybeProjectId && !isNoneId(maybeProjectId) ? maybeProjectId : null
      const project = projectId ? ctx.projects.byId[projectId] : null
      const projectWorkspaceId = project?.workspaceId ?? null

      const assigneeId =
        parents.user?.value?.id ?? getAssigneeIdFromFilters(item.value.items)
      const priorityLevel =
        parents.priority?.value ??
        (getPriorityFromFilters(item.value.items) as PriorityLevelSchema | null)

      let maybeStartDate =
        parents.startDate?.value ??
        getTaskDefaultDatesWithErrorLogging({
          project,
          stageDefinitionId:
            parents.stage?.value?.id ?? project?.activeStageDefinitionId,
        }).startDate ??
        null

      // To ISO Date string
      const startDate = DateTime.isDateTime(maybeStartDate)
        ? maybeStartDate.toISODate()
        : maybeStartDate

      let maybeDeadline =
        parents.deadline?.value ??
        (getDeadlineFromFilters(item.value.items) as string | null) ??
        getTaskDefaultDatesWithErrorLogging({
          project,
          stageDefinitionId:
            parents.stage?.value?.id ?? project?.activeStageDefinitionId,
        }).dueDate ??
        null

      // To ISO string
      let deadline = DateTime.isDateTime(maybeDeadline)
        ? maybeDeadline.toISO()
        : maybeDeadline

      // Ensure due date is not before start date
      deadline = adjustTaskDueDate(startDate, deadline)

      const workspaceId =
        workspaceIdArg ??
        parents.workspace?.value?.id ??
        projectWorkspaceId ??
        getWorkspaceIdFromFilters(item.value.items)

      const workspaceStatuses: StatusSchema[] = getWorkspaceStatuses(
        workspaceId ?? ''
      )
      const statusId =
        findByName(workspaceStatuses, parents.status?.value.name)?.id ??
        getStatusIdFromFilters(item.value.items) ??
        findDefaultStatus(workspaceStatuses)?.id ??
        'unknown'

      const workspaceLabels = getWorkspaceLabels(workspaceId ?? '')
      const labelId =
        findByName(workspaceLabels, parents.label?.value.name)?.id ??
        getLabelIdFromFilters(item.value.items as NormalTaskWithRelations[]) ??
        null

      const customFields = getWorkspaceCustomFieldsByTypedName(workspaceId)

      const customFieldValues = getCustomFieldValuesFromGroupParents(
        parents,
        customFields
      )

      const possibleWrongStageDefId = parents.stage?.value?.id
      const stageDefinitionId =
        getProjectStageByProjectAndDefinitionId(
          project,
          possibleWrongStageDefId
        )?.stageDefinitionId ??
        possibleWrongStageDefId ??
        getStageIdFromFilters(
          item.value.items.filter((t) => t.type === 'NORMAL')
        )

      let isAutoScheduled: boolean | null = null

      if (useLastItemFallback) {
        const lastItem = item.value.items[item.value.items.length - 1]
        if (lastItem.type === 'NORMAL') {
          isAutoScheduled = lastItem.isAutoScheduled
        }
      }

      return {
        workspaceId,
        projectId,
        assigneeId: !assigneeId || isNoneId(assigneeId) ? null : assigneeId,
        priorityLevel,
        statusId,
        startDate,
        deadline,
        labelId,
        customFieldValues:
          keys(customFieldValues).length > 0 ? customFieldValues : null,
        stageDefinitionId,
        isAutoScheduled: isAutoScheduled,
      }
    },
    [
      ctx.projects.byId,
      getAssigneeIdFromFilters,
      getDeadlineFromFilters,
      getLabelIdFromFilters,
      getPriorityFromFilters,
      getProjectIdFromFilters,
      getProjectStageByProjectAndDefinitionId,
      getStageIdFromFilters,
      getStatusIdFromFilters,
      getWorkspaceCustomFieldsByTypedName,
      getWorkspaceIdFromFilters,
      getWorkspaceLabels,
      getWorkspaceStatuses,
      useLastItemFallback,
    ]
  )
}

export function useInferProjectIdFromFilters(
  filterOverrides: PageParamsOverrides,
  { useLastItemFallback }: FilterFunctionOpts
) {
  const [projectIds] = useFieldFilter('projects', 'ids')
  return makeIdFilterFunction(
    filterOverrides.projects?.ids ?? projectIds,
    'projectId',
    { useLastItemFallback }
  )
}

function useInferAssigneeIdFromFilters({
  useLastItemFallback,
}: FilterFunctionOpts) {
  const [assigneeIds] = useFieldFilter('tasks', 'assigneeUserIds')
  return makeIdFilterFunction(assigneeIds, 'assigneeUserId', {
    useLastItemFallback,
  })
}

function useInferPriorityFromFilters({
  useLastItemFallback,
}: FilterFunctionOpts) {
  const [priorities] = useFieldFilter('tasks', 'priorities')
  return makeIdFilterFunction(priorities, 'priorityLevel', {
    useLastItemFallback,
  })
}

function useInferStatusIdFromFilters({
  useLastItemFallback,
}: FilterFunctionOpts) {
  const [statusIds] = useFieldFilter('tasks', 'statusIds')
  return makeIdFilterFunction(statusIds, 'statusId', {
    useLastItemFallback,
  })
}

function useInferLabelIdFromFilters({
  useLastItemFallback,
}: FilterFunctionOpts) {
  const [labelIds] = useFieldFilter('tasks', 'labelIds')
  return makeIdFilterFunction(labelIds, 'labelIds', {
    useLastItemFallback,
  })
}

function useInferDeadlineFromFilters({
  useLastItemFallback,
}: FilterFunctionOpts) {
  const [dueDate] = useFieldFilter('tasks', 'dueDate')

  return makeDateFilterFunction(dueDate, 'dueDate', {
    useLastItemFallback,
  })
}

function useInferStageIdFromFilters({
  useLastItemFallback,
}: FilterFunctionOpts) {
  const [stageDefinitionIds] = useFieldFilter('tasks', 'stageDefinitionIds')
  return makeIdFilterFunction(stageDefinitionIds, 'stageDefinitionId', {
    useLastItemFallback,
  })
}
