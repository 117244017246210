import { type EmailAccount } from '@motion/rpc/types'
import { type ProviderType } from '@motion/shared/common'
import { BaseModal, showToast } from '@motion/ui/base'
import { isEmailValid } from '@motion/utils/string'
import { makeLog } from '@motion/web-base/logging'
import { type CalendarSchema } from '@motion/zod/client'

import { useAllCalendars } from '~/areas/calendar/hooks'
import { useOnboardingApi } from '~/areas/onboarding/state/use-onboarding-api'
import { useUpdateCalendars } from '~/global/rpc/v2'
import { useEffect, useState } from 'react'

import { LinkCalendarsIntro } from './link-calendars-intro'

import { updateCalendarList } from '../../../../state/calendar/calendarThunks'
import { selectEmailAccounts } from '../../../../state/email-accounts/email-accounts-slice'
import {
  deleteEmailAccount,
  setMainCalendar,
} from '../../../../state/email-accounts/email-accounts-thunks'
import { useAppDispatch, useAppSelector } from '../../../../state/hooks'
import { addAccount } from '../../../../utils/auth'
import { type ModalTriggerComponentProps } from '../../modal-trigger'
import { ConnectCalendars } from '../connect-calendars'
import { SelectMainCalendar } from '../select-main-calendar'
import { SelectMyCalendars } from '../select-my-calendars'

type LinkCalendarsModalProps = {
  onClose: () => void
}

const log = makeLog('link-calendars')

export type LinkCalendarsStep =
  | 'link_calendars_intro'
  | 'connect_calendar'
  | 'select_main_calendar'
  | 'select_my_calendars'

export const LinkCalendarsModal = ({ onClose }: LinkCalendarsModalProps) => {
  const dispatch = useAppDispatch()
  const emailAccounts = useAppSelector(selectEmailAccounts)
  const { calendars } = useAllCalendars()
  const { mutateAsync: updateCalendars } = useUpdateCalendars()
  const [step, setStep] = useState<LinkCalendarsStep>('link_calendars_intro')
  const [emailConnectionErrors, setEmailConnectionErrors] = useState<
    Record<string, boolean>
  >({})

  const dismissible = step === 'link_calendars_intro' || !emailAccounts.length

  useEffect(() => {
    // This is not ideal - we should be making this change as soon as auth
    // succeeds rather than after email accounts get updated in redux.
    if (emailAccounts.length && step === 'link_calendars_intro') {
      setStep('connect_calendar')
    }
  }, [step, emailAccounts])

  function onConnectAccount(provider?: ProviderType) {
    addAccount(provider)
    setEmailConnectionErrors({})
  }

  function onRemoveAccount(email: string) {
    setEmailConnectionErrors((prevState) => {
      if (prevState[email]) {
        return {}
      }
      return prevState
    })

    const emailAccount = emailAccounts.find((e) => e.email === email)
    if (!emailAccount) {
      log(`Error: could not find email account for email ${email}`)
      return
    }

    void dispatch(deleteEmailAccount(emailAccount.id))
    return
  }

  /**
   * @deprecated remove when kill firestore - onboarding is complete
   */
  async function onConnectCalendarsComplete() {
    try {
      await dispatch(updateCalendarList({ updateFrequentlyMet: true })).unwrap()
      setStep('select_main_calendar')
    } catch (err) {
      if (err instanceof Error && err.message) {
        const failedEmail = err.message.split(' ').pop()

        if (failedEmail && isEmailValid(failedEmail)) {
          showToast(
            'error',
            `We couldn’t connect "${failedEmail}". Please try re-connecting that calendar.`,
            { duration: 15_000 }
          )

          setEmailConnectionErrors((state) => ({
            ...state,
            [failedEmail]: true,
          }))
        }
      }
    }
  }

  async function onSelectMainCalendar(emailAccount: EmailAccount) {
    const res = await dispatch(setMainCalendar(emailAccount.id)).unwrap()
    if (res.assigned === false) {
      throw new Error(
        `The calendar for ${emailAccount.email} is currently the main calendar for another Motion account. ` +
          'Please make this calendar a non-main calendar in any other Motion accounts you may own if you want to use it here. ' +
          'You can contact our friendly support team if you have any further questions.'
      )
    }
    setStep('select_my_calendars')
  }

  async function onSelectedMyCalendars(updatedCalendars: CalendarSchema[]) {
    if (updatedCalendars.length) {
      await updateCalendars({
        calendars: updatedCalendars.map((c) => ({
          id: c.id,
          emailAccountId: c.emailAccountId,
          isInMyCalendars: true,
        })),
      })
    }

    onClose()
  }

  return (
    <BaseModal
      onClose={() => onClose()}
      visible
      disableEscapeKey={!dismissible}
      disableOverlayClick={!dismissible}
    >
      <div className='w-[600px] p-8'>
        {step === 'link_calendars_intro' && <LinkCalendarsIntro />}
        {step === 'connect_calendar' && (
          <ConnectCalendars
            accounts={emailAccounts}
            onConnectAccount={onConnectAccount}
            onRemoveAccount={onRemoveAccount}
            onComplete={onConnectCalendarsComplete}
            emailConnectionErrors={emailConnectionErrors}
            isInModal
          />
        )}
        {step === 'select_main_calendar' && (
          <SelectMainCalendar
            emailAccounts={emailAccounts}
            onBack={() => setStep('connect_calendar')}
            onSelectMainCalendar={onSelectMainCalendar}
            isInModal
          />
        )}
        {step === 'select_my_calendars' && (
          <SelectMyCalendars
            calendars={calendars}
            emailAccounts={emailAccounts}
            onBack={() => setStep('select_main_calendar')}
            onComplete={onSelectedMyCalendars}
            isInModal
          />
        )}
      </div>
    </BaseModal>
  )
}

export const ConnectedLinkCalendarsModal = ({
  close,
}: ModalTriggerComponentProps<'link-calendars'>) => {
  const dispatch = useAppDispatch()
  const { setIsInLinkCalendarsFlow } = useOnboardingApi()

  useEffect(() => {
    setIsInLinkCalendarsFlow(true)
  }, [dispatch, setIsInLinkCalendarsFlow])

  return (
    <LinkCalendarsModal
      onClose={() => {
        setIsInLinkCalendarsFlow(false)
        close()
      }}
    />
  )
}
