import { createUseMutation } from '@motion/rpc'
import { type MeetingInsightsQueryResponseSchema } from '@motion/rpc-types'
import { showToast } from '@motion/ui/base'
import { cloneDeep, omit } from '@motion/utils/core'
import { client } from '@motion/web-common/rpc'

import { type QueryKey } from '@tanstack/react-query'
import { showErrorToast } from '~/global/toasts'

import { updateMeetingInsights } from '../../rpc-definition'

const useUpdateMeetingInsightsMutation = createUseMutation(
  updateMeetingInsights
)

export function useUpdateMeetingInsights() {
  return useUpdateMeetingInsightsMutation({
    onSuccess: () => {
      showToast('success', 'Notetaker settings updated')
    },
    onMutate: async ({ meetingInsightsId, ...rest }) => {
      await client.cancelQueries(['meeting-insights'])

      const previousData =
        client.getQueriesData<MeetingInsightsQueryResponseSchema>([
          'meeting-insights',
        ])

      client.setQueriesData<MeetingInsightsQueryResponseSchema>(
        ['meeting-insights'],
        (oldData) => {
          if (oldData == null) return oldData

          const meetingInsights =
            oldData.models.meetingInsights[meetingInsightsId]

          if (meetingInsights == null) return oldData

          const copy = cloneDeep(oldData)

          copy.models.meetingInsights[meetingInsightsId] = {
            ...meetingInsights,
            ...omit(rest, 'isMeetingBotEnabled'),
            meetingBotStatus:
              rest.isMeetingBotEnabled != null
                ? rest.isMeetingBotEnabled
                  ? 'NEEDS_SCHEDULING'
                  : 'DONT_SCHEDULE'
                : meetingInsights.meetingBotStatus,
          }

          return copy
        }
      )

      return { previousData }
    },
    onError: (error, _, c) => {
      const context = c as {
        previousData: [QueryKey, MeetingInsightsQueryResponseSchema][]
      }

      if (context?.previousData != null) {
        context.previousData.forEach(([key, data]) => {
          client.setQueriesData([key], data)
        })
      }

      showErrorToast(error)
    },
  })
}
