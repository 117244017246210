import { useDependantState } from '@motion/react-core/hooks'
import { type VariantProps } from '@motion/theme'
import { getFormatter, type NumberInputFormat } from '@motion/ui/helpers'

import {
  forwardRef,
  type ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react'

import { type StyledField } from './styled-field'
import { TextInput, type TextInputProps } from './textinput'

import { NUMBER_RE, NumberParser } from '../helpers'

export type NumberInputProps = {
  value: number | null | undefined
  onChange?(value: number): void
  formatOptions?: NumberInputFormat
} & Pick<
  TextInputProps,
  | 'autoFocus'
  | 'defaultValue'
  | 'disabled'
  | 'maxLength'
  | 'name'
  | 'onBlur'
  | 'onFocus'
  | 'onClick'
  | 'onKeyDown'
  | 'onKeyUp'
  | 'placeholder'
  | 'readOnly'
  | 'required'
> &
  VariantProps<typeof StyledField> & {
    prefix?: ReactNode
    suffix?: ReactNode
    showClearButton?: boolean
    enableAutofill?: boolean
  }

export const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
  function NumberInput(props: NumberInputProps, ref) {
    const {
      value,
      onChange,
      formatOptions = { type: 'decimal', grouped: true, decimalPlaces: 2 },
      ...textInputProps
    } = props

    const parse = useCallback(
      (value: string) => parseValue(value, formatOptions),
      [formatOptions]
    )
    const [focused, setFocused] = useState(false)

    const [text, setText] = useDependantState<string>(
      (prev) => {
        if (prev == null) return numberToString(value)
        if (numberEqual(parse(prev), value ?? NaN)) return prev
        if (isEmpty(value)) return prev
        return numberToString(value)
      },
      [parse, value]
    )

    const formatter = useMemo(
      () => getFormatter(formatOptions),
      [formatOptions]
    )
    const formatted = useMemo(() => formatter(value), [formatter, value])

    const changeHandler = useCallback(
      (value: string): void => {
        setText(value)
        const num = parse(value)
        onChange?.(num)
      },
      [onChange, parse, setText]
    )

    const onKeyDownHandler: React.KeyboardEventHandler<HTMLInputElement> = (
      e
    ) => {
      textInputProps.onKeyDown?.(e)

      const {
        key,
        currentTarget: { selectionStart },
      } = e

      // Allow key combinations like 'Ctrl + C' or 'Cmd + V'
      if (e.metaKey || e.ctrlKey) return

      // Allow one '-' only at the beginning of the input
      if (key === '-' && selectionStart === 0) {
        return
      }

      // Allow only numbers and one decimal point
      // Calling 'e.preventDefault()' keeps the cursor in the same place while discarding invalid chars.
      if (
        (!NUMBER_RE.test(key) &&
          ![
            'Backspace',
            'ArrowLeft',
            'ArrowRight',
            'ArrowUp',
            'ArrowDown',
            'Tab',
          ].includes(key)) ||
        (key === '.' && text.includes('.'))
      ) {
        e.preventDefault()
      }
    }

    return (
      <TextInput
        ref={ref}
        {...textInputProps}
        value={focused ? text : formatted}
        onPaste={(e) => {
          e.preventDefault()
          const text = e.clipboardData.getData('text')
          const filteredValue = NumberParser.stripInvalidChars(text)
          changeHandler(filteredValue)
        }}
        onChange={changeHandler}
        onKeyDown={onKeyDownHandler}
        onFocus={(e) => {
          setFocused(true)
        }}
        onBlur={(e) => {
          setFocused(false)
          setText(numberToString(value))
          textInputProps.onBlur?.(e)
        }}
      />
    )
  }
)

function isEmpty(value: number | null | undefined) {
  return value == null || Number.isNaN(value)
}

function numberToString(value: number | null | undefined) {
  return isEmpty(value) ? '' : String(value)
}

function numberEqual(
  left: number | null | undefined,
  right: number | null | undefined
) {
  const leftIsNaN = isEmpty(left)
  const rightIsNaN = isEmpty(right)
  if (leftIsNaN || rightIsNaN) return leftIsNaN === rightIsNaN
  return left === right
}

function parseValue(text: string, opts: NumberInputFormat) {
  if (typeof opts === 'function') return parseFloat(text)

  const value = NumberParser.parse(text)
  if (opts.type === 'integer' || opts.decimalPlaces === 0)
    return Math.trunc(value)

  // Don't keep more than 6 decimal places
  return parseFloat(value.toFixed(6))
}
