import { QuestionMarkCircleSolid } from '@motion/icons'
import { type DeadlineType } from '@motion/rpc/types'
import { Toggle, Tooltip } from '@motion/ui/base'

import { ModalFieldButton } from '~/areas/task-project/components'
import { useController } from 'react-hook-form'

import { SubItemIndentMarker } from '../components'
import { useActionItemForm } from '../hooks'

const TOOLTIP_CONTENT =
  'If this is toggled on, Motion will prioritize this task and make sure it schedules before deadline; if needed, Motion may schedule it outside of work hours (or another selected schedule) in order to meet deadline.'

export const DeadlineToggle = () => {
  const { form } = useActionItemForm()

  const { control, watch } = form

  const { field: deadlineTypeField } = useController({
    name: 'deadlineType',
    control,
  })

  const priorityLevel = watch('priorityLevel')
  const deadlineType = deadlineTypeField.value

  const disabled = priorityLevel === 'ASAP'

  const onChange = (value: DeadlineType) => {
    deadlineTypeField.onChange(value)
  }

  const hardDeadlineField = (
    <ModalFieldButton
      label='Hard deadline'
      size='normal'
      variant='subtle'
      disabled={disabled}
    >
      <Tooltip content={disabled ? undefined : TOOLTIP_CONTENT}>
        <QuestionMarkCircleSolid />
      </Tooltip>
      <Toggle
        checked={deadlineType === 'HARD'}
        disabled={disabled}
        size='small'
        onChange={() => {
          onChange(deadlineType === 'HARD' ? 'SOFT' : 'HARD')
        }}
      />
    </ModalFieldButton>
  )

  return (
    <div className='flex flex-row items-center gap-0.5'>
      <SubItemIndentMarker />
      {hardDeadlineField}
    </div>
  )
}
