import { classed, type ComponentProps, type VariantProps } from '@motion/theme'

import { forwardRef } from 'react'

import { BaseButton } from './base-button'

const StyledButton = classed(BaseButton, {
  base: `
    disabled:cursor-not-allowed
  `,
  variants: {
    sentiment: {
      primary: `
        box-border
        border border-solid border-[#314FB1]
        bg-[linear-gradient(180deg,#4D70E5_0%,#5E7FEB_0.01%,#395CD2_100%)]
        hover:border-[#25409D] hover:bg-[linear-gradient(180deg,#4D70E5_0%,#456CED_0.01%,#264CC9_100%)]

        active:border-[#1C368D]
        active:bg-[linear-gradient(180deg,#4D70E5_0%,#2856F0_0.01%,#1D41B9_100%)]
        text-button-primary-solid-text-default
        hover:text-button-primary-solid-text-default
        shadow-[0_2px_0_0_rgba(255,255,255,0.20)_inset,0px_1px_4px_0px_rgba(0,0,0,0.20)]

        disabled:bg-none
        disabled:bg-button-primary-solid-bg-disabled
        disabled:shadow-none
        disabled:text-button-primary-solid-text-disabled
        disabled:border-button-primary-solid-border-disabled
      `,
      neutral: `
        bg-clip-border
        border border-solid
        border-semantic-neutral-border-hover

        button-gradient-neutral

        text-semantic-neutral-text-default
        hover:text-semantic-neutral-text-default

        shadow-[0px_1px_4px_rgba(0,0,0,0.04)]

        disabled:opacity-50
      `,
      promotion: `
        bg-promotion-gradient
        enabled:hover:bg-promotion-hover-gradient enabled:active:bg-promotion-active-gradient
        focus:border-button-neutral-outlined-border-focus
        text-semantic-gradient-purple

        disabled:opacity-50
      `,
      purple: `
        bg-purple-gradient
        active:border-button-primary-solid-border-focus
        text-semantic-neutral-text-onDark

        disabled:opacity-50
      `,
    },
  },
})

export type GradientButtonProps = ComponentProps<typeof StyledButton> &
  VariantProps<typeof StyledButton> & {
    // These props are not allowed
    className?: never
    style?: never
  }

export const GradientButton = forwardRef<
  HTMLButtonElement,
  GradientButtonProps
>(function GradientButton({ sentiment = 'primary', ...props }, ref) {
  return (
    <StyledButton ref={ref} sentiment={sentiment} {...props}>
      {props.children}
    </StyledButton>
  )
})
