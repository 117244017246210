import { isNoneId } from '@motion/shared/identifiers'

import { type CellContext } from '@tanstack/react-table'
import { type ReactNode } from 'react'

import { GroupRowCopyTasksButton, SelectRowCheckbox } from './components'
import { ExpandButton } from './components/expand-button'
import { ProjectNameAutoScheduleToggle } from './project-name-auto-schedule-toggle'
import { StageNameAutoScheduleToggle } from './stage-name-auto-schedule-toggle'

import { DateGroupCurrentTag } from '../../components'
import { type GroupedNode, isDateGroup } from '../../grouping'
import { type ViewStateGroupByField } from '../../view-state'
import { type TreeListRowValueType } from '../types'

type ExpandableHeaderProps = {
  cellSuffix: ReactNode
  cell: ReactNode
  info: CellContext<GroupedNode<TreeListRowValueType>, unknown>
}
export function ExpandableHeader({
  cellSuffix,
  cell,
  info,
}: ExpandableHeaderProps) {
  const item = info.row.original.value

  return (
    <div className='overflow-hidden flex gap-1 items-center relative px-2.5 w-full'>
      <SelectRowCheckbox info={info} />
      <div className='pl-[var(--indent)] flex gap-3 items-center relative overflow-hidden w-full'>
        <ExpandButton row={info.row} />
        <div className='overflow-hidden flex items-center'>{cell}</div>
        {isDateGroup(item) && <DateGroupCurrentTag item={item} />}
        <div className='text-semantic-neutral-text-subtle'>
          {info.row.original.count}
        </div>
        <div className='hidden group-hover/table-cell:block group-focus-within/table-cell:block'>
          <GroupRowCopyTasksButton
            depth={info.row.depth}
            originalRow={info.row.original}
          />
          {cellSuffix}
        </div>
      </div>
      <AutoScheduleItem info={info} item={item} />
    </div>
  )
}

type AutoScheduleItemProps = {
  info: CellContext<GroupedNode<TreeListRowValueType>, unknown>
  item: TreeListRowValueType & ViewStateGroupByField
}
function AutoScheduleItem({ info, item }: AutoScheduleItemProps) {
  const shouldShowStageAutoScheduleToggle =
    item.type === 'stage' && item.value != null && !isNoneId(item.value.id)

  const shouldShowProjectAutoScheduleToggle =
    item.type === 'project' && item.value != null && !isNoneId(item.value.id)

  if (
    !shouldShowStageAutoScheduleToggle &&
    !shouldShowProjectAutoScheduleToggle
  ) {
    return null
  }

  return (
    <div className='flex items-center justify-end'>
      {/* added unnecessary null check to satisfy typescript */}
      {shouldShowStageAutoScheduleToggle && item.value != null && (
        <StageNameAutoScheduleToggle
          row={info.row.original}
          stage={item.value}
        />
      )}
      {shouldShowProjectAutoScheduleToggle && (
        <ProjectNameAutoScheduleToggle row={info.row.original} />
      )}
    </div>
  )
}
