import { templateStr } from '@motion/ui-logic'
import {
  recordAnalyticsEvent,
  useOnMountAnalyticsEvent,
} from '@motion/web-base/analytics'
import {
  EnterpriseBillingPlanCard,
  TeamBillingPlanCard,
} from '@motion/web-billing'
import { useAuthenticatedUser } from '@motion/web-common/auth'

import { useIndividualSubscription } from '~/global/rpc'
import { useGetTeamPrices } from '~/global/rpc/team'
import { useToggleIntercom } from '~/utils/toggleIntercom'

export interface CreateTeamLandingProps {
  onClick: () => void
}

export function CreateTeamLanding({ onClick }: CreateTeamLandingProps) {
  const { data } = useGetTeamPrices()
  const subscription = useIndividualSubscription()
  const user = useAuthenticatedUser()
  const toggleIntercom = useToggleIntercom()

  const currency = subscription?.plan.currency

  useOnMountAnalyticsEvent('NON_USD_ON_CREATE_TEAM_PAGE', {
    enabled: !!currency && currency?.toLowerCase() !== 'usd',
  })

  return (
    <div className='flex h-full w-full pt-20 justify-center bg-semantic-neutral'>
      <div className='w-full px-6'>
        <h1 className='text-semantic-1200 mb-2 lg:mb-6 text-center text-2xl lg:text-[32px] font-bold leading-[42px] select-none'>
          Create a team
        </h1>
        <div className='flex flex-col sm:flex-row justify-center gap-4 items-center sm:items-stretch'>
          {!!currency && currency?.toLowerCase() !== 'usd' ? (
            <div className='flex flex-col gap-2 bg-semantic-neutral-bg-disabled p-4 rounded-lg'>
              <p className='text-semantic-neutral-text-default'>
                {templateStr(
                  'You are currently on a legacy individual plan. To upgrade to a team plan, please {{link}}.',
                  {
                    link: (
                      <a
                        className='text-semantic-purple-text-default'
                        onClick={() => {
                          toggleIntercom()
                          recordAnalyticsEvent(
                            'NON_USD_ON_CONTACT_SUPPORT_CLICK',
                            {
                              location: 'createTeamLanding',
                            }
                          )
                        }}
                        role='button'
                      >
                        contact support
                      </a>
                    ),
                  }
                )}
              </p>
            </div>
          ) : (
            <>
              <TeamBillingPlanCard
                onClick={onClick}
                price={data?.annualPricePerMonth}
                withBorder
                buttonText='Switch to Team Plan'
              />
              <EnterpriseBillingPlanCard
                withBorder
                location='createTeamLanding'
                email={user.email}
                displayName={user.displayName ?? undefined}
              />
            </>
          )}
        </div>
      </div>
    </div>
  )
}
