import { PopoverTrigger, SearchableList } from '@motion/ui/base'
import { type DashboardViewLineChartInterpolationSchema } from '@motion/zod/client'

import { ModalFieldButton } from '~/areas/task-project/components'

import { useLineChartSettingField } from '../../contexts'
import { ListItem } from '../list-item'

const INTERPOLATION_ITEMS = [
  {
    label: 'None',
    value: 'linear',
  },
  {
    label: 'Basis',
    value: 'basis',
  },
  {
    label: 'Basis Open',
    value: 'basisOpen',
  },
  {
    label: 'Bump X',
    value: 'bumpX',
  },
  {
    label: 'Bump Y',
    value: 'bumpY',
  },
  {
    label: 'Bump',
    value: 'bump',
  },
  {
    label: 'Natural',
    value: 'natural',
  },
  {
    label: 'Monotone',
    value: 'monotone',
  },
  {
    label: 'Monotone X',
    value: 'monotoneX',
  },
  {
    label: 'Monotone Y',
    value: 'monotoneY',
  },
  {
    label: 'Step',
    value: 'step',
  },
  {
    label: 'Step Before',
    value: 'stepBefore',
  },
  {
    label: 'Step After',
    value: 'stepAfter',
  },
] as const satisfies {
  label: string
  value: DashboardViewLineChartInterpolationSchema
}[]

export function ChartInterpolationField() {
  const [interpolation, setInterpolation] =
    useLineChartSettingField('interpolation')

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <SearchableList
          items={INTERPOLATION_ITEMS}
          computeKey={(item) => item.value}
          computeSelected={(item) => item.value === interpolation}
          computeSearchValue={(item) => item.label}
          onSelect={(item) => {
            setInterpolation(item.value)
            close()
          }}
          renderItem={(item) => <ListItem name={item.label} />}
        />
      )}
    >
      <ModalFieldButton label='Interpolation'>
        {
          INTERPOLATION_ITEMS.find((item) => item.value === interpolation)
            ?.label
        }
      </ModalFieldButton>
    </PopoverTrigger>
  )
}
