import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { getProxy } from './backgroundProxy'
import { teamsExtraReducers } from './projectManagement/teamReducers'
import { type RootState } from './types'

import {
  type UserNotificationsPayload,
  type UserNotificationsResponse,
} from '../services/userService/userNotificationsService'

const userServiceHandler = getProxy('UserService')

export type LoadingState =
  | 'preload'
  | 'loading'
  | 'loaded'
  | 'error'
  | 'forbidden'
  | 'refetching'
  | 'stale'

export interface ProjectManagementSlice {
  teamId: string | null
  teamFetched: boolean

  userNotificationPreferences: UserNotificationsResponse | null
}

export const initialProjectManagementState: ProjectManagementSlice = {
  teamFetched: false,

  teamId: null,

  userNotificationPreferences: null,
}

export const fetchUserNotificationPreferences = createAsyncThunk(
  'projectManagement/fetchUserNotificationPreferences',
  (): Promise<UserNotificationsResponse> => {
    return userServiceHandler.getNotificationPreferences()
  }
)

export const updateUserNotificationPreferences = createAsyncThunk(
  'projectManagement/updateUserNotificationPreferences',
  (payload: UserNotificationsPayload): Promise<UserNotificationsResponse> => {
    return userServiceHandler.updateNotificationPreferences(payload)
  }
)

export const mainSlice = createSlice({
  extraReducers: (builder) => {
    teamsExtraReducers(builder)

    /**
     *
     */
    builder.addCase(
      fetchUserNotificationPreferences.fulfilled,
      (state, action) => {
        state.userNotificationPreferences = action.payload
      }
    )
    builder.addCase(
      updateUserNotificationPreferences.fulfilled,
      (state, action) => {
        state.userNotificationPreferences = action.payload
      }
    )
  },
  initialState: initialProjectManagementState,
  name: 'projectManagement',
  reducers: {
    reset: () => initialProjectManagementState,
  },
})

export const { reset } = mainSlice.actions

export const selectPMTeamFetched = (state: RootState) =>
  state.projectManagement.teamFetched

export const selectUserNotificationPreferences = (state: RootState) =>
  state.projectManagement.userNotificationPreferences

export const projectManagementReducer = mainSlice.reducer
