import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useBulkUpdateTasks } from '~/areas/tasks/hooks'
import { useNonAutoSchedulableStatuses, useProject } from '~/global/hooks'
import { useCallback } from 'react'

import { useProjectTasks } from './use-project-tasks'

type UseAutoScheduleProjectProps = {
  projectId?: string
}
export const useAutoScheduleProject = ({
  projectId,
}: UseAutoScheduleProjectProps) => {
  const project = useProject(projectId)

  const bulkUpdateTasks = useBulkUpdateTasks()

  const { data: tasks } = useProjectTasks({
    projectId: project?.id,
    workspaceId: project?.workspaceId,
  })

  const autoScheduleDisabledStatusIds = useNonAutoSchedulableStatuses(
    project?.workspaceId
  ).map((status) => status.id)

  return useCallback(
    async (shouldAutoSchedule: boolean) => {
      const tasksToUpdate = tasks.filter(
        (task) =>
          !autoScheduleDisabledStatusIds.includes(task.statusId) &&
          task.assigneeUserId != null &&
          task.isAutoScheduled !== shouldAutoSchedule
      )

      if (project == null || !tasksToUpdate.length) return

      recordAnalyticsEvent('PROJECT_AUTO_SCHEDULE_TOGGLE', {
        numTasks: tasksToUpdate.length,
        autoScheduled: shouldAutoSchedule,
      })

      await bulkUpdateTasks([
        {
          currentWorkspaceId: project.workspaceId,
          taskIds: tasksToUpdate.map((task) => task.id),
          update: {
            type: 'bulk-field-update',
            isAutoScheduled: shouldAutoSchedule,
          },
        },
      ])
    },
    [tasks, project, bulkUpdateTasks, autoScheduleDisabledStatusIds]
  )
}
