import { PlusSolid } from '@motion/icons'
import { type COLOR, COLORS } from '@motion/shared/common'
import { type FieldTypeSchema } from '@motion/shared/custom-fields'
import { classed } from '@motion/theme'
import { Button } from '@motion/ui/base'
import {
  getNewColorName,
  getOptionValidationRules,
  labelColors,
} from '@motion/ui-logic'
import { useHasTreatment } from '@motion/web-common/flags'

import { LabelColoredIcon } from '~/areas/project-management/settings/common'
import { findUnusedColor } from '~/components/ProjectManagement/constants'
import { ColorItemBadge } from '~/global/components/badges'
import {
  ColorDropdown,
  LegacyColorDropdown,
} from '~/global/components/dropdowns'
import { useEffect } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'

import { OptionRow } from './option-row'

import { type CustomFieldFormFields } from '../form-utils'

export const OptionFields = () => {
  const { control, register, unregister, clearErrors, setValue, watch } =
    useFormContext<CustomFieldFormFields>()

  const hasNewColorsEnabled = useHasTreatment(
    'new-colors-for-labels-and-custom-fields'
  )

  const fieldType = watch('type') as Extract<
    FieldTypeSchema,
    'select' | 'multiSelect'
  >
  const rules = getOptionValidationRules(fieldType)
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'metadata.options',
  })

  const unusedColor = findUnusedColor(
    fields,
    hasNewColorsEnabled ? [...COLORS] : labelColors
  )
  useEffect(() => {
    if (fields.length === 0) {
      append({ value: '', color: unusedColor }, { shouldFocus: false })
    }
  }, [fields.length, append, unusedColor])

  useEffect(() => {
    return () => {
      unregister('metadata')
      clearErrors('metadata')
    }
  }, [unregister, clearErrors])

  return (
    <div>
      <LabelText>Options</LabelText>
      <div className='flex flex-col gap-2 pt-2'>
        {fields.map((field, index) => {
          const fieldColor = watch(`metadata.options.${index}.color`)

          const icon = hasNewColorsEnabled ? (
            <ColorDropdown
              selectedColor={getNewColorName(fieldColor)}
              onChange={(color: COLOR) => {
                setValue(`metadata.options.${index}.color`, color)
              }}
            >
              <Button
                iconOnly
                variant='outlined'
                sentiment='neutral'
                size='small'
              >
                <div className='px-[5px] py-1'>
                  <ColorItemBadge color={getNewColorName(fieldColor)} />
                </div>
              </Button>
            </ColorDropdown>
          ) : (
            <LegacyColorDropdown
              colorOptions={labelColors}
              renderItem={(color, onClose) => (
                <Button
                  iconOnly
                  key={color}
                  onClick={() => {
                    setValue(`metadata.options.${index}.color`, color)
                    onClose()
                  }}
                  size='small'
                  variant='muted'
                >
                  <LabelColoredIcon color={color} size='small' />
                </Button>
              )}
              trigger={
                <Button
                  iconOnly
                  variant='outlined'
                  sentiment='neutral'
                  size='small'
                >
                  <div className='px-[5px] py-1'>
                    <LabelColoredIcon color={fieldColor} size='small' />
                  </div>
                </Button>
              }
            />
          )

          return (
            <OptionRow
              key={field.id}
              icon={icon}
              onCancel={() => {
                unregister(`metadata.options.${index}.value`)
                clearErrors(`metadata.options.${index}.value`)
                remove(index)
              }}
              defaultValue={field.value}
              {...register(`metadata.options.${index}.value`, {
                ...rules,
              })}
            />
          )
        })}

        <div className='self-start'>
          <Button
            onClick={() =>
              fields.length < rules.maxOptions &&
              append({
                value: '',
                color: unusedColor,
              })
            }
            size='small'
            variant='muted'
          >
            <PlusSolid />
            Add option
          </Button>
        </div>
      </div>
    </div>
  )
}

const LabelText = classed('span', {
  base: `
    text-semantic-neutral-text-subtle text-xs
    mb-1.5
  `,
})
