import { createUseMutation } from '@motion/rpc'
import { client } from '@motion/web-common/rpc'

import { createAutomationPolicy } from '../../rpc-definition'

const useCreateAutomationPolicyMutation = createUseMutation(
  createAutomationPolicy
)

export function useCreateAutomationPolicy() {
  return useCreateAutomationPolicyMutation({
    onSuccess: () => {
      client.invalidateQueries(['automations'])
    },
  })
}
