import { createNoneId } from '@motion/shared/identifiers'
import { PopoverTrigger, SearchableList } from '@motion/ui/base'
import { type WorkspaceSchema } from '@motion/zod/client'

import {
  ModalFieldButton,
  type ModalFieldButtonProps,
} from '~/areas/ai-hackerhouse/event/modals/action-item-modal/components/modal-field-button'
import { WorkspaceBadge } from '~/global/components/badges'
import { useAllWorkspaces, useWorkspaceById } from '~/global/hooks'
import { type ReactNode } from 'react'

export type WorkspaceFieldProps = {
  workspaceId: WorkspaceSchema['id']
  onChange: (workspace: WorkspaceSchema) => void
  disabled?: boolean
  size?: ModalFieldButtonProps['size']
}

// START AIHH - This version allows "No workspace" to be selected
export const WorkspaceField = ({
  workspaceId,
  onChange,
  disabled,
  size = 'normal',
}: WorkspaceFieldProps) => {
  const selectedWorkspace = useWorkspaceById(workspaceId)

  return (
    <WorkspaceDropdown selectedWorkspaceId={workspaceId} onChange={onChange}>
      <ModalFieldButton
        size={size}
        icon={<WorkspaceBadge />}
        disabled={disabled}
      >
        {selectedWorkspace?.name ?? 'No workspace'}
      </ModalFieldButton>
    </WorkspaceDropdown>
  )
}

export type WorkspaceDropdownProps = Omit<
  WorkspaceDropdownContentProps,
  'close'
> & {
  withAllWorkspacesOption?: boolean
  children?: ReactNode
}

export const WorkspaceDropdown = (props: WorkspaceDropdownProps) => {
  const { children, ...rest } = props

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <WorkspaceDropdownContent
          close={close}
          {...rest}
          withNoWorkspaceOption
        />
      )}
    >
      {children}
    </PopoverTrigger>
  )
}

export const ALL_WORKSPACES_ID = 'all'

type WorkspaceDropdownContentProps = {
  close: () => void
  onChange: (workspace: WorkspaceSchema) => void
  selectedWorkspaceId: WorkspaceSchema['id'] | null
  withAllWorkspacesOption?: boolean
  withNoWorkspaceOption?: boolean
}
export const WorkspaceDropdownContent = ({
  close,
  onChange,
  selectedWorkspaceId,
  withAllWorkspacesOption = false,
  withNoWorkspaceOption = false,
}: WorkspaceDropdownContentProps) => {
  const workspaces = useAllWorkspaces({ scope: 'root' })

  const items = [
    withAllWorkspacesOption &&
      ({ id: ALL_WORKSPACES_ID, name: 'All workspaces' } as WorkspaceSchema),
    withNoWorkspaceOption &&
      ({
        id: createNoneId('workspace'),
        name: 'No workspace',
      } as WorkspaceSchema),
    ...workspaces,
  ].filter(Boolean)

  return (
    <SearchableList
      itemType='select'
      items={items}
      computeKey={(item) => item.id}
      computeSearchValue={(item) => item.name}
      computeSelected={(item) => item.id === selectedWorkspaceId}
      onSelect={(item) => {
        onChange(item)
        close()
      }}
      renderItem={(item) => (
        <span className='max-w-xs truncate'>{item.name}</span>
      )}
      inputProps={{ placeholder: 'Choose workspace...' }}
    />
  )
}

// END AIHH
