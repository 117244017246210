import { type ReactNode } from 'react'

export function joinElements(elements: ReactNode[], separator: ReactNode) {
  return elements.reduce<ReactNode[]>((acc, el, index) => {
    if (index === 0) {
      return [el]
    }

    return [...acc, separator, el]
  }, [])
}
