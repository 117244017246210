import { isObjectNoneId } from '@motion/shared/identifiers'
import { classed } from '@motion/theme'
import { ProjectPalette } from '@motion/ui/project'
import { type StageDefinitionSchema } from '@motion/zod/client'

import {
  type BadgeSize,
  StageBadge,
  type StageBadgeProps,
} from '~/global/components/badges'
import { useLegacyStageDefinition } from '~/global/hooks'
import { forwardRef } from 'react'

type StageSize = Extract<BadgeSize, 'small' | 'normal'>

export type StageLabelProps = {
  value: Pick<StageDefinitionSchema, 'name' | 'color'> | null | undefined
  size?: BadgeSize
  nameOnly?: boolean
  variant?: StageBadgeProps['variant']
  active?: boolean
}

export const StageLabel = forwardRef<HTMLDivElement, StageLabelProps>(
  (
    {
      value,
      size: propSize,
      nameOnly = false,
      variant = 'default',
      active = false,
    },
    ref
  ) => {
    const name = value == null ? 'No stage' : value.name

    const size = getStageLabelSize(propSize)

    return (
      <ProjectPalette color={value?.color ?? 'gray'}>
        <StageContainer ref={ref} size={size} variant={variant} active={active}>
          {!nameOnly && value != null && (
            <StageBadge
              value={value}
              size={size}
              hideTooltip
              variant={variant}
            />
          )}
          <StageText size={size} variant={variant}>
            {name}
          </StageText>
        </StageContainer>
      </ProjectPalette>
    )
  }
)

StageLabel.displayName = 'StageLabel'

export type ConnectedStageLabelProps = Omit<StageLabelProps, 'value'> & {
  id: StageDefinitionSchema['id']
  size?: StageSize
  nameOnly?: boolean
}

export const ConnectedStageLabel = ({
  id,
  ...rest
}: ConnectedStageLabelProps) => {
  const value = useLegacyStageDefinition(id)

  if (isObjectNoneId(value)) return null

  return <StageLabel value={value} {...rest} />
}

const StageContainer = classed('div', {
  base: `
    bg-palette-bg-default
    inline-flex items-center
    w-min max-w-full
    overflow-hidden
    p-0.5
    rounded-full
  `,
  variants: {
    size: {
      xsmall: 'p-px leading-3',
      small: 'p-px',
      normal: 'p-0.5',
    },
    variant: {
      default: '',
      completed: 'opacity-50',
      skipped: 'opacity-50 cursor-default',
      stageDueDeadline: '',
    },
    active: {
      true: 'border-2 border-palette-highlight-default',
      false: '',
    },
  },
  defaultVariants: {
    size: 'normal',
    variant: 'default',
    active: false,
  },
})

const StageText = classed('span', {
  base: 'truncate font-medium text-palette-text-default',
  variants: {
    size: {
      xsmall: 'mx-1 text-[10px]',
      small: 'mx-1 text-2xs',
      normal: 'mx-1.5 text-xs',
    },
    variant: {
      default: '',
      completed: '',
      active: '',
      skipped: 'line-through',
      stageDueDeadline: '',
    },
  },
  defaultVariants: {
    size: 'normal',
    variant: 'default',
  },
})

function getStageLabelSize(size: BadgeSize | undefined) {
  switch (size) {
    case 'xsmall':
      return 'xsmall'
    case 'small':
      return 'small'
    default:
      return 'normal'
  }
}
