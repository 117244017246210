import { type Operation } from './types'
import { allKeys, hasId, isArrayWithIdElement, join, OP } from './utils'

import { isEqual } from '../../core'

export function isObject(obj: unknown): obj is object {
  return typeof obj === 'object'
}

export function diff<T extends object>(
  base: T,
  modified: T,
  path: string = '/'
): Operation[] {
  if (isEqual(base, modified)) return []

  if (hasId(base) && hasId(modified)) {
    if (base.id !== modified.id) {
      return [OP.replace(path, modified, base)]
    }
  }

  if (base == null && modified != null) {
    if (Array.isArray(modified)) {
      return modified.map((item) => OP.add(join(path, '-'), item))
    }
    return [OP.replace(path, modified, base)]
  }

  return allKeys(base, modified).flatMap((key) => {
    const baseValue = base?.[key]
    const modifiedValue = modified?.[key]
    if (isEqual(baseValue, modifiedValue)) return []

    if (baseValue == null && modifiedValue != null) {
      if (Array.isArray(modifiedValue)) {
        return modifiedValue.map((item) => OP.add(join(path, key, '-'), item))
      }
      return OP.replace(join(path, key), modifiedValue, baseValue)
    }

    if (
      isArrayWithIdElement(modifiedValue) &&
      isArrayWithIdElement(baseValue)
    ) {
      return diffArrayWithIds(baseValue, modifiedValue, join(path, key))
    } else if (isObject(baseValue) && isObject(modifiedValue)) {
      return diff(baseValue, modifiedValue, join(path, key))
    } else if (baseValue !== modifiedValue) {
      return OP.replace([path, key], modifiedValue, baseValue)
    }
    return []
  })
}

type HasId = { id: string }
type IndexReference<T extends HasId> = {
  index: number
  value: T
}
function createIdLookup<T extends HasId>(arr: T[]) {
  return arr.reduce((acc, cur, index) => {
    acc.set(cur?.id ?? 'null', { index, value: cur })
    return acc
  }, new Map<string, IndexReference<T>>())
}

type SeparateArrayResult<T> = {
  both: {
    id: string
    left: {
      index: number
      value: T
    }
    right: {
      index: number
      value: T
    }
  }[]
  left: {
    id: string
    index: number
    value: T
  }[]
  right: {
    id: string
    index: number
    value: T
  }[]
}

function separateArray<T extends HasId>(
  left: T[],
  right: T[]
): SeparateArrayResult<T> {
  const leftLookup = createIdLookup(left)
  const rightLookup = createIdLookup(right)

  const result: SeparateArrayResult<T> = {
    both: [],
    left: [],
    right: [],
  }

  Array.from(leftLookup.values()).forEach((item) => {
    const rightValue = rightLookup.get(item.value?.id ?? 'null')
    if (rightValue === undefined) {
      return result.left.push({
        id: item.value?.id ?? 'null',
        index: item.index,
        value: item.value,
      })
    }
    result.both.push({
      id: item.value?.id ?? 'null',
      left: item,
      right: rightValue,
    })
  })

  Array.from(rightLookup.values()).forEach((item) => {
    const leftValue = leftLookup.get(item.value?.id ?? 'null')
    if (leftValue !== undefined) return
    result.right.push({
      id: item.value?.id ?? 'null',
      index: item.index,
      value: item.value,
    })
  })

  return result
}

// TODO: This could probably use the proper spec
export function diffArrayWithIds<T extends HasId>(
  left: T[],
  right: T[],
  path = '/'
): Operation[] {
  if (isEqual(left, right)) return []
  const changes: Operation[] = []

  const ids = separateArray(left, right)

  ids.left.forEach(({ value, index }) => {
    changes.push(OP.remove([path, index], value))
  })

  ids.right.forEach(({ value }) => {
    changes.push(OP.add([path, '-'], value))
  })

  ids.both.forEach((item) => {
    if (isEqual(item.left.value, item.right.value)) return
    changes.push(
      OP.replace(join(path, item.left.index), item.right.value, item.left.value)
    )
  })

  return changes
}
