import { SearchSolid } from '@motion/icons'
import { type ProjectSchema } from '@motion/rpc-types'
import { TextField } from '@motion/ui/forms'

import { AttachmentUploadButton } from '~/areas/attachments'
import { AttachmentGroups } from '~/areas/attachments/components'
import { useGetProjectAttachmentGroups } from '~/areas/attachments/hooks/use-get-project-all-attachments'
import { useState } from 'react'

type AttachmentsTabProps = {
  project: ProjectSchema
}

export function AttachmentsTab({ project }: AttachmentsTabProps) {
  const [searchQuery, setSearchQuery] = useState('')
  const groups = useGetProjectAttachmentGroups({
    projectId: project.id,
  })

  return (
    <div className='w-full h-full overflow-auto rounded-b-lg shadow-lg'>
      <div className='flex gap-2 p-3'>
        <TextField
          size='small'
          rounded='default'
          prefix={<SearchSolid />}
          placeholder='Search'
          value={searchQuery}
          onChange={setSearchQuery}
          fullWidth
          showClearButton
        />

        <AttachmentUploadButton
          targetId={project.id}
          targetType='PROJECT'
          workspaceId={project.workspaceId}
          label='Add'
          size='small'
        />
      </div>
      <div className='flex flex-col pl-[6px] pr-3 pb-[6px]'>
        <AttachmentGroups
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          groups={groups}
          variant='compact'
        />
      </div>
    </div>
  )
}
