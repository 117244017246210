import { DeadlineSolid } from '@motion/icons'
import { type StageDefinitionSchema } from '@motion/rpc-types'
import { type DeadlineInterval } from '@motion/shared/flows'
import { FieldButton, Tooltip } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useFlowTemplateForm } from '~/areas/flows/flow-template-modal/hooks'
import { getIndexFromName } from '~/areas/flows/flow-template-modal/utils/get-index-from-name'
import { useI18N } from '~/global/contexts'
import { useCallback, useMemo } from 'react'

import { RelativeDateDropdown, RelativeDateLabel } from './components'
import { DurationText } from './components/duration-text'
import {
  getRelativeDateTooltipContent,
  handleTaskRelativeIntervalUpdate,
} from './utils'

import { useStageTaskField } from '../hooks'

export type ControlledRelativeDeadlineFieldProps = {
  color: StageDefinitionSchema['color']
  taskBasePath: `stages.${number}.tasks.${number}`
  size: 'xsmall' | 'small'
  stageDeadlineInterval: DeadlineInterval
}

export const ControlledRelativeDeadlineField = ({
  color,
  taskBasePath,
  size,
  stageDeadlineInterval,
}: ControlledRelativeDeadlineFieldProps) => {
  const { form } = useFlowTemplateForm()
  const [relativeDeadline, setRelativeDeadline] = useStageTaskField(
    `${taskBasePath}.dueRelativeInterval`
  )
  const { pluralize } = useI18N()
  const { duration, referenceType } = relativeDeadline

  const tooltipContent = useMemo(
    () =>
      getRelativeDateTooltipContent({
        referenceType,
        duration,
        pluralize,
        startOrEnd: 'End date',
      }),
    [referenceType, duration, pluralize]
  )

  const onChange: typeof setRelativeDeadline = useCallback(
    (valueOrFn) => {
      const res = setRelativeDeadline(valueOrFn)

      const { stageIndex, taskIndex } = getIndexFromName(taskBasePath)
      if (stageIndex == null || taskIndex == null) return res
      handleTaskRelativeIntervalUpdate(form, stageIndex, taskIndex, 'due')

      const parsedValue =
        typeof valueOrFn === 'function'
          ? valueOrFn(relativeDeadline)
          : valueOrFn

      recordAnalyticsEvent('TASK_DEFINITION_RELATIVE_DATE_CHANGED', {
        taskValue: 'deadline',
        target: parsedValue.referenceType,
        relative: parsedValue.duration.sign === 1 ? 'plus' : 'minus',
        type: parsedValue.duration.unit,
        value: parsedValue.duration.value,
      })

      return res
    },
    [setRelativeDeadline, form, taskBasePath, relativeDeadline]
  )

  return (
    <RelativeDateDropdown
      color={color}
      type='deadline'
      value={relativeDeadline}
      onChange={onChange}
      stageDeadlineInterval={stageDeadlineInterval}
    >
      <Tooltip asChild renderContent={() => tooltipContent}>
        <FieldButton variant='muted' fullWidth size={size}>
          <DeadlineSolid />
          <RelativeDateLabel color={color} value={referenceType} size={size} />
          <DurationText duration={duration} />
        </FieldButton>
      </Tooltip>
    </RelativeDateDropdown>
  )
}
