import { isCompletedStatus } from '@motion/shared/common'
import {
  byProperty,
  cascade,
  Compare,
  groupBy,
  ordered,
} from '@motion/utils/array'
import { type StatusSchema, type WorkspaceSchema } from '@motion/zod/client'

import { useUserSettings } from '~/global/rpc'
import { useMemo } from 'react'

import { useAllProjects } from './use-project'
import { useWorkspaceFns } from './use-workspace-fns'
import { useAllWorkspaces } from './use-workspace-selectors'

import {
  DefaultStatusFilterValue,
  parseSidebarState,
} from '../../utils/workspace-treeview/workspace-tree-utils'

interface Options {
  omitPersonalWorkspaces?: boolean
  omitStatusFilter?: boolean
}

export type OrderedWorkspaceProjectList = ReturnType<
  typeof useOrderedWorkspaceProjectList
>['data']

export function useOrderedWorkspaceProjectList(options: Options = {}) {
  const { omitPersonalWorkspaces = false, omitStatusFilter = false } = options

  const workspaces = useAllWorkspaces()
  const allProjects = useAllProjects()
  const { getWorkspaceStatuses } = useWorkspaceFns()

  const { data, isInitialLoading } = useUserSettings()

  const workspaceProjectsGroups = useMemo(
    () => groupBy(allProjects, (p) => p.workspaceId),
    [allProjects]
  )

  return useMemo(() => {
    const {
      workspaceOrderIds,
      projectOrderMap,
      expandedMap,
      filteredStatusIdMap,
    } = parseSidebarState(data?.sidebarState)

    const getProjectsByWorkspaceId = (wid: WorkspaceSchema['id']) => {
      return workspaceProjectsGroups[wid] ?? []
    }

    const sortedWorkspaces = workspaces
      .filter((w) => {
        if (omitPersonalWorkspaces) {
          return !w.isPersonalWorkspace
        }

        return true
      })
      .sort(
        cascade(
          byProperty('isPersonalWorkspace', ordered([true, false])),
          byProperty('id', ordered(workspaceOrderIds)),
          byProperty('name', Compare.string)
        )
      )

    return {
      isLoading: isInitialLoading,
      data: sortedWorkspaces.map((workspace) => {
        const projectOrderIds = projectOrderMap[workspace.id] ?? []
        const filteredStatusIds =
          filteredStatusIdMap[workspace.id] ?? DefaultStatusFilterValue
        const cleanedFilteredStatusIds = cleanFilteredStatusIds(
          getWorkspaceStatuses(workspace.id),
          omitStatusFilter ? DefaultStatusFilterValue : filteredStatusIds
        )

        return {
          expanded: expandedMap[workspace.id] ?? false,
          filteredStatusIds: cleanedFilteredStatusIds,
          filteredStatusIdsIsDefault:
            filteredStatusIds === DefaultStatusFilterValue,
          item: {
            ...workspace,
            projects: getProjectsByWorkspaceId(workspace.id).sort(
              cascade(
                byProperty('id', ordered(projectOrderIds)),
                byProperty('name', Compare.caseInsensitive)
              )
            ),
          },
        }
      }),
    }
  }, [
    data?.sidebarState,
    getWorkspaceStatuses,
    isInitialLoading,
    omitPersonalWorkspaces,
    omitStatusFilter,
    workspaceProjectsGroups,
    workspaces,
  ])
}

function cleanFilteredStatusIds(
  statuses: StatusSchema[],
  statusIds: string[] | typeof DefaultStatusFilterValue
) {
  if (statusIds === DefaultStatusFilterValue) {
    return getDefaultfilteredStatusIds(statuses)
  }

  return statuses.reduce<string[]>((acc, status) => {
    if (statusIds.some((sId) => sId === status.id)) {
      acc.push(status.id)
    }
    return acc
  }, [])
}

export function getDefaultfilteredStatusIds(statuses: StatusSchema[]) {
  return statuses.reduce<string[]>((acc, status) => {
    if (!isCompletedStatus(status)) {
      acc.push(status.id)
    }
    return acc
  }, [])
}
