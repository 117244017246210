import { ChartThemeProvider } from '@motion/web-charts/theme'

import { ModalTrigger } from '~/areas/modals'

import { DashboardGridLayout } from './grid-layout'
import { ChartModal } from './modals/chart-modal'
import { ExpandedChartModal } from './modals/expanded-chart-modal'

export const DashboardPage = () => {
  return (
    <ChartThemeProvider>
      <div className='mx-4 overflow-y-scroll overflow-x-hidden'>
        <DashboardGridLayout />
      </div>
      <ModalTrigger name='chart-modal' component={ChartModal} />
      <ModalTrigger
        name='expanded-chart-modal'
        component={ExpandedChartModal}
      />
    </ChartThemeProvider>
  )
}
