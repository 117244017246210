import { useHasTreatment } from '@motion/web-common/flags'

import { useQueryNotes } from '~/global/rpc/v2'
import { useMemo } from 'react'

import { useAllWorkspaces } from '../workspaces'

export const useAllNotes = () => {
  const hasNotes = useHasTreatment('notes-in-webapp')
  const workspaces = useAllWorkspaces()
  const query = useQueryNotes(
    { workspaceId: workspaces.map((w) => w.id) },
    { enabled: hasNotes && workspaces && workspaces.length > 0 }
  )

  return useMemo(() => {
    const notes = query.data?.models.notes ?? {}
    const ids = query.data?.ids ?? []
    return { notes, ids }
  }, [query.data])
}
