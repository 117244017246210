import {
  ArrowDownSolid,
  ArrowUpSolid,
  DuplicateOutline,
  TrashOutline,
} from '@motion/icons'
import { type NoteSchema } from '@motion/rpc-types'
import { ActionList } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useNoteUrl } from '~/global/navigation'
import { useNoteById } from '~/global/rpc/v2'
import { useClipboard } from '~/localServices/clipboard'

import { NoteColorPicker } from './components'
import { useDeleteNote } from './hooks'

import { useMoveFolderItemInDirection } from '../folders/hooks'

type NoteActionsListProps = {
  noteId: NoteSchema['id']
  hideColorPicker?: boolean
  moveActionAllowed: {
    up: boolean
    down: boolean
  }
  close: () => void
  folderItemId?: string
}
export const NoteActionsList = (props: NoteActionsListProps) => {
  const {
    folderItemId,
    noteId,
    moveActionAllowed,
    hideColorPicker = false,
    close,
  } = props
  const moveFolderItemInDirection = useMoveFolderItemInDirection()
  const getNoteUrl = useNoteUrl()
  const clipboard = useClipboard()
  const deleteNote = useDeleteNote()

  const { data: note } = useNoteById({ id: noteId })

  const handleCopyLink = () => {
    const { noteUrl } = getNoteUrl({ noteId })

    clipboard.write({
      text: noteUrl,
    })
  }

  return (
    <ActionList
      onActionAnyItem={close}
      sections={[
        !hideColorPicker && {
          items: [
            {
              content: (
                <NoteColorPicker
                  noteId={noteId}
                  selected={note?.color ?? 'gray'}
                />
              ),
              isNested: true,
            },
          ],
        },
        {
          items: [
            {
              prefix: <DuplicateOutline />,
              content: 'Copy link',
              onAction: handleCopyLink,
            },
          ],
        },
        {
          items: [
            folderItemId && {
              prefix: <ArrowUpSolid />,
              content: 'Move up',
              disabled: !moveActionAllowed.up,
              onAction: () => {
                recordAnalyticsEvent('FOLDERS_SHIFT_SIDEBAR_ITEM', {
                  itemType: 'NOTE',
                  direction: 'UP',
                })

                moveFolderItemInDirection(folderItemId, -1)
              },
            },
            folderItemId && {
              prefix: <ArrowDownSolid />,
              content: 'Move down',
              disabled: !moveActionAllowed.down,
              onAction: () => {
                recordAnalyticsEvent('FOLDERS_SHIFT_SIDEBAR_ITEM', {
                  itemType: 'NOTE',
                  direction: 'DOWN',
                })

                moveFolderItemInDirection(folderItemId, 1)
              },
            },
          ].filter(Boolean),
        },
        {
          items: [
            {
              prefix: <TrashOutline />,
              content: 'Delete',
              destructive: true,
              onAction: () => {
                deleteNote(noteId)
              },
            },
          ],
        },
      ].filter(Boolean)}
    />
  )
}
