import { isNoAccess } from '@motion/rpc-cache'
import { byProperty, cascade, Compare } from '@motion/utils/array'
import { Sentry } from '@motion/web-base/sentry'
import type { RecursiveFolderItemSchema } from '@motion/zod/client'

import { useLookup } from '~/global/cache'
import { useAllNotes, useAllProjects } from '~/global/hooks'
import { useFolders } from '~/global/rpc/folders'
import { useUriByRouteId } from '~/routing'
import { useCallback, useMemo } from 'react'

import { type WorkspacesTreeItem } from './types'

import { useFindFolder } from '../folders'

export const useWorkspacesTree = (): WorkspacesTreeItem[] => {
  const { data: folders } = useFolders()
  const getRouteUri = useUriByRouteId({ noDefaults: true })
  const allProjects = useAllProjects()
  const allNotes = useAllNotes()
  const findFolder = useFindFolder()
  const lookup = useLookup()

  const getFolderById = useCallback(
    (id: string) => {
      return folders?.models.folders[id] ?? null
    },
    [folders]
  )

  const getWorkspaceByFolderId = useCallback(
    (id: string) => {
      const folder = getFolderById(id)
      if (!folder || folder.targetType !== 'WORKSPACE') return null
      return lookup('workspaces', folder.targetId)
    },
    [getFolderById, lookup]
  )

  const formatFolderTree = useCallback(
    (item: RecursiveFolderItemSchema): WorkspacesTreeItem | null => {
      let children: WorkspacesTreeItem[] = []

      if ('items' in item) {
        children.push(
          ...item.items
            .map(formatFolderTree)
            .filter(Boolean)
            .sort(byProperty('order', Compare.string))
        )
      }

      const isContainer = item.itemType === 'FOLDER'

      // These props are the same for workspaces, folders, and projects
      const commonProps = {
        id: item.id,
        order: item.order,
        itemId: item.itemId,
        parentId: item.folderId,
        children,
        projectCount: children.reduce(
          (count, child) => count + child.projectCount,
          0
        ),
        isContainer,
      } as const

      // A "folder" can be a folder or a workspace
      if (item.itemType === 'FOLDER') {
        const folderModel = getFolderById(item.itemId)

        if (!folderModel) {
          Sentry.captureException(
            new Error('Could not find folder model when building folder tree'),
            {
              extra: {
                item,
              },
              tags: {
                position: 'useFolderTree',
              },
            }
          )

          return null
        }

        const workspace = getWorkspaceByFolderId(folderModel.id)
        const isNoAccessWorkspace = isNoAccess(workspace)

        if (!workspace || isNoAccessWorkspace) {
          Sentry.captureException(
            new Error(
              'Could not find workspace model when building folder tree'
            ),
            {
              extra: {
                folderModel,
                isNoAccessWorkspace,
              },
              tags: {
                position: 'useFolderTree',
              },
            }
          )

          return null
        }

        if (folderModel.type === 'WORKSPACE') {
          // This is a workspace
          return {
            ...commonProps,
            label: workspace.name,
            workspaceId: workspace.id,
            type: `${workspace.type}_WORKSPACE`,
            url: getRouteUri('workspace-detail', { workspaceId: workspace.id }),
            meta: {},
          }
        }

        // If it's not a workspace, this is a folder
        return {
          ...commonProps,
          color: folderModel.color,
          label: folderModel.name ?? '(no name)',
          workspaceId: folderModel.targetId,
          type: 'FOLDER',
          url: getRouteUri('workspace-folder', {
            workspaceId: workspace.id,
            folderId: folderModel.id,
          }),
          meta: {},
        }
      }

      // This is a project
      if (item.itemType === 'PROJECT') {
        const projectModal = allProjects.find(
          (project) => item.itemId === project.id
        )

        if (!projectModal) {
          Sentry.captureException(
            new Error('Could not find project model when building folder tree'),
            {
              extra: {
                itemId: item.itemId,
              },
              tags: {
                position: 'useFolderTree',
              },
            }
          )

          return null
        }

        const workspaceFolder = findFolder(
          ({ targetId, type }) =>
            targetId === projectModal.workspaceId && type === 'WORKSPACE'
        )

        if (!workspaceFolder) {
          Sentry.captureException(
            new Error(
              'Could not find workspace folder model for project when building folder tree'
            ),
            {
              extra: {
                itemId: item.itemId,
                projectModal,
              },
              tags: {
                position: 'useFolderTree',
              },
            }
          )

          return null
        }

        return {
          ...commonProps,
          color: projectModal.color,
          label: projectModal.name ?? '(no name)',
          workspaceId: projectModal.workspaceId,
          type: 'PROJECT',
          projectCount: 1,
          url: getRouteUri('workspace-project', {
            workspaceId: projectModal.workspaceId,
            projectId: projectModal.id,
          }),
          meta: {
            workspaceFolderId: workspaceFolder.id,
            projectStatusId: projectModal.statusId,
          },
        }
      }

      if (allNotes.ids.length > 0 && item.itemType === 'NOTE') {
        const note = allNotes.notes[item.itemId]

        // Notes can have target type Project, don't show them in the sidebar yet
        if (!note || note.targetType !== 'WORKSPACE') {
          return null
        }

        return {
          ...commonProps,
          color: 'highlighter-yellow',
          label: note.title || 'Untitled',
          workspaceId: note.targetId,
          type: 'NOTE',
          url: getRouteUri('notes-detail', { noteId: item.itemId }),
          meta: {},
        }
      }

      Sentry.captureException(
        new Error('No item match found when building folder tree'),
        {
          extra: {
            item,
          },
          tags: {
            position: 'useFolderTree',
          },
        }
      )

      return null
    },
    [
      allNotes.ids.length,
      allNotes.notes,
      allProjects,
      findFolder,
      getFolderById,
      getRouteUri,
      getWorkspaceByFolderId,
    ]
  )

  return useMemo(() => {
    if (!folders?.models.systemFolders.workspaces) {
      return []
    }

    return folders.models.systemFolders.workspaces.items
      .map(formatFolderTree)
      .filter(Boolean)
      .sort(cascade(byProperty('order', Compare.string)))
  }, [folders, formatFolderTree])
}
