import { type ApiTypes, useQueryOptionsFactory } from '@motion/rpc'
import { type MeetingInsightsSchema } from '@motion/rpc-types'

import { useQuery, type UseQueryOptions } from '@tanstack/react-query'

import { getMeetingInsightsQuery } from '../../rpc-definition'

type QueryApi = ApiTypes<typeof getMeetingInsightsQuery>

export const useMeetingInsightsQuery = <TOut = MeetingInsightsSchema>(
  args: QueryApi['args'],
  opts?: Omit<
    UseQueryOptions<QueryApi['queryFnData'], Error, TOut[]>,
    'queryKey'
  >
) => {
  const queryOptionsOf = useQueryOptionsFactory(getMeetingInsightsQuery)
  const queryArgs = queryOptionsOf(args, opts as any)

  return useQuery<QueryApi['queryFnData'], Error, TOut[]>({
    notifyOnChangeProps: ['error', 'data', 'dataUpdatedAt'],
    select: (data: QueryApi['queryFnData']) => {
      return data.ids.map((id) => data.models.meetingInsights[id])
    },
    ...queryArgs,
    // @ts-expect-error - typing
    async queryFn(ctx) {
      // TODO: Have our own model cache and we can populate here
      const value = await queryArgs.queryFn(ctx as any)
      return value
    },
  })
}
