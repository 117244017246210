import {
  createMergeReducer,
  createStateKeyFilter,
  on,
  SharedStateProvider,
} from '@motion/react-core/shared-state'
import { isNoneId } from '@motion/shared/identifiers'
import { createNoneLabel, createNoneProject } from '@motion/ui-logic/pm/data'
import {
  byProperty,
  byValue,
  cascade,
  Compare,
  ordered,
} from '@motion/utils/array'
import { type WorkspaceSchema } from '@motion/zod/client'

import { type ReactNode, useMemo } from 'react'

import {
  AppWorkspaceContext,
  type AppWorkspaceDataContext,
} from './app-workspace-context'
import { createEntityCache } from './utils'

type SelectedWorkspaceIdsContextProps = {
  children: ReactNode
  name?: string
  selectedWorkspaceIds: WorkspaceSchema['id'][] | null
  removeNoneProjectIds?: boolean
}

const DEFAULT_SORT = Compare.string.with({
  null: 'at-end',
  empty: 'at-end',
})

export const SelectedWorkspaceIdsContext = ({
  children,
  name,
  selectedWorkspaceIds,
  removeNoneProjectIds,
}: SelectedWorkspaceIdsContextProps) => {
  const merge = useMemo(() => {
    return createMergeReducer(
      on(AppWorkspaceContext, (ctx) =>
        mergeContext(ctx, selectedWorkspaceIds, { removeNoneProjectIds })
      )
    )
  }, [removeNoneProjectIds, selectedWorkspaceIds])

  return (
    <SharedStateProvider
      name={name}
      merge={merge}
      filter={onlyWorkspaceFilter}
      batchTime={0}
    >
      {children}
    </SharedStateProvider>
  )
}

const onlyWorkspaceFilter = createStateKeyFilter({
  include: [AppWorkspaceContext],
})

function mergeContext(
  ctx: AppWorkspaceDataContext,
  selectedWorkspaceIds: string[] | null,
  opts: { removeNoneProjectIds?: boolean } = { removeNoneProjectIds: false }
): AppWorkspaceDataContext {
  const activeWorkspaceIds =
    selectedWorkspaceIds == null || selectedWorkspaceIds.length === 0
      ? ctx.workspaces.all.map((x) => x.id)
      : selectedWorkspaceIds

  const activeWorkspaces = ctx.workspaces.all.filter((w) =>
    activeWorkspaceIds.includes(w.id)
  )

  function inActiveWorkspace<T extends { workspaceId: string }>(item: T) {
    return activeWorkspaceIds.includes(item.workspaceId)
  }

  const projects = ctx.projects.all
    .filter(inActiveWorkspace)
    .concat(
      opts.removeNoneProjectIds
        ? []
        : activeWorkspaceIds.map((id) => createNoneProject(id))
    )
    .sort(
      cascade(
        byValue((x) => isNoneId(x.id), ordered([true])),
        byValue((x) => x.name, DEFAULT_SORT),
        byValue(
          (item) => ctx.workspaces.byId[item.workspaceId]?.name,
          Compare.string
        )
      )
    )
  const labels = ctx.labels.all
    .filter((x) => !x.deleted && inActiveWorkspace(x))
    .concat(activeWorkspaceIds.map((id) => createNoneLabel(id)))
    .sort(
      cascade(
        byValue((x) => isNoneId(x.id), ordered([true])),
        byValue((x) => x.name, DEFAULT_SORT),
        byValue(
          (item) => ctx.workspaces.byId[item.workspaceId]?.name,
          Compare.string
        )
      )
    )

  const statuses = ctx.statuses.all
    .filter((x) => !x.deleted && inActiveWorkspace(x))
    .sort(
      cascade(
        byValue((x) => x.name, DEFAULT_SORT),
        byValue(
          (item) => ctx.workspaces.byId[item.workspaceId]?.name,
          Compare.string
        )
      )
    )

  const customFields = ctx.customFields.all.filter(inActiveWorkspace)

  const users = ctx.users.all
    .filter((x) => !x.deleted)
    .sort(byProperty('name', Compare.string))

  // Only get users that are active members in the selected workspaces
  const members = ctx.members.all.filter(
    (m) => m.status === 'ACTIVE' && inActiveWorkspace(m)
  )

  const projectDefinitions =
    ctx.projectDefinitions.all.filter(inActiveWorkspace)

  const legacyStageDefinitions =
    ctx.stageDefinitions.all.filter(inActiveWorkspace)

  const stageDefinitionsV2 = ctx.stageDefinitionsV2.all.filter(
    (stageDefinition) =>
      stageDefinition.workspaceId != null &&
      inActiveWorkspace({
        ...stageDefinition,
        workspaceId: stageDefinition.workspaceId,
      })
  )

  const legacyProjectTemplates =
    ctx.legacyProjectTemplates.all.filter(inActiveWorkspace)

  const folders = ctx.folders.all.filter((item) =>
    activeWorkspaceIds.includes(item.targetId)
  )

  return {
    loaded: ctx.loaded,
    workspaces: createEntityCache(activeWorkspaces),
    projects: createEntityCache(projects),
    labels: createEntityCache(labels),
    statuses: createEntityCache(statuses),
    users: createEntityCache(users),
    members: createEntityCache(members),
    customFields: createEntityCache(customFields),
    projectDefinitions: createEntityCache(projectDefinitions),
    stageDefinitions: createEntityCache(legacyStageDefinitions),
    stageDefinitionsV2: createEntityCache(stageDefinitionsV2),
    legacyProjectTemplates: createEntityCache(legacyProjectTemplates),
    priorities: ctx.priorities,
    deadlineStatuses: ctx.deadlineStatuses,
    views: ctx.views,
    folders: createEntityCache(folders),
    systemFolders: ctx.systemFolders,
  }
}
