import { type FilterDefinition } from '~/areas/project-management/filters'
import { type ReactNode } from 'react'

type FilterListProps<T extends 'tasks' | 'projects'> = {
  filters: FilterDefinition<T>[]
  applyTo: T
  showChildrenFirst?: boolean
  children?: ReactNode
}

export const FilterList = <T extends 'tasks' | 'projects'>({
  filters,
  applyTo,
  showChildrenFirst = false,
  children,
}: FilterListProps<T>) => {
  const childNode = children != null && (
    <li className='flex pb-1.5'>{children}</li>
  )

  return (
    <ul className='overflow-auto pt-2'>
      {showChildrenFirst && childNode}
      {filters.map((def) => (
        <li
          key={def.key}
          className='flex pb-1.5 [&>*]:w-full w-[245px] modal-md:w-[285px] modal-lg:w-[330px]'
        >
          {def.renderDropdown({ target: applyTo })}
        </li>
      ))}
      {!showChildrenFirst && childNode}
    </ul>
  )
}
