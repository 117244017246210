import { classed } from '@motion/theme'

export const Container = classed('div', 'flex flex-col gap-2 p-3 w-[400px]')
export const Title = classed(
  'span',
  'text-xs font-medium text-semantic-neutral-text-subtle'
)
export const Row = classed(
  'div',
  'grid grid-cols-[auto_min-content_minmax(32px,auto)_auto] gap-1'
)
