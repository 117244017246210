import { type ProviderType } from '@motion/shared/common'
import { Button } from '@motion/ui/base'

import { useParams } from 'react-router'

import { CalendarIcon } from './icons/CalendarIcon'
import { ContactsIcon } from './icons/ContactsIcon'
import { EmailIcon } from './icons/EmailIcon'
import { GoogleLogo } from './icons/GoogleLogo'
import { MicrosoftLogo } from './icons/MicrosoftLogo'
import { GOOGLE_OAUTH_URL, MICROSOFT_OAUTH_URL } from './utils'

type NewOnboardingAddAccountProps = {
  doRedirect: (baseUrl: string) => void
}

export function NewOnboardingAddAccount({
  doRedirect,
}: NewOnboardingAddAccountProps) {
  const { provider = '' } = useParams<{
    provider: ProviderType
  }>()

  const validProvider = provider === 'google' || provider === 'microsoft'

  const addMicrosoft = async () => {
    await doRedirect(MICROSOFT_OAUTH_URL)
  }

  const addGoogle = async () => {
    await doRedirect(GOOGLE_OAUTH_URL)
  }

  const continueWithProvider = async () => {
    if (provider === 'google') {
      await addGoogle()
    } else if (provider === 'microsoft') {
      await addMicrosoft()
    }
  }

  return (
    <div className='h-screen w-screen bg-[#F3F3F4] p-6 lg:p-0 py-24'>
      <div className='mx-auto max-w-[590px]'>
        <h1 className='color-[#1A1A20] text-center text-lg lg:text-[40px] lg:leading-normal max-w-xs mx-auto lg:max-w-full lg:mx-0 font-semibold leading-[150%] select-none'>
          We need a few permissions to make Motion work
        </h1>

        <div className='border-1 mx-auto mt-8 max-w-[500px] rounded border-[#E0E0E3] bg-white p-6'>
          <div className='flex items-start gap-4'>
            <div className='flex h-8 w-8 items-center justify-center rounded-lg bg-[#E4EDFA]'>
              <CalendarIcon />
            </div>

            <div className='flex-1'>
              <h3 className='font-semibold'>Calendar</h3>
              <p className='text-[#747485]'>
                Schedule and move tasks, create meetings, share availabilities,
                sync events, and block out times.
              </p>
            </div>
          </div>
          <div className='mt-6 flex items-start gap-4'>
            <div className='flex h-8 w-8 items-center justify-center rounded-lg bg-[#E4EDFA]'>
              <EmailIcon />
            </div>

            <div className='flex-1'>
              <h3 className='font-semibold'>Email</h3>
              <p className='text-[#747485]'>
                Send templated emails to guests, and send reminders before
                booked meetings.
              </p>
            </div>
          </div>
          <div className='mt-6 flex items-start gap-4'>
            <div className='flex h-8 w-8 items-center justify-center rounded-lg bg-[#E4EDFA]'>
              <ContactsIcon />
            </div>

            <div className='flex-1'>
              <h3 className='font-semibold'>Contacts</h3>
              <p className='text-[#747485]'>
                Auto-fill when typing email addresses, and suggest teammates.
              </p>
            </div>
          </div>
        </div>

        {validProvider ? (
          <div className='mt-4 flex justify-end max-w-[500px] mx-auto'>
            <Button onClick={continueWithProvider}>OK, Continue</Button>
          </div>
        ) : (
          <div className='mt-4 flex justify-center'>
            <div className='inline-flex flex-col'>
              <button
                onClick={addGoogle}
                className='mt-4 flex items-center gap-2 rounded bg-[#2C77E7] px-4 py-2'
              >
                <GoogleLogo />
                <span className='font-semibold text-white'>
                  Continue with Google
                </span>
              </button>

              <button
                onClick={addMicrosoft}
                className='mt-4 flex items-center gap-2 rounded bg-[#2C77E7] px-4 py-2'
              >
                <MicrosoftLogo />
                <span className='font-semibold text-white'>
                  Continue with Microsoft
                </span>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
