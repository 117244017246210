import { XSolid } from '@motion/icons'
import { IconButton, showToast, UnstyledModal } from '@motion/ui/base'
import { type MeetingActionItemSchema } from '@motion/zod/client'

import {
  useApproveActionItem,
  useRejectActionItem,
} from '~/areas/ai-hackerhouse/hooks/rpc'
import { useTaskModalUrl } from '~/global/navigation'
import { useNavigate } from 'react-router'

import { ActionItemHeader, Banner } from './components'
import { AutoscheduleToggle } from './components/autoschedule-toggle'
import {
  ControlledAssigneeField,
  ControlledDescriptionField,
  ControlledNameField,
  ControlledPriorityField,
  ControlledStatusField,
  ControlledWorkspaceFolderProjectField,
  DeadlineField,
  DeadlineToggle,
  DurationField,
  MinChunkField,
  ScheduleField,
  StartDateField,
} from './fields'
import { useActionItemForm } from './hooks'
import {
  BannerShell,
  FormShell,
  GridShell,
  MainHeader,
  MainScrollableContent,
  ShellActions,
  SidebarSection,
  SidebarShell,
  SidebarWorkspaceSection,
} from './styled'

export type ConnectedActionItemModalProps = {
  close: () => void
  actionItem: MeetingActionItemSchema
  visible: boolean
}

export function ConnectedActionItemModal({
  close,
  actionItem,
  visible,
}: ConnectedActionItemModalProps) {
  return (
    <UnstyledModal
      data-testid='action-item-modal'
      type='page'
      visible={visible}
      onClose={close}
      withAnimation
      overlayClassName='bg-modal-overlay'
    >
      <ActionItemModalBody close={close} actionItem={actionItem} />
    </UnstyledModal>
  )
}

type ActionItemModalBodyProps = Pick<ConnectedActionItemModalProps, 'close'> & {
  actionItem: MeetingActionItemSchema
}

function ActionItemModalBody({ close, actionItem }: ActionItemModalBodyProps) {
  const { form } = useActionItemForm()
  const { mutateAsync: approveAsync } = useApproveActionItem()
  const { mutateAsync: rejectAsync } = useRejectActionItem()

  const navigate = useNavigate()
  const buildTaskModalUrl = useTaskModalUrl()

  return (
    <FormShell>
      <ShellActions>
        <IconButton
          icon={XSolid}
          sentiment='onDark'
          size='small'
          variant='muted'
          onClick={close}
        />
      </ShellActions>

      <GridShell>
        <BannerShell>
          <Banner
            onAction={async (action) => {
              if (action === 'approve') {
                const { newActionItem } = await approveAsync({
                  actionItemId: actionItem.id,
                  meetingInsightsId: actionItem.meetingInsightsId,
                  ...form.getValues(),
                })
                const taskId = newActionItem.taskId

                if (taskId == null) {
                  showToast('error', 'Failed to approve action item')
                  return
                }

                navigate(
                  buildTaskModalUrl({
                    task: taskId,
                  })
                )
              } else if (action === 'reject') {
                // Mutate form data
                await rejectAsync({ actionItemId: actionItem.id })
              }

              close()
            }}
          />
        </BannerShell>

        <MainHeader>
          <ActionItemHeader />
          <ControlledNameField />
        </MainHeader>

        <MainScrollableContent>
          <ControlledDescriptionField />
        </MainScrollableContent>

        <SidebarShell>
          <SidebarWorkspaceSection>
            <ControlledWorkspaceFolderProjectField />
          </SidebarWorkspaceSection>

          <AutoscheduleToggle canToggle={false} checked />

          <SidebarSection className='pt-3 modal-lg:pt-5'>
            <ControlledAssigneeField />
            <ControlledStatusField />
            <ControlledPriorityField />
          </SidebarSection>

          <SidebarSection>
            <DurationField />
            <MinChunkField />
            <StartDateField />
            <DeadlineField />
            <DeadlineToggle />
            <ScheduleField />
          </SidebarSection>
        </SidebarShell>
      </GridShell>
    </FormShell>
  )
}
