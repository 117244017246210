import { createUseMutation } from '@motion/rpc'
import { showToast } from '@motion/ui/base'

import { showErrorToast } from '~/global/toasts'

import { sendBotToMeetingNow } from '../../rpc-definition'
import { useAiAnalyticsCalls } from '../use-ai-analytics-calls'

export const useSendBotNowMutation = createUseMutation(sendBotToMeetingNow)

export function useSendBotNow() {
  const { recordSendBotNowCall } = useAiAnalyticsCalls()

  return useSendBotNowMutation({
    onSuccess: ({ result }, { meetingInsightsId }) => {
      recordSendBotNowCall(meetingInsightsId)
      if (result === 'success') {
        showToast('success', 'Bot sent successfully')
      } else if (result === 'bot-already-in-meeting') {
        showToast(
          'neutral',
          'Bot is already in the meeting or meeting has already ended'
        )
      }
    },
    onError: (error) => {
      showErrorToast('Failed to send bot to meeting')
    },
  })
}
