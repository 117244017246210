import {
  byValue,
  cascade,
  Compare,
  groupBy,
  ordered,
} from '@motion/utils/array'

import { type ScheduledEntityWithRelations } from '~/global/proxies'
import { DateTime } from 'luxon'
import { useMemo } from 'react'

import { useAgendaScheduledEntities } from './use-agenda-scheduled-entities'

import { useCalendarState } from '../../hooks'
import { type DateRow } from '../agenda-sidebar.types'
import { createDateRow, shouldAddDate } from '../utils'

export const useAgendaItems = () => {
  const scheduledEntities = useAgendaScheduledEntities()

  const { selectedDate, showAgendaEvents } = useCalendarState((state) => ({
    selectedDate: state.selectedDate,
    showAgendaEvents: state.settings.showAgendaEvents,
  }))

  return useMemo(() => {
    const groupedRows = groupBy(scheduledEntities, (entity) => {
      return DateTime.fromISO(entity.schedule.start).toISODate()
    })

    return Object.keys(groupedRows)
      .sort(Compare.string)
      .reduce<(ScheduledEntityWithRelations | DateRow)[]>((acc, key) => {
        if (shouldAddDate(key, selectedDate.toISO())) {
          const entitiesToAdd = groupedRows[key]
            .filter((entity) => {
              if (entity.type === 'EVENT') {
                return showAgendaEvents
              }

              return true
            })
            .sort(
              cascade(
                // Compare boolean
                byValue((e) => e.schedule.timeless, ordered([true, false])),
                // Compare by start time
                byValue((e) => e.schedule.start, Compare.string),
                byValue((e) => e.schedule.end, Compare.string)
              )
            )

          if (entitiesToAdd.length) {
            acc.push(createDateRow(key))
            acc.push(...entitiesToAdd)
          }
        }

        return acc
      }, [])
  }, [scheduledEntities, selectedDate, showAgendaEvents])
}
