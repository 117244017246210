import {
  ArrowDownSolid,
  ArrowRightSolid,
  ArrowUpSolid,
  LinkSolid,
  PencilSolid,
  PuzzleSolid,
  StarCrossOutOutline,
  StarOutline,
  StatusCanceledSolid,
  StatusCompletedSolid,
  TrashSolid,
} from '@motion/icons'
import {
  type COLOR,
  isCanceledStatus,
  isCompletedStatus,
} from '@motion/shared/common'
import { type ActionSection } from '@motion/ui/base'
import { isFlowProject } from '@motion/ui-logic/pm/project'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { type ProjectSchema } from '@motion/zod/client'

import {
  useFindFolderItem,
  useMoveFolderItemInDirection,
  useUserFavoritedItem,
} from '~/areas/folders/hooks'
import {
  useCancelProject,
  useCompleteProject,
  useDeleteProject,
} from '~/areas/project/hooks'
import { useHasPinnedViewsTreatment } from '~/areas/sidebar/hooks'
import { MoveProjectToPopover } from '~/areas/sidebar/treeviews/workspaces-treeview/components'
import {
  useCopyProjectModalLinkToClipboard,
  useWorkspaceFns,
  useWorkspaceStatusById,
} from '~/global/hooks'
import { useProjectModalUrl } from '~/global/navigation'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router'

import { ProjectColorPicker } from '../../components/project-color-picker'

export type ProjectActionSectionOptions = {
  hideEdit?: boolean
  showMoves?: boolean
  disabledMoveActions?: { up: boolean; down: boolean }

  allowProjectResolution?: boolean
  showColors?: boolean

  copyShortcutLabel?: string
  onDeleteConfirmed?: () => void
}

/**
 * Create a list of action sections. Typically used in a popover or dropdown.
 * @param project
 * @param options
 * @returns
 */
export function useProjectActionSections(
  project: ProjectSchema,
  options: ProjectActionSectionOptions
) {
  const {
    showMoves = false,
    copyShortcutLabel,
    onDeleteConfirmed,
    disabledMoveActions,
    allowProjectResolution = false,
    hideEdit = false,
    showColors = false,
  } = options

  const buildProjectModalUrl = useProjectModalUrl()
  const deleteProject = useDeleteProject()
  const copyProjectLink = useCopyProjectModalLinkToClipboard()
  const navigate = useNavigate()
  const completeProject = useCompleteProject()
  const cancelProject = useCancelProject()
  const { getWorkspaceCanceledStatus } = useWorkspaceFns()

  const moveFolderItemInDirection = useMoveFolderItemInDirection()
  const findFolderItem = useFindFolderItem()
  const hasPinnedViewsTreatment = useHasPinnedViewsTreatment()
  const { isFavorited, toggleFavorite } = useUserFavoritedItem(
    'PROJECT',
    project
  )

  const handleMoveProjectInDirection = useCallback(
    (direction: -1 | 1) => {
      const searchResult = findFolderItem(({ itemId }) => itemId === project.id)

      if (!searchResult || !searchResult[0]) return

      recordAnalyticsEvent('FOLDERS_SHIFT_SIDEBAR_ITEM', {
        itemType: 'PROJECT',
        direction: direction === -1 ? 'UP' : 'DOWN',
      })

      return moveFolderItemInDirection(searchResult[0].id, direction)
    },
    [findFolderItem, moveFolderItemInDirection, project]
  )

  const projectStatus = useWorkspaceStatusById(project.statusId)

  const shouldShowCompleteProject =
    allowProjectResolution && !isCompletedStatus(projectStatus)

  const shouldShowCancelProject =
    allowProjectResolution && !isCanceledStatus(projectStatus)

  return useMemo<ActionSection[]>(
    () => [
      showColors && {
        items: [
          {
            content: (
              <ProjectColorPicker
                project={project}
                selected={project.color as COLOR}
              />
            ),
            isNested: true,
          },
        ],
      },
      {
        items: [
          shouldShowCompleteProject && {
            prefix: <StatusCompletedSolid />,
            content: 'Complete project',
            onAction: () => {
              void completeProject(project.id)
            },
          },
          shouldShowCancelProject && {
            prefix: <StatusCanceledSolid />,
            content: 'Cancel project',
            onAction: () => {
              void cancelProject(
                project.id,
                getWorkspaceCanceledStatus(project.workspaceId).id
              )
            },
          },
          !hideEdit && {
            prefix: <PencilSolid />,
            content: 'Edit',
            onAction: () => {
              navigate(
                buildProjectModalUrl({
                  project: project.id,
                  forWorkspace: project.workspaceId,
                })
              )
            },
          },
          {
            prefix: <LinkSolid />,
            content: 'Copy link',
            shortcut: copyShortcutLabel,
            onAction: () => {
              if (!project.id) return
              copyProjectLink({
                workspaceId: project.workspaceId,
                projectName: project.name,
                projectId: project.id,
              })
            },
          },
          hasPinnedViewsTreatment && {
            prefix: isFavorited ? <StarCrossOutOutline /> : <StarOutline />,
            content: isFavorited ? 'Remove from Favorites' : 'Add to Favorites',
            onAction: toggleFavorite,
          },
          !isFlowProject(project) && {
            prefix: <PuzzleSolid />,
            content: 'Save as template',
            onAction: async () => {
              navigate(
                buildProjectModalUrl({ project: project.id, template: 'new' })
              )
            },
          },
          showMoves && {
            renderPopover: ({ close: nestedClose }) => (
              <MoveProjectToPopover onClose={nestedClose} project={project} />
            ),
            prefix: <ArrowRightSolid />,
            content: 'Move to',
            disabled: isFlowProject(project),
            tooltip:
              isFlowProject(project) &&
              `Workspace can’t be changed for projects using a workflow`,
          },
        ],
      },
      showMoves && {
        items: [
          {
            prefix: <ArrowUpSolid />,
            content: 'Move up',
            disabled: disabledMoveActions?.up,
            onAction: () => void handleMoveProjectInDirection(-1),
          },
          {
            prefix: <ArrowDownSolid />,
            content: 'Move down',
            disabled: disabledMoveActions?.down,
            onAction: () => void handleMoveProjectInDirection(1),
          },
        ],
      },
      {
        items: [
          {
            prefix: <TrashSolid />,
            content: 'Delete',
            destructive: true,
            onAction: () =>
              project.id && void deleteProject(project, onDeleteConfirmed),
          },
        ],
      },
    ],
    [
      buildProjectModalUrl,
      completeProject,
      copyProjectLink,
      copyShortcutLabel,
      deleteProject,
      disabledMoveActions?.down,
      disabledMoveActions?.up,
      handleMoveProjectInDirection,
      navigate,
      onDeleteConfirmed,
      project,
      showColors,
      hideEdit,
      showMoves,
      shouldShowCancelProject,
      shouldShowCompleteProject,
      cancelProject,
      getWorkspaceCanceledStatus,
      toggleFavorite,
      isFavorited,
      hasPinnedViewsTreatment,
    ]
  )
}
