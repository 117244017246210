import { type TaskSchema } from '@motion/zod/client'

import { useAgendaTaskActions } from '~/areas/agenda/hooks'
import { useIsTaskStarted } from '~/areas/tasks/hooks'

import { TaskItemActionButton } from './task-item-action-button'

type StartTaskButtonProps = {
  taskId: TaskSchema['id']
}

export function StartTaskButton({ taskId }: StartTaskButtonProps) {
  const { startTask, stopTask } = useAgendaTaskActions({
    taskId,
  })

  const { isStarted } = useIsTaskStarted(taskId)

  if (isStarted) {
    return (
      <TaskItemActionButton action='stop-task' onClick={stopTask}>
        Stop task
      </TaskItemActionButton>
    )
  }

  return (
    <TaskItemActionButton action='start-task' onClick={startTask}>
      Start task
    </TaskItemActionButton>
  )
}
