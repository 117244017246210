import { Tag } from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'
import { useHasTreatment } from '@motion/web-common/flags'
import { useModalApi } from '@motion/web-common/modals'

import { useIsNoCardTrialing } from '~/global/hooks'
import { DateTime } from 'luxon'

import { Events } from '../../../analyticsEvents'
import { useAppSelector } from '../../../state/hooks'
import { selectTeam } from '../../../state/team'

export interface TrialDaysLeftBadgeProps {
  trialExpiryISO: string
}

export function TrialDaysLeftBadge({
  trialExpiryISO,
}: TrialDaysLeftBadgeProps) {
  const team = useAppSelector(selectTeam)
  const shouldUseNoCcTrial = useHasTreatment('no-cc-combined-trial')
  const shouldUseNoCcB2b = useHasTreatment('no-cc-b2b-everywhere')
  const shouldUseCombinedB2cTrial = useHasTreatment(
    'b2c-cc-combined-with-no-cc'
  )
  const isNoCardTrialing = useIsNoCardTrialing()

  const modalApi = useModalApi()

  if (!trialExpiryISO) {
    Sentry.captureException(
      'Attempting to add trial days left badge without trial end date',
      { tags: { position: 'trialDaysLeftBadge' } }
    )
    return null
  }

  const trialDaysRemaining =
    DateTime.fromISO(trialExpiryISO)
      .endOf('day')
      .diff(DateTime.local().endOf('day'), 'days')
      .toObject().days ?? -1

  let label = templateStr('Trial ends in {{trialDaysRemaining}} day{{s}}', {
    trialDaysRemaining,
    s: trialDaysRemaining !== 1 ? 's' : '',
  })

  if (trialDaysRemaining === 0) {
    label = 'Trial ends today'
  } else if (trialDaysRemaining < 0) {
    label = 'Trial ended'
  }

  const handleClick = () => {
    if (team) {
      recordAnalyticsEvent(Events.TEAM_BILLING_TRIAL_BADGE_CLICK)
    } else {
      recordAnalyticsEvent(Events.INDIVIDUAL_BILLING_TRIAL_BADGE_CLICK)
    }

    if (
      (shouldUseNoCcTrial || shouldUseNoCcB2b || shouldUseCombinedB2cTrial) &&
      isNoCardTrialing
    ) {
      modalApi.open('choose-plan-modal')
      return
    }

    modalApi.open('change-payment-method', {
      hasPaymentMethod: false,
      activeTeamId: team?.id,
    })
  }

  return (
    <div className='w-full [&_button]:text-center [&_button]:w-full'>
      <Tag color='yellow' size='small' variant='subtle' onClick={handleClick}>
        {label}
      </Tag>
    </div>
  )
}
