import { ProjectCubeSolid, type SvgIcon, TaskSolid } from '@motion/icons'
import { type DashboardViewCellSchema } from '@motion/rpc-types'
import { PopoverTrigger, SearchableList } from '@motion/ui/base'
import { keys } from '@motion/utils/object'

import { ModalFieldButton } from '~/areas/task-project/components'

import { useChartSettingField } from '../../contexts'
import { ListItem } from '../list-item'

export function ChartDataField() {
  const [dataValue, setDateValue] = useChartSettingField('item')

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <SearchableList
          searchable={false}
          items={keys(itemToData)}
          computeKey={(item) => item}
          computeSelected={(item) => item === dataValue}
          onSelect={(item) => {
            setDateValue(item)
            close()
          }}
          renderItem={(item) => (
            <ListItem icon={itemToData[item]?.icon} name={getDataName(item)} />
          )}
        />
      )}
    >
      <ModalFieldButton label='Data'>{getDataName(dataValue)}</ModalFieldButton>
    </PopoverTrigger>
  )
}

const itemToData: Record<
  DashboardViewCellSchema['chart']['item'],
  { icon: SvgIcon; name: string }
> = {
  tasks: { icon: TaskSolid, name: 'Tasks' },
  projects: { icon: ProjectCubeSolid, name: 'Projects' },
}

function getDataName(item: DashboardViewCellSchema['chart']['item']): string {
  return itemToData[item]?.name ?? 'Unknown'
}
