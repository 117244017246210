import { RechartsPieChart } from '@motion/web-charts/pie'
import { type ChartQueryResponseSchema } from '@motion/zod/client'

import { ChartQueryDataProvider } from './chart-query-data-provider'
import { type ConnectedChartProps } from './types'
import { useChartGroupDefinition } from './use-chart-category'
import { useChartLookups } from './use-chart-lookups'
import { useValueFormatter } from './use-value-formatter'

export const ConnectedPieChart = ({ chart }: ConnectedChartProps<'pie'>) => {
  return (
    <ChartQueryDataProvider chart={chart}>
      {(chartQueryData) => {
        return <InnerPieChart data={chartQueryData} chart={chart} />
      }}
    </ChartQueryDataProvider>
  )
}

type InnerPieChartProps = ConnectedChartProps<'pie'> & {
  data: ChartQueryResponseSchema
}

export const InnerPieChart = ({ data, chart }: InnerPieChartProps) => {
  const lookups = useChartLookups()

  const category = useChartGroupDefinition(chart.groupBy[0])

  const valueFormatter = useValueFormatter(chart)

  if (category == null) {
    return <div>Please add a group by</div>
  }

  return (
    <RechartsPieChart
      category={category}
      data={data.data}
      labelAccessor={lookups.getLabel}
      showLabelIndicatorAccessor={lookups.getShowLabelIndicator}
      colorAccessor={lookups.getColor}
      donut={chart.donut}
      valueFormatter={valueFormatter}
    />
  )
}
