import { XSolid } from '@motion/icons'
import { classed } from '@motion/theme'

import { type ReactNode } from 'react'

import { ConditionButton } from './condition-dropdown'
import { FilterDropdownButton } from './filter-dropdown-button'
import { type ConditionOption, type DropdownProps } from './types'

const INVERT_OPTIONS_ALL: ConditionOption[] = [
  {
    id: 'false',
    label: 'is',
  },
  {
    id: 'true',
    label: 'is not',
  },
]
const INVERT_OPTIONS_NO_NEGATE = [INVERT_OPTIONS_ALL[0]]

export type FilterItemProps = {
  label: string

  hideInvert?: boolean
  canInvert?: boolean
  inverted?: boolean
  onInvertChanged?(value: boolean): void

  condition?: string
  availableConditions?: ConditionOption[]
  onConditionChanged?(value: string): void

  children: ReactNode
  renderDropdown(props: DropdownProps): ReactNode
  onDismiss(): void

  onBlur?: (() => void) | undefined
  openOnMount?: boolean | undefined
}

export const FilterItem = (props: FilterItemProps) => {
  const conditions = props.availableConditions

  return (
    <Container>
      <CloseButton onClick={props.onDismiss}>
        <XSolid width={10} height={10} />
      </CloseButton>
      <div className='text-semantic-neutral-text-subtle px-[6px] py-[2px] flex items-center max-w-[100px]'>
        <span className='truncate'>{props.label}</span>
      </div>

      {!props.hideInvert && (
        <ConditionButton
          options={
            props.canInvert ? INVERT_OPTIONS_ALL : INVERT_OPTIONS_NO_NEGATE
          }
          value={String(!!props.inverted)}
          onChange={(text) => props.onInvertChanged?.(text === 'true')}
        />
      )}

      {conditions != null && (
        <ConditionButton
          options={conditions}
          value={props.condition ?? ''}
          onChange={(value) => {
            props.onConditionChanged?.(value)
          }}
        />
      )}
      {props.children != null && (
        <FilterDropdownButton
          renderDropdown={props.renderDropdown}
          onBlur={props.onBlur}
          openOnMount={props.openOnMount}
        >
          <span className='truncate'>{props.children}</span>
        </FilterDropdownButton>
      )}
    </Container>
  )
}

const Container = classed('div', {
  base: `
    relative
    group/item
    rounded
    text-semantic-neutral-text-default
    bg-filter-button-bg-default

    cursor-default

    flex
    text-2xs
  `,
})

const CloseButton = classed('button', {
  base: `
    bg-semantic-neutral-bg-strong-default
    text-semantic-neutral-icon-onDark
    rounded-full
    p-0.5
    absolute z-10
    top-[-7px] right-[-7px]
    hidden

    hover:block
    group-hover/item:block
  `,
})
