import { showToast } from '@motion/ui/base'

import { useWorkspaceFns } from '~/global/hooks'
import { useShiftProject as useShiftProjectCall } from '~/global/rpc/v2'
import { useCallback } from 'react'

type ShiftProjectModalProps = {
  projectId: string
  numDays: number
}

/**
 * @returns a function that shifts the tasks and stages of
 * a project by a specified number of days
 */
export const useShiftProjectDates = () => {
  const { getWorkspaceProjectById } = useWorkspaceFns()
  const { mutateAsync: shiftProjectDates } = useShiftProjectCall()

  return useCallback(
    async ({ projectId, numDays }: ShiftProjectModalProps) => {
      const project = getWorkspaceProjectById(projectId)
      if (project == null) {
        throw new Error('Project not found')
      }

      if (numDays === 0) {
        return true
      }

      try {
        await shiftProjectDates({ projectId, numDays: Math.round(numDays) })
      } catch {
        showToast('error', 'Moving project failed, please try again')
        return false
      }

      showToast('success', 'Project moved successfully')
      return true
    },
    [getWorkspaceProjectById, shiftProjectDates]
  )
}
