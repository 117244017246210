import { defineApi } from '../../core'
import { type RouteTypes } from '../types'

export type ChartsGetQuery = RouteTypes<'ChartsController_query'>
export const getQuery = defineApi<
  ChartsGetQuery['request'],
  ChartsGetQuery['response']
>().using({
  key: (args) => ['v2', 'charts', args],
  method: 'POST',
  uri: '/v2/charts/query',
  body: (args) => args,
})

export type ChartTasksQuery = RouteTypes<'ChartsController_getTasks'>
export const getChartTasks = defineApi<
  ChartTasksQuery['request'],
  ChartTasksQuery['response']
>().using({
  key: (args) => ['v2', 'charts', 'tasks', args],
  method: 'POST',
  uri: '/v2/charts/tasks',
  body: (args) => args,
})
