import { entries } from '@motion/utils/object'

import {
  clearProjectForParams,
  getProjectUrlParams,
  type ProjectUrlParams,
  type ProjectUrlSearchParams,
  // It's alright to reach for specific utils
  // eslint-disable-next-line import-x/no-restricted-paths
} from '~/areas/project/modals/project-modal/utils'
import { useCallback } from 'react'
import { useLocation, useParams } from 'react-router-dom'

export type ProjectModalParams = ProjectUrlSearchParams

export function useProjectModalUrl() {
  const { workspaceId: workspaceIdParam, projectId: projectIdParam } =
    useParams<ProjectUrlParams>()
  const { pathname, search } = useLocation()

  return useCallback(
    (params: ProjectModalParams = {}) => {
      const urlParams = new URLSearchParams(search)
      urlParams.delete('task')
      urlParams.delete('mTask')

      clearProjectForParams(urlParams)

      const newParams = getProjectUrlParams(
        params,
        workspaceIdParam,
        projectIdParam
      )

      for (const [key, value] of entries(newParams)) {
        if (key === 'forCustomField') {
          if (value == null || typeof value !== 'object') {
            urlParams.delete(key)
            continue
          }

          urlParams.set(key, JSON.stringify(value))
          continue
        }

        if (value) {
          urlParams.set(key, value as string)
        }
      }

      return `${pathname}?${urlParams.toString()}`
    },
    [pathname, projectIdParam, search, workspaceIdParam]
  )
}
