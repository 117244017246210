import { byProperty, cascade, Compare, maxBy } from '@motion/utils/array'
import {
  type DashboardViewCellSchema,
  type DashboardViewDefinitionV2,
} from '@motion/zod/client'

import { useMemo } from 'react'
import { type Layout, type Layouts } from 'react-grid-layout'

import { chartDefaultLayoutSettings, type TileLayout } from '../utils'

/**
 * Convert a dashboard view to a set of Layout for react-grid-layout.
 * `Layouts` is composed of arrays of `Layout` with different breakpoints.
 */
export function useViewToLayouts(view: DashboardViewDefinitionV2): Layouts {
  return useMemo(() => {
    const baseLayout = cellsToLayout(view.cells)

    const flow = convertToFlowLayout(baseLayout)
    const grid = [...baseLayout]

    return {
      flow,
      grid,
    } satisfies Record<TileLayout, Layout[]>
  }, [view])
}

function cellsToLayout(cells: DashboardViewCellSchema[]) {
  const cardCells = cells.map<Layout>((cell) => {
    const defaults = chartDefaultLayoutSettings[cell.chart.type]

    return {
      ...defaults,
      i: cell.id,
      h: cell.height,
      w: cell.width,
      x: cell.x,
      y: cell.y,
      cell,
    }
  })

  cardCells.push({
    i: 'add-card',
    x: 0,
    y: cardCells.length === 0 ? 0 : maxBy(cardCells, (c) => c.y + c.h),
    w: 12,
    h: 1,
    static: true,
    minH: 1,
    minW: 12,
  })
  return cardCells
}

function convertToFlowLayout(layouts: Layout[]) {
  let y = 0
  return layouts
    .toSorted(
      cascade(
        byProperty('y', Compare.numeric),
        byProperty('x', Compare.numeric)
      )
    )
    .reduce((acc, layout) => {
      acc.push({
        ...layout,
        x: 0,
        y: y,
        w: 2,
        minW: 2,
        static: true,
      })
      y += layout.h

      return acc
    }, [] as Layout[])
}
