import { API } from '@motion/rpc'
import { debounce } from '@motion/utils/core'
import { client } from '@motion/web-common/rpc'

import { type MutationIds } from '../handlers/utils'
import {
  type BulkTaskOperations,
  type CalendarsOperations,
  type FeedUpdates,
  type FolderBatchOperations,
  type PastDueBatchOperations,
  type ProjectBatchOperations,
  type ProjectDefinitionBatchOperations,
  type ProjectEtaBatchOperations,
  type ProjectEtaUpdate,
  type ScheduledEntitiesBatchOperations,
  type StageEtaBatchOperations,
  type StageEtaUpdate,
  type StatusBatchOperations,
  type TaskBatchOperations,
} from '../types'

export function taskRefresh(
  mutation: Partial<MutationIds>
): TaskBatchOperations {
  return { type: 'refresh-tasks', data: mutation }
}

export function projectRefresh(
  mutation: Partial<MutationIds>
): ProjectBatchOperations {
  return { type: 'refresh-projects', data: mutation }
}

export function foldersRefresh(
  mutation: Partial<MutationIds>
): FolderBatchOperations {
  return { type: 'refresh-folders', data: mutation }
}

export function statusRefresh(
  mutation: Partial<MutationIds> & { workspaces: string[] }
): StatusBatchOperations {
  return { type: 'refresh-statuses', data: mutation }
}

export function feedRefresh(
  feedUpdates: Partial<{ tasks: string[]; project: string[] }>
): FeedUpdates {
  return { type: 'refresh-feed', data: feedUpdates }
}

export function refreshCalendars(): CalendarsOperations {
  return { type: 'refresh-calendars' }
}

export function refreshScheduledEntities(
  scheduledEntityUpdate: Partial<{ workspaceId: string }> = {}
): ScheduledEntitiesBatchOperations {
  return { type: 'refresh-scheduled-entities', data: scheduledEntityUpdate }
}

export function refreshProjectEta(
  data: ProjectEtaUpdate
): ProjectEtaBatchOperations {
  return { type: 'refresh-project-eta', data }
}

export function refreshStageEta(data: StageEtaUpdate): StageEtaBatchOperations {
  return { type: 'refresh-stage-eta', data }
}

export function refreshPastDueTasks(): PastDueBatchOperations {
  return { type: 'refresh-past-due-tasks' }
}

export function refreshProjectDefinition(mutation: {
  workspaceId: string
  definitionId: string
}): ProjectDefinitionBatchOperations {
  return {
    type: 'refresh-project-definition',
    data: {
      workspaceId: mutation.workspaceId,
      definitionId: mutation.definitionId,
    },
  }
}

export function bulkTaskOperations(
  data: BulkTaskOperations['data'],
  operation: BulkTaskOperations['operation']
): BulkTaskOperations {
  return {
    type: 'bulk-task-operations',
    operation,
    data,
  }
}

export const invalidateWorkspacesV2 = debounce(
  () => {
    void client.invalidateQueries({ queryKey: API.workspacesV2.queryKeys.root })
  },
  5_000,
  { leading: true, trailing: true, maxWait: 10_000 }
)

export const invalidateFolders = debounce(
  () => {
    void client.invalidateQueries({ queryKey: API.folders.queryKeys.getAll })
  },
  5_000,
  { leading: true, trailing: true, maxWait: 10_000 }
)

export const invalidateUserSettings = debounce(
  () => {
    void client.invalidateQueries({ queryKey: API.userSettings.queryKeys.root })
  },
  5_000,
  { leading: true, trailing: true, maxWait: 10_000 }
)
