import { API } from '@motion/rpc'
import { type UserSerializer } from '@motion/rpc-types'
import { type WorkspaceMemberWithUser } from '@motion/ui-logic'
import { parseDate } from '@motion/utils/dates'
import { entries } from '@motion/utils/object'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useAuthenticatedUser } from '@motion/web-common/auth'
import { useModalApi } from '@motion/web-common/modals'

import { useQueryClient } from '@tanstack/react-query'
import {
  ConnectedTeamMembersTable,
  type TeamMembersTableEditType,
  type TeamMembersTableUser,
} from '~/areas/settings/components'
import {
  useAllWorkspaces,
  useCreateWorkspaceMembers,
  useDeleteWorkspaceMember,
  useDeleteWorkspaceMembers,
  useWorkspaceFns,
} from '~/global/hooks'
import { useTeamMembers } from '~/global/hooks/team'
import { useCurrentTeam, useUpdateTeamMemberRoles } from '~/global/rpc/team'
import { useAppDispatch, useAppSelector } from '~/state/hooks'
import { removeTeamMember } from '~/state/projectManagement/teamThunks'
import { selectEmail } from '~/state/userSlice'
import { DateTime } from 'luxon'
import { useCallback } from 'react'

import { UserTypeType } from '../../../../../types/User'
import {
  IndividualOrTeam,
  TeamMemberRole,
} from '../../../../ProjectManagement/types'

type SimpleWorkspace = { label: string; value: string }

export const TeamMembersPage = () => {
  const dispatch = useAppDispatch()

  const teamMembers = useTeamMembers()
  const pmWorkspaces = useAllWorkspaces()
  const { getWorkspaceMemberById, getWorkspaceMembers } = useWorkspaceFns()
  const { data: team } = useCurrentTeam()
  const { deleteWorkspaceMember } = useDeleteWorkspaceMember()
  const { createWorkspaceMembers } = useCreateWorkspaceMembers()
  const { deleteWorkspaceMembers } = useDeleteWorkspaceMembers()
  const { mutateAsync: updateTeamMemberRoles } = useUpdateTeamMemberRoles()

  const userEmail = useAppSelector(selectEmail)
  const authenticatedUser = useAuthenticatedUser()
  const modalApi = useModalApi()
  const queryClient = useQueryClient()

  const isAdmin =
    teamMembers.filter((member) => member.user.email === userEmail)[0]?.role ===
    'ADMIN'

  const onMemberRemove = async (email: string) => {
    const user = teamMembers.find((member) => member.user.email === email)?.user
    if (!user) return

    const isSelf = authenticatedUser.uid === user.id
    const confirmed = isSelf
      ? await modalApi.prompt('confirm', {
          analytics: {
            name: 'remove-self-from-team',
          },
          title: `Are you sure you want to remove yourself from the team?`,
          description:
            "You will lose access to Motion immediately. All other users will retain access and the team's subscription will not be cancelled.",
          destructive: true,
          confirmButtonText: 'Remove',
        })
      : await modalApi.prompt('confirm', {
          analytics: {
            name: 'remove-user-from-team',
          },
          title: `Are you sure you want to remove ${user.name} from the team?`,
          destructive: true,
          confirmButtonText: 'Remove',
        })

    if (confirmed !== true) return
    await dispatch(removeTeamMember(user.id))
    // Remove uses redux, also invalidate the query key
    void queryClient.invalidateQueries(API.teams.queryKeys.currentTeam)
    void recordAnalyticsEvent('TEAM_BILLING_REMOVE', {
      userId: user.id,
      email: user.email,
    })
  }

  const handleRoleChanges = async (
    updatedMembers: Record<string, TeamMembersTableUser>,
    teamId: string
  ) => {
    const newTeamMemberRoles: Record<TeamMemberRole, string[]> = {
      ADMIN: [],
      MEMBER: [],
      GUEST: [],
    }

    entries(updatedMembers).forEach(([email, updatedTeamMember]) => {
      const originalTeamMember = teamMembers.find(
        (member) => member.user.email === email
      )
      if (
        !originalTeamMember ||
        originalTeamMember.role === updatedTeamMember.role
      ) {
        return
      }

      newTeamMemberRoles[updatedTeamMember.role].push(originalTeamMember.id)
    })

    await Promise.all(
      entries(newTeamMemberRoles).map(([role, teamMemberIds]) => {
        if (teamMemberIds.length === 0) return

        return updateTeamMemberRoles({
          teamId,
          role: role as TeamMemberRole,
          teamMemberIds,
        })
      })
    )

    recordAnalyticsEvent('TEAM_MEMBERS_TABLE_BULK_EDIT_APPLIED', {
      type: 'role',
      count: Object.keys(updatedMembers).length,
      location: 'team-members-settings-page',
    })
  }

  const handleWorkspaceChanges = async (
    updatedMembers: Record<string, TeamMembersTableUser>,
    isBulk: boolean
  ) => {
    const newWorkspaceMembersByWorkspaceId: Record<string, string[]> = {}
    const removedWorkspaceMembersByWorkspaceId: Record<string, string[]> = {}

    entries(updatedMembers).forEach(([email, updatedTeamMember]) => {
      const originalTeamMember = teamMembers.find(
        (member) => member.user.email === email
      )
      if (!originalTeamMember) {
        return
      }

      const originalWorkspaces =
        displayedTeamMembers.find((m) => m.email === email)?.workspaces ?? []

      const workspacesToAdd = updatedTeamMember.workspaces.filter(
        (workspace) =>
          !originalWorkspaces.some((ws) => ws.value === workspace.value)
      )

      const workspacesToRemove = originalWorkspaces.filter((workspace) => {
        const isIndividual =
          pmWorkspaces.find((ws) => ws.id === workspace.value)?.type ===
          IndividualOrTeam.INDIVIDUAL
        return (
          !updatedTeamMember.workspaces.some(
            (ws) => ws.value === workspace.value
          ) && !isIndividual
        )
      })

      workspacesToAdd.forEach((workspace) => {
        newWorkspaceMembersByWorkspaceId[workspace.value] ??= []
        newWorkspaceMembersByWorkspaceId[workspace.value].push(
          originalTeamMember.userId
        )
      })

      workspacesToRemove.forEach(async (workspace) => {
        const workspaceMember = getWorkspaceMemberById(
          workspace.value,
          originalTeamMember.userId
        )
        if (!workspaceMember) return

        if (isBulk) {
          removedWorkspaceMembersByWorkspaceId[workspace.value] ??= []
          removedWorkspaceMembersByWorkspaceId[workspace.value].push(
            workspaceMember.id
          )
        } else {
          await onMemberWorkspaceRemove(
            originalTeamMember.user,
            workspace,
            getWorkspaceMembers(workspace.value)
          )
        }
      })
    })

    await Promise.all([
      entries(newWorkspaceMembersByWorkspaceId).map(
        ([workspaceId, userIds]) => {
          return createWorkspaceMembers({ workspaceId, userIds })
        }
      ),
      entries(removedWorkspaceMembersByWorkspaceId).map(
        ([workspaceId, memberIds]) => {
          return deleteWorkspaceMembers({
            workspaceId,
            workspaceMemberIds: memberIds,
          })
        }
      ),
    ])

    if (isBulk) {
      recordAnalyticsEvent('TEAM_MEMBERS_TABLE_BULK_EDIT_APPLIED', {
        type: 'workspace',
        count: Object.keys(updatedMembers).length,
        location: 'team-members-settings-page',
      })
    }
  }

  const onMemberWorkspaceRemove = useCallback(
    async (
      user: UserSerializer,
      workspace: SimpleWorkspace,
      workspaceMembers: WorkspaceMemberWithUser[]
    ) => {
      const confirmed = await modalApi.prompt('confirm', {
        analytics: {
          name: 'remove-user-from-workspace',
        },
        title: `Are you sure you would like to remove ${user.name} from ${workspace.label}?`,
        destructive: true,
        confirmButtonText: 'Remove',
      })
      if (confirmed !== true) return

      const workspaceMember = workspaceMembers.find(
        (member) => member.user.id === user.id
      )
      if (!workspaceMember) return
      await deleteWorkspaceMember({
        workspaceId: workspace.value,
        memberId: workspaceMember.id,
      })
    },
    [deleteWorkspaceMember, modalApi]
  )

  if (!team) {
    return null
  }

  const simplifiedWorkspaces = pmWorkspaces
    .filter((workspace) => workspace.type === IndividualOrTeam.TEAM)
    .map((workspace) => ({ label: workspace.name, value: workspace.id }))

  const displayedTeamMembers: TeamMembersTableUser[] = teamMembers.map(
    (teamMember) => ({
      email: teamMember.user.email,
      name: teamMember.user.name,
      role: teamMember.role as TeamMemberRole,
      editableRole: isAdmin && teamMember.role !== TeamMemberRole.ADMIN,
      dateAdded: teamMember.createdTime
        ? parseDate(teamMember.createdTime).toLocaleString(DateTime.DATE_MED)
        : undefined,
      invited:
        (team &&
          team.invites.some(
            (invite) => invite.email === teamMember.user.email
          )) ||
        teamMember.user.type === UserTypeType.PENDING_INVITED,
      key: teamMember.userId,
      teamMember,
      workspaces: pmWorkspaces
        .filter((workspace) => {
          const workspaceMember = getWorkspaceMemberById(
            workspace.id,
            teamMember.userId
          )
          return (
            !!workspaceMember &&
            !workspaceMember.user.deleted &&
            workspaceMember.status === 'ACTIVE'
          )
        })
        .map((workspace) => ({
          label: workspace.name,
          value: workspace.id,
        })),
      notAccepted:
        teamMember.user.onboardingComplete === false &&
        teamMember.user.email !== userEmail,
      profileUrl: teamMember.user.picture ?? undefined,
    })
  )

  const onMembersChange = async (
    updatedMembers: Record<string, TeamMembersTableUser>,
    type: TeamMembersTableEditType,
    isBulk: boolean
  ) => {
    switch (type) {
      case 'role':
        return handleRoleChanges(updatedMembers, team.id)
      case 'workspace':
        return handleWorkspaceChanges(updatedMembers, isBulk)
    }
  }

  return (
    <div className='flex h-full w-full flex-col gap-2 pb-20'>
      <ConnectedTeamMembersTable
        users={displayedTeamMembers}
        teamWorkspaces={simplifiedWorkspaces}
        onTeamMembersUpdate={onMembersChange}
        onRemove={onMemberRemove}
        showRemoveButton={isAdmin}
      />
    </div>
  )
}
