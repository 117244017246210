import { createLookup } from '@motion/utils/object'
import { type DashboardViewChartSchema } from '@motion/zod/client'

import {
  type ConnectedChartProps,
  ConnectedNumberChart,
  ConnectedPieChart,
} from '~/areas/charts'
import { ConnectedBarChart } from '~/areas/charts/components/connected-bar-chart'
import { ConnectedLineChart } from '~/areas/charts/components/connected-line-chart'
import { type ComponentType } from 'react'

type ChartLookup = Record<
  DashboardViewChartSchema['type'],
  ComponentType<ConnectedChartProps<any>>
>

export const chartComponentLookup = createLookup<
  ChartLookup & {
    default: null
  }
>({
  pie: ConnectedPieChart,
  number: ConnectedNumberChart,
  bar: ConnectedBarChart,
  line: ConnectedLineChart,
  default: null,
})
