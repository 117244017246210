import { createUseMutation } from '@motion/rpc'
import { type OptimisticUpdateValue } from '@motion/rpc-cache'
import { showToast } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'
import {
  type CreateCalendarEventSchema,
  type MeetingInsightsQueryResponseSchema,
} from '@motion/zod/client'

import { useQueryClient } from '@tanstack/react-query'
import {
  createCalendarEventAI,
  meetingInsightsKey,
} from '~/areas/ai-hackerhouse/rpc-definition'
import {
  applyCalendarEventCreateToCaches,
  applyOptimisticCalendarEventCreate,
} from '~/global/cache'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

const useCreateCalendarEventMutation = createUseMutation(createCalendarEventAI)
export const useCreateCalendarEvent = () => {
  const client = useQueryClient()

  return useCreateCalendarEventMutation({
    onSuccess: (data, _, context) => {
      const { cacheUpdates } = context as {
        cacheUpdates: OptimisticUpdateValue | undefined
      }
      cacheUpdates?.rollback()
      applyCalendarEventCreateToCaches(client, data)

      client.setQueryData<MeetingInsightsQueryResponseSchema>(
        meetingInsightsKey.byCalendarId(data.id),
        (oldData) => {
          const meetingInsight = data.meetingInsights

          if (!meetingInsight) return oldData

          if (!oldData) {
            return {
              ids: [meetingInsight.id],
              meta: {
                model: 'meetingInsights',
              },
              models: {
                meetingInsights: {
                  [meetingInsight.id]: meetingInsight,
                },
                events: {},
              },
            }
          }

          const cloned = { ...oldData }

          cloned.models.meetingInsights = {
            ...cloned.models.meetingInsights,
            [meetingInsight.id]: meetingInsight,
          }

          return cloned
        }
      )
    },
    onMutate: (data) => {
      const cacheUpdates = applyOptimisticCalendarEventCreate(client, data)
      return { cacheUpdates }
    },
    onError: (err, _, context) => {
      const { cacheUpdates } = context as {
        cacheUpdates: OptimisticUpdateValue | undefined
      }
      cacheUpdates?.rollback()
    },
  })
}

export const useCreateEvent = () => {
  const { mutateAsync: createCalendarEvent } = useCreateCalendarEvent()

  return useCallback(
    async (data: CreateCalendarEventSchema & { calendarId: string }) => {
      try {
        recordAnalyticsEvent('CALENDAR_PEEK_MODAL_CREATE_EVENT', {
          isRecurring: Boolean(data.recurrence),
        })

        const response = await createCalendarEvent(data)

        showToast('success', 'Event created')

        return response
      } catch (e) {
        Sentry.captureException(e)
        showErrorToast(e)
      }
    },
    [createCalendarEvent]
  )
}
