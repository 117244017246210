import {
  type MeetingActionItemTaskDataSchema,
  type TaskSchema,
} from '@motion/rpc-types'
import { getDefaultChunkDuration } from '@motion/ui-logic'

import { type ReactNode, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { useInitialFormData } from './hooks'

type ActionItemFormProps = {
  children: ReactNode
  initialData: MeetingActionItemTaskDataSchema | TaskSchema
}

export function ActionItemForm({ children, initialData }: ActionItemFormProps) {
  const initialFormData = useInitialFormData({ initialData })

  const form = useForm({
    defaultValues: initialFormData,
    shouldUseNativeValidation: false,
    mode: 'onSubmit',
  })

  useEffect(() => {
    const subscription = form.watch((_, { name }) => {
      if (name == null) return

      if (name.includes('duration')) {
        // Don't run for task relative intervals
        if (name.includes('RelativeInterval')) {
          return
        }

        // Set the minimum duration to the default chunk duration when the duration changes
        form.setValue(
          'minimumDuration',
          getDefaultChunkDuration(form.getValues('duration'))
        )
      }
    })
    return () => subscription.unsubscribe()
  }, [form])

  return <FormProvider {...form}>{children}</FormProvider>
}
