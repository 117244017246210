import {
  type ChartGroupDefinition,
  type PointObject,
} from '@motion/web-charts/common'
import { type DashboardViewGroupBySchema } from '@motion/zod/client'

import { useLookup } from '~/global/cache'
import { useMemo } from 'react'

import { createGroupBySort } from '../utils'

export const useChartGroupDefinition = (
  group: DashboardViewGroupBySchema | undefined
): ChartGroupDefinition<PointObject, string> | null => {
  const lookup = useLookup()

  return useMemo(() => {
    if (group == null) return null

    return {
      key: group.field,
      sort: createGroupBySort(group, lookup),
    }
  }, [group, lookup])
}
