import { cloneDeep } from '@motion/utils/core'
import { clamp } from '@motion/utils/math'
import { Sentry } from '@motion/web-base/sentry'

import { LexoRank } from 'lexorank'

import {
  type DragProjection,
  type LevelCalculation,
  type SortableTreeviewBaseItem,
} from '../types'

type UseDragProjectionProps<
  T extends SortableTreeviewBaseItem = SortableTreeviewBaseItem,
> = {
  items: T[]
  activeId: T['id'] | null
  overId: T['id'] | null
  dragLevel: number
  getMinLevel?: LevelCalculation<T>
  getMaxLevel?: LevelCalculation<T>
}

export function useDragProjection<T extends SortableTreeviewBaseItem>({
  items: ogItems,
  activeId,
  overId,
  dragLevel,
  getMinLevel = defaultGetMinLevel,
  getMaxLevel = defaultGetMaxLevel,
}: UseDragProjectionProps<T>): DragProjection<T> | null {
  if (activeId == null || overId == null) return null

  const items = cloneDeep(ogItems)

  // Get the item that we're currently "over"
  const overItemIndex = items.findIndex(({ id }) => id === overId)

  // Get the item we're currently dragging
  const activeItemIndex = items.findIndex(({ id }) => id === activeId)
  const activeItem = items[activeItemIndex] as T | undefined

  if (!activeItem) return null

  // If the item we're dragging is a workspace or folder, get the count of the
  // number of children it has and move the active item and it's children in the
  // array.
  const childCount = getChildCount()
  const splicedItems = items.splice(activeItemIndex, 1 + childCount)
  const insertIndex =
    overItemIndex > activeItemIndex ? overItemIndex - childCount : overItemIndex

  items.splice(insertIndex, 0, ...splicedItems)

  // Get the item that is previous and next to the active item
  const previousItem = items[insertIndex - 1] as T | undefined
  const nextItem = items[insertIndex + childCount + 1] as T | undefined

  // Based on the cursor position, get the "level" that the cursor is at, as well
  // as the minimum and maximum levels based on where we're currently "over".
  const projectedLevel = activeItem.level + dragLevel
  const maxLevel = getMaxLevel(activeItem, previousItem)
  const minLevel = getMinLevel(activeItem, nextItem)
  const level = clamp(projectedLevel, minLevel, maxLevel)
  const levelDiff = level - activeItem.level

  activeItem.level = level

  // When moving a folder with children, we need to also adjust the level of each
  // child by the same number of levels the active item has moved.
  if (levelDiff !== 0 && childCount > 0) {
    items.splice(
      insertIndex + 1, // Skip the activeItem
      splicedItems.length - 1,
      ...splicedItems.toSpliced(0, 1).map((item) => ({
        ...item,
        level: item.level + levelDiff,
      }))
    )
  }

  // Get the next and previous siblings of the "over" item. By "siblings" we mean
  // items in the same level/container.
  const previousSibling: T | null =
    previousItem && level === previousItem.level ? previousItem : null
  const nextSibling: T | null =
    nextItem && level === nextItem.level ? nextItem : null

  let newOrder!: LexoRank

  // Determine the new Lexorank for the active item based on its new position
  if (!previousSibling && !!nextSibling) {
    // Placed at the top of an expanded container
    newOrder = nextSibling.order.genPrev()
  } else if (!!previousSibling && !!nextSibling) {
    // Placed in-between two existing items
    const prevOrder = previousSibling.order
    const nextOrder = nextSibling.order

    try {
      newOrder = prevOrder.between(nextOrder)
    } catch (e) {
      newOrder = nextOrder

      /*
       * If an item is dragged in between two items that have the same order,
       * an exception is thrown. We don't have a great way to handle this on
       * the client, and probably need to trigger a full re-rank on the server.
       */

      Sentry.captureException(
        new Error(
          'Could not generate new Lexorank while reordering folder tree items',
          { cause: e }
        ),
        {
          extra: {
            activeItem,
            nextItem,
            nextOrder,
            nextSibling,
            prevOrder,
            previousItem,
            previousSibling,
          },
          tags: {
            position: 'useGetDragProjection',
          },
        }
      )
    }
  } else if (!!previousSibling && !nextSibling) {
    // Placed at the end of an expanded container
    newOrder = previousSibling.order.genNext()
  } else if (
    previousItem &&
    previousItem.isContainer &&
    !previousItem.isContainerExpanded
  ) {
    // Placed inside a collapsed container, append to the top
    if (nextSibling) {
      // Collapsed folder is not empty
      newOrder = nextSibling.order.genPrev()
    } else {
      // Collapsed folder is empty
      newOrder = LexoRank.middle()
    }
  } else {
    // Placed in an empty expanded container
    newOrder = LexoRank.middle()
  }

  newOrder = newOrder ?? LexoRank.middle()

  const parentId = getParentId()
  const hasMovedParent = activeItem.parentId !== parentId
  const hasMoved = hasMovedParent || activeItemIndex !== overItemIndex

  activeItem.order = newOrder
  activeItem.parentId = parentId

  return {
    hasMoved,
    hasMovedParent,
    level,
    maxLevel,
    minLevel,
    newItems: items,
    order: newOrder,
    parentId,
  }

  function getParentId(): T['id'] | null {
    if (level === 0 || !previousItem) {
      return items[0]?.parentId ?? null
    }

    if (level === previousItem.level) {
      return previousItem.parentId
    }

    if (level > previousItem.level) {
      return previousItem.id
    }

    const newParent = items
      .slice(0, overItemIndex)
      .reverse()
      .find((item) => item.level === level)?.parentId

    return newParent ?? null
  }

  function getChildCount(): number {
    if (activeItemIndex == null || !activeItem) return 0

    const slicedItems = items.slice(activeItemIndex)

    const match = slicedItems.findIndex((_item, i, arr): boolean => {
      const nextItem = arr[i + 1]

      if (!nextItem) return false

      return nextItem.level <= activeItem.level
    })

    return clamp(match, 0, slicedItems.length)
  }
}

const defaultGetMinLevel: LevelCalculation = () => 0

const defaultGetMaxLevel: LevelCalculation = (_activeItem, previousItem) =>
  previousItem ? previousItem.level + Number(previousItem.isContainer) : 0
