import { type AssignmentSchema } from '@motion/rpc-types'
import { Tooltip } from '@motion/ui/base'

import { PermissionPopover } from '~/areas/ai-hackerhouse/automation/permission-popover'
import { type Invitee } from '~/areas/ai-hackerhouse/automation/types'
import { useUpdateMeetingInsights } from '~/areas/ai-hackerhouse/hooks/rpc/use-update-meeting-insights'
import { useCurrentTeam } from '~/global/rpc/team'

import { useEventModalState } from '../contexts'

export function ConnectedPermissionField() {
  const { meetingInsights } = useEventModalState()
  const { data: team } = useCurrentTeam()
  const { mutateAsync: updateMeetingInsights } = useUpdateMeetingInsights()

  if (
    meetingInsights == null ||
    meetingInsights.meetingBotStatus === 'DONT_SCHEDULE'
  ) {
    return null
  }

  const invitees =
    meetingInsights.assignments?.flatMap((assignment) =>
      assignment.targetIds.map((id) => ({
        id,
        type: assignment.type,
        action: assignment.action,
      }))
    ) ?? []

  function handleIdsChange(type: AssignmentSchema['type'], ids: string[]) {
    if (meetingInsights == null) return

    const copy = [...(meetingInsights.assignments ?? [])]
    // Note: the following assumes that there's only one action type for each assignment type, for now
    const existingAssignment = copy.find((a) => a.type === type)

    if (existingAssignment) {
      existingAssignment.targetIds = ids
    } else {
      copy.push({ type, targetIds: ids, action: 'read' })
    }

    updateMeetingInsights({
      meetingInsightsId: meetingInsights.id,
      assignments: copy,
    })
  }

  function handleRemove(invitee: Invitee) {
    if (meetingInsights == null) return

    const copy = [...(meetingInsights.assignments ?? [])]
    copy.forEach((a) => {
      a.targetIds = a.targetIds.filter((id) => id !== invitee.id)
    })

    updateMeetingInsights({
      meetingInsightsId: meetingInsights.id,
      assignments: copy,
    })
  }

  return (
    <div className='flex flex-col mt-2 gap-1'>
      <label
        htmlFor='permission-button'
        className='text-2xs text-semantic-neutral-text-subtle'
      >
        Share notes & recording with
      </label>
      <Tooltip
        renderContent={() =>
          team == null ? 'Permissions are only available for teams' : null
        }
      >
        <PermissionPopover
          invitees={invitees}
          onSelected={handleIdsChange}
          onRemove={handleRemove}
        />
      </Tooltip>
    </div>
  )
}
