import {
  MultiPersonDropdown,
  MultiSelectDropdown,
  PersonDropdown,
  SelectDropdown,
} from '~/areas/custom-fields/components'

import { Date } from './date'
import { Number } from './number'
import { Text } from './text'
import { Url } from './url'

export const CustomField = {
  Date,
  MultiSelect: MultiSelectDropdown,
  Number,
  Select: SelectDropdown,
  Text,
  Url,
  MultiPerson: MultiPersonDropdown,
  Person: PersonDropdown,
}
