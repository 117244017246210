import { AutoScheduleToggle, Tooltip } from '@motion/ui/base'
import {
  ProjectAutoScheduleTooltipContent,
  StageOrProjectAutoscheduleDisabledMessage,
} from '@motion/ui-logic'

import {
  useAutoScheduleProject,
  useProjectTasks,
} from '~/areas/task-project/hooks'
import { useNonAutoSchedulableStatuses } from '~/global/hooks'
import { useMemo } from 'react'

import { useProjectInTree } from './hooks'

import { type GroupedNode } from '../../grouping'

export type ProjectNameAutoScheduleToggleProps = {
  row: GroupedNode
}
export function ProjectNameAutoScheduleToggle({
  row,
}: ProjectNameAutoScheduleToggleProps) {
  const project = useProjectInTree(row)

  const { data: tasks } = useProjectTasks({
    projectId: project?.id,
    workspaceId: project?.workspaceId,
  })

  const autoScheduleProject = useAutoScheduleProject({
    projectId: project?.id,
  })

  const autoScheduleDisabledStatusIds = useNonAutoSchedulableStatuses(
    project?.workspaceId
  ).map((status) => status.id)

  const isDisabled = useMemo(
    () =>
      tasks.every(
        (task) =>
          autoScheduleDisabledStatusIds.includes(task.statusId) ||
          task.assigneeUserId == null
      ),
    [tasks, autoScheduleDisabledStatusIds]
  )

  if (project == null) return null

  const isAutoScheduled = tasks.some((task) => task.isAutoScheduled)

  const disabledMessage = isDisabled
    ? StageOrProjectAutoscheduleDisabledMessage
    : undefined
  const tooltipMessage = isAutoScheduled
    ? ProjectAutoScheduleTooltipContent.autoScheduled
    : ProjectAutoScheduleTooltipContent.notAutoScheduled

  const tooltipContent = disabledMessage ?? tooltipMessage

  return (
    <Tooltip content={tooltipContent} asChild>
      <AutoScheduleToggle
        checked={isAutoScheduled}
        disabled={isDisabled}
        labelHidden
        onChange={(e) => autoScheduleProject(e.target.checked)}
      />
    </Tooltip>
  )
}
