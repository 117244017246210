import { useHasTreatment } from '@motion/web-common/flags'
import { type VersionedViewV2 } from '@motion/zod/client'

import { useCopyHtmlLinkToClipboard } from '~/global/hooks'
import { useUriByRouteId } from '~/routing'
import { useCallback } from 'react'

import { viewIdSlug } from './view-id-slug'

export function useCopyViewLink() {
  const clipboard = useCopyHtmlLinkToClipboard()
  const routesV4 = useHasTreatment('saved-views-as-tabs')
  const getRouteUri = useUriByRouteId()

  return useCallback(
    (view: VersionedViewV2) => {
      if (routesV4) {
        const viewUrlPath = getRouteUri('parent', {
          viewId: viewIdSlug(view.id),
        })

        const newUri = new URL(viewUrlPath, window.location.origin)
        return clipboard(newUri.toString(), view.name)
      }

      const newUri = new URL(window.location.toString())
      newUri.searchParams.set('view', view.id)

      clipboard(newUri.toString(), view.name)
    },
    [clipboard, getRouteUri, routesV4]
  )
}
