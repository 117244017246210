/* c8 ignore start */

import { useEffect, useState } from 'react'

import { useDebouncedCallback } from './use-debounced-callback'

type ElementSize = {
  width: number
  height: number
}

type BoxOptions = 'border-box' | 'content-box' | 'device-pixel-content-box'

export type UseElementSizeOptions = {
  boxModel: BoxOptions
  debounce: DebounceOptions
}

type DebounceOptions = {
  delay: number
  maxWait: number
}
const DEFAULT_OPTIONS: UseElementSizeOptions = {
  boxModel: 'border-box',
  debounce: { delay: 0, maxWait: 100 },
}

export function useElementSize(opts?: Partial<UseElementSizeOptions>) {
  const {
    boxModel = DEFAULT_OPTIONS.boxModel,
    debounce = DEFAULT_OPTIONS.debounce,
  } = opts ?? DEFAULT_OPTIONS

  const [element, setElement] = useState<HTMLElement | null>(null)
  const [size, setSize] = useState<ElementSize>({ width: 0, height: 0 })

  const debouncedSet = useDebouncedCallback(
    (size: ElementSize) => setSize(size),
    debounce.delay,
    { maxWait: debounce.maxWait }
  )

  useEffect(() => {
    const el = element
    if (el == null) return

    const obs = new ResizeObserver((entries) => {
      debouncedSet(normalizeEntry(entries[0], boxModel))
    })
    obs.observe(el, { box: boxModel })

    return () => obs.disconnect()
  }, [boxModel, debouncedSet, element])

  return [size, setElement] as const
}

// Normalizes the ResizeObserverEntry to handle some edge cases
// https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserverEntry#examples

function normalizeEntry(entry: ResizeObserverEntry, mode: BoxOptions) {
  const prop =
    mode === 'border-box'
      ? 'borderBoxSize'
      : mode === 'content-box'
        ? 'contentBoxSize'
        : 'devicePixelContentBoxSize'

  if (entry[prop]) {
    if (entry[prop][0]) {
      return {
        width: entry[prop][0].inlineSize,
        height: entry[prop][0].blockSize,
      }
    }
    return {
      // @ts-expect-error - handles old firefox
      width: entry[prop].inlineSize,
      // @ts-expect-error - handles old firefox
      height: entry[prop].blockSize,
    }
  }
  return {
    width: entry.contentRect.width,
    height: entry.contentRect.height,
  }
}
