import { FilledChevronRightSolid } from '@motion/icons'
import { classed } from '@motion/theme'

import type { Row } from '@tanstack/react-table'

export function ToggleTasksButton({ row }: { row?: Row<any> }) {
  const expanded = row?.getIsExpanded() ?? false

  return (
    <button
      type='button'
      className='size-4 flex items-center justify-center hover:bg-button-neutral-outlined-bg-hover'
      onClick={row?.getToggleExpandedHandler()}
    >
      <ExpandIcon expanded={expanded} />
    </button>
  )
}

const ExpandIcon = classed(FilledChevronRightSolid, {
  base: `
  transition-transform
  size-3
  text-button-neutral-outlined-icon-default`,
  variants: {
    expanded: {
      true: 'rotate-90',
      false: 'rotate-0',
    },
  },
  defaultVariants: {
    expanded: 'true',
  },
})
