import {
  ArrowDownSolid,
  ArrowUpSolid,
  DotsHorizontalSolid,
  DuplicateOutline,
  PencilSolid,
  StarCrossOutOutline,
  StarOutline,
  TrashOutline,
} from '@motion/icons'
import { type COLOR } from '@motion/shared/common'
import {
  ActionList,
  IconButton,
  PopoverTrigger,
  showToast,
} from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'
import { useModalApi } from '@motion/web-common/modals'

import {
  useDeleteFolder,
  useMoveFolderItemInDirection,
  useUserFavoritedItem,
} from '~/areas/folders/hooks'
import { useHasPinnedViewsTreatment } from '~/areas/sidebar/hooks'
import { ColorDropdownContent } from '~/global/components/dropdowns'
import { useUpdateFolder } from '~/global/rpc/folders'
import { showErrorToast } from '~/global/toasts'
import { useClipboard } from '~/localServices/clipboard'

import type { WorkspacesTreeviewItem } from '../types'

type WorkspaceActionMenuProps = {
  folder: WorkspacesTreeviewItem['item']
  moveActionAllowed: {
    up: boolean
    down: boolean
  }
}

export const FolderActionsMenu = (props: WorkspaceActionMenuProps) => {
  return (
    <PopoverTrigger
      renderPopover={({ close }) => (
        <FolderActionsPopoverContents close={close} {...props} />
      )}
    >
      <IconButton
        variant='muted'
        sentiment='neutral'
        size='xsmall'
        icon={DotsHorizontalSolid}
        onClick={() => {
          recordAnalyticsEvent('FOLDERS_CLICKED_SIDEBAR_BUTTON', {
            itemType: 'FOLDER',
            button: 'ACTIONS',
          })
        }}
      />
    </PopoverTrigger>
  )
}

type FolderActionsPopoverContentsProps = WorkspaceActionMenuProps & {
  close: () => void
}

export const FolderActionsPopoverContents = ({
  folder,
  moveActionAllowed,
  close,
}: FolderActionsPopoverContentsProps) => {
  const modalApi = useModalApi()
  const { mutateAsync: updateFolder } = useUpdateFolder()
  const deleteFolder = useDeleteFolder()
  const moveFolderItemInDirection = useMoveFolderItemInDirection()
  const clipboard = useClipboard()

  const hasPinnedViewsTreatment = useHasPinnedViewsTreatment()
  const { isFavorited, toggleFavorite } = useUserFavoritedItem(
    'FOLDER',
    folder.itemId
  )

  const handleUpdateFolderColor = async (color: COLOR) => {
    const folderId = folder.itemId

    recordAnalyticsEvent('FOLDERS_UPDATED_FOLDER', {
      updated: 'COLOR',
      location: 'SIDEBAR',
    })

    try {
      await updateFolder({ folderId, color })

      showToast('success', 'Folder color updated')
    } catch (e) {
      showErrorToast(e, 'Could not update folder color')

      Sentry.captureException(
        new Error('Failed to update folder color', { cause: e }),
        {
          extra: {
            folderId,
            color,
          },
          tags: {
            position: 'FolderActionsMenu',
          },
        }
      )
    }
  }

  const handleDeleteFolder = async () => {
    recordAnalyticsEvent('FOLDERS_DELETED_FOLDER', {
      location: 'SIDEBAR',
    })

    try {
      await deleteFolder(folder.itemId)
    } catch (e) {
      Sentry.captureException(
        new Error('Failed to delete folder', { cause: e }),
        {
          extra: {
            folderId: folder.itemId,
          },
          tags: {
            position: 'FolderActionsMenu',
          },
        }
      )
    }
  }

  const handleEditFolder = () => {
    modalApi.open('edit-folder', {
      folderId: folder.itemId,
    })
  }

  const handleCopyLink = () => {
    recordAnalyticsEvent('FOLDERS_COPIED_LINK', {
      location: 'SIDEBAR',
    })

    clipboard.write({
      text: new URL(folder.url, window.location.origin).toString(),
    })
  }

  return (
    <div className='scrollbar-none w-full scroll-py-1 overflow-y-auto overflow-x-hidden'>
      <div className='p-2 border-b border-dropdown-border space-y-2'>
        <ColorDropdownContent
          selectedColor={folder.color as COLOR}
          onChange={(color) => {
            void handleUpdateFolderColor(color)
          }}
          close={close}
        />
      </div>

      <ActionList
        onActionAnyItem={close}
        sections={[
          {
            items: [
              {
                prefix: <PencilSolid />,
                content: 'Edit',
                onAction: handleEditFolder,
              },
              {
                prefix: <DuplicateOutline />,
                content: 'Copy link',
                onAction: handleCopyLink,
              },
              hasPinnedViewsTreatment && {
                prefix: isFavorited ? <StarCrossOutOutline /> : <StarOutline />,
                content: isFavorited
                  ? 'Remove from Favorites'
                  : 'Add to Favorites',
                onAction: toggleFavorite,
              },
              // {
              //   prefix: <ArrowRightOutline />,
              //   content: 'Move to',
              //   onAction: () => {
              //     //
              //   },
              // },
            ],
          },
          {
            items: [
              {
                prefix: <ArrowUpSolid />,
                content: 'Move up',
                disabled: !moveActionAllowed.up,
                onAction: () => {
                  recordAnalyticsEvent('FOLDERS_SHIFT_SIDEBAR_ITEM', {
                    itemType: 'FOLDER',
                    direction: 'UP',
                  })

                  moveFolderItemInDirection(folder.id, -1)
                },
              },
              {
                prefix: <ArrowDownSolid />,
                content: 'Move down',
                disabled: !moveActionAllowed.down,
                onAction: () => {
                  recordAnalyticsEvent('FOLDERS_SHIFT_SIDEBAR_ITEM', {
                    itemType: 'FOLDER',
                    direction: 'DOWN',
                  })

                  moveFolderItemInDirection(folder.id, 1)
                },
              },
            ],
          },
          {
            items: [
              {
                prefix: <TrashOutline />,
                content: 'Delete',
                destructive: true,
                onAction: handleDeleteFolder,
              },
            ],
          },
        ]}
      />
    </div>
  )
}
