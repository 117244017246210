import { isNoneId } from '@motion/shared/identifiers'
import { createNoneProject } from '@motion/ui-logic/pm/data'
import { type ProjectSchema, type WorkspaceSchema } from '@motion/zod/client'

import {
  ModalFieldButton,
  type ModalFieldButtonProps,
} from '~/areas/ai-hackerhouse/event/modals/action-item-modal/components/modal-field-button'
import { useCachedItem } from '~/global/cache'
import { ProjectBadge } from '~/global/components/badges'
import {
  WorkspacesTreeDropdown,
  type WorkspacesTreeDropdownProps,
} from '~/global/components/dropdowns'
import { ProjectLabel, WorkspaceLabel } from '~/global/components/labels'
import { useProject, useWorkspaceById } from '~/global/hooks'

export type WorkspaceProjectFieldProps = {
  workspaceId: WorkspaceSchema['id']
  projectId: ProjectSchema['id'] | null
  onChange: (
    workspaceId: WorkspaceSchema['id'],
    projectId: ProjectSchema['id'] | null
  ) => void
  disabled?: boolean
  suffix?: ModalFieldButtonProps['suffix']
  hideNoProject?: WorkspacesTreeDropdownProps['hideNoProject']
  showFullPath?: boolean
  showIcon?: boolean
  size?: ModalFieldButtonProps['size']
}
export const WorkspaceProjectField = ({
  workspaceId,
  projectId,
  onChange,
  disabled,
  suffix,
  hideNoProject,
  showFullPath,
  showIcon,
  size = 'normal',
}: WorkspaceProjectFieldProps) => {
  const selectedWorkspace = useWorkspaceById(workspaceId)
  const selectedProject =
    useProject(projectId) ?? createNoneProject(workspaceId)

  return (
    <WorkspacesTreeDropdown
      // TODO: if no project id is selected select the "none" item which is the parent, in this case a workspace
      // for tasks this is only true while we don't allow task in folders without being in a project.
      selectedId={projectId ?? workspaceId}
      workspaceId={workspaceId}
      hideNoProject={hideNoProject}
      onChange={(selected) => {
        onChange(selected.workspaceId, selected.projectId)
      }}
    >
      <ModalFieldButton
        size={size}
        icon={showIcon && <ProjectBadge value={selectedProject} />}
        disabled={disabled}
        suffix={suffix}
      >
        {showFullPath ? (
          <FullPathLabel
            workspace={selectedWorkspace}
            project={selectedProject}
          />
        ) : (
          <ProjectLabel value={selectedProject} nameOnly />
        )}
      </ModalFieldButton>
    </WorkspacesTreeDropdown>
  )
}

type FullPathLabelProps = {
  workspace: WorkspaceSchema | null
  project: ProjectSchema | null
}

function FullPathLabel({ workspace, project }: FullPathLabelProps) {
  const folder = useCachedItem('folders', project?.folderId ?? '')

  if (workspace == null) {
    return <span>No workspace</span>
  }

  if (project == null || isNoneId(project.id)) {
    return <WorkspaceLabel value={workspace} />
  }

  // the project is directly in the workspace
  if (folder?.type === 'WORKSPACE' && folder?.targetId === workspace.id) {
    return (
      <span className='flex flex-row gap-0.5'>
        <span className='max-w-[120px] truncate'>
          <WorkspaceLabel size='xsmall' value={workspace} />
        </span>
        <span>/</span>
        <span className='max-w-[120px] truncate'>
          <ProjectLabel size='xsmall' value={project} />
        </span>
      </span>
    )
  }

  // the project is in a folder
  return (
    <span className='flex flex-row gap-0.5'>
      <span className='max-w-[120px] truncate'>
        <WorkspaceLabel size='xsmall' value={workspace} />
      </span>
      <span>/</span>
      <span>...</span>
      <span>/</span>
      <span className='max-w-[120px] truncate'>
        <ProjectLabel size='xsmall' value={project} />
      </span>
    </span>
  )
}
