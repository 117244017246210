import { PlusSolid } from '@motion/icons'
import { Button } from '@motion/ui/base'
import { getPrefixFromMaybeCustomFieldKey } from '@motion/ui-logic'

import {
  type FilterDefinition,
  useEntityFilterState,
  useFieldFilter,
} from '~/areas/project-management/filters'
import { AddFilterButton } from '~/areas/project-management/filters/components/v2/add-filter-button'
import {
  useProjectFilterDefinitions,
  useTaskFilterDefinitions,
} from '~/areas/project-management/pages/pm-v3/filtering/hooks'
import { usePageData } from '~/areas/project-management/pages/pm-v3/routes'
import { Fragment, useMemo, useState } from 'react'

import { FilterList } from './filter-list'
import { additionalChartingTaskFilters, filterOverriddenFields } from './utils'

import {
  useChartSettingField,
  useValidChartFieldsContext,
} from '../../contexts'
import { SidebarSection, SidebarSectionTitle } from '../styled'

// TODO: Unify with `ConnectedFilterButtons` from PM filter header
export const ConnectedFilterSection = () => {
  const ctx = useValidChartFieldsContext()
  const [fullGroupBy] = useChartSettingField('groupBy')

  const dataType = ctx.fields.settings.item
  const [filters] = useEntityFilterState(dataType)

  const [workspaceFilterValue] = useFieldFilter('workspaces', 'ids')

  const allAvailableTaskFilters = useTaskFilterDefinitions({
    includeWorkspaceFilter: true,
  })
  const allAvailableProjectFilters = useProjectFilterDefinitions({
    includeWorkspaceFilter: true,
  })
  const data = usePageData()

  const filterDefinitions = useMemo(() => {
    return {
      tasks: allAvailableTaskFilters
        .concat(additionalChartingTaskFilters)
        .filter(data.predicates.tasks.filters),
      projects: allAvailableProjectFilters.filter(
        data.predicates.projects.filters
      ),
    }
  }, [allAvailableProjectFilters, allAvailableTaskFilters, data.predicates])

  const [currentlyOpen, setOpen] = useState<FilterDefinition | null>(null)

  const definitions: (
    | FilterDefinition<'tasks'>
    | FilterDefinition<'projects'>
  )[] = filterDefinitions[dataType]

  const currentFilters = ['workspaces', ...filters.ordered]
    .filter((key) => (currentlyOpen?.key ?? '') !== key)
    .map((key) => {
      const possibleCustomFieldType = getPrefixFromMaybeCustomFieldKey(key)

      const value =
        key === 'workspaces'
          ? workspaceFilterValue
          : // @ts-expect-error - check is fine
            (filters.filters[possibleCustomFieldType]?.[key] ??
            // @ts-expect-error - check is fine
            filters.filters[key])

      return {
        key,
        value,
      }
    })
    .filter((def) => {
      const include = filterOverriddenFields(def, filters.ordered, fullGroupBy)

      return def.value != null && include
    })
    .map((def) => definitions.find((x) => x.key === def.key))
    .filter(Boolean)

  const available = definitions.filter(
    (d) =>
      !currentFilters.some((x) => x.key === d.key) &&
      filterOverriddenFields(d, filters.ordered, fullGroupBy)
  )

  return (
    <SidebarSection>
      <SidebarSectionTitle>
        <span className='truncate'>Filters</span>
        <AddFilterButton
          definitions={available}
          onSelect={setOpen}
          variant={currentFilters.length === 0 ? 'initial' : 'additional'}
        >
          <Button size='xsmall' sentiment='neutral' variant='muted'>
            <PlusSolid />
            Add filter
          </Button>
        </AddFilterButton>
      </SidebarSectionTitle>
      <FilterList
        applyTo={dataType}
        filters={currentFilters}
        showChildrenFirst={currentlyOpen?.key === 'workspaces'}
      >
        {currentlyOpen && (
          <Fragment key={currentlyOpen.key}>
            {currentlyOpen.renderDropdown({
              openOnMount: true,
              onBlur: () => setOpen(null),
              target: dataType,
            })}
          </Fragment>
        )}
      </FilterList>
    </SidebarSection>
  )
}
