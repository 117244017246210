import { useSharedState } from '@motion/react-core/shared-state'
import { API, type ApiTypes } from '@motion/rpc'
import {
  createQueryFilter,
  MODEL_CACHE_KEY,
  MotionCache,
} from '@motion/rpc-cache'
import { type TeamWithRelationsSerializer } from '@motion/rpc-types'
import { ActiveFilterKey } from '@motion/ui-logic/pm/data'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useAuthenticatedUser, type User } from '@motion/web-common/auth'

import { useQueryClient } from '@tanstack/react-query'
import { AppWorkspaceContext } from '~/global/contexts'
import { useCurrentTeam } from '~/global/rpc/team'
import { useCreateView, useUpdateView } from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { useCallback, useMemo } from 'react'

import { useSelectView } from './use-select-view'
import { useSelectedView } from './use-selected-view'

import { usePageData } from '../../routes'
import { type PageParams } from '../../routes/types'
import { ViewStateKey, type WorkspaceViewState } from '../../view-state'
import { isDefaultView } from '../defaults'
import { type LocalView } from '../types'
import { areViewsEqual, toViewDefinition } from '../utils'

function createViewTarget(
  page: PageParams,
  user: User,
  team: TeamWithRelationsSerializer | null | undefined
): Pick<LocalView, 'targetId' | 'targetType'> {
  if (page.view.type === 'all-tasks') {
    return {
      targetType: team ? 'TEAM' : 'USER',
      targetId: team ? team.id : user.uid,
    }
  }

  if (page.view.type === 'my-tasks') {
    return {
      targetType: 'USER',
      targetId: user.uid,
    }
  }

  if (page.view.type === 'workspace') {
    return {
      targetType: 'WORKSPACE',
      targetId: page.lock.workspaceId!,
    }
  }

  throw new Error('unknown')
}

export const useEffectiveView = () => {
  const [filterState] = useSharedState(ActiveFilterKey)
  const [viewState] = useSharedState(ViewStateKey)
  const route = usePageData()

  const [selectedView] = useSelectedView()

  const team = useCurrentTeam()
  const user = useAuthenticatedUser()

  const effectiveView = useMemo(() => {
    const info: Pick<LocalView, 'targetId' | 'targetType'> = createViewTarget(
      route,
      user,
      team.data
    )

    const viewType =
      route.view.type === 'unknown' ? 'all-tasks' : route.view.type

    const definition = toViewDefinition(
      viewType,
      filterState,
      viewState as WorkspaceViewState
    )
    const view: LocalView = {
      id: isDefaultView(selectedView) ? null : selectedView.id,
      type: viewType,
      name: isDefaultView(selectedView) ? '' : selectedView.name,
      isPrivate: selectedView.isPrivate,
      definition,
      ...info,
    }

    return view
  }, [filterState, route, selectedView, team.data, user, viewState])

  const state = useMemo(() => {
    const modified = !areViewsEqual(
      selectedView.definition,
      effectiveView.definition
    )
    return modified ? 'dirty' : 'clean'
  }, [effectiveView.definition, selectedView.definition])

  const saveInternal = useSaveView()

  const save = useCallback(
    (args: { asNew?: boolean }) => {
      return saveInternal({ view: effectiveView, asNew: args.asNew })
    },
    [effectiveView, saveInternal]
  )

  return {
    state,
    isDefaultView: effectiveView.id == null,
    view: effectiveView,
    save,
  }
}

const VIEW_KEYS_TO_UPDATE = createQueryFilter([MODEL_CACHE_KEY])

export type UseSaveViewArgs = {
  view: LocalView
  asNew?: boolean
}

export const useSaveView = () => {
  const createView = useCreateView()
  const updateView = useUpdateView()
  const select = useSelectView()
  const user = useAuthenticatedUser()
  const client = useQueryClient()
  const [ctx] = useSharedState(AppWorkspaceContext)
  const viewNames = useMemo(() => {
    return ctx.views.all.map((v) => v.name)
  }, [ctx.views.all])

  return useCallback(
    async (args: UseSaveViewArgs) => {
      const viewId = args.view.id
      const viewName = args.view.name

      const currentView = viewId ? ctx.views.byId[viewId] : null

      const viewNameExists = viewNames.includes(viewName)
      if (viewNameExists) {
        if (!currentView || isViewChangingName(currentView, args)) {
          showErrorToast('View already exists with this name')
          return false
        }
      }

      const shouldCreateNew = viewId == null || args.asNew

      recordAnalyticsEvent(
        shouldCreateNew
          ? 'PROJECT_MANAGEMENT_CREATE_SAVED_VIEW'
          : 'PROJECT_MANAGEMENT_UPDATE_SAVED_VIEW',
        {
          isPrivate: Boolean(args.view.isPrivate),
          type: args.view.definition.type,
        }
      )

      const response = await (shouldCreateNew
        ? createView.mutateAsync({
            data: { ...args.view, creatorUserId: user.uid },
          })
        : updateView.mutateAsync({ viewId, data: args.view }))

      const newView = response.models.views[response.id]

      MotionCache.upsert(client, VIEW_KEYS_TO_UPDATE, response)

      // Manually update the root query to reflect the new view until `MotionCache.upsert` can support this
      client.setQueriesData(
        API.views.queryKeys.root,
        (
          oldData: ApiTypes<typeof API.views.getAll>['data'] | null | undefined
        ) => {
          if (!oldData) {
            return {
              meta: response.meta,
              models: {
                views: {
                  [newView.id]: newView,
                },
              },
              ids: [newView.id],
            }
          }

          return {
            ...oldData,
            models: {
              ...oldData.models,
              views: {
                ...oldData.models.views,
                [newView.id]: newView,
              },
            },
            ids: [...oldData.ids, newView.id],
          }
        }
      )

      select(newView)

      return newView
    },
    [
      client,
      createView,
      ctx.views.byId,
      select,
      updateView,
      user.uid,
      viewNames,
    ]
  )
}

function isViewChangingName(view: LocalView, args: UseSaveViewArgs): boolean {
  return view && view.name !== args.view.name
}
