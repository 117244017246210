import { API } from '@motion/rpc'
import {
  getMinimumBucket,
  INDIVIDUAL_ANNUAL_PRICE,
  INDIVIDUAL_MONTHLY_PRICE,
  INDIVIDUAL_PRICES,
  LOW_COST_TRIAL_PRICE,
  TEAM_DEFAULT_BUCKET_SEATS,
  Term,
} from '@motion/ui-logic/billing'
import {
  getSegmentAnalytics,
  getTrackingCookies,
  logEvent,
  recordAnalyticsEvent,
} from '@motion/web-base/analytics'
import { isMobileExperience } from '@motion/web-base/env'
import { errorInDev } from '@motion/web-base/logging'
import { BillingFeatures, BillingPaymentPage } from '@motion/web-billing'
import { useAuthenticatedUser } from '@motion/web-common/auth'
import { useExperiment } from '@motion/web-common/flags'

import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import {
  type StripeElements,
  type StripePaymentElementChangeEvent,
} from '@stripe/stripe-js'
import { useQueryClient } from '@tanstack/react-query'
import { TEAM_DEFAULT_TRIAL_LENGTH } from '~/areas/onboarding/constants'
import { useUncancelSubscriptionAndUpdate } from '~/components/cancel-account-modal/rpc-hooks'
import { useUpdateDefaultPaymentMethod } from '~/global/rpc'
import {
  useCurrentTeam,
  useGetTeamPrices,
  useUpdateBucketSeats,
} from '~/global/rpc/team'
import { createTeam, fetchTeam } from '~/state/projectManagement/teamThunks'
import { switchBillingCycle } from '~/state/teamSlice'
import { useState } from 'react'

import { Events } from '../../analyticsEvents'
import { useTrialLength } from '../../localServices/experiments'
import { createSubscription } from '../../state/corrilySlice'
import { useAppDispatch, useAppSelector } from '../../state/hooks'
import {
  selectDisplayName,
  setStripeSubscription,
  upgradeToAnnualPlan,
} from '../../state/userSlice'
import { trackCreateTeam } from '../onboarding/v2/shared/onboardingAnalytics'
import { useTrackAffiliate } from '../onboarding/v2/shared/rpc-hooks'

const isMobile = isMobileExperience()

interface BillingProps {
  error: string | null
  userEmail: string
  onChange: () => void
  onSubmit: () => void
  onComplete: () => void
  onRerender: (clientSecret: string, error: string) => void
  isTeam: boolean
  onBack?: () => void
  isAddPaymentMethod?: boolean
}

export function Billing({
  error,
  userEmail,
  onChange,
  onSubmit,
  onComplete,
  onRerender,
  isTeam,
  onBack,
  isAddPaymentMethod,
}: BillingProps) {
  const [term, setTerm] = useState<Term>(Term.Annual)
  const isMonthly = term === Term.Monthly
  const isLowCostTrial = term === Term.LowCostTrial
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isPaymentFilledOut, setIsPaymentFilledOut] = useState(false)
  const [errorMessage, setErrorMessage] = useState<null | string>(error)
  const [hasSelectedCard, setHasSelectedCard] = useState(true)
  const dispatch = useAppDispatch()
  const stripe = useStripe()
  const elements = useElements()
  const { data: teamPrices } = useGetTeamPrices()
  const { data: team } = useCurrentTeam()
  const { uid: userId } = useAuthenticatedUser()
  const queryClient = useQueryClient()
  const initialBucketSeats = Math.max(
    getMinimumBucket(team?.teamSubscription?.numSeats),
    TEAM_DEFAULT_BUCKET_SEATS
  )
  const [bucket, setBucket] = useState(initialBucketSeats)

  const userDisplayName = useAppSelector(selectDisplayName)
  const { doUncancelSubscription } = useUncancelSubscriptionAndUpdate()
  const { mutateAsync: updateDefaultPaymentMethodAsync } =
    useUpdateDefaultPaymentMethod({
      onError: (error) => {
        recordAnalyticsEvent('TEAM_BILLING_ADD_CARD_ERROR')
        errorInDev(error)
        setErrorMessage(error.message ?? '')
        return
      },
    })
  const { mutateAsync: updateBucketSeats } = useUpdateBucketSeats()

  const { mutateAsync: trackAffiliateLink } = useTrackAffiliate()

  const maximizeAnnualB2cEligible = !isTeam && !isAddPaymentMethod
  const maximizeAnnualB2cPayload = useExperiment('maximize-annual-b2c', {
    track: maximizeAnnualB2cEligible,
  }).payload
  const lowCostTrialPriceId = maximizeAnnualB2cEligible
    ? maximizeAnnualB2cPayload?.priceId
    : undefined

  const individualTrialLength = useTrialLength()
  const annualTrialLength = isTeam
    ? TEAM_DEFAULT_TRIAL_LENGTH
    : individualTrialLength
  const monthlyTrialLength = isTeam
    ? TEAM_DEFAULT_TRIAL_LENGTH
    : lowCostTrialPriceId
      ? 0
      : individualTrialLength
  const chosenTrialLength =
    term === Term.Annual
      ? annualTrialLength
      : term === Term.Monthly
        ? monthlyTrialLength
        : 0

  const logPurchaseEvent = (
    subscriptionId: string,
    cookieData: Record<string, string | undefined>
  ) => {
    try {
      const eventData = {
        action_source: 'website',
        category: 'checkout',
        currency: 'usd',
        customer_id: userId,
        isTeam,
        label: isMonthly ? 'motion_monthly' : 'motion_annual',
        messageId: `${subscriptionId}-trialStart`,
        subscription_id: subscriptionId,
        trialLength: chosenTrialLength,
        ...(isLowCostTrial && lowCostTrialPriceId && { lowCostTrialPriceId }),
        url: window.location.href,
        value:
          term === Term.LowCostTrial
            ? LOW_COST_TRIAL_PRICE
            : isMonthly
              ? INDIVIDUAL_MONTHLY_PRICE
              : INDIVIDUAL_ANNUAL_PRICE,
        ...cookieData,
      }
      const traitData = {
        traits: {
          email: userEmail,
          firstName: userDisplayName?.split(' ')[0]?.toLowerCase(),
          lastName: userDisplayName?.split(' ')[1]?.toLowerCase(),
        },
      }
      // maps to Segment StartTrial event
      getSegmentAnalytics()?.track('success_purchase', eventData, traitData)
      // maps to Segment Purchase event
      getSegmentAnalytics()?.track('success_purchase_new', eventData, traitData)

      if (isTeam) {
        getSegmentAnalytics()?.track(
          'b2b_trial',
          {
            ...eventData,
            value: bucket,
          },
          traitData
        )
      }
      if (!hasSelectedCard) {
        void logEvent(Events.TRIAL_START_WALLET)
      }
    } catch (e) {
      errorInDev(e)
    }
  }

  const processAddPaymentMethod = async () => {
    if (!stripe || !elements) {
      return
    }
    setIsSubmitting(true)

    const result = await stripe?.confirmSetup({
      elements,
      redirect: 'if_required',
    })

    if (result?.error) {
      setErrorMessage(result?.error?.message || 'Error')
      setIsSubmitting(false)
      return
    }

    // Stripe types seem to think this is impossible
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const setupIntent: { id: string } = result.setupIntent!

    // Case 1: Individual adding a payment method staying individual
    if (!isTeam && !team) {
      await updateDefaultPaymentMethodAsync({ setupIntentId: setupIntent.id })
      if (!isMonthly) {
        await dispatch(upgradeToAnnualPlan())
      }
      recordAnalyticsEvent('INDIVIDUAL_BILLING_ADD_CARD_SUCCESS')
    }
    // Case 2: Individual adding a payment method switching to team
    else if (isTeam && !team) {
      await dispatch(
        createTeam({
          invited: [],
          isMonthly,
          name: 'My team',
          setupIntentId: setupIntent.id,
          seats: bucket,
        })
      )
      recordAnalyticsEvent('INDIVIDUAL_TO_TEAM_BILLING_ADD_CARD_SUCCESS')
    }
    // Case 3: Team adding payment method
    else if (isTeam && team) {
      await updateDefaultPaymentMethodAsync({
        setupIntentId: setupIntent.id,
      })

      if (bucket !== TEAM_DEFAULT_BUCKET_SEATS) {
        await updateBucketSeats({ id: team.id, seats: bucket })
      }

      if (!isMonthly) {
        await dispatch(switchBillingCycle(team.id))
      }

      const fetchedTeam = await dispatch(fetchTeam()).unwrap()
      if (fetchedTeam?.team?.teamSubscription?.subscription) {
        dispatch(
          setStripeSubscription(
            fetchedTeam?.team?.teamSubscription?.subscription
          )
        )
      }
      recordAnalyticsEvent('TEAM_BILLING_ADD_CARD_SUCCESS')
    }
    await doUncancelSubscription()

    void queryClient.invalidateQueries({
      queryKey: API.subscriptions.getIndividualAndTeamSubscription.key(),
    })
    onComplete()
  }

  const processPayment = async () => {
    onSubmit()
    setIsSubmitting(true)
    setErrorMessage(null)

    try {
      // @ts-expect-error stripe may be null. Did not touch it to avoid breaking anything in billing while turning on strict mode.
      const result = await stripe.confirmSetup({
        elements: elements as StripeElements,
        redirect: 'if_required',
      })

      if (result?.error) {
        setErrorMessage(result?.error?.message || 'Error')
        setIsSubmitting(false)
        return
      }
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const setupIntent: { id: string } = result.setupIntent!
      let subscriptionId: string
      if (isTeam) {
        const teamName = 'My team'
        const response = await dispatch(
          createTeam({
            name: teamName,
            setupIntentId: setupIntent.id,
            invited: [],
            isMonthly,
            seats: bucket,
            hasTrial: true,
          })
        ).unwrap()

        if ('error' in response || !response.pmTeamSubscription) {
          const errorMessage =
            'message' in response && typeof response.message === 'string'
              ? response.message
              : 'Unable to create subscription'
          setErrorMessage(errorMessage)
          setIsSubmitting(false)

          if (
            'clientSecret' in response &&
            typeof response.clientSecret === 'string'
          ) {
            onRerender(response.clientSecret, errorMessage)
          }

          return
        }
        trackCreateTeam(teamName, [], userEmail, userDisplayName)
        subscriptionId = response.pmTeamSubscription.id
      } else {
        const response = await dispatch(
          createSubscription({
            // For BE creation, the low cost trials need to be treated as monthly.
            // But for all other purposes here (FE copy, analytics) they should be
            // treated as annual.
            isMonthly: isLowCostTrial ? true : isMonthly,
            setupIntentId: setupIntent.id,
            ...(isLowCostTrial && lowCostTrialPriceId
              ? { lowCostTrialPriceId }
              : { trialLength: chosenTrialLength }),
          })
        ).unwrap()
        if (response.error || !response.subscription) {
          const errorMessage = response.error || 'Unable to create subscription'
          setErrorMessage(errorMessage)
          setIsSubmitting(false)
          if (response.clientSecret) {
            onRerender(response.clientSecret, errorMessage)
          }
          return
        }
        subscriptionId = response.subscription.id
      }

      const cookieData = getTrackingCookies()

      if (cookieData.ps_xid) {
        void trackAffiliateLink({
          xid: cookieData.ps_xid,
          email: userEmail,
          name: userDisplayName ?? userEmail,
        })
      }

      logPurchaseEvent(subscriptionId, cookieData)

      onComplete()
    } catch (e: any) {
      setIsSubmitting(false)
      setErrorMessage(e?.message)
    }
  }

  const handlePaymentElementChange = (
    event: StripePaymentElementChangeEvent
  ) => {
    setHasSelectedCard(event.value.type === 'card')
    setIsPaymentFilledOut(event.complete)
  }

  if (!stripe || !elements || !teamPrices) {
    return null
  }

  const teamProps = isTeam
    ? { bucket, setBucket, isTeam, teamPrices }
    : { isTeam }

  const prices = isTeam ? teamPrices : INDIVIDUAL_PRICES
  const price = isMonthly ? prices.monthlyPrice : prices.annualPricePerMonth

  return (
    <div className='flex flex-col w-full min-h-full lg:flex-row'>
      <div className='flex grow justify-center gap-y-6 py-6 px-3'>
        <BillingPaymentPage
          paymentElement={
            <PaymentElement onChange={handlePaymentElementChange} />
          }
          monthlyTrialLength={monthlyTrialLength}
          annualTrialLength={annualTrialLength}
          chosenTrialLength={chosenTrialLength}
          term={term}
          setTerm={setTerm}
          lowCostTrialPriceId={lowCostTrialPriceId}
          isMobile={isMobile}
          userEmail={userEmail}
          onChangeEmail={onChange}
          hasSelectedCard={hasSelectedCard}
          error={errorMessage}
          isPaymentFilledOut={isPaymentFilledOut}
          isSubmitting={isSubmitting}
          handleSubmit={
            isAddPaymentMethod ? processAddPaymentMethod : processPayment
          }
          onBack={onBack}
          minSeats={team?.teamSubscription?.numSeats ?? 0}
          {...teamProps}
          isAddPaymentMethod={isAddPaymentMethod}
          hideChangeEmail={isAddPaymentMethod}
        />
      </div>
      <BillingFeatures
        term={term}
        allowLowCostTrial={!!lowCostTrialPriceId}
        trialLength={chosenTrialLength}
        hideTimeline={isAddPaymentMethod}
        price={price}
      />
    </div>
  )
}
