import { type CustomFieldSchemaByType } from '@motion/ui-logic'
import { createLookupByKey } from '@motion/utils/object'
import { type DateFilterSchema } from '@motion/zod/client'

import { useCustomFieldFilter } from '~/areas/project-management/filters/context/hooks'
import { DateTime } from 'luxon'
import { useEffect, useMemo } from 'react'

import { type DropdownFilterProps } from '../../../../types'
import { DateFilter } from '../../date-filter'
import { getNarrowedFilter } from '../utils'

type Props = DropdownFilterProps & {
  groupedFields: CustomFieldSchemaByType<'date'>[]
}

export const ConnectedCustomFieldDateFilter = (props: Props) => {
  const { groupedFields } = props
  const firstField = groupedFields[0]
  const [filters, setFilterValue] = useCustomFieldFilter(
    props.target,
    firstField
  )
  const filter = getNarrowedFilter(filters)

  const applyFilter = (schema: DateFilterSchema | null) => {
    if (schema == null) {
      setFilterValue(null)
    } else {
      const record = createLookupByKey(groupedFields, 'id', () => schema)
      setFilterValue(record)
    }
  }

  const value = useMemo<DateFilterSchema>(() => {
    if (filter != null) return filter
    const now = DateTime.now()

    return {
      operator: 'range',
      value: {
        from: now.minus({ week: 1 }).startOf('day').toISO(),
        to: now.endOf('day').toISO(),
      },
    }
  }, [filter])

  useEffect(() => {
    if (filter == null) {
      applyFilter(value)
    }
    // Only run on first mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <DateFilter
      label={firstField.name}
      value={filter}
      onChanged={applyFilter}
      onBlur={props.onBlur?.() ?? (() => undefined)}
    />
  )
}
