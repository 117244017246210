import { CalendarEvent } from '@motion/ui/calendar'
import { colorOptions } from '@motion/ui/palette'

import { type EventContentArg } from '@fullcalendar/core'

type NewCalendarEventProps = {
  eventArg: EventContentArg
}

export function NewCalendarEvent({ eventArg }: NewCalendarEventProps) {
  const { event } = eventArg
  const { start, end } = event

  if (!start) return null

  return (
    <CalendarEvent
      colorId={colorOptions.gray.colorId}
      startTime={start}
      endTime={end ?? start}
      variant='dashed'
      selected
    />
  )
}
