import { type OnboardingType } from '@motion/rpc-types'
import { AlertModal, ConfirmationModal } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useState } from 'react'

type IndividualTrialOptionsModalProps = {
  onClose: () => void
  isOpen: boolean
  onSubmit: (onboardingType: OnboardingType) => void
}

export function IndividualTrialOptionsModal({
  onClose,
  isOpen,
  onSubmit,
}: IndividualTrialOptionsModalProps) {
  const [showSpecialOffer, setShowSpecialOffer] = useState(false)

  if (showSpecialOffer) {
    return (
      <ConfirmationModal
        visible
        onClose={() => {
          recordAnalyticsEvent('COMBINED_B2C_REFUSE_SPECIAL_OFFER')
          setShowSpecialOffer(false)
        }}
        action={{
          label: 'Start Free Without Card',
          onAction: () => {
            recordAnalyticsEvent('COMBINED_B2C_SELECT', {
              card: false,
              type: 'individual',
            })
            onSubmit('individual_no_card')
          },
        }}
        closeLabel='No thanks'
        title='Special Offer: Try Motion without a Credit Card'
        description='Experience Motion free for 7 days—no card required!'
      />
    )
  }

  return (
    <AlertModal
      visible={isOpen}
      onClose={onClose}
      actions={[
        {
          label:
            'Start Individual Trial with Card (no charge until after trial ends)',
          onAction: () => {
            recordAnalyticsEvent('COMBINED_B2C_SELECT', {
              card: true,
              type: 'individual',
            })
            onSubmit('individual')
          },
          variant: 'solid',
          sentiment: 'primary',
        },
        {
          label:
            'Start Team Trial (no card required, but need to invite team of 2+)',
          onAction: () => {
            recordAnalyticsEvent('COMBINED_B2C_SELECT', {
              card: false,
              type: 'team',
            })
            onSubmit('team')
          },
          variant: 'outlined',
          sentiment: 'neutral',
        },
        {
          label: 'I don’t wish to proceed',
          onAction: () => {
            recordAnalyticsEvent('COMBINED_B2C_DISMISS')
            setShowSpecialOffer(true)
            onClose()
          },
          variant: 'outlined',
          sentiment: 'neutral',
        },
      ]}
      title='Start Your Free Trial of Motion Individual (Card Required)'
    >
      <p className='text-sm text-semantic-neutral-text-subtle'>
        A credit or debit card is needed to begin your 7-day free trial. You
        won’t be charged during the trial, and you can cancel anytime with one
        click in settings.
      </p>
    </AlertModal>
  )
}
