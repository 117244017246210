import { ChevronDownSolid } from '@motion/icons'
import {
  Button,
  PopoverTrigger,
  SearchableList,
  showToast,
} from '@motion/ui/base'
import { CalendarTitle } from '@motion/ui/calendar'
import { parseColorId } from '@motion/ui-logic'
import { type CalendarEventSchemaV2 } from '@motion/zod/client'

import { useActiveCalendars } from '~/areas/calendar/hooks'
import { ColorItemBadge } from '~/global/components/badges'
import { selectEmailAccountsMap } from '~/state/email-accounts/email-accounts-slice'
import { useAppSelector } from '~/state/hooks'

import { useEventModalState } from '../contexts'
import {
  useAllEventsMatchingKeyInCalendars,
  useEventForm,
  useNavigateFromEventToEvent,
} from '../hooks'

export const ControlledEventViewAsCalendarField = () => {
  const navigateToEvent = useNavigateFromEventToEvent()

  const { initialEvent } = useEventModalState()
  const { form } = useEventForm()
  const { watch } = form

  const eventId = watch('id')
  const calendarId = watch('calendarId')

  const activeCalendars = useActiveCalendars()
  const allMyEvents = useAllEventsMatchingKeyInCalendars(
    eventId,
    activeCalendars
  )
  const emailAccountsMap = useAppSelector(selectEmailAccountsMap)

  if (allMyEvents.length < 2) return null

  const onChange = async (event: CalendarEventSchemaV2) => {
    if (initialEvent == null) return

    if (form.formState.isDirty) {
      showToast('error', 'Save the event before viewing from another calendar')
      return
    }

    navigateToEvent(initialEvent, event)
  }

  const eventCalendar = activeCalendars.find((cal) => cal.id === calendarId)

  return (
    <div className='flex gap-1 items-center overflow-hidden [&>button]:truncate'>
      <span className='text-semantic-neutral-text-subtle text-sm whitespace-nowrap'>
        View event as:
      </span>
      <PopoverTrigger
        renderPopover={({ close }) => (
          <SearchableList
            searchable={false}
            items={allMyEvents}
            computeKey={(event) => event.id}
            computeSelected={(event) => event.id === eventId}
            onSelect={(event) => {
              onChange(event)
              close()
            }}
            renderItem={(event) => {
              const cal = activeCalendars.find(
                (cal) => cal.id === event.calendarId
              )

              return (
                <div className='flex items-center gap-1'>
                  <ColorItemBadge colorId={parseColorId(cal?.colorId)} />
                  {cal && (
                    <CalendarTitle
                      calendar={cal}
                      emailAccountsMap={emailAccountsMap}
                    />
                  )}
                </div>
              )
            }}
          />
        )}
        placement='bottom-start'
      >
        <Button variant='muted' sentiment='neutral' size='xsmall'>
          <div className='flex gap-1 items-center truncate'>
            <ColorItemBadge colorId={parseColorId(eventCalendar?.colorId)} />
            <div className='truncate'>{eventCalendar?.title ?? 'Unknown'}</div>
            <ChevronDownSolid />
          </div>
        </Button>
      </PopoverTrigger>
    </div>
  )
}
