import { formatNumber } from '@motion/utils/number'
import { type DashboardViewChartSchema } from '@motion/zod/client'

import { Duration } from 'luxon'
import { useCallback } from 'react'

export const shouldConvertToHours = (chart: DashboardViewChartSchema) => {
  if (chart.aggregate.type === 'count') return false
  return chart.aggregate.field === 'duration'
}

export const useValueFormatter = (chart: DashboardViewChartSchema) => {
  return useCallback(
    (value: number) => {
      if (shouldConvertToHours(chart)) {
        return formatMinutes(value)
      }

      return formatNumber(value, { decimals: 1 })
    },
    [chart]
  )
}

/**
 * Formats minutes to a human readable string
 * If less than 1 hour, it shows `Xm`
 * If less than 10 hour, it shows with 2 decimals
 * Otherwise it just shows rounded hours
 * @param value the number of minutes
 * @returns the formatted text
 */
function formatMinutes(value: number) {
  if (value < 60) {
    return Duration.fromObject({ minute: value }).toHuman({
      unitDisplay: 'narrow',
    })
  }

  if (value < 600) {
    return Duration.fromObject({
      hours: value / 60,
    })
      .toHuman({
        unitDisplay: 'narrow',
        listStyle: 'narrow',
      })
      .replaceAll(/,/g, '')
  }

  return Duration.fromObject({
    hours: Math.round(value / 60),
  })
    .toHuman({
      unitDisplay: 'narrow',
      listStyle: 'narrow',
    })
    .replaceAll(/,/g, '')
}
