import { PlusSolid } from '@motion/icons'
import { classed } from '@motion/theme'
import { Button } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useStageDefinitionsWithProjectInfo } from '~/global/hooks'
import { useParams } from 'react-router-dom'

import { FlowStageCard } from './flow-stage-card'
import { NewStageTemplateCard } from './new-workspace-stage-card'

export function StageTable() {
  const { workspaceId = '' } = useParams<{ workspaceId: string }>()
  const stageDefinitions = useStageDefinitionsWithProjectInfo(workspaceId)

  const handleOpenStageModal = () => {
    recordAnalyticsEvent('STAGE_MODAL_OPENED', { new: true })
  }
  return (
    <FlowSettingsTable>
      <SettingsTitle>
        <SettingsTitleText>Workspace Stages</SettingsTitleText>
        <Button onClick={handleOpenStageModal} size='small'>
          <PlusSolid />
          Create new stages
        </Button>
      </SettingsTitle>
      <div className='grid grid-cols-3 rounded gap-3 self-stretch'>
        {stageDefinitions.length === 0 && (
          <NewStageTemplateCard workspaceId={workspaceId} />
        )}
        {stageDefinitions.map((stageDefinition) => (
          <FlowStageCard
            key={stageDefinition.id}
            stageDefinition={stageDefinition}
            workspaceId={workspaceId}
          />
        ))}
      </div>
    </FlowSettingsTable>
  )
}

export const FlowSettingsTable = classed('div', {
  base: 'flex flex-col items-start gap-4 self-stretch',
})

export const SettingsTitle = classed('div', {
  base: 'flex justify-between items-center self-stretch',
})

export const SettingsTitleText = classed('span', {
  base: 'text-semantic-neutral-text-default text-center text-base font-semibold leading-5',
})
