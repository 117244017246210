import { entries } from '@motion/utils/object'
import { useAuthenticatedUser } from '@motion/web-common/auth'

import {
  createNewFullCalendarMotionEvent,
  type FullCalendarMotionCreateNewEvent,
  type FullCalendarMotionNewEvent,
} from '~/areas/calendar/utils'
import { useMemo } from 'react'

import {
  useScheduleAssistantCalendarContext,
  useStateGetScheduleAssistantEvents,
  useTransformEmailsToUserIds,
} from '../../../hooks'
import { scheduleAssistantEventToFullCalendar } from '../../utils'
import { disableEventEditable, parseZeroDuration } from '../utils'

export function useCreateNewEvents() {
  const { uid: currentUserId } = useAuthenticatedUser()
  const [calendarState] = useScheduleAssistantCalendarContext()

  const { selectedCalendarEvent } = calendarState

  const { userIds } = useTransformEmailsToUserIds(
    calendarState.mode === 'create-new'
      ? calendarState.attendees.map((a) => a.email)
      : []
  )

  const { data: userIdToScheduleInfo } = useStateGetScheduleAssistantEvents(
    userIds,
    {
      enabled: calendarState.mode === 'create-new',
    }
  )

  return useMemo(() => {
    const newEvent = selectedCalendarEvent?.new
      ? createNewFullCalendarMotionEvent(selectedCalendarEvent.new)
      : null

    const allEvents: (
      | FullCalendarMotionNewEvent
      | FullCalendarMotionCreateNewEvent
      | null
    )[] = [newEvent]

    if (userIdToScheduleInfo != null && calendarState.mode === 'create-new') {
      const hiddenGuestUserIds = calendarState.hiddenGuestUserIds

      const events = entries(userIdToScheduleInfo).flatMap(([userId, info]) => {
        if (hiddenGuestUserIds.includes(userId)) return []

        return info.events.flatMap((calendarEvent) => {
          const fullCalendarEvent = scheduleAssistantEventToFullCalendar(
            calendarEvent,
            info,
            currentUserId === userId
          )

          if (fullCalendarEvent == null) return []

          return parseZeroDuration(disableEventEditable(fullCalendarEvent))
        })
      })

      allEvents.push(...events)
    }

    return allEvents.filter(Boolean)
  }, [
    calendarState,
    currentUserId,
    userIdToScheduleInfo,
    selectedCalendarEvent?.new,
  ])
}
