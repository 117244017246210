import { defineMutation } from '@motion/rpc'
import {
  type AIHH_CreateCalendarEventRequestSchema,
  type AIHH_CreateCalendarEventResponseSchema,
} from '@motion/rpc-types'

// AIHH - Since there's no codegen for AI app, we need to manually define the mutation

export const createCalendarEventAI = defineMutation<
  AIHH_CreateCalendarEventRequestSchema & {
    calendarId: string
  },
  AIHH_CreateCalendarEventResponseSchema
>().using({
  method: 'POST',
  uri: (args) =>
    `${import.meta.env.MOTION_AI_API_HOST}/v3/calendar-events/${args.calendarId}`,
  body: (args) => args,
})
