import {
  StageSolid,
  StatusCanceledSolid,
  StatusCompletedSolid,
} from '@motion/icons'
import { classed } from '@motion/theme'
import { Tooltip } from '@motion/ui/base'
import { type StatusIcon } from '@motion/ui/pm'
import { ProjectPalette } from '@motion/ui/project'
import { type StageVariant } from '@motion/ui-logic/pm/project'
import { type StageDefinitionSchema } from '@motion/zod/client'

import { type ComponentRef, forwardRef, type MouseEventHandler } from 'react'

import { type BadgeSize, getBadgeSizeInPixels } from './utils'

type StageBadgeVariant = StageVariant | 'stageDueDeadline'
export type StageBadgeProps = {
  value: Pick<StageDefinitionSchema, 'color'> & {
    name?: StageDefinitionSchema['name']
  }
  size?: BadgeSize
  hideTooltip?: boolean
  variant?: StageBadgeVariant
  onContextMenu?: MouseEventHandler<SVGSVGElement>
}

export const StageBadge = forwardRef<
  ComponentRef<typeof StatusIcon>,
  StageBadgeProps
>(function StageBadge(
  { value, size, hideTooltip = false, variant = 'default', onContextMenu },
  ref
) {
  const pixelSize = getBadgeSizeInPixels(size)

  const Icon = getStageIcon(variant)

  const badge = (
    <ProjectPalette color={value.color}>
      <Icon
        ref={ref}
        width={pixelSize}
        height={pixelSize}
        onContextMenu={onContextMenu}
      />
    </ProjectPalette>
  )

  if (hideTooltip || !value.name) return badge

  return <Tooltip content={`Stage: ${value.name}`}>{badge}</Tooltip>
})

function getStageIcon(variant: StageBadgeVariant) {
  if (variant === 'completed') {
    return StyledStageCompletedSolid
  }

  if (variant === 'stageDueDeadline') {
    return StyledFlippedStageSolid
  }

  return variant === 'skipped' ? StyledStatusCanceledSolid : StyledStageSolid
}

const StyledStageSolid = classed(StageSolid, {
  // using !important because these can be used within buttons
  base: `
    !shrink-0
    !text-palette-highlight-default
  `,
})

const StyledFlippedStageSolid = classed(StageSolid, StyledStageSolid, {
  base: `
    rotate-180
  `,
})

const StyledStageCompletedSolid = classed(
  StatusCompletedSolid,
  StyledStageSolid,
  {
    base: `
    [&>circle]:!fill-current
  `,
  }
)

const StyledStatusCanceledSolid = classed(StatusCanceledSolid, StyledStageSolid)
