import { type TaskDefinitionFormFields } from '@motion/ui-logic/pm/project'
import {
  getTaskHiddenAndDisabledFields,
  type TaskFormFields,
} from '@motion/ui-logic/pm/task'
import { useAuthenticatedUser } from '@motion/web-common/auth'

import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { type UseFormReturn } from 'react-hook-form'

import { useTaskDefinitionModalState } from './use-task-definition-modal-state'

export function useTaskDefinitionForm() {
  const form = useFormContext<TaskDefinitionFormFields>()
  const { initialTaskData } = useTaskDefinitionModalState()
  const { uid: currentUserId } = useAuthenticatedUser()

  return useMemo(
    () => ({
      form,
      ...getTaskHiddenAndDisabledFields(
        form as unknown as UseFormReturn<TaskFormFields>,
        {
          initialTask: initialTaskData.task,
          currentUserId,
        }
      ),
    }),
    [currentUserId, form, initialTaskData.task]
  )
}
