import { XSolid } from '@motion/icons'
import { useSharedState } from '@motion/react-core/shared-state'
import { Button, IconButton, UnstyledModal } from '@motion/ui/base'
import { ActiveFilterKey } from '@motion/ui-logic/pm/data'
import {
  type DashboardViewCellSchema,
  type DashboardViewChartSchema,
} from '@motion/zod/client'

import { type ModalTriggerComponentProps } from '~/areas/modals'
import { toViewDefinitionFilters } from '~/areas/project-management/pages/pm-v3/views'
import { ErrorBoundary } from '~/global/components'
import { useMemo } from 'react'

import {
  ChartContainer,
  ChartTitleField,
  ConnectedChartSaveButton,
  ConnectedFilterSection,
  GridShell,
  ModalShell,
  ShellActions,
  ShellVars,
  SidebarContent,
  SidebarFooter,
  SidebarSection,
  SidebarSectionTitle,
  SidebarShell,
} from './components'
import { ChartSettingsSection } from './components/settings/chart-settings-section'
import { ChartFieldProvider, useValidChartFieldsContext } from './contexts'
import {
  createBaseChart,
  createNewCell,
  mergeChartFieldsIntoCell,
} from './utils'

import { chartComponentLookup } from '../../grid-layout/utils/lookups'

type CreateModal = {
  mode: 'create'
  type: DashboardViewChartSchema['type']
}
type EditModal = {
  mode: 'edit'
  cell: DashboardViewCellSchema
}

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'chart-modal': CreateModal | EditModal
  }
}

export function ChartModal(props: ModalTriggerComponentProps<'chart-modal'>) {
  return (
    <UnstyledModal
      data-testid='chart-modal'
      type='page'
      visible
      onClose={() => props.close()}
      withAnimation
      overlayClassName='bg-modal-overlay'
    >
      <InnerChartModal {...props} />
    </UnstyledModal>
  )
}

function InnerChartModal({
  close,
  ...rest
}: ModalTriggerComponentProps<'chart-modal'>) {
  const cell =
    rest.mode === 'edit' ? rest.cell : createNewCell(createBaseChart(rest.type))

  const Chart = chartComponentLookup(cell.chart.type)
  if (Chart == null) return null

  return (
    <ShellVars>
      <ModalShell>
        <ShellActions>
          <IconButton
            icon={XSolid}
            sentiment='onDark'
            size='small'
            variant='muted'
            onClick={() => close()}
          />
        </ShellActions>
        <GridShell>
          <ChartFieldProvider chartCell={cell}>
            <ChartContainer>
              <ErrorBoundary>
                <UpdatedChart chartComponent={Chart} chartCell={cell} />
              </ErrorBoundary>
            </ChartContainer>
            <SidebarShell>
              <SidebarContent>
                <SidebarSection>
                  <SidebarSectionTitle>Info</SidebarSectionTitle>
                  <ChartTitleField />
                </SidebarSection>

                <ChartSettingsSection cell={cell} />

                <ConnectedFilterSection />
              </SidebarContent>
            </SidebarShell>
            <SidebarFooter>
              <Button sentiment='neutral' variant='outlined' onClick={close}>
                Cancel
              </Button>
              <ConnectedChartSaveButton
                onClick={close}
                mode={rest.mode}
                chartCell={cell}
              />
            </SidebarFooter>
          </ChartFieldProvider>
        </GridShell>
      </ModalShell>
    </ShellVars>
  )
}

type UpdatedChartProps = {
  chartComponent: any
  chartCell: DashboardViewCellSchema
}

function UpdatedChart({ chartComponent: Chart, chartCell }: UpdatedChartProps) {
  const ctx = useValidChartFieldsContext()
  const [filters] = useSharedState(ActiveFilterKey)

  const updatedChartCell = useMemo(
    () =>
      mergeChartFieldsIntoCell(
        ctx.fields,
        chartCell,
        toViewDefinitionFilters(filters)
      ),
    [ctx.fields, chartCell, filters]
  )

  return <Chart chart={updatedChartCell.chart} title={updatedChartCell.title} />
}
