import { PopoverTrigger, SearchableList } from '@motion/ui/base'
import { keys } from '@motion/utils/object'
import { type DashboardViewBarChartSchema } from '@motion/zod/client'

import { ModalFieldButton } from '~/areas/task-project/components'
import { SubItemIndentMarker } from '~/areas/tasks/modals/task-modal/components'

import { useBarChartSettingField } from '../../contexts/chart-fields-context'
import { ListItem } from '../list-item'

export function ChartStackBarsField() {
  const [layout, setLayout] = useBarChartSettingField('layout')

  return (
    <div className='flex flex-row items-center gap-0.5'>
      <SubItemIndentMarker />

      <PopoverTrigger
        placement='bottom-start'
        renderPopover={({ close }) => (
          <SearchableList
            searchable={false}
            items={keys(layoutMap)}
            computeKey={(item) => item}
            computeSelected={(item) => item === layout}
            onSelect={(item) => {
              setLayout(item)
              close()
            }}
            renderItem={(item) => <ListItem name={getLayoutLabel(item)} />}
          />
        )}
      >
        <ModalFieldButton label='Stack bars'>
          {getLayoutLabel(layout)}
        </ModalFieldButton>
      </PopoverTrigger>
    </div>
  )
}

const layoutMap: Record<
  Exclude<DashboardViewBarChartSchema['layout'], 'single'>,
  string
> = {
  grouped: 'Grouped',
  stacked: 'Stacked',
}

function getLayoutLabel(layout: DashboardViewBarChartSchema['layout']): string {
  return layoutMap[layout as keyof typeof layoutMap] ?? layout
}
