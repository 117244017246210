import { type ProviderType } from '@motion/shared/common'
import { sleep } from '@motion/utils/promise'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { firebase } from '@motion/web-common/firebase'

const getFirebaseToken = async (fresh?: boolean) =>
  firebase.auth().currentUser?.getIdToken(fresh)

function getTokenWithRetry(fresh?: boolean) {
  return getFirebaseToken(fresh)
    .catch(async () => {
      await sleep(200)
      return getFirebaseToken(true)
    })
    .catch((ex) => {
      if (
        ex.code === 'auth/network-request-failed' ||
        ex.message === 'Failed to get the auth token'
      ) {
        // Don't log this as an error
        recordAnalyticsEvent('TOKEN_NETWORK_REQUEST_FAILED')
        return undefined
      }

      throw new Error('Failed to get the auth token', { cause: ex })
    })
}

let inflight: Promise<string | undefined> | null = null

export const getCurrentUserToken = (fresh?: boolean) => {
  if (inflight) return inflight
  return (inflight = getTokenWithRetry(fresh).finally(() => (inflight = null)))
}

/*
 * Open a new window with the add account page
 */
export const addAccount = (provider?: ProviderType) => {
  let url = `${__FRONTEND_HOST__}/add-account`
  if (provider) {
    url += `/${provider}`
  }

  window.open(url, '_blank', 'height=800,width=700')
}
