import {
  type DashboardViewCellSchema,
  type ViewDefinitionFiltersSchema,
} from '@motion/zod/client'

import { type ChartFields } from '../types'

export function mergeChartFieldsIntoCell(
  fields: ChartFields,
  cell: DashboardViewCellSchema,
  filters: ViewDefinitionFiltersSchema
): DashboardViewCellSchema {
  return {
    ...cell,
    title: fields.title.trim(),
    chart: {
      ...cell.chart,
      ...fields.settings,
      filters,
    } as DashboardViewCellSchema['chart'],
  }
}
