import { ThumbDownSolid, ThumbUpSolid } from '@motion/icons'
import { ButtonGroup, IconButton } from '@motion/ui/base'
import { type MeetingInsightsSchema } from '@motion/zod/client'

import { useAiAnalyticsCalls } from '~/areas/ai-hackerhouse/hooks/use-ai-analytics-calls'
import { useState } from 'react'

type UserRatingProps = {
  label: string
  section: 'summary' | 'notes' | 'actionItems'
  meetingInsightsId: MeetingInsightsSchema['id']
  onClick?: (result: 'up' | 'down') => void
}

export function UserRating({
  label,
  section,
  meetingInsightsId,
  onClick,
}: UserRatingProps) {
  const { recordUserContentFeedback } = useAiAnalyticsCalls()
  const [thumbClicked, setThumbClicked] = useState<'up' | 'down' | null>(null)

  const onClickUp = () => {
    recordUserContentFeedback(meetingInsightsId, section, 'positive')
    setThumbClicked('up')
    onClick?.('up')
  }

  const onClickDown = () => {
    recordUserContentFeedback(meetingInsightsId, section, 'negative')
    setThumbClicked('down')
    onClick?.('down')
  }

  return (
    <div className='flex items-center gap-4 text-2xs text-semantic-neutral-text-subtle'>
      <span>{label}</span>

      <ButtonGroup size='small'>
        <IconButton
          icon={ThumbUpSolid}
          size='small'
          variant='outlined'
          sentiment={thumbClicked === 'up' ? 'primary' : 'neutral'}
          onClick={onClickUp}
          pressed={thumbClicked === 'up'}
        />
        <IconButton
          icon={ThumbDownSolid}
          size='small'
          variant='outlined'
          sentiment={thumbClicked === 'down' ? 'primary' : 'neutral'}
          onClick={onClickDown}
          pressed={thumbClicked === 'down'}
        />
      </ButtonGroup>
    </div>
  )
}
