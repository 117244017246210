import { merge } from '@motion/utils/core'

import {
  defaultSidebarState,
  type SidebarState,
  useSidebarState,
} from '~/global/hooks/sidebars'
import { useCallback, useMemo } from 'react'

export type GlobalSidebarState = SidebarState & {
  sections: {
    favorites: boolean
    workspaces: boolean
  }
}

type SectionKeys = keyof GlobalSidebarState['sections']

const defaultGlobalSidebarState: GlobalSidebarState = {
  ...defaultSidebarState,
  sections: {
    favorites: true,
    workspaces: true,
  },
}

export const useGlobalSidebarState = () => {
  const [localStorageState, setSidebarState] =
    useSidebarState<GlobalSidebarState>('global')

  const sidebarState = useMemo(
    () =>
      merge(
        {},
        defaultGlobalSidebarState,
        localStorageState
      ) as GlobalSidebarState,
    [localStorageState]
  )

  const toggleSidebarSection = useCallback(
    (section: SectionKeys, newOpenState?: boolean) => {
      setSidebarState((currentState) => ({
        sections: {
          ...currentState.sections,
          [section]: newOpenState ?? !currentState.sections[section] ?? true,
        },
      }))
    },
    [setSidebarState]
  )

  return {
    sidebarState,
    setSidebarState,
    toggleSidebarSection,
  }
}
