import { useHasTreatment } from '@motion/web-common/flags'

import { type FilterDefinition } from '~/areas/project-management/filters'
import {
  ConnectedETAFilter,
  ConnectedWorkspaceFilter,
} from '~/areas/project-management/filters/components/v2'
import { useMemo } from 'react'

import { useCustomFieldFilterDefinitions } from './custom-fields/use-custom-field-filter-definitions'
import { defaultProjectFilters, defaultTaskFilters } from './definitions'

type FilterDefinitionsProps = {
  includeWorkspaceFilter?: boolean
}

export const useTaskFilterDefinitions = ({
  includeWorkspaceFilter = false,
}: FilterDefinitionsProps = {}): FilterDefinition<'tasks'>[] => {
  const customFieldFilters = useCustomFieldFilterDefinitions<'tasks'>()
  const hasPastDueM2 = useHasTreatment('past-due-m2')

  return useMemo(() => {
    const filters = [...defaultTaskFilters, ...customFieldFilters]

    if (includeWorkspaceFilter) {
      filters.push({
        key: 'workspaces',
        label: 'Workspaces',
        renderDropdown: (props) => <ConnectedWorkspaceFilter {...props} />,
      })
    }

    if (hasPastDueM2) {
      filters.push({
        key: 'deadlineStatuses',
        label: 'ETA',
        renderDropdown: (props) => <ConnectedETAFilter {...props} />,
      })
    }

    return filters
  }, [customFieldFilters, hasPastDueM2, includeWorkspaceFilter])
}

export const useProjectFilterDefinitions = ({
  includeWorkspaceFilter = false,
}: FilterDefinitionsProps = {}): FilterDefinition<'projects'>[] => {
  const customFieldFilters = useCustomFieldFilterDefinitions<'projects'>()
  const hasPastDueM2 = useHasTreatment('past-due-m2')

  return useMemo(() => {
    const filters = [...defaultProjectFilters, ...customFieldFilters]

    if (includeWorkspaceFilter) {
      filters.push({
        key: 'workspaces',
        label: 'Workspaces',
        renderDropdown: (props) => <ConnectedWorkspaceFilter {...props} />,
      })
    }

    if (hasPastDueM2) {
      filters.push({
        key: 'deadlineStatuses',
        label: 'ETA',
        renderDropdown: (props) => <ConnectedETAFilter {...props} />,
      })
    }

    return filters
  }, [customFieldFilters, hasPastDueM2, includeWorkspaceFilter])
}
