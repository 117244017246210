import { API } from '@motion/rpc'
import { isNoneId } from '@motion/shared/identifiers'
import { showToast } from '@motion/ui/base'
import { isRecurringTaskParent } from '@motion/ui-logic'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'
import { useAuthenticatedUser } from '@motion/web-common/auth'
import {
  type ChunkTaskSchema,
  type NormalTaskSchema,
  type RecurringInstanceSchema,
  type RecurringTaskSchema,
  type TaskSchema,
  type TasksV2ChunkUpdateSchema,
  type TasksV2NormalUpdateSchema,
  type TasksV2RecurringInstanceUpdateSchema,
  type TasksV2RecurringTaskUpdateSchema,
} from '@motion/zod/client'

import { useQueryClient } from '@tanstack/react-query'
import { useCustomFieldsFns } from '~/areas/custom-fields/hooks'
import { useAutosaveContext } from '~/areas/task-project/contexts'
import { useRouteAnalyticsMetadata } from '~/global/analytics'
import { useLookup } from '~/global/cache'
import { useWorkspaceFns } from '~/global/hooks'
import { createTaskProxy } from '~/global/proxies'
import {
  useReadTaskFn,
  useUpdateTask as useUpdateTaskCall,
} from '~/global/rpc/v2'
import { DateTime } from 'luxon'
import { useCallback } from 'react'

import { useAddTaskStateSetter } from '../../states'
import {
  getAmplitudeTaskChangedFields,
  handleTaskUpdateErrors,
} from '../../utils'

export interface TaskUpdateFns {
  (
    task: NormalTaskSchema,
    updates: Omit<TasksV2NormalUpdateSchema, 'type'>
  ): Promise<NormalTaskSchema>
  (
    task: ChunkTaskSchema,
    updates: Omit<TasksV2ChunkUpdateSchema, 'type'>
  ): Promise<ChunkTaskSchema>
  (
    task: RecurringInstanceSchema,
    updates: Omit<TasksV2RecurringInstanceUpdateSchema, 'type'>
  ): Promise<RecurringInstanceSchema>
  (
    task: RecurringTaskSchema,
    updates: Omit<TasksV2RecurringTaskUpdateSchema, 'type'>
  ): Promise<RecurringTaskSchema>
  (
    task: TaskSchema,
    updates:
      | Omit<TasksV2NormalUpdateSchema, 'type'>
      | Omit<TasksV2ChunkUpdateSchema, 'type'>
      | Omit<TasksV2RecurringInstanceUpdateSchema, 'type'>
  ): Promise<TaskSchema>
  (
    task: TaskSchema | RecurringTaskSchema,
    updates:
      | Omit<TasksV2NormalUpdateSchema, 'type'>
      | Omit<TasksV2ChunkUpdateSchema, 'type'>
      | Omit<TasksV2RecurringInstanceUpdateSchema, 'type'>
      | Omit<TasksV2RecurringTaskUpdateSchema, 'type'>
  ): Promise<TaskSchema | RecurringTaskSchema>
}

interface ExtendedUpdateFns extends TaskUpdateFns {
  (
    taskId: TaskSchema['id'],
    updates:
      | Omit<TasksV2NormalUpdateSchema, 'type'>
      | Omit<TasksV2ChunkUpdateSchema, 'type'>
      | Omit<TasksV2RecurringInstanceUpdateSchema, 'type'>
      | Omit<TasksV2RecurringTaskUpdateSchema, 'type'>
  ): Promise<TaskSchema | RecurringTaskSchema>
}

export function useTaskUpdater(): ExtendedUpdateFns {
  const readTask = useReadTaskFn()
  const realTaskUpdater = useRealTaskUpdater()
  const fakeTaskUpdater = useFakeTaskUpdater()

  return useCallback<ExtendedUpdateFns>(
    // @ts-expect-error-next-line - externally typed
    async (taskOrId, updates) => {
      const task =
        typeof taskOrId === 'string' ? await readTask(taskOrId) : taskOrId

      if (task == null) {
        throw new Error('Task cannot be found')
      }

      if (isNoneId(task.id)) {
        return fakeTaskUpdater(task, updates)
      }
      return realTaskUpdater(task, updates)
    },
    [fakeTaskUpdater, readTask, realTaskUpdater]
  )
}

function useFakeTaskUpdater(): TaskUpdateFns {
  const setAddTaskState = useAddTaskStateSetter()
  const lookup = useLookup()
  const { getWorkspaceMembers } = useWorkspaceFns()

  return useCallback<TaskUpdateFns>(
    // @ts-expect-error-next-line - externally typed
    async (task, updates) => {
      // When updating a none id task, it means it's still considered a "temporary" task, so let's update our internal state
      if (isNoneId(task.id)) {
        if (task.type !== 'NORMAL') return

        const members = getWorkspaceMembers(task.workspaceId)

        recordAnalyticsEvent('PROJECT_MANAGEMENT_UPDATE_TEMPORARY_TASK', {
          autoScheduled:
            'isAutoScheduled' in updates
              ? (updates.isAutoScheduled ?? task.isAutoScheduled)
              : task.isAutoScheduled,
          taskDuration: (updates.duration || task.duration) ?? null,
          workspaceSize: members.length,
          ...getAmplitudeTaskChangedFields(task, updates),
        })

        return setAddTaskState((prev) => {
          // A task should already exist in the state if we hit this here
          if (prev.task == null) return prev

          const mergedCustomFields =
            'customFieldValues' in updates
              ? {
                  ...task.customFieldValues,
                  ...(updates.customFieldValues ?? {}),
                }
              : task.customFieldValues

          return {
            ...prev,
            task: createTaskProxy(
              {
                ...task,
                ...updates,
                customFieldValues: mergedCustomFields,
              },
              lookup
            ),
          }
        })
      }
    },
    [getWorkspaceMembers, lookup, setAddTaskState]
  )
}

function useRealTaskUpdater(): TaskUpdateFns {
  const queryClient = useQueryClient()
  const context = useRouteAnalyticsMetadata()

  const { uid: currentUserId } = useAuthenticatedUser()
  const { shouldSupressSavingToast, setLastSavedTime } = useAutosaveContext()
  const { mutateAsync: updateTaskCall } = useUpdateTaskCall()
  const { getWorkspaceMembers } = useWorkspaceFns()
  const { getCustomFields } = useCustomFieldsFns()

  return useCallback<TaskUpdateFns>(
    // @ts-expect-error-next-line - externally typed
    async (task, updates) => {
      try {
        const isRecurring = isRecurringTaskParent(task)
        const members = getWorkspaceMembers(task.workspaceId)

        recordAnalyticsEvent('PROJECT_MANAGEMENT_UPDATE_TASK', {
          type: isRecurring ? 'recurring' : undefined,
          autoScheduled: isRecurring
            ? true
            : 'isAutoScheduled' in updates
              ? (updates.isAutoScheduled ?? task.isAutoScheduled)
              : task.isAutoScheduled,
          workspaceSize: members.length,
          taskDuration: (updates.duration || task.duration) ?? null,
          ...context,
          ...getAmplitudeTaskChangedFields(task, updates),
        })

        const response = await updateTaskCall({
          id: task.id,
          // @ts-expect-error-next-line - externally typed
          data: {
            type: task.type,
            ...updates,
          },
        })

        const savedTask = response.models[response.meta.model][response.id]

        setLastSavedTime(DateTime.now())
        if (!shouldSupressSavingToast) {
          showToast('success', 'Task saved')
        }

        // When auto-scheduling is turned off, we need to invalidate the scheduled entities cache
        // There's already a websocket event `workspace.tasksScheduled` that fires when a task is scheduled
        // but **NOT** when its unscheduled (i.e. when turning auto-scheduling off) and the scheduled entities
        // **ONLY** get refreshed if the workspace passed in the event is a personal workspace.
        // This means that in some cases we might get lucky and if the user has a shceduled task in their personal workspace
        // that gets its schedule updated, the scheduled entities cache will get invalidated, but we need to invalidate
        // the cache here manually for those other cases when that does not happen.
        if (
          task.assigneeUserId === currentUserId &&
          task.isAutoScheduled !== savedTask.isAutoScheduled &&
          !savedTask.isAutoScheduled
        ) {
          void queryClient.invalidateQueries(
            API.scheduledEntities.queryKeys.root
          )
        }

        return savedTask
      } catch (e) {
        Sentry.captureException(e, {
          tags: {
            position: 'useTaskUpdater',
          },
        })

        if (e instanceof Error) {
          handleTaskUpdateErrors(e, getCustomFields(task.workspaceId))
        }

        throw e
      }
    },
    [
      context,
      currentUserId,
      getCustomFields,
      getWorkspaceMembers,
      setLastSavedTime,
      shouldSupressSavingToast,
      updateTaskCall,
      queryClient,
    ]
  )
}
