import { classed } from '@motion/theme'

export const ModalShell = classed('section', {
  base: `
    bg-modal-bg
    rounded-lg
    shadow-lg
    text-semantic-neutral-text-default

    h-[687px]
    max-h-[100vh]
    modal-md:h-[769px]
    modal-lg:h-[819px]
    modal-xl:h-[80vh] modal-xl:max-h-[1415px]
  `,
})

export const GridShell = classed('div', {
  base: `
    h-full
    grid
    grid-rows-[auto_minmax(0,1fr)]
    grid-cols-2
    [grid-template-areas:'header_header''chart_details']
    `,
})

export const GridHeader = classed('header', {
  base: `[grid-area:header] flex p-3 pl-4 justify-between items-center self-stretch border-b border-modal-border`,
})

export const HeaderTitle = classed('div', {
  base: `text-semantic-neutral-text-default text-center font-semibold text-base flex items-center gap-1 truncate`,
})

export const GridChart = classed('div', {
  base: `[grid-area:chart] [&>*]:h-full p-12`,
})

export const GridDetailsAside = classed('div', {
  base: `[grid-area:details] h-full border-l border-modal-border`,
})
