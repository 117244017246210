import { type DashboardViewCellSchema } from '@motion/rpc-types'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useCallback } from 'react'

import { useDashboardViewState } from '../../../view-state'

export const useRemoveChartCell = () => {
  const [, setViewState] = useDashboardViewState()

  return useCallback(
    (cellId: DashboardViewCellSchema['id']) => {
      setViewState((prevView) => {
        const foundCell = prevView.view.cells.find((c) => c.id === cellId)
        if (foundCell) {
          recordAnalyticsEvent('DASHBOARD_CHART_REMOVE', {
            chartType: foundCell.chart.type,
          })
        }

        return {
          ...prevView,
          view: {
            ...prevView.view,
            cells: prevView.view.cells.filter((c) => c.id !== cellId),
          },
        }
      })
    },
    [setViewState]
  )
}
