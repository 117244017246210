import { type EventConferenceType } from '@motion/shared/common'
import { Sentry } from '@motion/web-base/sentry'

import { useCallback } from 'react'
import { type UseFormReturn } from 'react-hook-form'

import { useEventForm } from './use-event-form'
import { useGenerateConferencingData } from './use-generate-conferencing-data'

import { type EventAiFormFields } from '../../../types'

export function useFormHandleConferenceChange() {
  const { form } = useEventForm()
  const updateConference = useUpdateFormConferenceData()

  return useCallback(
    async (value: EventConferenceType) => {
      updateConference(form, value)
    },
    [form, updateConference]
  )
}

export function useUpdateFormConferenceData() {
  const generateConferenceData = useGenerateConferencingData()

  return useCallback(
    async (
      form: UseFormReturn<EventAiFormFields, any, undefined>,
      value: EventConferenceType
    ) => {
      try {
        const { description, location } = await generateConferenceData(
          value,
          form.getValues('description'),
          {
            endTime: form.getValues('end'),
            recurringRule: form.getValues('recurrence') ?? undefined,
            startTime: form.getValues('start'),
            topic: form.getValues('title'),
          }
        )

        form.setValue('conferenceType', value, { shouldDirty: true })
        form.setValue('description', description, { shouldDirty: true })
        form.setValue('location', location, { shouldDirty: true })

        return {
          conferenceType: value,
          description,
          location,
        }
      } catch (e) {
        Sentry.captureException(e)

        form.setValue('conferenceType', 'none', { shouldDirty: true })
      }
    },
    [generateConferenceData]
  )
}
