import { getEventTitleFromSchedulingTaskTitle } from '@motion/shared/meetings'
import { getInitialEventFormData } from '@motion/ui-logic'
import {
  type AIHH_CalendarEventSchema,
  type CalendarEventSchemaV2,
  type MeetingInsightsSchema,
  type NormalTaskSchema,
} from '@motion/zod/client'

import { useMainCalendarId } from '~/areas/calendar/hooks'
import { useCustomFieldsByWorkspaceId } from '~/areas/custom-fields/hooks'
import { useConferenceSettingsApi } from '~/areas/settings/state'
import { selectMainEmailAccount } from '~/state/email-accounts/email-accounts-slice'
import { useAppSelector } from '~/state/hooks'
import { useMemo } from 'react'

import { type EventAiFormFields } from '../../../types'
import { NoMainCalendarError } from '../errors'

export type InitialValues = Partial<EventAiFormFields>

export type InitialFormOptions = {
  isLoading?: boolean
  event: AIHH_CalendarEventSchema | null | undefined
  eventRecurringParent: CalendarEventSchemaV2 | null | undefined
  meetingTask: NormalTaskSchema | null | undefined
  schedulingTask: NormalTaskSchema | null | undefined
  meetingInsights: MeetingInsightsSchema | null | undefined
}

export function useInitialFormData(
  defaultValues: InitialValues = {},
  options: InitialFormOptions
): EventAiFormFields {
  const {
    isLoading,
    event,
    eventRecurringParent,
    meetingTask,
    schedulingTask,
    meetingInsights,
  } = options

  const finalTask = meetingTask ?? schedulingTask

  const workspaceCustomFields = useCustomFieldsByWorkspaceId(
    finalTask?.workspaceId
  )

  const { mainCalendarId, isLoading: isLoadingCalendarId } = useMainCalendarId()
  const mainEmailAccount = useAppSelector(selectMainEmailAccount)
  const conferenceSettings =
    useConferenceSettingsApi().selectConferenceSettings()

  if (!isLoadingCalendarId && mainCalendarId == null) {
    throw new NoMainCalendarError()
  }

  return useMemo(() => {
    const isCreating = event == null
    const meetingInsightHasBot =
      meetingInsights != null &&
      meetingInsights.meetingBotStatus !== 'DONT_SCHEDULE'

    return {
      ...getInitialEventFormData({
        mainCalendarId,
        mainEmailAccount,
        eventRecurringParent,
        settingConferenceType: conferenceSettings.conferenceType,
        workspaceCustomFields,
        defaultValues: {
          title: schedulingTask
            ? getEventTitleFromSchedulingTaskTitle(schedulingTask.name)
            : undefined,
          ...defaultValues,
        },
        options: {
          task: finalTask,
          event,
          isLoading: isLoading || isLoadingCalendarId,
        },
      }),
      isMeetingBotEnabled: isCreating || meetingInsightHasBot,
      sendRecapToAllAttendees:
        meetingInsights?.sendRecapToAllAttendees ?? false,
      assignments: meetingInsights?.assignments ?? [],
    }
  }, [
    meetingInsights,
    mainCalendarId,
    mainEmailAccount,
    eventRecurringParent,
    conferenceSettings.conferenceType,
    workspaceCustomFields,
    schedulingTask,
    defaultValues,
    finalTask,
    event,
    isLoading,
    isLoadingCalendarId,
  ])
}
