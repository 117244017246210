import { PopoverTrigger, SearchableList } from '@motion/ui/base'

import { ModalFieldButton } from '~/areas/task-project/components'

import { useChartSettingField } from '../../contexts/chart-fields-context'
import { ListItem } from '../list-item'

const VALUES = ['false' as const, 'true' as const]

export function ChartPieStyleField() {
  const [layout, setLayout] = useChartSettingField('donut')

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <SearchableList
          searchable={false}
          items={VALUES}
          computeKey={(item) => item}
          computeSelected={(item) => item === String(layout)}
          onSelect={(item) => {
            setLayout(item === 'true')
            close()
          }}
          renderItem={(item) => <ListItem name={getLabel(item)} />}
        />
      )}
    >
      <ModalFieldButton label='Style'>{getLabel(layout)}</ModalFieldButton>
    </PopoverTrigger>
  )
}

const layoutMap: Record<string, string> = {
  true: 'Donut',
  false: 'Filled',
}

function getLabel(donut: boolean | 'true' | 'false'): string {
  return layoutMap[String(donut)]
}
