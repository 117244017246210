import {
  API,
  type ApiTypes,
  createUseMutation,
  useQueryOptionsFactory,
} from '@motion/rpc'
import {
  createQueryFilter,
  MODEL_CACHE_KEY,
  MotionCache,
  type OptimisticUpdateValue,
  useStoreModelsFn,
} from '@motion/rpc-cache'
import { createLookupById, stripUndefined } from '@motion/utils/object'
import {
  type CalendarSchema,
  type GetAllCalendarsSchema,
} from '@motion/zod/client'

import { useQuery, useQueryClient } from '@tanstack/react-query'

export type GetCalendarsApi = ApiTypes<typeof API.calendars.getCalendars>

export const useGetCalendars = (opts?: GetCalendarsApi['UseQueryOptions']) => {
  const storeModels = useStoreModelsFn()

  const queryOptionsOf = useQueryOptionsFactory(API.calendars.getCalendars)
  const queryArgs = queryOptionsOf(void 0, opts as any)

  return useQuery({
    notifyOnChangeProps: ['error', 'data', 'dataUpdatedAt'],
    ...queryArgs,
    async queryFn(ctx) {
      const value = await queryArgs.queryFn(ctx as any)

      storeModels({
        calendars: createLookupById(value.calendars),
      })
      return value
    },
  })
}

export const useUpdatePrimaryCalendar = createUseMutation(
  API.calendars.updatePrimary
)

const useUpdateCalendarsMutation = createUseMutation(
  API.calendars.updateMultiple
)
export const useUpdateCalendars = () => {
  const queryClient = useQueryClient()

  return useUpdateCalendarsMutation({
    onSuccess: (data) => {
      // Update model cache
      MotionCache.upsert(queryClient, createQueryFilter([MODEL_CACHE_KEY]), {
        models: {
          calendars: createLookupById(data.calendars),
        },
      })

      // Update `getCalendars` query key
      queryClient.setQueryData(
        API.calendars.queryKeys.root,
        (previousValue: GetAllCalendarsSchema | undefined) => {
          if (!previousValue) return previousValue

          return {
            ...previousValue,
            calendars: Object.values({
              ...createLookupById(previousValue.calendars),
              ...createLookupById(data.calendars),
            }),
          }
        }
      )
    },
    onMutate: async (data) => {
      await queryClient.cancelQueries(API.calendars.queryKeys.root)

      const calendarSchemas: Partial<CalendarSchema>[] = data.calendars.map(
        (cal) => stripUndefined(cal)
      )

      const calendarsById = createLookupById(calendarSchemas)

      const cacheUpdates = MotionCache.patch(
        queryClient,
        createQueryFilter([MODEL_CACHE_KEY]),
        'calendars',
        calendarsById
      )

      const previousCalendars = queryClient.getQueryData(
        API.calendars.queryKeys.root
      )

      queryClient.setQueryData(
        API.calendars.queryKeys.root,
        (previousValue: GetAllCalendarsSchema | undefined) => {
          if (!previousValue) return previousValue

          return {
            ...previousValue,
            calendars: previousValue.calendars.map((cal) => {
              if (calendarsById[cal.id]) {
                return { ...cal, ...calendarsById[cal.id] }
              }
              return cal
            }),
          }
        }
      )

      return { cacheUpdates, previousCalendars }
    },
    onError: (err, _, context) => {
      const { cacheUpdates, previousCalendars } = context as {
        cacheUpdates: OptimisticUpdateValue | undefined
        previousCalendars: GetAllCalendarsSchema | undefined
      }
      cacheUpdates?.rollback()
      queryClient.setQueryData(API.calendars.queryKeys.root, previousCalendars)
    },
  })
}
