import { createNoAccessWorkspace } from '@motion/rpc-cache'
import { Button, PopoverTrigger } from '@motion/ui/base'
import { byProperty, Compare } from '@motion/utils/array'
import { type WorkspaceSchema } from '@motion/zod/client'

import { WorkspaceBadge } from '~/global/components/badges'
import { useAppWorkspaceContext } from '~/global/contexts'

import { ConnectedWorkspaceDropdown } from './connected-workspace-filter'

import { useFieldFilter } from '../../../context'

export const ConnectedWorkspaceFilterButton = () => {
  const [filter] = useFieldFilter('workspaces', 'ids')

  const [shellContext] = useAppWorkspaceContext({ scope: 'shell' })

  const all = shellContext.workspaces.all.sort(
    byProperty('name', Compare.string)
  )

  const ids = filter?.value ?? null
  const text = getLabelText(ids, all)

  const sentiment = text === 'All' ? 'neutral' : 'primary'

  return (
    <PopoverTrigger
      renderPopover={({ close }) => (
        <ConnectedWorkspaceDropdown close={close} />
      )}
    >
      <Button sentiment={sentiment} variant='outlined' size='small'>
        <WorkspaceBadge />
        Workspace: {text}
      </Button>
    </PopoverTrigger>
  )
}

function getLabelText(ids: string[] | null, all: WorkspaceSchema[]) {
  if (ids == null || ids.length === 0 || ids.length === all.length) return 'All'

  if (ids.length > 1) {
    return `${ids.length} workspaces`
  }

  const id = ids[0]
  const workspace = all.find((w) => w.id === id)

  return workspace?.name ?? createNoAccessWorkspace('').name
}
