import { TaskSolid } from '@motion/icons'
import { createPendingStatus } from '@motion/rpc-cache'
import { SYSTEM_FLOW_VARIABLE_OBJECTS } from '@motion/shared/flows'
import { classed } from '@motion/theme'
import { ActionList, Button, PopoverTrigger } from '@motion/ui/base'
import {
  type StageDefinitionSchema,
  type VariableDefinitionSchema,
} from '@motion/zod/client'

import { useFlowTemplateModalUrl } from '~/areas/flows'
import { MotionLink } from '~/global/components'
import { StatusBadge } from '~/global/components/badges'
import { StageLabel } from '~/global/components/labels'
import {
  type ProjectDefinitionWithProjectInfo,
  useWorkspaceStatuses,
} from '~/global/hooks'
import { useMemo } from 'react'
import { useNavigate } from 'react-router'

import { TaskDefinitionName } from './task-definition-name'

type TemplateStagesProps = {
  flowTemplate: ProjectDefinitionWithProjectInfo
}

export function TemplateStages({ flowTemplate }: TemplateStagesProps) {
  const { stages, variables } = flowTemplate

  const allVariables = useMemo(() => {
    return variables.concat(SYSTEM_FLOW_VARIABLE_OBJECTS)
  }, [variables])

  if (!stages.length) {
    return <FlowStagesContainer />
  }

  return (
    <FlowStagesContainer>
      {stages.map((stage) => (
        <StageRow key={stage.id}>
          {/* TODO: add link to stage modal once built */}
          <MotionLink url=''>
            <StageLabel size='small' value={stage} />
          </MotionLink>
          <TaskListDropdown
            stage={stage}
            variables={allVariables}
            templateId={flowTemplate.id}
          />
        </StageRow>
      ))}
    </FlowStagesContainer>
  )
}

function TaskListDropdown({
  stage,
  variables,
  templateId,
}: {
  stage: StageDefinitionSchema
  variables: VariableDefinitionSchema[]
  templateId: string
}) {
  const { tasks, workspaceId } = stage
  const statuses = useWorkspaceStatuses(workspaceId)

  const navigate = useNavigate()
  const buildFlowTemplateModalUrl = useFlowTemplateModalUrl()
  const goToFlowTemplateModal = () =>
    navigate(
      buildFlowTemplateModalUrl({
        template: 'edit',
        templateId: templateId,
      })
    )

  return (
    <PopoverTrigger
      placement='bottom-end'
      renderPopover={() => (
        <ActionList
          items={tasks.map((task) => ({
            prefix: (
              <StatusBadge
                value={
                  statuses.find((status) => status.id === task.statusId) ??
                  createPendingStatus('Placeholder')
                }
                size='small'
                taskVariant={
                  task.scheduleMeetingWithinDays != null ? 'meeting' : 'default'
                }
              />
            ),
            content: <TaskDefinitionName task={task} variables={variables} />,
            onAction: goToFlowTemplateModal,
          }))}
        />
      )}
    >
      <Button sentiment='neutral' variant='muted' size='xsmall'>
        <TaskSolid />
        {tasks.length}
      </Button>
    </PopoverTrigger>
  )
}

const StageRow = classed('div', {
  base: `
    flex items-start self-stretch 
    justify-between cursor-pointer
  `,
})

const FlowStagesContainer = classed('div', {
  base: `
    rounded-b border border-t-0 border-semantic-neutral-border-light
    bg-semantic-neutral-surface-raised-bg-subtlest flex flex-col items-start self-stretch
    h-[100px] py-2 px-3 gap-1 overflow-hidden
  `,
})
