import { isCanceledStatus } from '@motion/shared/common'
import { useContextMenu } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { AttachmentCountButton } from '~/areas/attachments/components'
import { useGetProjectAttachmentsCount } from '~/areas/attachments/hooks'
import { ConnectedProjectEtaBadge } from '~/areas/eta'
import { ProjectColorTag } from '~/areas/project/components'
import { ProjectActionList } from '~/areas/project/components/project-action-list'
import { useProjectModalUrl } from '~/global/navigation'
import { type ProjectWithRelations } from '~/global/proxies'
import { useNavigate } from 'react-router'

import { Card, CardInfoRow, CardTitle } from './components'
import {
  CustomFieldsSection,
  ProjectAssigneeField,
  ProjectDeadlineField,
  ProjectLabelsField,
  ProjectPriorityField,
  ProjectStatusField,
} from './fields'

export type ProjectCardProps = {
  project: ProjectWithRelations
  dragging?: boolean
}
export const ProjectCard = ({ project, dragging }: ProjectCardProps) => {
  const buildProjectModalUrl = useProjectModalUrl()
  const navigate = useNavigate()
  const { handleContextMenu, ContextMenuPopover } = useContextMenu({
    onOpen: () => {
      recordAnalyticsEvent('PROJECT_MANAGEMENT_KANBAN_RIGHT_CLICK', {
        type: 'project',
      })
    },
  })
  const { projectAttachmentCount } = useGetProjectAttachmentsCount({
    projectId: project.id,
  })

  return (
    <>
      <Card
        dragging={dragging}
        onClick={() => navigate(buildProjectModalUrl({ project: project.id }))}
        draggable='false'
        onContextMenu={handleContextMenu}
      >
        <CardInfoRow className='gap-1'>
          <ProjectColorTag
            projectId={project.id}
            color={project.color}
            size='small'
            square
          />
          <ProjectPriorityField project={project} />
          <ProjectStatusField project={project} />
          <div className='ml-auto -mt-2'>
            <ConnectedProjectEtaBadge project={project} source='kanban' />
          </div>
        </CardInfoRow>
        <CardTitle canceled={isCanceledStatus(project.status)}>
          {project.name}
        </CardTitle>
        <CardInfoRow spaceBetween>
          <ProjectDeadlineField project={project} />
          <ProjectAssigneeField project={project} />
        </CardInfoRow>
        <CardInfoRow spaceBetween>
          <ProjectLabelsField project={project} />
          {project.type === 'NORMAL' && (
            <AttachmentCountButton
              targetId={project.id}
              targetType='PROJECT'
              count={projectAttachmentCount}
            />
          )}
        </CardInfoRow>

        <CustomFieldsSection project={project} />
      </Card>
      <ContextMenuPopover
        renderContent={({ close }) => (
          <ProjectActionList close={close} project={project} />
        )}
      />
    </>
  )
}
