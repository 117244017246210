import { classed } from '@motion/theme'

import { type ExpandedStateList, type Row } from '@tanstack/react-table'
import {
  type Range,
  type VirtualItem,
  type Virtualizer,
} from '@tanstack/react-virtual'
import React from 'react'

import { useSidebarTaskContext } from './context'
import { SidebarTasksGroupHeader } from './header'
import { InlineTaskCreationForm } from './inline-create'
import { InlineAddTaskButton } from './project-panel/components/inline-add-tasks-button'
import { TaskPanelTaskLine } from './task-line'
import {
  INLINE_ADD_TASK_BUTTON_HEIGHT,
  INLINE_ADD_TASK_HEIGHT,
  isTaskRow,
  shouldShowAddTaskButton,
} from './utils'

import { type SidebarTasksGroup } from '../../hooks'
import { type SortBy } from '../../utils'

type VirtualSidebarTaskItemProps<SK extends keyof typeof SortBy> = {
  row: Row<SidebarTasksGroup>
  rowsToUse: Row<SidebarTasksGroup>[]
  virtual: VirtualItem
  virtualItems: VirtualItem[]
  rowVirtualizer: Virtualizer<HTMLTableElement, Element>
  range: Range
  sort: SK
  initialStartDate: { startDate: string } | undefined
  addTaskExpanded: Record<string, boolean>
  setAddTaskExpanded: React.Dispatch<React.SetStateAction<ExpandedStateList>>
  rowHeight: number
}
export function VirtualSidebarTaskItem<SK extends keyof typeof SortBy>({
  virtual,
  rowVirtualizer,
  virtualItems,
  range,
  sort,
  initialStartDate,
  addTaskExpanded,
  setAddTaskExpanded,
  row,
  rowHeight,
  rowsToUse,
}: VirtualSidebarTaskItemProps<SK>) {
  const visible =
    !isTaskRow(row) || virtual.index >= range.startIndex - range.overscan

  const { enableInlineAdd, projectId, workspaceId } = useSidebarTaskContext()

  if (!isTaskRow(row)) {
    return (
      <React.Fragment key={row.id}>
        {virtual.index !== 0 && <div className='h-4' />}

        <SidebarTasksGroupHeader
          key={row.id}
          index={virtual.index}
          measureElement={rowVirtualizer.measureElement}
          sort={sort}
          groupId={row.id}
          row={row}
          rowVirtualizer={rowVirtualizer}
          isAddTaskExpanded={addTaskExpanded[row.id]}
          toggleAddTaskExpanded={() =>
            setAddTaskExpanded((expanded) => ({
              ...expanded,
              [row.id]: !expanded[row.id],
            }))
          }
        />
      </React.Fragment>
    )
  }

  const { showAddTaskButton, parentRow } = shouldShowAddTaskButton({
    index: virtual.index,
    virtualItems,
    rowsToUse,
    row,
    sort,
    enableInlineAdd,
  })

  const showInlineAddTaskForm =
    !!parentRow && addTaskExpanded[parentRow.id] && showAddTaskButton

  let height = rowHeight
  if (showAddTaskButton) {
    height += INLINE_ADD_TASK_BUTTON_HEIGHT
  }
  if (showInlineAddTaskForm) {
    height += INLINE_ADD_TASK_HEIGHT
  }

  return (
    <RowShell
      key={row.id}
      ref={rowVirtualizer.measureElement}
      data-index={virtual.index}
      style={{
        height,
      }}
    >
      {visible && (
        <TaskPanelTaskLine
          className={sort === 'STAGES' ? undefined : 'pl-4'}
          task={row.original}
        />
      )}
      {showInlineAddTaskForm && visible && (
        <InlineTaskCreationForm
          close={() =>
            setAddTaskExpanded((expanded) => ({
              ...expanded,
              [parentRow.id]: false,
            }))
          }
          workspaceId={workspaceId}
          projectId={projectId}
          initialData={{
            ...initialStartDate,
            stageDefinitionId: parentRow.id,
          }}
        />
      )}
      {showAddTaskButton && visible && parentRow && (
        <InlineAddTaskButton
          onClick={() => {
            setAddTaskExpanded((expanded) => ({
              ...expanded,
              [parentRow.id]: !expanded[parentRow.id],
            }))
            if (!addTaskExpanded[parentRow.id]) {
              rowVirtualizer.scrollToIndex(virtual.index, {
                align: 'end',
              })
            }
          }}
        />
      )}
    </RowShell>
  )
}

export const RowShell = classed(
  'div',
  'flex truncate flex-col transition-height'
)
