import { QuestionMarkCircleSolid } from '@motion/icons'
import { Toggle, Tooltip } from '@motion/ui/base'

import { useUpdateMeetingInsights } from '~/areas/ai-hackerhouse/hooks/rpc/use-update-meeting-insights'
import { selectEmailAccounts } from '~/state/email-accounts/email-accounts-slice'
import { useAppSelector } from '~/state/hooks'
import { useController } from 'react-hook-form'

import { ConnectedPermissionField } from './permissioning-field'

import { ConnectedSendBotNowButton } from '../components'
import { useEventModalState } from '../contexts'
import { useEventForm } from '../hooks'
import { isAiBotSupportedConference } from '../utils'

export function EventMeetingBotSection() {
  const {
    isLoading,
    isReadOnly,
    isMeetingInsightsCompleted,
    hostEmailAccount,
    meetingInsights,
  } = useEventModalState()
  const { form } = useEventForm()
  const { control } = form

  const conferenceType = form.watch('conferenceType')
  const allDay = form.watch('isAllDay')
  const attendees = form.watch('attendees')
  const emailAccounts = useAppSelector(selectEmailAccounts)

  const { mutateAsync: updateMeetingInsights } = useUpdateMeetingInsights()

  const { field: enabledField } = useController({
    name: 'isMeetingBotEnabled',
    control,
  })
  const { field: sendRecapField } = useController({
    name: 'sendRecapToAllAttendees',
    control,
  })

  // Meeting bot is "enabled" as long as the meeting bot status is not "DONT_SCHEDULE"
  const isMeetingBotEnabled =
    meetingInsights != null
      ? meetingInsights?.meetingBotStatus !== 'DONT_SCHEDULE'
      : enabledField.value
  const sendRecap =
    meetingInsights != null
      ? meetingInsights.sendRecapToAllAttendees
      : sendRecapField.value

  // Show toggle if you're the organizer or an attendee
  const userAccountEmailSet = new Set(
    emailAccounts.map((account) => account.email)
  )
  const emailsOnTheMeetingSet = new Set(
    [
      ...attendees.map((attendee) => attendee.email),
      hostEmailAccount?.email,
    ].filter(Boolean)
  )
  const userIsOnTheMeeting =
    userAccountEmailSet.intersection(emailsOnTheMeetingSet).size > 0

  // Not available for all day events
  // To be moved to `getEventHiddenAndDisabledFields`
  if (
    !userIsOnTheMeeting ||
    isLoading ||
    !isAiBotSupportedConference(conferenceType) ||
    allDay
  ) {
    return null
  }

  function handleMeetingBotChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (meetingInsights != null) {
      updateMeetingInsights({
        meetingInsightsId: meetingInsights.id,
        isMeetingBotEnabled: event.target.checked,
      })

      return
    }

    enabledField.onChange(event)
  }

  function handleSendRecapChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (meetingInsights != null) {
      updateMeetingInsights({
        meetingInsightsId: meetingInsights.id,
        sendRecapToAllAttendees: event.target.checked,
      })
      return
    }

    sendRecapField.onChange(event)
  }

  // We can defintely break this component down into smaller components...next time :)
  return (
    <>
      <div className='flex flex-col gap-2'>
        <div className='flex items-center justify-between h-5'>
          <strong className='text-2xs font-semibold text-semantic-neutral-text-default flex items-center gap-1'>
            AI Notetaker
            <Tooltip
              content='Our
                  meeting bot will take notes for you during the meeting. Once meeting ends, you can view the notes & recording here.'
            >
              <QuestionMarkCircleSolid
                tabIndex={0}
                className='cursor-pointer size-3 text-semantic-neutral-icon-subtle'
              />
            </Tooltip>
          </strong>

          <ConnectedSendBotNowButton />
        </div>

        {!isMeetingInsightsCompleted && (
          <>
            <Tooltip
              placement='top-start'
              renderContent={() =>
                isReadOnly
                  ? 'You do not have permission to change this'
                  : undefined
              }
            >
              <Toggle
                size='small'
                checked={isMeetingBotEnabled}
                onChange={handleMeetingBotChange}
                disabled={isReadOnly}
              >
                <span className='text-xs text-semantic-neutral-text-default'>
                  Add to event when it starts
                </span>
              </Toggle>
            </Tooltip>

            {enabledField.value === true && (
              <Tooltip
                placement='top-start'
                content='When toggled off, the meeting recap email will only be sent to meeting attendees with an active Motion account'
              >
                <Toggle
                  size='small'
                  checked={sendRecap}
                  onChange={handleSendRecapChange}
                  disabled={isReadOnly}
                >
                  <p className='text-semantic-neutral-text-default text-xs'>
                    Send meeting recap email to{' '}
                    <strong className='font-medium'>all</strong> meeting
                    attendees
                  </p>
                </Toggle>
              </Tooltip>
            )}
          </>
        )}

        <ConnectedPermissionField />
      </div>

      <div className='my-4 border-b border-semantic-neutral-border-subtle' />
    </>
  )
}
