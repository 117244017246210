import { Button } from '@motion/ui/base'
import { RichTextField } from '@motion/ui/forms'
import { RichTextContent, RichTextImageBubbleMenu } from '@motion/ui/rte'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useRef } from 'react'

import { useCommentBoxRte, useOnReplyAction } from '../../hooks'
import { type CommentSubmitPayload } from '../../types'

type SlimCommentBoxProps = {
  onSubmit: (comment: CommentSubmitPayload) => void
  onChange?: (value: string) => void
}

export const SlimCommentBox = ({ onSubmit, onChange }: SlimCommentBoxProps) => {
  const richTextFieldRef = useRef<HTMLSpanElement>(null)

  const { editor, handleSubmit } = useCommentBoxRte({
    commentBoxRef: richTextFieldRef,
    onSubmit,
    onChange,
    isSlim: true,
  })

  useOnReplyAction((payload) => {
    recordAnalyticsEvent('ON_REPLY_ACTION')

    editor
      ?.chain()
      .focus()

      .command(({ tr, commands }) => {
        const lastPos = tr.doc.content.size

        // Set cursor to end of doc
        commands.setTextSelection(lastPos)

        // Check if there's text at the end, if so add a small space
        const lastNode = tr.doc.lastChild?.lastChild
        if (lastNode?.isText && !lastNode.text?.endsWith(' ')) {
          commands.insertContent(' ')
        }

        commands.insertContent({
          type: 'mention',
          attrs: {
            id: payload.userId,
            label: payload.label,
          },
        })

        return commands.insertContent(' ')
      })
      .run()
  })

  return (
    <>
      <RichTextImageBubbleMenu editor={editor} />

      <RichTextField
        ref={richTextFieldRef}
        editor={editor}
        suffix={({ active }) => (
          <Button
            onClick={() => editor && handleSubmit(editor)}
            shortcut='mod+enter'
            size='small'
            disabled={editor?.isEmpty}
            variant={active ? 'solid' : 'muted'}
          >
            <span className={active ? 'inline' : 'hidden'}>Send</span>
          </Button>
        )}
      >
        <RichTextContent editor={editor} />
      </RichTextField>
    </>
  )
}
