import { type DeadlineInterval } from '@motion/shared/flows'
import { PopoverButton, SearchableDropdown } from '@motion/ui/base'
import { type TaskDefinitionFormRelativeIntervalDuration } from '@motion/ui-logic/pm/project'

import { useMemo } from 'react'

import { getValidDurationValues } from './utils'

export type DurationValueDropdownProps = {
  duration: TaskDefinitionFormRelativeIntervalDuration
  onChange: (value: number) => void
  stageDeadlineInterval: DeadlineInterval
}

export function DurationValueDropdown({
  duration,
  onChange,
  stageDeadlineInterval,
}: DurationValueDropdownProps) {
  const items = useMemo(
    () => getValidDurationValues(stageDeadlineInterval, duration),
    [stageDeadlineInterval, duration]
  )

  const { value } = duration

  return (
    <SearchableDropdown
      searchable
      items={items}
      selectedItem={value}
      onChange={onChange}
      renderItem={(item) => String(item)}
    >
      <PopoverButton muted>{String(value)}</PopoverButton>
    </SearchableDropdown>
  )
}
