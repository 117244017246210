import { useEventHandler } from '@motion/react-core/hooks'
import {
  type InternalStateKey,
  type StateKey,
  useSharedStateContext,
} from '@motion/react-core/shared-state'

import { type DataStore } from '../types'

type SharedStatePersisterProps = {
  prefix?: string
  store: DataStore<string>
  keyFilter?: (key: StateKey<any>) => boolean
}

export function SharedStatePersister(props: SharedStatePersisterProps) {
  const ctx = useSharedStateContext()
  const { prefix, store, keyFilter } = props

  useEventHandler(
    ctx.event,
    async (e) => {
      if (keyFilter && !keyFilter(e.key)) return

      const storageKey = prefix ? `${prefix}:${e.key.name}` : e.key.name

      const stateKey = e.key as InternalStateKey
      if (stateKey.serialize == null) return
      const text = stateKey.serialize(e.value)

      void store.set(storageKey, text)

      // This is just so its easier to see the value while debugging

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - __IS_DEV__ is defined at the root
      if (typeof __IS_DEV__ !== 'undefined' && __IS_DEV__) {
        // @ts-expect-error - debugging only
        void store.set(`${storageKey}:value`, e.value)
      }
      return
    },
    [ctx.event]
  )

  return null
}
