import { type Row } from '@tanstack/react-table'
import { type VirtualItem } from '@tanstack/react-virtual'
import { type NormalTaskWithRelations } from '~/global/proxies'

import { type SidebarTasksGroup } from '../../hooks'
import { type SortBy } from '../../utils'

export const HEADER_GROUP_HEIGHT = 36
export const SPACER_HEIGHT = 16

export const TASK_LINE_HEIGHT = 30
export const INLINE_ADD_TASK_HEIGHT = 142

export const INLINE_ADD_TASK_BUTTON_HEIGHT = 24

type ShouldShowAddTaskButtonArgs = {
  index: number
  virtualItems: VirtualItem[]
  rowsToUse: Row<SidebarTasksGroup>[]
  row: Row<SidebarTasksGroup>
  sort: keyof typeof SortBy
  enableInlineAdd: boolean
}
export function shouldShowAddTaskButton({
  index,
  virtualItems,
  rowsToUse,
  row,
  sort,
  enableInlineAdd,
}: ShouldShowAddTaskButtonArgs): {
  showAddTaskButton: boolean
  parentRow: Row<any> | undefined
} {
  if (sort !== 'STAGES' || !enableInlineAdd) {
    return {
      showAddTaskButton: false,
      parentRow: undefined,
    }
  }

  const nextVirtual = virtualItems[index + 1]
  const nextRow = nextVirtual ? rowsToUse[nextVirtual.index] : null
  const parentRow = row.getParentRow()

  return {
    showAddTaskButton: Boolean(
      parentRow &&
        ((nextRow && !isTaskRow(nextRow)) || index === virtualItems.length - 1)
    ),
    parentRow,
  }
}

export const isTaskRow = (row: Row<any>): row is Row<NormalTaskWithRelations> =>
  row.getParentRow() != null
