import { FilledChevronRightSolid } from '@motion/icons'
import { classed } from '@motion/theme'

export const RotatingChevronIcon = classed(FilledChevronRightSolid, {
  base: `
    transition-transform
  `,
  variants: {
    expanded: {
      true: 'rotate-90',
      false: '',
    },
  },
})
