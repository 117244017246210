import {
  type DashboardViewGroupBySchema,
  type DashboardViewSortSchema,
} from '@motion/rpc-types'
import { PopoverTrigger, SearchableList } from '@motion/ui/base'
import { type EntityFilter } from '@motion/ui-logic/pm/data'
import { keys } from '@motion/utils/object'

import { useEntityFilterState } from '~/areas/project-management/filters'
import { ModalFieldButton } from '~/areas/task-project/components'
import { SubItemIndentMarker } from '~/areas/tasks/modals/task-modal/components'
import { useCallback } from 'react'

import { RangeField } from './chart-range-field'
import { ChartSortByField } from './chart-sortby-field'

import {
  useChartGroupBy,
  useChartSettingField,
  useChartSettings,
} from '../../contexts'
import { useGroupByFields } from '../../hooks/use-group-by-fields'
import {
  getGroupByDurationLabel,
  groupByDurationMap,
  isDateBasedGroupBy,
} from '../../utils'
import { ListItem } from '../list-item'

export type ChartFirstGroupByFieldProps = {
  label: string
  byLabel: string
}

export function ChartFirstGroupByField({
  label,
  byLabel,
}: ChartFirstGroupByFieldProps) {
  const settings = useChartSettings()
  const [dataType] = useChartSettingField('item')
  const [groupBy, setGroupBy] = useChartGroupBy()
  const [entityFilters, setEntityFilters] = useEntityFilterState(dataType)

  const { sections, getName } = useGroupByFields(dataType, {
    includeDateFields: true,
  })

  const isDateField = isDateBasedGroupBy(groupBy)

  const showSortBy =
    !isDateField && (settings.type === 'bar' || settings.type === 'line')

  const onSelectHandler = useCallback(
    (item: string) => {
      const by = isDateField ? groupBy.by : 'day'

      const isNewItemDateField = isDateBasedGroupBy({ field: item })

      setGroupBy({
        field: item,
        ...(isNewItemDateField && {
          by,
          range: isNewItemDateField
            ? (groupBy?.range ?? {
                operator: 'defined-relative',
                name: 'this-week',
              })
            : undefined,
          sort: null,
        }),
        ...(showSortBy && getInitialSort(groupBy?.sort, { field: item })),
      })

      // Unset date filters if date is selected as group by
      if (isNewItemDateField && isItemInFilters(item, entityFilters.filters)) {
        setEntityFilters(item as any, null)
      }
    },
    [
      isDateField,
      groupBy?.by,
      groupBy?.range,
      groupBy?.sort,
      setGroupBy,
      showSortBy,
      setEntityFilters,
      entityFilters,
    ]
  )

  return (
    <>
      <PopoverTrigger
        placement='bottom-start'
        renderPopover={({ close }) => (
          <SearchableList
            itemType='sectioned'
            sections={sections}
            computeKey={(item) => item}
            computeSelected={(item) => item === groupBy?.field}
            computeSearchValue={(item) => getName(item)}
            onSelect={(item) => {
              onSelectHandler(item)
              close()
            }}
            renderItem={(item) => <ListItem name={getName(item)} />}
          />
        )}
      >
        <ModalFieldButton label={label}>
          {getName(groupBy?.field)}
        </ModalFieldButton>
      </PopoverTrigger>

      {isDateBasedGroupBy(groupBy) && (
        <div className='flex flex-row items-center gap-0.5'>
          <SubItemIndentMarker />

          <PopoverTrigger
            placement='bottom-start'
            renderPopover={({ close }) => (
              <SearchableList
                items={keys(groupByDurationMap)}
                computeKey={(item) => item}
                computeSelected={(item) => item === groupBy.by}
                computeSearchValue={(item) => getGroupByDurationLabel(item)}
                onSelect={(item) => {
                  setGroupBy({
                    ...groupBy,
                    by: item,
                  })
                  close()
                }}
                renderItem={(item) => (
                  <ListItem name={getGroupByDurationLabel(item)} />
                )}
              />
            )}
          >
            <ModalFieldButton label={byLabel}>
              {getGroupByDurationLabel(groupBy.by)}
            </ModalFieldButton>
          </PopoverTrigger>
        </div>
      )}

      {isDateField && (
        <RangeField
          value={
            groupBy.range ?? { operator: 'defined-relative', name: 'this-week' }
          }
          setValue={(range) => {
            setGroupBy({
              ...groupBy,
              range,
            })
          }}
        />
      )}
      {showSortBy && <ChartSortByField category='groupBy' />}
    </>
  )
}

function getInitialSort(
  currentSort: DashboardViewSortSchema | undefined | null,
  newGroupByField: Pick<DashboardViewGroupBySchema, 'field'>
): Pick<DashboardViewGroupBySchema, 'sort'> {
  if (isDateBasedGroupBy(newGroupByField)) {
    return { sort: { direction: 'asc', source: 'name' } }
  }

  if (currentSort) {
    return { sort: currentSort }
  }

  return { sort: { direction: 'asc', source: 'value' } }
}

// Add mapping here of date fields to filters if later needed
function isItemInFilters(item: string, filters: EntityFilter) {
  return Object.keys(filters).includes(item)
}
