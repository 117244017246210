import { PlusSolid } from '@motion/icons'
import { classed } from '@motion/theme'
import { Button, ButtonGroup } from '@motion/ui/base'
import { getPrefixFromMaybeCustomFieldKey } from '@motion/ui-logic'

import { Fragment, type ReactNode, useState } from 'react'

import { AddFilterButton } from './add-filter-button'

import { useEntityFilterState } from '../../context'
import { type FilterDefinition } from '../types'

type FilterBarProps<T extends 'tasks' | 'projects'> = {
  title: string
  definitions: FilterDefinition[]
  applyTo: T
  children?: ReactNode
}

export const ConnectedFilterButtons = <T extends 'tasks' | 'projects'>(
  props: FilterBarProps<T>
) => {
  const [filters] = useEntityFilterState(props.applyTo)
  const [currentlyOpen, setOpen] = useState<FilterDefinition | null>(null)

  function onSelect(def: FilterDefinition) {
    setOpen(def)
  }

  const currentFilters = filters.ordered
    .filter((key) => (currentlyOpen?.key ?? '') !== key)
    .map((key) => {
      const possibleCustomFieldType = getPrefixFromMaybeCustomFieldKey(key)

      const value =
        // @ts-expect-error - check is fine
        filters.filters[possibleCustomFieldType]?.[key] ?? filters.filters[key]

      return {
        key,
        value,
      }
    })
    .filter((def) => def.value != null)
    .map((def) => props.definitions.find((x) => x.key === def.key))
    .filter(Boolean)

  const available = props.definitions.filter(
    (d) => !currentFilters.some((x) => x.key === d.key)
  )

  return (
    <div className='grid [grid-template-columns:50px_1fr] items-center gap-2'>
      <span className='text-2xs text-semantic-neutral-text-subtle'>
        {props.title}:
      </span>
      <ButtonGroup>
        {currentFilters.map((def) => (
          <Fragment key={def.key}>
            {def.renderDropdown({ target: props.applyTo })}
          </Fragment>
        ))}

        {currentlyOpen ? (
          <Fragment key={currentlyOpen.key}>
            {currentlyOpen.renderDropdown({
              openOnMount: true,
              onBlur: () => setOpen(null),
              target: props.applyTo,
            })}
          </Fragment>
        ) : (
          <AddFilterButton
            definitions={available}
            onSelect={onSelect}
            variant={currentFilters.length === 0 ? 'initial' : 'additional'}
          >
            <Button sentiment='neutral' size='small' variant='muted' iconOnly>
              <PlusSolid />
            </Button>
          </AddFilterButton>
        )}
      </ButtonGroup>
    </div>
  )
}

export const FilterPanel = classed('div', {
  base: `
    pt-3
    grid
    auto-rows-[32px]
    gap-3
    border-t
    border-semantic-neutral-border-subtle
  `,
})
