import { useDependantState } from '@motion/react-core/hooks'
import { MAX_FOLDER_DEPTH } from '@motion/shared/common'
import { clamp } from '@motion/utils/math'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'

import { type DroppableContainer } from '@dnd-kit/core'
import { useMoveProjectInFolder } from '~/areas/folders/hooks'
import { useUpdateItemInFolder } from '~/global/rpc/folders'
import { showErrorToast } from '~/global/toasts'
import { pastVerticalCenter } from '~/utils/pm-revamp/collision-detection-utils'

import {
  ConnectedWorkspacesTreeviewItem,
  ProjectPlaceholder,
} from './components'
import { type SortableItemData } from './types'
import { flattenTree } from './utils'

import { type SidebarWorkspacesTreeItem } from '../../types'
import { SortableTreeview } from '../components/sortable-treeview'

type WorkspacesTreeviewProps = {
  items: SidebarWorkspacesTreeItem[]
}

export const WorkspacesTreeview = ({ items }: WorkspacesTreeviewProps) => {
  const moveProjectInFolder = useMoveProjectInFolder()
  const { mutateAsync: updateItemInFolder } = useUpdateItemInFolder()

  const [flattenedItems, setOptimisticItems] = useDependantState(
    () => flattenTree(items),
    [items]
  )

  return (
    <SortableTreeview
      items={flattenedItems}
      collisionDetection={({ activeItem }) =>
        (args) => {
          if (!activeItem) return []

          const activeItemType = activeItem.type
          const activeItemWorkspaceId = activeItem.item.workspaceId

          const filterContainers = (container: DroppableContainer) => {
            const containerData = container.data.current as SortableItemData

            if (activeItemType === 'WORKSPACE') {
              // allowed in root only and the end of a workspace only
              return containerData.type === 'WORKSPACE'
            }

            // We do not support moving folders/projects between workspaces at this time
            if (containerData.item.workspaceId !== activeItemWorkspaceId) {
              return false
            }

            if (activeItemType === 'FOLDER') {
              return (
                containerData.type !== 'WORKSPACE' &&
                containerData.level <= MAX_FOLDER_DEPTH
              )
            }

            if (activeItemType === 'PROJECT' || activeItemType === 'NOTE') {
              return containerData.type !== 'WORKSPACE'
            }

            return false
          }

          return pastVerticalCenter({
            ...args,
            droppableContainers:
              args.droppableContainers.filter(filterContainers),
          })
        }}
      renderFilter={(items, { activeItem, activeItemIdx }) => {
        if (activeItem?.type === 'WORKSPACE') {
          return items.filter(({ level }) => level === 0)
        }

        let maxLevel: number | null = null

        return items.reduce<typeof items>((acc, item, i) => {
          if (maxLevel != null) {
            if (item.level > maxLevel) {
              // A parent folder is collapsed and this item should not be rendered
              return acc
            }

            // The level is now the same or less than the collapsed folder, reset the max level
            maxLevel = null
          }

          if (item.isContainer && !item.isContainerExpanded) {
            maxLevel = item.level
          }

          return [...acc, item]
        }, [])
      }}
      renderItem={({ item, level, projection }) =>
        item.type === 'PLACEHOLDER' ? (
          <ProjectPlaceholder item={item} />
        ) : (
          <ConnectedWorkspacesTreeviewItem
            item={item}
            level={level}
            projection={projection}
          />
        )
      }
      renderGhostItem={({ item, level, projection }) => (
        <ConnectedWorkspacesTreeviewItem
          item={item}
          level={item.level}
          projection={projection}
          disableDrag
          isGhost
        />
      )}
      calculateMinimumLevel={(activeItem, nextItem) => {
        if (nextItem) {
          if (
            nextItem.type === 'WORKSPACE' &&
            activeItem.type !== 'WORKSPACE'
          ) {
            return 1
          }

          return nextItem.level
        }

        return activeItem.type === 'WORKSPACE' ? 0 : 1
      }}
      calculateMaximumLevel={(activeItem, previousItem) => {
        if (!previousItem || activeItem.type === 'WORKSPACE') return 0

        const maxLevel =
          previousItem.level + Number(previousItem.item.isContainer)

        if (activeItem.type === 'FOLDER') {
          return clamp(maxLevel, 1, MAX_FOLDER_DEPTH)
        }

        return maxLevel
      }}
      onDragEnd={async ({ active, over, projection }) => {
        const activeItemData = active.data.current as
          | SortableItemData
          | undefined

        const activeId = active.id
        const overId = over?.id

        if (!activeItemData) {
          return void Sentry.captureException(
            new Error('handleDragEnd active item data is undefined'),
            {
              extra: {
                activeId,
                overId,
                projection,
              },
              tags: {
                position: 'WorkspacesTreeview',
              },
            }
          )
        }

        setOptimisticItems(projection.newItems)

        const itemType = activeItemData.type

        if (!projection.parentId) {
          // Folders and projects must have a parent ID to continue
          return void Sentry.captureException(
            new Error(
              'Parent folder ID was not set when attempting to move folder/project'
            ),
            {
              extra: {
                activeId,
                overId,
                projection,
              },
              tags: {
                position: 'WorkspacesTreeview',
              },
            }
          )
        }

        const { order, parentId } = projection

        if (itemType === 'WORKSPACE') {
          const parentFolderId = projection.parentId.toString()
          const itemId = activeItemData.item.id

          recordAnalyticsEvent('FOLDERS_DRAGGED_SIDEBAR_ITEM', { itemType })

          try {
            await updateItemInFolder({
              parentFolderId,
              itemId,
              order: order.toString(),
            })
          } catch (e) {
            Sentry.captureException(
              new Error('Failed to move workspace', { cause: e }),
              {
                extra: {
                  parentFolderId,
                  itemId,
                  order,
                },
                tags: {
                  position: 'WorkspacesTreeview',
                },
              }
            )
          }

          return
        }

        const parentFolder = projection.newItems.find(
          ({ item }) => item.id === parentId.toString()
        )

        if (!parentFolder) {
          return void Sentry.captureException(
            new Error(
              'Parent folder could not be found in flattened folder tree'
            ),
            {
              extra: {
                activeId,
                overId,
                projection,
              },
              tags: {
                position: 'WorkspacesTreeview',
              },
            }
          )
        }

        const parentFolderId = parentFolder.item.itemId

        if (itemType === 'FOLDER') {
          const itemId = activeItemData.item.id

          recordAnalyticsEvent('FOLDERS_DRAGGED_SIDEBAR_ITEM', { itemType })

          try {
            await updateItemInFolder({
              parentFolderId,
              itemId,
              order: order.toString(),
            })
          } catch (e) {
            if (
              e instanceof Error &&
              e.message === 'Folder depth limit reached'
            ) {
              return void showErrorToast(
                `You cannot exceed a folder depth of ${MAX_FOLDER_DEPTH}`
              )
            }

            Sentry.captureException(
              new Error('Failed to update item in folder', { cause: e }),
              {
                extra: {
                  parentFolderId,
                  itemId,
                  order,
                },
                tags: {
                  position: 'WorkspacesTreeview',
                },
              }
            )

            showErrorToast(e, 'Failed to move folder')
          }

          return
        }

        if (itemType === 'PROJECT') {
          const projectId = activeItemData.item.itemId

          recordAnalyticsEvent('FOLDERS_DRAGGED_SIDEBAR_ITEM', { itemType })

          try {
            await moveProjectInFolder(
              projectId,
              parentFolderId,
              order.toString()
            )
          } catch (e) {
            Sentry.captureException(
              new Error('Failed to update project in folder', { cause: e }),
              {
                extra: {
                  projectId,
                  parentFolderId,
                  order,
                },
                tags: {
                  position: 'WorkspacesTreeview',
                },
              }
            )
          }
          return
        }

        if (itemType === 'NOTE') {
          await updateItemInFolder({
            parentFolderId,
            itemId: activeItemData.item.id,
            order: order.toString(),
          })
        }
      }}
    />
  )
}
