import { type TaskSchema, type UploadedFileSchema } from '@motion/rpc-types'
import { useModalApi } from '@motion/web-common/modals'

import { Attachments, AttachmentUploadButton } from '~/areas/attachments'
import { useGetProjectTaskAttachments } from '~/areas/attachments/hooks/use-get-project-task-attachments'
import { useTaskByIdV2 } from '~/global/rpc/v2'
import { useCallback, useState } from 'react'

import { UncontrolledCollapsableContainer } from './collapsable-container'

type TaskAttachmentsCollapsableProps = {
  taskId: TaskSchema['id']
}

export const TaskAttachmentsCollapsable = ({
  taskId,
}: TaskAttachmentsCollapsableProps) => {
  const [expanded, setExpanded] = useState(false)

  const modalApi = useModalApi()

  const { data: task } = useTaskByIdV2({ id: taskId })
  const taskAttachments = useGetProjectTaskAttachments({
    targetId: taskId,
    targetType: 'TEAM_TASK',
  })
  const attachmentIds = taskAttachments.map((attachment) => attachment.id)

  const handleToggle = () => setExpanded((expanded) => !expanded)

  const handleAttachmentUpload = () => setExpanded(true)

  const handleAttachmentClick = useCallback(
    (attachmentId: UploadedFileSchema['id']) => {
      modalApi.open('attachment-preview', {
        attachmentId,
        attachmentIds,
      })
    },
    [modalApi, attachmentIds]
  )

  // Attachments are only available for normal and recurring instance tasks
  if (task?.type !== 'NORMAL' && task?.type !== 'RECURRING_INSTANCE') {
    return null
  }

  return (
    <UncontrolledCollapsableContainer
      title={`Attachments (${attachmentIds.length})`}
      headerActions={
        <AttachmentUploadButton
          targetId={taskId}
          targetType='TEAM_TASK'
          workspaceId={task.workspaceId}
          onUpload={handleAttachmentUpload}
        />
      }
      expanded={expanded}
      toggle={handleToggle}
    >
      <div className='pl-7'>
        <Attachments
          attachmentIds={attachmentIds}
          targetId={taskId}
          targetType='TEAM_TASK'
          limit={3}
          onAttachmentClick={handleAttachmentClick}
        />
      </div>
    </UncontrolledCollapsableContainer>
  )
}
