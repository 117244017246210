import { classed } from '@motion/theme'

import { Outlet } from 'react-router-dom'

export function MeetingInsightShell() {
  return (
    <Container>
      <Outlet />
    </Container>
  )
}

const Container = classed('div', {
  base: `
  w-full h-full overflow-hidden
  bg-calendar-bg-default
  isolate
  relative
  `,
})
