import { type TaskSchema } from '@motion/zod/client'

import { type AllowableModelCacheKeys } from '~/global/cache'
import { createContext, type Dispatch, type SetStateAction } from 'react'

type SelectedId = TaskSchema['id']

export type BulkOpsTarget = Extract<
  AllowableModelCacheKeys,
  'tasks' | 'projects'
>

export type BulkOpsAction = 'select-all' | 'unselect-all'

export interface BulkOpsValue {
  target: BulkOpsTarget
  selectedIds: SelectedId[]
  setSelectedIds: Dispatch<SetStateAction<SelectedId[]>>
  /**
   * Pub/sub functions
   */
  triggerAction: (action: BulkOpsAction) => void
  onAction: (action: BulkOpsAction, fn: () => void) => () => void
}

const defaultValue: BulkOpsValue = {
  target: 'tasks',
  selectedIds: [],
  setSelectedIds: () => {},
  triggerAction: () => {},
  onAction: () => () => {},
}

export const BulkOpsContext = createContext<BulkOpsValue>(defaultValue)
