import { type CreateProjectDefinitionSchema } from '@motion/rpc-types'
import { findDefaultStatus } from '@motion/shared/common'
import { convertDaysToDeadlineInterval } from '@motion/shared/flows'

import { useWorkspaceStatuses } from '~/global/hooks'
import { useGetGalleryProjectDefinitions } from '~/global/rpc/v2'
import { useMemo } from 'react'

import { FLOW_TEMPLATE_PRESETS } from '../../presets'

export function usePresetTemplate(
  workspaceId: string | undefined,
  isCreatingFromPreset: boolean,
  fromPresetId: string | undefined
): CreateProjectDefinitionSchema | undefined {
  const workspaceStatuses = useWorkspaceStatuses(workspaceId ?? '')
  const defaultStatus =
    findDefaultStatus(workspaceStatuses) ?? workspaceStatuses[0]

  const {
    data: galleryProjectDefinitions,
    isLoading: isLoadingGalleryProjectDefinitions,
  } = useGetGalleryProjectDefinitions({
    workspaceId: workspaceId || '',
  })

  if (workspaceId == null) {
    throw new Error('Workspace id not defined')
  }

  return useMemo(() => {
    if (isCreatingFromPreset && fromPresetId != null) {
      const getTemplateFn = FLOW_TEMPLATE_PRESETS.find(
        (p) => p.id === fromPresetId
      )?.template
      const template = getTemplateFn?.()

      if (template == null) {
        if (
          isLoadingGalleryProjectDefinitions ||
          !galleryProjectDefinitions?.models?.projectDefinitions
        ) {
          return undefined
        }

        const galleryTemplate = Object.values(
          galleryProjectDefinitions.models.projectDefinitions
        ).find((p) => p.id === fromPresetId)
        if (galleryTemplate) {
          return { ...galleryTemplate, workspaceId }
        }

        throw new Error(
          `Preset or gallery template with id ${fromPresetId} not found`
        )
      }

      return {
        ...template,
        workspaceId,
        stages: template.stages.map((stage) => ({
          ...stage,
          deadlineInterval: convertDaysToDeadlineInterval(
            stage.deadlineIntervalDays
          ),
          duration: convertDaysToDeadlineInterval(stage.deadlineIntervalDays),
          tasks: stage.tasks.map((task) => ({
            ...task,
            customFieldValuesFieldArray: [],
            statusId: defaultStatus.id,
          })),
          workspaceId,
        })),
      } satisfies CreateProjectDefinitionSchema
    }
  }, [
    isCreatingFromPreset,
    fromPresetId,
    galleryProjectDefinitions,
    isLoadingGalleryProjectDefinitions,
    workspaceId,
    defaultStatus.id,
  ])
}
