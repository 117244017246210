import { type CellContext } from '@tanstack/react-table'
import { type GroupedNode } from '~/areas/project-management/pages/pm-v3/grouping'
import { DateTimeCell } from '~/areas/project-management/pages/pm-v3/tree-list/cells/common-cells'
import { CellText } from '~/areas/project-management/pages/pm-v3/tree-list/cells/components'
import { DateGroupHeader } from '~/areas/project-management/pages/pm-v3/tree-list/cells/group-headers'
import { type ReactNode } from 'react'

import { NameCell } from '../components/cells'
import { ExpandableHeader } from '../components/cells/expandable-header'
import {
  type MeetingInsightsFieldId,
  type MeetingTreeListRowValueType,
} from '../types'

type CellRenderFn = (
  info: CellContext<GroupedNode<MeetingTreeListRowValueType>, unknown>
) => ReactNode

export const CELLS: Record<MeetingInsightsFieldId, CellRenderFn> = {
  title(info) {
    const item = info.row.original.value
    const counter = info.row.index + 1

    let cell = null
    const canExpand = info.row.getCanExpand()

    if (item.type === 'meetingInsights') {
      cell = <NameCell counter={counter} meetingInsights={item.value} />
    } else if (item.type === 'startTime') {
      cell = <DateGroupHeader item={item} fieldName='Date' />
    } else if (item.type === 'host') {
      cell = <CellText>{item.value}</CellText>
    }

    if (canExpand) {
      return <ExpandableHeader cell={cell} info={info} />
    }

    return cell
  },
  startTime(info) {
    const item = info.row.original.value

    let cell = <span>-</span>

    if (item.type === 'meetingInsights') {
      cell = <DateTimeCell dateTime={item.value.startTime} />
    }

    return cell
  },
  host(info) {
    const item = info.row.original.value

    let cell = <span>-</span>

    if (item.type === 'meetingInsights') {
      cell = (
        <CellText>
          {item.value.eventData?.organizer?.displayName ??
            item.value.eventData?.organizer?.email ??
            '-'}
        </CellText>
      )
    }

    return cell
  },
}
