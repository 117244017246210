import { showToast } from '@motion/ui/base'
import {
  type ProjectSchema,
  type StageDefinitionSchema,
} from '@motion/zod/client'

import { useMoveProjectToStage } from '~/areas/project/hooks'
import { useCallback } from 'react'

export function useProjectStageKanbanUpdater() {
  const updateProjectStage = useMoveProjectToStage()

  return useCallback(
    async (project: ProjectSchema, newStage: StageDefinitionSchema) => {
      if (!project.projectDefinitionId) {
        showToast('error', 'No project workflow template found')
        return
      }

      const projectStage = project.stages.find(
        (stage) => stage.name === newStage.name
      )

      if (!projectStage) {
        showToast(
          'error',
          `Stage ${newStage.name} not found in project workflow template`
        )
        return
      }

      await updateProjectStage(project, projectStage.stageDefinitionId)
    },
    [updateProjectStage]
  )
}
