import { FavoritesSection } from './favorites-section'
import { WorkspacesSection } from './workspaces-section'

import { useHasPinnedViewsTreatment } from '../hooks'

export const Treeviews = () => {
  const hasPinnedViews = useHasPinnedViewsTreatment()

  return (
    <div className='space-y-6'>
      {hasPinnedViews && <FavoritesSection />}
      <WorkspacesSection />
    </div>
  )
}
