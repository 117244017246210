import { type InternalStateKey } from '@motion/react-core/shared-state'
import { getCacheEntryValue } from '@motion/rpc-cache'
import {
  ActiveFilterKey,
  type EntityFilterState,
} from '@motion/ui-logic/pm/data'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { client } from '@motion/web-common/rpc'
import { DB, readPersistedStateMany } from '@motion/web-common/storage'
import { type VersionedViewV2 } from '@motion/zod/client'

import {
  type PageType,
  type RouteData,
} from '~/areas/project-management/pages/pm-v3/routes/types'
import { redirectLoader } from '~/areas/project-management/pages/pm-v3/routes/utils/redirect'
import {
  type ViewState,
  ViewStateKey,
} from '~/areas/project-management/pages/pm-v3/view-state'
import { getDefaultView } from '~/areas/project-management/pages/pm-v3/views/defaults'
import { fromViewDefinition } from '~/areas/project-management/pages/pm-v3/views/utils'
import { type LoaderFunctionArgs } from 'react-router'

const PRELOAD_KEYS = [ActiveFilterKey, ViewStateKey]

function getViewIdFromPrefix(prefix: string) {
  if (prefix.startsWith('view_id_')) {
    return prefix.slice(8)
  }
  return null
}

function getViewOrDefault(pageType: PageType, viewId: string | null) {
  if (viewId) {
    const viewFromCache = getCacheEntryValue(client, 'views', viewId)

    if (viewFromCache) {
      return fromViewDefinition(viewFromCache)
    }
  }

  const defaultView = getDefaultView(pageType)
  return fromViewDefinition(defaultView)
}

export const loadState = async (
  pageType: PageType,
  prefix: string,
  args: LoaderFunctionArgs
) => {
  await DB.open()

  const isArchivedPage = prefix.endsWith('_archived')

  const state = await readPersistedStateMany(DB.state, PRELOAD_KEYS, {
    prefix,
    notFound: isArchivedPage ? 'default-value' : 'null',
  })
  if (isArchivedPage) {
    return state
  }

  const viewId = getViewIdFromPrefix(prefix)
  const view = getViewOrDefault(pageType, viewId)

  if (state.get(ActiveFilterKey) == null) {
    state.set(ActiveFilterKey, view.filter)
  }
  if (state.get(ViewStateKey) == null) {
    state.set(ViewStateKey, view.view)
  }

  return state
}

export function pageLoader(page: PageType) {
  return async (args: LoaderFunctionArgs<any>) => {
    const pageKey = getStatePrefix(page, args.params)
    if (pageKey == null) {
      return redirectLoader('/web/pm/all-tasks/views/default')(args)
    }

    const state = await loadState(page, pageKey, args)
    const filter = state.get(ActiveFilterKey) as EntityFilterState
    const viewState = state.get(ViewStateKey) as ViewState

    if (viewState.viewId != null) {
      await DB.state.set(getLastViewPrefix(page, args.params), viewState.viewId)
    }

    recordAnalyticsEvent('PM_PAGE_VIEW', {
      context: page,
      layout: viewState.page,
    })

    return {
      page,
      key: pageKey,
      filter: {
        views: getViewsFilter(page),
        workspaceId: args.params.workspaceId,
        projectId: args.params.projectId,
        folderId: args.params.folderId,
        viewId: args.params.viewId,
      },
      state,

      // will be removed
      type: filter.target,
      variant: viewState.page,
    } satisfies Partial<RouteData>
  }
}

function getViewsFilter(page: PageType) {
  switch (page) {
    case 'all-tasks':
      return 'all-tasks'
    case 'my-tasks':
      return 'my-tasks'
    default:
      return 'workspace'
  }
}

export function getPageTypeFromLoader(args: LoaderFunctionArgs): PageType {
  const uri = new URL(args.request.url)
  const parts = uri.pathname.split('/').slice(3) // remove /web/pm
  const segment = parts[0]

  switch (segment) {
    case 'all-tasks':
      return 'all-tasks'
    case 'my-tasks':
      return 'my-tasks'
    case 'all-projects':
      return 'all-projects'
    case 'team-schedule':
      return 'team-schedule'
    case 'workspaces':
      if (args.params.folderId) return 'folder'
      if (args.params.projectId) return 'project'
      if (args.params.workspaceId) return 'workspace'
    default:
      return 'all-tasks'
  }
}

export function getLastViewPrefix(
  pageType: PageType,
  params: LoaderFunctionArgs['params']
) {
  switch (pageType) {
    case 'folder':
      return `lastview_folder_${params.folderId}`

    case 'workspace':
      return `lastview_workspace_${params.workspaceId}`

    case 'project':
      return `lastview_project_${params.projectId}`

    default:
      return `lastview_${pageType}`
  }
}

export function getStatePrefix(
  pageType: PageType,
  params: LoaderFunctionArgs['params']
) {
  const { viewId = 'default' } = params

  if (viewId !== 'default') {
    return `view_id_${viewId}`
  }

  switch (pageType) {
    case 'folder':
      return `view_default_folder_${params.folderId}`

    case 'workspace':
      return `view_default_workspace_${params.workspaceId}`

    case 'project':
      return `view_default_project_${params.projectId}`

    default:
      return `view_default_${pageType}`
  }
}

export async function persistView(prefix: string, view: VersionedViewV2) {
  const viewParts = fromViewDefinition(view)

  const filter = (ActiveFilterKey as InternalStateKey).serialize?.(
    viewParts.filter
  )
  if (filter) {
    await DB.state.set(`${prefix}:${ActiveFilterKey.name}`, filter)
  }

  const viewState = (ViewStateKey as InternalStateKey).serialize?.(
    viewParts.view
  )
  if (viewState) {
    await DB.state.set(`${prefix}:${ViewStateKey.name}`, viewState)
  }
}
