import { createUseMutation } from '@motion/rpc'
import { type MeetingInsightsQueryResponseSchema } from '@motion/rpc-types'
import { showToast } from '@motion/ui/base'
import { cloneDeep } from '@motion/utils/core'
import { values } from '@motion/utils/object'
import { client } from '@motion/web-common/rpc'

import { useAiAnalyticsCalls } from '~/areas/ai-hackerhouse/hooks/use-ai-analytics-calls'

import { rejectMeetingAction } from '../../rpc-definition'

const useRejectActionItemMutation = createUseMutation(rejectMeetingAction)

export function useRejectActionItem() {
  const { recordActionItemTriage } = useAiAnalyticsCalls()

  return useRejectActionItemMutation({
    onMutate: async ({ actionItemId }) => {
      recordActionItemTriage('rejected', undefined, actionItemId)

      // AIHH - Refer to comment in useFormCreateEvent, but the following code will be a lot simpler in production
      client.setQueriesData<MeetingInsightsQueryResponseSchema>(
        ['meeting-insights'],
        (oldData) => {
          if (!oldData) {
            return
          }

          const meetingInsights = values(oldData.models.meetingInsights)
          const meetingInsightsWithActionItem = meetingInsights.find((item) =>
            item.actionItems.some((ai) => ai.id === actionItemId)
          )

          if (meetingInsightsWithActionItem == null) {
            return oldData
          }

          const cloned = cloneDeep(oldData)

          // Update the action item to reflect that it has been triaged
          const meetingInsight =
            cloned.models.meetingInsights[meetingInsightsWithActionItem.id]

          const actionItemIndex = meetingInsight.actionItems.findIndex(
            (item) => item.id === actionItemId
          )
          if (actionItemIndex === -1) {
            return cloned
          }

          meetingInsight.actionItems.splice(actionItemIndex, 1, {
            ...meetingInsight.actionItems[actionItemIndex],
            hasBeenTriaged: true,
          })

          return cloned
        }
      )
    },
    onSuccess: () => {
      showToast('success', 'Action item has been rejected')
    },
    onError: () => {
      showToast('error', 'Failed to reject action item')
    },
  })
}
