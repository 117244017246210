import { DragSolid } from '@motion/icons'
import { classed } from '@motion/theme'

export type DragHandleProps = {
  listeners: Record<string, unknown> | undefined
  setActivatorNodeRef?: (node: HTMLElement | null) => void
  disabled?: boolean
}

export const DragHandle = (props: DragHandleProps) => {
  const { disabled = false } = props

  if (disabled) {
    return <DragHandleShell />
  }

  return (
    <DragHandleShell {...props.listeners} ref={props.setActivatorNodeRef}>
      <DragSolid className='relative shrink-0 w-4' />
    </DragHandleShell>
  )
}

const DragHandleShell = classed('div', {
  base: `
    relative shrink-0
    w-2.5 h-full flex items-center justify-center
    text-semantic-neutral-icon-subtle hover:text-semantic-neutral-icon-default
    transition-all
  `,
  variants: {
    disabled: {
      false: 'cursor-grab',
      true: 'cursor-default',
    },
  },
  defaultVariants: {
    disabled: false,
  },
})
