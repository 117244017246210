import { type SvgIcon } from '@motion/icons'

import { type ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

export type ListItemProps = {
  icon?: SvgIcon
  name: ReactNode
  rotateIcon?: boolean
}

export function ListItem({ icon: Icon, name, rotateIcon }: ListItemProps) {
  return (
    <div className='flex gap-1.5 items-center overflow-hidden leading-4'>
      {Icon && (
        <Icon
          className={twMerge(
            'shrink-0 size-4 text-semantic-neutral-icon-default',
            rotateIcon && 'rotate-90'
          )}
        />
      )}
      <span className='truncate'>{name}</span>
    </div>
  )
}
