import type {
  DashboardViewGroupBySchema,
  NormalTaskSchema,
  ProjectSchema,
} from '@motion/rpc-types'

type SharedKeys =
  | Extract<
      keyof ProjectSchema,
      | 'workspaceId'
      | 'priorityLevel'
      | 'startDate'
      | 'dueDate'
      | 'deadlineStatus'
    >
  | 'status.name'
  | 'label.name'

const sharedItems: Record<SharedKeys, string> = {
  workspaceId: 'Workspace',
  'status.name': 'Status',
  priorityLevel: 'Priority',
  startDate: 'Start Date',
  dueDate: 'Deadline',
  'label.name': 'Label',
  deadlineStatus: 'ETA',
}

type TaskKeys = Extract<
  keyof NormalTaskSchema,
  | 'projectId'
  | 'stageDefinitionId'
  | 'assigneeUserId'
  | 'isAutoScheduled'
  | 'duration'
  | 'completedTime'
>
export const taskItems: Record<TaskKeys, string> = {
  ...sharedItems,
  projectId: 'Project',
  stageDefinitionId: 'Stage',
  assigneeUserId: 'Assignee',
  isAutoScheduled: 'Autoscheduled',
  duration: 'Duration',
  completedTime: 'Completed On',
}

type ProjectKeys = Extract<
  keyof ProjectSchema,
  | 'managerId'
  | 'activeStageDefinitionId'
  | 'projectDefinitionId'
  | 'color'
  | 'folderId'
>
export const projectItems: Record<ProjectKeys, string> = {
  ...sharedItems,
  folderId: 'Folder',
  managerId: 'Assignee',
  activeStageDefinitionId: 'Stage',
  projectDefinitionId: 'Template',
  color: 'Color',
}

type GroupByDurationValue = NonNullable<DashboardViewGroupBySchema['by']>
type GroupByWithDuration = DashboardViewGroupBySchema & {
  by: GroupByDurationValue
}

export const groupByDurationMap: Record<GroupByDurationValue, string> = {
  day: 'Day',
  week: 'Week',
  month: 'Month',
  quarter: 'Quarter',
  year: 'Year',
}

export function getGroupByDurationLabel(item: GroupByDurationValue): string {
  return groupByDurationMap[item] ?? item
}

export function isDateBasedGroupBy(
  groupBy: Pick<DashboardViewGroupBySchema, 'field' | 'by'> | null | undefined
): groupBy is GroupByWithDuration {
  if (!groupBy) return false

  return (
    groupBy.field.endsWith('Date') ||
    groupBy.field.endsWith('Time') ||
    groupBy.field.endsWith('Start') ||
    groupBy.field.endsWith('End') ||
    groupBy.field.startsWith('date/') ||
    groupBy.by != null
  )
}
