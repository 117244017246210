import { PopoverTrigger, SearchableList } from '@motion/ui/base'
import { keys } from '@motion/utils/object'

import { ModalFieldButton } from '~/areas/task-project/components'
import { SubItemIndentMarker } from '~/areas/tasks/modals/task-modal/components'

import { ChartSortByField } from './chart-sortby-field'
import { ChartStackBarsField } from './chart-stack-bars-field'

import {
  useChartGroupBy,
  useChartSegmentBy,
  useChartSettingField,
  useChartSettings,
} from '../../contexts'
import { useGroupByFields } from '../../hooks'
import {
  getGroupByDurationLabel,
  groupByDurationMap,
  isDateBasedGroupBy,
} from '../../utils'
import { ListItem } from '../list-item'

type ChartSecondGroupByFieldProps = {
  label: string
}

export function ChartSecondGroupByField({
  label,
}: ChartSecondGroupByFieldProps) {
  const settings = useChartSettings()

  const [dataType] = useChartSettingField('item')
  const [groupBy] = useChartGroupBy()

  const [segmentBy, setSegmentBy] = useChartSegmentBy()

  const { sections, getName } = useGroupByFields(dataType, {
    includeNone: true,
    excludeFields: groupBy ? [groupBy.field] : undefined,
  })
  const isDateField = isDateBasedGroupBy(segmentBy)
  const showSortBy =
    segmentBy != null &&
    !isDateField &&
    (settings.type === 'bar' || settings.type === 'line')
  const showStackBars = settings.type === 'bar' && segmentBy != null

  return (
    <>
      <PopoverTrigger
        placement='bottom-start'
        renderPopover={({ close }) => (
          <SearchableList
            itemType='sectioned'
            sections={sections}
            computeKey={(item) => item}
            computeSelected={(item) => item === segmentBy?.field}
            computeSearchValue={(item) => getName(item)}
            onSelect={(item) => {
              if (item === 'none') {
                setSegmentBy(null)
              } else {
                const by = isDateBasedGroupBy(segmentBy)
                  ? segmentBy.by
                  : 'month'

                setSegmentBy({
                  field: item,
                  ...(isDateBasedGroupBy({ field: item }) && {
                    by,
                  }),
                })
              }
              close()
            }}
            renderItem={(item) => <ListItem name={getName(item)} />}
          />
        )}
      >
        <ModalFieldButton label={label}>
          {getName(segmentBy?.field ?? 'none')}
        </ModalFieldButton>
      </PopoverTrigger>

      {isDateBasedGroupBy(segmentBy) && (
        <div className='flex flex-row items-center gap-0.5'>
          <SubItemIndentMarker />

          <PopoverTrigger
            placement='bottom-start'
            renderPopover={({ close }) => (
              <SearchableList
                items={keys(groupByDurationMap)}
                computeKey={(item) => item}
                computeSelected={(item) => item === segmentBy.by}
                computeSearchValue={(item) => getGroupByDurationLabel(item)}
                onSelect={(item) => {
                  setSegmentBy({
                    ...segmentBy,
                    by: item,
                  })
                  close()
                }}
                renderItem={(item) => (
                  <ListItem name={getGroupByDurationLabel(item)} />
                )}
              />
            )}
          >
            <ModalFieldButton label='Group by'>
              {getGroupByDurationLabel(segmentBy.by)}
            </ModalFieldButton>
          </PopoverTrigger>
        </div>
      )}
      {showSortBy && <ChartSortByField category='segmentBy' />}
      {showStackBars && <ChartStackBarsField />}
    </>
  )
}
