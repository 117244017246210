import { RechartsLineChart } from '@motion/web-charts/line'
import { type ChartQueryResponseSchema } from '@motion/zod/client'

import { ChartQueryDataProvider } from './chart-query-data-provider'
import { type ConnectedChartProps } from './types'
import { useChartGroupDefinition } from './use-chart-category'
import { useChartLookups } from './use-chart-lookups'
import { useValueFormatter } from './use-value-formatter'

export const ConnectedLineChart = (props: ConnectedChartProps<'line'>) => {
  return (
    <ChartQueryDataProvider chart={props.chart}>
      {(chartQueryData) => {
        return <InnerLineChart data={chartQueryData} chart={props.chart} />
      }}
    </ChartQueryDataProvider>
  )
}

type InnerLineChartProps = ConnectedChartProps<'line'> & {
  data: ChartQueryResponseSchema
}

export const InnerLineChart = ({ data, chart }: InnerLineChartProps) => {
  const lookups = useChartLookups()

  const category = useChartGroupDefinition(chart.groupBy[0])
  const group = useChartGroupDefinition(chart.groupBy[1])

  const valueFormatter = useValueFormatter(chart)

  if (category == null) {
    return <div>Please add a group by</div>
  }

  return (
    <RechartsLineChart
      category={category}
      group={group}
      data={data.data}
      labelAccessor={lookups.getLabel}
      showLabelIndicatorAccessor={lookups.getShowLabelIndicator}
      colorAccessor={lookups.getColor}
      valueAxis={{
        label:
          chart.aggregate.type === 'count' ? chart.item : chart.aggregate.field,
      }}
      categoryAxis={{
        size: category.key === 'assigneeUserId' ? 30 : undefined,
      }}
      interpolation={chart.interpolation}
      valueFormatter={valueFormatter}
    />
  )
}
