import { Button, type ButtonProps } from '@motion/ui/base'
import { type DashboardViewCellSchema } from '@motion/zod/client'

import { checkTextRuleErrors, getTextFieldRules } from '~/global/rules'
import { showErrorToast } from '~/global/toasts'

import { useChartField } from '../contexts'
import { useAddChartCell, useUpdateChartCell } from '../hooks'

export type ConnectedChartSaveButtonProps = {
  onClick?: ButtonProps['onClick']
  mode: 'create' | 'edit'
  chartCell: DashboardViewCellSchema
}

export function ConnectedChartSaveButton({
  onClick,
  mode,
  chartCell,
}: ConnectedChartSaveButtonProps) {
  const updateChart = useUpdateChartCell()
  const addChartCell = useAddChartCell()
  const [title] = useChartField('title')

  const handleClickSave: ButtonProps['onClick'] = (evt) => {
    const errorTitle = checkTextRuleErrors(
      title,
      getTextFieldRules('name', { required: true, maxLength: 250 })
    )
    if (errorTitle != null) {
      return showErrorToast(errorTitle)
    }

    if (mode === 'edit') {
      updateChart()
    } else {
      addChartCell(chartCell)
    }

    onClick?.(evt)
  }

  return (
    <Button sentiment='primary' variant='solid' onClick={handleClickSave}>
      Apply
    </Button>
  )
}
