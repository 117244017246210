import {
  ArrowCircleLeftSolid,
  ChevronLeftOutline,
  ChevronRightOutline,
} from '@motion/icons'
import { classed } from '@motion/theme'
import { Button, IconButton } from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'
import { parseDate } from '@motion/utils/dates'

import {
  useAgendaCalendarActions,
  useAgendaCalendarState,
  useAgendaState,
} from '~/areas/agenda/hooks'
import { BlockingTimeslotDropdown } from '~/areas/calendar/components'
import { DifferentTimezoneAlert } from '~/areas/calendar/components/calendar-header/timezone-group/components/different-timezone-alert'
import { TimezoneButton } from '~/areas/calendar/components/calendar-header/timezone-group/components/timezone-button'
import { useBlockingTimeslots } from '~/global/rpc'
import { DateTime } from 'luxon'

export function AgendaCalendarHeader() {
  const { data: blockingTimeslots } = useBlockingTimeslots()
  const [calendarState] = useAgendaCalendarState()
  const { goToToday, goToNextDay, goToPrevDay } = useAgendaCalendarActions()
  const [agendaState] = useAgendaState()

  const localTimezone = agendaState.selectedDate.zoneName

  const date = parseDate(calendarState.selectedDate)
  const dateIsoDate = date.toISODate()

  const isToday = dateIsoDate === DateTime.local().toISODate()
  const isBeforeToday = date < DateTime.local().startOf('day')
  const timeslotIds =
    !isBeforeToday && blockingTimeslots
      ? blockingTimeslots
          ?.filter(
            (t) => DateTime.fromISO(t.startTime).toISODate() === dateIsoDate
          )
          .map((t) => t.id)
      : []

  return (
    <>
      <TimezoneDisplay timezone={localTimezone} />
      <DayHeaderRow>
        <DateDisplayContainer>
          {isToday ? <TodayLabel /> : <BackToTodayButton onClick={goToToday} />}
          <DayHeader date={date} />
        </DateDisplayContainer>
        <ButtonsContainer>
          <NavButtons onClickNext={goToNextDay} onClickPrev={goToPrevDay} />
          <BlockTimeSlotDropdownContainer isBeforeToday={isBeforeToday}>
            <BlockingTimeslotDropdown
              date={date}
              timeslotIds={timeslotIds}
              disabled={isBeforeToday}
            />
          </BlockTimeSlotDropdownContainer>
        </ButtonsContainer>
      </DayHeaderRow>
    </>
  )
}

const DayHeaderRow = classed('div', {
  base: 'flex flex-row py-2 justify-between',
})

const DateDisplayContainer = classed('div', {
  base: 'flex flex-col items-start gap-1 truncate',
})

const ButtonsContainer = classed('div', {
  base: 'flex flex-row gap-2 items-center',
})

function DayHeader({ date }: { date: DateTime }) {
  return (
    <span className='text-sm font-normal'>
      {templateStr('{{weekDay}} {{date}}', {
        weekDay: date.toFormat('ccc'),
        date: <span className='font-semibold'>{date.toFormat('LLL d')}</span>,
      })}
    </span>
  )
}

function TimezoneDisplay({ timezone }: { timezone: string }) {
  return (
    <TimezoneOuterContainer>
      <TimezoneContainer>
        <DifferentTimezoneAlert>
          <TimezoneButton timezone={timezone} size='small' clickable={false} />
        </DifferentTimezoneAlert>
      </TimezoneContainer>
    </TimezoneOuterContainer>
  )
}

const TimezoneOuterContainer = classed('div', {
  base: 'flex relative items-center',
})

const TimezoneContainer = classed('div', {
  base: 'z-50 absolute -left-12 -bottom-12 text-semantic-neutral-text-subtle',
})

function TodayLabel() {
  return <TodayLabelContainer>Today</TodayLabelContainer>
}
const TodayLabelContainer = classed('span', {
  base: 'text-semantic-primary-text-default text-2xs leading-5 font-bold',
})

function BackToTodayButton({ onClick }: { onClick: () => void }) {
  return (
    <BackToTodayButtonOuter>
      <Button
        variant='muted'
        sentiment='neutral'
        onClick={onClick}
        size='xsmall'
        fullWidth // only takes the full width of the outer wrapper
      >
        <BackToTodayButtonInner>
          <ArrowCircleLeftSolid />
          <span className='truncate'>back to today</span>
        </BackToTodayButtonInner>
      </Button>
    </BackToTodayButtonOuter>
  )
}

const BackToTodayButtonOuter = classed('div', {
  base: '[&>button]:pl-0 max-w-full',
})

const BackToTodayButtonInner = classed('span', {
  base: 'flex flex-row gap-1 items-center text-2xs leading-4 font-semibold min-w-0',
})

function NavButtons({
  onClickPrev,
  onClickNext,
}: {
  onClickPrev: () => void
  onClickNext: () => void
}) {
  return (
    <NavButtonsContainer>
      <IconButton
        icon={ChevronLeftOutline}
        onClick={onClickPrev}
        variant='muted'
        sentiment='neutral'
        size='small'
      />
      <IconButton
        icon={ChevronRightOutline}
        onClick={onClickNext}
        variant='muted'
        sentiment='neutral'
        size='small'
      />
    </NavButtonsContainer>
  )
}

const NavButtonsContainer = classed('div', {
  base: 'flex flex-row h-6',
})

const BlockTimeSlotDropdownContainer = classed('div', {
  base: '',
  variants: {
    isBeforeToday: {
      true: 'opacity-0',
    },
  },
})
