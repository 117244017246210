import { LightBulbSolid } from '@motion/icons'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { MotionLink } from '~/global/components'
import { useUriByRouteId } from '~/routing'

type FirstWorkspaceHintProps = {
  onAddWorkspace: () => void
}

export const FirstWorkspaceHint = ({
  onAddWorkspace,
}: FirstWorkspaceHintProps) => {
  const getRouteUri = useUriByRouteId()

  return (
    <div className='flex flex-col gap-2'>
      <button
        className='box-border h-12 mx-3 shadow-sm overflow-visible bg-semantic-neutral-bg-subtle border border-semantic-neutral-border-default rounded-md'
        onClick={() => {
          recordAnalyticsEvent('ADD_WORKSPACE_HINT_CLICKED')
          onAddWorkspace()
        }}
      >
        <div className='flex flex-row p-2 gap-2 items-center h-full'>
          <LightBulbSolid className='text-semantic-neutral-icon-default size-5 shrink-0' />
          <p className='text-xs text-left'>
            Click here to add your first workspace.
          </p>
        </div>
      </button>
      <MotionLink
        className='pl-[46px] text-xs text-semantic-primary-text-default underline'
        url={getRouteUri('tutorial-lesson', { lessonId: 'views-reports' })}
        onClick={() => {
          recordAnalyticsEvent('ADD_WORKSPACE_TUTORIAL_LINK_CLICKED')
        }}
      >
        Learn about Workspaces
      </MotionLink>
    </div>
  )
}
