import { TaskModalCustomField } from '~/areas/task-project/components/categories'

import { useChartField } from '../../contexts'

export function ChartTitleField() {
  const [value, setValue] = useChartField('title')

  return (
    <TaskModalCustomField.Text
      name='Name'
      value={value}
      onChange={setValue}
      hideIcon
      variant='minimal'
    />
  )
}
