import { defineApi, defineMutation } from '@motion/rpc'
import {
  type ApproveActionItemResponseSchemaDto,
  type ApproveActionItemSchemaRequestDto,
  type GetMeetingInsightsQuerySchema,
  type MeetingInsightsQueryResponseSchema,
  type SendBotNowResponseDto,
  type UpdateMeetingInsightsRequestDto,
} from '@motion/rpc-types'

export const meetingInsightsKey = {
  root: ['meeting-insights'] as const,
  byCalendarId: (calendarEventId: string) =>
    ['meeting-insights', 'by-calendar-id', calendarEventId] as const,
  byId: (meetingInsightId: string) =>
    ['meeting-insights', 'by-id', meetingInsightId] as const,
}

export const getMeetingInsights = defineApi<
  GetMeetingInsightsQuerySchema,
  MeetingInsightsQueryResponseSchema
>().using({
  key: (args) => meetingInsightsKey.byCalendarId(args.calendarEventId),
  uri: (args) =>
    `${import.meta.env.MOTION_AI_API_HOST}/meeting-insights?calendarEventId=${args.calendarEventId}`,
})

export const approveMeetingAction = defineMutation<
  ApproveActionItemSchemaRequestDto & {
    actionItemId: string
  },
  ApproveActionItemResponseSchemaDto
>().using({
  method: 'POST',
  uri: (args) =>
    `${import.meta.env.MOTION_AI_API_HOST}/meeting-insights/approve/${args.actionItemId}`,
  body: (args) => args,
})

export const rejectMeetingAction = defineMutation<
  { actionItemId: string },
  void
>().using({
  method: 'POST',
  uri: (args) =>
    `${import.meta.env.MOTION_AI_API_HOST}/meeting-insights/reject/${args.actionItemId}`,
})

export const getMeetingBotZoomAuthUrl = defineMutation<void, string>().using({
  method: 'POST',
  uri: `${import.meta.env.MOTION_AI_API_HOST}/zoom-webhook/auth-url`,
})

export const sendBotToMeetingNow = defineMutation<
  { meetingInsightsId: string },
  SendBotNowResponseDto
>().using({
  method: 'POST',
  uri: (args) =>
    `${import.meta.env.MOTION_AI_API_HOST}/meeting-insights/${args.meetingInsightsId}/send-bot-now`,
})

export const updateMeetingInsights = defineMutation<
  UpdateMeetingInsightsRequestDto & { meetingInsightsId: string },
  MeetingInsightsQueryResponseSchema
>().using({
  method: 'PATCH',
  uri: (args) =>
    `${import.meta.env.MOTION_AI_API_HOST}/meeting-insights/${args.meetingInsightsId}`,
  body: ({ meetingInsightsId, ...args }) => args,
})
