import { PlusSolid, TagSolid } from '@motion/icons'
import { type COLOR, COLORS } from '@motion/shared/common'
import { getNextRank } from '@motion/shared/pm'
import { Button } from '@motion/ui/base'
import { getNewColorName, labelColors } from '@motion/ui-logic'
import { byProperty, Compare } from '@motion/utils/array'
import { useHasTreatment } from '@motion/web-common/flags'
import { type LabelSchema } from '@motion/zod/client'

import { ColorItemBadge } from '~/global/components/badges'
import {
  ColorDropdown,
  LegacyColorDropdown,
} from '~/global/components/dropdowns'
import {
  useCreateLabel,
  useDeleteLabel,
  useUpdateLabel,
  useWorkspaceFns,
  useWorkspaceLabels,
} from '~/global/hooks'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  AddWorkspaceItemRow,
  EditableWorkspaceRow,
  LabelColoredIcon,
} from './common'
import { EmptyContainer } from './empty-container'

import { findUnusedColor } from '../../../components/ProjectManagement/constants'
import {
  SettingTable,
  type SettingTableProps,
} from '../components/setting-table'

export const WorkspaceLabelSettings = () => {
  const { workspaceId = '' } = useParams<{ workspaceId: string }>()
  const workspaceLabels = useWorkspaceLabels(workspaceId)
  const { getWorkspaceLabels, getWorkspaceById } = useWorkspaceFns()
  const [isAddingLabel, setIsAddingLabel] = useState(false)

  const { createLabel } = useCreateLabel()
  const { updateLabel } = useUpdateLabel()
  const { deleteLabel } = useDeleteLabel()

  const hasNewColorsEnabled = useHasTreatment(
    'new-colors-for-labels-and-custom-fields'
  )

  function handleUpdateLabel(label: LabelSchema, fieldName: 'color' | 'name') {
    return async (value: string) => {
      if (fieldName === 'name' && value === '') return
      if (label[fieldName] === value) return
      await updateLabel({ ...label, [fieldName]: value })
    }
  }

  async function handleCreateLabel(name: string, newColor: string) {
    const workspace = getWorkspaceById(workspaceId)
    const totalLabels = getWorkspaceLabels(workspaceId)
    await createLabel({
      color: newColor,
      name,
      sortPosition: getNextRank(totalLabels.length),
      workspaceId: workspace?.id ?? '',
    })

    setIsAddingLabel(false)
  }

  const labelsToRender: SettingTableProps['items'] = workspaceLabels
    .sort(byProperty('sortPosition', Compare.caseInsensitive))
    .map((label) => ({
      id: label.id,
      label: (
        <EditableWorkspaceRow
          icon={
            hasNewColorsEnabled ? (
              <ColorDropdown
                selectedColor={getNewColorName(label.color)}
                onChange={handleUpdateLabel(label, 'color')}
              >
                <Button
                  iconOnly
                  variant='outlined'
                  sentiment='neutral'
                  size='small'
                >
                  <div className='px-0.5'>
                    <ColorItemBadge color={getNewColorName(label.color)} />
                  </div>
                </Button>
              </ColorDropdown>
            ) : (
              <LegacyColorDropdown
                colorOptions={labelColors}
                renderItem={(color, onClose) => (
                  <Button
                    iconOnly
                    key={color}
                    onClick={() => {
                      void handleUpdateLabel(label, 'color')(color)
                      onClose()
                    }}
                    size='small'
                    variant='muted'
                  >
                    <LabelColoredIcon color={color} />
                  </Button>
                )}
                trigger={
                  <Button
                    iconOnly
                    variant='outlined'
                    sentiment='neutral'
                    size='small'
                  >
                    <div className='px-1'>
                      <LabelColoredIcon color={label.color} />
                    </div>
                  </Button>
                }
              />
            )
          }
          onChange={handleUpdateLabel(label, 'name')}
          name={label.name}
          type='label'
        />
      ),
      onDelete: () => deleteLabel(label),
    }))

  if (isAddingLabel) {
    const newColor = findUnusedColor(
      workspaceLabels,
      hasNewColorsEnabled ? [...COLORS] : labelColors
    )
    labelsToRender.push({
      id: 'newLabel',
      label: (
        <AddWorkspaceItemRow
          icon={
            hasNewColorsEnabled ? (
              <div className='flex px-[6px] py-[7px] justify-center items-center '>
                <ColorItemBadge color={newColor as COLOR} />
              </div>
            ) : (
              <div className='flex px-[9px] py-[7px] justify-center items-center '>
                <LabelColoredIcon color={newColor} size='small' />
              </div>
            )
          }
          onCancel={() => setIsAddingLabel(false)}
          onSave={(name) => handleCreateLabel(name, newColor)}
          placeholder='Enter label name'
        />
      ),
    })
  }

  const showAddButton = !!labelsToRender.length && !isAddingLabel

  return (
    <div className='flex flex-col gap-3'>
      <SettingTable
        emptyContainer={
          <EmptyContainer
            title='No labels yet'
            description='Use labels to organize your tasks and projects!'
            icon={TagSolid}
            action={{
              children: 'Add label',
              icon: PlusSolid,
              onClick: () => setIsAddingLabel(true),
            }}
          />
        }
        items={labelsToRender}
        title='Labels'
      />
      <div>
        {showAddButton && (
          <Button
            onClick={() => setIsAddingLabel(true)}
            size='small'
            variant='muted'
          >
            <PlusSolid />
            Add label
          </Button>
        )}
      </div>
    </div>
  )
}
