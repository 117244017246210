import { isCanceledStatus } from '@motion/shared/common'
import {
  calculateScheduledType,
  getAutoscheduleInfo,
  scheduleTypeToInfo,
} from '@motion/ui-logic'
import { type StatusSchema, type TaskSchema } from '@motion/zod/client'

export const UNFIT_TYPES = ['unfit', 'unfitPastDue'] as const
export const PAST_DUE_TYPES = ['pastDue', ...UNFIT_TYPES] as const

export function getCanceledBlockers(
  blockerTasks: TaskSchema[],
  getStatusById: (id: string) => StatusSchema
) {
  return blockerTasks.filter((t) => isCanceledStatus(getStatusById(t.statusId)))
}

export function getUnscheduledBlockers(blockerTasks: TaskSchema[]) {
  return blockerTasks
    .map((b) =>
      getAutoscheduleInfo(b, b.isAutoScheduled, b.completedTime != null)
    )
    .filter((b) => b.type === 'notScheduled')
}

export function getPastDueBlockers(blockerTasks: TaskSchema[]) {
  return blockerTasks
    .map((b) =>
      getAutoscheduleInfo(b, b.isAutoScheduled, b.completedTime != null)
    )
    .filter((b) => PAST_DUE_TYPES.includes(b.type))
}

export function getScheduledTypeInfo(task: TaskSchema) {
  const scheduledType = calculateScheduledType(task, {
    isAutoScheduled: task.isAutoScheduled,
    isCompleted: task.completedTime != null,
    scheduledDate: null,
  })
  return scheduleTypeToInfo[scheduledType]
}
