import { TutorialSolid } from '@motion/icons'
import { classed, type VariantProps } from '@motion/theme'

import { useUriByRouteId } from '~/routing'
import { type NavigateByIdParams } from '~/routing/hooks/navigate-by-id-params'
import { useAppSelector } from '~/state/hooks'
import { selectIsDesktopSubWindow } from '~/state/mainSlice'
import { type ReactNode } from 'react'

import { MotionLink, type MotionLinkProps } from './motion-link'

export type TutorialButtonLinkProps = VariantProps<
  typeof StyledTutorialLink
> & {
  onClick?: () => void
  text?: ReactNode
} & (
    | {
        lesson: NavigateByIdParams['tutorial-lesson']['lessonId']
        url?: never
      }
    | { lesson?: never; url: MotionLinkProps['url'] }
  )

export function TutorialButtonLink(props: TutorialButtonLinkProps) {
  const { text = 'Tutorial', onClick, rounded, fullWidth, url, lesson } = props

  const getRouteUri = useUriByRouteId()
  // Detect if in the add task window on desktop
  const isSubWindow = useAppSelector(selectIsDesktopSubWindow)

  const finalUrl =
    url ??
    getRouteUri('tutorial-lesson', {
      lessonId: lesson,
    })

  return (
    <StyledTutorialLink
      url={finalUrl}
      external={url != null || isSubWindow}
      onClick={onClick}
      rounded={rounded}
      fullWidth={fullWidth}
    >
      <TutorialSolid />
      {text}
    </StyledTutorialLink>
  )
}

export const StyledNewText = classed('span', {
  base: `
  inline-flex items-center justify-center gap-1
  py-0.5 px-1
  font-semibold text-[10px] leading-3

  text-semantic-gradient-purple
  bg-promotion-gradient
`,
  variants: {
    fullWidth: {
      true: 'w-full',
      false: '',
    },
    rounded: {
      true: 'rounded-full',
      false: 'rounded',
    },
  },
  defaultVariants: {
    fullWidth: false,
    rounded: false,
  },
})

const StyledTutorialLink = classed(MotionLink, StyledNewText, {
  base: `
    py-1 px-2
    text-xs

    hover:bg-promotion-hover-gradient
    hover:text-semantic-gradient-purple

    active:bg-promotion-active-gradient
    focus:border-button-neutral-outlined-border-focus

    [&_[data-icon]]:size-4
    [&_[data-icon]]:shrink-0
  `,
})
