import { useFullCalendarStructuredEvents } from './use-full-calendar-transform'

import { useCalendarState, useStateScheduledEntities } from '../../hooks'

/*
  Grabs the events from state and returns them in a format that FullCalendar can understand.
*/
export const useCalendarFcEvents = () => {
  const calendarState = useCalendarState((data) => ({
    newEventData: data.selectedCalendarEvent?.new,
    selectedDate: data.selectedDate,
  }))
  const { data } = useStateScheduledEntities()

  return useFullCalendarStructuredEvents({
    newEventData: calendarState.newEventData,
    scheduledEntities: data?.scheduledEntities,
    selectedDate: calendarState.selectedDate,
  })
}
