import { ExclamationCircleSolid, PlusSolid, XSolid } from '@motion/icons'
import { type EmailAccount } from '@motion/rpc/types'
import { type ProviderType } from '@motion/shared/common'
import {
  Button,
  CompanyLogo,
  PopoverTrigger,
  Tag,
  Tooltip,
} from '@motion/ui/base'
import { addComponentName } from '@motion/ui/helpers'

import { useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { AddAccountDropdown } from '../../../components/Account/add-account-dropdown'

export interface ConnectCalendarsProps {
  accounts: EmailAccount[]
  onConnectAccount: (provider?: ProviderType) => void
  onRemoveAccount: (email: string) => void
  onComplete: () => Promise<void>
  onSkip?: () => void
  isInModal?: boolean
  emailConnectionErrors?: Record<string, boolean>
}

export function ConnectCalendars({
  accounts = [],
  onConnectAccount,
  onRemoveAccount,
  onComplete,
  onSkip = () => void 0,
  isInModal = false,
  emailConnectionErrors = {},
}: ConnectCalendarsProps) {
  const hasErrors = !!Object.values(emailConnectionErrors ?? {}).length
  const [loading, setLoading] = useState(false)

  function renderSubmitButton() {
    const areCalendarsMissingOrInvalid =
      accounts.length === 0 ||
      accounts.filter((email) => email.status !== 'OK').length > 0 ||
      hasErrors
    const allowSkip = !isInModal
    const isDisabled = !allowSkip && areCalendarsMissingOrInvalid
    const showSkipButton = allowSkip && areCalendarsMissingOrInvalid
    const textButton = (
      <Button
        sentiment='neutral'
        variant='muted'
        fullWidth
        disabled={isDisabled}
        loading={loading}
        onClick={
          showSkipButton
            ? onSkip
            : async () => {
                setLoading(true)
                await onComplete()
                setLoading(false)
              }
        }
        data-testid='skip-connect-calendar'
      >
        {showSkipButton
          ? "Skip connecting a calendar, I don't want to see meetings and events in Motion"
          : "I've connected all calendars I use"}
      </Button>
    )
    return hasErrors ? (
      <Tooltip content="One of your calendars couldn't be connected. Try reconnecting it above or remove it before continuing.">
        {textButton}
      </Tooltip>
    ) : (
      textButton
    )
  }

  return (
    <div className='h-full w-full' {...addComponentName('ConnectCalendars')}>
      <div
        className={twMerge('mx-auto max-w-[600px]', !isInModal && 'p-6 pt-12')}
      >
        {!isInModal && (
          <img
            className='mb-8 h-12 w-12 hidden lg:block'
            src='https://assets.website-files.com/5fcb349058268443b1f5dbdb/5fcb34905826842808f5dd41_motion%20logo.png'
            alt='Motion Logo'
          />
        )}
        <h1
          className={twMerge(
            'font-semibold',
            isInModal
              ? 'text-semantic-neutral-text-default pb-4 text-[24px]'
              : 'text-light-1200 mb-3 lg:mb-8 text-lg lg:text-[32px] lg:leading-normal select-none'
          )}
        >
          Connect all your calendars to Motion
        </h1>

        {isInModal && (
          <p className='text-semantic-neutral-text-default pb-2'>
            Add your other calendars to see all your calendars in one view!
          </p>
        )}
        {!isInModal && (
          <>
            <p className='text-light-1100'>
              View and manage all your calendars (work and personal) from one
              place.
            </p>
            <p className='text-light-1100'>
              Help Motion make sense of your time with its AI.
            </p>
            <p className='text-light-1100 my-3 lg:my-8 underline'>
              We&apos;ll never sell or share your data.
            </p>
          </>
        )}
        {!!accounts.length && (
          <div className='my-4'>
            <div
              className={twMerge(
                'rounded-md border',
                isInModal
                  ? 'border-semantic-neutral-border-subtle'
                  : 'border-gray-300 bg-white'
              )}
            >
              {accounts.map((account, index, arr) => {
                const isLast = index === arr.length - 1
                const hasError = !!emailConnectionErrors[account.email]

                return (
                  <div key={account.email}>
                    <CalendarRow
                      account={account}
                      onRemove={() => onRemoveAccount(account.email)}
                      onConnectAccount={onConnectAccount}
                      hasError={hasError}
                      isInModal={isInModal}
                    />
                    {!isLast && (
                      <div
                        className={
                          isInModal
                            ? 'border border-semantic-neutral-border-subtle'
                            : 'border-t border-gray-300'
                        }
                      />
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        )}
        <div>
          <AddAccountDropdown
            onAddAccount={onConnectAccount}
            placement='bottom'
          >
            <Button fullWidth>
              <div className='w-full flex items-center justify-center gap-2'>
                <PlusSolid />
                Connect{Object.keys(accounts).length < 1 ? ' ' : ' another '}
                account
              </div>
            </Button>
          </AddAccountDropdown>
          {renderSubmitButton()}
        </div>
      </div>
    </div>
  )
}

type CalendarRowProps = {
  account: EmailAccount
  onRemove: () => void
  onConnectAccount: () => void
  hasError?: boolean
  isInModal?: boolean
}

function CalendarRow({
  account,
  onRemove,
  onConnectAccount,
  hasError,
  isInModal,
}: CalendarRowProps) {
  const isAuthenticated = account.status === 'OK'

  function renderPermissionPopover() {
    return (
      <PopoverTrigger
        placement='bottom'
        renderPopover={() => (
          <div className='bg-white shadow-md px-4 py-2 border-none dark:bg-white'>
            <p>You did not grant Motion permission to access this calendar.</p>
            <p>
              Click{' '}
              <a className='text-primary-400' onClick={onConnectAccount}>
                this link
              </a>{' '}
              and allow calendar access to continue
            </p>
          </div>
        )}
      >
        <span
          className={twMerge(
            'cursor-pointer',
            isInModal ? 'text-semantic-error-text-default' : 'text-red-500'
          )}
        >
          Missing permission
        </span>
      </PopoverTrigger>
    )
  }

  function renderConnectedState() {
    if (hasError) {
      return (
        <Button
          onClick={onConnectAccount}
          fullWidth
          sentiment='primary'
          size='small'
        >
          <div className='flex items-center justify-center gap-2'>
            Reconnect
          </div>
        </Button>
      )
    }

    return (
      <Tag color='green' variant='subtle' size='small'>
        <span className='font-semibold'>Linked</span>
      </Tag>
    )
  }

  return (
    <div
      key={account.email}
      className='flex items-center justify-between px-4 py-4'
      {...addComponentName('CalendarRow')}
    >
      <span
        className={twMerge(
          'flex items-center gap-3',
          isInModal
            ? twMerge(
                'text-semantic-neutral-text-default',
                hasError && 'text-semantic-error-text-default'
              )
            : twMerge('text-light-1200', hasError && 'text-alert-500')
        )}
      >
        <div className='rounded-full border border-semantic-neutral-border-default w-8 h-8 flex items-center justify-center'>
          <div className='h-5 w-5 flex items-center justify-center'>
            <CompanyLogo provider={account.providerType} />
          </div>
        </div>
        {account.email}
        {hasError && (
          <ExclamationCircleSolid className='text-semantic-error-icon-default size-4 ml-2' />
        )}
      </span>
      <div className='flex items-center gap-2'>
        {isAuthenticated ? renderConnectedState() : renderPermissionPopover()}
        <Tooltip asChild content='Remove this account'>
          <button onClick={() => onRemove()}>
            <XSolid
              className={twMerge(
                'size-3',
                isInModal
                  ? 'text-semantic-neutral-icon-default'
                  : 'text-light-1100'
              )}
            />
          </button>
        </Tooltip>
      </div>
    </div>
  )
}
