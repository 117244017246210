import { ArrowRightOutline, XSolid } from '@motion/icons'
import { API } from '@motion/rpc'
import { Button, IconButton, Modal, Tag } from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'
import { INDIVIDUAL_PRICES, makeTeamBillingStr } from '@motion/ui-logic/billing'
import {
  logEvent,
  recordAnalyticsEvent,
  useOnMountAnalyticsEvent,
} from '@motion/web-base/analytics'
import { logInDev } from '@motion/web-base/logging'
import { Sentry } from '@motion/web-base/sentry'
import { useShouldShowSalesTaxMessage } from '@motion/web-billing'

import { useQueryClient } from '@tanstack/react-query'
import { useOpenPortalLink } from '~/components/Account/hooks'
import { useGetTeamPrices } from '~/global/rpc/team'
import { useToggleIntercom } from '~/utils/toggleIntercom'
import { useCallback, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import api from '../../chromeApi/chromeApiContentScript'
import { Header, Paragraph, SubParagraph } from '../../components/Common'
import { useAppDispatch, useAppSelector } from '../../state/hooks'
import { selectTeam } from '../../state/team'
import { switchBillingCycle } from '../../state/teamSlice'
import {
  type StripeSubscription,
  upgradeToAnnualPlan,
} from '../../state/userSlice'

export type AnnualUpgradeModalProps = {
  subscription: StripeSubscription
  onClose: () => void
}

export const AnnualUpgradeModal = (props: AnnualUpgradeModalProps) => {
  const { subscription, onClose: onClose } = props

  const dispatch = useAppDispatch()
  const [upgradeSuccess, setUpgradeSuccess] = useState(false)
  const [upgradeFail, setUpgradeFail] = useState(false)
  const [upgradeLoading, setUpgradeLoading] = useState(false)

  const { isPortalLoading, openPortalLink } = useOpenPortalLink({
    stripeCustomerId: subscription.customer,
  })
  const client = useQueryClient()

  const team = useAppSelector(selectTeam)
  const isOnTeamMonthly =
    team?.pmTeamSubscription?.status !== 'canceled' &&
    team?.pmTeamSubscription?.isMonthly
  const shouldShowSalesTaxMessage = useShouldShowSalesTaxMessage()
  const toggleIntercom = useToggleIntercom()

  const nonUSD =
    subscription.plan.currency &&
    subscription.plan.currency.toLowerCase() !== 'usd'

  const annualPlanHandler = useCallback(async () => {
    try {
      setUpgradeLoading(true)
      if (isOnTeamMonthly) {
        const result = await dispatch(switchBillingCycle(team.id))

        if (result.payload) {
          await client.invalidateQueries({
            queryKey: API.subscriptions.getIndividualAndTeamSubscription.key(),
          })
        }

        recordAnalyticsEvent('TEAM_BILLING_CONVERT_TO_ANNUAL')
      } else {
        const res = await dispatch(upgradeToAnnualPlan()).unwrap()
        if ('error' in res) {
          throw new Error(res.error)
        }
        if (res.subscription) {
          setUpgradeSuccess(true)
          await api.storage.local.set({ stripeSubscription: res.subscription })

          void logEvent('ACCOUNT_UPGRADE_ANNUAL')
        } else {
          throw new Error('Bad response')
        }
      }
      onClose()
    } catch (e) {
      logInDev('error', e)
      setUpgradeFail(true)
      Sentry.captureException(e, { tags: { position: 'annualPlanHandler' } })
    } finally {
      setUpgradeLoading(false)
    }
  }, [client, dispatch, isOnTeamMonthly, onClose, team?.id])

  useOnMountAnalyticsEvent('NON_USD_ON_ANNUAL_UPGRADE_MODAL', {
    enabled: !!nonUSD,
  })

  const { data: teamPrices } = useGetTeamPrices()
  if (!teamPrices) return null

  const prices = isOnTeamMonthly ? teamPrices : INDIVIDUAL_PRICES

  const currentPrice = subscription.plan.amount / 100

  const savings =
    (currentPrice - prices.annualPricePerMonth) * 12 * subscription.quantity

  const isTrial = (subscription.trial_end ?? 0) > Date.now() / 1000

  return (
    <Modal onClose={onClose} visible>
      <div className='flex flex-col w-[464px] items-center p-6 bg-semantic-neutral-bg-default'>
        <div className='absolute top-3 right-3'>
          <IconButton
            icon={XSolid}
            onClick={() => onClose()}
            size='small'
            variant='muted'
            sentiment='neutral'
            aria-label='Close annual upgrade modal'
          />
        </div>

        {upgradeSuccess ? (
          <div className='flex flex-col gap-3 items-center'>
            <Header className='mb-3 text-xl'>Success</Header>
            <SubParagraph className='text-md'>
              {templateStr(
                'Thank you for subscribing to Motion&apos;s annual plan! You can view your invoice from your {{page}} page.',
                { page: isOnTeamMonthly ? 'Team Billing' : 'Account' }
              )}
            </SubParagraph>
          </div>
        ) : upgradeFail ? (
          <div className='flex flex-col gap-3 items-center'>
            <Header className='text-xl'>Error</Header>
            <Paragraph>
              Sorry, something went wrong. Please contact support.
            </Paragraph>
            <Button
              fullWidth
              onClick={openPortalLink}
              loading={isPortalLoading}
            >
              Change payment method
            </Button>
          </div>
        ) : nonUSD ? (
          <div className='flex flex-col items-center'>
            <Header className='mb-3 text-xl'>Switch to annual plan</Header>
            <SubParagraph className='text-md'>
              {templateStr(
                'You are currently on a legacy monthly plan. To upgrade to an annual plan, please {{link}}.',
                {
                  link: (
                    <a
                      className='text-semantic-purple-text-default'
                      onClick={() => {
                        toggleIntercom()
                        recordAnalyticsEvent(
                          'NON_USD_ON_CONTACT_SUPPORT_CLICK',
                          {
                            location: 'annualUpgradeModal',
                          }
                        )
                      }}
                      role='button'
                    >
                      contact support
                    </a>
                  ),
                }
              )}
            </SubParagraph>
          </div>
        ) : (
          <div className='flex flex-col gap-6 w-full'>
            <div className='flex flex-col gap-1 items-center'>
              <Tag variant='subtle' color='blue'>
                Save {prices.annualSavingsPercentInteger}%
              </Tag>
              <h1 className='text-lg font-semibold text-semantic-neutral-text-default'>
                {templateStr('Switch to an annual plan to save {{savings}}', {
                  savings: savings.toLocaleString(),
                })}
              </h1>
            </div>
            <div className='flex items-center justify-between w-full'>
              <PlanWrapper
                price={currentPrice}
                isTeam={!!isOnTeamMonthly}
                isMuted
              />
              <SubParagraph>
                <ArrowRightOutline className='h-5 w-5' />
              </SubParagraph>
              <PlanWrapper
                price={prices.annualPricePerMonth}
                isTeam={!!isOnTeamMonthly}
              />
            </div>
            <div className='flex flex-col gap-3'>
              <Button
                fullWidth
                onClick={annualPlanHandler}
                loading={upgradeLoading}
              >
                Switch to annual plan
              </Button>
              <SubParagraph className='text-center'>
                {isOnTeamMonthly
                  ? makeTeamBillingStr({
                      isAnnual: true,
                      quantity: subscription.quantity,
                      isSeats: true,
                      prorationTextParams: {
                        isTrial,
                      },
                      teamPrices,
                      shouldShowSalesTaxMessage,
                    })
                  : templateStr(
                      "You'll be charged ${{price}} billed annually{{applicableTax}}{{prorationText}}",
                      {
                        price: prices.annualPrice,
                        prorationText: isTrial
                          ? ' after your trial ends.'
                          : '. The first year amount will be pro-rated starting today.',
                        applicableTax: shouldShowSalesTaxMessage
                          ? ' (plus applicable taxes)'
                          : '',
                      }
                    )}
              </SubParagraph>
            </div>
          </div>
        )}
      </div>
    </Modal>
  )
}

type PlanWrapperProps = { price: number; isTeam: boolean; isMuted?: boolean }

const PlanWrapper = ({ price, isTeam, isMuted }: PlanWrapperProps) => {
  return (
    <div className='flex flex-col p-4 bg-semantic-neutral-bg-active-default min-w-[160px] rounded'>
      <div className='mt-3 flex flex-col items-center gap-1'>
        <Paragraph
          className={twMerge(
            'text-3xl',
            isMuted
              ? 'text-semantic-neutral-text-subtle'
              : 'text-semantic-neutral-text-default'
          )}
        >
          ${price * 12}
        </Paragraph>
        <SubParagraph className='pb-1 text-xs'>
          {isTeam ? 'per user / year' : 'per year'}
        </SubParagraph>
      </div>
    </div>
  )
}
