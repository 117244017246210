import { Sentry } from '@motion/web-base/sentry'

import { useCurrentModal } from '~/areas/modals'
import { useMeetingModalUrl } from '~/global/navigation'
import { showErrorToast } from '~/global/toasts'
import { useNavigate } from 'react-router'

import { useEventForm } from './use-event-form'
import { useFormCreateEvent } from './use-form-create-event'
import { useFormUpdateEvent } from './use-form-update-event'

import { type EventAiFormFields } from '../../../types'

export function useSubmitEventForm() {
  const currentModal = useCurrentModal<'event-modal'>()
  const updateEvent = useFormUpdateEvent()
  const createEvent = useFormCreateEvent()
  const buildMeetingModalUrl = useMeetingModalUrl()
  const navigate = useNavigate()

  const { form } = useEventForm()
  const { watch } = form

  const eventId = watch('id')

  return async (data: EventAiFormFields) => {
    try {
      if (!form.formState.isDirty) return

      if (eventId != null) {
        const response = await updateEvent(data)
        if (response != null) {
          // reset dirty state when successful
          form.reset({}, { keepValues: true })
        }
        return
      }

      const createdEvent = await createEvent(data)
      if (createdEvent != null) {
        // reset dirty state when successful
        form.reset({}, { keepValues: true })

        if (createdEvent.meetingTaskId != null) {
          currentModal.dismiss()
          navigate(
            buildMeetingModalUrl({
              mTask: createdEvent.meetingTaskId,
            })
          )
        } else {
          currentModal.updateProps({
            eventId: createdEvent.id,
          } as any)
        }
      }
    } catch (e) {
      Sentry.captureException(e)
      showErrorToast(e)
    }
  }
}
