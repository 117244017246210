import { type COLOR } from '@motion/shared/common'
import { ProjectPalette } from '@motion/ui/project'
import { Sentry } from '@motion/web-base/sentry'

import { StageTooltip } from '~/areas/flows'
import { useViewState } from '~/areas/project-management/pages/pm-v3/view-state'
import { useLookup } from '~/global/cache'
import { StageBadge } from '~/global/components/badges'
import { useLegacyStageDefinition } from '~/global/hooks'
import { createProjectProxy } from '~/global/proxies'
import { forwardRef } from 'react'

import { useResizeStages, useStageLocation } from './hooks'
import {
  StageBadgeContainer,
  StageCompletionBar,
  StageInitialCircle,
  StageItemContainer,
} from './styled'
import {
  type ConnectedResizeableProjectStageItemProps,
  type ResizeableProjectStageItemProps,
} from './types'
import { isFirstStage } from './utils'

import { usePlannerProps } from '../../../context'
import { ZIndexMap } from '../../../shared-constants'

export const ConnectedResizeableProjectStageItem = (
  props: ConnectedResizeableProjectStageItemProps
) => {
  const { stageWithDates, project } = props
  const lookup = useLookup()
  const projectProxy = createProjectProxy(project, lookup)

  const { stage } = stageWithDates
  const [plannerProps] = usePlannerProps()
  const [viewState] = useViewState()

  const {
    resizingStagesState: { stageDetails, isResizing: isResizingStages },
    ref,
  } = useResizeStages(stageWithDates, projectProxy)

  const {
    stageLeft,
    stageProgressWidth,
    maxStageWidth,
    adjustedEndDate,
    adjustedStartDate,
  } = useStageLocation({
    ...props,
    stageDetails,
  })

  const isProjectResizing = project.id === plannerProps.resizingId
  const isProjectStagesResizing =
    stageDetails?.projectId === project.id && isResizingStages
  const isResizing =
    !props.ignoreStageResizing && (isProjectResizing || isProjectStagesResizing)
  const marginLeft =
    props.currentSide === 'left' &&
    plannerProps.shiftModifier &&
    isProjectResizing &&
    !isFirstStage(stage.id, project.stages)
      ? props.projectDeltaWidth
      : 0

  return (
    <ResizeableProjectStageItem
      startDate={adjustedStartDate}
      endDate={adjustedEndDate}
      stage={stage}
      project={project}
      stageLeft={stageLeft}
      maxStageWidth={maxStageWidth}
      marginLeft={marginLeft}
      stageProgressWidth={stageProgressWidth}
      isResizing={isResizing}
      isYearView={viewState.groupBy.dateRange === 'year'}
      ref={ref}
    />
  )
}

export const ResizeableProjectStageItem = forwardRef<
  HTMLDivElement,
  ResizeableProjectStageItemProps
>(function ResizeableProjectStageItem(
  {
    startDate,
    endDate,
    stage,
    project,
    stageLeft,
    maxStageWidth,
    stageProgressWidth,
    isResizing,
    marginLeft,
    isYearView,
  },
  ref
) {
  const stageDefinition = useLegacyStageDefinition(stage.stageDefinitionId)

  if (!stageDefinition) {
    Sentry.captureException('Stage definition not found', {
      tags: {
        projectId: project.id,
        stageId: stage.id,
      },
    })
    return null
  }

  return (
    <StageTooltip
      hideTooltip={isResizing}
      showDragHeader
      stage={stage}
      start={startDate.toISODate()}
      end={endDate.toISODate()}
    >
      <StageItemContainer
        style={{
          left: stageLeft + (marginLeft ?? 0),
          width: maxStageWidth,
        }}
        smallMinWidth={isYearView}
      >
        <div className='relative flex items-center w-full h-full'>
          <ProjectPalette color={stageDefinition.color}>
            <StageCompletionBar
              style={{
                width: stageProgressWidth,
              }}
            />
            <StageBadgeContainer
              // Force show the badge when resizing
              showing={isResizing}
              ref={ref}
            >
              {isResizing ? (
                <div className='w-[calc(100%-1px)] flex items-center bg-palette-highlight-subtle'>
                  <span className='mr-auto pl-0.5 text-[8px]'>
                    {startDate.toFormat('M/d')}
                  </span>
                  <span className='ml-auto pr-0.5 text-[8px]'>
                    {endDate.toFormat('M/d')}
                  </span>
                </div>
              ) : (
                <div
                  className='cursor-ew-resize w-auto h-auto absolute'
                  style={{
                    right: 0,
                    zIndex: ZIndexMap.projectItem,
                  }}
                >
                  <StageBadge
                    value={stageDefinition}
                    size='xxsmall'
                    hideTooltip
                  />
                </div>
              )}
            </StageBadgeContainer>
          </ProjectPalette>
          <ProjectPalette color={project.color as COLOR}>
            <StageInitialCircle
              // Force hide the initial palette when resizing
              hidden={isResizing}
              style={{
                right: 0,
              }}
            >
              <div className='rounded-full bg-palette-text-default opacity-40 w-full h-full' />
            </StageInitialCircle>
          </ProjectPalette>
        </div>
      </StageItemContainer>
    </StageTooltip>
  )
})
