import type { COLOR } from '@motion/shared/common'
import { FolderColoredIcon } from '@motion/ui/project'
import { computeSearchScore } from '@motion/ui-logic'
import { byProperty, Compare } from '@motion/utils/array'

import { type UserFavorite, useUserFavorites } from '~/areas/folders/hooks'
import { getViewIcon } from '~/areas/project-management/pages/pm-v3/components'
import { useSidebarSearchContext } from '~/areas/search/hook'
import { useLookup } from '~/global/cache'
import { ConnectedProjectBadge } from '~/global/components/badges'
import { useCallback, useMemo } from 'react'

import { type SortableFavoriteItem } from '../types'

export const useFavoritesTreeviewItems = (): SortableFavoriteItem[] => {
  const userFavorites = useUserFavorites()
  const { searchQuery } = useSidebarSearchContext()
  const lookup = useLookup()

  const formatItems = useCallback(
    (items: UserFavorite[]) =>
      items
        .reduce<
          {
            item: SortableFavoriteItem
            score: number
          }[]
        >((acc, item) => {
          const score = computeSearchScore(item.name, searchQuery)

          if (searchQuery && score <= 0) {
            return acc
          }

          const sharedProps = {
            id: item.id,
            isContainer: false,
            isContainerExpanded: false,
            label: item.name,
            level: 0,
            order: item.order,
            parentId: null,
            type: item.type,
            url: item.url,
          } satisfies Partial<SortableFavoriteItem>

          if (item.type === 'VIEW') {
            const tooltipParts: string[] = []

            if (item.workspaceId) {
              const workspace = lookup('workspaces', item.workspaceId)

              if (workspace) {
                tooltipParts.push(workspace.name)
              }
            }

            if (item.folderId) {
              const folder = lookup('folders', item.folderId)

              if (folder?.name) {
                tooltipParts.push(folder.name)
              }
            }

            if (item.projectId) {
              const project = lookup('projects', item.projectId)

              if (project) {
                tooltipParts.push(project.name)
              }
            }

            return [
              ...acc,
              {
                item: {
                  ...sharedProps,
                  targetId: item.view.id,
                  icon: getViewIcon(item.view),
                  tooltip: tooltipParts.join(' / '),
                },
                score,
              },
            ]
          }

          if (item.type === 'FOLDER') {
            return [
              ...acc,
              {
                item: {
                  ...sharedProps,
                  targetId: item.folder.id,
                  icon: () => (
                    <FolderColoredIcon color={item.folder.color as COLOR} />
                  ),
                },
                score,
              },
            ]
          }

          if (item.type === 'PROJECT') {
            return [
              ...acc,
              {
                item: {
                  ...sharedProps,
                  targetId: item.project.id,
                  icon: () => (
                    <ConnectedProjectBadge id={item.project.id} hideTooltip />
                  ),
                },
                score,
              },
            ]
          }

          return acc
        }, [])
        .sort(byProperty('score', Compare.numeric.desc))
        .map((i) => i.item),
    [lookup, searchQuery]
  )

  return useMemo(
    () =>
      formatItems(userFavorites.items).sort(
        byProperty('order', (l, r) =>
          Compare.string(l.toString(), r.toString())
        )
      ),
    [formatItems, userFavorites]
  )
}
