import { ProjectCubeSolid } from '@motion/icons'
import { useSharedState } from '@motion/react-core/shared-state'
import { Button, SearchableList } from '@motion/ui/base'
import { byProperty, Compare, groupInto } from '@motion/utils/array'
import { type ProjectSchema } from '@motion/zod/client'

import { AppWorkspaceContext } from '~/global/contexts'
import { useCallback, useMemo } from 'react'

import { FilterItem } from './filter-item/filter-item'

import { useFieldFilter } from '../../../context'
import { buildIdFilter } from '../../../utils'
import { MultiSelectFilterValue } from '../../multi-select-filter-value'
import { type DropdownFilterProps } from '../../types'

export const ConnectedProjectFilter = (
  props: DropdownFilterProps<'projects'>
) => {
  const [value, setValue] = useFieldFilter(props.target, 'ids')

  return (
    <FilterItem
      label='Name'
      canInvert
      inverted={value?.inverse}
      onInvertChanged={(invert) => {
        if (value == null) return
        setValue({ ...(value ?? {}), inverse: invert })
      }}
      onBlur={props.onBlur}
      openOnMount={props.openOnMount}
      onDismiss={() => setValue(null)}
      renderDropdown={({ close }) => <ConnectedProjectDropdown close={close} />}
    >
      <MultiSelectFilterValue
        Icon={ProjectCubeSolid}
        type='projects'
        ids={value?.value ?? null}
        labelSize='small'
      />
    </FilterItem>
  )
}

type ConnectedProjectDropdownProps = {
  close: () => void
}
const ConnectedProjectDropdown = (props: ConnectedProjectDropdownProps) => {
  const [ctx] = useSharedState(AppWorkspaceContext)
  const [filter, setFilter] = useFieldFilter('projects', 'ids')
  const selectedIds = (filter?.value ?? null) as string[]
  const computeSelected = useCallback(
    (value: ProjectSchema) => selectedIds?.includes(value.id) ?? false,
    [selectedIds]
  )

  const projects = useMemo(() => {
    const all = ctx.projects.all

    const sections = groupInto(all, (p) => p.workspaceId)
      .map((g) => ({
        key: g.key,
        label: ctx.workspaces.byId[g.key]?.name ?? 'unknown',
        items: g.items,
      }))
      .sort(byProperty('label', Compare.caseInsensitive))
    return {
      all,
      sections,
    }
  }, [ctx.projects.all, ctx.workspaces.byId])

  return (
    <div>
      <SearchableList
        inputProps={{ placeholder: 'Select projects ...' }}
        itemType='sectioned-checkbox'
        sections={projects.sections}
        renderItem={(project) => <div>{project.name}</div>}
        computeSearchValue={(user) => user.name}
        computeSelected={computeSelected}
        onSelect={(values) => {
          setFilter(buildIdFilter(values, filter))
        }}
        computeKey={(item) => item.id}
      />
      <div className='border-t border-dropdown-border p-2 flex justify-between'>
        <Button
          onClick={() => {
            setFilter(null)
            props.close()
          }}
          variant='outlined'
          sentiment='neutral'
          size='small'
        >
          Clear
        </Button>
        <Button
          onClick={() => {
            setFilter(buildIdFilter(projects.all, filter))
          }}
          variant='outlined'
          sentiment='neutral'
          size='small'
        >
          Select all
        </Button>
      </div>
    </div>
  )
}
