import { type MeetingInsightsSchema } from '@motion/rpc-types'
import { byValue, type CompareFn } from '@motion/utils/array'

import { type ColumnDef } from '@tanstack/react-table'
import {
  DEFAULT_STRING_SORT,
  type FieldDefinition,
} from '~/areas/project-management/pages/pm-v3/fields'
import {
  type GroupableField,
  type GroupedNode,
} from '~/areas/project-management/pages/pm-v3/grouping'
import { type DateTime } from 'luxon'

export const MeetingInsightsGroupableFields = [
  {
    id: 'startTime',
    label: 'Date',
  },
  {
    id: 'host',
    label: 'Host',
  },
] as const satisfies GroupableField[]

export const MEETING_INSIGHTS_FIELDS = {
  title: {
    id: 'title',
    name: 'Name',
    size: { min: 300, default: 500 },
    sortFn: byValue<Pick<MeetingInsightsSchema, 'eventData'>, string | null>(
      (meetingInsight) => meetingInsight.eventData?.title ?? null,
      DEFAULT_STRING_SORT
    ),
  },
  startTime: {
    id: 'startTime',
    name: 'Date',
    size: 200,
    // TODO: Update to use a date sort function
    sortFn: byValue<MeetingInsightsSchema, string>(
      (meetingInsight) => meetingInsight.startTime,
      DEFAULT_STRING_SORT
    ),
  },
  host: {
    id: 'host',
    name: 'Host',
    size: 200,
    sortFn: byValue<MeetingInsightsSchema, string | null>(
      (meetingInsight) =>
        meetingInsight.eventData?.organizer?.displayName ?? null,
      DEFAULT_STRING_SORT
    ),
  },
} satisfies Record<string, FieldDefinition<MeetingInsightsSchema>>

export type SortField = {
  [key in keyof typeof MEETING_INSIGHTS_FIELDS]: (typeof MEETING_INSIGHTS_FIELDS)[key] extends {
    sortFn: CompareFn<any>
  }
    ? key
    : never
}[keyof typeof MEETING_INSIGHTS_FIELDS]
export type MeetingInsightsFieldId = keyof typeof MEETING_INSIGHTS_FIELDS

export type MeetingTreeListRowValueType =
  | {
      type: 'meetingInsights'
      value: MeetingInsightsSchema
    }
  | {
      type: 'startTime'
      value: string | DateTime
    }
  | {
      type: 'host'
      value: string
    }

export type TreeListColumn = ColumnDef<GroupedNode<MeetingTreeListRowValueType>>
