import { DateFilter } from './date-filter'

import { useFieldFilter } from '../../../context'
import { type DropdownFilterProps } from '../../types'

export type ConnectedTaskDateFilterProps = DropdownFilterProps<'tasks'> & {
  label: string
  field:
    | 'createdTime'
    | 'dueDate'
    | 'completedTime'
    | 'lastInteractedTime'
    | 'estimatedCompletionTime'
    | 'scheduledStart'
}

export const ConnectedTaskDateFilter = (
  props: ConnectedTaskDateFilterProps
) => {
  const [fieldValue, setFieldValue] = useFieldFilter(props.target, props.field)

  return (
    <DateFilter
      label={props.label}
      value={fieldValue}
      onChanged={setFieldValue}
      openOnMount={props.openOnMount}
      onBlur={props.onBlur}
    />
  )
}

export type ConnectedProjectDateFilterProps =
  DropdownFilterProps<'projects'> & {
    field: 'updatedTime' | 'createdTime' | 'dueDate' | 'startDate'
    label: string
  }

export const ConnectedProjectDateFilter = (
  props: ConnectedProjectDateFilterProps
) => {
  const [fieldValue, setFieldValue] = useFieldFilter(props.target, props.field)

  return (
    <DateFilter
      label={props.label}
      value={fieldValue}
      onChanged={setFieldValue}
      openOnMount={props.openOnMount}
      onBlur={props.onBlur}
    />
  )
}
