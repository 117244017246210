import { type NoteSchema } from '@motion/rpc-types'
import { useModalApi } from '@motion/web-common/modals'

import { useDeleteNoteMutation } from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

export const useDeleteNote = () => {
  const { mutateAsync: deleteMutation } = useDeleteNoteMutation()
  const modalApi = useModalApi()

  return useCallback(
    (noteId: NoteSchema['id']) => {
      void modalApi.prompt('confirm', {
        analytics: {
          name: 'delete-note',
        },
        title: 'Are you sure you want to delete this Note?',
        destructive: true,
        confirmButtonText: 'Delete Note',
        onValue: async (shouldDelete) => {
          if (shouldDelete) {
            try {
              await deleteMutation({ id: noteId })
            } catch (err) {
              showErrorToast(err)
            }
          }
        },
      })
    },
    [deleteMutation, modalApi]
  )
}
