import { type CustomFieldValuesRecord } from '@motion/rpc/types'
import { findDefaultStatus } from '@motion/shared/common'
import { isNoneId } from '@motion/shared/identifiers'
import { keys } from '@motion/utils/object'
import { type PriorityLevelSchema } from '@motion/zod/client'

import { useFieldFilter } from '~/areas/project-management/filters'
import { useWorkspaceFns } from '~/global/hooks'
import { useCallback } from 'react'

import { useInferWorkspaceIdFromFilters } from './shared'
import {
  type FilterFunctionOpts,
  findByName,
  makeIdFilterFunction,
  type ProjectInferItem,
} from './utils'

import {
  getCustomFieldValuesFromGroupParents,
  useWorkspaceCustomFieldsByTypedName,
} from '../../../grouping/custom-fields'
import { usePageData } from '../../../routes'

export type InferProjectDataHandler = (
  item: ProjectInferItem,
  workspaceIdArg?: string
) => {
  workspaceId: string | null
  priorityLevel: PriorityLevelSchema | null
  statusId: string
  labelId: string | null
  managerId: string | null
  customFieldValues: CustomFieldValuesRecord | null
  folderId: string | null
}

export type UseInferProjectDataHandlerArgs = {
  useLastItemFallback?: boolean
}

export function useInferProjectDataHandler({
  useLastItemFallback,
}: UseInferProjectDataHandlerArgs = {}): InferProjectDataHandler {
  const data = usePageData()
  const { getWorkspaceStatuses, getWorkspaceLabels } = useWorkspaceFns()
  const getWorkspaceCustomFieldsByTypedName =
    useWorkspaceCustomFieldsByTypedName()

  const getWorkspaceIdFromFilters = useInferWorkspaceIdFromFilters(
    data.overrides,
    { useLastItemFallback }
  )
  const getUserIdFromFilters = useInferUserIdFromFilters({
    useLastItemFallback,
  })
  const getStatusIdFromFilters = useInferStatusIdFromFilters({
    useLastItemFallback,
  })
  const getLabelIdFromFilters = useInferLabelIdFromFilters({
    useLastItemFallback,
  })
  const getPriorityFromFilters = useInferPriorityFromFilters({
    useLastItemFallback,
  })
  const getFolderFromFilters = useInferFolderFromFilters({
    useLastItemFallback,
  })

  return useCallback(
    (item, workspaceIdArg) => {
      const { parents } = item

      const managerId =
        parents.user?.value?.id ?? getUserIdFromFilters(item.value.items)
      const priorityLevel: PriorityLevelSchema | null =
        parents.priority?.value ?? getPriorityFromFilters(item.value.items)

      const workspaceId =
        workspaceIdArg ??
        parents.workspace?.value?.id ??
        getWorkspaceIdFromFilters(item.value.items)

      const workspaceStatuses = getWorkspaceStatuses(workspaceId)
      const statusId =
        findByName(workspaceStatuses, parents.status?.value.name)?.id ??
        getStatusIdFromFilters(item.value.items) ??
        findDefaultStatus(workspaceStatuses)?.id ??
        ''

      const workspaceLabels = getWorkspaceLabels(workspaceId)
      const labelId =
        findByName(workspaceLabels, parents.label?.value.name)?.id ??
        getLabelIdFromFilters(item.value.items) ??
        null

      const customFields = getWorkspaceCustomFieldsByTypedName(workspaceId)

      const customFieldValues = getCustomFieldValuesFromGroupParents(
        parents,
        customFields
      )

      const folderId =
        parents.folder?.value?.id ??
        getFolderFromFilters(item.value.items) ??
        data.lock.folderId ??
        null

      return {
        workspaceId,
        priorityLevel,
        managerId: !managerId || isNoneId(managerId) ? null : managerId,
        statusId,
        labelId,
        customFieldValues:
          keys(customFieldValues).length > 0 ? customFieldValues : null,
        folderId,
      }
    },
    [
      getUserIdFromFilters,
      getPriorityFromFilters,
      getWorkspaceIdFromFilters,
      getWorkspaceStatuses,
      getStatusIdFromFilters,
      getWorkspaceLabels,
      getLabelIdFromFilters,
      getWorkspaceCustomFieldsByTypedName,
      getFolderFromFilters,
      data.lock.folderId,
    ]
  )
}

function useInferUserIdFromFilters({
  useLastItemFallback,
}: FilterFunctionOpts) {
  const [userIds] = useFieldFilter('projects', 'managerIds')
  return makeIdFilterFunction(userIds, 'managerId', {
    useLastItemFallback,
  })
}

function useInferStatusIdFromFilters({
  useLastItemFallback,
}: FilterFunctionOpts) {
  const [statusIds] = useFieldFilter('projects', 'statusIds')
  return makeIdFilterFunction(statusIds, 'statusId', {
    useLastItemFallback,
  })
}

function useInferLabelIdFromFilters({
  useLastItemFallback,
}: FilterFunctionOpts) {
  const [labelIds] = useFieldFilter('projects', 'labelIds')
  return makeIdFilterFunction(labelIds, 'labelIds', {
    useLastItemFallback,
  })
}

function useInferPriorityFromFilters({
  useLastItemFallback,
}: FilterFunctionOpts) {
  const [priorities] = useFieldFilter('projects', 'priorities')
  return makeIdFilterFunction(priorities, 'priorityLevel', {
    useLastItemFallback,
  })
}

function useInferFolderFromFilters({
  useLastItemFallback,
}: FilterFunctionOpts) {
  const [folderIds] = useFieldFilter('projects', 'folderIds')
  return makeIdFilterFunction(folderIds, 'folderId', {
    useLastItemFallback,
  })
}
