import { PencilSolid, ProjectCubeSolid, PuzzleSolid } from '@motion/icons'
import { createPendingStatus } from '@motion/rpc-cache'
import { SYSTEM_FLOW_VARIABLE_OBJECTS } from '@motion/shared/flows'
import { ButtonGroup, IconButton } from '@motion/ui/base'

import { StatusBadge } from '~/global/components/badges'
import { StageLabel } from '~/global/components/labels'
import {
  type StageDefinitionWithProjectInfo,
  useWorkspaceStatuses,
} from '~/global/hooks'
import { useMemo } from 'react'

import { StageTemplateCardMenu } from './stage-template-card-menu'
import { CardList, FlowCard, FlowCardHeader, ProjectCountBadge } from './styled'
import { TaskDefinitionName } from './task-definition-name'

export type FlowStageCardProps = {
  stageDefinition: StageDefinitionWithProjectInfo
  workspaceId: string
}
export function FlowStageCard({
  stageDefinition,
  workspaceId,
}: FlowStageCardProps) {
  const statuses = useWorkspaceStatuses(workspaceId)
  const { projects, projectDefinitions, tasks, variables } = stageDefinition

  const allVariables = useMemo(() => {
    return variables.concat(SYSTEM_FLOW_VARIABLE_OBJECTS)
  }, [variables])

  return (
    <FlowCard className='h-[250px]'>
      <FlowCardHeader>
        <StageLabel value={stageDefinition} />
        <ButtonGroup>
          <IconButton
            icon={PencilSolid}
            // TODO: Add edit stage url and modal
            url=''
            sentiment='neutral'
            size='xsmall'
            variant='muted'
          />
          <StageTemplateCardMenu onDuplicate={() => {}} onDelete={() => {}} />
        </ButtonGroup>
      </FlowCardHeader>
      <CardList isRow>
        <ProjectCountBadge>
          <PuzzleSolid className='size-3' />
          {projectDefinitions.length} templates
        </ProjectCountBadge>
        <ProjectCountBadge>
          <ProjectCubeSolid className='size-3' />
          {projects.length} Active projects
        </ProjectCountBadge>
      </CardList>
      <CardList>
        {tasks.map((task) => (
          <CardList isRow className='gap-1.5' key={task.id}>
            <StatusBadge
              value={
                statuses.find((status) => status.id === task.statusId) ??
                createPendingStatus('Placeholder')
              }
              size='small'
              taskVariant={
                task.scheduleMeetingWithinDays != null ? 'meeting' : 'default'
              }
            />
            <TaskDefinitionName task={task} variables={allVariables} />
          </CardList>
        ))}
      </CardList>
    </FlowCard>
  )
}
