import {
  CalendarSolid,
  PlusSolid,
  QuestionMarkSolid,
  WorkspaceSolid,
  XSolid,
} from '@motion/icons'
import {
  Button,
  ButtonGroup,
  IconButton,
  Tooltip,
  UserAvatar,
} from '@motion/ui/base'

import { useI18N } from '~/global/contexts'
import { type ReactNode } from 'react'

import { type InviteeWithData, useInviteesData } from './hooks'
import { TeamMemberDropdown } from './team-member-dropdown'
import { type Invitee } from './types'
import { WorkspaceSelector } from './workspace-selector'

export type ShareWithDropdownProps = {
  selectedInvitees: Invitee[]

  /**
   * When an Invitee entity's section is updated
   * @param type The type of the entity
   * @param ids The final list of ids of the entity
   * @returns
   */
  onSelected: (type: 'USER' | 'WORKSPACE', ids: string[]) => void
  onRemove: (invitee: Invitee) => void
}

export function ShareWithDropdown({
  selectedInvitees,
  onSelected,
  onRemove,
}: ShareWithDropdownProps) {
  const { pluralize } = useI18N()

  const inviteesWithData = useInviteesData(selectedInvitees)

  const { users, workspaces } = inviteesWithData.reduce(
    (acc, invitee) => {
      if (invitee.type === 'USER') {
        acc.users.push(invitee)
      } else {
        acc.workspaces.push(invitee)
      }

      return acc
    },
    { users: [] as InviteeWithData[], workspaces: [] as InviteeWithData[] }
  )

  return (
    <div className='flex flex-col gap-4 p-3 bg-dropdown-bg border-dropdown-border rounded w-[400px]'>
      <header className='flex justify-between items-center'>
        <h3 className='text-sm font-semibold text-semantic-neutral-text-default'>
          Share with
        </h3>

        <ButtonGroup>
          <TeamMemberDropdown
            selectedUserIds={users.map((user) => user.id)}
            onChange={(ids) =>
              onSelected('USER', [
                ...users.filter((u) => u.isRedacted).map((u) => u.id),
                ...ids,
              ])
            }
          >
            <Button variant='outlined' sentiment='neutral' size='small'>
              <PlusSolid />
              Team member
            </Button>
          </TeamMemberDropdown>

          <WorkspaceSelector
            selectedWorkspaceIds={workspaces.map((workspace) => workspace.id)}
            onSelect={(ids) =>
              onSelected('WORKSPACE', [
                ...workspaces.filter((w) => w.isRedacted).map((w) => w.id),
                ...ids,
              ])
            }
          >
            <Button variant='outlined' sentiment='neutral' size='small'>
              <PlusSolid />
              Workspace
            </Button>
          </WorkspaceSelector>
        </ButtonGroup>
      </header>

      <div className='flex flex-col gap-3'>
        <InviteesContainer
          title='Attendees (internal only)'
          description="External attendees won't have access"
          icon={<CalendarSolid />}
        />
        {inviteesWithData.map((invitee) => {
          if (invitee.isRedacted) {
            return (
              <InviteesContainer
                key={invitee.data.id}
                title={`(Hidden ${invitee.type.toLowerCase()})`}
                icon={<QuestionMarkSolid />}
                onRemove={() => onRemove(invitee)}
              />
            )
          }

          if (invitee.type === 'USER') {
            return (
              <InviteesContainer
                key={invitee.data.id}
                title={
                  <p className='inline-flex gap-1 items-center text-semantic-neutral-text-default'>
                    {invitee.data.name}
                    <span className='text-xs text-semantic-neutral-text-subtle'>
                      ({invitee.data.email})
                    </span>
                  </p>
                }
                icon={
                  <UserAvatar
                    id={invitee.data.id}
                    profileUrl={invitee.data.picture}
                    name={invitee.data.name}
                    size={16}
                  />
                }
                onRemove={() => onRemove(invitee)}
              />
            )
          }

          return (
            <InviteesContainer
              key={invitee.id}
              title={invitee.data.name}
              description={pluralize(
                invitee.data.members.length,
                '{{count}} member',
                '{{count}} members'
              )}
              icon={<WorkspaceSolid />}
              onRemove={() => onRemove(invitee)}
            />
          )
        })}
      </div>
    </div>
  )
}

type InviteesContainerProps = {
  title: ReactNode
  description?: ReactNode
  icon: ReactNode
  onRemove?: () => void
}

function InviteesContainer({
  title,
  description,
  icon,
  onRemove,
}: InviteesContainerProps) {
  return (
    <div className='group flex items-center gap-3'>
      <div className='flex flex-1 gap-2 [&_[data-icon]]:size-4 [&_[data-icon]]:text-dropdown-item-icon-default'>
        {icon}
        <div className='flex flex-col gap-1'>
          <div className='text-sm text-semantic-neutral-text-default'>
            {title}
          </div>
          <div className='text-xs text-semantic-neutral-text-subtle'>
            {description}
          </div>
        </div>
      </div>
      {onRemove != null && (
        <div className='group-hover:opacity-100 opacity-0'>
          <Tooltip content='Remove access' asChild>
            <IconButton
              variant='muted'
              size='small'
              onClick={onRemove}
              icon={XSolid}
              sentiment='neutral'
            />
          </Tooltip>
        </div>
      )}
    </div>
  )
}
