import { FormModal, PopoverButton, SearchableDropdown } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { type ModalDefinitions } from '@motion/web-common/modals/definitions'
import { type VersionedViewV2 } from '@motion/zod/client'

import { useCurrentTeam } from '~/global/rpc/team'
import { type ReactNode, useState } from 'react'

import { useSaveView } from '../hooks/use-save-view'
import { type LocalView } from '../types'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'save-view-v2': {
      view: LocalView
      asNew?: boolean
      renderTitle?: () => ReactNode
    } & PromptCallbacks<VersionedViewV2>
  }
}

type ConnectedSaveViewModalProps = ModalDefinitions['save-view-v2'] & {
  close: () => void
}
export const ConnectedSaveViewModal = (props: ConnectedSaveViewModalProps) => {
  const save = useSaveView()

  return (
    <SaveViewModal
      isNew={props.asNew ?? false}
      view={props.view}
      onSave={async (viewToSave) => {
        return save({ view: viewToSave, asNew: props.asNew })
      }}
      onClose={props.close}
      renderTitle={props.renderTitle}
    />
  )
}

type SaveViewModalProps = {
  isNew: boolean
  view: LocalView
  onSave(view: LocalView): Promise<VersionedViewV2 | false>
  onClose(): void
  renderTitle?: () => ReactNode
}

export const SaveViewModal = (props: SaveViewModalProps) => {
  const { data: team } = useCurrentTeam()

  const [name, setName] = useState(props.isNew ? '' : props.view.name)
  const [isPrivate, setIsPrivate] = useState(props.view.isPrivate)
  const canShare = props.view.type !== 'my-tasks' && Boolean(team) // must have a team to make team views

  const isNew = props.isNew

  return (
    <FormModal
      onClose={props.onClose}
      submitAction={{
        onAction: async () => {
          const savedView = await props.onSave({
            ...props.view,
            name,
            isPrivate,
          })

          if (savedView) {
            props.onClose()
          }
        },
        disabled: name.length < 2,
      }}
      title={props.renderTitle?.() ?? (isNew ? 'Save view' : 'Update view')}
      visible
    >
      <div className='flex flex-col items-start gap-4 self-stretch'>
        <div className='flex flex-col w-full gap-4 justify-between'>
          <div className='w-full flex [&>*]:flex-1 gap-[12px]'>
            <TextField
              autoFocus
              placeholder='View name'
              value={name}
              onChange={setName}
              size='normal'
            />
            {canShare && (
              <SearchableDropdown
                items={[true, false].map((item) => ({
                  id: `${item}`,
                  label: item ? 'Personal view' : 'Team view',
                  value: item,
                }))}
                renderItem={(item) => item.label}
                onChange={(item) => setIsPrivate(item.value)}
              >
                <PopoverButton className='h-[34px]'>
                  {isPrivate ? 'Personal view' : 'Team view'}
                </PopoverButton>
              </SearchableDropdown>
            )}
          </div>
        </div>
      </div>
    </FormModal>
  )
}
