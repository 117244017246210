import { findCompletedStatus, findDefaultStatus } from '@motion/shared/common'
import { isObjectNoneId } from '@motion/shared/identifiers'
import { HoverCard, ProgressCircle } from '@motion/ui/base'
import {
  formatDateRange,
  formatDurationToShort,
  templateStr,
} from '@motion/ui-logic'
import { type StageSchema } from '@motion/zod/client'

import { StatusBadge } from '~/global/components/badges'
import { StageLabel } from '~/global/components/labels'
import { useLegacyStageDefinition, useWorkspaceStatuses } from '~/global/hooks'

import {
  DragLabelHeader,
  HeaderSection,
  MetricsSection,
  SmallText,
  SpaceBetweenRow,
  StageTooltipContainer,
  StatusLabel,
  SubtleText,
} from './styled'

type StageTooltipProps = {
  children: React.ReactNode
  stage: StageSchema
  start: string | null
  end: string | null
  showDragHeader?: boolean
  hideTooltip?: boolean
}

export const StageTooltip = ({
  children,
  stage,
  start,
  end,
  hideTooltip = false,
  showDragHeader = false,
}: StageTooltipProps) => {
  const stageDefinition = useLegacyStageDefinition(stage.stageDefinitionId)
  const statuses = useWorkspaceStatuses(stageDefinition.workspaceId)

  if (start == null || end == null || hideTooltip) return children

  const defaultStatus = findDefaultStatus(statuses)
  const completedStatus = findCompletedStatus(statuses)

  return (
    <HoverCard
      placement='bottom-start'
      renderContent={() => (
        <StageTooltipContainer showDragHeader={showDragHeader}>
          {showDragHeader && (
            <DragLabelHeader>
              Hold Shift + drag to resize only this stage
            </DragLabelHeader>
          )}
          <HeaderSection>
            {!isObjectNoneId(stageDefinition) && (
              <StageLabel value={stageDefinition} size='normal' />
            )}
            <SmallText>{formatDateRange(start, end)}</SmallText>
          </HeaderSection>

          <MetricsSection>
            <SpaceBetweenRow>
              <StatusLabel>
                <ProgressCircle
                  value={stage.completion}
                  size='xsmall'
                  labelHidden
                />
                <SmallText className='font-medium'>
                  {stage.completion}%
                </SmallText>
              </StatusLabel>

              <SmallText className='font-medium'>
                {templateStr('{{count}} ({{duration}})', {
                  count: stage.taskCount,
                  duration: formatDurationToShort(stage.duration),
                })}
              </SmallText>
            </SpaceBetweenRow>

            <div className='flex flex-col gap-3 pt-2 w-full'>
              {defaultStatus != null && (
                <SpaceBetweenRow>
                  <StatusLabel>
                    <StatusBadge value={defaultStatus} size='xsmall' />
                    <SmallText>Open</SmallText>
                  </StatusLabel>

                  <SubtleText>
                    {templateStr('{{count}} ({{duration}})', {
                      count: stage.taskCount - stage.completedTaskCount,
                      duration: formatDurationToShort(
                        stage.duration - stage.completedDuration
                      ),
                    })}
                  </SubtleText>
                </SpaceBetweenRow>
              )}

              {completedStatus != null && (
                <SpaceBetweenRow>
                  <StatusLabel>
                    <StatusBadge value={completedStatus} size='xsmall' />
                    <SmallText>Completed</SmallText>
                  </StatusLabel>

                  <SubtleText>
                    {templateStr('{{count}} ({{duration}})', {
                      count: stage.completedTaskCount,
                      duration: formatDurationToShort(stage.completedDuration, [
                        'hours',
                        'minutes',
                      ]),
                    })}
                  </SubtleText>
                </SpaceBetweenRow>
              )}
            </div>
          </MetricsSection>
        </StageTooltipContainer>
      )}
    >
      {children}
    </HoverCard>
  )
}
