import { ActionModal, type ActionModalProps } from '@motion/ui/base'

import { type ReactNode } from 'react'

import { type ModalTriggerComponentProps } from './modal-trigger'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    action: PromptCallbacks<string> & {
      title: ActionModalProps['title']
      blocking?: ActionModalProps['blocking']
      actions: (Omit<ActionModalProps['actions'][number], 'onAction'> & {
        value: string
      })[]
      children: ReactNode
    }
  }
}

export const ConnectedActionModal = ({
  close,
  ...rest
}: ModalTriggerComponentProps<'action'>) => {
  const actions = rest.actions.map(({ value, ...action }) => ({
    ...action,
    onAction: () => close(value),
  }))

  return (
    <ActionModal visible {...rest} actions={actions} onClose={() => close()} />
  )
}
