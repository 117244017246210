import { type QuickAction } from '@motion/ui-logic'
import {
  getEnabledStagesWithDates,
  type StageArg,
} from '@motion/ui-logic/pm/project'
import {
  type ProjectSchema,
  type StageDefinitionSchema,
} from '@motion/zod/client'

import { StageBadge } from '~/global/components/badges'
import { useLegacyStageDefinitionsByProjectDefinitionId } from '~/global/hooks'
import { DateTime } from 'luxon'

export type ProjectDateQuickActions = {
  projectQuickActions: {
    start?: QuickAction
    deadline?: QuickAction
  }
  stageQuickActionsByStageDefinitionId: Record<
    StageDefinitionSchema['id'],
    { start: QuickAction; deadline: QuickAction }
  >
}

export type ProjectDateQuickActionInput = Pick<
  ProjectSchema,
  'startDate' | 'dueDate' | 'projectDefinitionId'
> & { stages: StageArg[] }

export function useProjectDateQuickActions(
  project: ProjectDateQuickActionInput | null | undefined
): ProjectDateQuickActions {
  const stagesDefinitions = useLegacyStageDefinitionsByProjectDefinitionId(
    project?.projectDefinitionId
  )

  if (project == null) {
    return {
      projectQuickActions: {},
      stageQuickActionsByStageDefinitionId: {},
    }
  }

  const stagesWithDates = getEnabledStagesWithDates(project.stages, {
    start: project.startDate,
    due: project.dueDate,
  })

  const projectQuickActions = {
    start:
      project.startDate != null
        ? {
            label: 'Project start',
            value: DateTime.fromISO(project.startDate),
          }
        : undefined,
    deadline:
      project.dueDate != null
        ? {
            label: 'Project deadline',
            value: DateTime.fromISO(project.dueDate),
          }
        : undefined,
  }

  const getStageQuickActionLabel = (
    stageDefinition: StageDefinitionSchema,
    startOrDeadline: 'start' | 'deadline'
  ) => (
    <span className='flex flex-row gap-1'>
      <StageBadge value={stageDefinition} hideTooltip />
      Stage {startOrDeadline}
    </span>
  )

  const stageQuickActionsByStageDefinitionId = stagesDefinitions.reduce(
    (acc, stageDefinition) => {
      const stage = stagesWithDates.find(
        (s) => s.stage.stageDefinitionId === stageDefinition.id
      )

      if (stage == null) {
        return acc
      }

      return {
        ...acc,
        [stageDefinition.id]: {
          start: {
            label: getStageQuickActionLabel(stageDefinition, 'start'),
            value: DateTime.fromISO(stage.start),
          },
          deadline: {
            label: getStageQuickActionLabel(stageDefinition, 'deadline'),
            value: DateTime.fromISO(stage.due),
          },
        },
      }
    },
    {}
  )

  return {
    projectQuickActions,
    stageQuickActionsByStageDefinitionId,
  }
}
