import { type CreateNoteRequestSchema } from '@motion/rpc-types'

import { useCreateNoteMutation } from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { useUriByRouteId } from '~/routing'
import { useCallback } from 'react'
import { useNavigate } from 'react-router'

export const useCreateNote = () => {
  const navigate = useNavigate()
  const getRouteUri = useUriByRouteId({ noDefaults: true })
  const { mutateAsync: createNote } = useCreateNoteMutation()

  return useCallback(
    async (
      params: Omit<CreateNoteRequestSchema, 'content'>,
      options: { navigate: boolean } = { navigate: false }
    ) => {
      try {
        const note = await createNote(params)
        if (options.navigate) {
          navigate(getRouteUri('notes-detail', { noteId: note.id }))
        }
      } catch (err) {
        showErrorToast('Could not create note')
      }
    },
    [createNote, getRouteUri, navigate]
  )
}
