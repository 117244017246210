import { PopoverTrigger, SearchableList } from '@motion/ui/base'
import { keys } from '@motion/utils/object'
import {
  type DefinedRelativeQueryName,
  type GroupableFieldRangeSchema,
} from '@motion/zod/client'

import { ModalFieldButton } from '~/areas/task-project/components'
import { SubItemIndentMarker } from '~/areas/tasks/modals/task-modal/components'

import { ListItem } from '../list-item'

type RangeFieldProps = {
  value: GroupableFieldRangeSchema
  setValue(value: GroupableFieldRangeSchema): void
}

type SupportedRangeNames = Exclude<
  DefinedRelativeQueryName,
  'today' | 'tomorrow' | 'yesterday'
>
const RANGE_NAMES = {
  'next-7-days': 'Next 7 days',
  'next-14-days': 'Next 14 days',
  'next-30-days': 'Next 30 days',
  'last-7-days': 'Last 7 days',
  'last-14-days': 'Last 14 days',
  'last-30-days': 'Last 30 days',
  'last-60-days': 'Last 60 days',
  'this-week': 'This week',
  'last-week': 'Last week',
  'next-week': 'Next week',
  'this-month': 'This month',
  'last-month': 'Last month',
  'next-month': 'Next month',
} satisfies Record<SupportedRangeNames, string>

export function RangeField(props: RangeFieldProps) {
  return (
    <div className='flex flex-row items-center gap-0.5'>
      <SubItemIndentMarker />

      <PopoverTrigger
        placement='bottom-start'
        renderPopover={({ close }) => (
          <SearchableList
            searchable={false}
            items={keys(RANGE_NAMES)}
            computeKey={(item) => item}
            computeSelected={(item) => item === props.value.name}
            onSelect={(item) => {
              props.setValue({ operator: 'defined-relative', name: item })
              close()
            }}
            renderItem={(item) => <ListItem name={RANGE_NAMES[item]} />}
          />
        )}
      >
        <ModalFieldButton label='Range'>
          {RANGE_NAMES[props.value.name as SupportedRangeNames]}
        </ModalFieldButton>
      </PopoverTrigger>
    </div>
  )
}
