import {
  type DeadlineInterval,
  type RelativeIntervalUnit,
  RelativeIntervalUnits,
} from '@motion/shared/flows'
import { PopoverButton, SearchableDropdown } from '@motion/ui/base'
import { type TaskDefinitionFormRelativeIntervalDuration } from '@motion/ui-logic/pm/project'

import { useMemo } from 'react'

import { getValidDurationUnits } from './utils'

export type DurationUnitDropdownProps = {
  duration: TaskDefinitionFormRelativeIntervalDuration
  onChange: (value: RelativeIntervalUnit) => void
  stageDeadlineInterval: DeadlineInterval
}

export const DurationUnitDropdown = ({
  duration,
  onChange,
  stageDeadlineInterval,
}: DurationUnitDropdownProps) => {
  const { unit } = duration

  const filteredItems = useMemo(
    () =>
      RelativeIntervalUnits.filter(
        getValidDurationUnits(stageDeadlineInterval, duration)
      ),
    [stageDeadlineInterval, duration]
  )

  return (
    <SearchableDropdown
      searchable={false}
      items={filteredItems}
      selectedItem={unit}
      onChange={onChange}
      renderItem={(item) => item.toLowerCase()}
    >
      <PopoverButton>{unit.toLowerCase()}</PopoverButton>
    </SearchableDropdown>
  )
}
