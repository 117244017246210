import { useOnce } from '@motion/react-core/hooks'
import { SYNC_SESSION_DAYS_SPAN } from '@motion/ui-logic'
import { parseDate } from '@motion/utils/dates'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { NEW_EVENT_ID } from '~/areas/calendar/utils'
import { useAutoScheduleRange } from '~/areas/project-management/hooks/experiments'
import { DateTime } from 'luxon'
import { type ReactNode } from 'react'

import { useSendScheduleAssistantCalendarContext } from '../hooks'
import { type CreateNewInitialData, type ScheduleAssistantMode } from '../types'

type ScheduleAssistantProviderProps = {
  children: ReactNode
  mode: ScheduleAssistantMode
  createNewData?: CreateNewInitialData | null
}

export function ScheduleAssistantProvider({
  children,
  mode,
  createNewData,
}: ScheduleAssistantProviderProps) {
  if (mode === 'create-new') {
    return (
      <CreateNewProvider createNewData={createNewData}>
        {children}
      </CreateNewProvider>
    )
  }

  return <SelectExistingProvider>{children}</SelectExistingProvider>
}

type CreateNewProviderProps = {
  children: ReactNode
  createNewData?: CreateNewInitialData | null
}

function CreateNewProvider({
  children,
  createNewData,
}: CreateNewProviderProps) {
  const updateCalendarState = useSendScheduleAssistantCalendarContext()

  useOnce(() => {
    const now = DateTime.now()
    // Find the next 30-minute interval
    const start = now.plus({ minutes: 30 - (now.minute % 30) })

    // Automatically create a new event when the component mounts
    let selectedCalendarEvent = {
      id: NEW_EVENT_ID,
      new: {
        allDay: false,
        start: start.toISO(),
        end: start.plus({ minutes: 30 }).toISO(),
      },
    }

    // Use the provided data if it exists
    if (createNewData != null) {
      selectedCalendarEvent = {
        id: NEW_EVENT_ID,
        new: {
          allDay: false,
          start: createNewData.start,
          end: createNewData.end,
        },
      }
    }

    updateCalendarState({
      mode: 'create-new',
      attendees: createNewData?.attendees ?? [],
      hiddenGuestUserIds: [],
      hostEmail: createNewData?.hostEmail,
      loadingMode: false,
      selectedCalendarEvent,
      selectedDate: parseDate(selectedCalendarEvent.new.start),
    })

    recordAnalyticsEvent('SCHEDULE_ASSISTANT_MODAL_OPEN', {
      type: 'create-new',
    })
  })

  return <>{children}</>
}

type SelectExistingProviderProps = {
  children: ReactNode
}

export function SelectExistingProvider({
  children,
}: SelectExistingProviderProps) {
  const updateCalendarState = useSendScheduleAssistantCalendarContext()
  const autoscheduleDaySpan = useAutoScheduleRange()

  useOnce(() => {
    updateCalendarState({
      selectedCalendarEvent: null,
      mode: 'select-existing',
      loadingMode: false,
      syncSessionRange: {
        start: DateTime.now()
          .minus({ days: SYNC_SESSION_DAYS_SPAN.before })
          .startOf('day'),
        end: DateTime.now().plus({ days: autoscheduleDaySpan }).endOf('day'),
      },
      selectedDate: DateTime.now(),
    })

    recordAnalyticsEvent('SCHEDULE_ASSISTANT_MODAL_OPEN', {
      type: 'select-existing',
    })
  })

  return <>{children}</>
}
