import { EmojiHappyPlusOutline } from '@motion/icons'
import { useMemoDeep } from '@motion/react-core/hooks'
import {
  keyIsReaction,
  type Reaction,
  REACTION_EMOJI_MAP,
} from '@motion/shared/common'
import { classed } from '@motion/theme'
import { Button, PopoverTrigger, Tooltip } from '@motion/ui/base'
import { entries } from '@motion/utils/object'
import { useAuthenticatedUser } from '@motion/web-common/auth'
import type { CommentSchema } from '@motion/zod/client'

import { useWorkspaceActiveMembers } from '~/global/hooks'
import { useAddReaction, useRemoveReaction } from '~/global/rpc/v2/comments'
import { useCallback } from 'react'

import { CommentIconButton } from './comment-icon-button'

import { useActivityFeedContext } from '../../providers'

interface ReactionRowProps {
  comment: CommentSchema
}

export const Reactions = ({ comment }: ReactionRowProps) => {
  const { workspaceId } = useActivityFeedContext()
  const { uid: userId } = useAuthenticatedUser()
  const { mutate: addReaction } = useAddReaction()
  const { mutate: removeReaction } = useRemoveReaction()

  const reactionsMemo = useMemoDeep(comment.reactions)
  const members = useWorkspaceActiveMembers(workspaceId)

  const handleReactionClick = useCallback(
    (reaction: Reaction) => {
      if (reactionsMemo[reaction]?.includes(userId)) {
        removeReaction({
          commentId: comment.id,
          targetId: comment.targetId,
          userId: userId,
          value: reaction,
        })
      } else {
        addReaction({
          commentId: comment.id,
          targetId: comment.targetId,
          userId: userId,
          value: reaction,
        })
      }
    },
    [
      addReaction,
      comment.id,
      reactionsMemo,
      comment.targetId,
      removeReaction,
      userId,
    ]
  )

  return (
    <div className='flex gap-1 items-center'>
      {entries(reactionsMemo)
        .filter((entry): entry is [Reaction, string[]] => {
          const [reaction, userIds] = entry
          return (
            keyIsReaction(reaction) &&
            Array.isArray(userIds) &&
            userIds.length > 0
          )
        })
        .map(([reaction, userIds]) => {
          const userNames = members
            .filter((member) => userIds?.includes(member.user.id))
            .map((member) => member.user.name)

          const others = userIds.length - userNames.length

          const tooltipContent = `${userNames.join(', ')}${
            others > 0 ? ` and ${others} others` : ''
          } reacted with ${REACTION_EMOJI_MAP.get(reaction)}`

          return (
            <Tooltip content={tooltipContent} key={`tooltip:${reaction}`}>
              <ActiveReactionButton
                type='button'
                mine={userIds.includes(userId)}
                key={reaction}
                onClick={() => {
                  handleReactionClick(reaction)
                }}
              >
                {REACTION_EMOJI_MAP.get(reaction)} {userIds?.length}
              </ActiveReactionButton>
            </Tooltip>
          )
        })}
      <PopoverTrigger
        placement='top-start'
        renderPopover={({ close }) => (
          <>
            {Array.from(REACTION_EMOJI_MAP.entries()).map(
              ([reaction, emoji]) => (
                <Button
                  sentiment='neutral'
                  variant='muted'
                  key={emoji}
                  size='small'
                  onClick={() => {
                    handleReactionClick(reaction as Reaction)
                    close()
                  }}
                >
                  {emoji}
                </Button>
              )
            )}
          </>
        )}
      >
        <CommentIconButton type='button'>
          <EmojiHappyPlusOutline />
        </CommentIconButton>
      </PopoverTrigger>
    </div>
  )
}

const ActiveReactionButton = classed('button', {
  base: `
    rounded-[100px]
    outline-1 outline
    
    px-[6px] py-[2px]

    font-semibold

    text-xs

  `,
  variants: {
    mine: {
      true: `
        text-semantic-primary-text-default        
        outline-semantic-primary-border-hover
        bg-button-primary-outlined-bg-default
        hover:outline-semantic-primary-border-active
        focus:outline-semantic-primary-border-active
      `,
      false: `
        text-semantic-neutral-text-subtle
        outline-button-neutral-outlined-border-default
        bg-button-neutral-outlined-bg-default

        hover:outline-button-neutral-outlined-border-hover
        hover:bg-button-neutral-outlined-bg-hover

        focus:outline-button-neutral-outlined-border-focus        
      `,
    },
  },
  defaultVariants: {
    mine: true,
  },
})
