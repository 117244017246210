type RecordingPlayerProps = {
  videoUrl: string
}

export function RecordingPlayer({ videoUrl }: RecordingPlayerProps) {
  return (
    <div className='overflow-hidden rounded-md pb-[56.25%] relative'>
      <video src={videoUrl} controls className='size-full absolute' />
    </div>
  )
}
