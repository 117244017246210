import { DotsHorizontalSolid, Expand2Outline, PencilSolid } from '@motion/icons'
import { ActionList, IconButton, PopoverTrigger } from '@motion/ui/base'
import { useModalApi } from '@motion/web-common/modals'
import { type DashboardViewCellSchema } from '@motion/zod/client'

import { useCallback, useMemo } from 'react'

import { useRemoveChartCell } from '../../modals/chart-modal/hooks/use-remove-chart'

export type ControlsProps = {
  cell: DashboardViewCellSchema
}

export function Controls({ cell }: ControlsProps) {
  const modalApi = useModalApi()
  const isNarrow = cell.width < 4

  const handleExpand = useCallback(
    () => modalApi.open('expanded-chart-modal', { cell }),
    [cell, modalApi]
  )
  const handleEdit = useCallback(
    () => modalApi.open('chart-modal', { mode: 'edit', cell }),
    [cell, modalApi]
  )

  return (
    <div className='flex items-center gap-2'>
      {!isNarrow && (
        <>
          <IconButton
            size='xsmall'
            icon={Expand2Outline}
            sentiment='neutral'
            variant='muted'
            onClick={handleExpand}
          />

          <IconButton
            size='xsmall'
            icon={PencilSolid}
            sentiment='neutral'
            variant='muted'
            onClick={handleEdit}
          />
        </>
      )}

      <PopoverTrigger
        placement='bottom-end'
        renderPopover={({ close }) => (
          <ChartActionItems
            close={close}
            cell={cell}
            showExpandEdit={isNarrow}
            onExpand={handleExpand}
            onEdit={handleEdit}
          />
        )}
      >
        <IconButton
          size='xsmall'
          icon={DotsHorizontalSolid}
          sentiment='neutral'
          variant='muted'
        />
      </PopoverTrigger>
    </div>
  )
}

type ChartActionItemsProps = {
  close: () => void
  cell: DashboardViewCellSchema
  showExpandEdit: boolean
  onExpand: () => void
  onEdit: () => void
}

function ChartActionItems({
  close,
  cell,
  showExpandEdit,
  onExpand,
  onEdit,
}: ChartActionItemsProps) {
  const removeChart = useRemoveChartCell()

  const sections = useMemo(
    () =>
      [
        ...(showExpandEdit
          ? [
              {
                items: [
                  {
                    content: 'Expand chart',
                    onAction: onExpand,
                  },
                  {
                    content: 'Edit chart',
                    onAction: onEdit,
                  },
                ],
              },
            ]
          : []),
        {
          items: [
            {
              content: 'Remove chart',
              destructive: true,
              onAction: () => {
                removeChart(cell.id)
              },
            },
          ],
        },
      ].filter(Boolean),
    [showExpandEdit, cell.id, onExpand, onEdit, removeChart]
  )

  return <ActionList onActionAnyItem={close} sections={sections} />
}
