import { RecordingGradientSolid } from '@motion/icons'
import { classed } from '@motion/theme'

import { type ReactNode } from 'react'

import { ConnectedGroupingButton } from './connected-grouping-button'
import { ConnectedSortButton } from './connected-sort-button'

export function ConnectedHeader() {
  return (
    <HeaderBar>
      <PaddedContainer>
        <TitleBarTitle>Meeting Recordings</TitleBarTitle>
      </PaddedContainer>
      <PaddedContainer className='flex flex-col gap-2'>
        <div className='flex items-center gap-2'>
          <ConnectedGroupingButton />
          <ConnectedSortButton />
        </div>
      </PaddedContainer>
    </HeaderBar>
  )
}

const HeaderBar = classed('div', {
  base: `
  flex flex-col gap-3
  py-4
  `,
})

const PaddedContainer = classed('div', 'px-4')

type TitleBarTitleProps = {
  children?: ReactNode
}

const TitleBarTitle = (props: TitleBarTitleProps) => {
  return (
    <TitleBarHeader>
      <div className='inline-flex flex-row gap-1.5 items-center'>
        <RecordingGradientSolid className='size-4' />

        {props.children}
      </div>
    </TitleBarHeader>
  )
}

export const TitleBarHeader = classed('h2', {
  base: `
    text-semantic-neutral-text-default
    font-semibold
    text-base
  `,
})
