import type FullCalendar from '@fullcalendar/react'
import { useAgendaCalendarState } from '~/areas/agenda/hooks/use-agenda-calendar-state'
import { Calendar } from '~/areas/calendar/components/calendar'
import { useCalendarState } from '~/areas/calendar/hooks'
import { microTask } from '~/areas/calendar/utils/micro-task'
import { memo, useEffect, useRef } from 'react'

import { useAgendaSidebarCalendarProps } from '../../hooks'

export const AgendaSidebarCalendar = memo(function AgendaSidebarCalendar() {
  const calendarRef = useRef<FullCalendar | null>(null)

  const [agendaCalendarState] = useAgendaCalendarState()
  const selectedCalendarEvent = useCalendarState(
    (data) => data.selectedCalendarEvent
  )
  const calendarProps = useAgendaSidebarCalendarProps()

  useEffect(() => {
    if (!selectedCalendarEvent) return
    // Micro task is needed due to a rendering quirk of FullCalendar, should be fixed in newer versions.
    microTask(() => {
      calendarRef.current?.getApi().unselect()
    })
  }, [selectedCalendarEvent])

  useEffect(
    function handleBaseDateChange() {
      const baseDate = agendaCalendarState.selectedDate.toJSDate()
      // Micro task is needed due to a rendering quirk of FullCalendar, should be fixed in newer versions.
      microTask(() => {
        calendarRef.current?.getApi().gotoDate(baseDate)
      })
    },
    [agendaCalendarState.selectedDate]
  )

  return <Calendar ref={calendarRef} calendarProps={calendarProps} />
})
