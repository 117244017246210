import { useClosure } from '@motion/react-core/hooks'
import {
  createMergeReducer,
  createStateKeyFilter,
  on,
  SharedStateProvider,
} from '@motion/react-core/shared-state'
import { ActiveFilterKey, type FilterTarget } from '@motion/ui-logic/pm/data'
import { type ViewDefinitionFiltersSchema } from '@motion/zod/client'

import { fromViewDefinitionFiltersToFilterState } from '~/areas/project-management/pages/pm-v3/views'
import { type ReactNode } from 'react'

export type ChartActiveFilterContextProps = {
  name?: string
  children: ReactNode
  refreshKey?: string
  filters: ViewDefinitionFiltersSchema
  target: FilterTarget
}

export const ChartActiveFilterContext = ({
  name,
  children,
  refreshKey,
  target,
  filters,
}: ChartActiveFilterContextProps) => {
  const merge = useClosure(
    createMergeReducer(
      on(
        ActiveFilterKey,
        // TODO: custom fields won't work because it's not typed the same
        (value) => fromViewDefinitionFiltersToFilterState(target, filters)
      )
    )
  )

  return (
    <SharedStateProvider
      name={name}
      merge={merge}
      filter={onlyActiveFilter}
      refreshKey={refreshKey}
      batchTime={0}
    >
      {children}
    </SharedStateProvider>
  )
}

const onlyActiveFilter = createStateKeyFilter({
  include: [ActiveFilterKey],
})
