import type { Inclusion } from '@motion/rpc-types'

import type { ConditionOption } from '~/areas/project-management/filters/components/v2'

export const INCLUSION_CONDITIONS = [
  { label: 'Include', id: 'include' },
  { label: 'Exclude', id: 'exclude' },
  { label: 'Only', id: 'only' },
] satisfies ConditionOption<Inclusion>[]

export const getConditionLabel = (condition: Inclusion | null) =>
  INCLUSION_CONDITIONS.find((c) => c.id === condition)?.label ??
  INCLUSION_CONDITIONS[0].label
