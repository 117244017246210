import { defineApi } from '@motion/rpc'
import {
  type MeetingInsightsQueryResponseSchema,
  type MeetingInsightsRequestQuerySchema,
} from '@motion/rpc-types'

export const meetingInsightsQueryKey = {
  root: ['meeting-insights', 'query'] as const,
}

export const getMeetingInsightsQuery = defineApi<
  MeetingInsightsRequestQuerySchema,
  MeetingInsightsQueryResponseSchema
>().using({
  key: (args) => ['meeting-insights', 'query', args],
  method: 'POST',
  uri: `${import.meta.env.MOTION_AI_API_HOST}/meeting-insights/query`,
})
