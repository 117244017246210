import { CheckSolid } from '@motion/icons'
import { Button, Tag } from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'
import { MAX_PRO_TEAM_INITIAL_SIZE, PlanNames } from '@motion/ui-logic/billing'

import { twMerge } from 'tailwind-merge'

import { useShouldShowSalesTaxMessage } from '../../hooks'

export type BaseBillingPlanCardProps = {
  subtitle: string
  sectionHeader?: string
  items: string[]
  onClick: () => void
  isTeam: boolean
  price?: number
  savingsDescription?: string
  buttonText?: string
  planType: 'Individual' | 'Team' | 'Enterprise'
  withBorder?: boolean
  isSecondary?: boolean
  hidePrice?: boolean
}

export const BaseBillingPlanCard = ({
  subtitle,
  sectionHeader,
  items,
  onClick,
  isTeam,
  price,
  savingsDescription,
  buttonText,
  planType,
  withBorder,
  isSecondary,
  hidePrice,
}: BaseBillingPlanCardProps) => {
  const shouldShowSalesTaxMessage = useShouldShowSalesTaxMessage()

  return (
    <div
      className={twMerge(
        'flex flex-col flex-1 max-w-[500px] min-w-[300px] justify-top rounded bg-semantic-neutral-bg-default p-6 drop-shadow-md',
        withBorder && 'border border-semantic-neutral-border-default'
      )}
    >
      <div className='pb-8'>
        <div className='flex flex-row w-full justify-between mb-2'>
          <p className='text-semantic-neutral-text-default text-lg font-bold'>
            {PlanNames[planType]}
          </p>
          {isTeam && savingsDescription && (
            <Tag size='small' color='green' variant='subtle'>
              <strong>{savingsDescription}</strong>
            </Tag>
          )}
        </div>
        <p
          className={twMerge(
            'text-semantic-neutral-text-subtle lg:mb-4 text-sm'
          )}
        >
          {subtitle}
        </p>
        <p className='pb-2'>
          {hidePrice ? null : price ? (
            <>
              <span className='text-semantic-neutral-text-default text-xl font-bold'>
                ${price}
              </span>
              <span className='text-semantic-neutral-text-subtle text-sm font-normal'>
                {templateStr(' per month{{userStr}}{{applicableTax}}', {
                  userStr: isTeam ? ' per user' : '',
                  applicableTax: shouldShowSalesTaxMessage
                    ? ' (plus applicable taxes)'
                    : '',
                })}
              </span>
            </>
          ) : (
            <>
              <span className='text-semantic-neutral-text-default text-xl font-bold pr-1'>
                Contact us
              </span>
              <span className='text-semantic-neutral-text-subtle text-sm font-normal'>
                {templateStr('({{maxSize}} seat minimum)', {
                  maxSize: MAX_PRO_TEAM_INITIAL_SIZE,
                })}
              </span>
            </>
          )}
        </p>
        <Button
          fullWidth
          onClick={onClick}
          sentiment={isSecondary ? 'neutral' : 'primary'}
          data-testid={`choose-plan-${planType}`}
        >
          {buttonText ? buttonText : `Continue as ${planType}`}
        </Button>
      </div>
      {sectionHeader && (
        <p className='text-semantic-neutral-text-subtle mb-2 text-sm font-normal'>
          {sectionHeader}
        </p>
      )}
      <div className='flex mb-6 flex-col gap-2'>
        {items.map((item) => (
          <div key={item} className='flex gap-2 place-content-start'>
            <CheckSolid className='text-semantic-primary-icon-default h-4 w-4 shrink-0 mt-[2px]' />
            <p className='text-semantic-neutral-text-subtle mb-0 text-sm font-normal'>
              {item}
            </p>
          </div>
        ))}
      </div>
    </div>
  )
}
