import { type ContactRecord } from '@motion/rpc/types'

import { createSlice } from '@reduxjs/toolkit'

import { searchContacts } from './calendar/contacts-thunks'
import { type LoadingState } from './projectManagementSlice'
import type { RootState } from './types'

export interface Accounts {
  searchContacts: ContactRecord
  searchContactsLoadingState: LoadingState
}

const initialState: Accounts = {
  searchContacts: {},
  searchContactsLoadingState: 'preload',
}

export const accountsSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(searchContacts.pending, (state) => {
      state.searchContactsLoadingState = 'loading'
    })
    builder.addCase(searchContacts.fulfilled, (state, action) => {
      state.searchContactsLoadingState = 'loaded'
      const newContacts = { ...state.searchContacts, ...action.payload }
      // Add the search id to the contacts so that we can tell if the user selected a contact
      // from the search results or from what's already in the store.
      for (const key in newContacts) {
        if (!newContacts[key].searchId) {
          newContacts[key].searchId = action.meta.arg.uniqueId
        }
      }
      state.searchContacts = newContacts
    })
  },
  initialState,
  name: 'accounts',
  reducers: {
    reset: () => initialState,
  },
})

export const { reset } = accountsSlice.actions

export const selectSearchContacts = (state: RootState) =>
  state.accounts.searchContacts
export const selectSearchContactsLoadingState = (state: RootState) =>
  state.accounts.searchContactsLoadingState

export const accountsReducer = accountsSlice.reducer
