import { Button } from '@motion/ui/base'

import { useFlowTemplateModalUrl } from '~/areas/flows'
import { StageLabel } from '~/global/components/labels'

import { CardDescription, CardTitle, EmptyTableCard } from './styled'

type NewFlowTemplateCardProps = {
  workspaceId: string
}
export function NewStageTemplateCard({
  workspaceId,
}: NewFlowTemplateCardProps) {
  const buildFlowTemplateModalUrl = useFlowTemplateModalUrl()

  // TODO kenl: replace with stage modal url once that's built
  const newFlowTemplateUrl = buildFlowTemplateModalUrl({
    template: 'create',
    forWorkspace: workspaceId,
  })

  return (
    <EmptyTableCard>
      <div className='flex flex-row items-center gap-1'>
        <StageLabel
          value={{
            name: 'Initiation',
            color: 'blue',
          }}
          size='small'
        />
        <StageLabel
          value={{
            name: 'Execution',
            color: 'yellow',
          }}
          size='small'
        />
        <StageLabel
          value={{
            name: 'Delivery',
            color: 'green',
          }}
          size='small'
        />
      </div>
      <CardTitle>Create your first stage</CardTitle>
      <CardDescription>
        Every project can be broken down into stages, such as Initiation,
        Execution, and Delivery. By organizing work in stages, you ensure that
        projects move forward smoothly through each phase of the lifecycle.
      </CardDescription>
      <Button size='small' url={newFlowTemplateUrl}>
        Create stage
      </Button>
    </EmptyTableCard>
  )
}
