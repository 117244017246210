import { type DateFilterSchema } from '@motion/zod/client'

import { FilterPageFormat } from './format'
import { DateFilterQueryPicker } from './modal'

import { type DropdownFilterProps } from '../../../types'
import { FilterItem } from '../filter-item'

export type DateFilterProps = Pick<
  DropdownFilterProps,
  'onBlur' | 'openOnMount'
> & {
  label: string
  value: DateFilterSchema | null
  onChanged(value: DateFilterSchema | null): void
}

export const DateFilter = (props: DateFilterProps) => {
  const value = props.value ?? {
    operator: 'defined-relative',
    name: 'this-week',
  }

  return (
    <FilterItem
      label={props.label}
      canInvert
      inverted={value?.inverse}
      onInvertChanged={(invert) => {
        if (value == null) return
        props.onChanged({ ...value, inverse: invert })
      }}
      onBlur={props.onBlur}
      openOnMount={props.openOnMount}
      onDismiss={() => props.onChanged(null)}
      renderDropdown={({ close }) => {
        return (
          <DateFilterQueryPicker
            value={value}
            onSelect={(newValue) => {
              props.onChanged(newValue)
              close()
            }}
          />
        )
      }}
    >
      <FilterPageFormat value={value} />
    </FilterItem>
  )
}
