import type {
  DeadlineInterval,
  RelativeIntervalReferenceType,
} from '@motion/shared/flows'
import {
  convertDateIntervalToDays,
  convertDaysToDeadlineInterval,
} from '@motion/shared/flows'
import { Sentry } from '@motion/web-base/sentry'

export type AdjustmentParams<T extends DeadlineInterval> = {
  startDuration: T
  dueDuration: T
  startReferenceType?: RelativeIntervalReferenceType
  dueReferenceType?: RelativeIntervalReferenceType
  lock?: 'start' | 'due'
}

export type TaskDefinitionRelativeDurationAdjustment<
  T extends DeadlineInterval,
> = {
  start?: T
  due?: T
}

/**
 * Calculates new relative durations when stage duration changes to ensure they stay within bounds
 */
export function calculateAdjustedRelativeDurations<T extends DeadlineInterval>(
  stageDurationInDays: number,
  params: AdjustmentParams<T>
): TaskDefinitionRelativeDurationAdjustment<T> | null {
  const {
    startDuration,
    dueDuration,
    startReferenceType,
    dueReferenceType,
    lock,
  } = params
  const startDays = convertDateIntervalToDays(startDuration)
  const dueDays = convertDateIntervalToDays(dueDuration)

  // Handle cases where reference types are the same
  if (
    startReferenceType &&
    dueReferenceType &&
    startReferenceType === dueReferenceType
  ) {
    return handleSameReferenceTypes({
      startDays,
      dueDays,
      stageDurationInDays,
      referenceType: startReferenceType,
      lock,
    })
  }

  if (
    startReferenceType === 'STAGE_DUE' &&
    dueReferenceType === 'STAGE_START'
  ) {
    return handleStartDueFlipped({
      startDays,
      dueDays,
      stageDurationInDays,
      lock,
    })
  }

  // Handle cases where dates exceed stage duration
  if (startDays + dueDays > stageDurationInDays) {
    return handleExceedingStageDuration({
      startDays,
      dueDays,
      stageDurationInDays,
      lock,
    })
  }

  return null
}

type ReferenceTypeParams = {
  startDays: number
  dueDays: number
  stageDurationInDays: number
  referenceType: RelativeIntervalReferenceType
  lock?: 'start' | 'due'
}

function handleSameReferenceTypes<T extends DeadlineInterval>({
  startDays,
  dueDays,
  stageDurationInDays,
  referenceType,
  lock,
}: ReferenceTypeParams): TaskDefinitionRelativeDurationAdjustment<T> | null {
  // Handle STAGE_DUE reference type
  if (referenceType === 'STAGE_DUE' && dueDays > startDays) {
    if (lock === 'due') {
      return { start: convertDaysToDeadlineInterval(dueDays) as T }
    }
    if (lock === 'start') {
      return { due: convertDaysToDeadlineInterval(startDays) as T }
    }
  }

  // Handle STAGE_START reference type
  if (referenceType === 'STAGE_START' && startDays > dueDays) {
    if (lock === 'start') {
      return { due: convertDaysToDeadlineInterval(startDays) as T }
    }
    if (lock === 'due') {
      return { start: convertDaysToDeadlineInterval(dueDays) as T }
    }
  }

  // Handle cases where dates exceed stage duration
  const adjustment: TaskDefinitionRelativeDurationAdjustment<T> = {}

  if (startDays > stageDurationInDays) {
    adjustment.start = convertDaysToDeadlineInterval(stageDurationInDays) as T
  }
  if (dueDays > stageDurationInDays) {
    adjustment.due = convertDaysToDeadlineInterval(stageDurationInDays) as T
  }

  return Object.keys(adjustment).length > 0 ? adjustment : null
}

type StageDurationParams = {
  startDays: number
  dueDays: number
  stageDurationInDays: number
  lock?: 'start' | 'due'
}

function handleExceedingStageDuration<T extends DeadlineInterval>({
  startDays,
  dueDays,
  stageDurationInDays,
  lock,
}: StageDurationParams): TaskDefinitionRelativeDurationAdjustment<T> {
  if (startDays + dueDays <= stageDurationInDays) {
    Sentry.captureException(
      new Error('Invalid call to calculateAdjustedRelativeDurations'),
      {
        extra: {
          startDays,
          dueDays,
          stageDurationInDays,
        },
      }
    )
    return {
      start: convertDaysToDeadlineInterval(0) as T,
      due: convertDaysToDeadlineInterval(0) as T,
    }
  }

  // Handle locked start
  if (lock === 'start') {
    const adjustedDueDays = Math.floor(stageDurationInDays - startDays)
    return { due: convertDaysToDeadlineInterval(adjustedDueDays) as T }
  }

  // Handle locked due
  if (lock === 'due') {
    const adjustedStartDays = Math.floor(stageDurationInDays - dueDays)
    return { start: convertDaysToDeadlineInterval(adjustedStartDays) as T }
  }

  // Scale both proportionally if neither is locked
  const totalDays = startDays + dueDays
  const scaleFactor = stageDurationInDays / totalDays
  const adjustedStartDays = Math.floor(startDays * scaleFactor)
  const adjustedDueDays = Math.floor(dueDays * scaleFactor)

  return {
    start: convertDaysToDeadlineInterval(adjustedStartDays) as T,
    due: convertDaysToDeadlineInterval(adjustedDueDays) as T,
  }
}

function handleStartDueFlipped<T extends DeadlineInterval>({
  startDays,
  dueDays,
  stageDurationInDays,
  lock,
}: StageDurationParams): TaskDefinitionRelativeDurationAdjustment<T> | null {
  const startDaysParsed = Math.floor(stageDurationInDays - startDays)

  if (startDaysParsed > dueDays) {
    if (lock === 'start') {
      return { due: convertDaysToDeadlineInterval(startDaysParsed) as T }
    }
    if (lock === 'due') {
      const adjustedStartDays = Math.floor(stageDurationInDays - dueDays)
      return { start: convertDaysToDeadlineInterval(adjustedStartDays) as T }
    }

    // Scale both proportionally if neither is locked
    const totalDays = startDaysParsed + dueDays
    const scaleFactor = stageDurationInDays / totalDays
    const adjustedStartDays = Math.floor(startDays * scaleFactor)

    const adjustedDueDays = Math.floor(dueDays * scaleFactor)
    const newAdjustedStartDay = Math.min(
      Math.floor(stageDurationInDays - adjustedStartDays),
      Math.floor(stageDurationInDays - adjustedDueDays)
    )
    return {
      start: convertDaysToDeadlineInterval(newAdjustedStartDay) as T,
      due: convertDaysToDeadlineInterval(adjustedDueDays) as T,
    }
  }

  return null
}
