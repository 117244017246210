import { isStageCanceled } from '@motion/ui-logic/pm/project'
import { daysBetweenDates } from '@motion/utils/dates'
import type { ProjectSchema, StageSchema } from '@motion/zod/client'

import { DateTime } from 'luxon'

// Given the last stage estimated completion time and the projected date,
// calculate the number of days to distribute to all the active stages (on top of their estimated completion time)
function daysToAddToActiveStages(
  projectedEstimatedCompletionDate: DateTime | null,
  selectedProjectedCompletionDate: DateTime | null
) {
  if (
    projectedEstimatedCompletionDate == null ||
    selectedProjectedCompletionDate == null
  )
    return 0

  const daysToDistribute = daysBetweenDates(
    selectedProjectedCompletionDate,
    projectedEstimatedCompletionDate
  )

  return Math.floor(daysToDistribute)
}

export function resolveStageProjectedDate(
  stages: StageSchema[],
  stageIndex: number,
  // The date the project is projected to be completed
  selectedProjectedCompletionDate: DateTime | null,
  // The date the project is estimated to be completed
  projectEstimatedCompletionDate: string | null
): string {
  const estimatedCompletionDate = projectEstimatedCompletionDate
    ? DateTime.fromISO(projectEstimatedCompletionDate)
    : null

  const stage = stages[stageIndex]
  const previousStage = stageIndex - 1 >= 0 ? stages[stageIndex - 1] : null

  if (stage.estimatedCompletionTime != null && !isStageCanceled(stage)) {
    const daysToAdd = daysToAddToActiveStages(
      estimatedCompletionDate,
      selectedProjectedCompletionDate
    )

    return DateTime.fromISO(stage.estimatedCompletionTime)
      .plus({
        days: daysToAdd,
      })
      .toISODate()
  }

  if (isStageCanceled(stage)) {
    return stage.estimatedCompletionTime ?? stage.dueDate
  }

  if (previousStage != null && previousStage.estimatedCompletionTime != null) {
    return previousStage.estimatedCompletionTime
  }

  return stage.dueDate
}

// Given a project and a projected completion date, calculate the projected date for each stage (new due dates)
// Ensuring that a stage's due date is not before any of the previous stage's due date
export function resolveAllStageProjectedDates(
  stages: StageSchema[],
  project: ProjectSchema,
  selectedProjectedCompletionDate: DateTime | null
): {
  stageId: string
  stageDefinitionId: string
  dueDate: string
}[] {
  if (stages.length === 0) {
    return []
  }

  // Start with either project start date, first stage due date, or current date
  let latestDueDate = DateTime.fromISO(
    project.startDate ?? stages[0].dueDate ?? DateTime.now().toISODate()
  )

  return stages.map((stage, index) => {
    // Get the projected date for this stage
    const projectedDate = resolveStageProjectedDate(
      stages,
      index,
      selectedProjectedCompletionDate,
      project.estimatedCompletionTime
    )

    // Convert projected date to DateTime for comparison
    const projectedDateTime = projectedDate
      ? DateTime.fromISO(projectedDate)
      : null

    // Ensure this stage's due date is not before the previous stage
    // by taking the later of the projected date or latest due date seen
    if (projectedDateTime != null) {
      latestDueDate =
        projectedDateTime > latestDueDate ? projectedDateTime : latestDueDate
    }

    return {
      stageId: stage.id,
      stageDefinitionId: stage.stageDefinitionId,
      dueDate: latestDueDate.toISODate(),
    }
  })
}
