import { type CalendarStartDay } from '@motion/ui-logic'
import {
  buildDateFilterQuery,
  buildRelativeDateQuery,
  buildRelativeRangeDateQuery,
  isLogicalFilter,
} from '@motion/ui-logic/pm/data'
import {
  formatToReadableWeekDayMonth,
  templateStr,
} from '@motion/ui-logic/utils'
import { isSameDay } from '@motion/utils/dates'
import { type DateFilterSchema } from '@motion/zod/client'

import {
  LogicalFormats,
  RelativeFormats,
  RelativeQueryFormats,
} from './constants'
import { parseRelativeDate } from './utils'

type FormatFilterProps = {
  value: DateFilterSchema | null | undefined
  seperator?: string
}

export function DisplayValue(props: FormatFilterProps) {
  return (
    <div className='text-sm text-semantic-neutral-text-subtle flex justify-center'>
      <FormatFilter seperator='-' {...props} />
    </div>
  )
}

export function FormatFilter(props: FormatFilterProps) {
  const { value, seperator = 'and' } = props
  const normalized = buildDateFilterQuery(value)
  if (normalized == null) return null
  if (normalized.operator === 'defined' || normalized.operator === 'empty')
    return null

  if (typeof normalized.value === 'string') {
    return formatToReadableWeekDayMonth(normalized.value)
  }

  if (
    normalized.operator === 'range' &&
    isSameDay(normalized.value.from, normalized.value.to)
  ) {
    return formatToReadableWeekDayMonth(normalized.value.from)
  }

  return (
    <div className='flex gap-1'>
      <span>{formatToReadableWeekDayMonth(normalized.value.from)}</span>
      <span className='text-semantic-neutral-text-subtle'>{seperator}</span>
      <span>{formatToReadableWeekDayMonth(normalized.value.to)}</span>
    </div>
  )
}

type FilterPageFormatProps = {
  value: DateFilterSchema
  startDay?: CalendarStartDay
}

export function FilterPageFormat(props: FilterPageFormatProps) {
  const { value, startDay } = props

  if (isLogicalFilter(value)) {
    return templateStr(LogicalFormats[value.operator], {
      value: formatToReadableWeekDayMonth(value.value),
    })
  }

  if (value.operator === 'defined-relative') {
    const normalized = buildRelativeRangeDateQuery(value, startDay)
    return templateStr(RelativeQueryFormats[value.name], {
      from: formatToReadableWeekDayMonth(normalized.value.from),
      to: formatToReadableWeekDayMonth(normalized.value.to),
    })
  }

  if (value.operator === 'range') {
    return templateStr('between {{from}} and {{to}}', {
      from: formatToReadableWeekDayMonth(value.value.from),
      to: formatToReadableWeekDayMonth(value.value.to),
    })
  }

  if (value.operator === 'relative') {
    const info = parseRelativeDate(value)
    const normalized = buildRelativeDateQuery(value)
    return templateStr(RelativeFormats[info.direction], {
      value: info.value,
      unit: info.unit,
      from: formatToReadableWeekDayMonth(normalized.value.from),
      to: formatToReadableWeekDayMonth(normalized.value.to),
    })
  }

  if (value.operator === 'empty') {
    return 'none'
  }

  return 'unknown'
}
