import { useContext } from 'react'

import { CreateNewActionItemContext } from '../context/create-new-action-item-context'

export function useCreateNewActionItemContext() {
  const context = useContext(CreateNewActionItemContext)
  if (!context) {
    throw new Error(
      'useCreateNewActionItemContext must be used within a CreateNewActionItemProvider'
    )
  }
  return context
}
