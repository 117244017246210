import { WorkspaceSolid } from '@motion/icons'
import { useSharedState } from '@motion/react-core/shared-state'
import { Button, SearchableList } from '@motion/ui/base'
import { byProperty, Compare } from '@motion/utils/array'

import { WorkspaceLabel } from '~/global/components/labels'
import { AppWorkspaceContext } from '~/global/contexts'
import { useMemo } from 'react'

import { FilterItem } from './filter-item/filter-item'

import { useFieldFilter } from '../../../context'
import { buildIdFilter } from '../../../utils'
import { MultiSelectFilterValue } from '../../multi-select-filter-value'
import { type DropdownFilterProps } from '../../types'

export const ConnectedWorkspaceFilter = ({
  onBlur,
  openOnMount,
}: DropdownFilterProps) => {
  const [value, setValue] = useFieldFilter('workspaces', 'ids')
  const ids = value?.value ?? null

  return (
    <FilterItem
      label='Workspace'
      canInvert
      inverted={value?.inverse ?? false}
      onInvertChanged={(invert) => {
        if (value == null) return
        setValue({ ...value, inverse: invert })
      }}
      onBlur={onBlur}
      openOnMount={openOnMount}
      onDismiss={() => setValue(null)}
      renderDropdown={({ close }) => (
        <ConnectedWorkspaceDropdown close={close} />
      )}
    >
      <MultiSelectFilterValue
        contextScope='shell'
        Icon={WorkspaceSolid}
        type='workspaces'
        ids={ids}
      />
    </FilterItem>
  )
}

type ConnectedWorkspaceDropdownProps = {
  close: () => void
}
export const ConnectedWorkspaceDropdown = (
  props: ConnectedWorkspaceDropdownProps
) => {
  const [ctx] = useSharedState(AppWorkspaceContext, { scope: 'shell' })
  const [filter, setFilter] = useFieldFilter('workspaces', 'ids')

  const workspaces = useMemo(() => {
    return ctx.workspaces.all.sort(byProperty('name', Compare.string))
  }, [ctx.workspaces.all])

  return (
    <div>
      <SearchableList
        inputProps={{ placeholder: 'Select workspaces...' }}
        itemType='checkbox'
        items={workspaces}
        renderItem={(item) => <WorkspaceLabel value={item} />}
        computeSearchValue={(workspace) => workspace.name}
        computeSelected={(item) => filter?.value?.includes(item.id) ?? false}
        onSelect={(values) => {
          setFilter(buildIdFilter(values, filter))
        }}
        computeKey={(item) => item.id}
      />
      <div className='border-t border-dropdown-border p-2 flex justify-between'>
        <Button
          onClick={() => {
            setFilter(null)
            props.close()
          }}
          variant='outlined'
          sentiment='neutral'
          size='small'
        >
          Clear
        </Button>
        <Button
          onClick={() => {
            setFilter(buildIdFilter(workspaces, filter))
          }}
          variant='outlined'
          sentiment='neutral'
          size='small'
        >
          Select all
        </Button>
      </div>
    </div>
  )
}
