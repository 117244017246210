import { type TaskSchema } from '@motion/rpc-types'
import { isCanceledStatus, isCompletedStatus } from '@motion/shared/common'

import { useWorkspaceStatuses } from '~/global/hooks'

export function useFilterActiveTasks(tasks: TaskSchema[]) {
  const workspaceId = tasks.length > 0 ? tasks[0].workspaceId : undefined

  const workspaceStatuses = useWorkspaceStatuses(workspaceId)

  return tasks.filter((task) => {
    const status = workspaceStatuses.find(
      (status) => status.id === task.statusId
    )

    // If status is not found, consider it active
    if (status == null) return true

    // If task is completed or canceled, it's not active
    return !isCompletedStatus(status) && !isCanceledStatus(status)
  })
}
