/**
 * Use this for elements that aren't guaranteed to be unique on a page. For example, things like <Tag />, <Button />, etc.
 *
 * @example
 * <div {...addComponentName('MyComponent')}>...</div>
 */
export const addComponentName = (name: string) => {
  return __IS_PROD__ ? {} : { 'data-component-name': name }
}

/**
 * Use this for elements that are guaranteed to be unique on a page. Example: the Bulk Ops bottom section.
 *
 * @example
 * <div {...addTestId('MyComponent')}>...</div>
 */
export const addTestId = (id: string) => {
  return __IS_PROD__ ? {} : { 'data-testid': id }
}
