import { useMemo } from 'react'

import { groupByDate, groupByHost } from './definitions'

// Overkill by might need to plug data into the groups
export function useAvailableGroups() {
  return useMemo(() => [...groups], [])
}

const groups = [groupByDate(), groupByHost()]
