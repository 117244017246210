import { PendingStatusSolid } from '@motion/icons'
import { isNoneId } from '@motion/shared/identifiers'
import { Button, ButtonGroup, Tooltip } from '@motion/ui/base'

import { useActionItemForm } from '../hooks'

type BannerProps = {
  onAction: (action: 'approve' | 'reject') => void
}

export function Banner({ onAction }: BannerProps) {
  const {
    form: { watch },
  } = useActionItemForm()

  const workspaceId = watch('workspaceId')
  const assigneeUserId = watch('assigneeUserId')

  const disableApprove = isNoneId(workspaceId) || !assigneeUserId

  return (
    <div className='flex justify-center items-center gap-4 px-3 py-1'>
      <div className='flex items-center gap-1 text-sm text-semantic-purple-text-default'>
        <PendingStatusSolid className='size-3' />
        <span>Approve this action item to convert it to a task.</span>
      </div>

      <ButtonGroup>
        <Button
          sentiment='error'
          variant='outlined'
          size='small'
          onClick={() => onAction('reject')}
        >
          Reject
        </Button>
        <Tooltip
          content={
            disableApprove ? 'Select a workspace and assignee to approve' : null
          }
        >
          <Button
            sentiment={disableApprove ? 'neutral' : 'success'}
            variant='outlined'
            size='small'
            onClick={() => onAction('approve')}
            disabled={disableApprove}
          >
            Approve
          </Button>
        </Tooltip>
      </ButtonGroup>
    </div>
  )
}
