import { useTaskPriorityData } from '~/areas/tasks/hooks'
import { PriorityBadge } from '~/global/components/badges'
import { PriorityDropdown } from '~/global/components/dropdowns'
import { type TaskWithRelations } from '~/global/proxies'

export type TaskLinePriorityItemProps = {
  task: TaskWithRelations
}

export function TaskLinePriorityItem({ task }: TaskLinePriorityItemProps) {
  const { onChange, priorities, disabled } = useTaskPriorityData(task)

  return (
    <PriorityDropdown
      items={priorities}
      selectedItem={task.priorityLevel}
      onChange={onChange}
    >
      <button type='button' disabled={disabled}>
        <PriorityBadge value={task.priorityLevel} />
      </button>
    </PriorityDropdown>
  )
}
