import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { FolderField, type FolderFieldProps } from '~/areas/task-project/fields'
import { useController } from 'react-hook-form'

import { useFlowTemplateForm } from '../hooks'

export const ControlledFolderField = () => {
  const { form } = useFlowTemplateForm()

  const { control, watch } = form

  const { field } = useController({
    name: 'folderId',
    control,
  })
  const workspaceId = watch('workspaceId')

  const onChange: FolderFieldProps['onChange'] = (selected) => {
    recordAnalyticsEvent('FLOW_TEMPLATE_FOLDER_CHANGE', {
      folderId: selected.folderId ?? 'No folder',
    })
    field.onChange(selected.folderId)
  }

  return (
    <FolderField
      computeDisabled={({ workspace }) => workspace.id !== workspaceId}
      workspaceId={workspaceId}
      folderId={field.value ?? null}
      onChange={onChange}
    />
  )
}
