import { type COLOR } from '@motion/shared/common'
import { getColorName } from '@motion/ui/palette'
import { ProjectPalette } from '@motion/ui/project'

import { type ReactNode } from 'react'

import { ColorItemBadge } from '../badges'

export type ColorLabelProps = {
  color: COLOR
  children?: ReactNode
}

export const ColorTagLabel = ({ color }: ColorLabelProps) => {
  return (
    <ProjectPalette color={color}>
      <div className='bg-palette-bg-light text-palette-text-subtle text-2xs font-medium px-1.5 rounded-full'>
        {getColorName(color)}
      </div>
    </ProjectPalette>
  )
}

export const ColorLabel = ({
  color,
  children = getColorName(color),
}: ColorLabelProps) => {
  return (
    <div className='flex items-center gap-2 overflow-hidden'>
      <ColorItemBadge color={color} />
      <span className='truncate'>{children}</span>
    </div>
  )
}
