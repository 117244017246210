import { isNoneId } from '@motion/shared/identifiers'
import { Tooltip } from '@motion/ui/base'
import {
  type TaskSchema,
  type UserInfoSchema,
  type WorkspaceSchema,
} from '@motion/zod/client'

import {
  ModalFieldButton,
  type ModalFieldButtonProps,
} from '~/areas/ai-hackerhouse/event/modals/action-item-modal/components/modal-field-button'
import {
  AssigneeDropdown,
  type AssigneeDropdownProps,
} from '~/areas/project-management/components'
import { useTaskUpdater } from '~/areas/tasks/hooks'
import { UserBadge } from '~/global/components/badges'
import { UserLabel } from '~/global/components/labels'
import { useMember, useWorkspaceMember } from '~/global/hooks'
import { useController } from 'react-hook-form'

import { useActionItemForm } from '../hooks'

export const ControlledAssigneeField = ({
  showLabel = true,
  size = 'normal',
}: {
  showLabel?: boolean
  size?: ModalFieldButtonProps['size']
}) => {
  const { form } = useActionItemForm()

  const { control, watch } = form

  const { field } = useController({
    name: 'assigneeUserId',
    control,
  })
  const workspaceId = watch('workspaceId')
  const selectedAssignee = useMember(field.value)

  // if task is already created, can edit in-line
  const taskId = form.watch('id')
  const updateTask = useTaskUpdater()

  const onChange: AssigneeFieldProps['onChange'] = async (assigneeId) => {
    field.onChange(assigneeId)
    if (taskId != null) {
      await updateTask(taskId, { assigneeUserId: assigneeId })
    }
  }

  if (isNoneId(workspaceId)) {
    return (
      <Tooltip content='Select a workspace to update assignee'>
        <ModalFieldButton
          label={showLabel ? 'Assignee' : undefined}
          disabled
          size={size}
        >
          <UserBadge value={selectedAssignee?.user} />
          <UserLabel value={selectedAssignee?.user} nameOnly />
        </ModalFieldButton>
      </Tooltip>
    )
  }

  return (
    <AssigneeField
      value={selectedAssignee?.userId ?? null}
      onChange={onChange}
      workspaceId={workspaceId}
      showLabel={showLabel}
      size={size}
    />
  )
}

export type AssigneeFieldProps = {
  value: TaskSchema['assigneeUserId']
  onChange: (value: TaskSchema['assigneeUserId']) => void
  disabled?: boolean
  workspaceId: WorkspaceSchema['id']
  removeUnassignedOption?: AssigneeDropdownProps['removeUnassignedOption']
  disableOptionFn?: AssigneeDropdownProps['disableOptionFn']
  disabledOptionTooltip?: AssigneeDropdownProps['disabledOptionTooltip']
  showLabel?: boolean
  size?: ModalFieldButtonProps['size']
}

export const AssigneeField = ({
  value,
  disabled,
  onChange,
  workspaceId,
  removeUnassignedOption,
  disableOptionFn,
  disabledOptionTooltip,
  showLabel = true,
  size = 'normal',
}: AssigneeFieldProps) => {
  const selectedAssignee = useWorkspaceMember(workspaceId, value)

  return (
    <AssigneeDropdown
      selectedUserId={value}
      onChange={(assigneeId) => {
        onChange(assigneeId)
      }}
      workspaceId={workspaceId}
      removeUnassignedOption={removeUnassignedOption}
      disabledOptionTooltip={disabledOptionTooltip}
      disableOptionFn={disableOptionFn}
    >
      <ModalFieldButton
        size={size}
        label={showLabel ? 'Assignee' : undefined}
        disabled={disabled}
      >
        <AssigneeLabel user={selectedAssignee?.user} />
      </ModalFieldButton>
    </AssigneeDropdown>
  )
}

type UserLabelProps = {
  user: UserInfoSchema | undefined
}

const AssigneeLabel = ({ user }: UserLabelProps) => {
  return (
    <>
      <UserBadge value={user ?? null} />
      <UserLabel value={user ?? null} nameOnly />
    </>
  )
}
