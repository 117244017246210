import { RichTextContent, useRichTextEditor } from '@motion/ui/rte'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import Mention from '@tiptap/extension-mention'
import { type ReactNode } from 'react'

import { SectionCollapsible } from './section-collapsible'

type GenericSectionProps = {
  title: string
  content: string
  renderFooter?: () => ReactNode
}

export function GenericSection({
  title,
  content,
  renderFooter,
}: GenericSectionProps) {
  const editor = useRichTextEditor({
    className: 'h-full !text-semantic-neutral-text-default',
    placeholder: '',
    value: content,
    onImgExpandClick: () => {
      recordAnalyticsEvent('IMAGE_EXPAND_CLICK')
    },
    extensions: [
      Mention.configure({
        HTMLAttributes: {
          class: 'font-bold',
        },
      }),
    ],
    disabled: true,
  })

  return (
    <SectionCollapsible title={title}>
      <RichTextContent editor={editor} />
      {renderFooter && renderFooter?.()}
    </SectionCollapsible>
  )
}
