import { createNoneId, isNoneId } from '@motion/shared/identifiers'
import { intersection } from '@motion/ui-logic/pm/data'
import { byProperty, uniqueBy } from '@motion/utils/array'
import { time } from '@motion/utils/debug'

import { buildGroupComparator } from './sort'
import { createTree } from './tree'
import { type GroupDefinition, type GroupedNode } from './types'

import { type TreeOverrides } from '../../../pages/hooks'

type BuildOptions = {
  hideEmptyGroups?: boolean
  footer?: (node: GroupedNode) => { key: string; type: string; value: any }[]
  sortGroupFns?: Record<string, (l: GroupedNode, r: GroupedNode) => number>
  /**
   * @deprecated use `sortGroupFns` instead
   */
  sortOrder?: Record<string, string[]>
}

export function buildTreeGroup<T extends { id: string; workspaceId?: string }>(
  levels: GroupDefinition<T>[],
  overrides?: TreeOverrides
) {
  const { initialValues, groupByOverrides } = overrides ?? {}
  const nodeLookup = new Map<string, GroupedNode>()

  // Given a parent node, combine any none children into a single none group
  function dedupeNoneGroups(parent: GroupedNode): GroupedNode {
    const children = parent.children ?? []

    const noneGroups = children.filter((x) => isNoneId(x.key))

    if (noneGroups.length <= 1) {
      return parent
    }

    const noneGroup = noneGroups[0]
    const otherGroups = children.filter((x) => !isNoneId(x.key))
    const workspaceIds = []

    for (const group of noneGroups) {
      if (group.workspaceIds) {
        workspaceIds.push(...group.workspaceIds)
      }
    }

    parent.children = [
      ...otherGroups,
      {
        ...noneGroup,
        children: noneGroups.map((x) => x.children ?? []).flat(),
        value: {
          type: noneGroup.value.type,
          key: createNoneId('null'),
          value: noneGroup.value.value,
        },
        key: createNoneId('null'),
        workspaceIds: workspaceIds,
      },
    ]

    return parent
  }

  function appendChildGroups(parent: GroupedNode, depth: number) {
    if (depth > levels.length - 1) return

    const level = levels[depth]
    const values = initialValues?.[level.type] ?? level.initialValues ?? []

    for (const g of values) {
      // Get intersection between parent workspaces and the workspaces of the node
      // if we are at a depth greater than 1 filter out the empty nodes that don't have an intersection
      // if the item is a none id, we don't filter it out (should be placed regardless of parent workspace)

      if (depth > 0 && !!g.workspaceIds && !!parent.workspaceIds) {
        const workspaceIntersection = intersection(
          parent.workspaceIds ?? [],
          g.workspaceIds ?? []
        )

        if (workspaceIntersection?.length === 0) {
          continue
        }
      }

      const node: GroupedNode = {
        key: g.key,
        qualifiedKey: createQualifiedKey(parent, g.key),
        value: { type: level.type, key: g.key, value: g.value },
        parent,
        children: [],
        count: 0,
        workspaceIds: g.workspaceIds,
        items: [],
      }
      nodeLookup.set(node.key, node)

      parent.children!.push(node)
      appendChildGroups(node, depth + 1)
    }
  }

  function appendValue(
    root: GroupedNode,
    type: string,
    item: T,
    depth: number
  ) {
    if (root.children == null) {
      throw new Error('trying to append to leaf node')
    }
    root.items.push(item)
    if (depth > levels.length - 1) {
      root.children.push({
        key: item.id,
        qualifiedKey: createQualifiedKey(root, item.id),
        value: { type, key: item.id, value: item },
        count: 1,
        parent: root,
        items: [item],
      })
      return
    }

    const def = levels[depth]
    const raw = def.accessor(item)
    const values = Array.isArray(raw) ? raw : [raw]
    if (values.length === 0) {
      if (__IS_DEV__) {
        throw new Error(
          `Empty values array found for group: ${def.type}. Update the accessor function to return a non-empty value.`
        )
      }
    }

    for (const v of values) {
      const keyOf = groupByOverrides?.[def.type]?.keyOf ?? def.keyOf
      const key = keyOf(v)
      const value = groupByOverrides?.[def.type]?.valueOf?.(v) ?? v
      const by = groupByOverrides?.[def.type]?.by

      let foundChild = root.children.find((x) => x.key === key)
      if (!foundChild) {
        foundChild = {
          key,
          qualifiedKey: createQualifiedKey(root, key),
          value: { type: def.type, key, value, by },
          parent: root,
          children: [],
          count: 0,
          items: [item],
        }
        root.children.push(foundChild)
        // Add the initial values
        appendChildGroups(foundChild, depth + 1)
      }
      foundChild.count += 1
      appendValue(foundChild, type, item, depth + 1)
    }
    root.items.push(item)
  }

  function isEmpty(node: GroupedNode): boolean {
    // leaf node
    if (node.children == null) return false
    if (node.children.length === 0) return true
    return node.children.every(isEmpty)
  }

  function removeEmptyGroups(parent: GroupedNode) {
    if (parent.children == null) return

    parent.children.forEach(removeEmptyGroups)
    const newChildren = []
    for (const child of parent.children) {
      if (isEmpty(child)) {
        child.parent = null
      } else {
        newChildren.push(child)
      }
    }
    parent.children = newChildren
  }

  function addFooter(node: GroupedNode, opts: BuildOptions) {
    if (opts.footer) {
      node.children?.push(
        ...opts.footer(node).map<GroupedNode>((value) => ({
          key: value.key,
          qualifiedKey: createQualifiedKey(node, value.key),
          parent: node,
          count: 1,
          value,
          children: [],
          items: [],
        }))
      )
    }
  }

  function finalizeTree(node: GroupedNode, depth: number, opts: BuildOptions) {
    if (depth > levels.length - 1) {
      node.count = node.children?.length ?? 0
      addFooter(node, opts)
      return
    }

    if (!node.children) return

    // Dedupe none groups (only have a single none group per child group)

    node.children = dedupeNoneGroups(node).children ?? []

    node.count = 0
    for (const child of node.children) {
      finalizeTree(child, depth + 1, opts)
      node.count += child.count
    }
    node.items = uniqueBy(node.items, (x) => x.id)
  }

  function sortTree(
    node: GroupedNode,
    depth: number,
    order: Record<string, string[]>,
    sortGroupFns?: BuildOptions['sortGroupFns']
  ) {
    if (depth > levels.length - 1) return
    if (!node.children) return

    const level = levels[depth]

    const groupOrder = order[level.type]
    const sortGroupFn =
      (groupOrder
        ? byProperty<GroupedNode, 'value'>(
            'value',
            buildGroupComparator(level, groupOrder)
          )
        : sortGroupFns?.[level.type]) ?? level.sortBy

    if (sortGroupFn != null) {
      node.children.sort(sortGroupFn)
    }

    for (const child of node.children) {
      sortTree(child, depth + 1, order, sortGroupFns)
    }
  }

  const root: GroupedNode = {
    key: '',
    qualifiedKey: '',
    value: { type: '@@root', key: '@@root', value: '' },
    children: [],
    count: 0,
    parent: null,
    items: [],
  }

  time('build-tree-group.initial', () => {
    appendChildGroups(root, 0)
  })

  return {
    add(type: string, items: T | T[]) {
      const itemArray = Array.isArray(items) ? items : [items]
      time(`build-tree-group.add-items[${itemArray.length}]`, () => {
        itemArray.forEach((item) => appendValue(root, type, item, 0))
      })
      root.count += itemArray.length
      return this
    },
    build<T extends GroupedNode = GroupedNode>(
      opts: BuildOptions = { hideEmptyGroups: false }
    ) {
      const tree = this.buildTree<T>(opts)

      const children = root.children!
      children.forEach((child) => (child.parent = null))

      return tree.values
    },
    buildTree<T extends GroupedNode = GroupedNode>(
      opts: BuildOptions = {
        hideEmptyGroups: false,
        sortOrder: {},
      }
    ) {
      if (opts.hideEmptyGroups) {
        time('build-tree-group.remove-empty', () => {
          removeEmptyGroups(root)
        })
      }
      time('build-tree-group.finalize', () => {
        finalizeTree(root, 0, opts)
      })

      time('build-tree-group.sort', () => {
        sortTree(root, 0, opts.sortOrder ?? {}, opts.sortGroupFns)
      })

      return time('build-tree-group.createTree', () =>
        createTree<T>(root as T, levels)
      )
    },
  }
}

function createQualifiedKey(parent: GroupedNode, id: string) {
  return [parent.qualifiedKey, id].join('/')
}
