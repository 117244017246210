import {
  Button,
  ControlledUnstyledCollapsableContainer,
  UnstyledCollapsableContainer,
  type UnstyledCollapsableContainerProps,
} from '@motion/ui/base'

import { RotatingChevronIcon } from '~/global/components'
import { type ReactNode } from 'react'

type CollapsableContainerProps = {
  title: CollapsableHeaderProps['title']
  headerActions?: ReactNode
  children: ReactNode
  onToggle?: (expanded: boolean) => void
  initialExpanded?: boolean
  chevronPosition?: CollapsableHeaderProps['chevronPosition']
}

export const CollapsableContainer = ({
  title,
  headerActions,
  children,
  onToggle,
  initialExpanded = true,
  chevronPosition,
}: CollapsableContainerProps) => {
  return (
    <ControlledUnstyledCollapsableContainer
      initialExpanded={initialExpanded}
      renderHeader={({ expanded, toggle }) => (
        <CollapsableHeader
          title={title}
          expanded={expanded}
          toggle={() => {
            toggle()
            onToggle?.(!expanded)
          }}
          chevronPosition={chevronPosition}
        >
          {headerActions}
        </CollapsableHeader>
      )}
      className='items-stretch gap-3'
    >
      {children}
    </ControlledUnstyledCollapsableContainer>
  )
}

type UncontrolledCollapsableContainerProps = Pick<
  UnstyledCollapsableContainerProps,
  'expanded' | 'toggle'
> &
  CollapsableContainerProps

export const UncontrolledCollapsableContainer = ({
  title,
  headerActions,
  children,
  onToggle,
  chevronPosition,
  expanded,
  toggle,
}: UncontrolledCollapsableContainerProps) => {
  return (
    <UnstyledCollapsableContainer
      expanded={expanded}
      toggle={toggle}
      renderHeader={({ expanded, toggle }) => (
        <CollapsableHeader
          title={title}
          expanded={expanded}
          toggle={() => {
            toggle()
            onToggle?.(!expanded)
          }}
          chevronPosition={chevronPosition}
        >
          {headerActions}
        </CollapsableHeader>
      )}
      className='items-stretch gap-3'
    >
      {children}
    </UnstyledCollapsableContainer>
  )
}

type CollapsableHeaderProps = {
  title: ReactNode
  expanded: boolean
  toggle: () => void
  children?: React.ReactNode
  chevronPosition?: 'left' | 'right'
}
const CollapsableHeader = ({
  title,
  expanded,
  toggle,
  children,
  chevronPosition = 'left',
}: CollapsableHeaderProps) => {
  return (
    <div className='flex w-full items-center justify-between'>
      <Button onClick={toggle} sentiment='neutral' variant='muted'>
        {chevronPosition === 'left' && (
          <RotatingChevronIcon expanded={expanded} />
        )}
        <span className='text-semantic-neutral-text-default font-bold'>
          {title}
        </span>
        {chevronPosition === 'right' && (
          <RotatingChevronIcon expanded={expanded} />
        )}
      </Button>
      {children}
    </div>
  )
}
