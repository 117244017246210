import { convertDataFieldsToUpdateSchema } from '@motion/ui-logic'
import { keys } from '@motion/utils/object'
import { type CalendarEventSchemaV2 } from '@motion/zod/client'

import { useUpdateEvent } from '~/areas/event/hooks'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

import { useEventForm } from './use-event-form'

import { type EventAiFormFields } from '../../../types'
import { isAiBotSupportedConference } from '../utils'

export function useFormUpdateEvent() {
  const { form } = useEventForm()
  const updateEvent = useUpdateEvent()

  const eventId = form.watch('id')

  return useCallback(
    async (
      fields: EventAiFormFields
    ): Promise<CalendarEventSchemaV2 | undefined> => {
      const { dirtyFields } = form.formState

      if (eventId == null) {
        throw new Error(`Can't save an event without an id`)
      }

      const dirtyFieldsKeys = keys(dirtyFields)

      const dataToUpdate = keys(dirtyFields).reduce<Partial<EventAiFormFields>>(
        (acc, field) => ({ ...acc, [field]: fields[field] }),
        {}
      )

      try {
        const updates = {
          ...convertDataFieldsToUpdateSchema(dataToUpdate),

          // When only updating the color, we don't send any updates
          sendUpdates:
            dirtyFieldsKeys.length === 1 && dirtyFieldsKeys.includes('colorId')
              ? false
              : undefined,
        }

        // AIHH
        // If all day is enabled or conference link is set to null, disable the meeting bot
        if (
          (fields.isAllDay && fields.isMeetingBotEnabled) ||
          !isAiBotSupportedConference(fields.conferenceType)
        ) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ;(updates as any)['isMeetingBotEnabled'] = false
        }

        return updateEvent(eventId, updates)
      } catch (e) {
        showErrorToast(e)
      }
    },
    [eventId, form.formState, updateEvent]
  )
}
