import { useFieldFilter } from '~/areas/project-management/filters'

import { type FilterFunctionOpts, makeIdFilterFunction } from './utils'

import { type PageParamsOverrides } from '../../../routes/types'

export function useInferWorkspaceIdFromFilters(
  filterOverrides: PageParamsOverrides,
  { useLastItemFallback }: FilterFunctionOpts
) {
  const [workspaceIds] = useFieldFilter('workspaces', 'ids')
  return makeIdFilterFunction(
    filterOverrides.workspaces?.ids ?? workspaceIds,
    'workspaceId',
    { useLastItemFallback }
  )
}
