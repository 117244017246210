import { HttpError } from '@motion/rpc'
import { classed } from '@motion/theme'
import { Button, showToast, Tag } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { isEmailValid } from '@motion/utils/string'

import { showErrorToast } from '~/global/toasts'
import { useState } from 'react'
import { useNavigate } from 'react-router'

import { useCreateReferral, useGetUserReferrals } from './rpc-hooks'

import { SettingPage } from '../../Components/SettingPage'

export const Referrals = () => {
  const [inviteeEmail, setInviteeEmail] = useState('')
  const [emailIsInvalid, setEmailIsInvalid] = useState(false)
  const navigate = useNavigate()

  const { mutateAsync: createReferral, isLoading: isCreating } =
    useCreateReferral()

  const { data: userReferrals } = useGetUserReferrals()

  async function handleSendInvite() {
    if (!isEmailValid(inviteeEmail)) {
      setEmailIsInvalid(true)
      return
    } else if (
      userReferrals?.length &&
      userReferrals.filter((referral) => referral.inviteeEmail === inviteeEmail)
        .length > 0
    ) {
      showToast('error', `You've already invited ${inviteeEmail}`)
      return
    }
    setEmailIsInvalid(false)
    try {
      await createReferral({ inviteeEmail })
      showToast('success', 'Invite sent')

      setInviteeEmail('')
    } catch (err) {
      if (err instanceof HttpError && err.status === 409) {
        showErrorToast(err)
      }
    }
  }

  return (
    <SettingPage title='Invite friends (Get $100)'>
      <div className='flex flex-col gap-6 max-w-[600px]'>
        <div className='flex flex-col gap-5 text-semantic-neutral-text-default'>
          <div className='flex flex-col gap-3'>
            <h1 className='text-base font-semibold'>
              Give your friends an extended 2-week trial. Receive $100 if they
              subscribe.
            </h1>
            <p>
              Each invited user receives an extended 2-week trial (no credit
              card needed). You receive $100 in credit for each referred user
              who subscribes to an individual plan.
            </p>
          </div>
          <div className='flex flex-col gap-2'>
            <h2 className='text-sm font-semibold'>Invite by email</h2>
            <div className='flex flex-row items-center justify-between gap-2'>
              <div className='grow'>
                <TextField
                  label='Invitee email'
                  labelHidden
                  placeholder='Enter email'
                  value={inviteeEmail}
                  onChange={setInviteeEmail}
                  sentiment={emailIsInvalid ? 'error' : undefined}
                />
              </div>
              <Button onClick={handleSendInvite} loading={isCreating}>
                Send Invite
              </Button>
            </div>
            {emailIsInvalid && (
              <p className='text-xs text-semantic-error-text-default'>
                Please enter a valid email
              </p>
            )}
          </div>
        </div>
        <div className='w-full border-t border-semantic-neutral-border-default' />
        <div className='flex flex-col gap-3'>
          <h2 className='text-base font-semibold text-semantic-neutral-text-default'>
            Invited users
          </h2>
          {userReferrals && userReferrals.length ? (
            <div className='flex flex-col gap-3'>
              <p className='text-sm'>
                See how much credit you have on the{' '}
                <a
                  onClick={() => {
                    navigate('/web/settings/billing')
                  }}
                  className='cursor-pointer text-semantic-primary-text-default underline'
                >
                  accounts page
                </a>
                .
              </p>
              <div className='table w-full border border-table-border rounded bg-semantic-neutral-bg-default'>
                <div className='table-header-group'>
                  <TableRow>
                    <TableCell key='email' header>
                      Email
                    </TableCell>
                    <TableCell key='status' header>
                      Status
                    </TableCell>
                  </TableRow>
                </div>
                <div className='table-row-group'>
                  {userReferrals.map((referral) => {
                    return (
                      <TableRow key={referral.id}>
                        <TableCell>{referral.inviteeEmail}</TableCell>
                        <TableCell isStatus>
                          <div className='flex h-full'>
                            {referral.converted ? (
                              <Tag color='green' size='small' variant='subtle'>
                                Subscribed ($100 credit received!)
                              </Tag>
                            ) : (
                              <Tag size='small' variant='subtle'>
                                Pending
                              </Tag>
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </div>
              </div>
            </div>
          ) : (
            <p className='text-semantic-neutral-text-subtle'>
              Once you&apos;ve invited some people you&apos;ll see them listed
              here.
            </p>
          )}
        </div>
      </div>
    </SettingPage>
  )
}

const TableRow = classed('div', {
  base: 'table-row group',
})

const TableCell = classed('div', {
  base: 'table-cell py-2 px-3 border-b group-last:border-0 border-table-border text-semantic-neutral-text-default',
  variants: {
    isStatus: {
      true: 'w-[252px] align-middle',
    },
    header: {
      true: 'text-table-columnTitle text-xs font-medium group-last:border-b',
    },
  },
})
