import {
  useMyUserSettingsId,
  useMyUserSettingsQuery,
  useSaveConferenceSettings,
} from '@motion/web-common/settings'
import {
  type UserConferenceSettingsUpdateV2Schema,
  type UserConferenceSettingsV2Schema,
} from '@motion/zod/client'

import { useCallback } from 'react'

import {
  type ConferenceSettingsState,
  type PersistedConferenceSettingsStateUpdate,
} from '../types'

const DEFAULT_REMOTE_STATE: ConferenceSettingsState = {
  conferenceSettings: {
    conferenceType: 'meet',
    customLocation: '',
    phoneNumber: '',
    zoomAccount: '',
    zoomLinkType: 'manual',
    zoomManualLink: '',
    zoomPersonalLink: '',
  },
  reminderSettings: {
    duration: 300,
    warning: 15,
  },
  zoomAccessToken: undefined,
  zoomExpiration: undefined,
  zoomRefreshToken: undefined,
  zoomNeedsRefresh: false,
}

export const usePersistedConferenceSettingsState = (): [
  ConferenceSettingsState,
  (data: PersistedConferenceSettingsStateUpdate) => Promise<void>,
] => {
  const { mutateAsync: saveTo } = useSaveConferenceSettings()
  const update = useCallback(
    async (data: PersistedConferenceSettingsStateUpdate) => {
      await saveTo({ conferenceV2: v1ConferenceSettingsUpdateToV2(data) })
    },
    [saveTo]
  )

  const userSettingsId = useMyUserSettingsId() ?? ''
  const { data } = useMyUserSettingsQuery(undefined, {
    meta: { source: 'usePersistedConferenceSettingsState' },
  })
  if (!data?.models.userSettings[userSettingsId]?.conferenceV2) {
    return [DEFAULT_REMOTE_STATE, update]
  }
  const conferenceV1 = v2ConferenceSettingsToV1(
    data?.models.userSettings[userSettingsId]?.conferenceV2
  )

  return [conferenceV1, update] as const
}

const v2ConferenceSettingsToV1 = (
  settingsV2: UserConferenceSettingsV2Schema
): ConferenceSettingsState => {
  return {
    conferenceSettings: {
      conferenceType:
        settingsV2.defaultConferenceType ??
        DEFAULT_REMOTE_STATE.conferenceSettings.conferenceType,
      customLocation:
        settingsV2.customLocation ??
        DEFAULT_REMOTE_STATE.conferenceSettings.customLocation,
      phoneNumber:
        settingsV2.phoneNumber ??
        DEFAULT_REMOTE_STATE.conferenceSettings.phoneNumber,
      zoomAccount:
        settingsV2.zoomEmailAccount ??
        DEFAULT_REMOTE_STATE.conferenceSettings.zoomAccount,
      zoomLinkType:
        settingsV2.zoomLinkType ??
        DEFAULT_REMOTE_STATE.conferenceSettings.zoomLinkType,
      zoomManualLink:
        settingsV2.zoomManualLink ??
        DEFAULT_REMOTE_STATE.conferenceSettings.zoomManualLink,
      zoomPersonalLink:
        settingsV2.zoomPersonalLink ??
        DEFAULT_REMOTE_STATE.conferenceSettings.zoomPersonalLink,
    },
    reminderSettings: {
      duration:
        settingsV2.meetingReminderDuration ??
        DEFAULT_REMOTE_STATE.reminderSettings.duration,
      warning:
        settingsV2.secondsBeforeMeetingToRemind ??
        DEFAULT_REMOTE_STATE.reminderSettings.warning,
    },
    zoomAccessToken:
      settingsV2.zoomAccessToken ?? DEFAULT_REMOTE_STATE.zoomAccessToken,
    zoomExpiration: settingsV2.zoomAccessTokenExpiration ?? undefined,
    zoomRefreshToken:
      settingsV2.zoomRefreshToken ?? DEFAULT_REMOTE_STATE.zoomRefreshToken,
    zoomNeedsRefresh:
      settingsV2.zoomAccessTokenStatus === 'INVALID_CREDENTIALS',
  }
}

const v1ConferenceSettingsUpdateToV2 = (
  settingsUpdateV1: PersistedConferenceSettingsStateUpdate
): UserConferenceSettingsUpdateV2Schema => {
  const nestedConferenceSettings =
    settingsUpdateV1.conferenceSettings === null
      ? {
          conferenceType: null,
          customLocation: null,
          phoneNumber: null,
          zoomAccount: null,
          zoomLinkType: null,
          zoomManualLink: null,
          zoomPersonalLink: null,
        }
      : {
          conferenceType: settingsUpdateV1.conferenceSettings?.conferenceType,
          customLocation: settingsUpdateV1.conferenceSettings?.customLocation,
          phoneNumber: settingsUpdateV1.conferenceSettings?.phoneNumber,
          zoomAccount: settingsUpdateV1.conferenceSettings?.zoomAccount,
          zoomLinkType: settingsUpdateV1.conferenceSettings?.zoomLinkType,
          zoomManualLink: settingsUpdateV1.conferenceSettings?.zoomManualLink,
          zoomPersonalLink:
            settingsUpdateV1.conferenceSettings?.zoomPersonalLink,
        }

  return {
    defaultConferenceType: nestedConferenceSettings.conferenceType,
    customLocation: nestedConferenceSettings.customLocation,
    phoneNumber: nestedConferenceSettings.phoneNumber,
    zoomEmailAccount: nestedConferenceSettings.zoomAccount,
    zoomLinkType: nestedConferenceSettings.zoomLinkType,
    zoomManualLink: nestedConferenceSettings.zoomManualLink,
    zoomPersonalLink: nestedConferenceSettings.zoomPersonalLink,
    meetingReminderDuration: settingsUpdateV1.reminderSettings?.duration,
    secondsBeforeMeetingToRemind: settingsUpdateV1.reminderSettings?.warning,
    zoomAccessToken: settingsUpdateV1.zoomAccessToken,
    zoomAccessTokenExpiration: settingsUpdateV1.zoomExpiration,
    zoomAccessTokenStatus:
      settingsUpdateV1.zoomNeedsRefresh === true
        ? 'INVALID_CREDENTIALS'
        : settingsUpdateV1.zoomNeedsRefresh === false
          ? 'OK'
          : undefined,
    zoomRefreshToken: settingsUpdateV1.zoomRefreshToken,
  }
}
