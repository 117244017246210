import {
  type DashboardViewCellSchema,
  type DashboardViewChartSchema,
} from '@motion/zod/client'

import { v4 as uuid } from 'uuid'

export function createNewCell(
  chart: DashboardViewChartSchema
): DashboardViewCellSchema {
  return {
    id: uuid(),
    title: 'New chart',
    chart,
    x: 0,
    y: 2,
    width: 4,
    height: 2,
  }
}
