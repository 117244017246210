import { type COLOR, COLORS } from '@motion/shared/common'
import { classed } from '@motion/theme'

export const StyledColorTag = classed('span', {
  base: `
    inline-flex rounded-full
    bg-[hsl(var(--motion-palette-hue)_var(--motion-palette-100-default))]
    text-[hsl(var(--motion-palette-hue)_var(--motion-palette-800-default))]
    dark:bg-[hsl(var(--motion-palette-hue)_var(--motion-palette-900-default))]
    dark:text-[hsl(var(--motion-palette-hue)_var(--motion-palette-500-default))]
  `,
  variants: {
    color: COLORS.reduce(
      (acc, color) => {
        acc[color] =
          color !== 'gray'
            ? ''
            : 'bg-palette-gray-bg-light text-palette-gray-text-subtle dark:bg-palette-gray-bg-light dark:!text-palette-gray-highlight'
        return acc
      },
      {} as Record<COLOR, string>
    ),
    size: {
      xsmall: `
        px-[3px]
        text-2xs
      `,
      small: `
        px-[3px]
        text-xs
      `,
      normal: `
        px-1.5
        py-0.5
        text-xs
      `,
      large: `
        px-2 py-[2px]
        text-sm
      `,
    },
    square: {
      true: 'rounded-[4px]',
    },
    iconOnly: {
      true: `
        [&>[data-icon]]:size-3

        text-[hsl(var(--motion-palette-hue)_var(--motion-palette-500-default))]
        dark:text-[hsl(var(--motion-palette-hue)_var(--motion-palette-500-default))]
      `,
      false: '',
    },
    clickable: {
      true: `
        cursor-pointer

        hover:bg-[hsl(var(--motion-palette-hue)_var(--motion-palette-200-default))]
        dark:hover:bg-[hsl(var(--motion-palette-hue)_var(--motion-palette-800-default))]

        active:bg-[hsl(var(--motion-palette-hue)_var(--motion-palette-300-default))]
        dark:active:bg-[hsl(var(--motion-palette-hue)_var(--motion-palette-700-default))]
      `,
      false: '',
    },
  },
  compoundVariants: [
    {
      iconOnly: true,
      color: 'gray',
      className: '!text-gray-500',
    },
    {
      iconOnly: true,
      size: 'small',
      className: 'p-0.5',
    },
    {
      iconOnly: true,
      size: 'normal',
      className: 'p-1.5',
    },
    {
      iconOnly: true,
      size: 'large',
      className: `
        p-2
        [&>[data-icon]]:size-4
      `,
    },
    {
      iconOnly: true,
      color: 'gray',
      clickable: true,
      className:
        'hover:bg-gray-200 dark:hover:bg-gray-800 active:bg-gray-300 dark:active:bg-gray-700',
    },
  ],
})
