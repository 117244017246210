import { convertDataFieldsToCreateSchema } from '@motion/ui-logic'

import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

import { useCreateEvent } from '../../../hooks'
import { type EventAiFormFields } from '../../../types'
import { useEventModalState } from '../contexts'

export function useFormCreateEvent() {
  const modalState = useEventModalState()
  const createEvent = useCreateEvent()

  return useCallback(
    async (fields: EventAiFormFields) => {
      try {
        return createEvent(convertDataFieldsToCreateSchema(fields, modalState))
      } catch (e) {
        showErrorToast(e)
      }
    },
    [createEvent, modalState]
  )
}
