import { useHasTreatment } from '@motion/web-common/flags'

import {
  type ProjectWithRelations,
  type TaskWithRelations,
} from '~/global/proxies'
import { useMemo } from 'react'

import { useCustomFieldBaseDefinitions } from './custom-fields'
import { PROJECT_FIELD_LIST } from './project-fields'
import { TASK_FIELD_LIST } from './task-fields'
import { type FieldDefinition } from './types'

const useFieldsToExclude = () => {
  const hasPastDueM2 = useHasTreatment('past-due-m2')

  return useMemo(() => {
    const fieldsToExclude = []

    if (!hasPastDueM2) {
      fieldsToExclude.push('deadlineStatus')
    }

    return fieldsToExclude
  }, [hasPastDueM2])
}

export const useTaskFields = (): FieldDefinition<TaskWithRelations>[] => {
  const fieldsToExclude = useFieldsToExclude()
  const customFieldDefinitions = useCustomFieldBaseDefinitions()

  return useMemo(() => {
    let filteredTaskFields = TASK_FIELD_LIST
    if (fieldsToExclude.length) {
      filteredTaskFields = filteredTaskFields.filter(
        (field) => !fieldsToExclude.includes(field.id)
      )
    }

    return [...filteredTaskFields, ...customFieldDefinitions]
  }, [customFieldDefinitions, fieldsToExclude])
}

export const useProjectFields = (): FieldDefinition<ProjectWithRelations>[] => {
  const fieldsToExclude = useFieldsToExclude()
  const customFieldDefinitions = useCustomFieldBaseDefinitions()

  return useMemo(() => {
    let filteredProjectFields = PROJECT_FIELD_LIST

    if (fieldsToExclude.length) {
      filteredProjectFields = filteredProjectFields.filter(
        (field) => !fieldsToExclude.includes(field.id)
      )
    }
    return [...filteredProjectFields, ...customFieldDefinitions]
  }, [customFieldDefinitions, fieldsToExclude])
}
