import { useRegisterFieldArray } from '~/hooks'
import { type ComponentRef, useRef } from 'react'

import { StageDetails } from './stage-details'
import { StageTasks } from './stage-tasks'

import { StageTitleField } from '../../fields'
import { useFlowTemplateForm } from '../../hooks'
import { StageColumn } from '../styled'

export const StageTaskColumn = ({
  index,
  onRemove,
}: {
  index: number
  onRemove: () => void
}) => {
  const {
    form: { register, getValues },
  } = useFlowTemplateForm()

  useRegisterFieldArray(`stages.${index}`, register, {
    validate: () => {
      const stage = getValues(`stages.${index}`)
      if (stage != null && stage.tasks.length === 0) {
        return 'There must be at least one task in a stage'
      }
    },
  })

  const stageColumnRef = useRef<ComponentRef<typeof StageColumn>>(null)

  return (
    <StageColumn ref={stageColumnRef}>
      <StageTitleField stagePath={`stages.${index}`} onRemove={onRemove} />

      <StageDetails stagePath={`stages.${index}`} />

      <StageTasks
        tasksPath={`stages.${index}.tasks`}
        stageColumnRef={stageColumnRef}
      />
    </StageColumn>
  )
}
