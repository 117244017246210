import { useSharedState } from '@motion/react-core/shared-state'
import { ActiveFilterKey } from '@motion/ui-logic/pm/data'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { toViewDefinitionFilters } from '~/areas/project-management/pages/pm-v3/views'
import { useCallback } from 'react'

import { useDashboardViewState } from '../../../view-state'
import { useValidChartFieldsContext } from '../contexts'
import { mergeChartFieldsIntoCell } from '../utils'

export const useUpdateChartCell = () => {
  const [, setViewState] = useDashboardViewState()
  const ctx = useValidChartFieldsContext()
  const [filters] = useSharedState(ActiveFilterKey)

  return useCallback(() => {
    setViewState((prevView) => {
      const foundCell = prevView.view.cells.find(
        (c) => c.id === ctx.chartCellId
      )
      if (foundCell) {
        recordAnalyticsEvent('DASHBOARD_CHART_UPDATE', {
          chartType: foundCell.chart.type,
        })
      }

      return {
        ...prevView,
        view: {
          ...prevView.view,
          cells: prevView.view.cells.map((cell) => {
            if (cell.id !== ctx.chartCellId) return cell

            return mergeChartFieldsIntoCell(
              ctx.fields,
              cell,
              toViewDefinitionFilters(filters)
            )
          }),
        },
      }
    })
  }, [ctx.chartCellId, ctx.fields, filters, setViewState])
}
