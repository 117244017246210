import { formatToReadableWeekDayMonth } from '@motion/ui-logic'
import { parseDate } from '@motion/utils/dates'
import { type ProjectSchema } from '@motion/zod/client'

import { useUpdateProjectStageDueDate } from '~/areas/project/hooks'
import { useProjectStageByProjectAndDefinitionId } from '~/areas/project/hooks/data'

import { BaseDeadlineCell } from './deadline-cell'
import { useProjectInTree } from './hooks'

import { type GroupedNode } from '../../grouping'

export type StageDeadlineCellProps = {
  row: GroupedNode
  stageDefinitionId: string
}

export const StageDeadlineCell = ({
  row,
  stageDefinitionId,
}: StageDeadlineCellProps) => {
  const project = useProjectInTree(row)
  if (!project) return null

  return (
    <InnerStageDeadlineCell
      project={project}
      stageDefinitionId={stageDefinitionId}
    />
  )
}

type InnerStageDeadlineCellProps = {
  project: ProjectSchema
  stageDefinitionId: string
}

const InnerStageDeadlineCell = ({
  project,
  stageDefinitionId,
}: InnerStageDeadlineCellProps) => {
  const updateStageDueDate = useUpdateProjectStageDueDate()
  /**
   * If a parent grouping is project, we need to lookup the correct stage for that project,
   * due to grouping by name across projects
   */
  const stage = useProjectStageByProjectAndDefinitionId(
    project,
    stageDefinitionId
  )
  if (!stage) return null

  return (
    <BaseDeadlineCell
      onChange={(dueDate) => {
        if (dueDate == null) return
        updateStageDueDate(
          project.id,
          stage.stageDefinitionId,
          parseDate(dueDate).toISODate()
        )
      }}
      value={stage.dueDate}
      hideTimeOptions
      formattedValue={formatToReadableWeekDayMonth(stage.dueDate)}
      isValidDateOption={() => true}
      dropdownTarget='stage'
      contextProps={{
        projectId: project.id,
        stageDefinitionId,
      }}
    />
  )
}
