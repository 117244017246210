import { ChevronDownSolid, PlusSolid, SearchSolid, XSolid } from '@motion/icons'
import { Button, IconButton, UnstyledModal } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { omit } from '@motion/utils/core'
import { Sentry } from '@motion/web-base/sentry'
import { type FolderSchema } from '@motion/zod/client'

import {
  FlowTemplateCardLink,
  LegacyTemplateCardButton,
  useFlowTemplateModalUrl,
} from '~/areas/flows'
import { useTaskPrefillData } from '~/areas/project/modals/project-modal/hooks'
import { usePageData } from '~/areas/project-management/pages/pm-v3/routes'
import { ShellVars } from '~/areas/task-project/components'
import { FlowsAICard } from '~/global/components'
import { WorkspaceBadge } from '~/global/components/badges'
import { ALL_WORKSPACES_ID } from '~/global/components/dropdowns'
import {
  useAllLegacyProjectTemplates,
  useAllProjectDefinitions,
  useMyTasksWorkspace,
  useProjectDefinitions,
  useWorkspaceById,
} from '~/global/hooks'
import { useGetFolderById } from '~/global/hooks/folders'
import { useProjectModalUrl } from '~/global/navigation'
import { useSearchParams } from '~/routing'
import { useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import {
  CreateProjectWorkspaceDropdown,
  FormShell,
  Header,
  HeaderText,
  NewFlowTemplateCardLink,
  NewFlowTemplateLink,
  NewLegacyTemplateCardLink,
  NewLegacyTemplateLink,
  TemplateSearchResults,
} from './components'
import { useCloseCreateProjectModal } from './hooks'
import { type CreateProjectSearchParams } from './utils'

import { useCreateProject } from '../../hooks'
import { useSetupProjectModalUrl } from '../setup-project-modal'

type CreateProjectModalProps = {
  open: boolean
}

export function ConnectedCreateProjectModal({ open }: CreateProjectModalProps) {
  const closeModal = useCloseCreateProjectModal()

  return (
    <UnstyledModal
      data-testid='create-project-modal'
      type='page'
      visible={open}
      onClose={closeModal}
      withAnimation
      overlayClassName='bg-modal-overlay'
    >
      <CreateProjectModalContent onClose={closeModal} />
    </UnstyledModal>
  )
}

function CreateProjectModalContent({ onClose }: { onClose: () => void }) {
  const { workspaceId: workspaceIdParam, folderId: folderIdParam } = useParams<{
    workspaceId?: string
    folderId?: string
  }>()

  const { forTaskId, forWorkspace, forFolder } =
    useSearchParams<CreateProjectSearchParams>()

  const navigate = useNavigate()

  const createProject = useCreateProject()
  const getTaskData = useTaskPrefillData()

  const buildProjectModalUrl = useProjectModalUrl()
  const buildSetupProjectModalUrl = useSetupProjectModalUrl()
  const buildFlowTemplateModalUrl = useFlowTemplateModalUrl()
  const route = usePageData()

  const myTasksWorkspace = useMyTasksWorkspace()

  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState(
    forWorkspace ??
      (route.page === 'my-tasks'
        ? (myTasksWorkspace?.id ?? ALL_WORKSPACES_ID)
        : (workspaceIdParam ?? ALL_WORKSPACES_ID))
  )

  // If "all workspaces" is selected, default to my tasks workspace's id
  const validSelectedWorkspaceId =
    selectedWorkspaceId === ALL_WORKSPACES_ID
      ? (myTasksWorkspace?.id ?? null)
      : selectedWorkspaceId

  const selectedWorkspace = useWorkspaceById(selectedWorkspaceId)
  const getFolderById = useGetFolderById()

  const allTemplates = useAllLegacyProjectTemplates()
  const selectedTemplates = useMemo(() => {
    // If we're creating a project from a task, we don't want to show any templates
    if (forTaskId) return []

    return selectedWorkspaceId === ALL_WORKSPACES_ID
      ? allTemplates
      : allTemplates.filter(
          (template) => template.workspaceId === selectedWorkspaceId
        )
  }, [forTaskId, selectedWorkspaceId, allTemplates])

  const workspaceProjectDefinitions = useProjectDefinitions(
    selectedWorkspace?.id
  )
  const allProjectDefinitions = useAllProjectDefinitions()

  const projectDefinitions = selectedWorkspace?.id
    ? workspaceProjectDefinitions
    : allProjectDefinitions

  const [search, setSearch] = useState('')

  const onCreateProjectFromScratch = async () => {
    let projectId = 'new'

    // If creating project from task, create the project directly and redirect to project
    if (forTaskId != null) {
      const taskData = await getTaskData(forTaskId)
      if (taskData == null) {
        return
      }

      // Default to my tasks workspace if all workspaces is selected
      if (validSelectedWorkspaceId == null) {
        Sentry.captureMessage(
          'No workspace found when creating project from task',
          {
            extra: {
              forTaskId,
              selectedWorkspaceId,
            },
          }
        )
        return
      }

      // If the workspace is different from the task's workspace, omit fields that are not allowed
      const taskDataFields =
        validSelectedWorkspaceId !== taskData.workspaceId
          ? omit(taskData, ['customFieldValues', 'statusId', 'labelIds'])
          : taskData

      const project = await createProject({
        ...taskDataFields,
        workspaceId: validSelectedWorkspaceId,
        taskId: forTaskId,
      })

      if (project == null) {
        return
      }

      projectId = project.id
    }

    const url = buildProjectModalUrl({
      project: projectId,
      forWorkspace,
      forFolder,
    })

    navigate(url)
  }

  let targetFolderId: FolderSchema['id'] | null =
    forFolder ?? folderIdParam ?? null

  if (targetFolderId != null) {
    const matchedFolder = getFolderById(targetFolderId)

    // Prevent creating a project in a folder that does not belong to the target workspace
    if (!matchedFolder || matchedFolder.targetId !== targetFolderId) {
      targetFolderId = null
    }
  }

  return (
    <ShellVars>
      <FormShell>
        <div className='flex flex-col h-full'>
          <Header>
            <HeaderText>Create project</HeaderText>
            <div className='flex gap-2'>
              <IconButton
                icon={XSolid}
                sentiment='neutral'
                variant='muted'
                onClick={onClose}
              />
            </div>
          </Header>

          <div className='bg-semantic-neutral-surface-raised-bg-subtlest p-4 flex flex-row items-center justify-between'>
            <div className='flex flex-row items-center gap-2'>
              <TextField
                showClearButton
                sentiment={search ? 'active' : 'default'}
                prefix={<SearchSolid />}
                placeholder='Search templates'
                value={search}
                onChange={(searchValue) => {
                  setSearch(searchValue)
                }}
              />

              <CreateProjectWorkspaceDropdown
                onChange={(workspace) => setSelectedWorkspaceId(workspace.id)}
                selectedWorkspaceId={selectedWorkspaceId}
              >
                <Button variant='outlined' sentiment='neutral' size='small'>
                  <div className='flex flex-row items-center justify-between gap-2 px-[5px] py-1'>
                    <WorkspaceBadge />
                    <span className='text-semantic-neutral-text-default text-sm'>
                      {selectedWorkspace?.name ?? 'All workspaces'}
                    </span>
                    <ChevronDownSolid />
                  </div>
                </Button>
              </CreateProjectWorkspaceDropdown>
            </div>

            <Button
              sentiment='neutral'
              variant='outlined'
              onClick={onCreateProjectFromScratch}
            >
              <PlusSolid />
              <span className='text-nowrap'>Create project from scratch</span>
            </Button>
          </div>

          {search !== '' ? (
            <TemplateSearchResults
              search={search}
              clearSearch={() => setSearch('')}
              legacyTemplates={selectedTemplates}
              flowTemplates={projectDefinitions}
            />
          ) : (
            <div className='flex flex-col overflow-auto'>
              <div className='gap-6 flex flex-col p-4'>
                <div className='py-4'>
                  <FlowsAICard
                    workspaceId={validSelectedWorkspaceId ?? undefined}
                    onClose={onClose}
                  />
                </div>

                <div className='flex flex-row items-center justify-between'>
                  <HeaderText>Project Workflow Templates</HeaderText>

                  <NewFlowTemplateLink workspaceId={selectedWorkspace?.id}>
                    <Button variant='muted' sentiment='neutral' size='small'>
                      <PlusSolid />
                      New project workflow template
                    </Button>
                  </NewFlowTemplateLink>
                </div>
                <div className='flex gap-3 flex-wrap'>
                  {projectDefinitions.map((definition) => (
                    <FlowTemplateCardLink
                      key={definition.id}
                      name={definition.name}
                      stages={definition.stages}
                      url={buildSetupProjectModalUrl({
                        flowTemplateId: definition.id,
                        forWorkspace: definition.workspaceId,
                        forFolder:
                          targetFolderId ?? definition.folderId ?? undefined,
                      })}
                      editTemplateUrl={buildFlowTemplateModalUrl({
                        forWorkspace: definition.workspaceId,
                        template: 'edit',
                        templateId: definition.id,
                      })}
                    />
                  ))}
                  <NewFlowTemplateCardLink
                    workspaceId={selectedWorkspace?.id}
                  />
                </div>

                {!forTaskId && (
                  <>
                    <div className='flex flex-row items-center justify-between'>
                      <HeaderText>Regular Templates</HeaderText>

                      <NewLegacyTemplateLink
                        workspaceId={selectedWorkspace?.id}
                      >
                        <Button
                          variant='muted'
                          sentiment='neutral'
                          size='small'
                        >
                          <PlusSolid />
                          New regular template
                        </Button>
                      </NewLegacyTemplateLink>
                    </div>
                    <div className='flex gap-3 flex-wrap'>
                      {selectedTemplates.map((template) => {
                        return (
                          <LegacyTemplateCardButton
                            key={template.id}
                            template={template}
                          />
                        )
                      })}
                      <NewLegacyTemplateCardLink
                        workspaceId={selectedWorkspace?.id}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </FormShell>
    </ShellVars>
  )
}
