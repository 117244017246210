import { cssVar } from '@motion/theme/dom'
import { priorityLabels } from '@motion/ui-logic'
import { type PriorityLevelSchema } from '@motion/zod/client'

import { PriorityLabel } from '~/global/components/labels'

export function labelOfPriorityLevel(value: PriorityLevelSchema) {
  return {
    legend: priorityLabels.get(value) ?? value,
    tooltip: <PriorityLabel value={value} />,
    axis: priorityLabels.get(value) ?? value,
  }
}

export function colorOfPriorityLevel(value: PriorityLevelSchema) {
  switch (value) {
    case 'ASAP':
      return cssVar('palette-red-border-strong')
    case 'HIGH':
      return cssVar('palette-orange-border-strong')
    case 'MEDIUM':
      return cssVar('palette-yellow-border-strong')
    case 'LOW':
      return cssVar('palette-gray-border-strong')
    default:
      return cssVar('palette-gray-border-strong')
  }
}
