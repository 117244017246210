import { type FilterDefinition } from '~/areas/project-management/filters/components'
import {
  ConnectedAutoScheduledFilter,
  ConnectedColorFilter,
  ConnectedIsBlockedFilter,
  ConnectedIsUnvisitedStageFilter,
  ConnectedLabelFilter,
  ConnectedPriorityFilter,
  ConnectedProjectDateFilter,
  ConnectedProjectDefinitionFilter,
  ConnectedProjectFilter,
  ConnectedRecurringFilter,
  ConnectedStageFilter,
  ConnectedStatusFilter,
  ConnectedTaskDateFilter,
  ConnectedUserFilter,
} from '~/areas/project-management/filters/components/v2'
import { ConnectedFolderFilter } from '~/areas/project-management/filters/components/v2/buttons/connected-folder-filter'
import { ConnectedIsBlockingFilter } from '~/areas/project-management/filters/components/v2/buttons/connected-is-blocking-filter'

export const defaultTaskFilters: FilterDefinition<'tasks'>[] = [
  {
    key: 'assigneeUserIds',
    label: 'Assignees',
    renderDropdown: (props) => (
      <ConnectedUserFilter
        field='assigneeUserIds'
        label='Assignee'
        {...props}
      />
    ),
  },
  {
    key: 'stageDefinitionIds',
    label: 'Stages',
    renderDropdown: (props) => <ConnectedStageFilter {...props} />,
  },
  {
    key: 'isUnvisitedStage',
    label: 'Unvisited Stage',
    renderDropdown: (props) => <ConnectedIsUnvisitedStageFilter {...props} />,
  },
  {
    key: 'statusIds',
    label: 'Statuses',
    renderDropdown: (props) => <ConnectedStatusFilter {...props} />,
  },
  {
    key: 'labelIds',
    label: 'Labels',
    renderDropdown: (props) => <ConnectedLabelFilter {...props} />,
  },
  {
    key: 'priorities',
    label: 'Priority',
    renderDropdown: (props) => <ConnectedPriorityFilter {...props} />,
  },
  {
    key: 'createdTime',
    label: 'Created On',
    renderDropdown: (props) => (
      <ConnectedTaskDateFilter
        field='createdTime'
        label='Created on'
        {...props}
      />
    ),
  },
  {
    key: 'createdByUserIds',
    label: 'Created By',
    renderDropdown: (props) => (
      <ConnectedUserFilter
        field='createdByUserIds'
        label='Created by'
        hideEmptyUser
        {...props}
      />
    ),
  },
  {
    key: 'lastInteractedTime',
    label: 'Updated On',
    renderDropdown: (props) => (
      <ConnectedTaskDateFilter
        field='lastInteractedTime'
        label='Updated on'
        {...props}
      />
    ),
  },
  {
    key: 'completedTime',
    label: 'Completed On',
    renderDropdown: (props) => (
      <ConnectedTaskDateFilter
        field='completedTime'
        label='Completed on'
        {...props}
      />
    ),
  },
  {
    key: 'dueDate',
    label: 'Deadline',
    renderDropdown: (props) => (
      <ConnectedTaskDateFilter field='dueDate' label='Deadline' {...props} />
    ),
  },
  {
    key: 'scheduledStart',
    label: 'Scheduled Start',
    renderDropdown: (props) => (
      <ConnectedTaskDateFilter
        field='scheduledStart'
        label='Scheduled Start'
        {...props}
      />
    ),
  },
  {
    key: 'estimatedCompletionTime',
    label: 'Scheduled On',
    renderDropdown: (props) => (
      <ConnectedTaskDateFilter
        field='estimatedCompletionTime'
        label='Scheduled On'
        {...props}
      />
    ),
  },
  {
    key: 'autoScheduled',
    label: 'Auto-scheduled',
    renderDropdown: (props) => <ConnectedAutoScheduledFilter {...props} />,
  },
  {
    key: 'type',
    label: 'Recurring',
    renderDropdown: (props) => <ConnectedRecurringFilter {...props} />,
  },
  {
    key: 'isBlocked',
    label: 'Is Blocked',
    renderDropdown: (props) => <ConnectedIsBlockedFilter {...props} />,
  },
  {
    key: 'isBlocking',
    label: 'Is Blocking',
    renderDropdown: (props) => <ConnectedIsBlockingFilter {...props} />,
  },
]

export const defaultProjectFilters: FilterDefinition<'projects'>[] = [
  {
    key: 'ids',
    label: 'Name',
    renderDropdown: (props) => <ConnectedProjectFilter {...props} />,
  },
  {
    key: 'managerIds',
    label: 'Assignees',
    renderDropdown: (props) => (
      <ConnectedUserFilter field='managerIds' label='Assignee' {...props} />
    ),
  },
  {
    key: 'stageDefinitionIds',
    label: 'Stages',
    renderDropdown: (props) => <ConnectedStageFilter {...props} />,
  },
  {
    key: 'projectDefinitionIds',
    label: 'Templates',
    renderDropdown: (props) => <ConnectedProjectDefinitionFilter {...props} />,
  },
  {
    key: 'statusIds',
    label: 'Statuses',
    renderDropdown: (props) => <ConnectedStatusFilter {...props} />,
  },
  {
    key: 'labelIds',
    label: 'Labels',
    renderDropdown: (props) => <ConnectedLabelFilter {...props} />,
  },
  {
    key: 'priorities',
    label: 'Priority',
    renderDropdown: (props) => <ConnectedPriorityFilter {...props} />,
  },
  {
    key: 'createdTime',
    label: 'Created On',
    renderDropdown: (props) => (
      <ConnectedProjectDateFilter
        field='createdTime'
        label='Created on'
        {...props}
      />
    ),
  },
  {
    key: 'createdByUserIds',
    label: 'Created By',
    renderDropdown: (props) => (
      <ConnectedUserFilter
        field='createdByUserIds'
        label='Created by'
        hideEmptyUser
        {...props}
      />
    ),
  },
  {
    key: 'updatedTime',
    label: 'Updated On',
    renderDropdown: (props) => (
      <ConnectedProjectDateFilter
        field='updatedTime'
        label='Updated on'
        {...props}
      />
    ),
  },
  {
    key: 'dueDate',
    label: 'Deadline',
    renderDropdown: (props) => (
      <ConnectedProjectDateFilter field='dueDate' label='Deadline' {...props} />
    ),
  },
  {
    key: 'startDate',
    label: 'Start Date',
    renderDropdown: (props) => (
      <ConnectedProjectDateFilter
        field='startDate'
        label='Start Date'
        {...props}
      />
    ),
  },
  {
    key: 'folderIds',
    label: 'Folders',
    renderDropdown: (props) => <ConnectedFolderFilter {...props} />,
  },
  {
    key: 'color',
    label: 'Color',
    renderDropdown: (props) => <ConnectedColorFilter {...props} />,
  },
]
