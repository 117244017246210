import {
  type MeetingActionItemSchema,
  type MeetingInsightsSchema,
} from '@motion/zod/client'

import { createContext } from 'react'

type CreateNewActionItemContextType = {
  actionItem: MeetingActionItemSchema | null
  createNewActionItem: (meetingInsights: MeetingInsightsSchema) => void
  resetActionItem: () => void
  creatingNewActionItem: boolean
}

export const CreateNewActionItemContext = createContext<
  CreateNewActionItemContextType | undefined
>(undefined)
