import { type FullCalendarEvent } from '~/areas/calendar/utils'
import { DateTime } from 'luxon'

export function disableEventEditable<T extends FullCalendarEvent>(event: T): T {
  return {
    ...event,
    durationEditable: false,
    startEditable: false,
    resourceEditable: false,
    editable: false,
  }
}

/**
 * Handles the case where an event has a zero duration. In this case, the end time is set to 15 minutes after the start time. This is done to ensure that the event is visible in the calendar.
 */
export function parseZeroDuration<T extends FullCalendarEvent>(event: T): T {
  let end = event.end

  const zeroDuration = DateTime.fromISO(end).equals(
    DateTime.fromISO(event.start)
  )

  if (zeroDuration) {
    end = DateTime.fromISO(end).plus({ minutes: 15 }).toISO()
  }

  return {
    ...event,
    end,
    extendedProps: {
      ...event.extendedProps,
      zeroDuration,
    },
  }
}
