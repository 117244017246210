import {
  type BulkUpdateProjectStagesRequest,
  type ProjectSchema,
} from '@motion/rpc-types'
import { Sentry } from '@motion/web-base/sentry'

import { resolveAllStageProjectedDates } from '~/areas/project/modals/project-eta-modals/utils'
import { useBulkUpdateProjectStagesDueDate } from '~/global/rpc/v2/project'
import { showErrorToast } from '~/global/toasts'
import { DateTime } from 'luxon'
import { useCallback } from 'react'

export const useUpdateProjectStagesToProjectedDates = () => {
  const { mutateAsync } = useBulkUpdateProjectStagesDueDate()

  return useCallback(
    async (project: ProjectSchema, newProjectDueDate: string) => {
      const projectId = project.id
      const projectStages = project.stages

      const stages = resolveAllStageProjectedDates(
        projectStages,
        project,
        DateTime.fromISO(newProjectDueDate)
      )

      const updateArgs: BulkUpdateProjectStagesRequest = {
        updates: stages.map((stage) => ({
          stageDefinitionId: stage.stageDefinitionId,
          dueDate: stage.dueDate,
        })),
      }

      try {
        return mutateAsync({ ...updateArgs, projectId })
      } catch (error) {
        showErrorToast('Failed to update project stages')

        Sentry.captureException(error, {
          tags: { position: 'updateProjectStagesToProjectedDates' },
        })

        throw error
      }
    },
    [mutateAsync]
  )
}
