import {
  FieldButton,
  type FieldButtonProps,
  PopoverTrigger,
  SearchableList,
} from '@motion/ui/base'
import {
  createDurationsFromText,
  formatToTaskDuration,
  getDurations,
} from '@motion/ui-logic'

import {
  ModalFieldButton,
  type ModalFieldButtonProps,
} from '~/areas/ai-hackerhouse/event/modals/action-item-modal/components/modal-field-button'
import { type DurationDropdownProps } from '~/areas/project-management/components'
import { useTaskUpdater } from '~/areas/tasks/hooks'
import { useMemo } from 'react'
import { useController } from 'react-hook-form'

import { useActionItemForm } from '../hooks'

export const DurationField = ({
  showLabel = true,
  size = 'normal',
}: {
  showLabel?: boolean
  size?: ModalFieldButtonProps['size']
}) => {
  return (
    <ModalFieldButton label={showLabel ? 'Duration' : undefined} size={size}>
      <div className='flex items-center gap-[3px]'>
        <DurationPopover variant='muted' size='small' />
      </div>
    </ModalFieldButton>
  )
}

type DurationPopoverProps = Pick<FieldButtonProps, 'variant' | 'size'>

function DurationPopover(buttonProps: DurationPopoverProps) {
  const { form } = useActionItemForm()
  const { control } = form

  const { field: durationField } = useController({
    name: 'duration',
    control,
  })

  const selectedDurationValue = durationField.value

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => <DurationPopoverContent close={close} />}
    >
      <FieldButton {...buttonProps} id='duration' iconOnly>
        <span className='whitespace-nowrap'>
          {formatToTaskDuration(selectedDurationValue)}
        </span>
      </FieldButton>
    </PopoverTrigger>
  )
}

function DurationPopoverContent({ close }: { close: () => void }) {
  const { form } = useActionItemForm()
  const { control } = form

  const durations = useMemo(() => getDurations(), [])

  const { field: durationField } = useController({
    name: 'duration',
    control,
  })

  const selectedDurationValue = durationField.value

  // if task is already created, can edit in-line
  const taskId = form.watch('id')
  const updateTask = useTaskUpdater()

  const onChange: DurationDropdownProps['onChange'] = async (duration) => {
    durationField.onChange(duration)
    if (taskId != null) {
      await updateTask(taskId, { duration })
    }
  }

  return (
    <SearchableList
      items={durations}
      computeKey={(item) => String(item.value)}
      computeSearchValue={(item) => item.label}
      computeSelected={(item) => item.value === selectedDurationValue}
      onSelect={(item) => {
        onChange(item.value)
        close()
      }}
      renderItem={(item) => item.label}
      filter={(search) => {
        if (search) {
          return createDurationsFromText(search)
        }
        return durations
      }}
      inputProps={{ placeholder: 'Choose or type a duration' }}
    />
  )
}
