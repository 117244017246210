import { type CalendarProviderType } from '@motion/rpc/types'
import { type DayVerbose, type Schedule } from '@motion/rpc-types'
import {
  type EventConferenceType,
  type ZoomLinkType,
} from '@motion/shared/common'

export const Days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
] as DayVerbose[]

export type ScheduleCollection = Record<string, Schedule>

export type MotionTheme = 'light' | 'dark' | 'systemSettings'

/**
 * Note: this is only a subset of settings
 */
export interface UserSettings {
  schedules: ScheduleCollection
  theme?: MotionTheme
}

export interface ConferenceSettings {
  conferenceType: EventConferenceType
  customLocation: string
  phoneNumber: string
  zoomAccount: string
  zoomLinkType: ZoomLinkType
  zoomManualLink: string
  zoomPersonalLink: string
}

/**
 * Temporary type for genericizing the Calendar definition across providers.
 * This will be replaced with a Prisma model
 * @deprecated Use `Calendar` from @motion/rpc
 */
export type Calendar = {
  /**
   * Access role the current user has on the calendar
   */
  accessRole: 'read' | 'write' | 'owner'
  /**
   *
   */
  allowedConferenceTypes?: EventConferenceType[]
  /**
   * Color identifier - currently provider-specific, but we probably want to use
   * a hex value
   */
  colorId: string | null
  /**
   * Email account the calendar belongs to
   */
  email: string
  /**
   * Provider ID of the calendar
   */
  id: string
  /**
   * Whether the calendar is currently visible. If the calendar is the primary
   * calendar, then this will always be true. For other calendars, this controls
   * whether the calendar events for the current calendar are visible on the
   * frontend.
   * Was `active` in `CalendarListEntry`
   */
  isActive: boolean
  /**
   * Whether the calendar is a distinct calendar within the email account.
   * Certain calendars, such as teammate  and frequently met with
   * calendars, are generated by the application, and thus may need to handled
   * differently when performing certain operations (e.g. MS Graph queries
   * "schedules" for teammate calendars)
   */
  isInCalendarList: boolean
  /**
   * Calendars that show up under the "Frequently met with" section
   * Was `frequent` in `CalendarListEntry`
   */
  isInFrequentlyMetWith?: boolean
  /**
   * The "My Calendars" section affects the update function, preventing tasks
   * from scheduling over events associated to calendars in "My Calendars"
   * Was `mine` in `CalendarListEntry`
   */
  isInMyCalendars?: boolean
  /**
   * Whether this calendar is the primary calendar of the email account. There
   * can only be one 'primary' calendar per email account
   * Was `primary` in `CalendarListEntry`
   */
  isPrimary: boolean
  /**
   * The calendar provider the calendar belongs to
   */
  provider: CalendarProviderType
  /**
   * Calendar name
   */
  title: string | null
  /**
   *
   */
  userId: string
}

export type EmailTemplateType = {
  body?: string
  subject: string
}
