import { type EmailAccount } from '@motion/rpc/types'
import {
  type ColorId,
  isCalendarEventEditable,
  parseColorId,
} from '@motion/ui-logic'
import {
  type CalendarEventSchemaV2,
  type ScheduledEntitySchema,
} from '@motion/zod/client'

import { type LookupFn } from '~/global/cache'

import { type FullCalendarMotionEvent } from './types'

export function getFullCalendarEventId(
  eventId: string,
  modifier?: string
): `event|${string}` | `event|${string}|${string}` {
  // If the event id already has a modifier, don't add another one
  const modifierPart =
    modifier && eventId.split('|').length < 2 ? `|${modifier}` : ''

  const mainPart = eventId.includes('event|') ? eventId : `event|${eventId}`

  if (modifier != null) {
    return (mainPart + modifierPart) as `event|${string}|${string}`
  }

  return mainPart as `event|${string}`
}

type EventToFullCalendarEventArgs = {
  calendarEvent: CalendarEventSchemaV2
  lookupFn: LookupFn
  entity: ScheduledEntitySchema
  emailAccountMap?: Map<string, EmailAccount>
  modifier?: string
}

export function eventToFullCalendarEvent({
  calendarEvent,
  entity,
  lookupFn,
  emailAccountMap = new Map(),
  modifier,
}: EventToFullCalendarEventArgs): FullCalendarMotionEvent | undefined {
  if (entity.type !== 'EVENT') return
  const eventCalendar = lookupFn('calendars', calendarEvent.calendarId)
  if (!eventCalendar) return

  const emailAccount = emailAccountMap.get(eventCalendar.emailAccountId)
  if (!emailAccount) return

  const isEventEditable = isCalendarEventEditable(
    // TODO: Fix this type.
    // @ts-expect-error type for attendee status includes undefined
    calendarEvent,
    eventCalendar,
    emailAccount?.email
  )

  const curUserAttendee = calendarEvent.attendees?.find(
    (a) => a.email === calendarEvent.email
  )

  const meetingTask = calendarEvent.meetingTaskId
    ? lookupFn('tasks', calendarEvent.meetingTaskId)
    : null
  const meetingTaskProject = meetingTask?.projectId
    ? lookupFn('projects', meetingTask?.projectId)
    : null

  return {
    durationEditable: isEventEditable,
    end: entity.schedule.end,
    id: getFullCalendarEventId(calendarEvent.id, modifier),
    resourceEditable: isEventEditable,
    resourceId: 'mine',
    start: entity.schedule.start,
    startEditable: calendarEvent.isAllDay ? false : isEventEditable,
    title: calendarEvent.title,
    allDay: calendarEvent.isAllDay,
    travelTimeAfter: calendarEvent.travelTimeAfter,
    travelTimeBefore: calendarEvent.travelTimeBefore,
    description: calendarEvent.description ?? undefined,
    extendedProps: {
      attendees: calendarEvent.attendees,
      eventOwnerEmail: calendarEvent.email,
      organizer: calendarEvent.organizer,
      otherCalendarColorIds: entity.calendarIds
        .filter((calId) => calId !== calendarEvent.calendarId)
        .map((calId) => parseColorId(lookupFn('calendars', calId)?.colorId)),
      colorId: calendarEvent.colorId as ColorId | null,
      calendarColorId: eventCalendar.colorId as ColorId,
      calendarUniqueId: calendarEvent.calendarUniqueId,
      isDeclined: curUserAttendee?.status === 'declined',
      rsvp: curUserAttendee?.status,
      onMyCalendar: eventCalendar.isInMyCalendars,
      availability: calendarEvent.status,
      travelTimeAfter: calendarEvent.travelTimeAfter,
      travelTimeBefore: calendarEvent.travelTimeBefore,
      eventLocation: calendarEvent.location ?? undefined,
      conferenceType: calendarEvent.conferenceType ?? undefined,
      conferenceLink: calendarEvent.conferenceLink ?? undefined,
      recurringEventId: calendarEvent.recurringEventId ?? undefined,
      meetingTaskId: calendarEvent.meetingTaskId,
      meetingTaskProject: meetingTaskProject,
    },
  }
}
