import { MagicWandSolid } from '@motion/icons'
import { FormModal } from '@motion/ui/base'
import { getPercentageTimeChange, templateStr } from '@motion/ui-logic'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { type ProjectSchema } from '@motion/zod/client'

import type { ModalTriggerComponentProps } from '~/areas/modals'
import { useProjectStagesWithDefinitions } from '~/areas/project/hooks/data'
import { ProjectBadge } from '~/global/components/badges'
import { DateTime } from 'luxon'

import {
  ProjectExtendedTimeline,
  ProjectStagesTimeline,
  ProjectStageUpdateDetails,
} from '../components'
import {
  BodyContainer,
  LeftSideContainer,
  RightSideContainer,
  TimelinesContainer,
  TimelineSectionContainer,
  TimelineSectionTitle,
} from '../styled'
import { resolveAllStageProjectedDates } from '../utils'

export type OptimizeProjectModalProps = {
  project: ProjectSchema
}

export function OptimizeProjectModal({
  close,
  project,
}: ModalTriggerComponentProps<'optimize-project'>) {
  return (
    <FormModal
      visible
      onClose={() => close(false)}
      submitAction={{
        onAction: () => {
          recordAnalyticsEvent('ETA_OPTIMIZE_MODAL_BUTTON_OPTIMIZE')
          close(true)
        },
        text: 'Optimize and update project dates',
      }}
      cancelAction={{
        shortcut: 'esc',
      }}
      title={<OptimizeProjectHeader project={project} />}
      bodyPadded={false}
    >
      <OptimizeProjectModalBody project={project} />
    </FormModal>
  )
}

function OptimizeProjectHeader({
  project,
}: {
  project: Pick<ProjectSchema, 'id' | 'name' | 'color' | 'statusId'>
}) {
  return (
    <div className='flex flex-row gap-3 items-center'>
      <MagicWandSolid className='size-[18px] text-semantic-neutral-icon-default' />
      <span className='text-md text-semantic-neutral-text-default'>
        Optimize
      </span>
      <div className='flex flex-row gap-1 items-center'>
        <ProjectBadge value={project} size='medium' hideTooltip />
        <span className='text-md text-semantic-neutral-text-default'>
          {project.name}
        </span>
      </div>
    </div>
  )
}

type OptimizeProjectModalBodyProps = {
  project: ProjectSchema
}

function OptimizeProjectModalBody({ project }: OptimizeProjectModalBodyProps) {
  if (
    project.startDate == null ||
    project.dueDate == null ||
    project.estimatedCompletionTime == null
  )
    return null

  const startDate = DateTime.fromISO(project.startDate)
  const dueDate = DateTime.fromISO(project.dueDate)
  const projectedDate = DateTime.fromISO(project.estimatedCompletionTime)

  const percentageTimeChange = getPercentageTimeChange({
    startDate,
    currentDueDate: dueDate,
    newDueDate: projectedDate,
    rounded: false,
  })

  return (
    <BodyContainer>
      <LeftSideContainer>
        <OptimizeDescription percentageTimeChange={percentageTimeChange} />
        <OptimizedTimelines
          startDate={startDate}
          dueDate={dueDate}
          projectedDate={projectedDate}
          project={project}
          percentageTimeChange={percentageTimeChange}
        />
      </LeftSideContainer>
      <RightSideContainer>
        <ProjectStageUpdateDetails project={project} />
      </RightSideContainer>
    </BodyContainer>
  )
}

type OptimizeDescriptionProps = {
  percentageTimeChange: number
}

function OptimizeDescription({
  percentageTimeChange,
}: OptimizeDescriptionProps) {
  return (
    <div className='flex flex-col gap-[11px] text-semantic-neutral-text-subtle text-[13px] font-normal leading-5'>
      <p>
        {templateStr(
          'Motion can save you {{percentage}} time by adjusting deadlines.',
          {
            percentage: <b>{Math.round(Math.abs(percentageTimeChange))}%</b>,
          }
        )}
      </p>
      <p>
        We do this by projecting all known tasks onto assignees calendars and
        calculating an optimal stage date for each stage, then calculating the
        optimal project deadline.
      </p>
    </div>
  )
}

type OptimizedTimelinesProps = {
  startDate: DateTime
  dueDate: DateTime
  projectedDate: DateTime
  project: ProjectSchema
  percentageTimeChange: number
}

function OptimizedTimelines({
  startDate,
  dueDate,
  projectedDate,
  project,
  percentageTimeChange,
}: OptimizedTimelinesProps) {
  const stagesWithDefinitions = useProjectStagesWithDefinitions(
    { stages: project.stages },
    {
      includeCancelledStages: false,
    }
  )

  const currentStagesWithPositions = stagesWithDefinitions.map((stage) => ({
    positionDate: DateTime.fromISO(stage.dueDate),
    ...stage,
  }))

  const projectedStagesWithPositions = resolveAllStageProjectedDates(
    stagesWithDefinitions,
    project,
    projectedDate
  ).map((stage, index) => ({
    positionDate: DateTime.fromISO(stage.dueDate),
    ...stagesWithDefinitions[index],
  }))

  const afterWidthPercent =
    computeAfterTimelineWidthPercent(percentageTimeChange)

  return (
    <TimelinesContainer>
      <TimelineSectionContainer>
        <TimelineSectionTitle>Before</TimelineSectionTitle>
        <div className='w-full'>
          <ProjectStagesTimeline
            startDate={startDate}
            endDate={dueDate}
            stagesWithPositions={currentStagesWithPositions}
          />
        </div>
      </TimelineSectionContainer>
      <TimelineSectionContainer>
        <TimelineSectionTitle>After</TimelineSectionTitle>
        <div className='w-full'>
          <div
            style={{
              width: `${afterWidthPercent}%`,
            }}
          >
            <ProjectStagesTimeline
              startDate={startDate}
              endDate={projectedDate}
              stagesWithPositions={projectedStagesWithPositions}
            />
          </div>
          <ProjectExtendedTimeline
            percentageTimeChange={Math.round(percentageTimeChange)}
          />
        </div>
      </TimelineSectionContainer>
    </TimelinesContainer>
  )
}

function computeAfterTimelineWidthPercent(percentageTimeChange: number) {
  return 100 - Math.abs(percentageTimeChange)
}
