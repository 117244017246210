import { CheckSolid, XSolid } from '@motion/icons'
import { isNoneId } from '@motion/shared/identifiers'
import { ButtonGroup, IconButton, Tooltip } from '@motion/ui/base'
import type { MeetingActionItemSchema } from '@motion/zod/client'

import { useActionItemForm } from '~/areas/ai-hackerhouse/event/modals/action-item-modal/hooks'
import {
  useApproveActionItem,
  useRejectActionItem,
} from '~/areas/ai-hackerhouse/hooks/rpc'
import { useCreateNewActionItemContext } from '~/areas/ai-hackerhouse/hooks/use-create-new-action-item-context'

export function ApproveRejectButtons({
  actionItem,
}: {
  actionItem: MeetingActionItemSchema
}) {
  const { form } = useActionItemForm()
  const { mutateAsync: rejectAsync, isLoading: rejectIsLoading } =
    useRejectActionItem()
  const { mutateAsync: approveAsync, isLoading: approveIsLoading } =
    useApproveActionItem()

  const { resetActionItem } = useCreateNewActionItemContext()

  const name = form.watch('name')
  const workspaceId = form.watch('workspaceId')
  const assigneeUserId = form.watch('assigneeUserId')
  const duration = form.watch('duration')
  const dueDate = form.watch('dueDate')

  const canApprove =
    workspaceId != null &&
    !isNoneId(workspaceId) &&
    assigneeUserId != null &&
    duration != null &&
    dueDate != null &&
    name != null &&
    name.length > 0

  const handleReject = async () => {
    if (isNoneId(actionItem.id)) {
      resetActionItem()
      return
    }

    await rejectAsync({
      actionItemId: actionItem.id,
    })
  }

  const handleApprove = async () => {
    await approveAsync({
      actionItemId: actionItem.id,
      meetingInsightsId: actionItem.meetingInsightsId,
      ...form.getValues(),
    })

    if (isNoneId(actionItem.id)) {
      resetActionItem()
    }
  }

  return (
    <div className='self-center shrink-0'>
      <ButtonGroup>
        <Tooltip
          content={
            !canApprove ? 'Please select values for all fields' : 'Approve'
          }
          asChild
        >
          <IconButton
            disabled={!canApprove || rejectIsLoading}
            onClick={handleApprove}
            sentiment='success'
            variant='outlined'
            size='small'
            icon={CheckSolid}
            loading={approveIsLoading}
          />
        </Tooltip>

        <Tooltip content='Reject' asChild>
          <IconButton
            disabled={approveIsLoading}
            onClick={handleReject}
            sentiment='error'
            variant='outlined'
            size='small'
            icon={XSolid}
            loading={rejectIsLoading}
          />
        </Tooltip>
      </ButtonGroup>
    </div>
  )
}
