import { type MeetingUrlSearchParams } from '@motion/ui-logic'
import { HasExperiment } from '@motion/web-common/flags'

import { useSearchParams } from '~/routing'

import { ConnectedMeetingModalAiHackerhouse } from '../ai-hackerhouse/event/modals/event-modal'
import { ConnectedMeetingModal } from '../event/modals/event-modal'

export const GlobalMeetingModal = () => {
  const { mTask } = useSearchParams<MeetingUrlSearchParams>()

  const showMeetingModal = mTask != null

  return (
    <HasExperiment
      name='ai-meeting-bot'
      fallback={<ConnectedMeetingModal open={showMeetingModal} />}
    >
      <ConnectedMeetingModalAiHackerhouse open={showMeetingModal} />
    </HasExperiment>
  )
}
