import type {
  MeetingActionItemSchema,
  MeetingInsightsSchema,
} from '@motion/zod/client'

import { CreateNewActionItemContext } from '~/areas/ai-hackerhouse/context/create-new-action-item-context'
import React, { type ReactNode, useMemo, useState } from 'react'

import { createFakeActionItem } from './util'

type CreateNewActionItemProviderProps = { children: ReactNode }

export function CreateNewActionItemProvider({
  children,
}: CreateNewActionItemProviderProps) {
  const [actionItem, setActionItem] = useState<MeetingActionItemSchema | null>(
    null
  )

  // Function to create a new action item
  const createNewActionItem = (meetingInsights: MeetingInsightsSchema) => {
    setActionItem(createFakeActionItem(meetingInsights))
  }

  // Function to reset the action item
  const resetActionItem = () => {
    setActionItem(null)
  }

  const value = useMemo(
    () => ({
      actionItem,
      createNewActionItem,
      resetActionItem,
      creatingNewActionItem: actionItem != null,
    }),
    [actionItem]
  )

  return (
    <CreateNewActionItemContext.Provider value={value}>
      {children}
    </CreateNewActionItemContext.Provider>
  )
}
