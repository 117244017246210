import {
  createStateKey,
  type SharedStateSetterValue,
  useSharedStateQuery,
  useSharedStateSendOnly,
} from '@motion/react-core/shared-state'
import { getWeekOf } from '@motion/utils/dates'
import {
  type ScheduledEntitiesFilterSchema,
  type ScheduledEntitiesIncludeSchema,
} from '@motion/zod/client'

import { DateTime } from 'luxon'
import { useCallback, useMemo } from 'react'

import { useCalendarStartDay } from './use-calendar-start-day'

import { type NewEventType } from '../utils'

export type CalendarState = {
  calendarView: 'day' | 'week' | 'month'
  includedEntities: Record<ScheduledEntitiesIncludeSchema, boolean>
  isDraggingEvent: boolean
  myCalendarsOnly: ScheduledEntitiesFilterSchema['myCalendarsOnly']
  selectedCalendarEvent: { id: string; new?: NewEventType } | null
  // The currently selected date (shifting calendar will adjust by 1 week)
  selectedDate: DateTime
  settings: {
    // Whether to include or exclude completed entities
    showCompletedEntities: ScheduledEntitiesFilterSchema['completed']
    showAgendaEvents: boolean
  }
  // The last unselected time, use to prevent the peek modal from opening when clicking on an empty spot if it just closed.
  unselectCalendarEventTimeStamp: number
}

export const CALENDAR_STATE_DEFAULT_VALUE: CalendarState = {
  selectedDate: DateTime.now().startOf('day'),
  myCalendarsOnly: false,
  unselectCalendarEventTimeStamp: new Date().getTime(),
  selectedCalendarEvent: null,
  isDraggingEvent: false,
  calendarView: 'week',
  includedEntities: {
    task: true,
    blockingTimeslot: true,
    event: true,
  },
  settings: {
    showCompletedEntities: 'exclude',
    showAgendaEvents: false,
  },
}

export const CalendarsStateKey = createStateKey<CalendarState>(
  'calendarState',
  {
    defaultValue: CALENDAR_STATE_DEFAULT_VALUE,
  }
)

export const useSendCalendarState = () => {
  const setState = useSharedStateSendOnly(CalendarsStateKey)
  return useCallback(
    (
      changes: Partial<CalendarState> | SharedStateSetterValue<CalendarState>
    ) => {
      if (typeof changes === 'function') {
        setState(changes)
        return
      }
      setState((prev) => ({ ...prev, ...changes }))
    },
    [setState]
  )
}

export const useCalendarState = <T>(query: (data: CalendarState) => T) =>
  useSharedStateQuery(CalendarsStateKey, query)

export const useCalendarStartAndEnd = () => {
  const selectedDate = useSharedStateQuery(
    CalendarsStateKey,
    (data) => data.selectedDate
  )
  const startDay = useCalendarStartDay()

  const weekOfSelectedDay = getWeekOf(selectedDate, {
    startingDay: startDay,
  })

  return useMemo(() => {
    return {
      from: weekOfSelectedDay.start.toISO(),
      to: weekOfSelectedDay.end.toISO(),
    }
  }, [weekOfSelectedDay.end, weekOfSelectedDay.start])
}

export const useSyncFirebaseCalendarState = () => {
  const setState = useSharedStateSendOnly(CalendarsStateKey)

  const setCalendarStateSettings = useCallback(
    (changes: Partial<CalendarState['settings']>) => {
      setState((prev) => ({
        ...prev,
        settings: { ...prev.settings, ...changes },
      }))
    },
    [setState]
  )

  return useCallback(
    (newSettings: Record<string, any>) => {
      if ('showCompletedTasks' in newSettings) {
        setCalendarStateSettings({
          showCompletedEntities: newSettings.showCompletedTasks
            ? 'include'
            : 'exclude',
        })
      }

      if ('showAgendaEvents' in newSettings) {
        setCalendarStateSettings({
          showAgendaEvents: newSettings.showAgendaEvents,
        })
      }
    },
    [setCalendarStateSettings]
  )
}
