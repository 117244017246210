import { type ProjectSchema } from '@motion/rpc-types'
import { parseDate } from '@motion/utils/dates'

import { BarOverlay } from './components'
import { useResizeStageState } from './hooks/use-resize-stage-state'
import { ResizeableProjectStageItem } from './resizeable-stage-item'
import { type StageWithDates } from './types'
import { getInitialStageLocation } from './utils'

import { usePlannerProps } from '../../../context'
import { type Side } from '../resize-handle'

type OriginalStagesBarProps = {
  stagesWithDates: StageWithDates[]
  project: ProjectSchema
  projectDeltaWidth?: number
  currentSide?: Side
}
export const OriginalStagesBar = (props: OriginalStagesBarProps) => {
  const { currentSide, project, stagesWithDates, projectDeltaWidth } = props
  const [resizingStagesState] = useResizeStageState()

  const [plannerProps] = usePlannerProps()

  const isProjectResizing = project.id === plannerProps.resizingId
  const isProjectStagesResizing =
    resizingStagesState.isResizing &&
    resizingStagesState.stageDetails?.projectId === project.id
  const isResizing = isProjectResizing || isProjectStagesResizing

  if (!isResizing || !stagesWithDates.length) {
    return null
  }
  const { initialProjectWidth } = getInitialStageLocation({
    dayPx: plannerProps.dayPx,
    stageWithDates: stagesWithDates[0],
    project,
  })

  const addMarginLeft = currentSide === 'left'

  return (
    <div
      className='h-2.5 absolute -bottom-2.5 opacity-40'
      style={{
        width: initialProjectWidth,
        marginLeft: addMarginLeft ? projectDeltaWidth : undefined,
      }}
    >
      <BarOverlay />
      <div className='flex items-center size-full max-w-full overflow-hidden rounded-b-md px-0.5'>
        {stagesWithDates.map((stage, stageIndex) => {
          if (!stage.stage?.id) return null

          const { maxStageWidth, pixelsFromProjectStart, completedDuration } =
            getInitialStageLocation({
              dayPx: plannerProps.dayPx,
              stageWithDates: stage,
              project,
            })

          const startDate = parseDate(stage.start)
          const endDate = parseDate(stage.due)

          return (
            <ResizeableProjectStageItem
              key={stage.stage?.id ?? `stage-key-${stageIndex}`}
              project={project}
              stage={stage.stage}
              startDate={startDate}
              endDate={endDate}
              maxStageWidth={maxStageWidth}
              stageLeft={pixelsFromProjectStart}
              stageProgressWidth={completedDuration}
              isResizing
            />
          )
        })}
      </div>
    </div>
  )
}
