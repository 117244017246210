import { DatePicker } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { setBaseDate } from '~/state/calendar/calendarSlice'
import { useAppDispatch } from '~/state/hooks'
import { DateTime } from 'luxon'

import {
  useCalendarDateNavigation,
  useCalendarStartDay,
  useCalendarState,
  useSendCalendarState,
} from '../../hooks'

export function MiniCalendar() {
  const dispatch = useAppDispatch()
  const setCalendarState = useSendCalendarState()
  const selectedDate = useCalendarState((state) => state.selectedDate)

  const calendarStartDay = useCalendarStartDay()
  const calendarNavigation = useCalendarDateNavigation()

  const onSelectDateHandler = (date: DateTime) => {
    setCalendarState({ selectedDate: date })
    dispatch(setBaseDate(date.toISO()))
    recordAnalyticsEvent('MINI_CALENDAR_DATE_SELECT')
  }

  return (
    <DatePicker
      weekStartDay={calendarStartDay}
      onChange={(value: string | string[] | null) => {
        // disable arrays for now.
        if (value == null || Array.isArray(value)) return
        onSelectDateHandler(DateTime.fromISO(value))
      }}
      onToday={() => {
        calendarNavigation.today()
      }}
      size='small'
      value={selectedDate.toISO()}
      showTodayButton
      showDayNumberSupertext
    />
  )
}
