import { type COLOR, type DeadlineStatus } from '@motion/shared/common'
import { isNoneId } from '@motion/shared/identifiers'
import { formatToTaskDuration, truncateAtSpace } from '@motion/ui-logic'
import { createLookup } from '@motion/utils/object'
import {
  type FolderSchema,
  type LabelSchema,
  type PriorityLevelSchema,
  type ProjectDefinitionSchema,
  type ProjectSchema,
  type StageDefinitionSchema,
  type StatusSchema,
  type TaskSchema,
  type UserInfoSchema,
  type WorkspaceSchema,
} from '@motion/zod/client'

// eslint-disable-next-line import-x/no-restricted-paths
import { type SelectOption } from '~/areas/custom-fields/components'
import { type BadgeSize } from '~/global/components/badges'

import { ColorLabel } from './color'
import { EtaLabel } from './eta'
import { FolderLabel } from './folder'
import { Label } from './label'
import { CustomFieldTypedNumberLabel } from './number'
import { PriorityLabel } from './priority'
import { ProjectLabel } from './project'
import { StageLabel } from './stage'
import { StatusLabel } from './status'
import { Truncate, UrlLabel } from './url'
import { UserLabel } from './user'
import { WorkspaceLabel } from './workspace'

export type TaskFieldLabelProps<T> = {
  value: T
  // "key" is reserved by React
  keyProp: string
  size?: BadgeSize
}

export const getLabelComponent = createLookup({
  user: ({ value }: TaskFieldLabelProps<UserInfoSchema>) => (
    <UserLabel value={value} />
  ),
  stage: ({
    value,
    size,
  }: TaskFieldLabelProps<StageDefinitionSchema | null>) => (
    <StageLabel value={value} size={size} />
  ),
  status: ({ value, size }: TaskFieldLabelProps<StatusSchema>) => (
    <StatusLabel value={value} size={size} />
  ),
  label: ({ value }: TaskFieldLabelProps<LabelSchema>) => (
    <Label value={value} />
  ),
  project: ({
    value,
    size,
    clickable,
  }: TaskFieldLabelProps<ProjectSchema> & {
    clickable?: boolean
  }) => {
    return <ProjectLabel value={value} size={size} clickable={clickable} />
  },
  priority: ({
    value,
    size,
  }: TaskFieldLabelProps<PriorityLevelSchema | null>) =>
    value != null ? <PriorityLabel value={value} size={size} /> : 'No Priority',
  workspace: ({ value }: TaskFieldLabelProps<WorkspaceSchema>) => (
    <WorkspaceLabel value={value} />
  ),
  projectDefinition: ({
    value,
  }: TaskFieldLabelProps<ProjectDefinitionSchema | null>) =>
    value && !isNoneId(value.id) ? (
      <Truncate> {value.name}</Truncate>
    ) : (
      'No Template'
    ),

  deadline: ({ keyProp: key }: TaskFieldLabelProps<string>) => (
    <Truncate>{key}</Truncate>
  ),
  createdBy: ({ value }: TaskFieldLabelProps<UserInfoSchema>) => (
    <UserLabel value={value} />
  ),
  createdAt: ({ keyProp: key }: TaskFieldLabelProps<string>) => (
    <Truncate>{key}</Truncate>
  ),
  completedAt: ({ keyProp: key }: TaskFieldLabelProps<string>) => (
    <Truncate>{key}</Truncate>
  ),
  updatedAt: ({ keyProp: key }: TaskFieldLabelProps<string>) => (
    <Truncate>{key}</Truncate>
  ),
  startDate: ({ keyProp: key }: TaskFieldLabelProps<string>) => (
    <Truncate>{key}</Truncate>
  ),
  scheduledDate: ({ keyProp: key }: TaskFieldLabelProps<string>) => (
    <Truncate>{key}</Truncate>
  ),
  folder: ({ value }: TaskFieldLabelProps<FolderSchema>) => (
    <FolderLabel value={value} />
  ),
  deadlineStatus: ({ value }: TaskFieldLabelProps<DeadlineStatus>) => (
    <EtaLabel value={value} />
  ),
  color: ({ value }: TaskFieldLabelProps<COLOR>) => (
    <ColorLabel color={value} />
  ),
  isAutoScheduled: ({ value }: TaskFieldLabelProps<boolean>) => (
    <span>Auto-scheduled: {value ? 'Yes' : 'No'}</span>
  ),
  duration: ({ value }: TaskFieldLabelProps<TaskSchema['duration']>) => (
    <span>{formatToTaskDuration(value)}</span>
  ),

  // Custom fields
  text: ({ value }: TaskFieldLabelProps<string>) => (
    <Truncate>{value}</Truncate>
  ),
  select: ({ value }: TaskFieldLabelProps<SelectOption>) => (
    <Label
      value={{
        id: value.id,
        name: truncateAtSpace(value.value, 25),
        color: value.color,
      }}
    />
  ),
  multiSelect: ({ value }: TaskFieldLabelProps<SelectOption>) => (
    <Label
      value={{
        id: value.id,
        name: truncateAtSpace(value.value, 25),
        color: value.color,
      }}
    />
  ),
  date: ({ value, keyProp: key }: TaskFieldLabelProps<string>) => {
    const isFake = isNoneId(key)

    return <Truncate>{isFake ? value : key}</Truncate>
  },
  number: ({
    type,
    value,
  }: TaskFieldLabelProps<number> & { type?: string }) => (
    <CustomFieldTypedNumberLabel type={type} value={value} />
  ),
  url: ({ value }: TaskFieldLabelProps<string>) => <UrlLabel value={value} />,
  person: ({ value }: TaskFieldLabelProps<UserInfoSchema>) => (
    <UserLabel value={value} />
  ),
  multiPerson: ({ value }: TaskFieldLabelProps<UserInfoSchema>) => (
    <UserLabel value={value} />
  ),

  default: () => 'unknown',
})
