import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useStageTasksToAutoSchedule } from '~/areas/task-project/hooks'
import { useBulkUpdateTasks } from '~/areas/tasks/hooks'
import { useProject } from '~/global/hooks'
import { useCallback } from 'react'

export const useAutoScheduleStage = ({
  projectId,
  stageDefinitionId,
}: {
  projectId: string
  stageDefinitionId: string
}) => {
  const project = useProject(projectId)

  const stageTasksToAutoSchedule = useStageTasksToAutoSchedule({
    project,
    stageDefinitionId,
  })

  const bulkUpdateTasks = useBulkUpdateTasks()

  return useCallback(
    async (shouldAutoSchedule: boolean) => {
      if (project == null || stageTasksToAutoSchedule == null) return

      const stageTasksToUpdate = stageTasksToAutoSchedule.filter(
        (task) => task.isAutoScheduled !== shouldAutoSchedule
      )

      recordAnalyticsEvent('STAGE_AUTO_SCHEDULE_TOGGLE', {
        numTasks: stageTasksToUpdate.length,
        autoScheduled: shouldAutoSchedule,
      })

      await bulkUpdateTasks([
        {
          currentWorkspaceId: project.workspaceId,
          taskIds: stageTasksToUpdate.map((task) => task.id),
          update: {
            type: 'bulk-field-update',
            isAutoScheduled: shouldAutoSchedule,
          },
        },
      ])
    },
    [project, stageTasksToAutoSchedule, bulkUpdateTasks]
  )
}
