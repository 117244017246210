import {
  type ChartQueryResponseSchema,
  type DashboardViewChartSchema,
} from '@motion/zod/client'

import { useQuery } from '@tanstack/react-query'
import { type ReactNode } from 'react'

import { ChartLoadingSpinner } from './styled'

import { ChartQueryError, useCreateChartQueryOptions } from '../utils'

type ChartQueryDataProviderProps = {
  chart: DashboardViewChartSchema
  children: (chartQueryResponse: ChartQueryResponseSchema) => ReactNode
}

export function ChartQueryDataProvider({
  chart,
  children,
}: ChartQueryDataProviderProps) {
  const options = useCreateChartQueryOptions(chart)
  const { data: chartQueryResponse, isLoading } = useQuery(options)

  if (isLoading) {
    return <ChartLoadingSpinner />
  }

  if (chartQueryResponse == null) {
    throw new ChartQueryError('chartQueryResponse is null', chart)
  }

  if (chartQueryResponse.data.length === 0) {
    return <div className='place-self-center'>No Data Available</div>
  }

  return children(chartQueryResponse)
}
