import {
  fromNamedDurationToDateRange,
  fromRelativeNowToDateRange,
} from '@motion/utils/dates'
import {
  type DateFilterSchema,
  type DefinedFilterSchema,
  type DefinedRelativeDateFilterSchema,
  type EmptyFilterSchema,
  type LogicalDateFilterSchema,
  type RangeDateFilterSchema,
  type RelativeDateFilterSchema,
} from '@motion/zod/client'

import { DateTime } from 'luxon'

import { type CalendarStartDay } from '../../../../../calendar'

const LOGICAL_OPS = [
  'gt',
  'gte',
  'lt',
  'lte',
  'equals',
] as LogicalDateFilterSchema['operator'][]

export function isLogicalFilter(
  obj: DateFilterSchema
): obj is LogicalDateFilterSchema {
  return LOGICAL_OPS.includes(obj.operator)
}

export function buildDateFilterQuery(
  filter: DateFilterSchema | null | undefined,
  startDay: CalendarStartDay = 'sunday'
):
  | DefinedFilterSchema
  | EmptyFilterSchema
  | RangeDateFilterSchema
  | LogicalDateFilterSchema
  | undefined {
  if (filter == null) return undefined
  if (filter.operator === 'defined') return filter
  if (filter.operator === 'empty') return filter

  if (filter.operator === 'relative') {
    return buildRelativeDateQuery(filter, startDay)
  }

  if (filter.operator === 'defined-relative') {
    return buildRelativeRangeDateQuery(filter, startDay)
  }

  if (filter.operator === 'range') return normalizeRangeFilter(filter)

  return normalizeLogicalFilters(filter)
}

export function buildRelativeDateQuery(
  filter: RelativeDateFilterSchema,
  startDay: CalendarStartDay = 'sunday'
): RangeDateFilterSchema {
  const range = fromRelativeNowToDateRange(filter.duration)
  return {
    operator: 'range',
    inverse: filter.inverse,
    value: range,
  }
}

export function buildRelativeRangeDateQuery(
  filter: DefinedRelativeDateFilterSchema,
  startDay: CalendarStartDay = 'sunday'
): RangeDateFilterSchema {
  const base = buildRelativeRangeDateQueryBase(filter, startDay)
  return {
    ...base,
    inverse: filter.inverse,
  }
}

function buildRelativeRangeDateQueryBase(
  filter: DefinedRelativeDateFilterSchema,
  startDay: CalendarStartDay = 'sunday'
): RangeDateFilterSchema {
  const range = fromNamedDurationToDateRange(filter.name)
  return {
    operator: 'range',
    value: range,
    inverse: filter.inverse,
  }
}

function normalizeRangeFilter(
  filter: RangeDateFilterSchema
): RangeDateFilterSchema {
  return {
    ...filter,
    value: {
      from: DateTime.fromISO(filter.value.from).startOf('day').toISO(),
      to: DateTime.fromISO(filter.value.to).endOf('day').toISO(),
    },
  }
}

export function normalizeLogicalFilters(
  filter: LogicalDateFilterSchema
): RangeDateFilterSchema | LogicalDateFilterSchema {
  const value = DateTime.fromISO(filter.value)
  if (filter.operator === 'equals') {
    return {
      inverse: filter.inverse,
      operator: 'range',
      value: {
        from: value.startOf('day').toISO(),
        to: value.endOf('day').toISO(),
      },
    }
  }
  if (filter.operator === 'gt') {
    return { ...filter, value: value.endOf('day').toISO() }
  }
  if (filter.operator === 'lt') {
    return { ...filter, value: value.startOf('day').toISO() }
  }
  if (filter.operator === 'gte') {
    return { ...filter, value: value.startOf('day').toISO() }
  }

  if (filter.operator === 'lte') {
    return { ...filter, value: value.endOf('day').toISO() }
    /* c8 ignore start */
  }

  // This shouldn't ever be hit.
  // But just in case, return the filter
  return filter
}
