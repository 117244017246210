import { useDependantState } from '@motion/react-core/hooks'
import { NumberField } from '@motion/ui/forms'

import { checkNumberRuleErrors } from '~/global/rules'
import { useRegisterFieldArray } from '~/hooks'

import { ExpectedDurationUnitSelector } from './expected-duration-unit-selector'
import { StageAutoScheduleField } from './fields/stage-auto-schedule'

import { useFlowTemplateForm } from '../../hooks'
import { getNumberFieldRules } from '../../utils'

type StagePathType = `stages.${number}`
type StageDetailsProps = {
  stagePath: StagePathType
}

export const StageDetails = ({ stagePath }: StageDetailsProps) => {
  const deadlineIntervalPath: `${StagePathType}.deadlineInterval` = `${stagePath}.deadlineInterval`
  const tasksPath: `${StagePathType}.tasks` = `${stagePath}.tasks`
  const deadlineIntervalValuePath = `${deadlineIntervalPath}.value` as const

  const {
    form: { register, watch, getValues, formState, getFieldState },
  } = useFlowTemplateForm()
  const { error } = getFieldState(deadlineIntervalValuePath, formState)

  const { onChange, onBlur, ...registerProps } = useRegisterFieldArray(
    deadlineIntervalValuePath,
    register,
    {
      validate: () => {
        const value = getValues(deadlineIntervalValuePath)

        const errorMessage = checkNumberRuleErrors(
          value,
          getNumberFieldRules('Stage deadline')
        )

        if (errorMessage) {
          return errorMessage
        }
      },
    }
  )

  const deadlineIntervalValue = watch(deadlineIntervalValuePath)

  const [expectedDuration, setExpectedDuration] = useDependantState<number>(
    () => deadlineIntervalValue,
    [deadlineIntervalValue]
  )

  return (
    <div className='flex flex-col gap-2'>
      <span className='font-medium text-2xs text-semantic-neutral-text-default'>
        Tasks will be created when this stage begins
      </span>

      <div>
        <label className='text-xs text-semantic-neutral-text-subtle flex flex-row items-center gap-1.5 whitespace-nowrap'>
          Expected duration:
          <NumberField
            value={expectedDuration}
            label='Deadline interval'
            labelHidden
            size='small'
            // @ts-expect-error - fine
            className='w-5'
            sentiment={error ? 'error' : 'default'}
            onBlur={(e) => {
              onBlur(e)
              onChange(expectedDuration)
            }}
            onChange={(e) => {
              setExpectedDuration(e)
            }}
            {...registerProps}
          />
          <ExpectedDurationUnitSelector
            deadlineIntervalPath={deadlineIntervalPath}
          />
        </label>
      </div>

      <StageAutoScheduleField tasksPath={tasksPath} />
    </div>
  )
}
